<template>
  <el-dialog
    :visible.sync="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="radar-dialog select-dialog">
    <div class="radar-dialog__title">
      <span>Elige un colegio</span>
    </div>
    <div
      class="radar-dialog__subtitle radar-dialog__subtitle--light">
      Para acceder a esta vista necesitas tener un colegio seleccionado.
    </div>
    <school-select
      v-model="chosenSchool"
      class="select-dialog__input rdr-select relation--picker__select"
      placeholder="Colegio o RBD" />
    <span
      v-if="chosenSchool"
      class="select-dialog__school">({{ chosenSchool.name | startCase }})</span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { Dialog } from 'element-ui';
import AuthMixin from '../../mixins/auth';
import LinksMixin from '../../mixins/links';
import SchoolSelect from '../../components/school-select.vue';

export default {
  components: {
    'el-dialog': Dialog,
    SchoolSelect,
  },
  mixins: [AuthMixin, LinksMixin],
  data() {
    return {
      chosenSchool: null,
    };
  },
  computed: {
    ...mapState('ui', ['dialogs']),
    show: {
      get() { return this.dialogs.schoolSelect.open; },
      set(value) {
        this.setDialogState({ dialog: 'schoolSelect', state: value });
      },
    },
  },
  watch: {
    async chosenSchool(newSchool) {
      if (newSchool) {
        try {
          this.addLoading();
          await this.setChosenSchool(newSchool.id);
          this.$router.push(this.landingLink);
        } catch (_) {
          this.error = true;
        } finally {
          this.removeLoading();
        }
        this.show = false;
      }
    },
  },
  methods: {
    ...mapMutations('ui', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
      setDialogState: 'SET_DIALOG_STATE',
    }),
    ...mapActions('options', ['setChosenSchool']),
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";

  .radar-dialog {
    a {
      color: $primary-color;
      text-decoration: none;
      border-bottom: 1px dashed;
    }

    .el-dialog {
      background-color: #FCFCFC;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 90%;
      }
    }

    .subitile{
      margin-bottom: 20px;
    }

    .el-dialog__body {
      padding: 60px 30px;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
      text-align: center;
    }

    &__subtitle {
      font-size: 18px;
      text-align: center;

      &--light {
        font-weight: 300;
        font-size: 12pt;
      }
    }
  }

  .select-dialog {
    &__input {
      width: 380px;
      margin: 0 auto;
      margin-bottom: 15px;
      margin-top: 45px;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 100%;
      }
    }
    &__school {
      display: block;
      margin: 15px auto;
      color: $light-color;
      font-weight: 400;
      text-align: center;
    }
  }
</style>
