<template>
  <div>
    <div v-if="isLoggedIn && hasInternalEvaluationsPermission">
      <div id="downTable" class="results-colormap">
        <div class="results-colormap_color-map">
          <color-map
            :loading="loading"
            :rows="schoolsAsRows"
            :columns="subjectsAsColumns"
            :results="results"
            :hide-empty-columns="true"
            :show-school-picker="false"
            :disable-school-adding="true"
            :round="true"
            :percentage="true"
            :column-header-hover="false"
            :internal-evaluations-view="true"
            column-sort="average_ascending"
            results-row-key="school_id"
            row-sort="average_ascending"
          />
        </div>
        <template v-if="results.length !== 0">
          <color-map-footer
            :footer-bottom-paragraphs="footerParagraphs"
            text="La gama de colores representa el logro en cada prueba de la siguiente forma:"
            high="Alto"
            average="Medio"
            low="Bajo"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import AuthMixin from '../../../mixins/auth';
import MockDataMixin from '../../../mixins/mock-data';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import TooltipInfoMixin from '../../../mixins/tooltip-info';

export default {
  name: 'ComparativeInternalEvaluationsLevelTestsView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin, TooltipInfoMixin],
  data() {
    return {
      footerParagraphs: [],
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('results', ['resultsSchools']),
    ...mapGetters('results', {
      resultsGetter: 'results',
      evaluations: 'resultsEvaluations',
      indicators: 'resultsIndicators',
    }),
    results() {
      return this.resultsGetter.map(result => {
        const indicator = this.indicators.find(ind => ind.id === result.indicator_id);
        return { ...result, column_id: indicator.id }; // eslint-disable-line camelcase
      });
    },
    schoolsAsRows() {
      return this.resultsSchools.map(school => ({ ...this.schoolData(school), parent_id: null })); // eslint-disable-line camelcase
    },
    subjectsAsColumns() {
      const columns = [];
      this.indicators.forEach(indicator => {
        const newColumn = {
          id: indicator.id,
          label: indicator.label,
          format: indicator.format,
        };
        const columnExists = columns.find(column => column.id === newColumn.id);
        if (!columnExists) columns.push(newColumn);
      });
      return columns;
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    ...mapActions('ui', ['openLoginDialog']),
    login() {
      this.openLoginDialog(true);
    },
    setViewParams() {
      this.setReportParams({ 'school_ids_auth': true, 'years': true, 'months': true });
      this.setReportKey('pdn-school-comparative');
    },
  },
};
</script>
