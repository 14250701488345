<template>
  <paywall
    :permitted="hasImprovementPlanPermission">
    <div :class="$style.header">
      <div :class="[$style.title, 'print-only']">Seguimiento PME — Visión general</div>
      <div v-if="networkName">{{ networkName }}</div>
      <div v-else>Seguimiento PME Comparativo</div>
      <div>—</div>
      <div>
        <span>Año</span>
        <rdr-picker
          v-model="chosenYear"
          :options="years"
          :loading="loadingOptions"/>
      </div>
      <div>
        <span>Dimensión</span>
        <rdr-picker
          v-model="chosenDimension"
          :options="dimensions"
          :loading="loadingOptions"
          no-choice="Todas"
          label-property="name"
          empty-choice/>
      </div>
    </div>
    <div :class="$style.content">
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
      <strong v-if="error">Ocurrió un error. Intenta de nuevo.</strong>
      <rdr-table
        v-else-if="rows && !loading"
        :columns="columns"
        :data="rows"
      />
    </div>
  </paywall>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import Paywall from '../../components/paywall.vue';
import formatDateString from '../../utils/format-date-string';
import PmeApi from '../../api/pme';

export default {
  name: 'ImprovementPlanComparativeView',
  components: { Paywall },
  mixins: [PermissionMixin],
  data() {
    return {
      loading: 0,
      loadingOptions: 0,
      error: false,
      years: [],
      chosenDimension: null,
      dimensions: [],
      data: null,
      summary: null,
    };
  },
  computed: {
    ...mapGetters('auth', {
      schools: 'userSchools',
      networkName: 'userNetworkName',
    }),
    ...mapState('options', { resultsChosenYear: 'chosenYear' }),
    chosenYear: {
      get() {
        if (!this.years.includes(this.resultsChosenYear)) {
          this.setChosenYear(this.years[0]);

          return this.years[0];
        }

        return this.resultsChosenYear;
      },
      set(value) {
        this.setChosenYear(value);
      },
    },
    options() {
      const ret = { years: this.chosenYear };
      if (this.chosenDimension) ret.dimension_ids = this.chosenDimension.id; // eslint-disable-line camelcase

      return ret;
    },
    columns() {
      return [
        { key: 'name', label: 'Colegio', filters: ['cleanSchoolName', 'startCase'], infoTooltip: 'lastUpdate', type: 'link', textAlign: 'left', sortFunc: (a, b) => a.localeCompare(b) },
        { key: 'action_count', label: 'Total de acciones', filters: ['number'], sortFunc: (a, b) => a - b },
        { key: 'execution_percentage', label: 'Porcentaje de ejecución', filters: ['percentage'], type: 'progress-bar', options: { textInside: true }, sortFunc: (a, b) => a - b },
        { key: 'total_budget', label: 'Presupuesto total', filters: ['clp'], sortFunc: (a, b) => a - b },
        { key: 'percentage_of_total', label: 'Porcentaje', filters: ['percentage'], sortFunc: (a, b) => a - b },
      ];
    },
    rows() {
      if (!this.data || !this.summary) return null;

      return [
        {
          id: 'summary',
          name: 'Todos',
          first: true,
          ...this.summary,
          highlight: true,
        },
        ...this.data.map(d => ({
          ...d,
          link: {
            name: 'improvementPlanPerSchool',
            params: {
              school: d.id,
              year: this.resultsChosenYear,
            },
          },
          lastUpdate: `Última actualización: ${formatDateString(d.last_update)}`,
        })),
      ];
    },
  },
  watch: {
    options(to, from) {
      if (
        to.years && from.years && to.years === from.years &&
        to.dimension_ids && from.dimension_ids && to.dimension_ids === from.dimension_ids
      ) return;
      this.fetchActionSummary();
    },
  },
  created() {
    this.fetchOptions();
  },
  methods: {
    ...mapActions('options', ['setChosenYear']),
    async fetchOptions() {
      try {
        this.loadingOptions++;
        const response = await PmeApi.getOptions(this.schools.map(s => s.id).join(','));
        const { body: { options: { years, dimensions } } } = response;
        this.years = years;
        this.dimensions = dimensions;
        this.error = false;
      } catch (_) {
        this.error = true;
      } finally {
        this.loadingOptions--;
      }
      this.fetchActionSummary();
    },
    async fetchActionSummary() {
      /* eslint-disable camelcase */
      if (this.loadingOptions || !this.chosenYear) return;
      try {
        this.loading++;
        const { body: { school_summaries, summary } } = await PmeApi.getActionSummary({
          school_ids: this.schools.map(s => s.id).join(','),
          ...this.options,
        });
        this.data = school_summaries;
        this.summary = summary;
        this.error = false;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading--;
      }
      /* eslint-enable camelcase */
    },
  },
};
</script>

<style lang="scss" module>
.header {
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  padding: 0 30px;
  margin-bottom: 30px;

  & > * {
    display: inline-block;
    margin-top: 10px;
  }
}

.title {
  display: block;
}

.content {
  min-height: 200px;
}

.school-name-cell > div {
  display: flex;
  align-items: center;
}

.school-name {
  flex-grow: 1;
}

.last-update-icon > span {
  display: flex;
  align-items: center;
  cursor: help;
  user-select: none;
}
</style>
