<template>
  <div :class="$style.column">
    <div
      v-if="hasRecord"
      :class="$style.row">
      <div>
        <div :class="$style.rowNoWrap">
          <div :class="$style.mobileScrollable">
            <div class="info__datum info__datum--centered">
              <span class="info__label">Asistencia promedio mensual {{ year }}</span>
            </div>
            <historic-chart
              :periods="chartMonths"
              :columns="chartColumns"
              :view-window="chartFrame"
              :v-axis-format="vAxisFormat"
              :rows="chartRows"
              :width="550"
              :point-size="5"
              :slanted="true"
              :line-width="1"
              :ticks="attendanceTicks"
              :font-size="100"
              :height="180"
              legend-position="right"
              label-type="string"
            />
          </div>
          <div :class="[$style.column]">
            <div
              v-for="(column, index) in chartColumns"
              :key="index"
              class="info__column">
              <div
                :style="{ borderColor: chartColors[index] }"
                class="info__color"
              />
              <div class="info__column-name">{{ column }}</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.row">
        <div v-if="maxMonth">
          <percentage-donut-result
            :total="100"
            :value="Math.round(maxMonth.value)"
            :footer="monthName(maxMonth.month)"
            title="Mes asistencia más alta"/>
        </div>
        <div v-if="minMonth">
          <percentage-donut-result
            :total="100"
            :value="Math.round(minMonth.value)"
            :footer="monthName(minMonth.month)"
            title="Mes asistencia más baja"/>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="$style.noRecord">
      <img src="/noResultados.svg">
    </div>
  </div>
</template>

<script>
import HistoricChart from '../../components/historic-chart.vue';
import { MONTHS, academicYearMonths } from '../../utils/months';
import GRAPH_COLORS from '../../utils/graph-colors';
import CircularProgressBar from '../../components/base/circular-progress-bar';
import PercentageDonutResult from '../../components/utils/percentage-donut-result';

export default {
  name: 'CircularProgressBar',
  components: {
    HistoricChart,
    CircularProgressBar,
    PercentageDonutResult,

  },
  props: {
    year: {
      type: Number,
      default: 0,
    },
    vAxisFormat: {
      type: String,
      default: '',
    },
    chartAttendance: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartFormat: '#\'%\'',
      chartFormatter: { groupingSymbol: '', fractionDigits: 1, suffix: '%' },
      chartColors: GRAPH_COLORS,
    };
  },
  computed: {
    hasRecord() {
      return this.chartAttendance.length > 0;
    },
    minMonth() {
      const studentAttendances = this.chartAttendance.filter(attendance => attendance.type === 'student');
      return studentAttendances.reduce((min, p) => (p.value < min.value ? p : min), studentAttendances[0]);
    },
    maxMonth() {
      const studentAttendances = this.chartAttendance.filter(attendance => attendance.type === 'student');
      return studentAttendances.reduce((max, p) => (p.value > max.value ? p : max), studentAttendances[0]);
    },
    attendanceTicks() {
      if (this.chartFrame.min === 50) return [50, 60, 70, 80, 90, 100]; // eslint-disable-line no-magic-numbers
      return [20, 40, 60, 80, 100]; // eslint-disable-line no-magic-numbers
    },
    chartFrame() {
      const chartFrame = { min: 50, max: 100 };
      this.chartAttendance.forEach(attendance => {
        if (attendance.value < chartFrame.min) chartFrame.min = 0;
      });
      return chartFrame;
    },
    chartColumns() {
      return ['Alumno', 'Generación'];
    },
    chartMonths() {
      if (this.chartAttendance.length > 0) {
        const maxMonth = Math.max(
          ...this.chartAttendance.map(a => a.month)
        );
        return academicYearMonths(maxMonth);
      }
      return [];
    },
    chartRows() {
      const rows = [];
      const typeMappings = { 'Alumno': 'student', 'Generación': 'group' };
      this.chartMonths.forEach(monthString => {
        const month = MONTHS.findIndex(value => value === monthString) + 1;
        const monthResults = this.chartAttendance.filter(result => result.month === month);
        if (monthResults.length) {
          const row = [monthString];
          this.chartColumns.forEach(type => {
            const rowResult = monthResults.find(result => result.type === typeMappings[type]);
            row.push(rowResult ? Math.round(rowResult.value) : null);
          });
          rows.push(row);
        }
      });
      return rows;
    },
  },
  methods: {
    monthName(number) {
      return MONTHS[number - 1];
    },
  },
};

</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
.column{
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.row{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.row-no-wrap{
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-wrap: wrap;
  }
}

.mobile-scrollable {
  @media only screen and (max-width: $mobile-breakpoint) {
    width: 95vw;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.no-record{
  text-align: center;
}

</style>

