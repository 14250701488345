/* eslint-disable no-unused-vars */

import Vue from 'vue';
import VueResource from 'vue-resource';
import VueFreshchat from './utils/VueFreshchat';
import vSelect from 'vue-select';
import vEnerSelect from 'vue-ener-select';
import VueTouch from 'vue-touch';
import VueAnalytics from 'vue-analytics';
import VueYouTubeEmbed from 'vue-youtube-embed';
// import VueHtml2Canvas from 'vue-html2canvas';
import html2canvas from 'html2canvas';

import filters from './filters';
import router from './router';
import store from './store';

import Dialog from './components/base/dialog.vue';
import Popover from './components/base/popover.vue';
import Table from './components/base/table.vue';
import Tooltip from './components/base/tooltip.vue';

import Picker from './components/utils/picker.vue';

import Auth from './components/partials/auth.vue';
import ColorMap from './components/color-map.vue';
import FilterTable from './components/filter-table.vue';
import Paywall from './components/paywall.vue';

import GroupPicker from './components/group-picker.vue';
import HistoricGroupPicker from './components/historic-group-picker.vue';
import IndicatorPicker from './components/indicator-picker.vue';
import HistoricIndicatorPicker from './components/historic-indicator-picker.vue';
import MonthPicker from './components/month-picker.vue';
import SchoolPicker from './components/school-picker.vue';
import YearPicker from './components/year-picker.vue';
import ChosenSchoolPicker from './components/chosen-school-picker.vue';

import LoadingOverlay from './components/utils/loading-overlay.vue';
import ShareWhatsappButton from './components/utils/share-whatsapp.vue';

import ContactFormDialog from './components/dialogs/contact-form.vue';
import FreeOfferDialog from './components/dialogs/free-offer.vue';
import LoginDialog from './components/dialogs/login.vue';
import NewSimceNotificationDialog from './components/dialogs/new-simce-notification.vue';
import PasswordFeedbackDialog from './components/dialogs/password-feedback.vue';
import SchoolPickerDialog from './components/dialogs/school-picker.vue';
import SchoolSelectDialog from './components/dialogs/school-select.vue';
import SignUpDialog from './components/dialogs/sign-up.vue';
import UpcomingDialog from './components/dialogs/upcoming.vue';
import VersionUpdateDialog from './components/dialogs/version-update.vue';

Vue.use(VueResource);
Vue.use(filters);
Vue.use(VueTouch, { name: 'v-touch' });
Vue.use(VueYouTubeEmbed);
// Vue.use(VueHtml2Canvas);
Vue.use(html2canvas);
Vue.use(VueFreshchat, { appToken: 'd6c04984-db5e-4bcb-b3e1-b2a8d579a4bd', config: {} }); // config object is optional

const tracking_id = localStorage.getItem('ua_tracking_id');
if (tracking_id) {
  Vue.use(VueAnalytics, {
    id: tracking_id,
    router,
    autoTracking: {
      pageviewTemplate(route) {
        return {
          page: route.path,
          title: route.name,
          location: window.location.href,
        };
      },
    },
  });
}

/* Base Components */
Vue.component('rdr-dialog', Dialog);
Vue.component('rdr-popover', Popover);
Vue.component('rdr-tooltip', Tooltip);
Vue.component('rdr-table', Table);

Vue.component('v-select', vSelect);
Vue.component('v-ener-select', vEnerSelect);
Vue.component('rdr-picker', Picker);

Vue.component('auth', Auth);
Vue.component('color-map', ColorMap);
Vue.component('filter-table', FilterTable);
Vue.component('paywall', Paywall);

Vue.component('group-picker', GroupPicker);
Vue.component('indicator-picker', IndicatorPicker);
Vue.component('historic-group-picker', HistoricGroupPicker);
Vue.component('historic-indicator-picker', HistoricIndicatorPicker);
Vue.component('month-picker', MonthPicker);
Vue.component('school-picker', SchoolPicker);
Vue.component('year-picker', YearPicker);
Vue.component('chosen-school-picker', ChosenSchoolPicker);

Vue.component('loading-overlay', LoadingOverlay);
Vue.component('share-whatsapp', ShareWhatsappButton);

const app = new Vue({
  el: '#radar-app',
  store,
  router,
});

export default app;
