<template>
  <div class="rdr-view enrollment-view">
    <rdr-results-header
      :title="teacherHoursTitle"
      mobile-only-picker>
      <div class="results-header__subtitle">Este gráfico muestra las horas de docencia de tu colegio en comparación con otros establecimientos.</div>
    </rdr-results-header>
    <historic-results
      :restricted="!isLoggedIn || !hasManagementIndicatorsPermission"
      numbers="roundWithDecimal"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ResultsHeader from '../components/results/results-header.vue';
import HistoricResults from '../components/historic-results.vue';

import AuthMixin from '../mixins/auth';
import PermissionMixin from '../mixins/permission';
import ResultsMixin from '../mixins/results';

export default {
  name: 'TeacherHoursView',
  components: {
    'rdr-results-header': ResultsHeader,
    HistoricResults,
  },
  mixins: [AuthMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      teacherHoursTitle: 'Horas de docencia en tu(s) establecimiento(s)',
    };
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'chosen_school_ids': true });
      this.setReportKey('teacher-hours');
    },
  },
};
</script>

<style lang="scss">
</style>
