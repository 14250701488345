<template>
  <transition-group
    :enter-class="$style.sortRowsEnter"
    :enter-active-class="$style.sortRowsEnterActive"
    :enter-to-class="$style.sortRowsEnterTo"
    :leave-class="$style.sortRowsLeave"
    :leave-active-class="$style.sortRowsLeaveActive"
    :leave-to-class="$style.sortRowsLeaveTo"
    :move-class="$style.sortRowsMove"
    v-bind="$attrs"
    name="sort-rows"
    v-on="$listeners">
    <slot/>
  </transition-group>
</template>

<script>
export default {
  name: 'SortRows',
};
</script>

<style lang="scss" module>
$transition-time: 0.3s;

.sort-rows-enter-active {
  transition: all $transition-time ease;
}

.sort-rows-leave-active {
  transition: all $transition-time ease;
  display: none;
}

.sort-rows-enter {
  opacity: 0;
}

.sort-rows-leave-to {
  opacity: 0;
  display: none;
}

.sort-rows-leave {
  opacity: 1;
}

.sort-rows-move {
  transition: transform $transition-time;
}
</style>
