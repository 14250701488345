<template>
  <div style="display: none;">
    <a
      ref="openWhatsApp"
      :href="whatsAppLink"
      data-action="share/whatsapp/share"
    />
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import LinksMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';

export default {
  mixins: [LinksMixin, PermissionMixin],
  computed: {
    whatsAppLink() {
      return 'http://wa.me/56951203573';
    },
  },
  mounted() {
    this.openNotification();
  },
  beforeDestroy() {
    // this.closeNotification();
  },
  methods: {
    openNotification() {
      this.openContactForm();
      // Notification.closeAll();
      // if (!this.isLoggedIn) {
      //   setTimeout(() => {
      //     this.createFreeUserNotification('desktop-only', this.openContactForm);
      //     this.createFreeUserNotification('mobile-only', this.openWhatsApp);
      //   }, 1000); // eslint-disable-line no-magic-numbers
      // }
    },
    createFreeUserNotification(className, clickFunction) {
      Notification({ // eslint-disable-line new-cap
        title: '¿Dudas? ¡Escríbenos! ....',
        message: '+56951203573 <em>(WhatsApp)</em><br />Haz click para más información.',
        dangerouslyUseHTMLString: true,
        customClass: `el-notification contact-notification ${className}`,
        iconClass: 'socicon-whatsapp contact-notification__icon',
        duration: 0,
        position: 'bottom-right',
        onClick: clickFunction,
      });
    },
    closeNotification() {
      Notification.closeAll();
    },
    openContactForm() {
      this.$store.dispatch('ui/openDialog', 'contact');
      this.$ga.event({
        eventCategory: 'ContactNotification',
        eventAction: 'OpenContactForm ',
        eventLabel: 'Contact',
      });
    },
    openWhatsApp() {
      this.$ga.event({
        eventCategory: 'ContactNotification',
        eventAction: 'OpenWhatsApp',
        eventLabel: 'Contact',
      });
      this.$refs.openWhatsApp.click();
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/app/variables";

$icon-size: 36px;

.contact-notification {
  align-items: center;
  z-index: 1400 !important;
  cursor: pointer;

  @media only screen and (max-width: $mobile-breakpoint) {
    width: calc(100% - 32px);
    right: 16px;
    left: 16px;
  }

  &__icon {
    color: $success-color;
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;
  }

  .el-notification__content {
    text-align: left;
  }
}
</style>
