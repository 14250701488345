<template>
  <rdr-dialog
    :visible.sync="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :custom-classes="[$style.dialog]">
    <div :class="$style.title">
      <div>¡Buenas noticias!</div>
      <div>Existe una nueva versión de Radar Escolar.</div>
    </div>
    <div
      :class="[$style.subtitle, $style.light]">
      Haz click en el botón para actualizar
    </div>
    <div
      :class="$style.btnWrapper"
    >
      <div
        :class="[$style.btn ,'rdr-btn rdr-btn--large rdr-btn--primary']"
        @click="refresh">
        Actualizar
      </div>
    </div>
  </rdr-dialog>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import RdrDialog from '../../components/base/dialog.vue';
import SchoolSelect from '../../components/school-select.vue';

export default {
  name: 'VersionUpdateDialog',
  components: {
    RdrDialog,
    SchoolSelect,
  },
  computed: {
    ...mapState('ui', ['dialogs', 'latestVersion']),
    show: {
      get() { return this.dialogs.versionUpdate.open; },
      set(value) {
        this.setDialogState({ dialog: 'versionUpdate', state: value });
      },
    },
  },
  methods: {
    ...mapMutations('ui', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
      setDialogState: 'SET_DIALOG_STATE',
      setLatestVersion: 'SET_LATEST_VERSION',
    }),
    ...mapActions('options', ['setChosenSchool']),
    refresh() {
      localStorage.setItem('version', this.latestVersion);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .dialog {
    max-width: 800px;
  }

  .btn-wrapper {
    text-align: center;
  }

  .btn {
    text-align: center;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    word-break: keep-all;
  }

  .subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
</style>
