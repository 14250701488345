<template>
  <attendance-bar-chart
    :disable-options="disableOptions"
    :label-text="labelText"
    :month-joiner="monthJoiner"
    :chart-props="chartProps"/>
</template>

<script>
import AttendanceBarChart from '../attendance-bar-chart.vue';

export default {
  name: 'AbsenteeismLastYearComparisonBySchoolView',
  components: { AttendanceBarChart },
  data() {
    return {
      disableOptions: ['course'],
      labelText: 'Ausentismo crónico',
      monthJoiner: 'a',
      chartProps: {
        colors: ['#ff9897', '#ff3839'],
        reportKey: 'absenteeism-last-year-comparison',
      },
    };
  },
};
</script>

<style lang="scss" module>
</style>
