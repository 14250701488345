import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['googleAuthInfo', 'isLoggedIn']),
    ...mapState('auth', { currentUser: 'userInfo' }),
    proQuoteLink() {
      return { name: 'proQuote' };
    },
    settingsLink() {
      return { name: 'settings' };
    },
  },
  mounted() {
    if (this.googleAuthInfo) {
      this.signUp();
    }
  },
  methods: {
    ...mapActions('ui', ['openLoginDialog', 'openSignUpDialog']),
    ...mapMutations('ui', ['hideMobileMenu']),
    ...mapMutations('options', { resetOptionsState: 'RESET_STATE' }),
    ...mapMutations('results', { resetResultsState: 'RESET_STATE' }),
    ...mapMutations('resultsPro', { resetResultsProState: 'RESET_STATE' }),
    loginPopUp() {
      this.openLoginDialog(true);
      this.$ga.event({
        eventCategory: 'LoginPopUp',
        eventAction: 'Open',
        eventLabel: 'Login',
      });
    },
    logout() {
      this.$router.push('/', () => {
        this.$store.dispatch('auth/logout');
        this.openLoginDialog(false);
        this.hideMobileMenu();
        this.resetOptionsState();
        this.resetResultsState();
        this.resetResultsProState();
        this.$freshchat.hideWidget();
      });
      this.$ga.event({
        eventCategory: 'Logout',
        eventAction: 'Logout',
        eventLabel: 'Login',
      });
    },
    login() {
      this.openLoginDialog(true);
      this.$ga.event({
        eventCategory: 'LoginPopUp',
        eventAction: 'Open',
        eventLabel: 'Login',
      });
    },
    signUp() {
      this.openSignUpDialog(true);
      this.$ga.event({
        eventCategory: 'SignUpPopup',
        eventAction: 'Open',
        eventLabel: 'SignUp',
      });
    },
  },
};
