<template>
  <div
    :class="[
      $style.rdrPicker,
      {[$style.rdrPickerSmall]: size === 'small'},
      {[$style.rdrPickerInline]: pickerStyle === 'inline'},
      {[$style.rdrPickerBorder]: pickerStyle !== 'round'},
      {[$style.rdrPickerRound]: pickerStyle === 'round'}]"
  >
    <div class="contenSelect">
      <div
        :class="[
          $style.rdrPickerCurrent,
          {[$style.rdrPickerCurrentDisabled]: options.length === 0 || disabled}
        ]"
      >
        <template v-if="options.length && value">
          <input
            ref="search"
            @input="switchToSearch($event)"
            @click="switchToSearch($event)"
            @focusout="validateFocus"
            @keyup="matchingOptions($event)"
            :class="[$style.inpsearch]"
            placeholder="Seleccione un colegio"
            :value="searchText"
          >
          <ul
            v-if="viewList"
            tabindex="-1"
            ref="options"
            :class="[
              $style.shadowMd,
              $style.absolute,
              $style.wFull,
              $style.overflowAuto,
              $style.appearanceNone,
              $style.border,
              $style.rounded,
              $style.textGreyDarker,
              $style.borderGreyLighter,
              $style.bgWhite,
              $style.listReset,
              $style.leadingNormal,
              $style.textLeft,
              $style.styleul,
            ]"
          >
            <li
              v-for="(option, idx) in optionsClon"
              :key="idx"
              :class="[
                { 'is-active': idx === pointer },
                $style.cursorPointer,
                $style.styleli,
                $style.outlineNone
              ]"
              @click="handleChange(option)"
            >
              {{ getOptionLabel(option) }}
            </li>
          </ul>
        </template>

        <span
          v-if="!loading && options.length && !value"
          :class="$style.rdrPickerLabel">
          {{ noChoice }}
        </span>

        <i
          v-if="!loading && options.length > 0 && searchText === ''"
          @click="activList"
          :class="[
            {[$style.rdrPickerArrowDisabled]: options.length === 0 || disabled},
            $style.rdrPickerArrow,
            $style.customMaterialIcons,
            'material-icons',
            'hide-print']">
          keyboard_arrow_down
        </i>
        <div
          v-else-if="!loading && options.length > 0"
          :class="[$style.icon,
            $style.rdrPickerArrow,
            $style.customMaterialIcons,
            'material-icons',
            'hide-print']"
          @click="closeOut"
        >
          <svg
            @click="closeOut"
            aria-hidden="true"
            viewBox="0 0 512 512"
          >
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
          </svg>
        </div>
        <span v-else-if="!options.length">{{ noOptions }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RdrPicker',
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    labelFilter: {
      type: String,
      default: null,
    },
    labelFunction: {
      type: Function,
      default: null,
    },
    labelProperty: {
      type: String,
      default: null,
    },
    valueProperty: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    pickerStyle: {
      type: String,
      default: 'inline',
    },
    noChoice: {
      type: String,
      default: 'Seleccionar...',
    },
    noOptions: {
      type: String,
      default: 'sin datos',
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Cargando...',
    },
    emptyChoice: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: null,
      searchTextOld: null,
      selectedOption: null,
      pointer: -1,
      firstLoad: true,
      viewList: false,
      optionsClon: [],
      clearText: false,
    };
  },
  computed: {
    optionsAreObjects() {
      return this.options.every(option => typeof option === 'object');
    },
    optionsAreNumbers() {
      return this.options.every(option => typeof option === 'number');
    },
  },
  mounted() {
    if (this.firstLoad) {
      const txtInput = this.getOptionLabel(this.value);
      this.searchText = txtInput;
      this.searchTextOld = txtInput;
      this.firstLoad = false;
      this.optionsClon = JSON.parse(JSON.stringify(this.options));
    }
  },
  methods: {
    handleChange(value) {
      const processedValue = value;
      const texName = value.name;
      this.searchText = texName;
      this.searchTextOld = texName;
      this.$emit('input', processedValue);
      this.$emit('update:value', processedValue);
      this.viewList = false;
      this.clearText = false;
      this.optionsClon = JSON.parse(JSON.stringify(this.options));
    },
    getOptionValue(option) {
      if (this.optionsAreObjects) {
        if (this.valueProperty) return option[this.valueProperty];

        return this.options.indexOf(option);
      }

      return option;
    },
    getValueOption(value) {
      return this.options.find(option => option[this.valueProperty] === value);
    },
    getOptionLabel(option) {
      let label = option;
      if (this.labelProperty) {
        if (typeof label !== 'object') label = this.getValueOption(label);
        label = label[this.labelProperty];
      }
      if (this.labelFunction) label = this.labelFunction(label);
      if (this.labelFilter) label = this.$options.filters[this.labelFilter](label);
      return label;
    },

    closeOut(openList = true) {
      this.clearText = true;
      this.searchText = '';
      this.$refs.search.focus();
      if (openList) this.viewList = true;
    },
    switchToSearch(event) {
      this.clearText = false;
      this.searchText = event.target.value;
      this.selectedOption = null;
      this.$nextTick().then(() => {
        this.$refs.search.focus();
      });
      if (this.searchText === '') this.viewList = true;
    },
    validateFocus() {
      setTimeout(() => {
        if (!this.clearText && this.searchText !== this.searchTextOld) {
          this.searchText = this.searchTextOld;
          this.viewList = false;
        }
      }, 600);
    },
    activList() {
      this.viewList = !this.viewList;
    },
    matchingOptions() {
      this.optionsClon = this.options
        .filter(option =>
          option.name
            .toString()
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase())
        )
        .slice(0, this.maxResults);
    },

    converText(txt) {
      const palabras = txt.split(' ');
      for (let i = 0; i < palabras.length; i++) {
        palabras[i] = palabras[i][0].toUpperCase() + palabras[i].substr(1);
      }
      palabras.join(' ');
      return palabras;
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .rdr-picker {
    display: inline-block;
    position: relative;
    font-size: 28px;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 19px;
    min-width: 120px;

    &-disabled {
      background-color: $gray-background-color;
      border-color: $disabled-border-color;
      color: $light-gray-color;

      &:hover {
        cursor: not-allowed;
        .rdr-picker-current {
          border-color: $disabled-border-color;
          color: $light-gray-color;
        }
      }
    }

    &-select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: inherit;
      cursor: pointer;
      opacity: 0;
      z-index: 1;
    }

    &-inline {
      @media only screen {
        .rdr-picker-current {
          padding-bottom: 2px;
          color: $adminitrador-color-text;
          cursor: pointer;
          display: flex;
          align-content: space-between;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          min-width: 200px;
          height:40px;
          padding: 0px 15px;
          font-size: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .custom-material-icons {
          position: relative;
          font-size: inherit;
        }

        &:hover {
          .rdr-picker-current {
            color: darken($adminitrador-color-text, 30%);
          }
        }
      }
    }

    &-round {
      .rdr-picker-current {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ccc;
        padding: 6px 10px 6px 20px;
        border-radius: 20px;
        align-items: center;
        font-size: 14px;

        &-disabled {
          background-color: $gray-background-color !important;
          border-color: $disabled-border-color !important;
          color: $light-gray-color !important;
        }
      }

      .rdr-picker-label {
        display: inline-block;
        margin-right: 15px;
        font-size: 16px;
      }

      .rdr-picker-arrow {
        border: 1px solid;
        border-radius: 13px;
        color: $primary-color;
        text-align: center;
        font-size: 22px;
        padding-top: 1px;
        height: 24px;

        &-disabled {
          color: $light-gray-color;
        }
      }

      &:hover {
        .rdr-picker-current {
          border-color: $primary-color;
          color: $adminitrador-color-text;
        }
      }
    }

    &-border {
      border: 1px solid $cards-background-border;
    }

    &-small {
      font-size: 16px;

      .custom-material-icons {
        top: 3px;
      }
    }
  }
  /** ======== CSS ADD ======= **/
  .w-full {
    width: 100%;
  }
  .inline-block {
    display: inline-block;
  }
  .block {
    display: block;
  }
  .flex {
    display: flex;
  }
  .border {
    border-width: thin;
    border-style: solid;
  }
  .rounded {
    border-radius: 0.25rem;
  }
  .text-black {
    color: #22292f;
  }
  .text-grey-darker {
    color: #606f7b;
  }
  .text-grey-light {
    color: #dae1e7;
  }
  .border-grey-lighter {
    border-color: #ced4da;
  }
  .bg-grey-lighter {
    background-color: #606f7b;
  }
  .bg-grey-light {
    background-color: #dae1e7;
  }
  .bg-grey-dark {
    background-color: #8795a1;
  }
  .bg-white {
    background-color: #fff;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }

  .items-center {
    align-items: center;
  }
  .py2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .mt-px {
    margin-top: 1px;
  }
  .leading-tight {
    line-height: 1.25;
  }
  .leading-normal {
    line-height: 1.5;
  }
  .text-left {
    text-align: left;
  }
  .w-full {
    width: 100%;
  }
  .shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .list-reset {
    list-style: none;
    padding: 0;
  }
  .overflow-auto {
    overflow: auto;
  }
  .appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .fill-current {
    fill: currentColor;
  }
  .no-underline {
    text-decoration: none;
  }
  .hover\:no-underline:hover {
    text-decoration: none;
  }
  .outline-none {
    outline: 0;
  }
  .hover\:outline-none {
    outline: 0;
  }
  .hover\:bg-grey-light:hover {
    background-color: #dae1e7;
  }
  .shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
  }
  .icon {
    padding-left: 10px;
    padding-right: 0px;
    fill: #606f7b;
  }
  .icon > svg {
    width: 0.75rem;
    height: 0.75rem;
  }
  .single-select-wrapper {
    position: relative;
    margin-bottom: 0.5rem;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  ol, ul {
    margin: 0;
  }
  button, input, optgroup, select, textarea {
    font-family: inherit;
  }
  button, input {
    overflow: visible;
  }
  li:hover {
    background-clip: pink;
  }
  .is-active {
    background: #dae1e7;
  }
  .inpsearch {
    border: 0px !important;
    font-size: 10pt !important;
    color: #555;
    outline: none !important;
  }
  .styleli {
    padding: 3px 10px;
    font-size: 12px;
    border-bottom: 1px solid #eee;
  }
  .styleli:hover {
    background: #177ED3;
    color: #fff;
  }
  .styleul {
    margin-left: -16px;
    margin-top: 40px;
    max-height: 200px;
    min-width: 200px;
    z-index: 900;
    top: 0;
    width: auto;
  }
  .hiddenclass {
    display: none;
  }
</style>
