<template>
  <div>
    <div
      v-if="!hasResults"
      :class="$style.noResultsMessage">
      No hay evaluaciones Aptus registradas para este estudiante.
    </div>
    <div
      v-else
      :class="$style.row">
      <explanation-message
        v-if="showAptusExplanation"
        :explanation-message="aptusExplanationMessage"
      />
      <div
        v-for="(grades, subjectKey) in results"
        :key="subjectKey"
        :class="[$style.greyBox, $style.row]">
        <result-box
          :title="subjectMap[subjectKey]"
          :value="latestValue(subjectKey)"
          :color-picker="colorPicker"
          :relative-value="relativeValue(subjectKey)"
          metric-type="pts"/>
        <div :class="$style.column">
          <div :class="$style.fullWidth">
            <span :class="['info__label', $style.alignRight]">Últimas pruebas de {{ subjectMap[subjectKey] }}</span>
          </div>
          <div :class="$style.row">
            <div>
              <historic-chart
                :columns="aptusEvolutionColumns"
                :view-window="chartFrame"
                :v-axis-format="chartFormat"
                :rows="chartRows(subjectKey)"
                :height="200"
                :width="250"
                :ticks="ticks"
                :line-width="1"
                :point-size="5"
                label-type="string"
              />
            </div>
            <div :class="$style.column">
              <div
                v-for="(column, index) in aptusEvolutionColumns"
                :key="index"
                class="info__column">
                <div
                  :style="{ borderColor: chartColors[index] }"
                  class="info__color"
                />
                <div class="info__column-name">{{ column }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultBox from '../../components/utils/result-box.vue';
import HistoricChart from '../../components/historic-chart.vue';
import GRAPH_COLORS from '../../utils/graph-colors';
import { MAIN_TEXT_COLOR } from '../../utils/style-variables';
import ExplanationMessage from '../../components/explanation-message.vue';

export default {
  name: 'StudentAptusView',
  components: {
    HistoricChart,
    ResultBox,
    ExplanationMessage,
  },
  props: {
    results: {
      type: Object,
      default: () => {},
    },
    subjectMap: {
      type: Object,
      default: () => {},
    },
    colorPicker: {
      type: Function,
      default: () => MAIN_TEXT_COLOR,
    },
    showAptusExplanation: {
      type: Boolean,
      default: false,
    },
    aptusExplanationMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      chartFormatter: { groupingSymbol: '', fractionDigits: 1, suffix: '%' },
      chartColors: GRAPH_COLORS,
      chartFormat: '0.0',
      aptusEvolutionColumns: ['Alumno', 'Curso'],
    };
  },
  computed: {
    chartFrame() {
      return { min: this.ticks[0], max: this.ticks.slice(-1)[0] };
    },
    ticks() {
      const step = 0.5;
      const scaled = { min: Math.floor(this.range.min / step), max: Math.ceil(this.range.max / step) };
      const nTicks = scaled.max - scaled.min + 1;
      const ticks = [...Array(nTicks).keys()].map(i => ((i + scaled.min) * step));
      return ticks;
    },
    range() {
      const allValues = Object.values(this.results).map(results =>
        [
          ...results.student.map(result => result.value),
          ...results.group.map(result => result.value),
        ]
      ).flat(Infinity);
      return {
        min: Math.min(...allValues, -1.0),
        max: Math.max(...allValues, 1.0),
      };
    },
    hasResults() {
      return Object.keys(this.results).length !== 0;
    },
  },
  methods: {
    sortResults(r1, r2) {
      if (r1.year === r2.year) {
        return (r1.month === r2.month ? - r1.indicator_key.localeCompare(r2.indicator_key) : r1.month - r2.month);
      }
      return r1.year - r2.year;
    },
    chartRows(subjectKey) {
      const dateFormatOptions = { year: 'numeric', month: 'short' };
      return this.results[subjectKey].student.map((e, i) => [
        `${e.indicator_label.split(' ')[0]}, ${new Date(e.year, e.month, 0).toLocaleString('es-ES', dateFormatOptions)}`,
        e.value,
        this.results[subjectKey].group[i].value,
      ]);
    },
    latestValue(subjectKey) {
      return this.results[subjectKey].student.slice(-1)[0].value;
    },
    relativeValue(subjectKey) {
      return this.latestValue(subjectKey) - this.results[subjectKey].group.slice(-1)[0].value;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.no-results-message {
  text-align: center;
}

.historic-grade-title {
  text-align: right;
  font-weight: bold;
  margin-left: 10px;
}
.column{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.full-width {
  width: 100%;
  text-align: center;
}
.grey-box {
  background-color: #FBFBFE;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 10px !important;
}
</style>
