<template>
  <div :class="$style.settingsGrid">
    <div :class="[$style.settingsElement, $style.name]">
      <div>
        <div><strong>Nombre</strong></div>
        <div>{{ currentUser.name }}</div>
      </div>
    </div>
    <div :class="[$style.settingsElement, $style.email]">
      <div><strong>Correo electrónico</strong></div>
      <div>{{ currentUser.email }}</div>
    </div>
    <div :class="[$style.settingsElement, $style.phone]">
      <div><strong>Teléfono</strong></div>
      <optional-input
        :value="currentUser.phone"
        :loading="loading.phone"
        edit-tooltip-text="Editar teléfono"
        @update="(value) => updateUserInfo('phone', value)"/>
    </div>
    <div :class="[$style.settingsElement, $style.account]">
      <div><strong>Tipo de cuenta</strong></div>
      <div>{{ subscriptionType }}</div>
    </div>
    <div :class="[$style.settingsElement, $style.profileImg]">
      <div><strong>Imagen de perfil</strong></div>
      <form
        :class="$style.avatarContainer"
        @submit.prevent="uploadImage">
        <div :class="$style.accountAvatar">
          <img
            id="account-avatar"
            ref="account-avatar"
            :src="currentUser.original_avatar">
        </div>
        <div :class="$style.avatarImageSelector">
          <input
            id="avatar"
            :class="$style.avatarInput"
            type="file"
            accept="image/*"
            name="avatar"
            @change="onFileChanged">
          <label
            :class="$style.avatarLabel"
            for="avatar">
            Seleccionar una imagen
          </label>
        </div>
        <div>
          <button
            :disabled="disableSubmit || submitting"
            class="rdr-btn rdr-btn--small rdr-btn--primary"
            type="submit">
            <span v-if="submitting">Subiendo</span>
            <span v-if="!submitting">Subir</span>
          </button>
        </div>
        <span v-if="flashMessage">{{ flashMessage }}</span>
      </form>
    </div>
    <div :class="[$style.settingsElement, $style.settingsButton]">
      <button
        v-if="buttonName.length > 0"
        :class="['rdr-btn', 'rdr-btn--primary', 'school__platforms-button']"
        @click="buttonMethod">
        {{ buttonName }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import AuthApi from '../../api/auth';
import AuthMixin from '../../mixins/auth';
import Permission from '../../mixins/permission';
import OptionalInput from '../../components/optional-input.vue';

export default {
  name: 'UserInfo',
  components: { OptionalInput },
  mixins: [AuthMixin, Permission],
  props: {
    buttonName: {
      type: String,
      default: '',
    },
    buttonMethod: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      loading: {
        phone: 0,
      },
      error: false,
      flashMessage: '',
      imageFile: null,
      submitting: false,
    };
  },
  computed: {
    subscriptionType() {
      if (!this.currentUser) return 'Cargando..';
      if (!this.isFreeUser) return 'Pro';

      return 'Gratuita';
    },
    disableSubmit() { return !this.imageFile; },
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'auth/fetchUserInfo',
      showFeedbackDialog: 'ui/showFeedbackDialog',
    }),
    ...mapMutations({
      setUserInfo: 'auth/SET_USER_INFO',
    }),
    onFileChanged(event) {
      if (event.target.files.length > 0) {
        this.imageFile = event.target.files[0];
        this.imagePreview();
      }
    },
    imagePreview() {
      if (this.imageFile) {
        const fr = new FileReader();
        fr.onload = () => {
          this.$refs['account-avatar'].src = fr.result;
        };
        fr.readAsDataURL(this.imageFile);

        return true;
      }

      return false;
    },
    async uploadImage() {
      await this.fetchChangeUserAvatar();
      await this.fetchGetUserInfo();
    },
    async fetchChangeUserAvatar() {
      this.submitting = true;
      try {
        const response = await AuthApi.updateUserInfo(this.currentUser.id, { avatar: this.imageFile });
        if (response.ok) {
          this.flashMessage = 'Tu foto ha sido actualizada.';
        }
        this.imageFile = null;
      } catch (_) {
        this.flashMessage = 'Ocurrió un error.';
      } finally {
        this.submitting = false;
      }
    },
    async fetchGetUserInfo() {
      this.submitting = true;
      try {
        const { body } = await AuthApi.getUserInfo();
        this.setUserInfo(body.user);
      } catch (_) {
        this.error = true;
      } finally {
        this.submitting = false;
      }
    },
    async updateUserInfo(attribute, value) {
      /* eslint-disable no-magic-numbers */
      this.loading[attribute]++;
      try {
        const response = await AuthApi.updateUserInfo(this.currentUser.id, { [attribute]: value });
        if (response.status === 200) await this.fetchUserInfo();
      } catch (err) {
        if (err.status === 401) this.showFeedbackDialog('No tienes los permisos para editar esto.');
        else this.showFeedbackDialog('Ocurrió un error.');
      } finally {
        this.loading[attribute]--;
      }
      /* eslint-enable no-magic-numbers */
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.account-avatar {
   img {
    width: auto;
    height: 150px;
    max-width: 500px;
  }
}

.avatar-input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  z-index: -1;
}

.avatar-label {
  height: 55px;
  padding: 2px 30px;
  cursor: pointer;
  border: 2px solid #b5b5b5;
  border-radius: 55px;
  outline: none;
  background-color: #fff;
  font-family: $accent-font;
  font-size: 13px;
  font-weight: 300;
}

.avatar-container {
  margin: 10px;
  text-align: center;
  .account-avatar {
    margin-bottom: .5rem;
  }
  .avatar-image-selector {
    margin-bottom: .3rem;
  }
}

.show-mode {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

  }
}
.left {
  width: 50%;
}
.rigth {
  width: 50%;
}

.bottom-center {
  display: flex;
  justify-content: center;
  direction: column;
  width: 100%;
}

.settings-grid {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
}

.settings-element {
  line-height: 2em;
  margin-bottom: 1rem;
}

.settings-button {
  display: flex;
  button {
    height: auto;
    max-height: 50px;
    flex-basis: 100%;
  }
}

@media (min-width: $mobile-breakpoint) {
  .profile-img {
    grid-column: 2 / -1;
    grid-row: 1 / span 3;
    align-self: start;
  }

  .settings-button {
    grid-column: span 2;
    justify-self: center;
  }

  .settings-grid {
    width: 100%;
    padding: 25px 30px;
    margin: 0 10%;
    display: grid;
    grid-template-columns: minmax(200px, 1fr) 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
    align-items: center;
  }

  .settings-element {
    margin-bottom: 0;
    align-self: start;
  }
}

</style>
