<template>
  <div class="report">
    <alert-info :text-alert="reportTitle" />
    <div :class="[$style.report, 'rdr-view']">

      <year-warning-bar v-if="hasGroupReportPermission" />

      <report-options
        :no-options.sync="noOptions"
        report-key="group_report_summary" />

      <!-- <div :class="[$style.options, 'hide-print']">
        <school-attachment-download
          v-if="chosenGroup && chosenSchool"
          :key-params="attachmentDownloadKeyParams"
          :reports-params="[
            {
              reportKey: 'group-report',
              label: 'Descargar Ficha Curso',
            },
            {
              reportKey: 'all-student-reports',
              label: 'Descargar Ficha Alumno',
            },
            {
              reportKey: 'alertas-de-inicio-de-ano-escolar',
              label: 'Descargar Alertas de Inicio de Año',
            },
          ]"
        />
      </div> -->

      <paywall
        :permitted="hasGroupReportPermission"
        restricted>
        <div
          v-if="noOptions"
          :class="$style.noOptions">
          <img src="/noResultados.svg">
        </div>
        <div
          v-else
          :class="$style.reportContainer">
          <tabs
            v-model="currentTab"
            :tabs="tabs"/>
          <summary-view
            v-if="currentTab === 'summary'"
            :sticky-top-offset="isCurrentSchoolYearChosen || isMobile ? 0 : 40"
          />
          <students-view
            v-if="currentTab === 'students'"
            :loading.sync="loading"
            :chosen-school="chosenSchoolId"
            :chosen-group-key="chosenGroupKey"
          />
          <attendance-view
            v-if="currentTab === 'attendance'"
            :loading.sync="loading"
            :chosen-school="chosenSchoolId"
            :chosen-groups="chosenGroup"
          />
          <grades-view
            v-if="currentTab === 'grades'"
            :loading.sync="loading"
            :chosen-school="chosenSchoolId"
            :chosen-group-key="chosenGroupKey"
          />
        </div>
      </paywall>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import DisplayMixin from '../../mixins/display';
import AttendanceView from './attendance.vue';
import GradesView from './grades.vue';
import SummaryView from './summary.vue';
import StudentsView from './students.vue';
import Tabs from '../../components/tabs.vue';
import GoBackLink from '../../components/go-back-link.vue';
import SchoolAttachmentDownload from '../../components/school-attachment-download.vue';
import Paywall from '../../components/paywall.vue';
import YearWarningBar from '../../components/utils/year-warning-bar.vue';
import ReportOptions from '../../components/report-options';
import schoolYear from '../../utils/years';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'GroupReportView',
  components: {
    AttendanceView,
    GradesView,
    StudentsView,
    SummaryView,
    Tabs,
    GoBackLink,
    Paywall,
    ReportOptions,
    SchoolAttachmentDownload,
    YearWarningBar,
    AlertInfo,
  },
  mixins: [PermissionMixin, DisplayMixin],
  data() {
    return {
      reportTitle: 'Acá se muestran los resultados promedio del curso elegido en asistencia y calificaciones a lo largo del año actual.',
      error: false,
      currentTab: 'summary',
      noOptions: false,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenYear', 'chosenSchoolId', 'chosenGroupKey']),
    ...mapGetters('options', ['chosenSchool', 'chosenGroup']),
    attachmentDownloadKeyParams() {
      return {
        chosenSchoolId: this.chosenSchoolId,
        chosenSchoolRbd: this.chosenSchool.rbd,
        chosenYear: this.chosenYear,
        chosenGroupKey: this.chosenGroupKey,
        chosenGroupShortName: this.chosenGroup.short_name,
      };
    },
    tabs() {
      return [
        { key: 'summary', text: 'Resumen', icon: '' },
        { key: 'attendance', text: 'Asistencia', icon: '' },
        // { key: 'grades', text: 'Rojos por asignatura', icon: '' },
        // { key: 'students', text: 'Detalle Alumnos', icon: '' },
      ];
    },
    isCurrentSchoolYearChosen() {
      return this.chosenYear === schoolYear();
    },
  },
};
</script>

<style lang="scss" module>
.no-options {
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  width: 100%;
}
</style>
