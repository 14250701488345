<template>
  <div id="dth-contenReport" class="report">
    <alert-info :text-alert="title" :icon-viwe="false"/>
    <div class="rdr-view">
      <rdr-results-header
        year-picker
        hide-school-picker>
      </rdr-results-header>
      <router-view/>
    </div>
  </div>
</template>

<script>
import ResultsHeader from '../../components/results/results-header.vue';
import AuthMixin from '../../mixins/auth';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'ComparativeAttendanceView',
  components: {
    'rdr-results-header': ResultsHeader,
    AlertInfo,
  },
  mixins: [AuthMixin],
  data() {
    return {
      title: 'Porcentaje de Asistencia (en base a días) y Ausentismo Crónico (porcentaje de estudiantes en esa condición).',
    }
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

</style>
