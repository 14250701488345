import Vue from 'vue';

export default {
  getSchoolAttachment(params) {
    return Vue.http.get('/api/v1/school_attachments', {
      params,
    });
  },
  generateSchoolAttachmentPdf(params) {
    return Vue.http.post('/api/v1/school_attachments/generate-pdf', {
      ...params,
    });
  },
};
