<template>
  <input
    v-model="content"
    :class="inputClass"
    :style="style"
    @change="valueUpdate">
</template>

<script>
export default {
  name: 'InputParser',
  model: {
    prop: 'initialValue',
    event: 'blur',
  },
  props: {
    initialValue: {
      type: [String, Number],
      default: null,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputClass: {
      type: String,
      default: null,
    },
    editedColor: {
      type: String,
      default: 'yellow',
    },
    customParser: {
      type: Function,
      default: () => null,
    },
    customDisplayParser: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      content: null,
      previousContent: null,
    };
  },
  computed: {
    style() {
      return {
        'background-color': this.content === this.previousContent ? 'inherit' : this.editedColor };
    },
  },
  mounted() {
    const currentValue = this.customDisplayParser(this.initialValue) || this.initialValue;
    this.content = currentValue;
    this.previousContent = currentValue;
  },
  methods: {
    valueUpdate(event) {
      const value = event.target.value;
      const parsedValue = this.customParser(value);
      this.content = parsedValue;
      this.$emit('blur', this.content);
    },
  },
};
</script>

<style lang="scss" module>
</style>
