<template>
  <el-dialog
    :visible.sync="show"
    class="radar-dialog upcoming-dialog">
    <div v-if="feature">
      <div class="radar-dialog__title"><span v-html="feature.text"/></div>
      <div
        v-if="feature.showSubtitle"
        class="radar-dialog__subtitle subitile">
        ¿Quieres acceder a más indicadores y más colegios de comparación? <br>
        ¿Quieres tomar acciones de mejora en base a evidencia? <br>
        ¿Quieres hacer seguimiento de tu Plan de Mejoramiento Escolar? <br>
        ¿Quieres tener un diagnóstico en detalle a nivel de curso?<br>
        <a
          href="http://radarpro.incremental.cl/"
          target="_blank">¡Usa Radar Pro!</a>
      </div>
      <div
        v-if="!feature.shortText"
        class="radar-dialog__subtitle">
        Vamos a ir actualizando esta página todas las semanas.<br>
        Ingresa tu correo y te avisaremos cuando esta función esté lista.
      </div>
      <div
        v-if="feature.shortText"
        class="radar-dialog__subtitle">
        Ingresa tu correo y te contactaremos.
      </div>

      <form
        v-if="!submitted"
        class="upcoming-dialog__form"
        @submit.prevent="submitContact">
        <input
          v-model="email"
          class="upcoming-dialog__email rdr-input rdr-input--block"
          type="email"
          placeholder="Tu correo electrónico"
          required >
        <v-ener-select
          :options="relations"
          class="rdr-select relation--picker__select"
          required
          placeholder="¿Qué relación tienes con el Colegio?"
          @input="onSelectChange($event)"/>
        <button
          :disabled="submitting"
          class="rdr-btn rdr-btn--block rdr-btn--large rdr-btn--primary"
          type="submit">
          <span v-if="!submitting">Contáctenme</span>
          <span v-if="submitting">Enviando...</span>
        </button>
      </form>

      <div
        v-if="submitted"
        class="upcoming-dialog__form upcoming-dialog__form--submitted">
        <span>¡Hemos recibido tu solicitud!<br>Te contactaremos a la brevedad.</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { Dialog } from 'element-ui';
import ContactApi from '../../api/contact';

export default {
  components: {
    'el-dialog': Dialog,
  },
  data() {
    return {
      submitting: false,
      relations: [
        { key: 'sostenedor', label: 'Sostenedor' },
        { key: 'director', label: 'Director' },
        { key: 'equipoDirectivo', label: 'Integrante Equipo Directivo' },
        { key: 'profesor', label: 'Profesor' },
        { key: 'apoderado', label: 'Apoderado' },
      ],
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolIds']),
    submitted() {
      return this.feature.submitted;
    },
    featureKey() {
      return this.$store.state.ui.dialogs.upcoming.feature;
    },
    feature() {
      return this.$store.state.ui.dialogs.upcoming.features[this.featureKey];
    },
    email: {
      get() {
        return this.$store.state.ui.contact.email;
      },
      set(value) {
        this.$store.commit({
          type: 'ui/updateContactData',
          key: 'email',
          value,
        });
      },
    },
    show: {
      get() { return this.$store.state.ui.dialogs.upcoming.open; },
      set(value) { this.$store.commit({ type: 'ui/SET_DIALOG_STATE', dialog: 'upcoming', state: value }); },
    },
  },
  methods: {
    onSelectChange(event) {
      this.selected = event.key;
      this.$store.commit({
        type: 'ui/updateContactData',
        key: 'relation',
        value: event.key,
      });
    },
    submitContact() {
      const currentSchool = this.chosenSchoolIds.shift();
      this.submitting = true;

      const args = {
        contactType: this.featureKey,
        email: this.email,
        currentSchool,
        otherSchools: this.chosenSchoolIds,
        relation: this.selected,
        name: '',
        phone: '',
      };

      ContactApi.submit(args).then(() => {
        this.submitting = false;
        this.$store.commit('ui/setUpcomingDialogFormSubmitted', this.featureKey);
        this.$ga.event({
          eventCategory: 'FeatureContactForm',
          eventAction: 'SendContactInfo',
          eventLabel: 'Login',
          eventValue: this.featureKey,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";

  .radar-dialog {
    a {
      color: $primary-color;
      text-decoration: none;
      border-bottom: 1px dashed;
    }

    .el-dialog {
      background-color: #FCFCFC;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 90%;
      }
    }

    .subitile{
      margin-bottom: 20px;
    }

    .el-dialog__body {
      padding: 60px 30px;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
      text-align: center;
    }

    &__subtitle {
      font-size: 18px;
      text-align: center;
    }
  }

  .upcoming-dialog {
    &__form {
      width: 380px;
      margin: 0 auto;
      margin-top: 45px;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 100%;
      }

      &--submitted {
        margin-top: 25px;
        font-size: 18px;
      }
    }

    &__email {
      margin-bottom: 15px;
    }
    .rdr-btn {
      margin-top: 35px;
    }
  }

  .open-indicator {
    right: 32px;
    bottom: 20px;

    &:before {
      display: none;
    }

    &:after {
      color: #4266f7 !important;
      font-family: 'Material Icons';
      font-size: 25px;
      font-style: normal;
      content: 'keyboard_arrow_down' !important;
    }
  }

</style>
