export default [
  '#4266F7',
  '#A0A5B8',
  '#8EE6B1',
  '#FED277',
  '#FF9D41',
  '#F3FC1B',
  '#24D5BD',
  '#293657',
  '#FF7070',
  '#CD8CE6',
  '#932400',
  '#926600',
  '#669100',
  '#029100',
  '#00914a',
  '#007b91',
  '#001891',
  '#4f0091',
  '#91008e',
  '#91001a',
  '#328332',
  '#492A7C',
  '#A48D3E',
  '#BC8618',
  '#639D23',
  '#7A1C6B',
  '#243C74',
  '#B2B340',
  '#86306E',
  '#B48040',
];
