<template>
  <rdr-tooltip class="hide-print">
    <div
      :class="$style.infoIconText"
      v-html="text"/>
    <i
      slot="reference"
      :class="[$style.infoIcon, 'material-icons']"
      :style="iconColor">
      {{ icon }}
    </i>
  </rdr-tooltip>
</template>

<script>
export default {
  name: 'InfoIcon',
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'info',
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconColor() { return this.color ? { color: this.color } : {}; },
  },
};
</script>

<style lang="scss" module>
.info-icon {
  user-select: none;
  outline: none;
  cursor: help;

  &-text {
    white-space: pre-line;
    word-break: break-word;
  }
}
</style>
