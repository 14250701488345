function repetitionRiskValueToMessage(rr) {
  switch (rr) {
  case 1: return 'R1: Una asignatura roja y prom. final bajo 4,5';
  case 2: return 'R2: Dos asignaturas rojas y prom. final bajo 5,0'; // eslint-disable-line no-magic-numbers
  case 3: return 'R3: Asistencia bajo 85%'; // eslint-disable-line no-magic-numbers
  case 4: return 'R4: Leng. o Mat. rojo y prom. final bajo 5,5'; // eslint-disable-line no-magic-numbers
  case 5: return 'R5: Tres o más asignaturas rojas'; // eslint-disable-line no-magic-numbers
  case 0: return 'No';
  default: return 'Falta información para calcular';
  }
}

function compareRepetitionRisks(rra, rrb) {
  const a = rra === null ? 0.5 : rra; // eslint-disable-line no-magic-numbers
  const b = rrb === null ? 0.5 : rrb; // eslint-disable-line no-magic-numbers
  return b - a;
}

export { repetitionRiskValueToMessage, compareRepetitionRisks };
