<template>
  <div class="password-settings settings__block settings__block--emphasis">
    <div class="settings__block-element">
      <form @submit.prevent="submitUserPassword()">
        <strong>Contraseña actual</strong>
        <div class="password-settings__input-wrapper">
          <input
            v-model="currentPassword"
            class="login-dialog__input rdr-input"
            type="password"
            placeholder="Ingrese su contraseña actual"
          >
          <i
            v-show="currentPasswordStatus === 'correct'"
            class="material-icons green">check_circle</i>
          <i
            v-show="currentPasswordStatus === 'incorrect'"
            class="material-icons red">cancel</i>
          <i
            v-show="currentPasswordStatus === 'checking'"
            class="material-icons grey">autorenew</i>
        </div>
        <strong>Contraseña nueva</strong>
        <div class="password-settings__input-wrapper">
          <input
            v-model="newPassword"
            class="login-dialog__input rdr-input"
            type="password"
            placeholder="Ingrese su nueva contraseña"
          >
          <i
            v-show="newPasswordStatus === 'correct'"
            class="material-icons green">check_circle</i>
          <i
            v-show="newPasswordStatus === 'incorrect'"
            class="material-icons red">cancel</i>
        </div>
        <strong>Reingrese contraseña nueva</strong>
        <div class="password-settings__input-wrapper">
          <input
            v-model="newPasswordConfirmation"
            class="login-dialog__input rdr-input"
            type="password"
            placeholder="Reingrese su nueva contraseña"
          >
          <i
            v-show="newPasswordConfirmationStatus === 'correct'"
            class="material-icons green"
          >check_circle</i>
          <i
            v-show="newPasswordConfirmationStatus === 'incorrect'"
            class="material-icons red"
          >cancel</i>
        </div>
        <button
          :disabled="submitting || !submitable"
          class="rdr-btn rdr-btn--primary"
          type="submit"
        >
          <span v-show="!submitting">Cambiar contraseña</span>
          <span v-show="submitting">Enviando...</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-magic-numbers */
import _ from 'lodash';
import AuthApi from '../../api/auth';
import AuthMixin from '../../mixins/auth';

export default {
  name: 'PasswordSettings',
  mixins: [AuthMixin],
  data() {
    return {
      submitting: false,
      currentPassword: '',
      currentPasswordStatus: null,
      newPassword: '',
      newPasswordStatus: null,
      newPasswordConfirmation: '',
      newPasswordConfirmationStatus: null,
    };
  },
  computed: {
    submitable() {
      return (
        this.currentPasswordStatus === 'correct' &&
        this.newPasswordStatus === 'correct' &&
        this.newPasswordConfirmationStatus === 'correct'
      );
    },
  },
  watch: {
    currentPassword(newValue) {
      if (newValue.length > 5) {
        this.currentPasswordStatus = 'checking';
        this.checkCurrentPassword();
      } else {
        this.currentPasswordStatus = null;
      }
    },
    newPassword(newValue) {
      if (newValue.length === 0) {
        this.newPasswordStatus = null;
      } else if (newValue.length < 6) {
        this.newPasswordStatus = 'incorrect';
      } else {
        this.newPasswordStatus = 'correct';
      }
    },
    newPasswordConfirmation(newValue) {
      if (this.newPasswordStatus === 'correct') {
        if (newValue === this.newPassword) {
          this.newPasswordConfirmationStatus = 'correct';
        } else if (newValue.length > 0) {
          this.newPasswordConfirmationStatus = 'incorrect';
        } else {
          this.newPasswordConfirmationStatus = null;
        }
      } else {
        this.newPasswordConfirmationStatus = null;
      }
    },
  },
  methods: {
    checkCurrentPassword: _.debounce(function () {
      AuthApi.checkUserPassword(this.currentUser.id, this.currentPassword)
        .then(() => {
          this.currentPasswordStatus = 'correct';
        })
        .catch(() => {
          this.currentPasswordStatus = 'incorrect';
        });
    }, 600),
    submitUserPassword() {
      this.submitting = true;
      AuthApi.changeUserPassword(
        this.currentUser.id,
        this.currentPassword,
        this.newPassword
      )
        .then(() => {
          this.openDialog('success');
          this.submitting = false;
          this.currentPassword = '';
          this.newPassword = '';
          this.newPasswordConfirmation = '';
        })
        .catch(err => {
          switch (err.status) {
          case 400:
            this.openDialog('badRequest');
            break;
          case 401:
            this.openDialog('unauthorized');
            break;
          case 403:
            this.openDialog('forbidden');
            break;
          case 404:
            this.openDialog('notFound');
            break;
          default:
            this.openDialog('error');
          }
          this.submitting = false;
          this.currentPassword = '';
        });
    },
    openDialog(feature) {
      this.$store.dispatch('ui/openDialogWithFeature', {
        dialog: 'passwordFeedback',
        feature,
      });
    },
  },
};
</script>

<style lang="scss">
.password-settings {
  form {
    > * {
      display: block;
      width: 100%;

      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    input {
      width: 100%;
      height: 45px;
    }

    button {
      height: 35px;
    }
  }

  &__input-wrapper {
    position: relative;

    i {
      font-size: 28px;
      line-height: inherit;
      position: absolute;
      right: 10px;
      top: 6px;

      &.green {
        color: rgb(135, 234, 187);
      }

      &.red {
        color: red;
      }

      &.grey {
        color: grey;
      }
    }
  }
}
</style>
