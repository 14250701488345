<template>
  <div v-if="newStyle">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      :class="[
        'btn-verticalmenu',
        'rdr-btn--menuconfig',
        { 'btn-active': value === tab.key },
      ]"
      @click.prevent="$emit('input', tab.key)">
      {{ tab.text }}
    </button>
  </div>
  <div v-else :class="$style.tabs">
    <div
      v-if="currentTab"
      :class="[$style.currentTab, 'print-only']">
      <span>{{ links ? currentTab.name : currentTab.text }}</span>
    </div>
    <div
      :class="[$style.tabsOuterWrapper, 'hide-print']">
      <div
        v-if="tabs.length > 0"
        :class="[$style.leftBorder, 'hide-print']">
        <span />
      </div>
        <div
          v-if="links"
          :class="[$style.tabsWrapper]">
          <router-link
            v-for="tab in tabs"
            :key="tab.name"
            :to="tab.link"
            :class="[$style.tab, 'hide-print', {[$style.active]: match(tab.link.name)}]"
            :style="tabStyle">
            <i :class="[$style.materialIcons, 'material-icons']">{{ tab.icon }}</i>
            <div> {{ tab.name }}</div>
          </router-link>
        </div>
        <div
          v-else
          :class="[$style.tabsWrapper]">
          <a
            v-for="(tab, index) in tabs"
            :key="index"
            :class="[
              $style.tab,
              'hide-print',
              { [$style.active]: value === tab.key },
            ]"
            :title="tab.text"
            @click.prevent="$emit('input', tab.key)">
            <i :class="[$style.materialIcons, 'material-icons']">{{ tab.icon }}</i>
            <div style="overflow: hidden;">{{ tab.text }}</div>
          </a>
        </div>
      <div
        v-if="tabs.length > 0"
        :class="[$style.rightBorder, 'hide-print']">
        <span />
      </div>
    </div>
  </div>
</template>

<script>
import LinksMixin from '../mixins/links';

export default {
  name: 'Tabs',
  mixins: [LinksMixin],
  props: {
    value: {
      type: [String, Object],
      default: '',
    },
    links: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    tabWidth: {
      type: Number,
      default: null,
    },
    newStyle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentTab() {
      if (this.links) return this.tabs.find(t => this.routeMatches(t.link.name));
      return this.tabs.find(t => t.key === this.value);
    },
    tabStyle() {
      return {
        ...(this.tabWidth ? { width: `${this.tabWidth}px` } : {}),
      };
    },
  },
  methods: {
    match(name) {
      return this.$route.name === name;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.tabs {
  display: flex;
  justify-content: start;
  margin: 0 auto 10px auto;
  @media only screen and (max-width: $mobile-breakpoint) {
    margin: 0 auto 4px auto;
    padding: 0;
  }
}

.current-tab {
  font-size: 28px;
  font-weight: 200;
}

.tabs-wrapper {
  display: flex;
  justify-content: center;
}

.tabs-outer-wrapper {
  // display: flex;
  display: block;
  justify-content: stretch;
  margin: 10px auto 0 auto;
  width: 100%;
}

.left-border {
  & > span {
    margin-right: auto;
  }
}

.right-border {
  & > span {
    margin-left: auto;
  }
}

.tab {
  // flex: 1;
  color: $colorCilegium;
  border: 1px solid $colorCilegium;
  font-family: 'GT Haptik', sans-serif;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 20px;
  padding: 6px 15px;
  gap: 5px;
  min-width: 160px;
  width: auto;
  height: 28px;

  .material-icons {
    font-size: 20px;
  }

  span {
    flex: 1;
  }
  div {
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    width: auto;
    padding: 0px 10px;
    span {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.active {
  color: $colorTextTabs;
  border-bottom: 1px solid $colorCilegium;
  background: $colorCilegium;
  border-left: 1px solid $colorCilegium;
  border-right: 1px solid $colorCilegium;
}
</style>
