/**
 * Constants for mutatnions
 * @author Cristian Moreno <khriztian@platan.us>
 */

// UI Module
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const HIDE_MENU = 'HIDE_MENU';
export const DISPLAY_LOGIN_DIALOG = 'DISPLAY_LOGIN_DIALOG';
export const DISPLAY_SIGN_UP_DIALOG = 'DISPLAY_SIGN_UP_DIALOG';
export const DISPLAY_FREE_OFFER_DIALOG = 'DISPLAY_FREE_OFFER_DIALOG';
export const DISPLAY_NEW_SIMCE_NOTIFICATION_DIALOG = 'DISPLAY_NEW_SIMCE_NOTIFICATION_DIALOG';
export const SET_DIALOG_DATA = 'SET_DIALOG_DATA';
export const SET_DIALOG_STATE = 'SET_DIALOG_STATE';
// Auth Module
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const UNAUTHORIZED = 'UNAUTHORIZED';
// Results PRO
export const FETCH = 'FETCH';
export const SET_SCHOOLS = 'SET_SCHOOLS';
export const SET_CHOSEN_SCHOOL = 'SET_CHOSEN_SCHOOL';
export const SET_CHOSEN_EVALUATION = 'SET_CHOSEN_EVALUATION';
