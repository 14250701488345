<template>
  <div class="contenPB">
    <loading-overlay v-if="loading"/>
        <iframe
          id="iframe"
          src="https://radar-jdtrujillom.pythonanywhere.com/">
        </iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToLocalTimeMixin from '../mixins/to-local-time';
import SchoolsApi from '../api/schools';

export default {
  name: 'ExternalEvaluationsView',
  mixins: [ToLocalTimeMixin],
  data() {
    return {
      loadIframe: false,
      urlFinancial: '',
      widthIFRM: 0,
      heightIFRM: 0,
      schools: [],
      loading: 0,
      searchQuery: '',
    };
  },
  computed: {},
  watch: {
    menuHover(newMenuHover, oldMenuHover) {
      if (this.urlFinancial !== '') {
        this.renderLoadIframe(this.urlFinancial);
      }
    }
  },
  mounted() {
    this.fetchUserSchools();
  },
  methods: {
    async fetchUserSchools() {
      try {
        this.loading++;
        let { body: { schools } } = await SchoolsApi.getUserSchoolFinantials();
        this.schools = schools;
      } catch (_) {
      } finally {
        this.loading--;
      }
    },

    renderLoadIframe(data) {
      this.loading = 1;
      this.urlFinancial = data;
      this.heightIFRM = window.innerHeight - 90;
      this.menuHover
        ? this.widthIFRM = window.innerWidth - 60
        : this.widthIFRM = window.innerWidth - 257
      setTimeout(() => {
        this.loadIframe = true;
        setTimeout(() => {
          this.loading = 0;
        }, 500);
      }, 500);
    }
  },
};
</script>

<style lang="scss">
  @import "../../styles/app/variables";
  #iframe {
    border: none;
    width: 100%;
    height: 90vh;
}

</style>
