<template>
  <div :class="$style.histogram">
    <transition name="fade">
      <loading-overlay v-if="loading"/>
    </transition>
    <div ref="chart"/>
  </div>
</template>

<script>
export default {
  name: 'Histogram',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chart: null,
      loading: true,
    };
  },
  watch: {
    data: 'draw',
  },
  created() {
    google.charts.load('current', { 'packages': ['corechart'], 'language': 'es' });
    google.charts.setOnLoadCallback(this.onLibraryLoad);
  },
  methods: {
    onLibraryLoad() {
      this.chart = new google.visualization.Histogram(this.$refs.chart);
      this.draw();
    },
    draw() {
      this.loading = true;
      if (!this.chart) return;
      const dataTable = google.visualization.arrayToDataTable(this.data);
      this.chart.draw(dataTable, this.options);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" module>
.histogram {
  position: relative;
  min-height: 40px;
}

.loading {
   position: absolute !important;
   margin-top: 0 !important;
 }
</style>
