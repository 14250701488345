<template>
  <div
    id="downTable"
    :ref="chartContainerRef"
    :class="$style.lastYearComparison"
  >
    <paywall
      :permitted="hasPermission('attendance')"
      :free="isFreeUser"
      restricted>
      <template v-if="!override && chartRows.length > 0">
        <div :class="$style.selectorWrapper">
          <div :class="$style.selectorWithLabel">
            <!-- <span :class="$style.selectorLabel">Agrupar por:</span> -->
            <label class="labelInput">Agrupar por:</label><br>
            <rdr-picker
              v-model="dataAggregationType"
              :options="aggregationTypes"
              label-property="label"
              picker-style="round"/>
          </div>
          <div :class="$style.selectorWithLabel">
            <!-- <span :class="$style.selectorLabel">Mes:</span> -->
            <label class="labelInput">Mes:</label><br>
            {{ $style.picker }}
            <rdr-picker
              v-model="dataChosenMonth"
              :options="monthOptions"
              label-property="label"
              picker-style="round"/>
          </div>
        </div>
        <h3
          v-if="!override">
          {{ labelText }} {{ dataAggregationType.title }}
        </h3>
      </template>
      <div
        v-if="chartRows.length > 0"
        style="text-align:right;margin-top: -40px;"
      >
        <!-- <button
          :class="['rdr-btn', 'rdr-btn--small', 'rdr-btn--primary', 'hide-print']"
          @click="printChart">
          <i class="rdr-btn__icon material-icons">print</i>
          <span>Imprimir</span>
        </button> -->
      </div>

      <transition
        :enter-class="$style.faded"
        :leave-to-class="$style.faded"
        :enter-active-class="$style.fadeTransition"
        :leave-active-class="$style.fadeTransition"
        name="fade"
        mode="out-in">
        <loading-overlay v-if="loading"/>

        <div
          v-else-if="chartRows.length > 0"
          :key="noTransitions && '' || 'transition-1'"
          :class="$style.chartContainer">

          <template v-if="renderChart">
            <div :class="$style.contenchartColum" ref="contenchartColum">
              <column-chart
                :columns="years"
                :rows="chartRows"
                :colors="chartProps.colors"
                :animation-options="noTransitions && {} || {
                  startup: true,
                  easing: 'out',
                  duration: 500,
                }"
                :annotation-options="{
                  alwaysOutside: false,
                  style: 'point',
                  visibleInLegend: true,
                  textStyle: {
                    color: '#777',
                    fontSize: 9,
                  },
                }"
                :bar-options="{
                  groupWidth: '85%',
                  // width: 600,
                  // textPosition: 'none',
                  // height: 200
                }"
                :v-axis-options="{
                  viewWindow: chartViewWindow,
                  format: '#\'%\'',
                  textPosition: 'out',
                }"
                :size="chartSize"
                :has-custom-colors="aggregationType.key === 'course' ? true : false"
                custom-tooltip
                :has-annotations="true"
              />
            </div>
          </template>
          <div
            v-if="aggregationType.key !== 'course'"
            :class="$style.chartLabels">
            <div
              v-for="(year, index) in years"
              :class="$style.chartLabel"
              :key="index">
              <div
                :class="$style.chartLabelColor"
                :style="{ backgroundColor: chartProps.colors[index] }"
              />
              <span
                :class="$style.chartLabelText">
                {{ chosenMonth.label | lowerCase }} {{ year }}
              </span>
            </div>
          </div>
          <div
            v-else
            :class="$style.chartLabels">
            <div
              :class="$style.chartLabel">
              <div
                :class="$style.chartLabelColor"
                :style="chartProps.reportKey === 'absenteeism-last-year-comparison' ?
              { backgroundColor: chartProps.minColor } : { backgroundColor: chartProps.maxColor }"/>
              <span
                :class="$style.chartLabelText">
                Curso destacado
              </span>
            </div>
            <div
              :class="$style.chartLabel">
              <div
                :class="$style.chartLabelColor"
                :style="chartProps.reportKey === 'absenteeism-last-year-comparison' ?
              { backgroundColor: chartProps.maxColor } : { backgroundColor: chartProps.minColor }"/>
              <span
                :class="$style.chartLabelText">
                Curso descendido
              </span>
            </div>
          </div>
        </div>


        <div
          v-else-if="!override"
          :key="noTransitions && '' || 'transition-2'"
          :class="$style.noResults">
          <img src="/noResultados.svg">
        </div>

      </transition>
    </paywall>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { MONTHS } from '../../utils/months';
import ResultsApi from '../../api/results';
import PrintReactiveChartMixin from '../../mixins/print-reactive-chart';
import StageGroupsMixin from '../../mixins/stage-groups';
import RdrPicker from '../../components/utils/picker.vue';
import ColumnChart from '../../components/column-chart.vue';
import Paywall from '../../components/paywall.vue';
import PermissionMixin from '../../mixins/permission';

export default {
  name: 'AttendanceBarChart',
  components: { RdrPicker, ColumnChart, Paywall },
  mixins: [PrintReactiveChartMixin, StageGroupsMixin, PermissionMixin],
  props: {
    override: {
      type: Object,
      default: null,
      validator(value) {
        return !value || value.hasOwnProperty('aggregationType') &&
               value.hasOwnProperty('chosenMonth');
      },
    },
    disableOptions: {
      type: Array,
      default: () => [],
    },
    noTransitions: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: 'Comparación con el año anterior',
    },
    monthJoiner: {
      type: String,
      default: '',
    },
    chartProps: {
      type: Object,
      default: () => ({
        colors: ['#f0f', '#0ff'],
        minColor: '#ff6f71',
        maxColor: '#3690cf',
        reportKey: 'este-es-un-reporte',
      }),
    },
  },
  data() {
    return {
      selectedAggregationType: null,
      dataChosenMonth: null,
      results: [],
      resultGroups: [],
      loading: false,
      chartSize: {},
      renderChart: true,
      oldWidth: 0,
      oldHeight: 0,
    };
  },
  computed: {
    ...mapState('options', ['chosenYear', 'groups', 'fetchReady']),
    ...mapGetters('options', ['chosenSchool']),
    ...mapGetters('auth', ['userSchoolIds']),
    aggregationTypes() {
      const types = [
        { key: 'grade', label: 'Nivel', title: 'por nivel' },
        { key: 'stage', label: 'Ciclo', title: 'por curso, agrupado por ciclo' },
        { key: 'course', label: 'Curso', title: 'por curso' },
        { key: 'school', label: 'Colegio', title: 'del colegio' },
      ];
      return types.filter(t => (
        !this.disableOptions.includes(t.key)
      ));
    },
    dataAggregationType: {
      get() {
        return this.selectedAggregationType || this.aggregationTypes[0];
      },
      set(value) {
        this.selectedAggregationType = value;
      },
    },
    chartRows() {
      const maxValue = Math.max(...this.aggregateGroupings
        .map(grouping => (
          Math.max(...this.aggregateResults(grouping.type, grouping.key))
        )));
      const minValue = Math.min.apply(null, this.aggregateGroupings
        .map(grouping => (
          Math.min(...this.aggregateResults(grouping.type, grouping.key))
        )).filter(Number));
      return this.aggregateGroupings.map(grouping => (
        [
          grouping.label,
          ...this.aggregateResults(grouping.type, grouping.key)
            .map(r => {
              let results = [null, null, null];
              if (!isNaN(r)) {
                results = [
                  r,
                  `${r.toFixed(1)}%`,
                  `${r.toFixed(1)}`,
                ];
              }
              if (this.aggregationType.key === 'course') {
                results.push(this.barColor(r, maxValue, minValue));
              }
              return results;
            })
            .reduce((acc, r) => [...acc, ...r], []),
        ]
      )).filter(x => x.length > 1);
    },
    aggregateGroupings() {
      switch (this.aggregationType.key) {
      case 'course':
        return this.resultGroups.filter(g => g.parent_id !== this.schoolGroup.id && g.parent_id !== null)
          .map(g => ({
            type: 'course',
            label: g.short_name,
            key: g.id,
          }));
      case 'grade':
        return this.resultGroups.filter(g => g.parent_id === this.schoolGroup.id)
          .map(g => ({
            type: 'grade',
            label: g.short_name,
            key: g.id,
          }));
      case 'stage':
        return this.stageGroups.filter(sg => sg.key !== 'todos')
          .map(sg => ({
            type: 'stage',
            label: sg.name,
            key: sg.key,
          }));
      case 'school':
        return [
          {
            type: 'school',
            label: this.chosenSchool.name,
            key: this.chosenSchool.id,
          },
        ];
      default:
        return [];
      }
    },
    years() {
      if (this.aggregationType.key === 'course') {
        return [
          this.chosenYear,
        ];
      }
      return [
        this.chosenYear - 1,
        this.chosenYear,
      ];
    },
    months() {
      return [
        ...new Set(
          this.results
            .filter(r => r.year === this.chosenYear && r.month !== 0)
            .map(r => r.month)
        ),
      ].sort((a, b) => a - b);
    },
    monthOptions() {
      if (this.months.length > 0) {
        return this.months.map(month => this.monthToOption(month));
      }

      const currentMonthValue = new Date().getMonth();
      return [this.monthToOption(currentMonthValue)];
    },
    schoolGroup() {
      return this.groups.find(g => g.key === 'TD') || {};
    },
    chartViewWindow() {
      const min = Math.min(
        ...this.aggregateGroupings
          .map(grouping => [
            ...this.aggregateResults(grouping.type, grouping.key),
          ])
          .reduce((acc, current) => [...acc, ...current], [])
          .filter(r => !isNaN(r))
      );

      return { min: min > 55 ? 50 : 0, max: 100 }; // eslint-disable-line no-magic-numbers
    },
    aggregationType() {
      return this.override &&
             { key: this.override.aggregationType } ||
             this.dataAggregationType;
    },
    chosenMonth() {
      return this.override &&
             this.monthToOption(this.override.chosenMonth) ||
             this.dataChosenMonth ||
             { label: '' };
    },
  },
  watch: {
    async fetchReady(to) {
      if (to) {
        if (!this.override) {
          await this.fetchOptions();
        }
        await this.fetchResults(this.chosenSchool, this.years);
      }
    },
    chosenSchool: {
      handler(to) {
        if (!this.override) {
          this.fetchResults(to, this.years);
        }
      },
    },
    years: {
      handler(to) {
        if (!this.override) {
          this.fetchResults(this.chosenSchool, to);
        }
      },
    },
    monthOptions() {
      const currentMonthValue = new Date().getMonth();
      const choiceValue = this.chosenMonth && this.chosenMonth.value || currentMonthValue;
      this.dataChosenMonth = this.monthOptions.find(m => m.value === choiceValue) ||
                         this.monthOptions[0];
    },
  },
  beforeMount() {
    this.loading = true;
  },
  mounted() {
    window.addEventListener("afterprint", (event) => {
      this.renderChart = false;
      let self = this;
      setTimeout(() => {
        self.chartSize.width = self.oldWidth;
        self.chartSize.height = self.oldHeight;
        self.renderChart = true;
      }, 200);
    });
  },
  methods: {
    ...mapActions('options', { storeFetchOptions: 'fetchOptions' }),
    ...mapMutations('results', {
      setStoreResults: 'SET_RESULTS',
    }),
    ...mapMutations('options', {
      setFetchReady: 'SET_FETCH_READY',
      setReportKey: 'SET_CHOSEN_REPORT_KEY',
    }),
    aggregateResults(grouping, key) {
      const groupedResults = this.groupedResults(grouping, key);

      return this.years.map(year => {
        const results = groupedResults.filter(r => (
          r.year === year && r.month === this.chosenMonth.value
        ));

        return results.reduce((sum, result) => sum + result.value, 0) / results.length;
      });
    },
    barColor(value, max, min) {
      let color = this.chartProps.colors[0];
      if (value >= max) color = this.chartProps.maxColor;
      else if (value <= min) color = this.chartProps.minColor;
      return `stroke-color: ${color}; stroke-width: 6; stroke-opacity: 0.8; fill-color: ${this.chartProps.colors[0]}`;
    },
    groupedResults(grouping, key) {
      switch (grouping) {
      case 'course':
        return this.results.filter(r => r.group_id === key);
      case 'grade':
        return this.results.filter(r => r.group_id === key);
      case 'stage': {
        const stageGroupIds = this.stageGroups.find(sg => sg.key === key).groups.map(g => g.id);

        return this.results.filter(
          r => stageGroupIds.includes(this.groups.find(g => g.id === r.group_id).parent_id)
        );
      }
      case 'school': {
        return this.results.filter(r => r.group_id === this.schoolGroup.id && r.school_id === key);
      }
      default:
        return [];
      }
    },
    monthToOption(month) {
      return { value: month, label: MONTHS[month - 1] };
    },
    async fetchResults(school, years) {
      if (!school || !years) return;

      this.loading = true;
      try {
        const { body: { results_with_stats } } = await ResultsApi.getResults({ // eslint-disable-line camelcase
          school_ids: school.id, // eslint-disable-line camelcase
          report_key: this.chartProps.reportKey, // eslint-disable-line camelcase
          years: years.join(','),
        });
        this.results = results_with_stats.results.map(result => { // eslint-disable-line camelcase
          const newResult = { ...result };
          const evaluation = results_with_stats.evaluations.find(e => e.id === result.evaluation_id); // eslint-disable-line camelcase

          if (!evaluation) return newResult;

          if (evaluation.evaluable_type === 'School') {
            newResult.school_id = evaluation.evaluable_id; // eslint-disable-line camelcase
          } else if (evaluation.evaluable_type === 'SchoolNetwork') {
            newResult.school_network_id = evaluation.evaluable_id; // eslint-disable-line camelcase
          }
          newResult.year = evaluation.year;
          newResult.month = evaluation.month;

          return newResult;
        });
        this.resultGroups = results_with_stats.groups; // eslint-disable-line camelcase
        if (!this.override) this.setStoreResults(results_with_stats);
      } catch (_) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async fetchOptions() {
      this.loading = true;
      this.setReportKey(this.chartProps.reportKey);
      await this.storeFetchOptions();
    },
    printChart() {
      // this.renderChart = false;
      // this.chartSize.width = 600;
      // this.chartSize.height = 250;
      // this.oldWidth = this.$refs.contenchartColum.clientWidth;
      // this.oldHeight = this.$refs.contenchartColum.clientHeight;
      // let self = this;
      // setTimeout(() => {
      //   self.renderChart = true;
      //   setTimeout(() => {
      //     window.print();
      //   }, 200);
      // }, 200);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.last-year-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;

  .chart-container {
    width: 100%;
    height: 500px;
  }

  .chart-labels {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 10px 0px;

    .chart-label {
      display: flex;
      margin: 5px;
      align-items: center;

      .chart-label-color {
        width: 18px;
        height: 18px;
        margin-right: 2px;
      }
    }
  }

  .no-results {
    width: 100%;
    flex: 1;
    text-align: center;
  }

  .faded {
    opacity: 0;
  }

  .fade-transition {
    transition: all 0.2s;
  }

  .selector-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99%;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 20px;

    @media only screen and (max-width: $mobile-breakpoint) {
      width: 90vw;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .selector-with-label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin-top: 5px;
      }
    }

    .selector-label {
      padding-right: 10px;
    }

    .rdr-picker {
      border: 0px !important;
    }
  }
}

.last-year-comparison > div {
  width: 100%;
}

@media print {
  .content {
    margin: 0px !important;
    padding: 0px !important;
  }
  .attendance-evolution {
    padding: 0px !important;
  }

  .chart-label-color {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
  .chart-container {
    height: 350px;
  }
}
</style>
