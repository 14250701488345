<template>
  <rdr-picker
    :options="months"
    :value="chosenMonth"
    :on-change="pick"
    :loading="loading"
    label-property="label"
    value-property="value"
    :icon-calendar="iconCalendar" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { MONTHS } from '../utils/months';

const DEFAULT_NAMES = [
  { label: 'Anual', value: 0 },
  { label: 'Primer Semestre', value: 21 },
  { label: 'Segundo Semestre', value: 22 },
  { label: 'Primer Trimestre', value: 31 },
  { label: 'Segundo Trimestre', value: 32 },
  { label: 'Tercer Trimestre', value: 33 },
  { label: 'Primer Bimestre', value: 41 },
  { label: 'Segundo Bimestre', value: 42 },
  { label: 'Tercer Bimestre', value: 43 },
  { label: 'Cuarto Bimestre', value: 44 },
  { label: 'Primer Bimestre', value: 61 },
  { label: 'Segundo Bimestre', value: 62 },
  { label: 'Tercer Bimestre', value: 63 },
  { label: 'Cuarto Bimestre', value: 64 },
  { label: 'Quinto Bimestre', value: 65 },
  { label: 'Sexto Bimestre', value: 66 },
  ...MONTHS.map((label, i) => ({ label, value: i + 1 })),
];

export default {
  name: 'MonthPicker',
  props: {
    customMonthOptions: {
      type: Array,
      default: null,
    },
    customNames: {
      type: Array,
      default() {
        return [];
      },
      validator(array) {
        const validKeys = ['label', 'value'];

        return array.every(element => (validKeys.map(e => (Object.keys(element).indexOf(e))).every(v => v !== -1)));
      },
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    iconCalendar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('options', {
      storeChosenMonth: 'chosenMonth',
      storeMonths: 'months',
    }),
    names() {
      const customNamesValues = this.customNames.map(e => e.value);
      const defaultNames = DEFAULT_NAMES.filter(e => !customNamesValues.includes(e.value));

      return [
        ...defaultNames,
        ...this.customNames,
      ].sort((a, b) => a.value < b.value);
    },
    chosenMonth() {
      const monthNumber = this.storeChosenMonth;
      return this.months.find(option => option.value === monthNumber);
    },
    months() {
      const months = [...(this.customMonthOptions || this.storeMonths)];
      if (months.length === 0) return this.customNames;
      return months.sort((a, b) => a - b).map((monthNumber) => {
        const option = this.names.find(e => e.value === monthNumber);
        if (option) return option;

        return { label: monthNumber, value: monthNumber };
      });
    },
  },
  watch: {
    months: {
      handler() {
        let chosenMonth = this.chosenMonth;
        if (!chosenMonth || !this.months.find(m => m.value === chosenMonth.value)) {
          chosenMonth = this.months[0] ? this.months[0].value : (new Date()).getMonth();
          this.pick(chosenMonth);
        }
        this.$emit('monthgeter', chosenMonth);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('options', ['setChosenMonth']),
    pick(value) {
      const monthNumber = parseInt(value, 10);
      this.setChosenMonth(monthNumber);
      this.$ga.event({
        eventCategory: 'MonthPicker',
        eventAction: 'Pick',
        eventLabel: 'OptionSelect',
        eventValue: monthNumber,
      });
    },
  },
};
</script>
