<template>
  <div :class="$style.explanation">
    <span>{{ explanationMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'ExplanationMessage',
  props: {
    explanationMessage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module>
.explanation {
  font-weight: bold;
  text-align: center;
  width: 100%;
}
</style>
