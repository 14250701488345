<template>
  <img
    v-if="chosenSchool && chosenSchool.thumbnail"
    :src="chosenSchool.thumbnail"
    :class="$style.logoImage"
    alt="Thumbnail">
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SchoolLogo',
  computed: {
    ...mapGetters('options', ['chosenSchool']),
  },
};
</script>

<style lang="scss" module>
.logo-image {
  width: 110px;
}
</style>
