<template>
  <div
    v-if="Object.keys(filters).length > 0"
    :style="marginStyle"
    :class="[
      { [$style.verticalDisplay]: orientation === 'vertical' },
      { [$style.background]: background },
      { [$style.filters]: showFilters },
      { [$style.hidden]: !showFilters || hidden },
      { 'hide-print': Object.values(activeGroupFilters).every(filter => filter.length === 0) },
      { [customClass]: !!customClass },
  ]">
    <div
      :class="[
        $style.printFilters,
        { [$style.horizontalDisplay]: orientation === 'horizontal' },
    ]">
      <h2 v-if="showTitle"> FILTROS </h2>
      <div
        v-for="(values, key) in filters"
        :key="key"
        :class="[
          $style.filterColumns,
          { 'hide-print': activeGroupFilters[key] && activeGroupFilters[key].length === 0 },
      ]">
        <template
          v-if="!hiddenFilters.includes(key)"
        >
          <h3
            :class="[
              { 'title-switch':  values[0].type === 'switch' },
            ]">
            {{ key }}
          </h3>
          <div
            :class="[
              { 'conten-switch':  values[0].type === 'switch' },
              'conten-inps',
            ]">
            <div
              v-for="filter in values"
              :key="filter.key"
              :class="{ 'hide-print': activeGroupFilters[key] && !activeGroupFilters[key].includes(filter.key) }"
            >
              <div
                v-if="filter.type === 'slider'"
                :class="$style.filterTypeSlider">
                <slider
                  v-if="!loading || filter.loading === false"
                  v-model="filter.range"
                  :min="filter.min"
                  :max="filter.max"
                  :step="filter.step"
                  :class="['results-column__slider', $style.filterColumnsSlider]"
                  @change="toggleSliderFilter(filter)"/>
              </div>
              <div
                v-if="filter.type === 'switch'"
                :class="[$style.filterTypeSwitch, filter.mainItem ? $style.filterMainItem : '']" >
                <span :class="[$style.filterSpan, filter.subitem ? $style.filterSpanSubItem : '']">
                  {{ filter.label }}
                </span>
                <el-switch
                  v-model="activeFilters[filter.key]"
                  :active-color="GREEN_COLOR"
                  :inactive-color="LIGHT_COLOR"
                  :class="[$style.filterColumnsSwitch, 'hide-print']"
                  @change="toggleSwitchFilter(filter)"/>
              </div>
              <div
                v-if="filter.type === 'dropdown'"
                :class="$style.filterTypeDropdown">
                <span
                  v-for="id in filter.selectedGroups"
                  :class="[$style.filterSpan, 'print-only']"
                  :key="id">
                  {{ filter.options.find(x => x.id === id).name }}
                </span>
                <el-select
                  v-model="filter.selectedGroups"
                  :disabled="disabledInput(filter)"
                  :placeholder="filter.dropdownPlaceholder"
                  :class="[$style.filterColumnsSelect, 'hide-print']"
                  multiple
                  collapse-tags
                  @change="toggleSelectFilter(filter)">
                  <el-option
                    v-for="item in filter.options"
                    :key="item.key"
                    :label="item.short_name"
                    :value="item.id"/>
                </el-select>
              </div>
              <div
                v-if="filter.type === 'dropdownMultiple'"
                :class="$style.filterTypeDropdown">
                <span
                  v-for="key in filter.selectedGroups"
                  :class="[$style.filterSpan, 'print-only']"
                  :key="key">
                  {{ filter.options.find(x => x.key === key).name }}
                </span>
                <el-select
                  v-model="filter.selectedGroups"
                  :disabled="disabledInput(filter)"
                  :placeholder="filter.dropdownPlaceholder"
                  :class="[$style.filterColumnsSelect, 'hide-print']"
                  multiple
                  collapse-tags
                  @change="toggleSelectFilter(filter)">
                  <el-option
                    v-for="item in filter.options"
                    :key="item.key"
                    :label="item.name"
                    :value="item.key"/>
                </el-select>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="button"
      :class="[
        'button-wrapper',
        'hide-print',
        $style.buttons,
    ]">
      <button
        :class="[
          'rdr-btn',
        ]"
        @click="$emit('reset-filters')">
        <span>Resetear</span>
      </button>
      <button
        :class="[
          'rdr-btn',
          'rdr-btn--primary',
        ]"
        @click="$emit('apply-filters')">
        <span>Aplicar</span>
      </button>
    </div>
  </div>
</template>

<script>
import { Switch, Select, Option } from 'element-ui';
import { PRIMARY_COLOR, LIGHT_COLOR, MAIN_TEXT_COLOR, GREEN_COLOR } from '../utils/style-variables';
import Slider from '../components/utils/slider.vue';
import updateQueryParams from '../utils/update-query-params';

export default {
  name: 'Filters',
  components: {
    Slider,
    'el-switch': Switch,
    'el-select': Select,
    'el-option': Option,
  },
  props: {
    filters: {
      validator: (value) => {
        if (!(value === Object(value))) return false;
        if (value.length === 0) return true;
        if (Array.isArray(value[0])) return false;

        return Object.values(value).every(filters => (
          filters.every(filter => (
            typeof filter.label === 'string' && typeof filter.type === 'string'
          ))
        ));
      },
      default: () => ({}),
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    changeFunction: {
      type: Function,
      default: null,
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    allFilters: {
      type: Array,
      default: () => [],
    },
    hiddenFilters: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Number,
      default: 1,
    },
    button: {
      type: Boolean,
      default: false,
    },
    orientation: {
      type: String,
      default: 'vertical',
    },
    background: {
      type: Boolean,
      default: true,
    },
    margin: {
      type: String,
      default: '0',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      PRIMARY_COLOR,
      GREEN_COLOR,
      LIGHT_COLOR,
      MAIN_TEXT_COLOR,
    };
  },
  computed: {
    activeGroupFilters() {
      const activeGroupFilters = {};
      Object.keys(this.filters).forEach(key => {
        const activeFilters = this.filters[key].filter(filter => this.activeFilter(filter))
          .map(filter => filter.key);
        activeGroupFilters[key] = activeFilters;
      });

      return activeGroupFilters;
    },
    marginStyle() {
      return { marginTop: this.margin };
    },
    localQueryParams() {
      return Object.keys(this.activeFilters).filter(
        key => this.activeFilters[key] === true
      ).reduce((o, key) => {
        switch (key) {
        case 'group_ids':
          return { ...o, [key]: this.filters.Curso[0].selectedGroups.join(',') };
        case 'stage_group_ids':
          return { ...o, [key]: this.filters.Ciclo[0].selectedGroups.join(',') };
        case 'years_since_enrollment':
          return { ...o, [key]: this.filters['Años de antiguedad'][0].range.join(',') };
        default:
          return { ...o, [key]: true };
        }
      }, {});
    },
  },
  watch: {
    activeFilters(newValue, oldValue) {
      if (Object.keys(newValue).length === Object.keys(oldValue).length) {
        updateQueryParams({ page: this.currentPage, ...this.localQueryParams });
      } else {
        updateQueryParams({ page: 1, ...this.localQueryParams });
      }
    },
  },
  created() {
    const activeFilters = { ...this.getActiveFilters(this.$route.query) };
    this.$ga.event({
      eventCategory: 'FilterTable',
      eventAction: 'ApplyFilter',
      eventLabel: 'OptionSelect',
      eventValue: this.$route.query,
    });
    this.$emit('update:activeFilters', activeFilters);
    Object.entries(this.$route.query).forEach(([key, value]) => {
      if (key === 'group_ids') {
        if (value.toString().includes(',')) {
          this.filters.Curso[0].selectedGroups = value.split(',').map(x => +x);
        } else {
          this.filters.Curso[0].selectedGroups = [Number(value)];
        }
      } else if (key === 'stage_group_ids') {
        this.filters.Ciclo[0].selectedGroups = value.split(',');
      } else if (key === 'years_since_enrollment') {
        this.filters['Años de antiguedad'][0].range = value.split(',').map(x => +x);
      }
    });
    this.activateInitialFilters();
  },
  methods: {
    disabledInput(filter) {
      return !(filter.options.length > 0);
    },
    activeFilter(filter) {
      if (filter.type === 'slider') {
        return (filter.range[0] !== filter.min || filter.range[1] !== filter.max);
      } else if (filter.type === 'dropdown') {
        return filter.selectedGroups.length > 0;
      }
      if (this.activeFilters[filter.key] === undefined) {
        return false;
      }

      return this.activeFilters[filter.key];
    },
    toggleSwitchFilter(filter) {
      let activeFilters = { ...this.activeFilters };
      if (activeFilters[filter.key]) {
        activeFilters = this.disableExclusionFilter(filter, activeFilters);
        activeFilters[filter.key] = true;
        this.$ga.event({
          eventCategory: 'FilterTable',
          eventAction: 'ApplyFilter',
          eventLabel: 'OptionSelect',
          eventValue: filter.key,
        });
      } else {
        this.$ga.event({
          eventCategory: 'FilterTable',
          eventAction: 'RemoveFilter',
          eventLabel: 'OptionSelect',
          eventValue: filter.key,
        });
      }
      this.$emit('update:activeFilters', activeFilters);
    },
    toggleSelectFilter(filter) {
      let activeFilters = { ...this.activeFilters };
      activeFilters[filter.key] = filter.selectedGroups.length > 0;
      activeFilters = this.disableExclusionFilter(filter, activeFilters);
      this.$ga.event({
        eventCategory: 'FilterTable',
        eventAction: 'ApplyFilter',
        eventLabel: 'OptionSelect',
        eventValue: filter.key,
      });
      this.$emit('update:activeFilters', activeFilters);
    },
    toggleSliderFilter(filter) {
      let activeFilters = { ...this.activeFilters };
      activeFilters[filter.key] = filter.range[0] !== filter.min || filter.range[1] !== filter.max;
      activeFilters = this.disableExclusionFilter(filter, activeFilters);
      this.$emit('update:activeFilters', activeFilters);
    },
    disableExclusionFilter(filter, activeFilters) {
      if (filter.exclusionClass) {
        this.allFilters.forEach(otherFilter => {
          if (filter.exclusionClass === otherFilter.exclusionClass && filter !== otherFilter) {
            activeFilters[otherFilter.key] = false;
          }
        });
      }

      return activeFilters;
    },
    activateInitialFilters() {
      updateQueryParams({ page: this.currentPage, ...this.activeFilters });
    },
    clearFilters() {
      const activeFilters = { ...this.$data.activeFilters };
      Object.keys(activeFilters).forEach((key) => (activeFilters[key] = false));
      this.$emit('update:activeFilters', activeFilters);
    },
    getActiveFilters(filterObject) {
      return Object.keys(filterObject)
        .reduce(
          (a, b) => ({ ...a, ...(b === 'page' ? {} : { [b]: true }) }),
          {}
        );
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.vertical-display {
  position: sticky;
  top: 100px;
  width: 223px;
  margin-right: 25px;
  padding: 10px;
  flex: 0 0 auto;

  @media only print {
    width: auto;
  }
}

.horizontal-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;

  @media only print {
    border: 1px solid $sidebar-border-color;
  }

}

.horizontal-display>* {
  flex: 1 1 20%;

  @media only print {
    flex: 0 0 auto;
  }
}

.background {
  background-color: $sidebar-background;
  border: 1px solid $sidebar-border-color;
}

.filters {
  display: flex;
  flex-flow: column wrap;
  box-sizing: border-box;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s;

  @media only print {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  max-width: 0;
  max-height: 0;
  overflow: hidden;
  margin-right: 0;
  padding: 10px 0;

  @media only print {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    align-content: left;
    visibility: visible;
    opacity: 1;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px;
    padding: 10px;
    overflow: visible;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    display: none;
  }

}

.print-filters {
  @media only print {
    display: flex;
    flex-direction: row;
    align-content: left;
    flex-wrap: wrap;
  }
}

.filter-columns {
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
  align-content: flex-start;
  padding: 0 5px 20px 5px;
  min-width: 100%;

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &-switch {
    flex-grow: 1;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  &-select {
    padding-right: 20px;
    width: 100%;
  }

  &-slider {
    padding-top: 50px !important;

    @media only print {
      margin-bottom: -35px;
    }
  }

  @media only print {
     margin-bottom: -20px;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 100%;
    padding: 0 5px 2px 0;
  }
}

.filter-span {
  flex: 1 1 100%;
  margin: 5px 15px 0 0;

  &-sub-item {
    padding-left: 24px;
  }

  @media only print {
    display: block;

    &:before {
      content: '•';
      margin-right: 5px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 10px;
  }
}

.filter-main-item {
  margin-top: 10px;
}

.filter-type {
  &-switch {
    display: flex;
  }
 }
</style>
