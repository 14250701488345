<template>
  <div
    :class="$style.box">
    <div :class="$style.titleBox">
      <div
        v-if="legendColorSquare"
        :class="$style.coloredSquare"
        :style="colorSquareStyle"/>
      <strong
        :class="[$style.title, 'info__datum', legendColorSquare ? $style.titleWithSquare : '']">
        {{ title }}
      </strong>
    </div>
    <i
      v-if="!value && !objectValue"
      :style="noDataStyle"
      class="material-icons">
      help_outline
    </i>
    <h1
      v-else-if="objectValue"
      :class="[mainContentClass]"
      :style="{color: objectValueColor }">{{ objectValue.label }} </h1>
    <h1
      v-else
      :class="[mainContentClass]"
      :style="{color: valueColor}">{{ filteredValue }} </h1>
    <div v-if="customFooter"> {{ customFooter }} </div>
    <div
      v-else-if="relativeValue !== null && hasFooter"
      :class="$style.row">
      <i
        :style="{color: iconColor, fontWeight: 1000}"
        class="material-icons">
        {{ icon }}
      </i>
      <div v-if="differsFromCourse && objectValue">
        <small>
          <b>{{ relativeText }}</b> lo esperado
        </small>
      </div>
      <div v-else-if="!differsFromCourse && objectValue">
        <small>
          Igual a lo esperado
        </small>
      </div>

      <div v-else-if="differsFromCourse">
        <small>
          <b>{{ filteredRelativeValue }} {{ relativeText }}</b> su curso
        </small>
      </div>
      <div v-else>
        <small>
          Igual al promedio del curso
        </small>
      </div>
    </div>
    <small v-else-if="hasFooter && !isTextValue">
      No se tiene información del curso
    </small>
  </div>
</template>

<script>
import {
  APPROVED_COLOR,
  FAIL_COLOR,
  INCREASE_COLOR,
  DECREASE_COLOR,
  TEXT_GRADE_COLOR,
} from '../../utils/style-variables';

export default {
  name: 'ResultBox',
  props: {
    title: {
      type: String,
      default: '',
    },
    objectValue: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [Number, String],
      default: null,
    },
    relativeValue: {
      type: Number,
      default: null,
    },
    metricType: {
      type: String,
      default: null,
    },
    valueFilterKey: {
      type: String,
      default: 'numberWithOneDecimal',
    },
    customFooter: {
      type: String,
      default: null,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    colorPicker: {
      type: Function,
      default: value => {
        if (typeof value !== 'number') return TEXT_GRADE_COLOR;
        return value >= 3.95 ? APPROVED_COLOR : FAIL_COLOR; // eslint-disable-line no-magic-numbers
      },
    },
    legendColorSquare: {
      type: String,
      default: null,
    },
    mainContentClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      noDataStyle: { fontSize: '80px', color: DECREASE_COLOR },
    };
  },
  computed: {
    differsFromCourse() {
      return this.relativeValue && this.relativeValue !== 0;
    },
    relativeText() {
      return this.relativeValue < 0 ? 'bajo' : 'sobre';
    },
    valueColor() {
      return this.colorPicker(this.value);
    },
    isTextValue() {
      return typeof this.value === 'string';
    },
    objectValueColor() {
      return this.colorPicker(this.objectValue.normValue);
    },
    icon() {
      if (this.relativeValue === 0) return '';
      return this.relativeValue > 0 ? 'arrow_upward' : 'arrow_downward';
    },
    iconColor() {
      return this.relativeValue > 0 ? INCREASE_COLOR : DECREASE_COLOR;
    },
    filteredRelativeValue() {
      return this.$options.filters[this.valueFilterKey](Math.abs(this.relativeValue));
    },
    filteredValue() {
      return this.$options.filters[this.valueFilterKey](this.value);
    },
    colorSquareStyle() {
      return {
        background: this.legendColorSquare,
      };
    },
  },
};

</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.box {
  padding: 3px;
  width: 200px;
  min-height: 185px;
  border-radius: 5px;
  font-weight: 200;
  border-style: solid;
  border-width: thin;
  text-align: center;
  margin: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 0;
  color: $main-text-color;
  border-color: $light-gray-color;
  @media print {
    border-style: solid;
    border-width: 2px;
  }
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  line-height: 1.6;
  position: relative;
  margin-top: 0;
  font-weight: bold;
}
.title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .colored-square {
    width: 15px;
    height: 15px;
    border-radius: 35%;
    vertical-align: middle;
  }

  .title-with-square {
    width: 70%;
    padding: 0 2px;
  }
}

</style>
