<template>
  <div class="report">
    <alert-info :text-alert="textData"/>
    <div class="rdr-view">
      <paywall
        :permitted="hasInternalEvaluationsPermission"
        :free="isFreeUser"
        restricted>
        <rdr-results-header
          month-picker
          year-picker
          hide-school-picker>
        </rdr-results-header>
        <router-view/>
        <!-- <aptus-link-btn
          v-if="isAptusAccount"
          :aptus-link="aptusLink"/> -->
      </paywall>
    </div>
  </div>
</template>

<script>
import ResultsHeader from '../../components/results/results-header.vue';
// import AptusLink from '../../components/aptus-link.vue';
import AuthMixin from '../../mixins/auth';
import PermissionMixin from '../../mixins/permission';
import AlertInfo from '../../components/utils/alert-info.vue';


export default {
  name: 'ComparativeInternalEvaluationsView',
  components: {
    'rdr-results-header': ResultsHeader,
    // 'aptus-link-btn': AptusLink,
    AlertInfo,
  },
  mixins: [AuthMixin, PermissionMixin],
  data() {
    return {
      textData: 'Este mapa de color muestra el logro promedio por asignatura de cada colegio en las evaluaciones rendidas.',
    }
  },
  computed: {
    aptusLink() {
      return 'https://www.aptuschile.cl/apt_system/AptusDigitalResultados';
    },
  },
};
</script>
