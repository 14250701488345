<template>
  <div class="school-picker">
    <div slot="title">
      <h2 v-if="single">Elige el colegio a analizar</h2>
      <h2 v-else>Elige los colegios a comparar:</h2>
    </div><br>
    <div
      v-for="(choice, index) in choices"
      :key="`school-picker__choice_${index}`"
      :class="{ 'school-picker__choice--extra': index > 0 }"
      class="school-picker__choice">
      <button
        v-if="index > 0"
        class="school-picker__remove-choice"
        @click="removeChoice(index)">
        <span class="material-icons">remove_circle</span>
      </button>
      <school-select
        v-model="choices[index]"
        :class="[
          'rdr-select school-picker__select',
          { 'school-picker__select--owned': isSchoolOwned(choice) }
        ]"
        :filter-options="filterOptions"
        placeholder="Busca tu colegio o RBD" />
    </div>
    <button
      v-if="!single && schoolChoicesRemaining > 0"
      class="school-picker__add"
      @click="addChoice">
      <span class="material-icons">add</span>
      <span>Agregar otro colegio</span>
      <span v-if="schoolChoicesRemaining > 0">(quedan {{ schoolChoicesRemaining }})</span>
    </button>
    <div>
      <button
        :class="['school-picker__apply', 'rdr-btn', 'rdr-btn--primary', 'rdr-btn--large']"
        @click="hideDialog('schoolPicker')">Ver resultados
      </button>
    </div>
    <button
      :class="['school-picker__apply', 'rdr-btn', 'rdr-btn--primary', 'rdr-btn--large']"
      @click="reloadOwnSchoolsAndHide">Volver a mis colegios
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SchoolSelect from './school-select';
import UpcomingDialogMixin from '../mixins/upcoming-dialog';
import AuthMixin from '../mixins/auth';

export default {
  components: {
    SchoolSelect,
  },
  mixins: [AuthMixin, UpcomingDialogMixin],
  props: {
    single: {
      type: Boolean,
      default: false,
    },
    repeatedChoice: {
      type: Boolean,
      default: false,
    },
    dialogClosed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      choices: [],
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolIds']),
    ...mapGetters('options', ['chosenSchool', 'chosenSchools']),
    ...mapGetters('auth', ['userSchoolIds', 'chosenSchoolLimit']),
    schoolChoicesRemaining() {
      return this.chosenSchoolLimit - this.choices.length;
    },
    allSchoolsChosen() {
      return this.single && this.choices.length === 1 || this.schoolChoicesRemaining === 0;
    },
    filterOptions() {
      if (this.repeatedChoice) return [];

      return this.choices.filter(c => !!c).map(s => s.id);
    },
  },
  watch: {
    chosenSchoolIds: {
      handler(to, from) {
        const fromSet = from && from.length > 0 && new Set(from);
        if (fromSet && to.some(id => !fromSet.has(id))) {
          this.fetchResults();
        }

        this.choices = (this.single ? [this.chosenSchool] : this.chosenSchools);
      },
      immediate: true,
    },
    dialogClosed() {
      if (this.dialogClosed) {
        this.applyChoices();
        this.$emit('update:dialogClosed', false);
      }
    },
  },
  methods: {
    ...mapActions('options', ['setChosenSchools']),
    ...mapActions('results', ['fetchResults']),
    ...mapActions('ui', ['hideDialog']),
    isSchoolOwned(school) {
      if (!school) return false;

      return !!this.userSchoolIds.find(id => id === school.id);
    },
    addChoice() {
      if (this.isLoggedIn && this.schoolChoicesRemaining > 0) {
        this.choices.push(null);
      } else {
        this.openUpcomingDialog('addSchools');
      }
    },
    removeChoice(index) {
      this.choices.splice(index, 1);
    },
    applyChoices() {
      this.setChosenSchools(this.choices.filter(c => !!c));
      this.$ga.event({
        eventCategory: 'SchoolPicker',
        eventAction: 'ApplyChoices',
        eventLabel: 'SchoolPicker',
      });
    },
    reloadOwnSchoolsAndHide() {
      this.setChosenSchools(this.userSchoolIds);
      this.hideDialog('schoolPicker');
      this.$ga.event({
        eventCategory: 'SchoolPicker',
        eventAction: 'ReloadOwnSchools',
        eventLabel: 'SchoolPicker',
      });
    },
  },
};
</script>

<style lang="scss">
  @import "../../styles/app/variables";

  .school-picker {
    position: relative;
    font-family: $accent-font;

    .no-options {
      padding: 0 25px;
    }

    &__current {
      background-color: #d7defb;
      margin-bottom: 30px;
      color: #4266F7;
      border-radius: 4px;
      padding: 12px 20px;

      .header {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        margin-bottom: 4px;
        font-family: 'Muli';
      }
    }

    &__divider {
      text-align: left;
      margin-bottom: 15px;
      h2 {
        margin-top: 0px;
      }
    }

    &__select {
      margin-bottom: 10px;

      &--owned {
        .dropdown-toggle {
          border-color: $primary-color !important;
        }
      }
    }

    &__apply {
      width: 100%;
      margin-top: 10px;
    }

    &__choice {
      &--extra {
        position: relative;
        padding-left: 40px;
      }
    }

    &__remove-choice {
      position: absolute;
      background: none;
      border: 0;
      left: 0;
      height: 100%;

      .material-icons {
        font-size: 28px;
        padding: 0;
        font-family: 'Material Icons';
        color: #FC825D;
        cursor: pointer;
        outline: none;
      }

      &:hover {
        color: #FF4745;
         .material-icons {
          color: #FF4745;
      }
      }
    }

    &__add {
      width: 100%;
      margin-bottom: 15px;
      height: 55px;
      background-color: white;
      border: none;
      border-radius: 65px;
      text-align: left;
      padding: 0 25px;
      font-size: 15px;
      font-family: 'GT Haptik', sans-serif;
      font-weight: 300;
      letter-spacing: .5px;
      border: 1px solid #eee;
      position: relative;
      cursor: pointer;
      outline: none;
      color: #666;

      .material-icons {
        border: 2px solid;
        border-radius: 100px;
        font-size: 20px;
        color: #aaa;
        position: absolute;
        right: 16px;
        top: 15px;
      }

      &:hover {
        border-color: $primary-color;
        color: $primary-color;

        .material-icons {
          color: $primary-color;
        }
      }
    }

    &__disabled-message {
      text-align: center;
      margin-top: 15px;
      color: #888;
    }

    .commune {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .name {
      margin-right: 10px;
    }

    .rbd {
      opacity: .5;
    }

    .selected-tag .commune {
      display: none;
    }
  }
</style>
