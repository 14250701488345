<template>
  <paywall
    :permitted="isLoggedIn"
    :free-text="'Crea una cuenta en Radar Escolar para poder acceder a esta información'"
    free>
    <div :class="$style.learningLevels">
      <transition
        :enter-class="$style.faded"
        :leave-to-class="$style.faded"
        :enter-active-class="$style.fadeTransition"
        :leave-active-class="$style.fadeTransition"
        name="fade"
        mode="out-in">
        <div
          v-if="filteredResults.length > 0"
          key="transition-1"
          :class="$style.chartContainer">
          <column-chart
            :loading="loading > 0"
            :columns="learningLevels"
            :rows="chartRows"
            :colors="chartColors"
            :v-axis-options="({ viewWindow: { max: 100 }, format: '#\'%\'' })"
            :animation-options="{
              startup: true,
              easing: 'out',
              duration: 500,
            }"
            :has-annotations="true"
            is-stacked="true"
            custom-tooltip
          />
        </div>
        <div
          v-else
          key="transition-2"
          :class="$style.noResults">
          <p>No se encontraron resultados para las opciones seleccionadas</p>
        </div>
      </transition>
      <div :class="$style.rightColumn">
        <div :class="$style.pickers">
          <h3>Seleccione una evaluación:</h3>
          <rdr-picker
            v-model="chosenGroup"
            :options="resultGroups"
            :class="$style.picker"
            label-property="name"
            picker-style="round"/>
          <rdr-picker
            v-model="selectedTest"
            :options="testOptions"
            :class="$style.picker"
            label-property="label"
            picker-style="round"/>
          <rdr-picker
            v-model="selectedSubject"
            :options="subjectOptions"
            :class="$style.picker"
            label-property="label"
            picker-style="round"/>
        </div>
        <div
          :class="$style.chartLabels">
          <h3 v-show="!loading">Leyenda:</h3>
          <div
            v-for="(level, index) in invertedLevels"
            :class="$style.chartLabel"
            :key="index">
            <div
              :class="$style.chartLabelColor"
              :style="{ backgroundColor: invertedColors[index] }"/>
            <span :class="$style.chartLabelText">{{ level }}</span>
          </div>
        </div>
      </div>
    </div>
  </paywall>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import RdrPicker from '../../components/utils/picker.vue';
import ColumnChart from '../../components/column-chart.vue';
import Paywall from '../../components/paywall.vue';
import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';

export default {
  name: 'LearningLevelsView',
  components: { RdrPicker, ColumnChart, Paywall },
  mixins: [PermissionMixin, ResultsMixin],
  data() {
    return {
      dataChosenGroup: null,
      selectedTest: { key: 'simce', label: 'Simce' },
      testOptions: [
        { key: 'simce', label: 'Simce' },
        // { key: 'psu', label: 'Psu' },
      ],
      selectedSubject: { key: 'lenguaje', label: 'Lenguaje' },
      subjectOptions: [
        { key: 'lenguaje', label: 'Lenguaje' },
        { key: 'historia', label: 'Historia' },
        { key: 'matematica', label: 'Matemática' },
        { key: 'ciencias', label: 'Ciencias' },
      ],
      colors: [
        '#c7c9ff',
        '#9897ff',
        '#3839ff',
      ],
    };
  },
  computed: {
    ...mapState('results', ['loading', 'resultGroups']),
    ...mapGetters('results', { indicators: 'resultsIndicators' }),
    chartRows() {
      return this.years.map(year => (
        [
          year.toString(),
          ...this.learningLevels.map(level => this.resultWithLabel(year, level))
            .reduce((acc, r) => [...acc, ...r], []),
        ]
      ));
    },
    learningLevels() {
      const levels = {};
      this.filteredResults.forEach(r => {
        levels[r.extra_info.learning_level] = r.extra_info.index;
      });

      return Object.keys(levels).sort((a, b) => levels[a] - levels[b]);
    },
    filteredResults() {
      if (!this.chosenIndicator || !this.chosenGroup) return [];

      return this.results.filter(r => (
        r.indicator_id === this.chosenIndicator.id &&
        r.group_id === this.chosenGroup.id
      ));
    },
    years() {
      return [...new Set(this.filteredResults.map(r => r.year))].sort((a, b) => a - b);
    },
    chosenIndicator() {
      const key = [
        'nivel-aprendizaje',
        this.selectedTest.key,
        this.selectedSubject.key,
      ].join('-');

      return this.indicators.find(i => i.key === key);
    },
    chartColors() {
      return this.colors.slice(0, this.learningLevels.length);
    },
    invertedLevels() {
      return this.learningLevels.slice().reverse();
    },
    invertedColors() {
      return this.chartColors.slice().reverse();
    },
    chosenGroup: {
      get() {
        return this.dataChosenGroup;
      },
      set(value) {
        this.dataChosenGroup = value;
      },
    },
  },
  watch: {
    resultGroups(to) {
      const defaultGroup = to.find(g => g.key === '4');
      if (defaultGroup) {
        this.chosenGroup = defaultGroup;
      } else {
        this.chosenGroup = to[0];
      }
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    resultWithLabel(year, level) {
      const result = this.resultForYearAndLevel(year, level);
      result.value = parseFloat(result.value.toFixed(1));

      return result && [
        result.value,
        `${result.value}% de estudiantes en nivel ${level}`,
        result.value.toString(),
      ];
    },
    resultForYearAndLevel(year, level) {
      return this.filteredResults
        .find(r => r.year === year && r.extra_info.learning_level === level);
    },
    setViewParams() {
      this.setReportParams({ 'school_ids': true });
      this.setReportKey('learning-levels');
    },
    chosenGroupWatchHandler() { },
    chosenIndicatorWatchHandler() {},
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .learning-levels {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .right-column {
    width: 240px;
    padding: 20px;
    border: 1px solid $sidebar-border-color;
    background-color: $sidebar-background;
  }

  .pickers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .picker {
      margin-top: 10px;
    }

    h3 {
      margin: 0;
    }
  }

  .chart-container {
    height: 400px;
    flex: 1;

    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top: 10px;
      flex: auto;
      width: 100%;
    }
  }

  .chart-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-bottom: 0;
    }

    .chart-label {
      display: flex;
      margin-top: 5px;
      align-items: center;

      .chart-label-color {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  .no-results {
    width: 100%;
    flex: 1;
    text-align: center;
  }

  .faded {
    opacity: 0;
  }

  .fade-transition {
    transition: all 0.2s;
  }
</style>

