<template>
  <el-dialog
    :visible.sync="show"
    class="school-picker-dialog"
    @close="dialogClosed = true">
    <school-picker
      ref="schoolPicker"
      :single="schoolMode"
      :dialog-closed.sync="dialogClosed"/>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { Dialog } from 'element-ui';

export default {
  name: 'SchoolPickerDialog',
  components: {
    'el-dialog': Dialog,
  },
  data() {
    return {
      dialogClosed: false,
    };
  },
  computed: {
    ...mapState('options', ['chosenReportKey']),
    schoolMode() { return this.chosenReportKey && this.chosenReportKey.indexOf('school-') === 0; },
    show: {
      get() { return this.$store.state.ui.dialogs.schoolPicker.open; },
      set(value) { this.$store.commit({ type: 'ui/SET_DIALOG_STATE', dialog: 'schoolPicker', state: value }); },
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

  .school-picker-dialog {
    .el-dialog {
      width: 40%;
      overflow: hidden;
      margin-left: 60%;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 100%;


      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 90%;
        top: 20px !important;
      }

      &__header {
        // display: none;
        margin-top: 0px !important;
        padding-top: 0px !important;
      }

      &__body {
        padding: 30px;

        @media only screen and (max-width: #{$mobile-breakpoint}) {
          padding: 20px;
        }
      }
    }
  }
</style>
