<template>
  <paywall
    :permitted="isLoggedIn && hasGradesPermission"
    :quote-text="quoteText"
    :quote-header="quoteHeader"
    :free="isFreeUser"
    transparent
    restricted>
    <div
      v-if="loading"
      class="group-report__container">
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
    </div>
    <div
      v-else
      :class="$style.table">
      <div class="contenScroll">
        <rdr-table
          :columns="subjectsAsColumns"
          :data="rows"
          :custom-class="$style.schoolReportTable"
          :extended-header-class="$style.schoolReportHeaderCell"
          :extended-cell-class="$style.schoolReportCell"
        />
      </div>
    </div>
    <color-map
      slot="mock"
      :rows="mockGradesGroupAverageData.groups"
      :columns="mockGradesGroupAverageData.columns"
      :results="mockGradesGroupAverageData.results"
      :default-open-rows="mockGradesGroupAverageData.defaultOpenRows"
      :results-column-key="mockGradesGroupAverageData.resultsColumnKey"
      :results-row-key="mockGradesGroupAverageData.resultsRowKey"
      :disable-school-adding="true"
      round
      no-color
      class="color-map--pro"/>
  </paywall>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import { WHITE_COLOR } from '../../../utils/style-variables';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';

/* eslint-disable camelcase */

export default {
  name: 'GradesTestCountBySchoolView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [
        'Las calificaciones son obtenidas a partir de la información de la plataforma de gestión de cada establecimiento.',
        'Los colores son referenciales para cada concepto y no necesariamente representan las metas internas de cada proyecto educativo.',
      ],
      colors: [WHITE_COLOR],
      quoteHeader: '¿Quieres visualizar las calificaciones y recibir alertas de posible repitencia?',

    };
  },
  computed: {
    ...mapState({
      loading: state => state.results.loading,
    }),
    ...mapGetters({
      groups: 'results/resultsGroups',
      evaluations: 'results/resultsEvaluations',
      indicators: 'results/resultsIndicators',
    }),
    quoteText() {
      if (this.isAptusAccount && !this.hasGradesPermission) {
        return 'Actualmente tienes el plan Asistencia en alianza con Aptus. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que, además de la asistencia y evaluaciones Aptus, te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante)';
      } else if (!this.hasGradesPermission) {
        return 'Actualmente tienes un plan que no permite hacer seguimiento de calificaciones. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante).';
      }
      return '';
    },
    results() {
      return this.$store.getters['results/results'].map(result => {
        const indicator = this.indicators.find(i => i.id === result.indicator_id);

        return { ...result, column_id: indicator.id };
      });
    },
    groupsAsRows() {
      return this.groups.map(group => ({
        id: group.id,
        key: group.key,
        label: group.short_name,
        parent_id: group.parent_id,
      }));
    },
    rows() {
      const rows = [];
      this.groups.forEach(g => {
        const groupRow = {
          group_id: g.id,
          group_key: g.key,
          group: g.short_name,
          id: g.id,
        };
        const groupResults = this.results.filter(r => r.group_id === g.id);
        groupResults.forEach(result => {
          const indicator = this.indicators.filter(ind => ind.id === result.indicator_id)[0];
          groupRow[indicator.key] = result.value;
        });
        rows.push(groupRow);
      });
      return rows;
    },
    subjectsAsColumns() {
      const columns = [];
      const groupColumn = {
        id: 0,
        key: 'group',
        label: 'Curso',
        textAlign: 'left',
        sticky: true,
        sortFunc: (a, b) => a.localeCompare(b),
      };
      columns.push(groupColumn);
      this.indicators.forEach(indicator => {
        const newColumn = {
          id: indicator.id,
          label: indicator.label,
          key: indicator.key,
          colors: this.colors,
          sortFunc: (a, b) => a - b,
          norm: 'grade_average_norm_value',
          type: 'data-box',
          format: indicator.format,
        };
        const columnExists = columns.find(column => column.id === newColumn.id);

        if (!columnExists) columns.push(newColumn);
      });

      return columns;
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true, 'months': true });
      this.setReportKey('grades-test-count');
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../../styles/app/variables";

table.school-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $sidebar-background;
      }
    }
    tr:hover {
      td {
        background-color: #FBFBFC;
      }
    }
  }
}

div.school-report-header-cell {
  height: 42px;
}

</style>
