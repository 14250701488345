<template>
  <paywall
    :permitted="!restricted"
    free
    restricted>
    <div
      v-if="hasRows"
      class="results-historical">
      <div
        :class="{ 'results-historical__header--faded': restricted }"
        class="results-historical__header">
        <button
          v-if="restrictedSchoolPicker && restricted"
          class="desktop-only school-picker-button rdr-btn rdr-btn--small rdr-btn--primary disabled"
          disabled
          @click="openSchoolPicker">Agregar o cambiar colegios</button>
        <button
          v-else-if="restrictedSchoolPicker"
          class="desktop-only school-picker-button rdr-btn rdr-btn--small rdr-btn--primary"
          @click="showSignUpDialog">Agregar o cambiar colegios</button>
        <button
          v-else
          class="desktop-only school-picker-button rdr-btn rdr-btn--small rdr-btn--primary"
          @click="openSchoolPicker">Agregar o cambiar colegios</button>
        <div class="results-historical__legend">
          <div
            v-for="(school, index) in schools"
            :key="school.id"
            class="results-historical__school">
            <div
              :style="{ borderColor: colors[index] }"
              class="info__color"/>
            <div class="results-historical__school-name">{{ school.name | startCase | cleanSchoolName }}</div>
          </div>
        </div>
      </div>
      <div class="results-historical__picker">
        <historic-indicator-picker
          picker-style="round"
          size="small" />
      </div>
      <div v-if="!restricted">
        <div class="container-fluid results-historical__chart">
          <transition name="fade">
            <loading-overlay v-if="loading"/>
          </transition>
          <div
            v-if="chosenIndicator && (!withGroups || chosenGroup) && !results.length"
            class="results-historical__empty">
            No existen datos para el indicador
            <strong>{{ chosenIndicator.label }}</strong>
            <span v-if="withGroups">en el nivel <strong>{{ chosenGroup.name }}</strong></span>
          </div>
          <historic-chart
            :periods="chartYears"
            :columns="chartColumns"
            :rows="chartRows"
            :formatter-params="tooltipFormatter"
            label-type="string"
          />
        </div>
        <share-whatsapp/>
      </div>
    </div>
    <div
      v-else
      class="results-historical__empty"
    >
      <img src="/noResultados.svg">
    </div>
  </paywall>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import _ from 'lodash';

import GRAPH_COLORS from '../utils/graph-colors';
import HistoricChart from '../components/historic-chart.vue';
import Paywall from '../components/paywall.vue';

import AuthMixin from '../mixins/auth';
import PermissionMixin from '../mixins/permission';

export default {
  name: 'HistoricResults',
  components: {
    HistoricChart,
    Paywall,
  },
  mixins: [AuthMixin, PermissionMixin],
  props: {
    withGroups: {
      type: Boolean,
      default: null,
    },
    restricted: {
      type: Boolean,
      default: null,
    },
    restrictedSchoolPicker: {
      type: Boolean,
      default: null,
    },
    exitButton: {
      type: Boolean,
      default: null,
    },
    tooltipFormatter: {
      type: Object,
      default: () => ({ groupingSymbol: '', fractionDigits: 1 }),
    },
  },
  data() {
    return {
      colors: GRAPH_COLORS,
      emptyMessage: 'No hay resultados para las opciones seleccionadas.',
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('options', ['chosenSchools', 'chosenGroup', 'chosenIndicator']),
    ...mapGetters('results', ['results']),
    schools() {
      return this.chosenSchools;
    },
    chartYears() {
      return _.uniq(this.results.map(result => result.year)).sort();
    },
    chartColumns() {
      return this.chosenSchools.map(school => _.startCase(school.name.toLowerCase()));
    },
    chartRows() {
      const rows = [];

      this.chartYears.forEach(year => {
        const yearResults = this.results.filter(result => result.year === year);

        if (yearResults.length) {
          const row = [year.toString()];

          this.chosenSchools.forEach(school => {
            const result = yearResults.find(r => this.chosenIndicator && r.indicator_id === this.chosenIndicator.id && r.school_id === school.id);
            if (result) {
              // eslint-disable-next-line no-magic-numbers
              row.push(Math.round(result.value * 10) / 10);
            } else {
              row.push(null);
            }
          });

          rows.push(row);
        }
      });

      return rows;
    },
    hasRows() {
      return !!this.chartRows.length;
    },
  },
  methods: {
    ...mapActions('ui', ['openDialog']),
    ...mapActions('signUp', ['showSignUpDialog']),
    openSchoolPicker() {
      this.openDialog('schoolPicker');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/app/variables";

  .results-historical {
    position: relative;

    &__chart {
      position: relative;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      &--faded {
        opacity: .5;
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        display: block;
        padding: 0 50px;
      }
    }

    .school-picker-button {
      margin-right: 40px;
    }

    &__legend {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      top: 5px;

      @media only screen and (max-width: $mobile-breakpoint) {
        margin-bottom: 15px;
      }
    }

    &__empty {
      font-size: 18px;
      background-color: white;
      text-align: center;
      // position: absolute;
      // z-index: 20;
      // left: 0;
      // top: 0;
      width: 100%;
      padding-top: 20px;
    }

    &__school {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 30px;
      max-width: 200px;

      margin: 0 10px;
      margin-bottom: 10px;
      width: calc(50% - 20px);
      max-width: none;
    }

    &__color {
      width: 12px;
      height: 12px;
      border-radius: 30px;
      margin-right: 10px;
    }

    &__picker {
      text-align: center;
      margin: 20px;
    }

    &__school-name {
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
