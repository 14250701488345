<template>
  <div class="error-message">
    <strong>{{ errorMessage }}</strong>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: 'Ocurrió un error. Por favor actualiza la página.',
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  text-align: center;
  width: 100%;
}
</style>

