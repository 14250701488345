<template>
  <div class="report">
    <alert-info :text-alert="textData"/>
    <div class="rdr-view">
      <paywall
        :permitted="hasInternalEvaluationsPermission"
        :free="isFreeUser"
        restricted>
        <rdr-results-header
          year-picker
          group-picker
          month-picker
          show-school-selector
          hide-school-picker
          only-result-groups>
          <!-- Acá se muestran, para cada alumno, los resultados de las evaluaciones externas aplicadas durante el mes seleccionado. -->
        </rdr-results-header>
        <loading-overlay v-if="loading"/>
        <div
          v-else
          :class="$style.contentWrapper">
          <div v-if="noRows">
            <!-- No se encontraron evaluaciones para este curso y período. -->
            <img src="/noResultados.svg">
          </div>

          <rdr-table
            v-else
            :columns="columns"
            :data="rows"
            :custom-class="$style.evaluationsTable"
            :max-label-length="50"
          />
        </div>
        <!-- <aptus-link-btn
          v-if="isAptusAccount"
          :aptus-link="aptusLink"/> -->
      </paywall>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { groupBy } from 'lodash';
import ResultsHeader from '../../components/results/results-header.vue';
import ResultsMixin from '../../mixins/results';
import PermissionMixin from '../../mixins/permission';
import ValueFormattingMixin from '../../mixins/value-formatting';
import AptusLink from '../../components/aptus-link.vue';
import Paywall from '../../components/paywall.vue';
import AlertInfo from '../../components/utils/alert-info.vue';

const RANGE_KEYS = {
  within: 'withinRange',
  above: 'aboveRange',
  below: 'belowRange',
};
export default {
  name: 'InternalEvaluationsByStudentView',
  components: {
    'rdr-results-header': ResultsHeader,
    'aptus-link-btn': AptusLink,
    'paywall': Paywall,
    AlertInfo,
  },
  mixins: [ResultsMixin, ValueFormattingMixin, PermissionMixin],
  data() {
    return {
      colors: ['#e06666', '#fffdcc', '#93c47d'],
      textData: 'Este gráfico muestra el porcentaje de estudiantes en cada nivel de logro por curso. Puedes ajustar los niveles de logro en la barra de la derecha para hacer tus análisis.',
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenYear', 'chosenMonth', 'chosenGroup']),
    ...mapGetters('options', ['chosenSchool']),
    ...mapGetters('results', {
      results: 'results',
      indicators: 'resultsIndicators',
      evaluableStudents: 'resultsEvaluableStudents',
    }),
    ...mapGetters('auth', ['hasInternalEvaluationsPermission']),
    columns() {
      return [
        { key: 'full_name', label: 'Nombre', textAlign: 'left', filters: ['startCase'], sortFunc: (a, b) => a.localeCompare(b), minWidth: 150 },
        ...(this.indicators).map(i => ({
          key: i.key,
          label: i.label,
          type: 'data-box',
          sortFunc: (a, b) => parseInt(a, 10) - parseInt(b, 10),
          backgroundType: 'discrete',
          norm: `norm__${i.key}`,
          discreteColors: { [RANGE_KEYS.below]: '#e06666', [RANGE_KEYS.within]: '#fffdcc', [RANGE_KEYS.above]: '#93c47d' },
          getColorIndexValue: this.discretizer({ maxValue: 0.1, minValue: -0.3, key: `norm__${i.key}` }),
        })),
      ];
    },
    noRows() {
      return this.rows.length === 0;
    },
    rows() {
      return Object.values(this.evaluableStudents).map(student => ({
        ...student,
        ...this.resultsByStudent[student.id].reduce((obj, result) => {
          const { key: indicatorKey, format: indicatorFormat } = this.indicators.find(i => i.id === result.indicator_id);
          return {
            ...obj,
            [indicatorKey]: this.formatValueWithIndicator(result.value, indicatorFormat),
            [`norm__${indicatorKey}`]: result.norm_value,
          };
        }, {}),
      })).sort((a, b) => a.full_name.localeCompare(b.full_name));
    },
    resultsByStudent() {
      return groupBy(this.results, 'evaluable_student_id');
    },
    school() {
      return this.currentUser.schools.find(school => school.id.toString() === this.$route.params.school);
    },
    schoolRbd() {
      return this.school.rbd;
    },
    aptusLink() {
      return `https://www.aptuschile.cl/apt_system/AptusDigitalResultados/indexResultadoColegio/${this.schoolRbd}`;
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true, 'group_ids': true, 'months': true });
      this.setReportKey('internal-evaluations-by-student');
    },
    discretizer({ maxValue, minValue, key }) {
      return function (row) {
        const value = row[key];
        if (value > maxValue) return RANGE_KEYS.above;
        else if (value < minValue) return RANGE_KEYS.below;
        return RANGE_KEYS.within;
      };
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.evaluations-table {
  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
    }
  }
  tbody {
    tr:hover {
      td {
        background-color: #f9f9fd;
      }
    }
  }
}
</style>
