<template>
  <rdr-dialog
    :visible.sync="show"
    :class="$style.filters"
    append-to-body
    @closed="closed"
  >
    <div slot="title">
      <h2 v-if="!uploadedFileStatus">
        Subida de archivos<br>
        <small>{{ school.name | startCase }}</small>
      </h2>
      <h2 v-else-if="errors.length > 0">
        Archivo no subido debido a errores
      </h2>
      <h2 v-else>
        Archivo subido con éxito y listo para ser procesado
      </h2>
    </div>

    <div
      v-if="!uploadedFileStatus"
      :class="$style.contencols">
      <div :class="$style.cols1">
        <!-- <a
          v-if="managementSystemIsRadar"
          :href="manualUrl"
          target="_blank">Manual de uso</a> -->
        <tabs
          :tabs="tabs"
          v-model="fileType"
          :new-style="true"
        />
      </div>
      <div :class="$style.cols2">
        <div :class="$style.contencol2">
          <div :class="$style.fileUploaderMenu">
            <div v-if="!showFileHistory && managementSystemIsSchoolTrack">
              <p>* Solo puedes subir archivos obtenidos con la consulta entregada<br>por Radar (un archivo .txt)</p>
            </div>

            <div
              v-else
              :class="$style.filesContainer">
              <div :class="$style.rowContainer">
                <rdr-picker
                  :options="fileKeyOptions"
                  :value="chosenFileKey"
                  :on-change="pickKey"
                  :loading="submitting"
                  :size="pickerSize"
                  :empty-choice="true"
                  :no-choice="emptyChoiceMessage"
                  :no-options="noOptionsMessage"/>
              </div>
              <div
                v-if="enabledUpload"
                :class="$style.rowContainer">
                <a
                  v-if="latestFileUploaded"
                  :href="latestFileUploaded">
                  <button
                    class="rdr-btn rdr-btn--primary"
                    title="Descargar"
                    target="_blank">
                    <span class="material-icons">file_download</span>
                    Bajar último archivo cargado
                  </button>
                </a>
                <a
                  v-if="fileTemplate"
                  :href="fileTemplate">
                  <button
                    :class="[
                      'rdr-btn',
                      {'rdr-btn--primary': !latestFileUploaded}]"
                    title="Descargar"
                    target="_blank">
                    <span class="material-icons">file_download</span>
                    Bajar archivo de ejemplo
                  </button>
                </a>
              </div>
              <div
                v-else-if="!enabledUpload && latestFileUploaded || fileTemplate">
                <h3>Calificaciones por estudiante</h3>
                <div :class="$style.labelContainer">
                  <a
                    v-if="latestFileUploaded"
                    :href="latestFileUploaded">
                    <button
                      class="rdr-btn rdr-btn--primary"
                      title="Descargar"
                      target="_blank">
                      <span class="material-icons">file_download</span>
                      Bajar archivo #4 de calificaciones
                    </button>
                  </a>
                </div>
                <h3>Situación final de promoción de los estudiantes</h3>
                <div :class="$style.labelContainer">
                  <a
                    v-if="fileTemplate"
                    :href="fileTemplate">
                    <button
                      class="rdr-btn rdr-btn--primary"
                      title="Descargar"
                      target="_blank">
                      <span class="material-icons">file_download</span>
                      Bajar archivo #5 de promoción
                    </button>
                  </a>
                  <br>
                  <span>
                    Dadas las contingencias del 2020, la asistencia de este archivo solo refleja clases presenciales de marzo.
                    Recuerda que puedes editarlo de acuerdo a las políticas definidas por tu establecimiento.
                    Puedes encontrar las instrucciones en el
                    <a
                      :href="manualUrl"
                      target="_blank">
                      manual de uso
                    </a>.
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="validOptionSelected || managementSystemIsSchoolTrack"
              :class="$style.rowContainer">
              <span v-if="showSigeSubjectsFile">
                Aquí puedes encontrar las fuentes oficiales para
                <a :href="sigeSubjectsFileUrl">los códigos de las asignaturas</a>,
                y los <a :href="sigeEducationTypesUrl">códigos de enseñanza</a>.
              </span>
              <div v-if="submitting">
                <transition name="fade">
                  <loading-overlay/>
                </transition>
              </div>
              <div
                v-else-if="enabledUpload"
                :class="$style.fileUploaderArea">
                <input
                  type="file"
                  accept=".csv, .txt, .xls, .xlsx"
                  @change="handleFileUpload">
                <div>
                  <div
                    v-if="!file"
                    :class="$style.fileUploaderAreaContent">
                    <p>Arrastra el archivo de {{ fileTypeName }} aquí</p>
                    <p>o</p>
                    <p>Clickea para seleccionarlo</p>
                  </div>
                  <div
                    v-else
                    :class="$style.fileUploaderAreaContent">
                    <i class="material-icons">insert_drive_file</i>
                    <p>{{ file.name }}</p>
                  </div>
                </div>
              </div>
              <button
                v-if="enabledUpload"
                :disabled="submitting || !file"
                class="rdr-btn rdr-btn--primary"
                @click="uploadFile">
                Subir archivo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="errors.length > 0">
      <ul :class="$style.errorAndWarningList">
        <li
          v-for="(error, index) in errors"
          :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <div
      v-else
      :class="$style.confirmationBox">
      <div v-if="warnings.length > 0">
        <h2>Puntos importantes a tener en cuenta sobre el archivo que estás subiendo:</h2>
        <ul :class="$style.errorAndWarningList">
          <li
            v-for="(warning, index) in warnings"
            :key="index">
            {{ warning }}
          </li>
        </ul>
      </div>
      <h3>¿Desea procesar el archivo?</h3>
      <div :class="$style.buttonRow">
        <button
          class="rdr-btn"
          @click="closed">
          No
        </button>
        <button
          class="rdr-btn rdr-btn--primary"
          @click="proccesFile"
        >
          Sí
        </button>
      </div>
    </div>
  </rdr-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { upperFirst } from 'lodash';
import Tabs from '../tabs';
import ComingSoon from '../utils/coming-soon';
import UserUploadsApi from '../../api/user-uploads';
import PermissionMixin from '../../mixins/permission';

const TAB_MAPPINGS = {
  'school_track_legacy': {
    grades: {
      text: 'calificaciones',
      hasFiles: false,
      enabled: true,
    },
    attendance: {
      text: 'inasistencia',
      hasFiles: false,
      enabled: true,
    },
    enrollment: {
      text: 'nómina (matrícula)',
      hasFiles: false,
      enabled: true,
    },
    holidays: {
      text: 'feriados',
      hasFiles: true,
      enabled: true,
    },
  },
  radar: {
    students: {
      text: '0. Subir Matrícula',
      hasFiles: true,
      enabled: true,
    },
    config: {
      text: '0. Configurar Asignaturas',
      hasFiles: true,
      enabled: true,
    },
    grades: {
      text: '1. Subir Calificaciones',
      hasFiles: true,
      enabled: true,
    },
    sige: {
      text: '2. Archivos SIGE',
      hasFiles: true,
      enabled: false,
      permission: 'radar_generated_sige_files',
    },
  },
};

const POLLING_INTERVAL = 2000;

export default {
  name: 'FileUploader',
  components: {
    ComingSoon,
    Tabs,
  },
  mixins: [PermissionMixin],
  props: {
    school: {
      type: Object,
      default: () => ({}),
    },
    managementSystem: {
      type: String,
      required: true,
    },
    updateFunction: {
      type: Function,
      required: false,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      submitting: false,
      errors: [],
      warnings: [],
      fileType: 'grades',
      checkerJobId: null,
      uploadedFileStatus: null,
      schoolFiles: {},
      chosenFileKey: null,
      sigeSubjectsFileUrl: 'https://wwwfs.mineduc.cl/Archivos/Sige/Docs/SubSectores.xls',
      sigeEducationTypesUrl: 'https://wwwfs.mineduc.cl/Archivos/Sige/Docs/Descripcion_grados.xls',
      manualUrl: 'https://docs.google.com/presentation/d/1G8yMQ8nHjlsoOrL9hEy6AFA0XlFS8PUvhS2eZ11mV8Y/',
      techSupportEmail: 'radar@colegium.com',
    };
  },
  computed: {
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
    showSigeSubjectsFile() {
      return this.fileType === 'config';
    },
    fileKeyOptions() {
      let templateKeys = this.schoolFiles[this.fileType].file_template.map(f => f.key);
      if (templateKeys.length === 0 && this.fileType === 'sige') {
        templateKeys = this.schoolFiles[this.fileType].latest_file_uploaded.map(f => f.key);
      }
      return templateKeys;
    },
    tabMappings() {
      return TAB_MAPPINGS.hasOwnProperty(this.managementSystem) ?
        TAB_MAPPINGS[this.managementSystem] : {};
    },
    managementSystemIsSchoolTrack() {
      return this.managementSystem === 'school_track_legacy';
    },
    managementSystemIsRadar() {
      return this.managementSystem === 'radar';
    },
    validOptionSelected() {
      return this.chosenFileKey !== null && this.chosenFileKey !== '';
    },
    emptyChoiceMessage() {
      if (this.managementSystemIsSchoolTrack) return 'Selecciona el archivo a subir...';
      switch (this.fileType) {
      case 'config':
        return 'Selecciona el año a configurar...';
      case 'grades':
        return 'Selecciona el curso y asignatura a subir...';
      case 'sige':
        return 'Selecciona el año a exportar...';
      case 'students':
        return 'Selecciona el año para subir la nómina...';
      default:
        return 'Selecciona el archivo a subir...';
      }
    },
    noOptionsMessage() {
      if (this.managementSystemIsSchoolTrack) return 'sin datos';
      switch (this.fileType) {
      case 'config':
      case 'students':
        return `No está habilitado este proceso para tu colegio. \
        Si esto es un error, contáctate con ${this.techSupportEmail}`;
      case 'grades':
        return 'Todavía no se ha completado el paso 0';
      case 'sige':
        return 'Todavía no se han subido calificaciones';
      default:
        return 'sin datos';
      }
    },
    downloadLatestUploadMessage() {
      switch (this.fileType) {
      case 'sige':
        return 'Bajar';
      default:
        return 'Bajar último archivo cargado';
      }
    },
    pickerSize() {
      return this.validOptionSelected ? 'small' : 'medium';
    },
    tabs() {
      const tabs = Object.entries(this.tabMappings).map(([key, data]) => (
        { key, text: upperFirst(data.text), icon: '', permission: data.permission }
      ));
      return tabs.filter((tab) => (
        tab.permission === undefined || this.hasPermission(tab.permission)
      ));
    },
    fileTypeName() {
      return this.tabMappings[this.fileType].text;
    },
    enabledUpload() {
      return this.tabMappings[this.fileType].enabled;
    },
    latestFileUploaded() {
      if (!this.showFileHistory) return null;
      const { latest_file_uploaded: latestFileUploaded } = this.schoolFiles[this.fileType];
      const chosenFile = latestFileUploaded.find(f => f.key === this.chosenFileKey);
      return chosenFile && chosenFile.file;
    },
    fileTemplate() {
      if (!this.showFileHistory) return null;
      const { file_template: fileTemplate } = this.schoolFiles[this.fileType];
      const chosenFile = fileTemplate.find(f => f.key === this.chosenFileKey);
      return chosenFile && chosenFile.file;
    },
    showFileHistory() {
      return (
        this.tabMappings[this.fileType].hasFiles &&
        this.schoolFiles && Object.keys(this.schoolFiles).length && this.schoolFiles[this.fileType]
      );
    },
  },
  watch: {
    uploadedFileStatus(to, from) {
      if (to !== from && (to === 'ready' || to === 'failed')) {
        this.submitting = false;
        clearInterval(this.pollingIntervalId);
      }
    },
    school(to) {
      this.updateHolidayFiles(to);
    },
    fileType() {
      this.pickKey(null);
    },
  },
  mounted() {
    this.updateHolidayFiles(this.school);
  },
  methods: {
    ...mapActions('ui', { openDialog: 'showFeedbackDialog', hideDialog: 'hideDialog' }),
    pickKey(key) {
      this.chosenFileKey = key;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (/\.(csv|txt|xls|xlsx)$/.test(file.name)) {
        this.file = file;
      }
    },
    async uploadFile() { // eslint-disable-line max-statements
      await this.submitFile();
      this.pollingIntervalId = setInterval(async () => {
        try {
          const response = await UserUploadsApi.checkUploadedFileStatus(
            this.school.id, this.checkerJobId
          );
          if (!!response.body.errors && !!response.body.warnings) {
            this.errors = response.body.errors;
            this.warnings = response.body.warnings;
            this.uploadedFileStatus = 'ready';
          }
        } catch (err) {
          this.uploadedFileStatus = 'failed';
        }
      }, POLLING_INTERVAL); // eslint-disable-line no-magic-numbers
    },
    /* eslint-disable max-statements */
    async submitFile() {
      this.submitting = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('school_id', this.school.id);
      formData.append('file_type', this.fileType);
      formData.append('year', this.chosenFileKey);
      try {
        const response = await UserUploadsApi.uploadFile(formData);
        this.checkerJobId = response.body.checker_job_id;
      } catch (err) {
        switch (err.status) {
        case 400: // eslint-disable-line no-magic-numbers
          this.openDialog('Error: El archivo pesa más de 100 MB');
          break;
        case 401: // eslint-disable-line no-magic-numbers
          this.openDialog('Error de credenciales. Por favor, inicia sesión.');
          break;
        case 403: // eslint-disable-line no-magic-numbers
          this.openDialog('No estás autorizado para subir esto o has intentado subir demasiadas veces el archivo durante el día.');
          break;
        default:
          this.openDialog('Ocurrió un error. Por favor inténtalo nuevamente.');
        }
        this.uploadedFileStatus = 'failed';
        this.closeDialog();
      }
    },
    /* eslint-enable max-statements */

    async proccesFile() {
      await UserUploadsApi.confirmFileUpload(this.school.id, this.checkerJobId, this.file_type);
      this.openDialog('El archivo se procesará en unos minutos');
      this.closeDialog();
    },
    closeDialog() {
      this.show = false;
      this.$router.replace({ 'query': null });
      setTimeout(this.hideDialog, 3000, 'feedback'); // eslint-disable-line no-magic-numbers
    },
    closed() {
      clearInterval(this.pollingIntervalId);
      this.$router.replace({ 'query': null });
      this.submitting = false;
      this.file = null;
      this.checkerJobId = null;
      this.uploadedFileStatus = null;
      this.errors = [];
      this.warnings = [];
    },
    updateHolidayFiles({ files }) {
      this.schoolFiles = files[this.managementSystem];
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  $file-uploader-area-color: #e2e2ea;
  $file-uploader-area-border-color: #888888;
  $file-uploader-area-height: 150px;

  .title {
    text-align: left;
    word-break: keep-all;
  }

  .file-uploader-menu {
    text-align: center;
    word-break: keep-all;
    width: 100%;

    h1 {
      font-size: 24px;
      font-weight: 300;
    }

    h2 {
      font-weight: 400;
      padding-bottom: 10px;
    }

    button {
      margin-top: 20px;
    }

    ul {
      display: flex;
      justify-content: flex-start;

      li {
        font-weight: bold;
      }
    }

    .file-uploader-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px dashed $file-uploader-area-border-color;
      border-radius: 10px;
      background: $file-uploader-area-color;
      height: $file-uploader-area-height;
      cursor: pointer;

      input {
        opacity: 0;
        right: 42px;
        height: $file-uploader-area-height;
        width: 63%;
        position: absolute;
        cursor: pointer;
      }

      .file-uploader-area-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 1.2em;
          height: 10px;
        }

        i {
          font-size: 50px;
        }
      }
    }
  };

  .error-and-warning-list {
    max-height: 40vh;
    overflow-y: auto;
    word-break: keep-all;
    margin-top: 15px;

    li {
      font-size: 1.1em;
      margin-bottom: 10px;
      max-width: 99%;
    }
  }

  .confirmationBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttonRow {
      width: 50%;
      align-self: center;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .files-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 5px;
    justify-content: space-evenly;

    .row-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .label-container {
      margin-top: -25px;
      margin-bottom: 40px;
    }

    a {
      text-decoration: none;
      cursor: pointer;
      border-bottom: none;
    }
  }

  .filters {
    :global(.el-dialog) {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60%;
      margin-left: 40%;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 100%;

      @media only screen and (max-width: $mobile-breakpoint) {
        width: 95%;
      }
    }

    :global(.el-dialog__header) {
      margin-top: 0px;
      text-align: left;
      padding-top: 3px;
    }

    :global(.el-dialog__body) {
      height: 91%;
      padding-top: 0px;
    }
    a {
      border-bottom: none !important;
    }
  }
  .contencols {
    display: flex !important;
    height: 84%;
  }
  .cols1 {
    width: 29%;
  }
  .cols2 {
    width: 70%;
    padding: 0px 0px 10px 10px;
    display: flow-root;
    height: 100%;
  }
  .contencol2 {
    background: #E7EFFF;
    border-radius: 10px;
    height: 100%;
    margin-top: 9px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px
  }
</style>
