<template>
  <div class="report">
    <alert-info :text-alert="textData" :icon-viwe="false"/>
    <div class="rdr-view comparative-view">
      <div class="options">
        <print-button
          v-if="isLoggedIn"
          :loading="loading"
          primary/>
      </div>
      <rdr-results-header
        :tabs="tabs"
        :year-picker="isColorMap"
        mobile-only-picker
        historic-group-picker>
        <!-- <div
          v-if="isColorMap"
          class="results-header__subtitle">Este mapa de color muestra los resultados SIMCE, PSU y otros indicadores de tu colegio en comparación con otros establecimientos.</div>
        <div
          v-if="isHistorical"
          class="results-header__subtitle">Este gráfico te permite comparar la evolución de diferentes indicadores en múltiples colegios a lo largo del tiempo.</div> -->
      </rdr-results-header>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResultsHeader from '../../components/results/results-header.vue';
import PrintButton from '../../components/utils/print-button.vue';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'PublicEvaluationsComparativeView',
  components: {
    'rdr-results-header': ResultsHeader,
    PrintButton,
    AlertInfo,
  },
  data() {
    return {
      textData: '',
    };
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('results', {
      loading: state => !!state.loading,
    }),
    tabs() {
      return [
        { icon: 'today', name: 'Anual', link: this.colorMapLink },
        { icon: 'show_chart', name: 'Histórico', link: this.historicalLink },
      ];
    },
    colorMapLink() {
      return { name: 'comparativeColorMap', params: this.$route.params };
    },
    historicalLink() {
      return { name: 'comparativeHistorical', params: this.$route.params };
    },
    isColorMap() {
      this.textData = 'Este mapa de color muestra los datos públicos y otros indicadores de tu colegio en comparación con otros establecimientos.';
      return this.$route.name === 'comparativeColorMap';
    },
    isHistorical() {
      this.textData = 'Este gráfico te permite comparar la evolución de diferentes indicadores en múltiples colegios a lo largo del tiempo.';
      return this.$route.name === 'comparativeHistorical';
    },
  },
};
</script>

<style lang="scss">
.comparative-view .options {
  // display: flex;
  // justify-content: flex-end;
  // margin-bottom: 20px;
  position: relative;
  float: right;
  margin-right: 35px;
  margin-top: -9px;
}
</style>
