<template>
  <div :class="$style.comingSoon">
    <div :class="$style.panel">
      <h2>No habilitado</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
};
</script>

<style lang="scss" module>
  .coming-soon {
    .panel {
      background-color: whitesmoke;
      border-radius: 5px;
      text-align: center;
      padding: 50px;
      margin: 0 auto;
    }
  }
</style>
