<template>
  <div
    :class="[
      $style.box,
      { [$style.big] : size === 'big' },
    ]"
    :style="boxStyle">
    <div
      v-if="falseyExceptZero"
      :class="{ [$style.dash] : size !== 'big' }">—</div>
    <div v-else-if="icon !== null">
      <i
        class="material-icons material-icons-white">
        {{ icon }}
      </i>
    </div>
    <div
      v-else-if="text === null"
      :class="{ [$style.text] : size !== 'big' }">
      {{ customFilter(value, format) }}
    </div>
    <div
      v-else
      :class="{ [$style.text] : size !== 'big' }">
      {{ text }}
    </div>

  </div>
</template>

<script>
import { PRIMARY_COLOR } from '../../utils/style-variables';
import interpolate from '../../utils/color-interpolate';
import toGreyScale from '../../utils/grey-scale';

export default {
  name: 'DataBox',
  props: {
    value: {
      type: [Number, Boolean, String],
      default: 0,
    },
    normValue: {
      type: [Number, String],
      default: 0,
    },
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    colors: {
      type: Array,
      default: () => [PRIMARY_COLOR],
    },
    format: {
      type: String,
      default: null,
    },
    maxValue: {
      type: Number,
      default: 1.5,
    },
    minValue: {
      type: Number,
      default: -1.5,
    },
    size: {
      type: String,
      default: 'regular',
    },
    backgroundType: {
      type: String,
      default: 'interpolate',
    },
    discreteColors: {
      type: Object,
      default: () => ({}),
    },
    colorIndexValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    shade() {
      if (this.backgroundType === 'discrete') return () => ({});
      return value => (typeof value === 'number' ? interpolate(this.colors)(value) : '#ffffff');
    },
    boxStyle() {
      const obj = { borderColor: 'transparent' };
      if (!this.falseyExceptZero) {
        obj.backgroundColor = this.backgroundColor;
        obj.borderColor = this.backgroundColor;
        obj.textAlign = 'center';
        obj.color = this.textColor;
      }

      return obj;
    },
    falseyExceptZero() {
      return !(!!this.value || this.value === 0);
    },
    backgroundColor() {
      if (this.backgroundType === 'discrete') {
        return this.discreteColors[this.colorIndexValue];
      }
      const range = Math.abs(this.maxValue - this.minValue);
      if (typeof this.normValue === 'string' || Number.isNaN(this.normValue)) return '#ffffff';
      const normalizedValue = (this.normValue - this.minValue) / range;
      return this.shade(normalizedValue);
    },
    textColor() {
      const divisor = 200;
      const colorOptions = [255, 128]; // eslint-disable-line no-magic-numbers
      const newGrey = toGreyScale(this.backgroundColor, colorOptions, divisor);
      return `rgb(${newGrey})`;
    },
  },
  methods: {
    customFilter(value, format) {
      if (value === null) return null;
      if (format) return this.$options.filters[format](value);

      return value;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.box {
  display: block;
  padding: 3px;
  min-width: 55px;
  height: 30px;
  border-radius: 15px;
  font-weight: 200;

  @media print {
    border-style: solid;
    border-width: 2px;
  }
}

.text {
  margin-top: 3px;
  font-weight: normal;
}

.dash {
  margin-top: 3px;
  text-align: center;
}

.big {
  width: 65px;
  padding: 1px;
  font-size: 22px;
  font-weight: bold;
}

.pro-tooltip {
  padding: 0px 6px;
  margin: 5px;
  border-radius: 10px;
  user-select: none;
  outline: none;
  cursor: help;
}

</style>

