import SignUpApi from './../../api/sign-up';

export default {
  namespaced: true,
  state: {
    signUpError: false,
    signUpErrorMessage: '',
    signUpErrorCode: null,
    isLoggedIn: !!localStorage.getItem('token_radar'),
    showSignUpDialog: false,
  },
  getters: {

  },
  mutations: {
    ERROR(state, err) {
      state.signUpError = true;
      state.signUpErrorMessage = err.body;
      state.signUpErrorCode = err.status;
    },
    CLEAN_ERROR(state) {
      state.signUpError = false;
      state.signUpErrorMessage = '';
      state.signUpErrorCode = null;
    },
    SHOW_SIGN_UP_DIALOG(state, value) {
      state.showSignUpDialog = value;
    },
  },
  actions: {
    register({ commit }, userData) {
      commit('CLEAN_ERROR');

      return new Promise((resolve, reject) => {
        SignUpApi.submit(userData)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            commit('ERROR', err);
            reject();
          });
      });
    },
    showSignUpDialog({ commit }, value) {
      commit('SHOW_SIGN_UP_DIALOG', value);
    },
    closeSignUpDialog({ commit }) {
      commit('SHOW_SIGN_UP_DIALOG', false);
    },
  },
};
