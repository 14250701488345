<template>
  <div v-if="loading">
    <transition name="fade">
      <loading-overlay v-if="loading" />
    </transition>
  </div>
  <div v-else-if="chosenSchoolIds">
    <div>
      <div>
        <div :class="$style.dashboardElementsAggregateBox">
          <aggregate-box
            :value="schoolStudentsCount"
            :progress="100"
            text="estudiantes"
          />
          <aggregate-box
            v-if="schoolHasRepetition"
            :value="schoolRepetitionPercentage"
            :progress="schoolRepetitionPercentage"
            :color="REPETITION_COLOR"
            format="percentageWithOneDecimal"
            text="repitentes"
          />
          <aggregate-box
            v-else-if="repetitionRiskPresent"
            :value="schoolRepetitionRiskPercentage"
            :progress="schoolRepetitionRiskPercentage"
            :color="REPETITION_COLOR"
            :info-tooltip="repetitionRiskDecree67Tooltip"
            :text="shortRepetitionRiskLabel.toLowerCase()"
            format="percentageWithOneDecimal"
          />
          <aggregate-box
            v-if="!useOnSiteAttendances"
            :value="schoolChronicAbsenteeism"
            :progress="schoolChronicAbsenteeism"
            :color="CHRONIC_COLOR"
            format="percentageWithOneDecimal"
            text="ausentes crónicos"
          />
          <aggregate-box
            :value="attendanceResult"
            :progress="attendanceResult"
            :color="ATTENDANCE_COLOR"
            :info-tooltip="attendanceAggregateBoxInfoTooltip"
            :format="attendanceAggregateBoxFormat"
            :text="attendanceAggregateBoxText"
          />
        </div>
        <div :class="$style.dashboardElementsIndicatorBox">
          <indicator-box
            v-for="indicator in indicators"
            :key="indicator.name"
            :color="indicator.color"
            :objects="schoolsAsObject"
            :values="indicator.values"
            :ordered-objects="schoolsAsRows.map(school => school.id)"
            :title="indicator.name"
            :max-value="indicator.maxValue"
            :reverse-sort="indicator.reverseSort"
            :info-tooltip="indicator.infoTooltip"
            :legends="indicator.legends"
            :show-complement="indicator.showComplement"
            :interpolate-colors="indicator.interpolateColors"
            :has-total="indicator.hasTotal"
            :aggr-value="indicator.aggrValue"
            :aggr-format="indicator.aggrFormat"
            :aggr-info="indicator.aggrInfo"
            :show-last-value="indicator.showLastValue"
            :sort-func="indicator.sortFunc"
            object-type="school"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { uniqBy } from 'lodash';
import SchoolsApi from '../../api/schools';
import AggregateBox from '../../components/dashboard/aggregate-box';
import IndicatorBox from '../../components/dashboard/indicator-box';
import schoolYear from '../../utils/years';
import TooltipInfoMixin from '../../mixins/tooltip-info';
import Decree67Mixin from '../../mixins/decree-67';
import {
  REPETITION_COLOR, ATTENDANCE_COLOR, CHRONIC_COLOR, RED_COLOR, MALE_COLOR, FEMALE_COLOR, DARK_GRAY_COLOR,
  PIE_COLOR, PREFERENTIAL_COLOR, PRIORITY_COLOR, LOW_READING_QUALITY, UNDERPERFORMING_COLOR, SUCCESS_COLOR,
  PIE_ADDITIONAL_COLOR, SEMAPHORE_COLORS, SURVEY_COLORS, PLANNED_CLASSES_COLOR, LEARNING_OBJECTIVE_COVERAGE_COLOR,
  PULSO_COLOR,
} from '../../utils/style-variables';
import { groupBy } from '../../utils/methods';

export default {
  name: 'AdministratorReportDashboard',
  components: {
    AggregateBox,
    IndicatorBox,
  },
  mixins: [TooltipInfoMixin, Decree67Mixin],
  data() {
    return {
      schoolResults: [],
      REPETITION_COLOR,
      ATTENDANCE_COLOR,
      CHRONIC_COLOR,
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolIds', 'chosenYear']),
    ...mapState('results', ['loading']),
    ...mapGetters('options', { schools: 'chosenSchools' }),
    localIndicators() {
      return [
        { key: 'porcentaje-asistencia', id: 1, label: 'Promedio de asistencia', attribute: 'attendance_average' },
        { key: 'numero-alumnos-colegio', id: 2, label: 'Número de estudiantes', attribute: 'student_count' },
        { key: 'porcentaje-riesgo-repitencia-colegio', id: 3, label: 'Estudiantes en riesgo de repitencia', attribute: 'repetition_risk_percentage' },
        { key: 'porcentaje-inasistencia-cronica-acumulada', id: 4, label: '% Ausentismo crónico', attribute: 'chronic_absenteeism_average' },
        { key: 'porcentaje-alumnos-pie-colegio', id: 5, label: 'Estudiantes PIE', attribute: 'pie_percentage' },
        { key: 'porcentaje-alumnos-preferentes-colegio', id: 6, label: 'Estudiantes preferentes', attribute: 'preferential_percentage' },
        { key: 'porcentaje-alumnos-prioritarios-colegio', id: 7, label: 'Estudiantes prioritarios', attribute: 'priority_percentage' },
        { key: 'numero-alumnos-riesgo-repitencia-colegio', id: 8, label: 'Estudiantes en riesgo de repitencia', attribute: 'repetition_risk_count' },
        { key: 'porcentaje-alumnos-bajo-rendimiento-emn-colegio', id: 9, label: 'Estudiantes bajo desempeño EMN', attribute: 'emn_underperforming_percentage' },
        { key: 'porcentaje-alumnos-bajo-rendimiento-pdn-colegio', id: 10, label: 'Estudiantes bajo desempeño PDN', attribute: 'pdn_underperforming_percentage' },
        { key: 'porcentaje-alumnos-bajo-rendimiento-pdd-colegio', id: 11, label: 'Estudiantes bajo desempeño PDD', attribute: 'pdd_underperforming_percentage' },
        { key: 'porcentaje-alumnos-bajo-rendimiento-mda-colegio', id: 12, label: 'Estudiantes bajo desempeño MDA', attribute: 'mda_underperforming_percentage' },
        { key: 'porcentaje-de-alumnos-bajo-esperado-calidad-lectora-por-grupo', id: 13, label: '% bajo nivel esperado en calidad lectura', attribute: 'low_reading_quality_percentage' },
        { key: 'numero-alumnos-repitentes-colegio', id: 14, label: 'Estudiantes repitentes', attribute: 'repetition_count' },
        { key: 'porcentaje-alumnos-repitentes-colegio', id: 15, label: 'Estudiantes repitentes', attribute: 'repetition_percentage' },
        { key: 'porcentaje-mujeres', id: 16, label: 'Hombres y mujeres', attribute: 'female_percentage' },
        { key: 'porcentaje-hombres', id: 17, label: 'Hombres y mujeres', attribute: 'male_percentage' },
        { key: 'numero-alumnos-prioritarios-colegio', id: 18, label: 'Estudiantes prioritarios', attribute: 'priority_count' },
        { key: 'numero-alumnos-preferentes-colegio', id: 19, label: 'Estudiantes preferentes', attribute: 'preferential_count' },
        { key: 'cantidad-mujeres', id: 20, label: 'Hombres y mujeres', attribute: 'female_count' },
        { key: 'cantidad-hombres', id: 21, label: 'Hombres y mujeres', attribute: 'male_count' },
        { key: 'evaluacion-directiva-salesianos', id: 22, label: 'Liderazgo Directivo', attribute: 'salesianos_leadership_evaluation' },
        { key: 'cantidad-pie-oficiales', id: 23, label: 'Pie Oficiales', attribute: 'pie_count' },
        { key: 'cantidad-pie-adicionales', id: 24, label: 'Pie Adicionales', attribute: 'pie_additional_count' },
        { key: 'porcentaje-alumnos-pie-adicionales', id: 25, label: 'Estudiantes PIE Adicionales', attribute: 'pie_additional_percentage' },
        { key: 'evaluaciones-pie', id: 26, label: 'Información evaluaciones PIE', attribute: 'pie_evaluations' },
        { key: 'numero-alumnos-repitentes-historico-colegio', id: 27, label: 'Estudiantes repitentes', attribute: 'historical_repetition_count' },
        { key: 'porcentaje-alumnos-repitentes-historico-colegio', id: 28, label: 'Estudiantes repitentes', attribute: 'historical_repetition_percentage' },
        { key: 'porcentaje-clases-planificadas-poptimize', id: 29, label: 'Porcentaje de clases planificadas', attribute: 'poptimize_planned_classes_percentage' },
        { key: 'porcentaje-oas-cubiertos-poptimize', id: 30, label: 'Porcentaje de OAs cubiertos', attribute: 'poptimize_learning_objective_coverage_percentage' },
        { key: 'promedio-asistencia-presencial', id: 31, label: 'Promedio de asistencia presencial', attribute: 'on_site_attendance_average' },
        { key: 'numero-de-alumnos-total-calidad-lectora-por-grupo', id: 32, label: 'Número de estudiantes total calidad lectora', attribute: 'reading_quality_total' },
        { key: 'alertas-pulso-escolar', id: 33, label: 'Alertas de Pulso Escolar', attribute: 'pulso_alerts' },
        ...(this.surveyAlertLocalIndicators),
      ];
    },
    values() {
      const values = this.schoolResults.map(schoolData =>
        this.localIndicators.map(indicator =>
          this.resultObject(indicator.key, schoolData)
        )
      );

      return [].concat(...values);
    },
    schoolsAsRows() {
      return this.schools
        .map(school => (
          {
            id: school.id,
            value: school.name,
            key: this.schoolData(school).key,
            link: {
              name: 'schoolReport',
              params: { school: school.id, year: this.chosenYear },
            },
          }
        ));
    },
    schoolsAsObject() {
      return this.schoolsAsRows.reduce((obj, item) => {
        obj[item.id] = { name: item.value, link: item.link, key: item.key };

        return obj;
      }, {});
    },
    useOnSiteAttendances() {
      return !this.hasAttendanceValues;
    },
    indicators() {
      return [
        { name: 'Estudiantes', values: this.studentsCountValues, maxValue: Math.max(this.studentsCountValues) },
        ...(this.schoolHasRepetition ? [{ name: 'Repitentes', values: this.repetitionValues, color: RED_COLOR }] : []),
        ...(this.showRepetitionRisk ? [{ name: this.shortRepetitionRiskLabel, values: this.repetitionRiskPercentageValues, color: REPETITION_COLOR, infoTooltip: this.repetitionRiskDecree67Tooltip }] : []),
        ...(this.useOnSiteAttendances ? [
          {
            name: 'Días asistencia presencial', values: this.onSiteAttendanceValues, color: ATTENDANCE_COLOR, maxValue: 100, reverseSort: true,
            hasTotal: false, infoTooltip: 'Promedio de total días de asistencia a clases presenciales por estudiante (acumulado anual)',
          },
        ] : [
          {
            name: 'Promedio asistencia', values: this.attendanceValues, color: ATTENDANCE_COLOR, maxValue: 100, reverseSort: true,
            infoTooltip: 'Tasa de asistencia de estudiantes en relación al total de días de clases impartidos',
          },
          {
            name: 'Ausentes crónicos', values: this.chronicAbsenteeismValues, color: CHRONIC_COLOR,
          },
        ]),
        ...(this.surveyAlertIndicators),
        ...(this.showPulsoIndicatorBox ? [{ name: 'Alertas de Pulso Escolar', values: this.pulsoAlertsValues, color: PULSO_COLOR, infoTooltip: 'PULSO ESCOLAR: Porcentaje de estudiantes con 2 o más alertas' }] : []),
        ...(this.showEMNindicatorBox ? [{ name: 'EMN Aptus bajo desempeño', values: this.emnUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba EMN Aptus rendida durante el año' }] : []),
        ...(this.showPDNindicatorBox ? [{ name: 'PDN Aptus bajo desempeño', values: this.pdnUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba PDN Aptus rendida durante el año' }] : []),
        ...(this.showPDDindicatorBox ? [{ name: 'PDD Aptus bajo desempeño', values: this.pddUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba PDD Aptus rendida durante el año' }] : []),
        ...(this.showMDAindicatorBox ? [{ name: 'MDA Aptus bajo desempeño', values: this.mdaUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba MDA Aptus rendida durante el año' }] : []),
        ...(this.showReadingQualityIndicatorBox ? [{ name: 'C. Lectora bajo desempeño', values: this.lowReadingQualityValues, color: LOW_READING_QUALITY, infoTooltip: 'Porcentaje de estudiantes con resultados bajo lo esperado en la última medición de Calidad Lectora' }] : []),
        ...(this.schoolHasHistoricalRepetition ? [{ name: 'Repitentes históricos', values: this.historicalRepetitionValues, color: REPETITION_COLOR, infoTooltip: this.historicalRepetitionTooltip(), ignoreIfEmpty: true, aggrValue: this.aggregatedHistoricalRepetitionValue, aggrFormat: 'percentage', aggrInfo: `${this.absoluteAggregatedHistoricalRepetitionValue} alumnos` }] : []),
        ...(this.hasGenderProportionValues ? [{ name: 'Proporción hombres y mujeres', values: this.genderProportionValues, showComplement: true, infoTooltip: this.genderProportionTooltip, color: [MALE_COLOR, DARK_GRAY_COLOR, FEMALE_COLOR], maxValue: 100, legends: [{ color: MALE_COLOR, label: 'M' }, this.anyNoInfoGender ? { color: DARK_GRAY_COLOR, label: 'S/I' } : {}, { color: FEMALE_COLOR, label: 'F' }], interpolateColors: false }] : []),
        ...(this.hasAdditionalPieValues ?
          [{
            name: 'PIE', values: this.pieWithAdditionalValues, color: [PIE_ADDITIONAL_COLOR, PIE_COLOR], infoTooltip: 'Porcentaje de estudiantes PIE por colegio. Se muestra el valor oficial obtenido desde SIGE', maxValue: this.pieWithAdditionalMaxValue, showLastValue: true, interpolateColors: false, hasTotal: true, aggrValue: this.pieMeanValue, aggrFormat: 'percentage', aggrInfo: this.pieAggInfo, legends: [{ color: PIE_ADDITIONAL_COLOR, label: 'Adicional' }, { color: PIE_COLOR, label: 'Oficial' }],
            sortFunc(a, b) {
              const sumA = a.value[0] || 0 + a.value[1] || 0;
              const sumB = b.value[0] || 0 + b.value[1] || 0;
              return sumA === sumB ? b.value[1] - a.value[1] : sumB - sumA;
            },
          }] : [{ name: 'PIE', values: this.piePercentageValues, color: PIE_COLOR, ignoreIfEmpty: true, hasTotal: true, aggrValue: this.pieMeanValue, aggrFormat: 'percentage', aggrInfo: this.pieAggInfo }]
        ),
        ...(this.hasPieEvaluations ? [{
          name: 'Evaluaciones PIE', values: this.pieEvaluations, infoTooltip: this.pieEvaluationsTooltip,
          legends: [{ color: SEMAPHORE_COLORS.high, label: 'Terminadas' }, { color: SEMAPHORE_COLORS.middle, label: 'En progreso' }, { color: SEMAPHORE_COLORS.low, label: 'No empezadas' }], color: [SEMAPHORE_COLORS.high, SEMAPHORE_COLORS.middle, SEMAPHORE_COLORS.low],
          maxValue: 100, showComplement: true, interpolateColors: false,
        }] : []),
        { name: 'Prioritarios', values: this.priorityValues, color: PRIORITY_COLOR, ignoreIfEmpty: true, hasTotal: true, aggrValue: this.aggregatedPriorityValue, aggrFormat: 'percentage', aggrInfo: `${this.abosulteAggregatedPriorityValue} alumnos` },
        { name: 'Preferentes', values: this.preferentialValues, color: PREFERENTIAL_COLOR, ignoreIfEmpty: true, hasTotal: true, aggrValue: this.aggregatedPreferentialValue, aggrFormat: 'percentage', aggrInfo: `${this.absoluteAggregatedPreferentialValue} alumnos` },
        { name: 'Liderazgo Directivo', values: this.salesianosLeadershipEvaluationValues, color: SUCCESS_COLOR, ignoreIfEmpty: true, reverseSort: true },
        ...(this.hasPoptimizePlannedClassesValues ? [{
          name: 'Porcentaje clases planificadas', values: this.poptimizePlannedClassesValues, color: PLANNED_CLASSES_COLOR, maxValue: 50, reverseSort: true, hasTotal: false, infoTooltip: this.poptimizePlannedClassesTooltip,
        }] : []),
        ...(this.hasPoptimizeLearningObjectiveCoverageValues ? [{
          name: 'Porcentaje OAs cubiertos', values: this.poptimizeLearningObjectiveCoverageValues, color: LEARNING_OBJECTIVE_COVERAGE_COLOR, maxValue: 75, reverseSort: true, hasTotal: false, infoTooltip: this.poptimizeLearningObjectiveCoverageTooltip,
        }] : []),
      ].filter(indicator => !indicator.ignoreIfEmpty || this.sumArray(indicator.values) > 0);
    },
    surveyAlertIndicators() {
      return this.surveyAlertLocalIndicators.map((surveyAlert, index) => ({
        name: surveyAlert.label,
        values: this.surveyAlertValues[surveyAlert.id],
        color: SURVEY_COLORS[index],
        ignoreIfEmpty: true,
        infoTooltip: this.surveyAlertsTooltip,
      }));
    },
    surveyAlerts() {
      const alerts = this.schoolResults.flatMap(schoolData => (
        schoolData.survey_alerts ?
          schoolData.survey_alerts.map(alert => (
            { label: alert.label, key: alert.key, attribute: alert.key }
          )) : []
      ));
      return uniqBy(alerts, 'key');
    },
    surveyAlertLocalIndicators() {
      const baseAlertIndex = 1000;
      return this.surveyAlerts.map((alert, index) => (
        { ...alert, id: baseAlertIndex + index }
      ));
    },
    surveyAlertValues() {
      const values = this.schoolResults.flatMap(schoolData => (
        schoolData.survey_alerts ?
          schoolData.survey_alerts.map(alert => {
            const value = alert.students_with_alerts_percentage > 0 ? alert.students_with_alerts_percentage : null;
            const extraInfo = alert.count > 0 ? alert.count : null;
            return {
              indicatorId: this.getIndicator(alert.key).id,
              objectId: schoolData.id,
              value,
              format: value === null ? null : { string: 'percentage' },
              extraInfo: extraInfo === null ? null : `${extraInfo} alertas.`,
              norm_value: null, // eslint-disable-line camelcase
            };
          }) : []
      ));
      return groupBy(values, 'indicatorId');
    },
    hasGenderProportionValues() {
      return this.genderProportionValues.some(({ value }) => !!value);
    },
    hasAdditionalPieValues() {
      return this.additionalPieCountValues.some(({ value }) => !!value);
    },
    hasPieEvaluations() {
      return this.pieEvaluations.some(({ value }) => !!value);
    },
    hasPoptimizePlannedClassesValues() {
      return this.poptimizePlannedClassesValues.some(({ value }) => !!value);
    },
    hasPoptimizeLearningObjectiveCoverageValues() {
      return this.poptimizeLearningObjectiveCoverageValues.some(({ value }) => !!value);
    },
    hasAttendanceValues() {
      return this.attendanceValues.some(({ value }) => !!value);
    },
    attendanceAggregateBoxInfoTooltip() {
      return this.useOnSiteAttendances ? 'Promedio simple de la asistencia presencial de todos los colegios asociados a tu cuenta' : 'Promedio simple de la asistencia de todos los colegios asociados a tu cuenta';
    },
    attendanceAggregateBoxFormat() {
      return this.useOnSiteAttendances ? 'numberWithOneDecimal' : 'percentageWithOneDecimal';
    },
    attendanceAggregateBoxText() {
      return this.useOnSiteAttendances ? 'días asistencia presencial' : 'prom. asistencia';
    },
    hasOnSiteAttendanceValues() {
      return this.onSiteAttendanceValues.some(({ value }) => !!value);
    },
    emnUnderperformingValues() {
      return this.filterByIndicator('porcentaje-alumnos-bajo-rendimiento-emn-colegio');
    },
    mdaUnderperformingValues() {
      return this.filterByIndicator('porcentaje-alumnos-bajo-rendimiento-mda-colegio');
    },
    showRepetitionRisk() {
      return !this.schoolHasRepetition && this.repetitionRiskPresent;
    },
    pulsoAlertsValues() {
      return this.filterByIndicator('alertas-pulso-escolar');
    },
    showPulsoIndicatorBox() {
      return this.pulsoAlertsValues.some(x => !!x.value);
    },
    showEMNindicatorBox() {
      return this.emnUnderperformingValues.some(x => !!x.value);
    },
    showMDAindicatorBox() {
      return this.mdaUnderperformingValues.some(x => !!x.value);
    },
    pdnUnderperformingValues() {
      return this.filterByIndicator('porcentaje-alumnos-bajo-rendimiento-pdn-colegio');
    },
    showPDNindicatorBox() {
      return this.pdnUnderperformingValues.some(x => !!x.value);
    },
    pddUnderperformingValues() {
      return this.filterByIndicator('porcentaje-alumnos-bajo-rendimiento-pdd-colegio');
    },
    showPDDindicatorBox() {
      return this.pddUnderperformingValues.some(x => !!x.value);
    },
    chronicAbsenteeismValues() {
      return this.filterByIndicator('porcentaje-inasistencia-cronica-acumulada');
    },
    repetitionRiskPercentageValues() {
      return this.filterByIndicator('porcentaje-riesgo-repitencia-colegio');
    },
    repetitionRiskCountValues() {
      return this.filterByIndicator('numero-alumnos-riesgo-repitencia-colegio');
    },
    repetitionCountValues() {
      return this.filterByIndicator('numero-alumnos-repitentes-colegio');
    },
    repetitionValues() {
      return this.filterByIndicator('porcentaje-alumnos-repitentes-colegio');
    },
    attendanceValues() {
      return this.filterByIndicator('porcentaje-asistencia');
    },
    onSiteAttendanceValues() {
      return this.filterByIndicator('promedio-asistencia-presencial');
    },
    studentsCountValues() {
      return this.filterByIndicator('numero-alumnos-colegio');
    },
    pieCountValues() {
      return this.filterByIndicator('cantidad-pie-oficiales');
    },
    additionalPieCountValues() {
      return this.filterByIndicator('cantidad-pie-adicionales');
    },
    piePercentageValues() {
      return this.filterByIndicator('porcentaje-alumnos-pie-colegio');
    },
    additionalPiePercentageValues() {
      return this.filterByIndicator('porcentaje-alumnos-pie-adicionales');
    },
    pieEvaluationsValues() {
      return this.filterByIndicator('evaluaciones-pie');
    },
    preferentialValues() {
      return this.mergeAbsoluteValue('porcentaje-alumnos-preferentes-colegio', 'numero-alumnos-preferentes-colegio');
    },
    priorityValues() {
      return this.mergeAbsoluteValue('porcentaje-alumnos-prioritarios-colegio', 'numero-alumnos-prioritarios-colegio');
    },
    historicalRepetitionValues() {
      return this.mergeAbsoluteValue('porcentaje-alumnos-repitentes-historico-colegio', 'numero-alumnos-repitentes-historico-colegio');
    },
    totalReadingQualityValues() {
      return this.filterByIndicator('numero-de-alumnos-total-calidad-lectora-por-grupo');
    },
    lowReadingQualityValues() {
      return this.filterByIndicator('porcentaje-de-alumnos-bajo-esperado-calidad-lectora-por-grupo').map(lowReadingQuality => {
        const readingQualityTotal = this.totalReadingQualityValues.find(total => total.objectId === lowReadingQuality.objectId).value;
        const totalCount = this.studentsCountValues.find(total => lowReadingQuality.objectId === total.objectId).value;
        return {
          ...lowReadingQuality,
          ...(lowReadingQuality.value ?
            {
              extraInfo: `En este colegio fueron evaluados ${readingQualityTotal} estudiantes. Actualmente hay ${totalCount} estudiantes en el colegio. De los que rindieron la prueba, un ${lowReadingQuality.value}% estuvo bajo lo esperado`,
            } :
            {}
          ),
        };
      });
    },
    femalePercentageValues() {
      return this.filterByIndicator('porcentaje-mujeres');
    },
    malePercentageValues() {
      return this.filterByIndicator('porcentaje-hombres');
    },
    femaleCountValues() {
      return this.filterByIndicator('cantidad-mujeres');
    },
    maleCountValues() {
      return this.filterByIndicator('cantidad-hombres');
    },
    genderProportionValues() {
      return this.femalePercentageValues.map(femaleValue => {
        const maleValue = this.malePercentageValues.find(m => femaleValue.objectId === m.objectId);
        if ([0, null].includes(maleValue.value) && [0, null].includes(femaleValue.value)) return { ...femaleValue, value: null };

        const femaleCount = this.femaleCountValues.find(m => femaleValue.objectId === m.objectId).value;
        const maleCount = this.maleCountValues.find(m => femaleValue.objectId === m.objectId).value;
        const totalCount = this.studentsCountValues.find(m => femaleValue.objectId === m.objectId).value;
        const noInfoCount = totalCount - maleCount - femaleCount;
        return {
          ...femaleValue,
          value: [100, 100 - maleValue.value, femaleValue.value], // eslint-disable-line no-magic-numbers
          extraInfo: `Hombres: ${maleCount}. ${noInfoCount > 0 ? `Sin info: ${noInfoCount}. ` : ''}Mujeres: ${femaleCount}`,
          noInfoCount,
        };
      });
    },
    pieWithAdditionalValues() {
      return this.piePercentageValues.map(piePercentageValue => {
        const pieAdditionalPercentageValue = this.additionalPiePercentageValues.find(p => piePercentageValue.objectId === p.objectId);
        const officialPieCount = this.pieCountValues.find(p => piePercentageValue.objectId === p.objectId);
        const additionalPieCount = this.additionalPieCountValues.find(p => piePercentageValue.objectId === p.objectId);
        let extraInfo;
        if (additionalPieCount.value === null) {
          extraInfo = `Total (SIGE): ${officialPieCount.value} (${Math.round(piePercentageValue.value)}%).`;
        } else {
          extraInfo = `Total: ${officialPieCount.value + additionalPieCount.value} (${Math.round(piePercentageValue.value + pieAdditionalPercentageValue.value)}%). <br>
            Oficiales (SIGE): ${officialPieCount.value} (${Math.round(piePercentageValue.value)}%).<br>
            Adicionales (IntégratePIE): ${additionalPieCount.value} (${Math.round(pieAdditionalPercentageValue.value)}%).`;
        }
        return {
          ...piePercentageValue,
          value: [pieAdditionalPercentageValue.value + piePercentageValue.value, piePercentageValue.value],
          extraInfo,
        };
      });
    },
    pieEvaluations() {
      return this.pieEvaluationsValues
        .filter(pieEvaluation => !!pieEvaluation && !!pieEvaluation.value)
        .filter(pieEvaluation => pieEvaluation.value.finished_evaluations + pieEvaluation.value.ongoing_evaluations + pieEvaluation.value.pending_evaluations !== 0)
        .map(pieEvaluation => {
          const {
            finished_evaluations: finishedEvaluations,
            ongoing_evaluations: inProgressEvaluations,
            non_applicable_evaluations: nonApplicableEvaluations,
            pending_evaluations: pendingEvaluations,
          } = pieEvaluation.value;
          const totalEvaluations = finishedEvaluations + inProgressEvaluations + pendingEvaluations; // eslint-disable-line no-magic-numbers
          const extraInfo = `Terminadas: ${finishedEvaluations}<br>
          En progreso: ${inProgressEvaluations}<br>
          No empezadas: ${pendingEvaluations}<br>
          No aplican: ${nonApplicableEvaluations}`;
          return {
            ...pieEvaluation,
            /* eslint-disable no-magic-numbers */
            value: [
              100,
              Math.floor(100 * (inProgressEvaluations + pendingEvaluations) / totalEvaluations),
              Math.floor(100 * (pendingEvaluations) / totalEvaluations),
            ],
            /* eslint-enable no-magic-numbers */
            extraInfo,
          };
        });
    },
    pieWithAdditionalMaxValue() {
      return Math.max(...this.pieWithAdditionalValues.map(piePercentage => piePercentage.value[0]));
    },
    pieMeanValue() {
      return Math.round(this.sumArray(this.pieCountValues) * 100 / this.schoolStudentsCount); // eslint-disable-line no-magic-numbers
    },
    pieAggInfo() {
      const officialTotal = this.sumArray(this.pieCountValues);
      const additionalTotal = this.sumArray(this.additionalPieCountValues);
      let aggInfo;
      if (additionalTotal > 0) {
        aggInfo = `Oficiales (SIGE): ${officialTotal}<br>
        Adicionales (IntégratePIE): ${additionalTotal}<br>
        Total: ${officialTotal + additionalTotal} / ${this.schoolStudentsCount}`;
      } else {
        aggInfo = `${officialTotal} / ${this.schoolStudentsCount}`;
      }
      return aggInfo;
    },
    aggregatedPreferentialValue() {
      return 100 * this.absoluteAggregatedPreferentialValue / this.schoolStudentsCount; // eslint-disable-line no-magic-numbers
    },
    aggregatedPriorityValue() {
      return 100 * this.abosulteAggregatedPriorityValue / this.schoolStudentsCount; // eslint-disable-line no-magic-numbers
    },
    absoluteAggregatedPreferentialValue() {
      return this.sumArray(this.preferentialValues, 'absoluteValue');
    },
    abosulteAggregatedPriorityValue() {
      return this.sumArray(this.priorityValues, 'absoluteValue');
    },
    aggregatedHistoricalRepetitionValue() {
      return 100 * this.absoluteAggregatedHistoricalRepetitionValue / this.schoolStudentsCount; // eslint-disable-line no-magic-numbers
    },
    absoluteAggregatedHistoricalRepetitionValue() {
      return this.sumArray(this.historicalRepetitionValues, 'absoluteValue');
    },
    anyNoInfoGender() {
      return this.genderProportionValues.some(gv => gv.noInfoCount);
    },
    showReadingQualityIndicatorBox() {
      return this.lowReadingQualityValues.some(x => !!x.value);
    },
    schoolStudentsCount() {
      return this.sumArray(this.studentsCountValues);
    },
    schoolHasRepetition() {
      return this.sumArray(this.repetitionCountValues) > 0;
    },
    schoolHasHistoricalRepetition() {
      return this.absoluteAggregatedHistoricalRepetitionValue > 0;
    },
    schoolRepetitionPercentage() {
      const total = this.sumArray(this.repetitionCountValues);
      return (total / (this.schoolStudentsCount || 1)) * 100; // eslint-disable-line no-magic-numbers
    },
    schoolRepetitionRiskPercentage() {
      const total = this.sumArray(this.repetitionRiskCountValues);
      return (total / (this.schoolStudentsCount || 1)) * 100; // eslint-disable-line no-magic-numbers
    },
    schoolChronicAbsenteeism() {
      const weights = this.studentsCountValues
        .reduce((obj, item) => {
          obj[item.objectId] = item.value;

          return obj;
        }, {});

      return this.avgWeightedArray(this.chronicAbsenteeismValues, weights);
    },
    attendanceResult() {
      return this.useOnSiteAttendances ? this.onSiteAttendanceResult : this.avgArray(this.attendanceValues);
    },
    onSiteAttendanceResult() {
      return this.onSiteAttendanceValues.length ? this.onSiteAttendanceValues.reduce((sum, obj) => sum + obj.value, 0) / (this.onSiteAttendanceValues.length) : 0;
    },
    genderProportionTooltip() {
      return 'En verde se muestra el porcentaje de hombres, en morado el porcentaje de mujeres y en gris el porcentaje de estudiantes de los cuales no se tiene información del género en Radar.';
    },
    surveyAlertsTooltip() {
      return 'PULSO ESCOLAR: Porcentaje de estudiantes con 2 o más alertas en esta encuesta';
    },
    poptimizeLearningObjectiveCoverageTooltip() {
      return 'OAs cubiertos según plataforma Poptimize';
    },
    poptimizePlannedClassesTooltip() {
      return 'Clases planificadas según plataforma Poptimize';
    },
    pieEvaluationsTooltip() {
      return 'Porcentaje de evaluaciones PIE terminadas, en progreso y no empezadas.\nPoniendo el mouse sobre cada barra se muestra información adicional.\nDatos obtenidos desde IntégratePIE';
    },
    repetitionRiskPresent() {
      return this.repetitionRiskPercentageValues.some(school => school.value !== null);
    },
    salesianosLeadershipEvaluationValues() {
      return this.filterByIndicator('evaluacion-directiva-salesianos');
    },
    poptimizePlannedClassesValues() {
      return this.filterByIndicator('porcentaje-clases-planificadas-poptimize');
    },
    poptimizeLearningObjectiveCoverageValues() {
      return this.filterByIndicator('porcentaje-oas-cubiertos-poptimize');
    },
  },
  watch: {
    chosenYear: {
      handler: 'fetchSchoolResults',
    },
  },
  async created() {
    await this.fetchReportYears();
    this.fetchSchoolResults();
  },
  methods: {
    ...mapActions('options', ['setChosenSchool']),
    ...mapMutations('options', {
      setYears: 'SET_YEARS',
    }),
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    historicalRepetitionTooltip() {
      return 'Cantidad de estudiantes que alguna vez repitieron de curso en años anteriores';
    },
    mergeAbsoluteValue(percentageIndicator, absoluteIndicator) {
      const absoluteValues = this.filterByIndicator(absoluteIndicator);
      return this.filterByIndicator(percentageIndicator).map(v => {
        const absoluteValue = absoluteValues.find(v2 => v2.objectId === v.objectId).value;
        return { ...v, extraInfo: `${absoluteValue} alumnos.`, absoluteValue };
      });
    },
    async fetchReportYears() {
      this.addLoading();
      try {
        const { body: { years } } = await SchoolsApi.getReportYears({ school_ids: this.chosenSchoolIds.join(',') }); // eslint-disable-line camelcase
        const availableYears = [...new Set([...years, schoolYear()])].sort();
        this.setYears(availableYears);
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
      }
    },
    async fetchSchoolResults() {
      this.addLoading();
      try {
        const { body } = await SchoolsApi.getDashboardSchoolResults({
          year: this.chosenYear,
          school_ids: this.chosenSchoolIds.join(','), // eslint-disable-line camelcase
        });
        this.schoolResults = body.schools;
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
      }
    },
    resultObject(indicatorKey, schoolData) {
      const attribute = schoolData[this.getIndicator(indicatorKey).attribute];
      const format = this.formatData(indicatorKey);
      const value = this.valueData(attribute);
      return {
        indicatorId: this.getIndicator(indicatorKey).id,
        objectId: schoolData.id,
        value,
        format: format && value !== null ? { string: this.formatData(indicatorKey) } : null,
        norm_value: this.normValueFromData(attribute), // eslint-disable-line camelcase
        class: this.classFromData(attribute),
      };
    },
    formatData(indicatorKey) {
      if (indicatorKey) {
        if (
          [
            'porcentaje-mujeres',
            'porcentaje-alumnos-pie-colegio',
            'porcentaje-alumnos-preferentes-colegio',
            'porcentaje-alumnos-prioritarios-colegio',
            'porcentaje-alumnos-repitentes-historico-colegio',
            'porcentaje-riesgo-repitencia-colegio',
            'evaluaciones-pie',
            'porcentaje-clases-planificadas-poptimize',
            'porcentaje-oas-cubiertos-poptimize',
          ]
            .includes(indicatorKey)
        ) {
          return 'percentage';
        } else if (
          [
            'porcentaje-alumnos-bajo-rendimiento-emn-colegio',
            'porcentaje-alumnos-bajo-rendimiento-pdn-colegio',
            'porcentaje-alumnos-bajo-rendimiento-pdd-colegio',
            'porcentaje-alumnos-bajo-rendimiento-mda-colegio',
            'alertas-pulso-escolar',
            'porcentaje-asistencia',
            'porcentaje-inasistencia-cronica-acumulada',
            'porcentaje-de-alumnos-bajo-esperado-calidad-lectora-por-grupo',
            'porcentaje-alumnos-repitentes-colegio',
          ]
            .includes(indicatorKey)
        ) {
          return 'percentageWithOneDecimal';
        } else if (
          [
            'promedio-anual-grupo',
            'evaluacion-directiva-salesianos',
            'promedio-asistencia-presencial',
          ]
            .includes(indicatorKey)
        ) {
          return 'numberWithOneDecimal';
        }
      }

      return null;
    },
    valueData(data) {
      if ([null, undefined].includes(data) || data.value === null) return null;
      return data.value === undefined ? data : data.value;
    },
    classFromData(data) {
      if (Number.isInteger(data)) {
        return this.$style.centered;
      }

      return this.$style.color;
    },
    normValueFromData(data) {
      if ([null, undefined].includes(data) || data.norm_vale === null) return null;
      return data.norm_value;
    },
    filterByIndicator(indicatorKey) {
      const indicator = this.localIndicators.find(x => x.key === indicatorKey);

      return this.values.filter(obj => obj.indicatorId === indicator.id);
    },
    getIndicator(key) {
      return this.localIndicators.find(i => i.key === key);
    },
    sumArray(array, key = 'value') {
      if (array.every(elem => elem[key] === null)) {
        return null;
      }

      return array.reduce((sum, obj) => sum + obj[key], 0);
    },
    avgArray(array) {
      if (array.every(elem => elem.value === null)) {
        return null;
      }
      const validArray = this.validArray(array);

      return validArray.reduce((sum, obj) => sum + obj.value, 0) / (validArray.length || 1);
    },
    avgWeightedArray(array, weights) {
      if (array.every(elem => elem.value === null)) {
        return null;
      }
      const validArray = this.validArray(array);
      const validStudentCountArray = validArray.map(validObj =>
        this.studentsCountValues.find(obj => obj.objectId === validObj.objectId)
      );
      const validStudentsCount = this.sumArray(validStudentCountArray);

      return validArray.reduce((sum, obj) => sum + obj.value * weights[obj.objectId], 0) / (validStudentsCount || 1);
    },
    validArray(array) {
      return array.filter(obj => obj.value > 0);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
  .loading {
    position: absolute !important;
    margin-top: 0 !important;
  }

  .dashboard-elements {
    &-aggregate-box{
      display: flex;
      flex-basis: 30%;
      flex-direction: row;
      justify-content: space-between;
      @media only screen and (max-width: $mobile-breakpoint) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    &-indicator-box {
      display: grid;
      grid-template-columns: repeat(3, 30%);
      justify-content: space-between;
      @media only screen and (max-width: $mobile-breakpoint) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
