<template>
  <div :class="$style.subjectAverages">
    <paywall
      :permitted="isLoggedIn && hasGradesPermission"
      :quote-text="quoteText"
      :quote-header="quoteHeader"
      restricted>
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
      <div
        v-if="values.length > 0">
        <filter-table
          :rows="rows"
          :columns="columns"
          :header-row-count="2"
          :values="values"
          :filters="filters"
          :loading="localLoading"
          :color-from-result="colorFromResult"
          :header-handle-click="setChosenIndicatorFromColumn"
          subject-test-grades-by-student
          color-map
          compact-headers
          show-filters-on-mount
          row-key="run"
          column-key="indicatorId"
          show-row-index
          show-title
        />
      </div>
      <div
        v-else
        :class="$style.subjectAveragesEmpty">
        <template v-if="chosenEmptyGroup">
          <p :class="$style.pdescription">
            Seleccione un curso para continuar con la busqueda.
          </p>
          <img src="https://colegium-recursos.s3.amazonaws.com/recursos/ilustraciones/ilustracion_0011.svg">
        </template>
        <template v-else>
          <img src="/noResultados.svg">
        </template>
        <!-- <p>
          No hay resultados para las opciones seleccionadas.
        </p> -->
      </div>
    </paywall>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import interpolate from '../../../utils/color-interpolate';
import Decree67Mixin from '../../../mixins/decree-67';
import { APPROVAL_LIMIT_GRADE } from '../../../utils/constants';

export default {
  name: 'GradesStudentSubjectAveragesView',
  mixins: [AuthMixin, PermissionMixin, ResultsMixin, Decree67Mixin],
  data() {
    return {
      quoteHeader: '¿Quieres visualizar las calificaciones y recibir alertas de posible repitencia?',
      staticFilters: {
        'Características del estudiante': [
          {
            label: 'Preferente',
            filterFunc: () => row => row.preferential,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'preferential',
          },
          {
            label: 'Prioritario',
            filterFunc: () => row => row.priority,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'priority',
          },
          // {
          //   label: 'PIE',
          //   filterFunc: () => row => row.pieBoolean,
          //   exclusionClass: 'pie',
          //   type: 'switch',
          //   key: 'pieBoolean',
          //   mainItem: true,
          // },
          {
            label: 'Permanente',
            filterFunc: () => row => row.permanentPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'permanent_pie',
            subitem: true,
          },
          {
            label: 'Transitorio',
            filterFunc: () => row => row.temporaryPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'temporary_pie',
            subitem: true,
          },
        ],
      },
      repetitionFilter: {
        'Repitencia': [
          {
            label: 'Repitentes',
            filterFunc: () => row => row.repetition,
            exclusionClass: 'repetition',
            type: 'switch',
            key: 'repetition',
            subitem: true,
          },
        ],
      },
      dynamicFilters: {
        'Promedio general': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const lastCell = rowValues.find(v => v.isLast);
                if (!lastCell) {
                  return false;
                }
                if (!lastCell.value && lastCell.alternativeText) return true;
                return lastCell.value >= this.range[0] && lastCell.value <= this.range[1];
              };
            },
            exclusionClass: 'grades',
            type: 'slider',
            key: 'grade',
            min: 1.0,
            max: 7.0,
            range: [1.0, 7.0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
      },
      enrolledClass: null,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('results', {
      storeResults: 'results',
      indicators: 'resultsIndicators',
      evaluableStudents: 'resultsEvaluableStudents',
    }),
    ...mapGetters('options', ['chosenSchool', 'chosenGroup', 'chosenEmptyGroup']),
    quoteText() {
      if (this.isAptusAccount && !this.hasGradesPermission) {
        return 'Actualmente tienes el plan Asistencia en alianza con Aptus. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que, además de la asistencia y evaluaciones Aptus, te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante)';
      } else if (!this.hasGradesPermission) {
        return 'Actualmente tienes un plan que no permite hacer seguimiento de calificaciones. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante).';
      }
      return '';
    },
    localLoading() {
      return this.loading;
    },
    rows() {
      return this.studentsAsRows;
    },
    columns() {
      return this.studentInfoAsColumns.concat(this.subjectsAsColumns);
    },
    values() {
      return this.grades.concat(this.studentInfo);
    },
    results() {
      if (!this.chosenGroup) return [];
      return this.storeResults.filter(r => (
        this.chosenGroup.id === r.group_id &&
        r.evaluable_student && !r.evaluable_student.retired
      ));
    },
    grades() {
      const lastColumn = this.indicators[this.indicators.length - 1];

      return this.results.map(result => ({
        run: result.evaluable_student.run,
        indicatorId: result.indicator_id,
        value: result.value,
        norm_value: result.norm_value, // eslint-disable-line camelcase
        alternativeText: result.alternative_text,
        isLast: result.indicator_id === lastColumn.id,
        class: this.$style.grade,
        format: { string: 'numberWithOneDecimal' },
      }));
    },
    studentInfo() {
      return this.results.map(result => ({
        run: result.evaluable_student.run,
        indicatorId: -1,
        value: result.evaluable_student.full_name,
        class: this.$style.studentLink,
        format: { string: 'startCase' },
      }));
    },
    studentsAsRows() {
      return Object.values(this.evaluableStudents).map(student => ({
        id: student.run,
        value: student.full_name,
        preferential: student.preferential,
        priority: student.priority,
        pieBoolean: student.pie,
        temporaryPie: student.pie && student.pie.includes('Transitorio'),
        permanentPie: student.pie && student.pie.includes('Permanente'),
        repetition: student.repetition,
        repetitionRisk: student.repetition_risk,
        repetitionRiskAbsenteeism: student.repetition_risk === 3, // eslint-disable-line no-magic-numbers
        repetitionRiskGrades: [1, 2, 4, 5].includes(student.repetition_risk), // eslint-disable-line no-magic-numbers
        link: {
          name: 'studentReport',
          params: { student: student.id },
        },
      })).sort((a, b) => a.value.localeCompare(b.value));
    },
    subjectsAsColumns() {
      const indicators = this.indicators;
      const subjectsFirstRow = indicators.map(s => (
        {
          id: s.id,
          headerRow: 1,
          label: s.label,
          key: s.key,
          link: {
            name: 'gradesStudentSubjectTests',
            params: this.$route.params,
          },
        }
      ));
      return subjectsFirstRow.concat(subjectsFirstRow.map(s => (
        {
          id: 100 + s.id, // eslint-disable-line no-magic-numbers
          headerRow: 2,
          label: this.$options.filters.numberOnly(
            this.$options.filters.numberWithOneDecimal(this.subjectAverage(s.id))
          ),
        }
      )));
    },
    studentInfoAsColumns() {
      return [
        { id: -1, label: 'Nombre', headerRow: 1, notCompact: true },
        { id: -1, label: 'Promedio:', headerRow: 2, class: this.$style.secondRowLabel },
      ];
    },
    isAverageRegexp() {
      return new RegExp(`${this.chosenSchool && this.chosenSchool.rbd}-([PF](-d+)?)`);
    },
    hasRepetitionValues() {
      return this.studentsAsRows.some(e => typeof e.repetition === 'boolean');
    },
    hasRepetitionRiskValues() {
      return this.studentsAsRows.some(e => typeof e.repetitionRisk === 'number');
    },
    repetitionOrRepetitionRiskFilters() {
      if (this.hasRepetitionValues) return this.repetitionFilter;
      if (this.hasRepetitionRiskValues) return this.repetitionRiskFilter;
      return {};
    },
    repetitionRiskFilter() {
      return {
        [this.repetitionRiskLabel]: [
          {
            label: 'General',
            filterFunc: () => row => row.repetitionRisk,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk',
          },
          {
            label: 'Por calificaciones',
            filterFunc: () => row => row.repetitionRiskGrades,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_grades',
            subitem: true,
          },
          {
            label: 'Por asistencia',
            filterFunc: () => row => row.repetitionRiskAbsenteeism,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_grades_absenteeism',
            subitem: true,
          },
        ],
      };
    },
    filters() {
      let outputFilters = { ...this.staticFilters, ...this.repetitionOrRepetitionRiskFilters };
      outputFilters = this.addAverageFilter(outputFilters);

      return outputFilters;
    },
  },
  methods: {
    ...mapActions('options', {
      setChosenIndicator: 'setChosenIndicatorKey',
      setReportParams: 'setReportParams',
    }),
    setChosenIndicatorFromColumn(column) {
      this.setChosenIndicator(column.key.replace('promedio', 'calificacion'));
    },
    setViewParams() {
      this.optionsForChosenSchoolOnly = true;
      this.setReportParams({ 'school_ids': true, 'years': true, 'group_ids': true, 'months': true });
      this.setReportKey('subject-average-grades-by-student');
      this.setChosenMonth(0);
    },
    colorFromResult(result) {
      const colormap = interpolate(['#FF0000', '#FFFFFF', '#4266f7']);
      const colormapRed = interpolate(['#FF0000', '#FF7277', '#4266f7']);
      if (result) {
        if (!result.value) return '#fff';
        const value = Math.min(Math.max(1, result.value), 7); // eslint-disable-line no-magic-numbers
        const range = 6;
        const normalizedValue = (value - 1) / range;
        if (result.value < APPROVAL_LIMIT_GRADE) {
          return colormapRed(normalizedValue);
        }
        return colormap(normalizedValue);
      }

      return 'inherit';
    },
    subjectAverage(indicatorId) {
      let sum = 0;
      let count = 0;
      const results = this.results;
      const indicatorKey = 'indicator_id';
      const filteredResults = results.filter((r) => r[indicatorKey] === indicatorId && typeof r.value === 'number');
      filteredResults.forEach((r) => {
        sum += r.value;
        count++;
      });

      return count > 0 ? (sum / count) : NaN;
    },
    isAverageInColumns() {
      const averageKeys = ['promedio-de-notas-anual-por-alumno'];

      return this.indicators.find(i => averageKeys.includes(i.key));
    },
    addAverageFilter(filters) {
      if (this.isAverageInColumns()) {
        return {
          'Promedio general': this.dynamicFilters['Promedio general'],
          ...filters,
        };
      }

      return filters;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../../styles/app/variables";

.subject-averages {
  padding: 0 30px;

  &-empty {
    text-align: center;
  }
}

.grade {
  text-align: center;
  border: 2px solid;
  padding: 2px;
  max-height: 27px;
  min-width: 27px;
}

.second-row-label {
  text-align: right;
}

.student-link {
  a {
    color: $primary-color;
  }

  a:visited {
    color: $primary-color;
  }

  a:hover {
    color: $link-hover-color;
  }
}
.pdescription {
  color: #177ED3;
  font-size: 13pt;
  font-weight: 500;
}
</style>
