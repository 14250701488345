<template>
  <div class="report">
    <alert-info
      :text-alert="title"
      :icon-viwe="false"/>
    <report-description
      :description="repetitionRiskDecree67Tooltip"
      :new-stayle="true"/>
    <div class="rdr-view">
      <div :class="$style.studentList">
        <div :class="$style.resultHeaderInfo">
          <div
            :class="$style.headerInfoResultImput"
            style="margin-top:10px;"
          >
            <input
              v-model="searchQuery"
              :class="[$style.searchInput, 'rdr-input']"
              style="margin-top:7px;"
              type="text"
              placeholder="Buscar por RUT o nombre de estudiante"><br>
            <i class="clg-search" style="position: relative;top: -51px;margin-left: 5px"/>
          </div>
          <rdr-results-header
            :class="$style.headerInfoResultPiker"
            show-school-selector
            hide-school-picker
            year-picker/>
          <div :class="[$style.tableWithFilters,]">
            <div :class="[$style.filterButtons,]">
              <i
                v-if="!hasActiveFilters"
                class="clg-filter"
                @click="showFilters = !showFilters"/>
              <i
                v-if="hasActiveFilters"
                :class="['cancelFilter', 'clg-filter']"
                @click="resetFilters"/>
              <!--<button
                    :class="[
                      'rdr-btn',
                      'rdr-btn--small',
                      'rdr-btn--primary',
                      'visibility-button',
                    ]"
                    @click="showFilters = !showFilters">
                    <span>Mostrar filtros</span>
                  </button>
                  <button
                    v-for="(values, key) in activeTrueFilters"
                    :class="[
                      'rdr-btn',
                      'rdr-btn--small',
                      'visibility-button',
                    ]"
                    :key="key"
                    @click="resetFilter(key)"
                  >
                    <span> {{ getFilterLabel(key) }}</span>
                    <i class="material-icons">remove_circle</i>
                  </button>
                  <button
                    v-if="hasActiveFilters"
                    :class="[
                      'rdr-btn',
                      'rdr-btn--small',
                      'visibility-button',
                    ]"
                    @click="resetFilters">
                    <span>Resetear filtros</span>
                  </button>-->
            </div>
          </div>
        </div>
        <paywall
          :permitted="hasStudentReportPermission"
          restricted>
          <div :class="$style.container">
            <div :class="$style.filters">
              <el-dialog
                :class="$style.filtersDialog"
                :visible.sync="showFilters"
                append-to-body
                center
                @opened="openFilters"
                @close="closeFilters">
                <div
                  slot="title"
                  :class="$style.filterTitle">
                  <i class="clg-filter"/> Filtros
                </div>
                <div
                  slot="title"
                  :class="$style.filterTitleColored">
                  Estudiantes: {{ countResponse || 0 }}
                </div>
                <div class="conten-filter">
                  <filters
                    :active-filters.sync="activeFilters"
                    :hidden-filters="hiddenFilters"
                    :filters="filters"
                    :current-page="currentPage"
                    :show-filters="showFilters"
                    :all-filters="allFilters"
                    :loading="loading"
                    :background="false"
                    :custom-class="$style.filtersModal"
                    button
                    margin="0px"
                    orientation="horizontal"
                    @apply-filters="applyFilters"
                    @reset-filters="resetFilters"/>
                </div>
              </el-dialog>
            </div>
            <div :class="$style.filterTitleColored">
              Estudiantes: {{ countResponse || 0 }}
            </div>
            <div :class="$style.tableWrapper">
              <transition name="fade">
                <loading-overlay v-if="loading"/>
              </transition>
              <div class="contenScroll">
                <rdr-table
                  :columns="columns"
                  :data="studentsAsRows"
                  :click="handleClick"
                  :custom-class="$style.courseReportTable"
                  :extended-header-class="$style.courseReportHeaderCell"
                  :extended-cell-class="$style.courseReportCell"
                  :hidden-columns-count="hiddenColumnsCount"
                  :sticky-top-offset="stickyTopOffset"
                  :max-label-length="maxLabelLength"
                />
              </div>
            </div>
            <div
              v-if="studentsAsRows.length > 0"
              class="contenPaginacion">
              <div class="divNumRow">
                Mostrar:
                <select
                  :class="$style.rdrPickerSelect"
                  style="padding:4px;border-radius:7px;"
                  @change="changeRows($event.target.value)">
                  <option
                    v-for="option in optionsRows"
                    :value="option">
                    {{ option }}
                  </option>
                </select> de {{ countResponse }} fichas
              </div>
              <div
                v-if="showPaginator"
                class="divPaginator">
                <el-pagination
                  :current-page.sync="currentPage"
                  :page-size="pageSize"
                  :page-count="totalPages"
                  :class="[
                    $style.pagination,
                    $style.centerItem,
                  ]"
                  layout="prev, pager, next"
                  background/>
              </div>
            </div>
          </div>
        </paywall>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Table, TableColumn, Pagination, Tooltip, Dialog } from 'element-ui';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import AuthMixin from '../../mixins/auth';
import Decree67Mixin from '../../mixins/decree-67';
import Filters from '../../components/filters.vue';
import Paywall from '../../components/paywall.vue';
import TooltipInfoMixin from '../../mixins/tooltip-info';
import ChosenSchoolPicker from '../../components/chosen-school-picker.vue';
import StageGroupsMixin from '../../mixins/stage-groups';
import StudentsApi from '../../api/students';
import SchoolsApi from '../../api/schools';
import PermissionMixin from '../../mixins/permission';
import {
  REPETITION_COLOR, CHRONIC_COLOR, PIE_COLOR,
  PREFERENTIAL_COLOR, PRIORITY_COLOR, RED_COLOR, WHITE_COLOR, SURVEY_COLORS,
} from '../../utils/style-variables';
import { MONTHS } from '../../utils/months';
import ResultsHeader from '../../components/results/results-header.vue';
import updateQueryParams from '../../utils/update-query-params';
import AlertInfo from '../../components/utils/alert-info.vue';
import ReportDescription from '../../components/results/report-description.vue';

export default {
  name: 'ListTemplate',
  components: {
    'rdr-results-header': ResultsHeader,
    'rdr-chosen-school-picker': ChosenSchoolPicker,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-pagination': Pagination,
    'el-tooltip': Tooltip,
    'el-dialog': Dialog,
    Filters,
    Paywall,
    AlertInfo,
    ReportDescription,
  },
  mixins: [AuthMixin, PermissionMixin, StageGroupsMixin, Decree67Mixin, TooltipInfoMixin],
  props: {
    changePageCallback: {
      type: Function,
      default: () => {},
    },
    columnKeys: {
      type: Array,
      default: null,
    },
    maxLabelLength: {
      type: Number,
      default: 25,
    },
    stickyTopOffset: {
      type: Number,
      default: 0,
    },
    stickyColumns: {
      type: Array,
      default: () => (['full_name']),
    },
    title: {
      type: String,
      default: 'Esta ficha muestra el listado de estudiantes por colegio. Haz click en un estudiante para ver detalles.',
    },
  },
  data() {
    return {
      loading: 0,
      activeFilters: {},
      showFilters: false,
      infoWarning: 'Estudiante ausente crónico y con posible repitencia.',
      historicalRepetitionInfoTooltip: 'Cantidad de veces que el estudiante repitió de curso en este establecimiento en años anteriores al seleccionado.',
      hiddenColumnsCount: 0,
      repetitionRiskInfoTooltip: 'Desde 2020 calculamos la posible repitencia según el nuevo Decreto 67. El riesgo de repitencia por asistencia no se considera para el 2020 ni 2021.',
      error: false,
      rawStudents: [],
      schoolGroups: [],
      countResponse: 0,
      totalPages: 0,
      appliedFilters: null,
      mainIndicator: null,
      surveyAlerts: null,
      baseFilters: {
        'Curso': [
          {
            label: 'Curso',
            dropdownPlaceholder: 'Elige los cursos',
            exclusionClass: 'group',
            type: 'dropdown',
            key: 'group_ids',
            options: [],
            selectedGroups: [],
            loading: false,
          },
        ],
        'Ciclo': [
          {
            label: 'Ciclo',
            dropdownPlaceholder: 'Selecciona un ciclo',
            exclussionClass: 'stage_group',
            type: 'dropdownMultiple',
            key: 'stage_group_ids',
            options: [],
            selectedGroups: [],
            loading: false,
          },
        ],
        'Características del estudiante': [
          {
            label: 'Preferente',
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'preferential',
          },
          {
            label: 'Prioritario',
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'priority',
          },
          // {
          //   label: 'PIE',
          //   exclusionClass: 'pie',
          //   type: 'switch',
          //   key: 'pie',
          //   mainItem: true,
          // },
          {
            label: 'Permanente',
            exclusionClass: 'pie',
            type: 'switch',
            key: 'permanent_pie',
            subitem: true,
          },
          {
            label: 'Transitorio',
            exclusionClass: 'pie',
            type: 'switch',
            key: 'temporary_pie',
            subitem: true,
          },
        ],
        'Asistencia': [
          {
            label: 'Ausentismo crónico',
            exclusionClass: 'attendance',
            type: 'switch',
            key: 'chronic_absenteeism',
          },
        ],
        'Retirados': [
          {
            label: 'Retirados',
            exclusionClass: 'retired',
            type: 'switch',
            key: 'retired',
          },
        ],
        'Repitentes':
        [
          {
            label: 'Repitentes',
            exclusionClass: 'repetition',
            type: 'switch',
            key: 'repetition',
          },
        ],
        'Repitencia histórica':
        [
          {
            label: 'Repitencia histórica',
            exclusionClass: 'historical_repetition',
            type: 'switch',
            key: 'historical_repetition',
          },
        ],
        'Años de antiguedad':
        [
          {
            label: 'Años de antiguedad',
            exclusionClass: 'years_since_enrollment',
            type: 'slider',
            min: 0,
            max: 10,
            range: [0, 10], // eslint-disable-line no-magic-numbers
            step: 1,
            key: 'years_since_enrollment',
            loading: false,
          },
        ],
      },
      optionsRows: [10, 20, 30, 50],
      pageSize: 10,
      showPaginator: true,
    };
  },
  computed: {
    hiddenFilters() {
      return [this.existsRepetition ? this.repetitionRiskLabel : 'Repitentes'];
    },
    columns() {
      let columns = [
        { key: 'full_name', label: 'Nombre', textAlign: 'left', warning: true, warningFunction: this.warningFunction, infoWarning: this.infoWarning, minWidth: 175 },
        { key: 'formatted_run', label: 'RUN', textAlign: 'left' },
        { key: 'group_short_name', label: 'Curso', textAlign: 'left' },
        { key: 'retired', label: 'Retirado', type: 'data-box', icon: 'block', colors: [RED_COLOR], hover: true, hoverCheckFunction: this.showRetiredTooltip, hoverShowValue: this.unenrollmentDateTooltip },
        { key: 'preferential', label: 'Preferente', type: 'data-box', icon: 'check', colors: [PREFERENTIAL_COLOR] },
        { key: 'priority', label: 'Prioritario', type: 'data-box', icon: 'check', colors: [PRIORITY_COLOR] },
        // { key: 'pie_boolean', label: 'PIE ...ZZ', type: 'data-box', icon: 'check', colors: [PIE_COLOR] },
        ...(this.existsRepetition ? [{ key: 'repetition', label: 'Repitente', type: 'data-box', icon: 'warning', colors: [RED_COLOR] }] : []),
        {
          key: 'repetition_risk_boolean',
          label: this.repetitionRiskLabel,
          type: 'data-box',
          icon: 'warning',
          colors: [REPETITION_COLOR],
          hover: true,
          headerHover: this.repetitionRiskInfoTooltip,
          hoverCheckFunction: this.checkRepetitionRisk,
          hoverShowValue: this.repetitionRiskTooltip,
        },
        {
          key: 'historical_repetition_boolean',
          label: 'Repitencia histórica',
          type: 'data-box',
          icon: 'warning',
          colors: [RED_COLOR],
          hover: true,
          headerHover: this.historicalRepetitionInfoTooltip,
          hoverCheckFunction: this.checkHistoricalRepetition,
          hoverShowValue: this.historicalRepetitionTooltip,
        },
        { key: 'chronic_absenteeism', label: 'Ausente crónico', type: 'data-box', icon: 'warning', colors: [CHRONIC_COLOR] },
        { key: 'formatted_years_since_enrollment', type: 'data-box', format: 'number', label: 'Años de antiguedad', colors: [WHITE_COLOR] },
      ];
      if (this.columnKeys) {
        columns = columns.filter(c => this.columnKeys.includes(c.key))
          .sort((a, b) => this.columnKeys.indexOf(a.key) - this.columnKeys.indexOf(b.key));
      }
      return columns.concat(this.studentAlertColumns)
        .map(c => (this.stickyColumns.includes(c.key) ? { ...c, sticky: true } : c));
    },
    studentAlertColumns() {
      return this.hasSurveyAlerts ? this.surveyAlerts.map((surveyAlert, index) => ({
        key: surveyAlert.key,
        norm: `${surveyAlert.key}NormValue`,
        label: surveyAlert.short_name,
        headerHover:
          `${surveyAlert.label}\nTomada en ${MONTHS[surveyAlert.month - 1]} ${surveyAlert.year}`,
        type: 'data-box',
        format: 'number',
        sortFunc: (a, b) => a - b,
        colors: [WHITE_COLOR, SURVEY_COLORS[index]],
        hover: true,
        hoverCheckFunction: r => r[surveyAlert.key],
        hoverShowValue: r => (
          r[`${surveyAlert.key}Alerts`].map(a => `<span> ${a} </span>`).join('<br/>')
        ),
      })) : [];
    },
    activeTrueFilters() {
      return Object.entries(this.activeFilters).reduce((newObj, [key, val]) => {
        if (val === true) {
          newObj[key] = val;
        }
        return newObj;
      }, {});
    },
    filters() {
      return {
        ...this.baseFilters,
        [this.repetitionRiskLabel]: [
          {
            label: 'General',
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk',
          },
          {
            label: 'Por calificaciones',
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_grades',
            subitem: true,
          },
          {
            label: 'Por asistencia',
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_absenteeism',
            subitem: true,
          },
        ],
      };
    },
    allFilters() {
      return [].concat(...Object.values(this.filters));
    },
    hasActiveFilters() {
      return Object.values(this.activeFilters).some(filter => filter === true);
    },
    ...mapState('options', ['chosenSchoolId', 'chosenYear']),
    currentPage: {
      get() {
        return parseInt(this.$route.query.page, 10) || 1;
      },
      set(value) {
        this.$router.replace(
          { ...this.$route, query: { ...this.$route.query, page: value } },
          this.changePageCallback,
        );
      },
    },
    existsRepetition() {
      return this.rawStudents.some(student => student.repetition !== null);
    },
    /* eslint-disable max-statements */
    studentsAsRows() {
      return this.rawStudents.map((student) => {
        let ret = student;
        ret.formatted_run = `${this.$options.filters.number(ret.run)}-${ret.run_dv}`; // eslint-disable-line camelcase
        ret.formatted_repetition_risk = ret.repetition_risk ? `R${ret.repetition_risk}` : null; // eslint-disable-line camelcase
        ret.tooltip = this.studentData(student);
        ret.formatted_years_since_enrollment = ret.years_since_enrollment > 5 ? '6+' : ret.years_since_enrollment; // eslint-disable-line camelcase, no-magic-numbers
        ret.repetition_risk_boolean = ret.repetition_risk ? !!ret.repetition_risk : null; // eslint-disable-line camelcase
        for (const attribute of Object.keys(student)) {
          if (ret[attribute] === null) {
            ret = { ...ret, [attribute]: false };
          }
        }
        return {
          ...ret,
          ...(
            this.hasSurveyAlerts ? Object.fromEntries(
              this.surveyAlerts.map(surveyAlert => (
                ret.survey_alerts[surveyAlert.survey_type] ?
                  [
                    [surveyAlert.key, ret.survey_alerts[surveyAlert.survey_type].value],
                    [`${surveyAlert.key}Alerts`, ret.survey_alerts[surveyAlert.survey_type].alerts],
                    [`${surveyAlert.key}NormValue`, ret.survey_alerts[surveyAlert.survey_type].norm_value],
                  ] : []
              )).flat(1)
            ) : {}
          ),
        };
      });
    },
    hasSurveyAlerts() {
      return this.surveyAlerts !== null && this.surveyAlerts.length;
    },
    /* eslint-enable max-statements */
    searchQuery: {
      get() {
        return this.$route.query.search || '';
      },
      set(value) {
        this.$router.replace({ ...this.$route, query: { ...this.$route.query, search: value } });
      },
    },
    year() {
      const currentDate = new Date();
      // January and February fall back to previous year
      currentDate.setMonth(currentDate.getMonth() - 2); // eslint-disable-line no-magic-numbers

      return currentDate.getFullYear();
    },
    options() {
      return {
        year: this.chosenYear,
        page: this.currentPage,
        school: this.chosenSchoolId,
      };
    },
    queryFilters() {
      return Object.keys(this.activeFilters).filter(
        key => this.activeFilters[key] === true
      ).reduce((o, key) => {
        switch (key) {
        case 'group_ids':
          return { ...o, [key]: this.filters.Curso[0].selectedGroups.join() };
        case 'stage_group_ids':
          return {
            ...o,
            [key]: this.filters.Ciclo[0].options
              .filter(x => this.filters.Ciclo[0].selectedGroups.includes(x.key))
              .map(stage => stage.groups)
              .flat()
              .map(group => group.id)
              .join(),
          };
        case 'years_since_enrollment':
          return {
            ...o,
            [key]: this.filters['Años de antiguedad'][0].range.join(),
          };
        default:
          return { ...o, [key]: true };
        }
      }, {});
    },
  },
  watch: {
    chosenSchoolId: {
      handler: 'fetchReportYears',
      immediate: true,
    },
    options: {
      handler(newValue, oldValue) {
        if (newValue && oldValue && newValue.school === oldValue.school) {
          this.reloadStudents();
        } else {
          this.fetchInfo();
        }
      },
      immediate: true,
    },
    queryFilters: {
      handler() {
        this.debouncedFetchStudentsCount();
      },
      immediate: true,
    },
    searchQuery(newValue) {
      if (newValue.length === 0 || newValue.length > 3) { // eslint-disable-line no-magic-numbers
        this.debouncedFetchStudents();
      }
    },
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapMutations('options', {
      setYears: 'SET_YEARS',
    }),
    setViewParams() {
      this.setReportKey(null);
    },
    riskLabel(risk) {
      const label = {
        'R1': 'Una asignatura roja y prom. final bajo 4,5',
        'R2': 'Dos asignaturas rojas y prom. final bajo 5,0',
        'R3': 'Asistencia bajo 85%',
        'R4': 'Leng. o Mat. rojo y prom. final bajo 5,5',
        'R5': 'Tres o más asignaturas rojas',
      };

      return label[risk];
    },
    handleClick({ id }) {
      this.$router.push({
        name: 'studentReport',
        params: { student: id },
      });
    },
    openFilters() {
      this.appliedFilters = JSON.parse(JSON.stringify(this.activeFilters));
    },
    closeFilters() {
      if (!isEqual(this.activeFilters, this.appliedFilters)) {
        this.applyFilters();
      }
      this.appliedFilters = null;
    },
    async fetchReportYears() {
      this.addLoading();
      try {
        const { body: { years } } = await SchoolsApi.getReportYears({ school_ids: this.chosenSchoolId }); // eslint-disable-line camelcase
        this.setYears([...new Set(years)].sort());
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
      }
    },
    async fetchInfo() {
      this.activeFilters = this.getActiveFilters(this.$route.query);
      if ('group_ids' in this.activeFilters) {
        this.baseFilters.Curso[0].selectedGroups = this.$route.query.group_ids.split(',').map(x => +x);
      }
      if ('stage_group_ids' in this.activeFilters) {
        this.baseFilters.Ciclo[0].selectedGroups = this.$route.query.stage_group_ids.split(',');
      }
      if ('years_since_enrollment' in this.activeFilters) {
        this.baseFilters['Años de antiguedad'][0].range = this.$route.query.years_since_enrollment.split(',').map(x => +x);
      }
      await Promise.all([
        this.fetchGroups(),
        this.fetchStudents(),
        this.fetchStudentsCount(),
        this.fetchLatestSurveyApplications(),
      ]);
    },
    async reloadStudents() {
      await this.fetchStudents();
      await this.fetchStudentsCount();
      await this.fetchLatestSurveyApplications();
    },
    async fetchStudentsCount() {
      const response = await StudentsApi.countStudents({
        ...this.options,
        ...this.queryFilters,
        search: this.searchQuery,
      });
      try {
        const { body: { evaluable_students_count } } = response; // eslint-disable-line camelcase
        this.countResponse = evaluable_students_count; // eslint-disable-line camelcase
      } catch (_) {
        this.error = true;
      }
    },
    async fetchStudents() {
      this.loading++;
      try {
        const response = await StudentsApi.searchStudents(
          {
            ...this.options,
            ...this.queryFilters,
            search: this.searchQuery,
          },
          this.currentPage,
          this.pageSize
        );
        const { body: { evaluable_students, meta: { total_pages } } } = response; // eslint-disable-line camelcase
        this.rawStudents = evaluable_students; // eslint-disable-line camelcase
        this.totalPages = total_pages; // eslint-disable-line camelcase
        this.showPaginator = true;
      } catch (_) {
        this.error = true;
      } finally {
        this.loading--;
      }
    },
    async fetchGroups() {
      this.loading++;
      try {
        const { body: { groups } } = await SchoolsApi.getSchoolCourses(
          this.chosenSchoolId,
          { courses_only: true, // eslint-disable-line camelcase
            year: this.chosenYear },
        );
        this.schoolGroups = groups;
        this.baseFilters.Curso[0].options = this.schoolGroups;
        this.baseFilters.Ciclo[0].options = this.stageGroups.slice(1);
      } catch (_) {
        this.error = true;
      } finally {
        this.loading--;
      }
    },
    async fetchLatestSurveyApplications() {
      this.loading++;
      try {
        const { body: { survey_alerts: alerts } } = await SchoolsApi.getLatestSurveyApplications(
          { schoolId: this.chosenSchoolId, year: this.chosenYear }
        );
        this.surveyAlerts = alerts;
      } catch (_) {
        this.error = true;
      } finally {
        this.loading--;
      }
    },
    debouncedFetchStudentsCount: debounce(async function () {
      await this.fetchStudentsCount();
    }, 500), // eslint-disable-line no-magic-numbers
    debouncedFetchStudents: debounce(async function () {

      await this.fetchStudents();
    }, 1000), // eslint-disable-line no-magic-numbers
    applyFilters() {
      this.showFilters = false;
      this.debouncedFetchStudents();
    },
    resetFilters() {
      this.showFilters = false;
      this.activeFilters = {};
      updateQueryParams({ page: this.currentPage });
      this.baseFilters.Curso[0].selectedGroups = [];
      this.baseFilters.Ciclo[0].selectedGroups = [];
      this.baseFilters['Años de antiguedad'][0].range = [0, 10]; // eslint-disable-line no-magic-numbers
      this.debouncedFetchStudents();
    },
    warningFunction(row) {
      return row.repetition_risk && row.chronic_absenteeism;
    },
    getFilterLabel(filterKey) {
      const filter = this.allFilters.find(obj => obj.key === filterKey);

      return filter && filter.label;
    },
    resetFilter(filterKey) {
      if (filterKey === 'group_ids') {
        this.baseFilters.Curso[0].selectedGroups = [];
      } else if (filterKey === 'stage_group_ids') {
        this.baseFilters.Ciclo[0].selectedGroups = [];
      } else if (filterKey === 'years_since_enrollment') {
        this.baseFilters['Años de antiguedad'][0].range = [0, 10]; // eslint-disable-line no-magic-numbers
      }

      const filtered = Object.keys(this.activeFilters)
        .filter(key => ![filterKey].includes(key))
        .reduce((obj, key) => {
          obj[key] = this.activeFilters[key];
          return obj;
        }, {});
      this.activeFilters = filtered;
      this.debouncedFetchStudents();
    },
    showRetiredTooltip(row) {
      return row.retired && row.unenrollment_date;
    },
    unenrollmentDateTooltip(row) {
      return `Retirado/a el ${row.unenrollment_date}`;
    },
    checkHistoricalRepetition(row) {
      return row.historical_repetition_data && row.historical_repetition_data.length;
    },
    checkRepetitionRisk(row) {
      return row.formatted_repetition_risk && !!row.formatted_repetition_risk;
    },
    repetitionRiskTooltip(row) {
      return this.riskLabel(row.formatted_repetition_risk);
    },
    getActiveFilters(filterObject) {
      return Object.keys(filterObject)
        .reduce(
          (a, b) => ({ ...a, ...(b === 'page' ? {} : { [b]: true }) }),
          {}
        );
    },
    changeRows(data) {
      this.showPaginator = false;
      this.pageSize = parseInt(data);
      this.reloadStudents();
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.list {
  text-align: center;
  font-weight: 200;
  padding: 0 30px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 18px;
    font-weight: 200;
    margin: 20px 5px;
    max-width: 100%;
    background: antiquewhite;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-input {
  margin-bottom: 20px;
  width: 75%;
  background: #E7EFFF;
  border-radius: 10px;
  width: 374px;
  height: 40px;
}

.pagination {
  text-align: right;
  margin-top: 40px;

  &.el-pagination.is-background .el-pager li:not(.disabled){
    &.active {
      background-color: $primary-color;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.table {
  width: 100%;
  &.el-table .cell {
    word-break: normal !important;
  }
}

table.course-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $sidebar-background;
      }
    }
    tr:hover {
      td {
        background-color: #f9f9fd;
      }
    }
  }
}

div.course-report-header-cell {
  height: 42px;
}

div.course-report-cell {
  font-size: 14px;
  height: 40px;
}

.table-wrapper {
  width: 100%;
  position: relative;
}

.loading-overlay {
  top: 48px;
  height: calc(100% - 48px);
  background-color: rgba(255, 255, 255, 0.7);

  .spinner {
    margin-top: 70px;
  }
}

.clickable-row {
  cursor: pointer;
}

.filter-title {
  font-size: 20px;
  font-weight: 600;
  color: $primary-color;
}

.filter-title-colored {
  color: $primary-color;
  font-weight: 700;
  padding: 5px 26px;
}

.filters-modal {
  @media only screen and (max-width: $mobile-breakpoint) {
    width: 90vw;
  }
}

.filters-dialog {
  :global(.el-dialog) {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 40%;
    margin-left: 60%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 100%;
  }

  :global(.el-dialog__header) {
    margin-top: 10px;
    text-align: left;
  }

  :global(.el-dialog__body) {
    padding: 25px 0px !important;
  }

  .filters {
    width: 100%;
    display: flex;
    align-items: start;
    font-size: 13px;
    }

  @media only print {
    flex-direction: column;
  }
}

.result-header-info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}

.table-with-filters {
  width: 100%;
}

.center-item {
  padding-top: 10px;
  // display: flex;
  justify-content: right;
}

.filter-buttons {
  padding-bottom: 15px;
  i.clg-filter.cancelFilter {
  &:after {
      content: '/';
      color: #2D50AA;
      top: -3px;
      font-size: 25px;
      right: 15px;
      position: relative;
  }
}
i.clg-filter.cancelFilter{
  &:before {
      border: 1px solid #2D50AA;
      border-radius: 50%;
      color: #2D50AA;
  }
}

i.clg-filter{
    &:before {
     content: "\e998";
    font-size: 31px;
    color: #2D50AA;
    }
  }

  button {
    margin: 0px 2px;
  }
}
.headerInfoResultImput {
  width: 40%;
}
.headerInfoResultPiker {
  width: 50%;
}
.tableWithFilters {
  width: 6%;
  text-align: right;
  margin-top: -3PX;
  i {
    font-size: 26px !important;
    font-weight: 600;
    cursor: pointer;
    color: #177ed3;
  }
}
</style>
