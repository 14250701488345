<template>
  <el-dialog class="radar-dialog free-offer-dialog" :visible.sync="show">
    <div class="free-offer-dialog__title"><h1>¡Prueba Radar gratis!</h1></div>
    <br/>
    <div class="free-offer-dialog__content">
      <p class="free-offer-dialog__text" > Revisa los resultados SIMCE y PSU de cualquier colegio ingresando su nombre en el buscador.</p>
    </div>
  </el-dialog>
</template>

<script>
  import { Dialog } from 'element-ui';

  export default {
    name: 'freeOffer',
    components: {
      'el-dialog': Dialog,
    },
    computed: {
      show: {
        get() { return this.$store.state.ui.dialogs.freeOffer.open; },
        set(value) { this.$store.commit({ type: 'ui/SET_DIALOG_STATE', dialog: 'freeOffer', state: value }) },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";
</style>
