<template>
  <div :class="$style.row">
    <school-logo v-if="showLogo" />
    <div
      v-if="userSchools.length === 1"
      class="contenInp">
      {{ userSchools[0].name | startCase }}
    </div>
    <div
      v-else
      :class="$style.rowInner">
      <!-- <rdr-picker
        v-model="chosenSchool"
        :options="localSchools"
        :no-choice="noChoice"
        :loading="loading"
        :class="$style.resultsHeader"
        label-filter="startCase"
        label-property="name"/> -->
      <autocomplete-school
        v-model="chosenSchool"
        :options="localSchools"
        :loading="loading"
        :class="[$style.resultsHeaderSchoolPicker, $style.resultsHeaderSchoolRow]"
        label-filter="startCase"
        label-property="name"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SchoolLogo from './school-logo';
import AutocompleteSchool from '../components/utils/autocomplete.vue';

export default {
  name: 'ChosenSchoolPicker',
  components: {
    SchoolLogo,
    AutocompleteSchool,
  },
  props: {
    schools: {
      type: Array,
      default() {
        return null;
      },
    },
    noChoice: {
      type: String,
      default: null,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
  },
  computed: {
    ...mapGetters('options', {
      stateChosenSchool: 'chosenSchool',
    }),
    ...mapGetters('auth', ['userSchools']),
    chosenSchool: {
      get() {
        return this.stateChosenSchool;
      },
      async set(value) {
        await this.setChosenSchool(value.id);
      },
    },
    localSchools: {
      get() {
        return this.schools || this.userSchools;
      },
    },
  },
  methods: {
    ...mapActions('options', ['setChosenSchool']),
  },
};
</script>

<style lang="scss" module>

.logo-image {
  height: 130px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.results-header {
  text-align: center;
  font-weight: 200;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  // width: 100%;
  // padding-left: 30px;
  // padding-right: 30px;
  &-inner {
    justify-content: center;
  }
}
</style>
