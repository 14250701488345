import Vue from 'vue';

export default {
  getGroupSummary(params) {
    return Vue.http.get(
      `/api/v1/schools/${params.schoolId}/courses/${params.groupKey}/report/summary`,
      { params: { year: params.year } }
    );
  },
  getGroupStudents(params) {
    return Vue.http.get(
      `/api/v1/schools/${params.schoolId}/courses/${params.groupKey}/students`,
      { params: { year: params.year } }
    );
  },
  getGroupSubjects(params) {
    return Vue.http.get(
      `/api/v1/schools/${params.schoolId}/courses/${params.groupKey}/subjects`,
      { params: { year: params.year } }
    );
  },
  getGroupSurvey(params) {
    return Vue.http.get(
      `/api/v1/schools/${params.schoolId}/courses/${params.groupKey}/survey`,
      { params: { year: params.year } }
    );
  },
};
