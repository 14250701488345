<template>
  <div class="pro-quote-view">
    <div v-if="!sendedQuotation">
      <div
        id="step-zero"
        class="step">
        <div class="step--item">
          <div class="pro-quote-view__header">
            Escríbenos a <a href="mailto:radar@colegium.com">radar@colegium.com</a></div>
        </div>
      </div>
      <div
        id="step-one"
        class="step">
        <div class="step--item">
          <div class="pro-quote-view__subtitle">
            Pide tu cotización escribiendo a nuestro correo indicando el nombre y RBD
            del(los) colegio(s) para los que quieres cotizar y los servicios en los que estás interesado(a).</div>
          <!-- <school-select
            v-model="selectModel"
            :placeholder="placeholderText"
            :selected-options="quoteSchoolIds"
            class="rdr-select pro-quote-view__select" /> -->
          <div class="pro-quote-view__schools">
            <div
              v-for="school in quoteSchools"
              :key="school.id"
              class="pro-quote-view__school"
              @click="removeSchool(school)">
              <span>{{ school.name | startCase | cleanSchoolName }}</span>
              <span class="pro-quote-view__school-remove">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasSelectedSchool"
        id="step-two"
        class="step">
        <div class="step--info">
          <div class="step-dot step-dot--green"><p class="number">2</p></div>
        </div>
        <div class="step--item">
          <div>
            <div class="pro-quote-view__subtitle">Elige un Plan</div>
            <div class="card-row">
              <vertical-card
                v-for="(bodyInfo, plan) in plans"
                :key="plan"
                :title="bodyInfo.title"
                :body="bodyInfo"
                :selected="selectedPlan === plan ? true : false"
                @click.native="selectPlan(plan)"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadingQuote">Cargando cotización...</div>
      <div
        v-if="hasSelectedSchool && selectedPlan"
        id="step-three"
        class="step">
        <div class="step--info">
          <div class="step-dot step-dot--grey"><p class="number">3</p></div>
        </div>
        <div class="step--item">
          <div class="pro-quote-view__subtitle">Completa tu Información</div>
          <form
            class="pro-quote-view__form"
            @submit.prevent="saveQuotation">
            <input
              v-model="contactInfo.name"
              required
              type="text"
              class="rdr-input rdr-input--small rdr-input--block"
              placeholder="Nombre completo">
            <input
              v-model="contactInfo.email"
              required
              type="email"
              class="rdr-input rdr-input--small rdr-input--block"
              placeholder="Correo electrónico">
            <input
              v-model="contactInfo.phone"
              required
              type="tel"
              class="rdr-input rdr-input--small rdr-input--block"
              placeholder="Teléfono de contacto">
            <v-ener-select
              :options="relations"
              class="rdr-select relation--picker__select"
              required
              placeholder="Relación con el colegio"
              @input="onRelationSelectChange($event)"/>
            <button
              v-if="selectedPlan"
              :disabled="savingQuote"
              class="rdr-btn rdr-btn--block rdr-btn--primary">
              <span v-if="!savingQuote">Enviar cotización por email</span>
              <span v-if="savingQuote">Enviando...</span>
            </button>
            <div
              v-else
              class="rdr-input--block pro-quote-view__form__plan-alert">
              <span>
                <info-icon
                  text="Selecciona un Plan"
                  icon="error"/>
                Recuerda seleccionar un plan para poder realizar tu cotización
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="pro-quote-view__header pro-quote-view__message">
        Hemos enviado la cotización a tu correo electrónico.
      </div>
      <div
        id="quote-results">
        <div
          v-if="savedQuote"
          class="pro-quote-view__message">
          Plan: {{ plans[selectedPlan].title }}
        </div>
        <table
          cellspacing="0"
          cellpadding="0"
          class="pro-quote-prices pro-quote-view__prices">
          <tr>
            <td
              class="pro-quote-prices__heading"
              colspan="4">
              {{ topMessage }}
            </td>
          </tr>
          <tr>
            <td class="pro-quote-prices__subheading">Número de estudiantes</td>
            <td
              v-for="(prices, plan, index) in priceTable"
              :key="`subheader-${index}`"
              :class="[
                'pro-quote-prices__cell',
                'pro-quote-prices__cell--students',
                { 'pro-quote-prices__cell--active': plans[selectedPlan].title === plan },
            ]">
              {{ plan }}
            </td>
          </tr>
          <tr>
            <td class="pro-quote-prices__subheading pro-quote-prices__subheading--prices">{{ totalStudents }}</td>
            <td
              v-for="(prices, plan, index) in priceTable"
              :key="`price-${index}`"
              :class="[
                'pro-quote-prices__cell',
                'pro-quote-prices__cell--price',
                { 'pro-quote-prices__cell--active': plans[selectedPlan].title === plan },
            ]">
              {{ prices[studentsLevel].price * totalStudents | clp }}
            </td>
          </tr>
        </table>
        <div
          class="pro-quote-view__message pro-quote-view__bold-message">
          Equivalente a {{ pricePerStudent }} CLP mensuales por estudiante para el número de estudiantes seleccionados.
        </div>
      </div>
      <div
        class="footer">
        <p>
          Esta cotización es válida por 30 días y está en Pesos Chilenos. Fecha de cotización: {{ (new Date()).toLocaleDateString('en-GB') }}.
        </p>
        <p>
          Genera tu orden de compra o realiza cotizaciones especiales escribiéndonos a <a href="mailto:radar@colegium.com">radar@colegium.com</a>.
        </p>
        <div class="disclaimer">
          <p>
            La información entregada en esta cotización es referencial y debe ser corroborada al momento de realizar una orden de compra.
          </p>
        </div>
      </div>
      <div class="pro-quote-view__message">
        <br><br>
        <button
          class="rdr-btn rdr-btn--primary"
          @click="resetQuote">Iniciar otra cotización
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProQuoteApi from '../api/pro-quote';
import SchoolSelect from '../components/school-select';
import VerticalCard from '../components/base/vertical-card';
import InfoIcon from '../components/info-icon.vue';

export default {
  name: 'ProQuoteView',
  components: {
    SchoolSelect,
    VerticalCard,
    InfoIcon,
  },
  data() {
    return {
      actualYear: new Date().getFullYear(),
      selectModel: null,
      quoteSchools: [],
      quotation: null,
      loadingQuote: false,
      savingQuote: false,
      savedQuote: false,
      selectedPlan: null,
      relations: [
        { key: 'sostenedor', label: 'Sostenedor' },
        { key: 'director', label: 'Director' },
        { key: 'equipoDirectivo', label: 'Integrante Equipo Directivo' },
        { key: 'profesor', label: 'Profesor' },
        { key: 'apoderado', label: 'Apoderado' },
        { key: 'otro', label: 'Otro' },
      ],
      /* This data must be in sync with the Quotation model in the backend*/
      priceTable: {
        'Asistencia': [
          { range: '0-700', price: 200 },
          { range: '700-1.500', price: 170 },
          { range: '1.500-5.000', price: 140 },
          { range: '5.000-10.000', price: 130 },
          { range: '10.000+', price: 120 },
        ],
        'Académico': [
          { range: '0-700', price: 350 },
          { range: '700-1.500', price: 300 },
          { range: '1.500-5.000', price: 240 },
          { range: '5.000-10.000', price: 220 },
          { range: '10.000+', price: 200 },
        ],
        'Socioemocional': [
          { range: '0-700', price: 500 },
          { range: '700-1.500', price: 430 },
          { range: '1.500-5.000', price: 340 },
          { range: '5.000-10.000', price: 310 },
          { range: '10.000+', price: 280 },
        ],
      },
      contactInfo: {
        name: '',
        email: '',
        phone: '',
        relation: '',
      },
      plans: {
        attendance: { title: 'Asistencia', message: 'Accede a visualizaciones generadas con tu información de: ',
          list: ['Simce y PSU', 'Asistencia'],
          extraInfo: 'Alianza Aptus: Si eres cliente de evaluaciones Aptus también incluye integración y precio preferencial' },
        academic: { title: 'Académico', message: 'Accede a visualizaciones generadas con tu información de: ',
          list: ['Simce y PSU', 'Asistencia', 'Calificaciones', 'Evaluaciones Externas'],
          extraInfo: null },
        socioemotional: { title: 'Socioemocional', message: 'Accede a visualizaciones generadas con tu información de: ',
          list: ['Simce y PSU', 'Asistencia', 'Calificaciones', 'Evaluaciones Externas', 'Módulo de seguimiento socioemocional Radar'],
          extraInfo: null },
      },
    };
  },
  computed: {
    studentsLevel() {
      /* eslint-disable no-magic-numbers */
      if (this.quotation) {
        if (this.quotation.total_students < 700) return 0;
        else if (this.quotation.total_students < 1500) return 1;
        else if (this.quotation.total_students < 5000) return 2;
        else if (this.quotation.total_students < 10000) return 3;

        return 4;
      }

      return -1;
      /* eslint-enable no-magic-numbers */
    },
    quoteSchoolIds() {
      return this.quoteSchools.map(s => s.id);
    },
    placeholderText() {
      if (this.quoteSchools.length > 0) {
        return 'Agrega otro establecimiento';
      }

      return 'Agrega un establecimiento';
    },
    totalStudents() {
      return this.quotation.total_students;
    },
    topMessage() {
      return `Precio mensual por plan para su cantidad de alumos totales en ${this.quotation.total_schools > 1 ? `los ${this.quotation.total_schools} colegios seleccionados.` : 'el colegio seleccionado'}`;
    },
    hasSelectedSchool() {
      return this.quoteSchools.length > 0;
    },
    sendedQuotation() {
      return this.hasSelectedSchool && this.selectedPlan && this.savedQuote;
    },
    pricePerStudent() {
      return this.priceTable[this.plans[this.selectedPlan].title][this.studentsLevel].price;
    },
  },
  watch: {
    selectModel(to, from) {
      if (to && (!from || to.value !== from.value)) {
        if (this.quoteSchoolIds.indexOf(to.id) === -1) this.quoteSchools.push(to);
        this.$nextTick(() => this.scrollToElementById('step-one'));
        setTimeout(() => (this.selectModel = null), 0);
      }
    },
    quoteSchoolIds() {
      if (this.quoteSchoolIds.length) {
        this.loadingQuote = true;
        ProQuoteApi.getQuote(this.quoteSchoolIds).then(response => {
          this.quotation = response.data.quotation;
          this.loadingQuote = false;
        });
      } else {
        this.quotation = null;
      }
    },
  },
  methods: {
    removeSchool(school) {
      const schoolIndex = this.quoteSchools.indexOf(school);
      if (schoolIndex > -1) this.quoteSchools.splice(schoolIndex, 1);
    },
    onRelationSelectChange(event) {
      this.contactInfo.relation = event.key;
    },
    saveQuotation() {
      this.savingQuote = true;
      const { name, email, phone, relation } = this.contactInfo;
      const selectedPlan = this.selectedPlan;
      ProQuoteApi.saveQuote({
        schools: this.quoteSchoolIds,
        name,
        email,
        phone,
        relation,
        selectedPlan,
      }).then((response) => {
        this.quotation = response.data.quotation;
        this.savingQuote = false;
        this.savedQuote = true;
        // Google analytics event
        this.$ga.event({
          eventCategory: 'ProQuoteView',
          eventAction: 'SaveQuotation',
          eventLabel: 'Quotation',
          eventValue: this.quotation.price,
        });
      });
    },
    resetQuote() {
      this.quotation = null;
      this.savedQuote = false;
      this.quoteSchools = [];
      this.selectedPlan = null;
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
      if (document.getElementById('quote-results')) this.$nextTick(() => this.scrollToElementById('quote-results'));
      else this.$nextTick(() => this.scrollToElementById('step-three'));
    },
    scrollToElementById(id) {
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/app/variables";

.pro-quote-view {
  display: flex;
  justify-content: left;
  min-height: 70vh;
  max-width: 700px;
  margin: 0 auto;
  &__header {
    text-align: center;
    font-size: 32px;
    margin-bottom: 35px;
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
  }

  &__select {
    max-width: 440px;
    margin: 0 auto;

    .dropdown-toggle {
        border-color: $primary-color;

        ::placeholder {
          font-weight: 900;
          color: $primary-color;
        }
    }
    .commune {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .name {
      margin-right: 10px;
    }

    .rbd {
      opacity: .5;
    }
  }

  &__schools {
    margin: 25px 0 40px;
    text-align: center;
  }

  &__school {
    display: inline-block;
    margin: 5px 12px;
    color: $primary-color;
    border-bottom: 1px dashed $primary-color;
    padding-bottom: 2px;
    cursor: pointer;

    &:hover {
      .pro-quote-view__school-remove {
        opacity: 1;
      }
    }
  }

  &__school-remove {
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
    opacity: .5;
  }

  &__prices {
    margin-bottom: 30px;
  }

  &__form {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 440px;

    .rdr-input {
      margin-bottom: 10px;
    }

    .rdr-button {
      margin-top: 20px;
    }

    .rdr-select {
      margin-bottom: 10px;

      &.v-select {
        .dropdown-toggle {
          border-color: #eee;

          &:hover {
            border-color: #ddd;
          }
        }

        &.open .dropdown-toggle {
          border-color: #bbb;
        }

        .selected-tag {
          font-size: 15px;
          line-height: 45px;
        }

        input {
          color: #000;
          font-size: 15px;
          height: 45px !important;
        }
      }
    }

    &__plan-alert {
      display: flex;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__message {
    margin-top: 40px;
    text-align: center;
  }

  &__bold-message {
    font-weight: bold;
  }
}

.pro-quote-prices {
  width: 100%;

  &__heading {
    background-color: #fafafa;
    color: $primary-color;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    border-bottom: 3px solid white;
  }

  &__subheading {
    font-size: 13px;
    text-align: center;
    max-width: 10px;

    &--prices {
      background-color: #fafafa;
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__cell {
    width: 90px;
    height: 40px;
    font-size: 12px;
    text-align: center;
    border: 0;

    &--active {
      background-color: $primary-color !important;
      color: white !important;
    }

    &--students {
      font-size: 11px;

      &:nth-child(even) {
        background-color: #fcfcfc;
      }
    }

    &--price {
      font-weight: 600;

      &:nth-child(2) { background-color: #fffcec; }
      &:nth-child(3) { background-color: #fffadc; }
      &:nth-child(4) { background-color: #fff4bb; }
      &:nth-child(5) { background-color: #feee97; }
      &:nth-child(6) { background-color: #fed189; }
    }
  }
}

.pro-quote-quotation {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  th {
    background-color: #f6f6f6;
    font-size: 13px;
    padding: 5px;

    &:last-child {
      color: $primary-color;
    }
  }

  td {
    border: 1px solid #f6f6f6;
    font-size: 14px;
    padding: 5px;

    strong {
      font-size: 16px;
    }
  }
}

.footer {
  margin-top: 30px;
  text-align: justify;

  p {
    font-size: 13px;
    text-align: center;
  }
}

.disclaimer {
  margin: 30px 0;
  color: #999;

  p {
    font-size: 12x;
  }
}

.card-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}
.step {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  max-width: 100%;
  margin: 0 auto;
  box-align: center;

  &--info {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 20%;
    text-align: center;

  }
  &--item {
    display: inline-block;
    width: 80%;

  }
}

@media (max-width: $mobile-breakpoint) {
  .pro-quote-view {
    &__header {
      text-align: center;
      font-size: 24px;
      margin-bottom: 35px;
    }
  }
  .step {
    display: block;
    text-align: center;
    &--info {
      justify-content: center;
      width: auto;
    }
    &--item {
      width: 100%;
    }
  }
}

.step-dot {
  border-radius: 50%;
  background-color: #000;
  z-index: 1;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  color: white;
  height: 60px;
  width: 60px;

  &--yellow {
    background-color: #ffd370;
  }

  &--green {
    background-color: #24d5bd;
  }

  &--red {
    background-color: #ff7070;
  }

  &--grey {
    background-color: #a0a5b9;
  }

  .number {
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    transform: scale(3, 3);
    font-family: Arial;
  }

}

</style>
