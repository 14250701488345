<template>
  <div class="reset-password">
    <div class="reset-password__logo"/>
    <div class="reset-password__header"><span>Ingresa tu nueva contraseña:</span></div>
    <form
      class="reset-password__form"
      @submit.prevent="resetPassword()">
      <div
        v-show="passwordMismatch"
        class="reset-password__error">
        <span>Las contraseñas no coinciden. <br>Por favor verifica tus datos.</span>
      </div>
      <div
        v-show="validationError"
        class="reset-password__error">
        <span>La contraseña debe tener al menos 6 caracteres.</span>
      </div>
      <div
        v-show="unauthorized"
        class="reset-password__error">
        <span>Ha ocurrido un error. Por favor, solicita un nuevo cambio de contraseña.</span>
      </div>
      <div
        v-show="forbidden"
        class="reset-password__error">
        <span>Usuario no encontrado. Por favor, solicita un nuevo cambio de contraseña.</span>
      </div>
      <input
        v-model="password"
        class="reset-password__input rdr-input rdr-input--block"
        type="password"
        placeholder="Nueva contraseña"
        required
        autofocus
        @keyup.down="cleanError" >
      <input
        v-model="confirmPassword"
        class="reset-password__input rdr-input rdr-input--block"
        type="password"
        placeholder="Reingrese contraseña"
        required >
      <button
        :disabled="disableSubmit"
        class="rdr-btn rdr-btn--block rdr-btn--large rdr-btn--primary"
        type="submit">
        <span v-if="!submitting">Enviar</span>
        <span v-if="submitting">Enviando...</span>
      </button>
    </form>
  </div>
</template>

<script>
import { Message } from 'element-ui';

export default {
  name: 'ResetPasswordView',
  data() {
    return {
      submitting: false,
      password: '',
      confirmPassword: '',
    };
  },
  computed: {
    disableSubmit() {
      return this.$data.submitting || this.passwordMismatch;
    },
    passwordMismatch() {
      return this.$data.password !== this.$data.confirmPassword;
    },
    error() { return this.$store.state.auth.authError; },
    validationError() { return this.error && this.$store.state.auth.authErrorCode === 400; }, // eslint-disable-line no-magic-numbers
    unauthorized() { return this.error && this.$store.state.auth.authErrorCode === 401; }, // eslint-disable-line no-magic-numbers
    forbidden() { return this.error && this.$store.state.auth.authErrorCode === 403; }, // eslint-disable-line no-magic-numbers
  },
  methods: {
    resetPassword() {
      this.$data.submitting = true;
      this.$store.dispatch(
        'auth/resetPassword',
        {
          email: this.$route.query.email,
          password: this.$data.password,
          token: this.$route.query.token,
        }
      )
        .then(() => {
          // eslint-disable-next-line new-cap
          Message({
            message: 'Contraseña cambiada con éxito.',
            type: 'success',
          });
          this.$router.push({ path: '/' });
        })
        .catch(() => {
          // eslint-disable-next-line new-cap
          Message({
            message: 'Error al intentar actualizar contraseña',
            type: 'error',
          });
          this.$data.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../styles/app/variables";

  .reset-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    overflow: auto;
    height: 100vh;

    &__logo {
      width: 100%;
      height: 40vh;
      min-width: 300px;
      min-height: 300px;
      mask-image: url("/logo.svg");
      background-color: $primary-color;
      mask-mode: match-source;
      mask-repeat: no-repeat;
      mask-clip: border-box;
      mask-origin: border-box;
      mask-composite: add;
      mask-position: center;
      mask-size: contain;
    }

    &__header {
      text-align: center;
      font-size: 24px;
      margin-top: 45px;
    }

    &__form {
      width: 380px;
      margin: 0 auto;
      margin-top: 45px;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 100%;
      }

      &--submitted {
        margin-top: 25px;
        font-size: 18px;
      }
    }

    &__error{
      margin-bottom: 20px;
      color: red;
      text-align: center;
      font-size: 16px;
    }

    &__input {
      margin-bottom: 15px;
    }

    .rdr-btn {
      margin-top: 35px;
    }
  }
</style>
