<template>
  <div :class="$style.tabs">
    <div :class="[$style.tabsOuterWrapper, 'hide-print']">
      <div
        v-if="tabsLoad"
        :class="[$style.tabsWrapper]">
        <a
          v-if="Object.keys(tabs).length !== 0"
          v-for="(tab, index) in tabs.children"
          :key="index"
          :class="[
            $style.tab,
            'hide-print',
            { [$style.active]: tab.action },
          ]"
          @click="link(tab, index)">
          <span>{{ tab.label }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LinksMixin from '../mixins/links';

export default {
  name: 'TabsSubmenu',
  mixins: [LinksMixin],
  props: {
    tabs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemsTabs: [],
      tabsLoad: false,
      objTabs: {},
    };
  },
  watch: {
    tabs:function(newValue, oldValue) {
      this.itemSelected()
    },
  },
  mounted() {
    this.itemSelected()
  },
  methods: {
    itemSelected(start=true, ind=0) {
      this.tabsLoad = false;
      const itemStorage = localStorage.getItem('item_select');
      const indexStorage = localStorage.getItem('item_index');

      if (start && itemStorage !== null && ind === 0) ind = parseInt(indexStorage);

      this.tabs.children.forEach((val, key) => {
        val.action = false;
      });
      this.tabs.children[ind].action = true;
      localStorage.setItem('item_select', JSON.stringify(this.tabs));
      localStorage.setItem('item_index', ind.toString());
      this.tabsLoad = true;
    },
    link(tab, ind) {
      this.$router.push(this[tab.link]);
      this.itemSelected(false, ind);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.tabs {
  display: flex;
  justify-content: center;
  margin: 0 auto 0px auto;
  padding: 0 30px;
  @media only screen and (max-width: $mobile-breakpoint) {
    margin: 0 auto 4px auto;
    padding: 0;
  }
}

.current-tab {
  font-size: 28px;
  font-weight: 200;
}

.tabs-wrapper {
  display: flex;
  justify-content: center;
  margin: 10px 0 0 0;
}

.tabs-outer-wrapper {
  display: flex;
  justify-content: stretch;
  margin: 0 auto 0 auto;
  width: 100%;
}

.left-border {
  width: 100%;
  & > span {
    margin-right: auto;
  }
  border-bottom: 1px solid #ccc;
}

.right-border {
  width: 100%;
  & > span {
    margin-left: auto;
  }
  border-bottom: 1px solid $light-color;
}

.tab {
  // flex: 1;
  color: $light-color;
  // border-bottom: 1px solid $light-color;
  font-family: 'GT Haptik', sans-serif;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  cursor: pointer;

  .material-icons {
    font-size: 20px;
  }

  span {
    flex: 1;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    width: auto;
    padding: 0px 10px;
    span {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}
.tab:hover {
  color: #555;
}

.active {
  color: $primary-color; // $main-text-color;
  // border-top: 3px solid $primary-color;
  // border-left: 1px solid $light-color;
  // border-right: 1px solid $light-color;
  // border-bottom: 1px solid white;
  border-bottom: 3px solid $primary-color;
}

</style>
