<template>
  <div :class="$style.barChart">
    <transition name="fade">
      <loading-overlay v-if="loading"/>
    </transition>
    <div
      ref="barChart"
      :class="$style.container"/>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'BarChart',
  props: {
    rowsLabel: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customTooltip: {
      type: Boolean,
      default: false,
    },
    viewWindow: {
      type: Object,
      default: () => ({}),
    },
    colors: {
      type: Array,
      default: () => ([
        '#3839ff',
        '#9897ff',
        '#c7c9ff',
      ]),
    },
    customStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chart: null,
      data: null,
    };
  },
  watch: {
    columns() { this.redraw(); },
    rows() { this.redraw(); },
    rowsLabel() { this.redraw(); },
    isStacked() { this.redraw(); },
  },
  created() {
    google.charts.load('current', { 'packages': ['corechart'], 'language': 'es' });
    google.charts.setOnLoadCallback(this.onLibraryLoad);
  },
  mounted() {
    window.addEventListener('resize', this.redraw);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.redraw);
  },
  methods: {
    onLibraryLoad() {
      this.chart = new google.visualization.BarChart(this.$refs.barChart);
      this.draw();
    },
    redraw: _.debounce(function () { this.draw(); }, 30),
    draw() {
      if (!this.chart) return;

      this.data = new google.visualization.DataTable();
      this.data.addColumn('string', this.rowsLabel);
      this.columns.forEach((column) => {
        this.data.addColumn('number', column);
        if (this.customTooltip) {
          this.data.addColumn({ type: 'string', role: 'tooltip' });
        }
        if (this.customStyle) {
          this.data.addColumn({ type: 'string', role: 'style' });
        }
      });

      this.data.addRows(this.rows);

      const formatter = new google.visualization.NumberFormat({
        groupingSymbol: '',
        fractionDigits: 0,
      });
      formatter.format(this.data, 0);

      this.chart.draw(this.data, {
        bar: { groupWidth: '40%' },
        legend: { position: 'none' },
        fontName: 'Muli',
        colors: this.colors,
        vAxis: {
          textPosition: 'out',
        },
        hAxis: {
          textStyle: { fontSize: 14 },
          showTextEvery: 1,
          viewWindow: this.viewWindow,
        },
        chartArea: {
          bottom: 30,
          top: '3%',
          height: '92%',
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.bar-chart {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;
}
</style>
