<template>
  <div :class="$style.chartContainer">
    <div
      ref="historicChart"
      :class="$style.lineChart"
      :style="{
        width: width ? `${width}px` : '100%',
        height: `${height}px`,
    }"/>
    <div
      v-if="showLegend"
      :class="$style.legend">
      <div
        v-for="(column, index) in columns"
        :key="index"
        class="info__column">
        <div
          :style="{ borderColor: colors[index] }"
          class="info__color"
        />
        <div class="info__column-name">{{ column }} </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

import GRAPH_COLORS from '../utils/graph-colors';

export default {
  name: 'HistoricChart',
  props: {
    rowsLabel: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    periods: {
      type: Array,
      default: () => [],
    },
    labelType: {
      type: String,
      default: 'number',
    },
    viewWindow: {
      type: Object,
      default: () => ({}),
    },
    vAxisFormat: {
      type: String,
      default: '',
    },
    vAxisText: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 400,
    },
    width: {
      type: Number,
      default: null,
    },
    colors: {
      type: Array,
      default: () => GRAPH_COLORS,
    },
    formatterParams: {
      type: Object,
      default: () => ({ groupingSymbol: '', fractionDigits: 1 }),
    },
    ticks: {
      type: Array,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    pointSize: {
      type: Number,
      default: 10,
    },
    legendPosition: {
      type: String,
      default: 'none',
    },
    fontSize: {
      type: Number,
      default: null,
    },
    slanted: {
      type: Boolean,
      default: null,
    },
    lineWidth: {
      type: Number,
      default: 2,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    customTooltip: {
      type: Boolean,
      default: false,
    },
    anotaciones: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      libraryLoaded: false,
      options: {
        chartArea: {
          left: 50,
          bottom: 50,
          right: 0,
          top: 20,
        },
        vAxis: {
          textPosition: this.vAxisText ? 'out' : 'none',
          baselineColor: '#DFDFDF',
          textStyle: {
            color: '#6E6E75',
          },
          format: this.vAxisFormat,
          gridlines: {
            color: '#EEE',
          },
          viewWindow: this.viewWindow,
          ticks: this.ticks,
        },
        hAxis: {
          slantedText: this.slanted,
          baselineColor: '#DFDFDF',
          textStyle: {
            color: '#6E6E75',
          },
          ticks: [],
          gridlines: {
            color: '#EEE',
          },
        },
        fontName: 'Muli',
        pointsVisible: true,
        pointSize: this.pointSize,
        backgroundColor: this.backgroundColor,
        legend: {
          position: this.legendPosition,
        },
        lineWidth: this.lineWidth,
        colors: this.colors,
        annotations: {
          textStyle: {
            // fontName: 'Times-Roman',
            fontSize: 12,
            bold: true,
            // italic: true,
            color: '#777',
          }
        }
      },
    };
  },
  watch: {
    columns() {
      this.draw();
    },
    rows() {
      this.draw();
    },
    periods() {
      this.draw();
    },
    height() {
      this.draw();
    },
    width() {
      this.draw();
    },
  },
  created() {
    google.charts.load('current', { 'packages': ['corechart'], 'language': 'es' });
    google.charts.setOnLoadCallback(this.onLibraryLoad);
  },
  methods: {
    onLibraryLoad() {
      this.libraryLoaded = true;
      this.draw();
    },
    draw() {
      /* eslint max-statements: [2, 20] */
      if (!this.libraryLoaded) return;
      let rowChart = this.rows;
      google.charts.load('current', {'packages':['annotationchart']});
      const chart = new google.visualization.LineChart(this.$refs.historicChart);
      const data = new google.visualization.DataTable();
      data.addColumn(this.labelType, this.rowsLabel || '');
      this.columns.forEach(column => {
        data.addColumn('number', column);
        if (this.anotaciones) data.addColumn({type: 'string', role: 'annotation'});
        if (this.customTooltip) data.addColumn({ type: 'string', role: 'tooltip' });
      });

      if (this.anotaciones) {
        let newArray = []
        this.rows.forEach(row => {
          let rowTemp = [];
          rowTemp.push(row[0]);
          this.columns.forEach((column, index) => {
            let uid = index + 1;
            let dato = row[uid]===null ? null : row[uid].toString() + '%';
            rowTemp.push(row[uid]);
            rowTemp.push(dato);
          });
          newArray.push(rowTemp);
        });
        rowChart = newArray;
      }

      data.addRows(rowChart);
      const formatter = new google.visualization.NumberFormat(
        this.formatterParams
      );
      for (let i = 1; i <= this.columns.length; i++) {
        formatter.format(data, i);
      }

      this.options.hAxis.ticks = [];
      this.options.hAxis.ticks.push({ v: this.periods[0] - 1, f: '' });
      this.periods.forEach(period => {
        this.options.hAxis.ticks.push({ v: period, f: period.toString() });
      });
      this.options.hAxis.ticks.push({ v: this.periods[this.periods.length - 1] + 1, f: '' });
      chart.draw(data, this.options);
    },
  },
};
</script>

<style lang="scss" module>
.chart-container {
  display: flex;
  flex-direction: row;
  width: calc(100% - 10px) !important;

  .line-chart {
    @media only print {
      & > div > div {
        margin: 0;
        // width: calc(100% - 200px) !important;
      }
    }
  }
  .legend{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 0 0 15px;
  }

  .google-visualization-tooltip {
    border: solid 3px #bdbdbd;
    display: block !important;
  }
}
</style>
