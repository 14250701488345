<template>
  <div
    :class="[
      $style.rdrPicker,
      {[$style.rdrPickerSmall]: size === 'small'},
      {[$style.rdrPickerInline]: pickerStyle === 'inline'},
      {[$style.rdrPickerBorder]: pickerStyle !== 'round'},
      {[$style.rdrPickerRound]: pickerStyle === 'round'}]">
    <template v-if="iconCalendar">
      <div class="contenlogo">
        <i class="clg-check-time"/>
      </div>
    </template>
    <div class="contenSelect">
      <select
        :disabled="options.length === 0 || disabled"
        :class="[
          $style.rdrPickerSelect,
          {[$style.rdrPickerDisabled]: options.length === 0 || disabled}]"
        :value="value"
        @change="handleChange($event.target && $event.target.value)">
        <option
          v-if="emptyChoice"
          :selected="value === null"
          :value="null">
          {{ noChoice }}
        </option>
        <option
          v-for="option in options"
          :key="getOptionValue(option)"
          :selected="isSelected(option)"
          :value="getOptionValue(option)">
          {{ getOptionLabel(option) }}
        </option>
      </select>
      <div
        :class="[
          $style.rdrPickerCurrent,
          {[$style.rdrPickerCurrentDisabled]: options.length === 0 || disabled}]">
        <span
          v-if="!loading && options.length && value"
          :class="$style.rdrPickerLabel">
          {{ getOptionLabel(value) }}
        </span>
        <span
          v-if="!loading && options.length && !value"
          :class="$style.rdrPickerLabel">
          {{ noChoice }}
        </span>
        <i
          v-if="!loading && options.length > 0"
          :class="[
            {[$style.rdrPickerArrowDisabled]: options.length === 0 || disabled},
            $style.rdrPickerArrow,
            $style.customMaterialIcons,
            'material-icons',
            'hide-print']">
          keyboard_arrow_down
        </i>
        <span v-if="loading">{{ loadingText }}</span>
        <span v-else-if="!options.length">{{ noOptions }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RdrPicker',
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    labelFilter: {
      type: String,
      default: null,
    },
    labelFunction: {
      type: Function,
      default: null,
    },
    labelProperty: {
      type: String,
      default: null,
    },
    valueProperty: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    pickerStyle: {
      type: String,
      default: 'inline',
    },
    noChoice: {
      type: String,
      default: 'Seleccionar...',
    },
    noOptions: {
      type: String,
      default: 'sin datos',
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Cargando...',
    },
    emptyChoice: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconCalendar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    optionsAreObjects() {
      return this.options.every(option => typeof option === 'object');
    },
    optionsAreNumbers() {
      return this.options.every(option => typeof option === 'number');
    },
  },
  methods: {
    handleChange(value) {
      let processedValue = value;
      if (this.optionsAreObjects && !this.valueProperty) {
        processedValue = this.options[value];
      }
      if (this.optionsAreNumbers) {
        processedValue = parseFloat(value);
      }
      if (this.onChange) {
        this.onChange(processedValue);
      }
      this.$emit('input', processedValue);
      this.$emit('update:value', processedValue);
    },
    isSelected(option) {
      if ([undefined, null].includes(this.value)) return false;

      return this.getOptionValue(option) === this.value;
    },
    getOptionValue(option) {
      if (this.optionsAreObjects) {
        if (this.valueProperty) return option[this.valueProperty];

        return this.options.indexOf(option);
      }

      return option;
    },
    getValueOption(value) {
      return this.options.find(option => option[this.valueProperty] === value);
    },
    getOptionLabel(option) {
      let label = option;
      if (this.labelProperty) {
        if (typeof label !== 'object') label = this.getValueOption(label);
        label = label[this.labelProperty];
      }
      if (this.labelFunction) label = this.labelFunction(label);
      if (this.labelFilter) label = this.$options.filters[this.labelFilter](label);

      return label;
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .rdr-picker {
    display: inline-block;
    position: relative;
    font-size: 28px;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 19px;
    min-width: 120px;

    &-disabled {
      background-color: $gray-background-color;
      border-color: $disabled-border-color;
      color: $light-gray-color;

      &:hover {
        cursor: not-allowed;
        .rdr-picker-current {
          border-color: $disabled-border-color;
          color: $light-gray-color;
        }
      }
    }

    &-select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: inherit;
      cursor: pointer;
      opacity: 0;
      z-index: 1;
    }

    &-inline {
      @media only screen {
        .rdr-picker-current {
          padding-bottom: 2px;
          color: $adminitrador-color-text;
          cursor: pointer;
          display: flex;
          align-content: space-between;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height:40px;
          padding: 0px 15px;
          font-size: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .custom-material-icons {
          position: relative;
          // top: 4px;
          font-size: inherit;
        }

        &:hover {
          .rdr-picker-current {
            color: darken($adminitrador-color-text, 30%);
          }
        }
      }
    }

    &-round {
      .rdr-picker-current {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ccc;
        padding: 6px 10px 6px 20px;
        border-radius: 20px;
        align-items: center;
        font-size: 14px;

        &-disabled {
          background-color: $gray-background-color !important;
          border-color: $disabled-border-color !important;
          color: $light-gray-color !important;
        }
      }

      .rdr-picker-label {
        display: inline-block;
        // min-width: 120px;
        margin-right: 15px;
        font-size: 16px;
      }

      .rdr-picker-arrow {
        border: 1px solid;
        border-radius: 13px;
        color: $primary-color;
        text-align: center;
        font-size: 22px;
        padding-top: 1px;
        height: 24px;

        &-disabled {
          color: $light-gray-color;
        }
      }

      &:hover {
        .rdr-picker-current {
          border-color: $primary-color;
          color: $adminitrador-color-text;
        }
      }
    }

    &-border {
      border: 1px solid $cards-background-border;
    }

    &-small {
      font-size: 16px;

      .custom-material-icons {
        top: 3px;
      }
    }
  }
</style>
