<template>
  <div
    class="contenPaginacion">
    <div class="divNumRow">
      Mostrar:
      <select
        :class="$style.rdrPickerSelect"
        style="padding:4px;border-radius:7px;"
        @change="changeRows($event.target.value)">
        <option
          v-for="option in optionsRows"
          :value="option">
          {{ option }}
        </option>
      </select> de {{ viewRows }} fichas
    </div>
    <div class="divPaginatorFront">
      <button
        type="button"
        class="btn-prev numberPage btnNext"
        :disabled="disabledLeft"
        @click="backPage()">
        <i class="el-icon el-icon-arrow-left"></i>
      </button>
      <ul class="el-pager">
        <li
          v-for="i in maxPages"
          :class="['numberPage', {'offPage': currentPage !== i}, {'onPage': currentPage === i}]"
          @click="getRowPage(i)">
            {{ i}}
        </li>
      </ul>
      <button
        type="button"
        class="btn-next numberPage btnNext"
        :disabled="disabledRight"
        @click="nextPage()">
        <i class="el-icon el-icon-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PaginadorFront',
    data() {
      return {
        page: 1,
        perPage: 4,
        totalPages: 0,
        users: [],
        userCols: ['Id', 'Avatar', 'First Name', 'Last Name'],
        optionsRows: [10],
        itemsPage: 10,
        maxPages: 1,
        currentPage: 1,
        disabledLeft: true,
        disabledRight: false,
        newList: [],
        viewRows: 0,
      };
    },
    props: {
      tableList: {
        default: [],
        required: true,
      },
    },
    mounted() {
      this.allPages();
      this.getOptionsRows();
    },
    methods: {
      getOptionsRows() {
        if(Array.isArray(this.tableList)) {
          let allRows = this.tableList.length
          if(allRows >= 20) this.optionsRows.push(20);
          if(allRows >= 30) this.optionsRows.push(30);
          allRows >= 50
            ? this.optionsRows.push(50)
            : this.optionsRows.push(allRows)
        }
      },
      allPages() {
        if(Array.isArray(this.tableList)) {
          const allRows = this.tableList.length;
          this.viewRows = allRows;
          this.maxPages = Math.ceil(allRows / this.itemsPage);
          this.getRowPage(1);
        }
      },
      changeRows(data) {
        this.itemsPage = data;
        this.disabledLeft = true;
        this.allPages();
      },
      getRowPage(page) {
        this.disabledLeft = true;
        this.disabledRight = true;
        if(this.tableList.length > 0) {
          this.newList = [];
          this.currentPage = page;
          let start = (page * this.itemsPage) - this.itemsPage;
          let end = (page * this.itemsPage);
          if(end > this.tableList.length) end = this.tableList.length;
          for (let index = start; index < end; index++) {
            this.newList.push(this.tableList[index])
          }
          if(page > 1) this.disabledLeft = false;
          if(page !== this.maxPages) this.disabledRight = false;
          let objEmit = {
            'data': this.newList,
            'start': start
          }
          this.$emit('items-return', objEmit);
        }
      },
      backPage() {
        let newPage = this.currentPage - 1;
        this.getRowPage(newPage);
      },
      nextPage() {
        let newPage = this.currentPage + 1;
        this.getRowPage(newPage);
      }
    },
  };
</script>


<style lang="scss" module>
@import "../../styles/app/variables";
  $white: #fff;
  $cornflower-blue: #65a2e9;
  $tropical-blue: #d1e5fa;

  .pagination {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      display: inline-block;
      // border: 1px solid #D5D5D5;
      box-sizing: border-box;
      margin-left: -1px;    
      
      a {
        background: $tropical-blue;
        margin: .25rem;
        padding: .25rem .5rem;
        border-radius: 4px;
        text-decoration: none;
        position: relative;
        display: block;
        color: $cornflower-blue;
        
        &.active {
          background: $cornflower-blue;
          color: $white;
        }
      }
    }
  }
</style>