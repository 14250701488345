import moment from 'moment-timezone';

export default {
  methods: {
    toLocal(time, format) {
      moment.locale('es');
      const clientTimeZone = moment.tz.guess();
      const momentObject = moment.tz(time, clientTimeZone);
      const parsedTime = momentObject.format(format);
      return parsedTime;
    },
  },
};
