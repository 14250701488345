import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('options', ['chosenYear']),
    isDecree67Active() {
      return this.chosenYear >= 2020; // eslint-disable-line no-magic-numbers
    },
    repetitionRiskDecree67Tooltip() {
      return this.isDecree67Active ?
        `Desde 2020 calculamos la posible repitencia según el nuevo Decreto 67.
         El riesgo de repitencia por asistencia no se considera para el 2020 ni 2021.` :
        'Riesgo de repitencia calculado según la ley vigente al año seleccionado';
    },
    shortRepetitionRiskLabel() {
      return this.isDecree67Active ? 'Posible repitencia' : 'Riesgo repitencia';
    },
    repetitionRiskLabel() {
      return this.isDecree67Active ? 'Posible Repitencia' : 'Riesgo de Repitencia';
    },
  },
};
