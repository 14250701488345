/* eslint-disable camelcase */
import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

export default {
  uploadFile(formData) {
    return Vue.http.post(
      'api/v1/user-uploads/upload-file',
      formData
    );
  },
  checkUploadedFileStatus(schoolId, checkerJobId) {
    return Vue.http.get(
      'api/v1/user-uploads/check-uploaded-file-status',
      { params: { school_id: schoolId, checker_job_id: checkerJobId } }
    );
  },
  confirmFileUpload(schoolId, checkerJobId, fileType) {
    return Vue.http.post(
      'api/v1/user-uploads/confirm-file-upload',
      { school_id: schoolId, checker_job_id: checkerJobId, file_type: fileType }
    );
  },
};
