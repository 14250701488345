<template>
  <div
    :class="[$style.container, classOverride]">
    <a
      :href="schoolAttachment.url"
      class="rdr-btn rdr-btn--primary"
      target="_blank">
      <span class="icon-wrapper">
        <i class="material-icons">attach_file</i>
      </span>
      <span>
        {{ attachmentLabel(schoolAttachment) || (`Descargar ${schoolAttachment.name}` | startCase) }}
      </span>
    </a>
  </div>
</template>

<script>

export default {
  name: 'SchoolAttachmentButton',
  props: {
    label: {
      type: [String, Function],
      default: null,
    },
    schoolAttachment: {
      type: Object,
      required: true,
    },
    classOverride: {
      type: String,
      default: null,
    },
  },
  methods: {
    attachmentLabel(attachment) {
      if (typeof this.label !== 'function') return this.label;
      return this.label(attachment);
    },
  },
};
</script>

<style lang="scss" module>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
</style>
