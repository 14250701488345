/* eslint-disable no-magic-numbers, no-unused-vars */

function range(min, max, step = 1) {
  const values = [];
  for (let i = min; i <= max; i += step) {
    values.push(i);
  }
  return values;
}

function snakeCaseToCamelCase(value) {
  const outputValue = value.replace(
    /([-_][a-z])/g, g => g.toUpperCase().replace('-', '').replace('_', '')
  );
  return outputValue[0].toUpperCase() + outputValue.slice(1);
}

function standardDeviation(array) {
  const mean = array.reduce((a, b) => a + b, 0) / array.length;
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / array.length);
}

function median(array) {
  if (array.length) {
    const sortedArray = array.sort((a, b) => a - b);
    const halfIndex = Math.floor(array.length / 2);
    if (array.length % 2 === 0) return (sortedArray[halfIndex] + sortedArray[halfIndex + 1]) / 2.0;
    return sortedArray[halfIndex];
  }
  return NaN;
}

function mostFrequentValues(array) {
  const counts = array.reduce((acc, v) => ({ ...acc, [v]: isNaN(acc[v]) ? 1 : acc[v] + 1 }), {});
  const highestFrequency = Math.max(...Object.values(counts));
  return Object.entries(counts)
    .filter(([_, frequency]) => frequency === highestFrequency)
    .map(([value, _]) => value)
    .sort((a, b) => a - b);
}

function groupBy(array, key, rejectUndefined = true) {
  return array.reduce((rv, item) => {
    if (item[key] === undefined && rejectUndefined) return rv;
    (rv[item[key]] = rv[item[key]] || []).push(item);
    return rv;
  }, {});
}

function averageWithoutNulls(array) {
  const values = array.filter(v => ![undefined, '', null, NaN].includes(v));
  const parsedValues = values.map(v => (typeof v === 'number' ? v : parseFloat(v.replace(',', '.'))));
  const valuesCount = parsedValues.length;
  return valuesCount > 0 ? parsedValues.reduce((a, b) => a + b, 0) / valuesCount : null;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  range,
  snakeCaseToCamelCase,
  standardDeviation,
  median,
  mostFrequentValues,
  groupBy,
  averageWithoutNulls,
};
