import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import ui from './modules/ui';
import results from './modules/results';
import resultsPro from './modules/results-pro';
import auth from './modules/auth';
import signUp from './modules/sign-up';
import intro from './modules/intro';
import options from './modules/options';

Vue.use(Vuex);

const api = axios.create({
  baseURL: RADAR_MANAGEMENT_HOST, // eslint-disable-line no-undef
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
});

const store = new Vuex.Store({
  modules: {
    ui,
    results,
    resultsPro,
    auth,
    signUp,
    intro,
    options,
  },
  strict: true,
});

api.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
  },
}));

export default store;
