<template>
  <el-popover
    :trigger="trigger"
    :title="title"
    :placement="placement"
    :value="value"
    :disabled="disabled"
    :width="width"
    :popper-class="popoverClasses"
    @after-leave="afterLeave"
    @hide="hide"
  >
    <slot
      slot="reference"
      name="reference"/>
    <slot/>
  </el-popover>
</template>

<script>
import { Popover } from 'element-ui';

/**
 * Popover for inline elements.
 *
 * This is a wrapper for Element UI's popover.
 * (http://element.eleme.io/#/en-US/component/popover)
 *
 * The content provided to the component will be the content
 * of the popover. The wrapped content must be given to the
 * reference slot.
 *
 * WARNING: This component wraps whatever you might want to
 * add the popover to with a styleless span element.
 */
export default {
  name: 'RdrPopover',
  components: { 'el-popover': Popover },
  props: {
    /**
     * Trigger mode of the popover over the wrapped element.
     * `click, focus, hover, manual`
     */
    trigger: {
      type: String,
      default: 'hover',
    },
    /**
     * Title inside the popover and over content provided.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Position of the popover around the wrapped element.
     * `top, top-start, top-end, bottom, bottom-start, bottom-end,
     * left, left-start, left-end, right, right-start, right-end`
     */
    placement: {
      type: String,
      default: 'top',
    },
    /**
     * v-model to control visibility of the popover.
     */
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    popperClass: {
      type: String,
      default: 'rdr-popover',
    },
    /**
     * possible values: small, big
     */
    arrowSize: {
      type: String,
      default: 'small',
    },
    /**
     * popover width, default value is adjust to text
     */
    width: {
      type: Number,
      default: null,
    },
    /**
     * function to execute after leave
     */
    afterLeave: {
      type: Function,
      default: () => null,
    },
    /**
     * function to execute when popover hides
     */
    hide: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    popoverClasses() {
      const sizeClass = `rdr-popover-${this.arrowSize}`;

      return this.popperClass.concat(' ', sizeClass);
    },
  },
};
</script>

<style lang="scss">

$color: #fff;
.rdr-popover-big {
  &[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: $color !important;
    border-width: 10px !important;
    top: -20px !important;
  }

  &[x-placement^="left"] .popper__arrow {
    border-left-color: $color !important;
    border-width: 10px !important;
    right: -20px !important;
  }

  &[x-placement^="right"] .popper__arrow {
    border-right-color: $color !important;
    border-width: 10px !important;
    left: -20px !important;
  }

  &[x-placement^="top"] .popper__arrow {
    border-top-color: $color !important;
    border-width: 10px !important;
    bottom: -20px !important;
  }
}
</style>
