<template>
  <div :class="$style.subjectTests">
    <paywall
      :permitted="isLoggedIn && hasGradesPermission"
      :quote-text="quoteText"
      :quote-header="quoteHeader"
      restricted>
      <div v-if="isLoggedIn && hasGradesPermission">
        <transition name="fade">
          <loading-overlay v-if="loading"/>
        </transition>
        <test-dialog
          :visible.sync="showTestDialog"
          :data="testDialogData"
          :school="chosenSchool"
          :group="chosenGroup"
          :year="chosenYear"
          :subject="chosenIndicator"
          :test="testDialogTest"/>
        <div v-if="values.length > 0">
          <div
            v-if="editing"
            :class="$style.switcher">
            <span> Poner calificaciones por estudiante </span>
            <el-switch
              v-model="verticalTab"
              :active-color="PRIMARY_COLOR"
              :inactive-color="LIGHT_COLOR"
              :class="[$style.switch, 'hide-print']"/>
            <span> Poner calificaciones por prueba </span>
          </div>
          <filter-table
            :rows="rows"
            :columns="columns"
            :header-row-count="2"
            :values.sync="values"
            :vertical-tab="verticalTab"
            :editing.sync="editing"
            :filters="filters"
            :loading="localLoading"
            :color-from-result="colorFromResult"
            :show-empty-data="true"
            :default-sort-column="defaultSortColumn"
            :enable-editing="false"
            show-filters-on-mount
            color-map
            row-key="run"
            column-key="testId"
            edit-button-message="Editar calificaciones"
            show-title
          >
            <div
              v-if="scope.column.tooltip"
              slot="tooltip"
              slot-scope="scope"
              :class="$style.headerTooltip">
              <div><strong>{{ scope.column.testName }}</strong></div>
              <div>Evaluados: {{ scope.column.studentsEvaluated }} de {{ rows.length }}</div>
              <div>Promedio del curso: {{ testAverage(scope.column.id) | numberWithOneDecimal | numberOnly }}</div>
              <div>Rojos: {{ scope.column.studentsRedGrade }}</div>
              <div>Sobre 6: {{ scope.column.studentsGreaterThanSix }}</div>
              <div>Mínimo: {{ scope.column.minGrade | numberWithOneDecimal | numberOnly }}</div>
              <div>Máximo: {{ scope.column.maxGrade | numberWithOneDecimal | numberOnly }}</div>
              <div>Calificación más repetida: {{ scope.column.mostFrequentValues }}</div>
              <div>Mediana: {{ scope.column.median | numberWithOneDecimal | numberOnly }}</div>
              <div>Desviación estándar: {{ scope.column.standardDeviation | numberWithOneDecimal | numberOnly }}</div>
              <button
                :class="['rdr-btn', 'rdr-btn--small', 'rdr-btn--primary']"
                @click="() =>openSubjectModal(scope.column)">
                Ver distribución
              </button>
            </div>
          </filter-table>
        </div>
        <div
          v-else
          :class="$style.subjectTestsEmpty">
          <img src="/noResultados.svg">
          <!-- <p>
            No hay resultados para las opciones seleccionadas.
          </p> -->
        </div>
      </div>
      <color-map
        slot="mock"
        :rows="mockGradesGroupAverageData.groups"
        :columns="mockGradesGroupAverageData.columns"
        :results="mockGradesGroupAverageData.results"
        :default-open-rows="mockGradesGroupAverageData.defaultOpenRows"
        :results-column-key="mockGradesGroupAverageData.resultsColumnKey"
        :results-row-key="mockGradesGroupAverageData.resultsRowKey"
        :disable-school-adding="true"
        class="color-map--pro"/>
    </paywall>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Switch } from 'element-ui';
import { PRIMARY_COLOR, LIGHT_COLOR } from '../../../utils/style-variables';
import TestDialog from './test-dialog.vue';
import AuthMixin from '../../../mixins/auth';
import MockDataMixin from '../../../mixins/mock-data';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import Decree67Mixin from '../../../mixins/decree-67';
import interpolate from '../../../utils/color-interpolate';
import { APPROVAL_LIMIT_GRADE } from '../../../utils/constants';
import { median, standardDeviation, mostFrequentValues, groupBy, averageWithoutNulls } from '../../../utils/methods';
import { TERM_NAMES } from '../../../utils/months';
import schoolYear from '../../../utils/years';

export default {
  name: 'GradesStudentSubjectTestsView',
  components: { TestDialog, 'el-switch': Switch },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin, Decree67Mixin],
  data() {
    return {
      currentSchoolYear: schoolYear(),
      quoteHeader: '¿Quieres visualizar las calificaciones y recibir alertas de posible repitencia?',
      statisticTypeKeys: ['promedio-anual-asignatura-alumno', 'promedio-periodo-asignatura-alumno'],
      baseFilters: {
        'Promedio final': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                let lastCell;
                rowValues.forEach((value) => {
                  if (!lastCell || lastCell.testId < value.testId) {
                    lastCell = value;
                  }
                });
                if (!lastCell) {
                  return false;
                }
                if (!lastCell.value && lastCell.alternativeText) return true;
                return lastCell.value >= this.range[0] && lastCell.value <= this.range[1];
              };
            },
            exclusionClass: 'grades',
            type: 'slider',
            min: 1.0,
            max: 7.0, // eslint-disable-line no-magic-numbers
            range: [1.0, 7.0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
        'Características del alumno': [
          {
            label: 'Preferente',
            filterFunc: () => row => row.preferential,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'preferential',
          },
          {
            label: 'Prioritario',
            filterFunc: () => row => row.priority,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'priority',
          },
          {
            label: 'PIE',
            filterFunc: () => row => row.pieBoolean,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'pieBoolean',
            mainItem: true,
          },
          {
            label: 'Permanente',
            filterFunc: () => row => row.permanentPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'permanent_pie',
            subitem: true,
          },
          {
            label: 'Transitorio',
            filterFunc: () => row => row.temporaryPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'temporary_pie',
            subitem: true,
          },
        ],
      },
      repetitionFilter: {
        'Repitencia': [
          {
            label: 'Repitentes',
            filterFunc: () => row => row.repetition,
            exclusionClass: 'repetition',
            type: 'switch',
            key: 'repetition',
            subitem: true,
          },
        ],
      },
      submitting: false,
      showTestDialog: false,
      showAutomaticAverageDialog: false,
      editedValues: null,
      testDialogTest: null,
      testDialogData: [],
      taughtClass: null,
      editing: false,
      verticalTab: true,
      PRIMARY_COLOR,
      LIGHT_COLOR,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['indicators', 'chosenYear']),
    ...mapGetters('results', {
      storeResults: 'results',
      evaluations: 'resultsEvaluations',
      evaluableStudents: 'resultsEvaluableStudents',
    }),
    ...mapGetters('options', ['chosenSchool', 'groups', 'chosenIndicator', 'chosenGroup']),
    localLoading() {
      return this.loading;
    },
    quoteText() {
      if (this.isAptusAccount && !this.hasGradesPermission) {
        return 'Actualmente tienes el plan Asistencia en alianza con Aptus. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que, además de la asistencia y evaluaciones Aptus, te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por alumno)';
      } else if (!this.hasGradesPermission) {
        return 'Actualmente tienes un plan que no permite hacer seguimiento de calificaciones. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por alumno).';
      }
      return '';
    },
    currentSchoolYearChosen() {
      return this.currentSchoolYear === this.chosenYear;
    },
    automaticAverageDialogTitle() {
      return this.currentSchoolYearChosen ? 'Subida de calificaciones sin promedios parciales' : 'Subida de calificaciones de años anteriores';
    },
    automaticAverageDialogBody() {
      return this.currentSchoolYearChosen ? `Acabas de ingresar calificaciones para ${this.chosenSchool.name}, ${this.chosenGroup.name.toUpperCase()}, ${this.taughtClass.subject.label}, pero no ingresaste los promedios parciales (de periodo y/o final). ¿Quieres que Radar Escolar calcule automáticamente los promedios de los estudiantes? (se calculará el promedio considerando los ponderadores asignados a cada evaluación)` : '';
    },
    hasRepetitionValues() {
      return this.studentsAsRows.some(e => typeof e.repetition === 'boolean');
    },
    hasRepetitionRiskValues() {
      return this.studentsAsRows.some(e => typeof e.repetitionRisk === 'number');
    },
    repetitionOrRepetitionRiskFilters() {
      if (this.hasRepetitionValues) return this.repetitionFilter;
      if (this.hasRepetitionRiskValues) return this.repetitionRiskFilter;
      return {};
    },
    repetitionRiskFilter() {
      return {
        [this.repetitionRiskLabel]: [
          {
            label: 'General',
            filterFunc: () => row => row.repetitionRisk,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk',
          },
          {
            label: 'Por calificaciones',
            filterFunc: () => row => row.repetitionRiskGrades,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_grades',
            subitem: true,
          },
          {
            label: 'Por asistencia',
            filterFunc: () => row => row.repetitionRiskAbsenteeism,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_absenteeism',
            subitem: true,
          },
        ],
      };
    },
    filters() {
      return {
        ...this.baseFilters,
        ...this.repetitionOrRepetitionRiskFilters,
      };
    },
    results() {
      if (!this.chosenIndicator) return [];
      if (!this.chosenGroup) return [];
      let dataInfo = this.storeResults.filter(r => (
        this.chosenGroup.id === r.group_id &&
        this.chosenIndicator.id === r.indicator_id &&
        r.evaluable_student && !r.evaluable_student.retired
      ));
      return dataInfo;
    },
    values: {
      get() {
        let infoData = this.grades.concat(this.studentInfo);
        return infoData;
      },
      async set(value) {
        this.editedValues = value;
        if (this.checkStudentsAveragesInconsistencies(value)) {
          this.showAutomaticAverageDialog = true;
        } else {
          this.updateGradesAndStats(false);
        }
      },
    },
    grades() {
      let dataInfo = this.results.map(result => ({
        run: result.evaluable_student.run,
        studentId: result.evaluable_student.id,
        runDv: result.evaluable_student.run_dv,
        fullName: result.evaluable_student.full_name,
        testId: result.test_number,
        value: result.value,
        alternativeText: result.alternative_text, // eslint-disable-line camelcase
        norm_value: result.norm_value, // eslint-disable-line camelcase
        class: this.$style.grade,
        format: { string: 'numberWithOneDecimal' },
      }));
      return dataInfo;
    },
    studentInfo() {
      return this.results.map(result => ({
        run: result.evaluable_student.run,
        testId: -1,
        value: result.evaluable_student.full_name,
        format: { string: 'startCase' },
      }));
    },
    rows() {
      return this.studentsAsRows;
    },
    studentsAsRows() {
      return Object.values(this.evaluableStudents).map(student => (
        {
          id: student.run,
          value: student.full_name,
          preferential: student.preferential,
          priority: student.priority,
          pieBoolean: student.pie,
          temporaryPie: student.pie && student.pie.includes('Transitorio'),
          permanentPie: student.pie && student.pie.includes('Permanente'),
          repetition: student.repetition,
          repetitionRisk: student.repetition_risk,
          repetitionRiskAbsenteeism: student.repetition_risk === 3, // eslint-disable-line no-magic-numbers
          repetitionRiskGrades: [1, 2, 4, 5].includes(student.repetition_risk), // eslint-disable-line no-magic-numbers
          link: {
            name: 'studentReport',
            params: { student: student.id },
          },
        }
      )).sort((a, b) => a.value.localeCompare(b.value));
    },
    testsAsColumns() {
      const testNumberMappings = {};
      let currentTestNumber = 1;
      const testsFirstRow = Object.values(
        this.results
          .slice()
          .sort((a, b) => a.test_number - b.test_number)
          .map(result => {
            const info = result.extra_info;
            const isAverageMatch = info && info.test_name ?
              info.test_name.match(this.isAverageRegexp) :
              false;
            if (!isAverageMatch && !testNumberMappings[result.test_number]) {
              testNumberMappings[result.test_number] = currentTestNumber++;
            }
            const label = isAverageMatch ?
              info.test_name.replace(/\d+-([PNF])-{0,1}(\d*)/, '$1$2') :
              `N${testNumberMappings[result.test_number]}`;
            const labelName = isAverageMatch && label === 'F' ? 'Promedio Final' : `Promedio ${TERM_NAMES[result.month]}`;
            const testName = info && info.test_name ? info.test_name : `Calificación ${testNumberMappings[result.test_number]}`;
            const test = {
              id: result.test_number,
              label,
              headerRow: 1,
              highlighted: isAverageMatch,
              testName: isAverageMatch ? labelName : testName,
              tooltip: true,
            };

            currentTestNumber = isAverageMatch ? 1 : currentTestNumber;
            return { [test.id]: {
              ...test,
              ...this.tooltipInfo(test),
            } };
          })
          .reduce((tests, test) => ({ ...tests, ...test }))
      );

      return testsFirstRow.concat(testsFirstRow.map(t => (
        {
          id: 100 + t.id, // eslint-disable-line no-magic-numbers
          headerRow: 2,
          label: this.$options.filters.numberOnly(
            this.$options.filters.numberWithOneDecimal(this.testAverage(t.id))
          ),
          highlighted: t.highlighted,
        }
      )));
    },
    studentInfoAsColumns() {
      return [
        { id: -1, label: 'Nombre', headerRow: 1 },
        { id: -1, label: 'Promedio:', headerRow: 2, class: this.$style.secondRowLabel },
      ];
    },
    columns() {
      const gradesAndStatsAsColumns = this.testsAsColumns;
      return this.studentInfoAsColumns
        .concat(gradesAndStatsAsColumns)
        .sort((a, b) => a.id - b.id);
    },
    isAverageRegexp() {
      return new RegExp(`${this.chosenSchool && this.chosenSchool.rbd}-([PNF](-d+)?)`);
    },
    defaultSortColumn() {
      return this.columns[0];
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    getTermAveragesByStudent(values) {
      const groupedValues = groupBy(values, 'studentId');
      const averages = {};
      Object.keys(groupedValues).forEach((studentId) => {
        averages[studentId] = {};
        const valuesByTerm = groupBy(groupedValues[studentId], 'termTestId');
        Object.keys(valuesByTerm).forEach((termId) => {
          const termValueWeights = valuesByTerm[termId].map(g => ({ value: g.newValue, weight: g.weight }));
          const termValues = valuesByTerm[termId].map(g => g.newValue);
          averages[studentId][termId] = termValueWeights.some(o => o.weight === null) ? averageWithoutNulls(termValues) : this.averageWithWeights(termValueWeights);
        });
      });
      return averages;
    },
    averageWithWeights(valuesWithWeights) {
      const cleanValues = valuesWithWeights.filter(o => ![undefined, '', null, NaN].includes(o.value));
      const parsedValues = cleanValues.map(o => ({
        value: typeof o.value === 'number' ? o.value : parseFloat(o.value.replace(',', '.')),
        weight: o.weight,
      }));
      const average = parsedValues.reduce((sum, curr) => ({
        sumProduct: sum.sumProduct + curr.value * curr.weight,
        weightSum: sum.weightSum + curr.weight,
      }), { sumProduct: 0, weightSum: 0 });
      return average.weightSum > 0 ? average.sumProduct / average.weightSum : null;
    },
    isTermAverage(testId) {
      return /^term\d+$/.test(testId);
    },
    isGrade(testId) {
      return typeof testId === 'number' && testId > 0;
    },
    isFinalAverage(testId) {
      return testId === 'F';
    },
    checkStudentsAveragesInconsistencies(values) {
      const studentValues = values.filter(g => g.testId !== -1).reduce((hash, g) => {
        hash[g.studentId] = hash[g.studentId] || { hasNewGrade: {} };
        if (this.isGrade(g.testId)) {
          hash[g.studentId].hasNewGrade[g.termTestId] = hash[g.studentId].hasNewGrade[g.termTestId] || !!g.newValue;
        } else {
          hash[g.studentId][g.testId] = !!g.newValue;
        }
        return hash;
      }, {});
      return Object.values(studentValues).some(g => this.checkStudentInconsistency(g));
    },
    checkStudentInconsistency(studentInfo) {
      const missingFinalGrade = !studentInfo.F;
      const gradesByTerm = studentInfo.hasNewGrade;
      const missingAnyTermAverage = Object.keys(gradesByTerm).some(k => gradesByTerm[k] && !studentInfo[k]);
      const hasAnyGrade = Object.values(gradesByTerm).some(s => s);
      const hasAnyTermAverage = Object.keys(studentInfo).filter(k => this.isTermAverage(k)).some(s => studentInfo[s]);
      return missingAnyTermAverage || missingFinalGrade && (hasAnyGrade || hasAnyTermAverage);
    },
    parseTermAveragesByStudent(grades) {
      return groupBy(
        grades.filter(g => this.isTermAverage(g.testId)),
        'studentId'
      );
    },
    /* eslint-disable max-statements */
    /* eslint-enable max-statements */
    needUpdate(value) {
      return this.isFinalAverage(value.testId) || this.isTermAverage(value.testId) || value.newValue !== value.value;
    },
    setViewParams() {
      this.optionsForChosenSchoolOnly = true;
      this.setReportParams({ 'school_ids': true, 'years': true, 'group_ids': true, 'indicator_ids': true });
      this.setReportKey('subject-test-grades-by-student');
    },
    getAggregationDimension(statistic) {
      if (statistic.statisticType.key === 'promedio-anual-asignatura-alumno') {
        const enrollment = this.taughtClassEnrollments.find(e => e.id === statistic.aggregationDimensionId);
        return { taughtClass: this.taughtClass, student: enrollment.student };
      }
      const termEnrollment = this.taughtClassTermsEnrollments.find(e => e.id === statistic.aggregationDimensionId);
      return { taughtClassTerm: termEnrollment.taughtClassTerm, student: termEnrollment.student };
    },
    parseEditedValue(value) {
      const newValue = value.newValue ? this.$options.filters.numberWithOneDecimal(value.newValue) : null;
      const ret = {
        value: newValue,
        studentId: value.studentId,
        action: newValue ? 'upsert' : 'delete',
      };
      return ret;
    },
    // eslint-disable-next-line max-statements
    colorFromResult(result) {
      const colormap = interpolate(['#FF0000', '#FFFFFF', '#4266f7']);
      const colormapRed = interpolate(['#FF0000', '#FF7277', '#4266f7']);
      if (result) {
        if (!result.value) return '#fff';
        const value = Math.min(Math.max(1, result.value), 7); // eslint-disable-line no-magic-numbers
        const range = 6;
        const normalizedValue = (value - 1) / range;
        if (result.value < APPROVAL_LIMIT_GRADE) { // eslint-disable-line no-magic-numbers
          return colormapRed(normalizedValue);
        }

        return colormap(normalizedValue);
      }

      return 'inherit';
    },
    testAverage(testNumber) {
      let sum = 0;
      let count = 0;
      const results = this.grades;
      results
        .filter(r => r.testId === testNumber && typeof r.value === 'number')
        .forEach(r => {
          sum += r.value;
          count++;
        });
      return count > 0 ? (sum / count) : NaN;
    },
    tooltipInfo(test) {
      const grades = this.grades.filter(value => value.testId === test.id);
      const getStudentUniqueIdentifier = g => g.run;
      const numericGrades = grades.filter(grade => ![undefined, null].includes(grade.value));
      const gradeValues = numericGrades.map(grade => parseFloat(grade.value));
      const studentsEvaluated = new Set(grades.map(getStudentUniqueIdentifier)).size;
      const studentsRedGrade = new Set(
        numericGrades.filter(grade => grade.value && parseFloat(grade.value) < APPROVAL_LIMIT_GRADE).map(getStudentUniqueIdentifier)
      ).size;
      const studentsGreaterThanSix = new Set(
        numericGrades.filter(grade => parseFloat(grade.value) > 6).map(getStudentUniqueIdentifier) // eslint-disable-line no-magic-numbers
      ).size;

      return {
        grades,
        studentsEvaluated,
        studentsRedGrade,
        studentsGreaterThanSix,
        minGrade: Math.min(...gradeValues),
        maxGrade: Math.max(...gradeValues),
        standardDeviation: standardDeviation(gradeValues),
        median: median(gradeValues),
        mostFrequentValues: mostFrequentValues(gradeValues).map(
          g => this.$options.filters.numberWithOneDecimal(parseFloat(g))
        ).join(' - '),
      };
    },
    getTestGrades(id) {
      const grades = this.grades;
      return [
        ['Alumno', 'Calificación'],
        ...grades
          .filter(g => g.testId === id)
          .sort((a, b) => a.value - b.value)
          .map(d => [
            `${d.fullName} (${d.run}-${d.runDv})`,
            d.value,
          ]),
      ];
    },
    openSubjectModal(column) {
      this.testDialogTest = column;
      this.testDialogData = this.getTestGrades(column.id);
      this.showTestDialog = true;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../../styles/app/variables";
.automatic-average-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title {
    color: $main-text-color;
    font-family: "GT Haptik", sans-serif;
    font-size: 28px;
    font-weight: bold;
    line-height:48px;
    text-align: center;
    width: 90%;
  }

  &-body {
    color: $main-text-color;
    font-family: "GT Haptik", sans-serif;
    font-size: 20px;
    line-height:48px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
    text-justify: inter-word;
    width: 90%;
  }
}

.switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.switch {
  margin: 0 10px
}

.subject-tests {
  padding: 0 30px;

  &-empty {
    text-align: center;
  }
}

.grade {
  text-align: center;
  border: 2px solid;
  padding: 2px;
  min-width: 27px;
}

.second-row-label {
  text-align: right;
}

.headerTooltip > *:not(:first-child) {
  margin-top: 5px;
}
</style>
