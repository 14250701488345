import { event } from 'vue-analytics';
import AuthApi from './../../api/auth';

const GUEST_SCHOOL_LIMIT = 4;

export default {
  namespaced: true,
  state: {
    authError: false,
    authErrorCode: null,
    userInfo: null,
    isLoggedIn: !!localStorage.getItem('token_radar'),
    token: localStorage.getItem('token_radar'),
    uid: localStorage.getItem('uid_radar'),
    googleAuthInfo: (() => {
      const googleAuthInfo = JSON.parse(decodeURIComponent(localStorage.getItem('google_auth_info')));
      localStorage.removeItem('google_auth_info');
      return googleAuthInfo;
    })(),
  },
  getters: {
    getAuthHeaders: state => (
      () => (
        {
          'X-User-Token': state.token,
          'X-User-Email': state.uid,
        }
      )
    ),
    userPermissions(state) {
      if (!state.userInfo) return {};

      return state.userInfo.permissions;
    },
    hasAttendanceCompromisePermission(state) {
      return state.userInfo && state.userInfo.permissions['attendance-compromise'];
    },
    hasAttendancePermission(state) {
      return state.userInfo && state.userInfo.permissions.attendance;
    },
    hasAdministratorReportPermission(state) {
      return state.userInfo && state.userInfo.permissions.administrator_report;
    },
    hasAptusClientPermission(state) {
      return state.userInfo && state.userInfo.permissions.aptus_client;
    },
    hasGradesPermission(state) {
      return state.userInfo && state.userInfo.permissions.grades;
    },
    hasEnrollmentTrackingPermission(state) {
      return state.userInfo && state.userInfo.permissions.enrollment_tracking;
    },
    hasInternalEvaluationsPermission(state) {
      return state.userInfo && state.userInfo.permissions.internal_evaluations;
    },
    hasImprovementPlanPermission(state) {
      return state.userInfo && state.userInfo.permissions.improvement_plan;
    },
    hasGroupReportPermission(state) {
      return state.userInfo && state.userInfo.permissions.group_report;
    },
    hasSchoolReportPermission(state) {
      return state.userInfo && state.userInfo.permissions.school_report;
    },
    hasStudentReportPermission(state) {
      return state.userInfo && state.userInfo.permissions.student_report;
    },
    hasManagementIndicatorsPermission(state) {
      return state.userInfo && state.userInfo.permissions.management_indicators;
    },
    hasOccupancyTrackingPermission(state) {
      return state.userInfo && state.userInfo.permissions.occupancy_tracking;
    },
    hasPresenteClientPermission(state) {
      return state.userInfo && state.userInfo.permissions.presente_client;
    },
    hasSipDashboardPermission(state) {
      return state.userInfo && state.userInfo.permissions.sip_dashboard;
    },
    hasSchoolTrackUploadFilesPermission(state) {
      return state.userInfo && state.userInfo.permissions.upload_schooltrack_files;
    },
    hasSalesianosLeadershipEvaluationPermission(state) {
      return state.userInfo && state.userInfo.permissions.salesianos_leadership_evaluation;
    },
    hasGoogleClassroomPermission(state) {
      return state.userInfo && state.userInfo.permissions.google_classroom;
    },
    hasPulsoPermission(state) {
      return state.userInfo && state.userInfo.permissions.pulso;
    },
    hasGoogleClassroomAdministratorPermission(state) {
      return state.userInfo && state.userInfo.permissions.google_classroom_administrator;
    },
    hasSchoolsEnrollmentCsvPermission(state) {
      return state.userInfo && state.userInfo.permissions.has_schools_enrollment_csv_permission;
    },
    hasSigeHistoricalStudentPromotionCsvPermission(state) {
      return state.userInfo && state.userInfo.permissions.has_sige_historical_student_promotion_csv_permission;
    },
    hasRadarUploadFilesPermission(state) {
      return state.userInfo && state.userInfo.permissions.upload_radar_files;
    },
    hasPsuDetailsPermission(state) {
      return state.userInfo && state.userInfo.permissions.psu_details;
    },
    hasTechnicalEducationQualificationPermission(state) {
      return state.userInfo && state.userInfo.permissions.technical_education_qualification;
    },
    hasHigherEducationGraduationPermission(state) {
      return state.userInfo && state.userInfo.permissions.higher_education_graduation;
    },
    hasBarrancasClientPermission(state) {
      return state.userInfo && state.userInfo.permissions.barrancas_client;
    },
    hasRadarGeneratedSigeFilesPermission(state) {
      return state.userInfo && state.userInfo.permissions.radar_generated_sige_files;
    },
    isFreeUser(state) {
      return state.userInfo && state.userInfo.plan === 'free';
    },
    freeOrGuestUser(state, getters) {
      return !state.userInfo || getters.isFreeUser;
    },
    isProUser(_, getters) {
      return !getters.freeOrGuestUser;
    },
    userType(state, getters) {
      if (!state.userInfo) return 'guest';
      if (getters.isFreeUser) return 'free';

      return 'pro';
    },
    userSchools({ userInfo }) {
      return (userInfo && userInfo.schools) || [];
    },
    userSchoolIds(_, getters) {
      return getters.userSchools.map(s => s.id);
    },
    userNetworkName({ userInfo }) {
      return (userInfo && userInfo.network_name);
    },
    chosenSchoolLimit({ userInfo }) {
      return userInfo && userInfo.schools_report_limit || GUEST_SCHOOL_LIMIT;
    },
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
      state.isLoggedIn = true;
    },
    LOGIN(state, user) {
      state.token = user.token;
      state.uid = user.uid;

      localStorage.setItem('token_radar', user.token);
      localStorage.setItem('uid_radar', user.uid);
    },
    LOGOUT(state) {
      state.isLoggedIn = false;

      state.token = null;
      state.tokenRadarManagement = null;
      state.uid = null;
      state.userInfo = null;

      localStorage.removeItem('uid_radar');
      localStorage.removeItem('token_radar');
      localStorage.removeItem('menu_data');
      localStorage.removeItem('item_select');
      localStorage.removeItem('item_index');
    },
    ERROR(state, err) {
      state.authError = true;
      state.authErrorCode = err.status;
      state.authErrorBody = err.body;
    },
    CLEAN_ERROR(state) {
      state.authError = false;
      state.authErrorCode = null;
    },
    MAKE_ATTACHMENT_REFRESHABLE(state, attachmentId) {
      const index = state.userInfo.user_attachments.findIndex(
        a => a.id === attachmentId
      );
      state.userInfo.user_attachments[index].refreshable = true;
    },
    MAKE_ATTACHMENT_UNREFRESHABLE(state, attachmentId) {
      const index = state.userInfo.user_attachments.findIndex(
        a => a.id === attachmentId
      );
      state.userInfo.user_attachments[index].refreshable = false;
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      try {
        const resp = await AuthApi.getUserInfo();
        if (resp.body === null) {
          throw new Error('Authentication error');
        } else {
          commit('SET_USER_INFO', resp.body.user);
          commit('options/ADD_SCHOOLS', resp.body.user.schools, { root: true });
          return resp.body.user;
        }
      } catch (err) {
        commit('LOGOUT');
        throw err;
      }
    },
    recoverPassword({ commit }, email) {
      return new Promise((resolve, reject) => {
        AuthApi.sendForgottenPassword(email)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            commit('ERROR', err);
            reject();
          });
      });
    },
    resetPassword({ commit }, creds) {
      return new Promise((resolve, reject) => {
        AuthApi.resetPassword(creds.email, creds.password, creds.token)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            commit('ERROR', err);
            reject(err);
          });
      });
    },
    // eslint-disable-next-line max-statements
    async login({ state, commit, dispatch }, creds) {
      commit('CLEAN_ERROR');
      try {
        const resp = await AuthApi.submit(creds);
        commit('LOGIN', resp.body);
        await dispatch('fetchUserInfo');
        const schoolIds = state.userInfo.schools.map(s => s.id);
        await dispatch('options/setChosenSchools', schoolIds, { root: true });
        const defaultSchool = state.userInfo.preferred_school_id || schoolIds[0];
        await dispatch('options/setChosenSchool', defaultSchool, { root: true });
        event({
          eventCategory: 'Login',
          eventAction: 'Login',
          eventLabel: 'Login',
          eventValue: creds.email,
        });
      } catch (err) {
        commit('ERROR', err);
        throw err;
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    makeAttachmentRefreshable({ commit }, attachmentId) {
      commit('MAKE_ATTACHMENT_REFRESHABLE', attachmentId);
    },
    makeAttachmentUnrefreshable({ commit }, attachmentId) {
      commit('MAKE_ATTACHMENT_UNREFRESHABLE', attachmentId);
    },
  },
};
