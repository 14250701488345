<template>
  <div class="report">
    <alert-info :text-alert="textData"/>
    <div class="rdr-view">
      <paywall
        :permitted="hasInternalEvaluationsPermission"
        :free="isFreeUser"
        restricted>
        <rdr-results-header
          :show-school-selector="isLoggedIn"
          month-picker
          hide-school-picker
          group-picker
          indicator-picker
          indicator-picker-label="Evaluación"
          year-picker
        >
        </rdr-results-header>
        <router-view/>

        <!-- <aptus-link-btn
          v-if="isAptusAccount"
          :aptus-link="aptusLink"/> -->
      </paywall>
    </div>
  </div>
</template>

<script>
import AuthMixin from '../../mixins/auth';
import PermissionMixin from '../../mixins/permission';
import ResultsHeader from '../../components/results/results-header.vue';
import AptusLink from '../../components/aptus-link.vue';
import AlertInfo from '../../components/utils/alert-info.vue';


export default {
  name: 'InternalEvaluationsByLevelView',
  components: {
    'rdr-results-header': ResultsHeader,
    'aptus-link-btn': AptusLink,
    AlertInfo,
  },
  mixins: [AuthMixin, PermissionMixin],
  data() {
    return {
      textData: 'Este gráfico muestra el porcentaje de estudiantes en cada nivel de logro por curso. Puedes ajustar los niveles de logro en la barra de la derecha para hacer tus análisis.',
    }
  },
  computed: {
    school() {
      return this.currentUser.schools.find(school => school.id.toString() === this.$route.params.school);
    },
    schoolRbd() {
      return this.school.rbd;
    },
    aptusLink() {
      return `https://www.aptuschile.cl/apt_system/AptusDigitalResultados/indexResultadoColegio/${this.schoolRbd}`;
    },
  },
};
</script>
