<template>
  <el-dialog
    :custom-class="$style.subjectDialog"
    :visible.sync="show"
    append-to-body>
    <h3 v-if="school">{{ school.name | startCase }}</h3>
    <h3 v-if="group && year">{{ group.name }} — {{ year }}</h3>
    <h3 v-if="subject">{{ subject.label }}</h3>
    <h3 v-if="test">{{ test.label }} — {{ test.testName }}</h3>
    <histogram
      :data="data"
      :options="{
        legend: {
          position: 'none',
        },
        hAxis: {
          title: 'Rango de calificación',
        },
        vAxis: {
          title: 'Cantidad de estudiantes',
        },
        histogram: {
          minValue: 1,
          maxValue: 7,
          bucketSize: 1
        },
    }"/>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import Histogram from '../../../components/histogram.vue';

export default {
  name: 'SubjectDialog',
  components: {
    'el-dialog': Dialog,
    Histogram,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    test: {
      type: Object,
      default: null,
    },
    school: {
      type: Object,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
    subject: {
      type: Object,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
};
</script>

<style lang="scss" module>
.subject-dialog {
  text-align: center;
}
</style>
