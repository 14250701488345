function longGenderMap(gender) {
  const mapping = { 'male': 'Masculino', 'female': 'Femenino' };
  return mapping[gender];
}

function shortGenderMap(gender) {
  const mapping = { 'male': 'M', 'female': 'F' };
  return mapping[gender];
}

export { longGenderMap, shortGenderMap };
