<template>
  <div class="report">
    <!-- <alert-info :text-alert="reportTitle" /> -->
    <div class="rdr-view">
      <year-warning-bar v-if="hasSchoolReportPermission" />
      <div>
        <div
          v-if="!isLoggedIn"
          :class="$style.schoolReport__header">
          <span
            v-if="chosenSchool">
            {{ chosenSchool.name | startCase }}
          </span>
        </div>
        <div :class="$style.schoolReport__header">
          <report-options report-key="school_report" />
          </report-options>
        </div>
      </div>
      <paywall
        :permitted="hasSchoolReportPermission"
        restricted>
        <div
          v-if="chosenSchool">
          <rdr-switcher
            :views="views"
          />
        </div>
      </paywall>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import AuthMixin from '../../mixins/auth';
import Paywall from '../../components/paywall.vue';
import RdrSwitcher from '../../components/utils/switcher.vue';
import GoBackLink from '../../components/go-back-link.vue';
import SchoolReportDashboard from './school-dashboard';
import ReportOptions from '../../components/report-options';
import YearWarningBar from '../../components/utils/year-warning-bar.vue';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'SchoolReportView',
  components: {
    'rdr-switcher': RdrSwitcher,
    ReportOptions,
    GoBackLink,
    SchoolReportDashboard,
    Paywall,
    YearWarningBar,
    AlertInfo,
  },
  mixins: [PermissionMixin, AuthMixin],
  data() {
    return {
      reportTitle: 'En esta ficha encontrarás los principales resultados de tu colegio. Haz click en un curso para ver detalles.',
      loading: 0,
      views: {
        left: {
          label: 'Ver Dashboard',
          component: SchoolReportDashboard,
          props: {
            dashboard: true,
          },
          color: '#9897ff',
        },
        right: {
          label: 'Ver Tabla',
          component: SchoolReportDashboard,
          props: {
            dashboard: false,
          },
          color: '#4266f7',
        },
      },
    };
  },
  computed: {
    ...mapGetters('options', ['chosenSchool']),
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .results-filter-table {
    margin-left: 60px;

    &__empty {
      text-align: center;
    }
  }

  .school-report {

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 28px;
      font-weight: 200;
      padding: 0 30px;

      & > *:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .report__container .loading-overlay {
    margin-top: 40px;
  }

  .dashboard-button {
    display: flex;
    flex-direction: row-reverse;
  }
</style>
