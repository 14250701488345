<template>
  <paywall
    :permitted="isLoggedIn && hasAttendancePermission"
    :free="isFreeUser"
    restricted
    transparent>
    <div id="downTable" class="results-colormap">
      <div class="results-colormap__color-map">
        <color-map-week
          :loading="loading"
          :rows="groupsAsRows"
          :columns="monthsAsColumns"
          :results="results"
          :totalRows="totalRows"
          :hide-empty-columns="true"
          :show-school-picker="false"
          :disable-school-adding="true"
          :percentage="true"
          :floor="true"
          :attendance="true"
          :column-header-hover="false"
          results-row-key="group_id"
        />
      </div>
      <share-whatsapp/>
      <template v-if="results.length !== 0">
        <color-map-footer
          :footer-bottom-paragraphs="footerParagraphs"
          text="La gama de colores representa el porcentaje de asistencia de la siguiente forma:"
          high="100%"
          average="90%"
          low="80% o menos"/>
      </template>
    </div>
    <!-- <color-map
      slot="mock"
      :rows="mockAttendanceData.groups"
      :columns="mockAttendanceData.columns"
      :results="mockAttendanceData.results"
      :default-open-columns="mockAttendanceData.defaultOpenColumns"
      :results-column-key="mockAttendanceData.resultsColumnKey"
      :results-row-key="mockAttendanceData.resultsIndicatorKey"
      :disable-school-adding="true"
      class="color-map--pro"/>
    -->
  </paywall>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColorMapWeek from '../../../components/color-map-week.vue';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';

import { MONTHS, offsetMonth } from '../../../utils/months';

export default {
  name: 'AttendancePercentageByDateView',
  components: {
    ColorMapFooter,
    ColorMapWeek,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [
        'Los cálculos de asistencia del año en curso se obtienen a partir de la información del sistema SIGE de cada establecimiento.',
        'Los colores son referenciales para cada concepto y no necesariamente representan las metas internas de cada proyecto educativo.',
      ],
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('options', ['groups']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      resultsGetter: 'results',
    }),
    results() {
      let resultRows = this.resultsGetter.filter( row => row.extra_info?.total !== true && row.test_number !== 0 && row.test_number !== 6);
      return resultRows.map(result => {
        return { ...result, column_id: `${"day"}_${result.test_number}` }; // eslint-disable-line camelcase
      });
    },
    groupsAsRows() {
      let answer = this.groups.filter(g => g.key !== "TD").map((group) => {
        return {
          id: group.id,
          key: group.key,
          label: group.short_name || "",
          format: "one_decimal",
          parent_id: group.parent_id, // eslint-disable-line camelcase
        }
         });
        return answer;
      },
    monthsAsColumns() {
      const columns = [];
      for(let i  = 1; i < 6; i++)
        columns.push({
          id: `${"day"}_${i}`,
          label: "Día " + i,
        })
      return columns;
    },

    totalRows() {
      const totalRows = this.resultsGetter
                        .filter((r) => r.extra_info?.total && r.test_number !== 0 && r.test_number !== 6)
                        .sort((r1, r2) => r1.test_number - r2.test_number)
                        .map(r => parseInt(r.value)+'%')

      return Object.assign({}, totalRows)
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true });
      this.setReportKey('attendance-aggregate-by-week-day'); // attendance-aggregate-by-week-day -- attendance
    },
  },
};
</script>
