<template>
  <footer v-if="!isLoggedIn">
    <p>
      <strong>Más información sobre</strong>
      <a
        href="https://www.radarescolar.cl/"
        class="lead"
        target="_blank">Radar Escolar</a>
    </p>
  </footer>
</template>

<script>
import AuthMixin from '../../mixins/auth';

export default {
  mixins: [AuthMixin],
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";

  footer {
    a.lead {
      color: $primary-color;
      font-family: $accent-font;
      text-decoration: none;
      font-size: 18px;
    }

    a { color: $primary-color; }
    p { text-align: center; }
  }
</style>
