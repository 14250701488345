export default {
  data() {
    return {
      autoScrollableComponentHeight: 0,
    };
  },
  updated() {
    this.autoScrollableComponentHeight = this.$refs.autoScrollableComponent.getBoundingClientRect().height;
  },
  watch: {
    autoScrollableComponentHeight(to, from) {
      if (to !== from) {
        const mobileBreakpoint = 750; // $mobile-breakpoint
        if (window.innerWidth < mobileBreakpoint) {
          this.autoScrollRight();
        }
      }
    },
  },
  methods: {
    autoScrollRight() {
      this.$refs.autoScrollableComponent.scrollLeft = window.innerWidth;
    },
  },
};
