<template>
  <div class="color-map-footer">
    <div class="color-map-footer__top">
      <p>
        {{ text }}
      </p>
    </div>
    <div class="color-map-footer__gradient-with-legends">
      <div :class="legendsClass">
        <div :class="lowClass">{{ low }}</div>
        <div :class="averageClass">{{ average }}</div>
        <div
          v-if="showHigh"
          :class="highClass">{{ high }}</div>
      </div>
      <div
        :style="gradientStyle"
        class="color-map-footer__color-gradient"/>
    </div>
    <div class="color-map-footer__bottom">
      <p v-for="item in footerBottomParagraphs">{{ item }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorMapFooter',
  props: {
    text: {
      default: 'La gama de colores representa la diferencia del puntaje obtenido por cada nivel con respecto a su grupo socioeconómico (GSE).',
      type: String,
    },
    high: {
      default: 'sobre el promedio',
      type: String,
    },
    average: {
      default: 'similar al promedio',
      type: String,
    },
    low: {
      default: 'bajo el promedio',
      type: String,
    },
    showHigh: {
      default: true,
      type: Boolean,
    },
    footerBottomParagraphs: {
      default: () => [
        `Los colores son ilustrativos y no necesariamente representan diferencias significativas estadísticamente.
        Para mayor detalle se recomienda consultar la información completa en el sitio web de la Agencia de Calidad de la Educación.`,
        'La importancia relativa de los indicadores depende de cada proyecto educativo, su contexto y realidad.',
      ],
      type: Array,
    },
    whiteReplacesYellow: null,
    reverseLegendsRow: null,
  },
  computed: {
    gradientStyle() {
      let gradient = `linear-gradient(0.25turn, #ED050B, ${this.whiteReplacesYellow ? '#FEFFF2' : '#FFFDCC'}${this.showHigh ? ', #05BD3C' : ''})`;
      if (this.reverseLegendsRow) {
        gradient = `linear-gradient(0.25turn, ${this.showHigh ? '#05BD3C,' : ''} ${this.whiteReplacesYellow ? '#FEFFF2' : '#FFFDCC'}, #ED050B)`;
      }

      return {
        background: gradient,
      };
    },
    averageClass() {
      return {
        'color-map-footer__legend--high': !this.showHigh && !this.reverseLegendsRow,
        'color-map-footer__legend--low': !this.showHigh && this.reverseLegendsRow,
        'color-map-footer__legend': this.showHigh,
      };
    },
    legendsClass() {
      return {
        'color-map-footer__legends--reverse': this.reverseLegendsRow,
        'color-map-footer__legends': !this.reverseLegendsRow,
      };
    },
    lowClass() {
      return {
        'color-map-footer__legend--high': this.reverseLegendsRow,
        'color-map-footer__legend--low': !this.reverseLegendsRow,
      };
    },
    highClass() {
      return {
        'color-map-footer__legend--low': this.reverseLegendsRow,
        'color-map-footer__legend--high': !this.reverseLegendsRow,
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

  .color-map-footer {
    margin-top: 40px;
    text-align: center;
    font-weight: 200;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 30px;
    }

    &__gradient-with-legends {
      width: 50%;
      margin: auto;

      @media only screen and (max-width: $mobile-breakpoint) {
        width: 100%;
      }
    }

    &__legends {
      margin: 3px 0;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      &--reverse {
        margin: 3px 0;
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;
      }
    }

    &__legend {
      margin: 0;
      padding: 0;
      width: 33%;
      text-align: center;
      font-size: 14px;

      &--low {
        margin: 0;
        padding: 0;
        width: 33%;
        text-align: left;
        float: left;
        font-size: 14px;
      }

      &--high {
        margin: 0;
        padding: 0;
        width: 33%;
        text-align: right;
        float: right;
        font-size: 14px;
      }
    }

    &__color-gradient {
      height: 8px;
      border-radius: 4px;
    }

    &__color {
      width: 15px;
      height: 15px;
      margin-right: 10px;

      &--high {
        background-color: #05BD3C;
      }

      &--average {
        background-color: #FFFDCC;
      }

      &--white-average {
        background-color: #FEFFF2;
      }
      &--low {
        background-color: #ED050B;
      }
    }
  }
</style>
