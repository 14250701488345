export default {
  data() {
    return {
      chartSize: {},
      printQuery: null,
    };
  },
  computed: {
    chartContainerRef() {
      return 'container';
    },
  },
  mounted() {
    this.printQuery = window.matchMedia('print');
    this.printQuery.addListener(this.setChartSize);
  },
  destroyed() {
    this.printQuery.removeListener(this.setChartSize);
  },
  methods: {
    setChartSize() {
      const containerRect = this.$refs[this.chartContainerRef].getBoundingClientRect();
      this.chartSize = {
        width: containerRect.width,
        height: containerRect.height,
      };
    },
  },
};