<template>
  <div>
    <historic-results
      :class="{ 'hide-print': !isProUser }"
      :with-groups="true"
      :restricted="restricted"
      :restricted-school-picker="!isLoggedIn"
      :exit-button="restrictedSchoolPicker"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import HistoricResults from '../../components/historic-results.vue';
import AuthMixin from '../../mixins/auth';
import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';

export default {
  name: 'ComparativeHistoricalView',
  components: {
    HistoricResults,
  },
  mixins: [AuthMixin, PermissionMixin, ResultsMixin],
  computed: {
    ...mapState('signUp', {
      restrictedSchoolPicker: 'showSignUpDialog',
    }),
    ...mapState('results', ['reportGroups']),
    ...mapGetters('options', ['chosenGroup']),
    restricted() {
      if (this.restrictedSchoolPicker) {
        return true;
      }
      if (this.chosenGroup) {
        if (!this.isLoggedIn && this.chosenGroup.grade === '4') {
          return false;
        }
        const reportGroup = this.reportGroups.find(item => item.group_id === this.chosenGroup.id);
        if (reportGroup && reportGroup.restricted_to_logged_users && !this.isLoggedIn) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    async chosenGroup() {
      await this.fetchOptionsAndResults();
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'chosen_school_ids': true, 'group_ids': true });
      this.setReportKey('historical');
    },
  },
};
</script>

<style lang="scss">
</style>
