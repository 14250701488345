import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import ErrorMixin from './error';
import ResultsApi from '../api/results';

export default {
  mixins: [ErrorMixin],
  data() {
    return {
      optionsForChosenSchoolOnly: false,
    };
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('options', [
      'chosenSchoolId',
      'chosenSchoolIds',
      'chosenIndicatorKey',
      'chosenYear',
      'chosenMonth',
      'groups',
      'fetchReady',
    ]),
    ...mapGetters('options', ['chosenGroup']),
    ...mapGetters('results', ['results']),
  },
  watch: {
    fetchReady(to, from) {
      if (!to || to === from) return;
      try {
        this.fetchOptionsAndResults();
      } catch (_) {
        this.error = true;
      }
    },
    chosenSchoolId(to, from) {
      if (to === from) return;
      this.fetchOptionsAndResults();
    },
    chosenSchoolIds(to, from) {
      if (to.length === 0 || from && to.every(s => from.includes(s))) return;
      this.fetchOptionsAndResults();
    },
    chosenYear(to, from) {
      this.validateThenFetchResults(to, from);
    },
    chosenMonth(to, from) {
      this.validateThenFetchResults(to, from);
    },
    chosenIndicatorKey(to, from) {
      this.validateThenFetchResults(to, from);
    },
    chosenGroup(to, from) {
      this.validateThenFetchResults(to, from, 'id', 'key');
    },
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapMutations('options', {
      setFetchReady: 'SET_FETCH_READY',
      setReportKey: 'SET_CHOSEN_REPORT_KEY',
      setChosenMonth: 'SET_CHOSEN_MONTH',
    }),
    ...mapActions('options', ['setChosenGroup', 'fetchOptions', 'setChosenYear']),
    ...mapActions('results', ['fetchResults']),
    ...mapActions('intro', { refreshIntro: 'refresh' }),
    validateThenFetchResults(to, from, ...validateBy) {
      if (from === null) return;
      if (to === null || to === undefined || (validateBy.length === 0 && to === from)) return;
      if (!!from && validateBy.length > 0 && validateBy.every(key => to[key] === from[key])) return;
      this.fetchResults();
    },
    async fetchOptionsAndResults() {
      this.setViewParams();
      await this.fetchOptions({ chosenSchoolOnly: this.optionsForChosenSchoolOnly });
      await this.fetchResults();
      this.refreshIntro();
    },
    setViewParams() {
      // this function has to be implemented by the view that uses this mixin
    },
    async getOptionsForCombination(schoolIds, reportKey, groupKey, year) {
      /* eslint-disable camelcase */
      const params = {
        group_keys: groupKey,
        years: year,
        school_ids: schoolIds,
        options_only: true,
        report_key: reportKey,
      };
      return ResultsApi.getResults(params);
      /* eslint-enable camelcase */
    },
    async getDefaultGroupKeyAndYear(schoolIds, reportKey) {
      /* eslint-disable no-magic-numbers */
      const currentYear = parseInt((new Date()).getFullYear(), 10);
      const defaultCombinations = [
        { groupKey: '12', year: currentYear - 1 },
        { groupKey: '8', year: currentYear - 1 },
        { groupKey: '4', year: currentYear - 1 },
        { groupKey: '10', year: currentYear - 1 },
        { groupKey: '4', year: currentYear - 2 },
      ];

      for (const combination of defaultCombinations) {
        const response = await this.getOptionsForCombination(schoolIds, reportKey, combination.groupKey, combination.year);
        if (response.body.results_with_stats.result_count > 0) return combination;
      }

      return { groupKey: '12', year: currentYear - 2 };
      /* eslint-enable no-magic-numbers */
    },
    async searchDefaultGroupAndYear(schoolIds, reportKey) {
      if (this.results.length === 0) {
        this.addLoading();
        const { groupKey, year } = await this.getDefaultGroupKeyAndYear(schoolIds, reportKey);
        this.setChosenGroup(groupKey);
        this.setChosenYear(year);
        await this.fetchOptionsAndResults();
        this.removeLoading();
      }
    },
  },
};
