import Vue from 'vue';

export default {
  getResults(params) {
    try {
      return Vue.http.get('/api/v1/results.json', {
        params,
      });
    } catch (e) {
      console.log('Error', e);
    }
  },
  getGroups() {
    return Vue.http.get('/api/v1/groups.json');
  },
};
