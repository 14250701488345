import ErrorMessage from '../components/utils/error-message.vue';

export default {
  components: {
    'error-message': ErrorMessage,
  },
  data() {
    return {
      error: false,
    };
  },
};
