<template>
  <transition
    name="toggle"
  >
    <div
      v-if="displayMobileMenu"
      class="mobile-only mobile-menu__container">
      <div
        :class="{ 'mobile-menu__content--small' : freeOrGuestUser }"
        class="mobile-menu__content">
        <ul
          v-for="item in items"
          :key="`mobile-menu-${item.key}`">
          <li
            :class="[
              {'mobile-menu__link': clickable(item)},
              { 'mobile-menu__link--active' : clickable(item) && item.routes && routesMatch(item.routes) },
            ]"
            @click="handleItemClick(item)">
            <span class="mobile-menu__link-text">{{ item.label }}</span>
            <span
              v-if="item.pro && freeOrGuestUser"
              class="mobile-menu__pro-badge">PRO</span>
          </li>
          <ul>
            <li
              v-for="subitem in item.children"
              :key="`mobile-menu-${subitem.key}`"
              :class="[
                'mobile-menu__link',
                { 'mobile-menu__link--active' : routesMatch(subitem.routes) }
              ]"
              @click="linkWithToggle(subitem.link)">
              <span class="mobile-menu__link-text">{{ subitem.label }}</span>
              <span
                v-if="subitem.pro && freeOrGuestUser"
                class="mobile-menu__pro-badge">PRO</span>
            </li>
          </ul>
        </ul>
        <ul v-if="isLoggedIn">
          <li>
            <span class="mobile-menu__link-text">Mi Cuenta</span>
            <span class="mobile-menu__link-text--blur"> — {{ (currentUser && currentUser.name) || 'Cargando...' }}</span>
          </li>
          <ul>
            <li
              :class="{ 'mobile-menu__link--active' : routeMatches('settings') }"
              class="mobile-menu__link"
              @click="linkWithToggle('settingsLink')">
              <span class="mobile-menu__link-text">Configuración</span>
            </li>
            <li
              class="mobile-menu__link"
              @click="logout">
              <span class="mobile-menu__link-text">Cerrar sesión</span>
            </li>
          </ul>
        </ul>
      </div>
      <div
        v-if="freeOrGuestUser"
        class="mobile-menu__footer">
        <router-link
          v-if="freeOrGuestUser"
          :to="proQuoteLink"
          class="rdr-btn rdr-btn--small rdr-btn--important">
          <span>Cotiza</span>
        </router-link>
        <a
          v-if="!isLoggedIn"
          class="rdr-btn rdr-btn--small rdr-btn--primary"
          @click="signUp">
          <span>Registrarse</span>
        </a>
        <a
          v-if="!isLoggedIn"
          class="rdr-btn rdr-btn--small"
          @click="loginPopUp">
          <span>Iniciar sesión</span>
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

import items from './menu';

import AuthMixin from '../../mixins/auth';
import LinksMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';

export default {
  name: 'MobileMenu',
  mixins: [AuthMixin, LinksMixin, PermissionMixin],
  computed: {
    ...mapState({
      displayMobileMenu: state => state.ui.displayMobileMenu,
    }),
    items() {
      return items.filter(i => this.checkItemPermissions(i)).map((i) => {
        if (!i.children) return i;

        return {
          ...i,
          children: i.children.filter(c => this.checkItemPermissions(c)),
        };
      });
    },
  },
  methods: {
    clickable(item) {
      return !item.children || !item.children.length;
    },
    handleItemClick(item) {
      if (this.clickable(item)) {
        this.linkWithToggle(item.link);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/app/variables";

$header-height: 55px;
$footer-height: 55px;

$header-background: #f7f7ff;
$header-border: #ededff;
$content-background: rgb(252, 252, 255);
$active-background: rgb(250, 250, 250);
$footer-background: rgb(247, 247, 250);

.mobile-menu {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1500;

  &__container {
    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1500;
    background-color: $content-background;
  }

  &__content {
    width: 100%;

    &--small {
      margin-bottom: 55px;
    }

    ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    li {
      position: relative;
      padding-left: 30px;
      list-style-type: none;
    }

    > ul {
      &:not(:last-child) {
        border-bottom: 1px solid $header-border;
      }

      > li {
        font-weight: bold;
      }

      > ul > li:before {
        content: "—";
        margin-right: 10px;
      }
    }
  }

  &__footer {
    position: fixed;
    width: 100vw;
    height: $footer-height;
    bottom: 0;
    z-index: 1501;
    background-color: $footer-background;
    display: flex;
    justify-content: center;
    align-items: center;

    .rdr-btn:not(:first-child) {
      margin-left: 8px;
    }
  }

  &__link {
    cursor: pointer;
    font-weight: normal !important;

    &:hover:not(&--active) {
      background-color: $header-background;
      padding-left: 26px !important;
      border-left: 4px solid lightslategrey;
    }

    &--active {
      opacity: 1;
      border-left-color: $primary-color;
      background-color: $active-background;
      color: $primary-color;
      padding-left: 26px !important;
      border-left: 4px solid $primary-color;
    }
  }

  &__link-text {
    height: 50px;
    line-height: 50px;

    &--blur {
      color: lightslategrey;
    }
  }

  &__pro-badge {
    padding: 0px 6px;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    background-color: $primary-color;
    border-radius: 10px;
    position: absolute;
    right: 30px;
    top: 18px;
  }
}

.toggle-enter-active,
.toggle-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.toggle-enter,
.toggle-leave-to {
  opacity: 0;
  transform: translateX(-100vw);
}

.title-bar {
  background-color: #f7f7f7;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  position: fixed !important;
  z-index: 1000;
  width: 100vw;
  height: 31px;
  top: $header-height;
  padding: 5px 20px;

  &__text {
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
  }
}
</style>
