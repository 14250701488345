<template>
  <div
    v-if="isMobile"
    :class="$style.container">
    <el-dialog
      :visible.sync="show"
      :class="$style.containerDialog"
      :show-close="showClose"
      append-to-body
      center>
      <div
        v-for="(title, index) in titles"
        slot="title"
        :key="index"
        :class="[
          $style.title,
          !!title.class ? title.class : ''
      ]">
        {{ title.text }}
      </div>
      <slot/>
    </el-dialog>
  </div>
  <div v-else>
    <slot/>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import DisplayMixin from '../../mixins/display';

export default {
  name: 'MobileHandlerDecorator',
  components: {
    'el-dialog': Dialog,
  },
  mixins: [DisplayMixin],
  props: {
    titles: {
      type: Array,
      default: () => ([{
        text: '',
        class: '',
      }]),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
  },
};
</script>
<style lang="scss" module>

  .container {
    width: 95%;
    display: flex;
    align-items: start;
    font-size: 13px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    white-space: normal;
    overflow: hidden;
  }

  .container-dialog {
    :global(.el-dialog) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60%;

    }
  }
</style>
