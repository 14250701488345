<template>
  <rdr-picker
    v-if="pickeRender"
    :options="indicatorOptions"
    :value="chosenIndicator"
    :on-change="pick"
    :picker-style="pickerStyle"
    :size="size"
    :loading="loading"
    :empty-choice="emptyChoiceVal"
    value-property="key"
    label-property="label"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      emptyChoiceVal: false,
      pickeRender: true,
    }
  },
  props: {
    pickerStyle: {
      type: String,
      default: 'inline',
    },
    size: {
      type: String,
      default: '',
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    donde: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('options', ['indicators']),
    ...mapGetters('options', ['chosenIndicator']),
    indicatorOptions() {
      const options = this.indicators.map(indicator => {
        if (indicator.parent_id) {
          const indicatorClone = JSON.parse(JSON.stringify(indicator));
          indicatorClone.label = `— ${indicatorClone.label}`;
          return indicatorClone;
        }
        return indicator;
      });

      if (options.length > 0 &&
        (!this.chosenIndicator ||
        !options.some((i) => i.id === this.chosenIndicator.id))) {
        if(this.donde === 'Asignatura') {
            this.setChosenIndicatorKey(null);
            this.emptyChoiceVal = true;
        } else {
          this.pick(options[0].key);
        }
      }
      return options;
    },
  },
  watch: {
    indicators: {
      handler() {
        if (this.indicators.length > 0 && (!this.chosenIndicator ||
        (this.chosenIndicator &&
        !this.indicators.some((i) => i.id === this.chosenIndicator.id)))) {
          if(this.donde === 'Asignatura' && this.chosenIndicator === undefined) {
            this.setChosenIndicatorKey(null);
            this.emptyChoiceVal = true;
          } else {
            this.pick(this.indicators[0].key);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('options', ['setChosenIndicatorKey']),
    pick(value) {
      if (!value) return;
      let timeSet = 50;
      if(this.chosenIndicator === undefined) {
        this.pickeRender = false;
        let timeSet = 3000;
        this.setChosenIndicatorKey('first');
      }
      setTimeout(() => {
        this.setChosenIndicatorKey(value);
        this.$ga.event({
          eventCategory: 'IndicatorPicker',
          eventAction: 'Pick',
          eventLabel: 'OptionSelect',
          eventValue: value,
        });
        this.pickeRender = true;
      }, timeSet);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
