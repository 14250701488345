<template>
  <div class="report">
    <template v-if="isGradesStudentSubjectAverages">
      <alert-info :text-alert="textInfo" :icon-viwe="false"/>
    </template>
    <template v-if="isGradesStudentSubjectTests">
      <alert-info :text-alert="textInfo" :icon-viwe="false"/>
    </template>

    <div class="rdr-view grades-by-group-view">
      <rdr-results-header
        :tabs="tabs"
        :indicator-picker="isGradesStudentSubjectTests"
        :show-school-selector="isLoggedIn"
        :is-months="false"
        :month-picker="routeMatches('gradesStudentSubjectAverages')"
        :show-planned-term-picker="!isGradesStudentSubjectTests"
        :include-annual-term="isGradesStudentSubjectAverages"
        indicator-picker-label="Asignatura"
        hide-school-picker
        year-picker
        group-picker
        only-result-groups
        show-school-picker
        show-school-year-picker
        show-enrolled-class-picker>

        <!-- <span
          v-if="isGradesStudentSubjectAverages"
          class="results-header__subtitle">
          Acá se muestran todos los promedios de notas por asignatura de los alumnos del curso selecionado.
        </span>
        <span
          v-if="isGradesStudentSubjectTests"
          class="results-header__subtitle">
          Acá se muestran todas las notas del curso y asignatura seleccionados.
        </span> -->
        <br>
        <span v-if="lastUpdated" class="results-header__subtitle">
          Última actualización: {{ lastUpdated }}
        </span>
      </rdr-results-header>
      <tutor-report-form
        v-if="hasGradesPermission"
        :visible.sync="showDialog" />
      <div
        v-if="hasGradesPermission"
        class="tutor-report-button">
        <button
          class="rdr-btn"
          @click="openTutorReportDialog">
          <span class="icon-wrapper">
            <i class="material-icons">picture_as_pdf</i>
          </span>
          <span>
            Generar reporte
          </span>
          <span>
            <info-icon
              text-style="max-width: 50%"
              text="Apretando el botón puedes generar automáticamente las
              fichas con el resumen de calificaciones por cada estudiante. Te llegarán por email en pdf"/>
          </span>
        </button>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>

import ResultsHeader from '../../components/results/results-header.vue';
import TutorReportForm from '../../components/dialogs/tutor-report.vue';
import InfoIcon from '../../components/info-icon.vue';
import LinksMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';
import LastUpdated from '../../mixins/last-updated';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'GradesByGroupView',
  components: {
    'rdr-results-header': ResultsHeader,
    TutorReportForm,
    InfoIcon,
    AlertInfo,
  },
  mixins: [LinksMixin, LastUpdated, PermissionMixin],
  data() {
    return {
      showDialog: false,
      textInfo: '',
    };
  },
  computed: {
    tabs() {
      return [
        { icon: 'class', name: 'Todas las asignaturas', link: this.gradesStudentSubjectAveragesLink },
        { icon: 'description', name: 'Detalle asignatura', link: this.gradesStudentSubjectTestsLink },
      ];
    },
    isGradesStudentSubjectAverages() {
      this.textInfo = 'Acá se muestran todos los promedios de calificaciones por asignatura de los estudiantes del curso selecionado.';
      return this.$route.name === 'gradesStudentSubjectAverages';
    },
    isGradesStudentSubjectTests() {
      this.textInfo = 'Acá se muestran todas las calificaciones del curso y asignatura seleccionados.';
      return this.$route.name === 'gradesStudentSubjectTests';
    },
  },
  methods: {
    openTutorReportDialog() {
      this.showDialog = true;
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

  .grades-by-group-view {

    &__sub-pickers {
      margin: auto;
    }

    &__subtitle-under-pickers {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }

    .results-header {
      margin-bottom: 10px;
    }
  }

  .tutor-report-button {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    width: 260px;
    justify-content: center;
    margin: auto;
  }

</style>
