<template>
  <el-dialog
    v-if="signUpForm"
    :visible.sync="show"
    class="radar-dialog sign-up-dialog"
  >
    <div
      slot="title"
      class="radar-dialog__title">
      Formulario de registro
    </div>
    <div class="conten-register">
      <form
        class="login-dialog__form"
        @submit.prevent="signUp">

        <div v-show="!success">
          <rdr-tooltip
            :value="checkError('name')"
            trigger="manual"
            placement="right">
            <input
              slot="reference"
              v-model="name.value"
              class="login-dialog__input rdr-input rdr-input--block"
              type="text"
              placeholder="Nombre completo"
              title="Debes rellenar este campo"
              autofocus
            >
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :value="checkError('phone')"
            trigger="manual"
            placement="right">
            <input
              slot="reference"
              v-model="phone.value"
              class="login-dialog__input rdr-input rdr-input--block"
              type="text"
              placeholder="Teléfono de contacto"
              title="Mínimo 8 dígitos. Solo puedes ingresar números y el símbolo +"
            >
            <div v-html="'Mínimo 8 dígitos. Solo puedes ingresar números y el símbolo +'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :value="checkError('email')"
            trigger="manual"
            placement="right">
            <input
              slot="reference"
              v-model="email.value"
              class="login-dialog__input rdr-input rdr-input--block"
              type="email"
              placeholder="Correo electrónico"
              title="Formato de email inválido"
            >
            <div v-html="'Formato de email inválido'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :value="checkError('preferredSchool')"
            trigger="manual"
            placement="right">
            <school-select
              slot="reference"
              v-model="preferredSchool.value"
              class="rdr-select relation--picker__select login-dialog__input"
              placeholder="Colegio o RBD"
            />
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :value="checkError('position')"
            trigger="manual"
            placement="right">
            <v-ener-select
              slot="reference"
              :options="positions"
              v-model="position.value"
              class="rdr-select relation--picker__select login-dialog__input"
              placeholder="Relación con el colegio"
            />
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :disabled="!needManagementSystem"
            :value="checkError('managementSystem')"
            trigger="manual"
            placement="right">
            <v-ener-select
              v-show="needManagementSystem"
              slot="reference"
              :options="managementSystems"
              v-model="managementSystem.value"
              class="rdr-select relation--picker__select login-dialog__input"
              placeholder="Sistema de Gestión que usa el Colegio"
            />
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :disabled="!needOtherManagementSystem"
            :value="checkError('otherManagementSystem')"
            trigger="manual"
            placement="right">
            <auto-complete-select
              v-show="needOtherManagementSystem"
              slot="reference"
              :select-options="managementSystemOthers"
              v-model="otherManagementSystem.value"
              class="rdr-select relation--picker__select login-dialog__input"
              placeholder="Escriba el sistema de gestión del colegio"
            />
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <input
            v-model="schoolNetwork.value"
            class="login-dialog__input rdr-input rdr-input--block"
            type="text"
            placeholder="Red a la que pertenece (opcional)"
          >
          <rdr-tooltip
            :value="checkError('password')"
            trigger="manual"
            placement="right">
            <input
              slot="reference"
              v-model="password.value"
              class="login-dialog__input rdr-input rdr-input--block"
              type="password"
              placeholder="Contraseña"
            >
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <rdr-tooltip
            :value="checkError('passwordConfirmation')"
            trigger="manual"
            placement="right">
            <input
              slot="reference"
              v-model="passwordConfirmation.value"
              class="login-dialog__input rdr-input rdr-input--block"
              type="password"
              placeholder="Reingrese contraseña"
            >
            <div v-html="'Debes rellenar este campo'"/>
          </rdr-tooltip>
          <button
            :disabled="submitting"
            class="rdr-btn rdr-btn--block rdr-btn--large rdr-btn--primary"
            type="submit"
          >
            <span v-if="submitting">Enviando...</span>
            <span v-else>Registrar</span>
          </button>
        </div>
        <div
          v-show="success"
          class="login-dialog__success">
          <span>
            Se ha enviado un mensaje de activación de tu cuenta al correo ingresado
          </span>
        </div>
        <div
          v-show="unauthorized"
          class="login-dialog__error">
          <span v-html="unauthorizedMessage" />
        </div>
      </form>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Dialog, Tooltip } from 'element-ui';
import LinksMixin from '../../mixins/links';
import SchoolSelect from '../school-select.vue';

import AutoCompleteSelect from './auto-complete-select.vue';

export default {
  name: 'SignUp',
  components: {
    'el-dialog': Dialog,
    SchoolSelect,
    AutoCompleteSelect,
    'el-tooltip': Tooltip,
  },
  mixins: [LinksMixin],
  data() {
    return {
      signUpForm: true,
      submitting: false,
      submitted: false,
      success: false,
      name: { value: '', pattern: /^(?!\s*$).+/ },
      phone: { value: '', pattern: /^[\d+(]+[\d ()-]{6,}[\d)]$/ },
      email: { value: '', pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ },
      preferredSchool: { value: null, pattern: null },
      position: { value: '', pattern: null },
      managementSystem: { value: null, pattern: null },
      otherManagementSystem: { value: null, pattern: null },
      schoolNetwork: { value: '', repattern: null },
      password: { value: '', pattern: null },
      passwordConfirmation: { value: '', pattern: null },
      positionsWithManagementSystem: [
        'sostenedor',
        'director',
        'equipoDirectivo',
      ],
      positions: [
        { key: 'sostenedor', label: 'Sostenedor' },
        { key: 'director', label: 'Director' },
        { key: 'equipoDirectivo', label: 'Integrante Equipo Directivo' },
        { key: 'profesor', label: 'Profesor' },
        { key: 'apoderado', label: 'Apoderado' },
        { key: 'other', label: 'Otro' },
      ],
      managementSystems: [
        { key: 'napsis', label: 'Napsis (SinedUC)' },
        { key: 'schoolTrack', label: 'SchoolTrack' },
        { key: 'edufacil', label: 'EDUFACIL' },
        { key: 'syscol', label: 'Syscol' },
        { key: 'webclass', label: 'Webclass' },
        { key: 'alexia', label: 'Alexia' },
        { key: 'appoderado', label: 'Appoderado' },
        { key: 'otro', label: 'Otro / No sé' },
      ],
      managementSystemOthers: [
        { key: 'eGed', label: 'eGed' },
        { key: 'eduka7', label: 'Eduka7' },
        { key: 'insignia', label: 'Insignia' },
        { key: 'lbclases', label: 'Lbclases' },
        { key: 'mateoXXIMateoNet', label: 'Mateo XXI Mateo.Net' },
        { key: 'sae', label: 'SAE' },
        { key: 'dataColl', label: 'Data Coll' },
        { key: 'sga', label: 'SGA: Sistema de Gestion Académica' },
        { key: 'senda', label: 'S.E.N.D.A' },
        { key: 'uCursos', label: 'U-Cursos' },
        { key: 'servicolegio', label: 'www.servicolegio.com' },
        { key: 'mySchool', label: 'MySchool' },
        { key: 'codesin', label: 'CODESIN0' },
        { key: 'colegioInteractivo', label: 'Colegio Interactivo' },
        { key: 'hijasDeMariaAuxiliadora', label: 'Hijas de Maria Auxiliadora' },
        { key: 'hermanosMaristas', label: 'Hermanos Maristas' },
        { key: 'crecic', label: 'Crecic' },
        { key: 'edoome', label: 'Edoome' },
        { key: 'eduplan', label: 'Eduplan' },
        { key: 'eduprotic', label: 'Eduprotic' },
        { key: 'effectiveResponse', label: 'Effective Response' },
        { key: 'fullCollege', label: 'Full College' },
        { key: 'telefonica', label: 'Telefonica' },
        { key: 'seniorsoft', label: 'SENIORSOFT' },
        { key: 'zero', label: 'Zero' },
        { key: 'edutec', label: 'Edutec' },
        { key: 'sslComputacion', label: 'SSL COMPUTACION' },
        { key: 'infeduc', label: 'INFEDUC' },
        { key: 'osiris', label: 'Osiris' },
        { key: 'sage', label: 'SAGE' },
        { key: 'electrocoop', label: 'Electrocoop' },
        { key: 'goodwinmanager', label: 'Goodwinmanager' },
        { key: 'otrosDesarolloInterno', label: 'Otros (desarrollo interno)' },
        { key: 'otrosSWComercial', label: 'Otros (sw comercial)' },
        { key: 'noUtiliza', label: 'Ninguno / No utiliza / Sin sistema' },
        { key: 'noSe', label: 'No sé' },
      ],
    };
  },
  computed: {
    ...mapGetters('options', ['chosenSchool']),
    ...mapState('auth', ['googleAuthInfo']),
    chosenManagementSystem() {
      if (this.position.value &&
      this.positionsWithManagementSystem.includes(this.position.value.key) &&
      this.managementSystem.value) {
        return this.managementSystem.value.key === 'otro' ?
          this.otherManagementSystem.value.key : this.managementSystem.value.key;
      }
      return null;
    },
    error() {
      return this.$store.state.signUp.signUpError;
    },
    unauthorized() {
      return this.error && this.$store.state.signUp.signUpErrorCode === 422; // eslint-disable-line no-magic-numbers
    },
    unauthorizedMessage() {
      let errorMessage = '';
      if (this.error && this.$store.state.signUp.signUpErrorMessage) {
        const errorBody = this.$store.state.signUp.signUpErrorMessage;
        let newField = '';
        for (const field of Object.keys(errorBody)) {
          if (field.includes('password')) {
            newField = 'contraseña';
          }
          errorMessage = `<br> ${newField} ${errorBody[field][0]}`;
        }
      }
      return errorMessage;
    },
    show: {
      get() { return this.$store.state.ui.dialogs.signUp.open; },
      set(value) { this.$store.commit({ type: 'ui/SET_DIALOG_STATE', dialog: 'signUp', state: value }); },
    },
    needManagementSystem() {
      return this.position.value && this.positionsWithManagementSystem.includes(this.position.value.key);
    },
    needOtherManagementSystem() {
      return this.needManagementSystem && this.managementSystem.value && this.managementSystem.value.key === 'otro';
    },
  },
  mounted() {
    this.preferredSchool = { value: this.chosenSchool, regex: null };
    if (this.googleAuthInfo) {
      this.name = { value: this.googleAuthInfo.name.replace(/\++/g, ' '), pattern: /^(?!\s*$).+/ };
      this.email = { value: this.googleAuthInfo.email, pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ };
    }
  },
  methods: {
    cleanError() {
      this.$store.commit('signUp/CLEAN_ERROR');
    },
    checkError(key) {
      const pattern = this.$data[key].pattern;
      const value = this.$data[key].value;
      const patternValid = pattern === null || RegExp(pattern).test(value);
      return (['', 0, null].includes(value) || !patternValid) && this.show && this.submitted;
    },
    validSignUp() {
      const keys = ['name', 'phone', 'email', 'preferredSchool', 'position', 'password', 'passwordConfirmation'];
      if (keys.some(this.checkError)) return false;
      if (this.needManagementSystem && this.checkError('managementSystem')) return false;
      if (this.needOtherManagementSystem && this.checkError('otherManagementSystem')) return false;
      return true;
    },
    signUp() {
      this.submitting = true;
      this.submitted = true;
      if (!this.validSignUp()) {
        this.submitting = false;
        return;
      }
      this.$store.dispatch('signUp/register', {
        name: this.name.value,
        phone: this.phone.value,
        email: this.email.value,
        preferred_school: this.preferredSchool.value, // eslint-disable-line camelcase
        position: this.position.value.key,
        management_system: this.chosenManagementSystem, // eslint-disable-line camelcase
        school_network: this.schoolNetwork.value, // eslint-disable-line camelcase
        password: this.password.value,
        password_confirmation: this.passwordConfirmation.value, // eslint-disable-line camelcase
      }).then(() => {
        this.success = true;
        this.$ga.event({
          eventCategory: 'FreeUserAccountDialog',
          eventAction: 'UserCreated',
          eventLabel: 'FreeUserAccount',
        });
      }).catch(() => {
        this.submitting = false;
      });
    },
    toggleForm() {
      this.signUpForm = !this.signUpForm;
    },
  },
};
</script>

<style>
  .conten-register {
    height: 100%;
    overflow: auto;
  }
  .el-dialog__body {
    height: calc(100% - 90px) !important;
    padding-top: 0px !important;
    padding-bottom: 20px !important;
  }
  .el-dialog__header {
    border-bottom: 1px solid #ddd !important;
    padding-bottom: 15px !important;
  }
</style>