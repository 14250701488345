<template>
  <div :class="$style.home">
    <!-- Dialogs -->
    <login-dialog />
    <!-- Dots -->
    <div :class="$style.home">
      <div :class="[ $style.dot, $style.dotYellow]" />
      <div :class="[ $style.dot, $style.dotGray]" />
      <div :class="[ $style.dot, $style.dotOlive]" />
      <div :class="[ $style.dot, $style.dotRed]" />
      <div :class="[ $style.dot, $style.dotGreen]" />

      <div
        :class="$style.schoolForm">
        <img
          :class="$style.featuresLogoRadar"
          src="/logo-radar-horizontal.svg" >
        <p :class="$style.featuresContent">
          En Alianza con
        </p>
        <img
          :class="$style.featuresLogoAptus"
          src="/logo-aptus-horizontal.png" >
        <div :class="$style.buttonsContainer">
          <button
            :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.loginBtn]"
            @click="openLoginModal">Iniciar sesión
          </button>
          <button
            :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.loginBtn]"
            @click="openshowVideoDialog"
          >
            ¿Qué es Radar Escolar?
          </button>
        </div>
      </div>
      <aptus-home-more-info-dialog
        :visible.sync="showVideoDialog"
        :video-id="videoId"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SchoolSelect from '../components/school-select.vue';
import AuthMixin from '../mixins/auth';
import LandingPopover from '../layouts/app/landing-popover.vue';
import LinksMixin from '../mixins/links';
import LoginDialog from '../components/dialogs/login.vue';
import AptusHomeMoreInfoDialog from '../components/dialogs/aptus-home-more-info.vue';

export default {
  name: 'AptusHomeView',
  components: {
    LandingPopover,
    LoginDialog,
    SchoolSelect,
    AptusHomeMoreInfoDialog,
  },
  mixins: [AuthMixin, LinksMixin],
  props: {
    openLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chosenSchool: null,
      showVideoDialog: false,
    };
  },
  computed: {
    ...mapState('ui', ['loading']),
  },
  created() {
    this.fetchGroups();
    if (this.isLoggedIn) {
      this.$router.push(this.landingLink);
    } else if (this.openLogin) {
      this.loginPopUp();
    } else if (this.openRegister) {
      this.signUp();
    }
    this.videoId = this.radarYoutubeVideoId;
  },
  methods: {
    openLoginModal() {
      this.loginPopUp();
    },
    ...mapMutations('ui', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    openshowVideoDialog() {
      this.showVideoDialog = true;
    },
    ...mapActions('options', ['setChosenSchool', 'fetchGroups']),
  },
};
</script>

<style lang="scss" module>
  @import "../../styles/app/variables";
  @import "../../styles/app/forms";

  .home {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px;
    position: relative;
    text-align: center;
    background-color: #f7f6fb;

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-direction: column-reverse;
      overflow-y: auto;
      flex-basis: auto;
    }

    &__features-display {
      background: linear-gradient(#5977ff, #6f64d9);
      color: #fff;
    }

    .buttons-container {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
    }
  }

  .features {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    &__logo {
      max-width: 450px;

      &--radar{
        width: 50%;
        min-width: 300px;
      }

      &--aptus{
        width: 12%;
        min-width: 150px;
      }
    }

    &__content {
      margin-top: 70px;
      font-size: 19px;
      margin-left: 140px;
      margin-right: 140px;
    }

    &__footer {
      position: absolute;
      bottom: 7%;
      text-align: center;
      font-size: 16px;
      color: #fff;

      @media only screen and (max-width: $mobile-breakpoint) {

      }
    }
  }

  .login-btn{
    margin-top: 3%;
    min-width: 20%;
    font-size: 16px;

  }

  .dot {
    position: absolute;
    border-radius: 50%;
    display: inline-block;
    background-color: #000;
    z-index: 1;
    &-yellow {
    height: 6vw;
    width: 6vw;
    min-height: 20px;
    min-width: 20px;
    left: 7%;
    top: 7%;
    background-color: #ffd370;
    }
    &--gray {
      height: 1.5vw;
      width: 1.5vw;
      min-height: 5px;
      min-width: 5px;
      left: 30%;
      top: 10%;
      background-color: #a0a5b9;
    }
    &--red {
      height: 2vw;
      width: 2vw;
      min-height: 6px;
      min-width: 6px;
      right: 25%;
      top: 77%;
      background-color: #ff7070;
    }
    &--green {
      height: 3vw;
      width: 3vw;
      min-height: 10px;
      min-width: 10px;
      left: 8%;
      top: 88%;
      background-color: #24d5bd;
    }

    &--olive {
      height: 4vw;
      width: 4vw;
      min-height: 11px;
      min-width: 11px;
      right: 6%;
      top: 40%;
      background-color: #dbf2d0;
    }
  }

  .school-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 65px 0 15px 0;
    position: absolute;
    top: 5%;
    * {
      z-index: 2;
    }
  }
</style>
