import SchoolsApi from '../../api/schools';
import ResultsApi from '../../api/results';
import updateRouterParam from '../../utils/update-router-param';
import schoolYear from '../../utils/years';

function updateOptionRouterParams(state) {
  updateRouterParam('school', state.chosenSchoolId);
  updateRouterParam('schools', state.chosenSchoolIds.join(','));
}

function saveOptions(_, commit, options) {
  commit('SET_YEARS', options.years);
  commit('SET_MONTHS', options.months);
  commit('results/SET_RESULT_GROUPS', options.groups, { root: true });
  commit('results/SET_REPORT_GROUPS', options.report_groups, { root: true });
  commit('SET_INDICATORS', options.indicators);
}

function saveOptionsAverage(_, commit, options) {
  commit('SET_MONTHS', options.months);
  commit('results/SET_RESULT_GROUPS', options.groups, { root: true });
  commit('results/SET_REPORT_GROUPS', options.report_groups, { root: true });
  commit('SET_INDICATORS', options.indicators);
}

function saveIndicators(_, commit, options) {
  commit('SET_INDICATORS', options.indicators);
}

async function handleSchoolsUpdate(commit, dispatch, schools, updateCallback) {
  const schoolIds = schools.map(s => (typeof (s) === 'object' ? s.id : s));
  const schoolObjects = schools.filter(s => typeof (s) === 'object');
  updateCallback(schoolIds);
  commit('ADD_SCHOOLS', schoolObjects);
  await dispatch('fetchMissingSchools');
}

function initialState() {
  return {
    chosenSchoolId: null,
    chosenSchoolIds: [],
    chosenGroupKey: null,
    chosenEmptyGroupKey: false,
    chosenIndicatorKey: null,
    chosenReportKey: null,
    chosenYear: schoolYear(),
    chosenMonth: (new Date()).getMonth(),
    schools: {},
    groups: [],
    years: [],
    months: [],
    indicators: [],
    fetchReady: false,
    fetchOptionsReady: false,
    enrollmentDisplay: null,
    reportParams: { 'chosen_school_ids': false, 'school_ids': false,
      'school_ids_auth': false, 'group_ids': false, 'indicator_ids': false,
      'years': false, 'consecutive_years': false, 'months': false, 'school_network_ids': false,
    },
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    groups(state, _, rootState) {
      if (state.groups.length === 0) return [];
      const resultReportGroups = (rootState.results.results ? rootState.results.results.report_groups : []);
      const reportGroups = rootState.results.reportGroups.length ? rootState.results.reportGroups : resultReportGroups;
      return reportGroups.concat().sort((a, b) => a.index - b.index).map(reportGroup => {
        const group = state.groups.find(item => item.id === reportGroup.group_id);
        const resultGroup = rootState.results.resultGroups.find(item => item.id === group.id);
        const privateAndVisible = (!reportGroup.restricted_to_logged_users || reportGroup.show_label_without_session);
        const visibleWithoutResults = (rootState.auth.isLoggedIn || privateAndVisible);
        if (group && (resultGroup || visibleWithoutResults)) {
          return group;
        }

        return false;
      })
        .filter(group => !!group);
    },
    chosenSchool(state) {
      if (!state.chosenSchoolId) return null;
      return state.schools[state.chosenSchoolId];
    },
    chosenSchools(state) {
      return state.chosenSchoolIds.map(id => state.schools[id]).filter(s => !!s);
    },
    schoolChoicesRemaining(state, __, ___, rootGetters) {
      return rootGetters['auth/chosenSchoolLimit'] - state.chosenSchoolIds.length;
    },
    chosenIndicator(state) {
      return state.indicators ? state.indicators.find(i => i.key === state.chosenIndicatorKey) : null;
    },
    chosenGroup(state) {
      return state.groups ? state.groups.find(g => g.key === state.chosenGroupKey) : null;
    },
    chosenEmptyGroup(state) {
      return state.chosenEmptyGroupKey;
    },
    chosenYear(state) {
      return state.chosenYear;
    },
  },
  mutations: {
    chosenSchool(state) {
      if (!state.chosenSchoolId) return null;
      return state.schools[state.chosenSchoolId];
    },
    SET_CHOSEN_SCHOOL(state, schoolId) {
      state.chosenSchoolId = schoolId && schoolId.toString();
      updateOptionRouterParams(state);
    },
    SET_CHOSEN_SCHOOLS(state, schoolIds) {
      state.chosenSchoolIds = schoolIds.map(s => s.toString());
      updateOptionRouterParams(state);
    },
    ADD_CHOSEN_SCHOOLS(state, schoolIds) {
      const schoolArray = Array.isArray(schoolIds) ? schoolIds : [schoolIds];
      schoolArray.forEach(school => {
        if (!state.chosenSchoolIds.includes(school)) state.chosenSchoolIds.push(school.toString());
      });
      updateOptionRouterParams(state);
    },
    REMOVE_CHOSEN_SCHOOLS(state, schoolIds) {
      const schoolArray = Array.isArray(schoolIds) ? schoolIds : [schoolIds];
      const removeIndices = schoolArray.map(s => state.chosenSchoolIds.indexOf(s));
      removeIndices.forEach(i => {
        if (i !== -1) state.chosenSchoolIds.splice(i, 1);
      });
      updateOptionRouterParams(state);
    },
    ADD_SCHOOLS(state, schools) {
      state.schools = {
        ...state.schools,
        ...schools.reduce((obj, s) => ({ ...obj, [s.id]: s }), {}),
      };
    },
    SET_CHOSEN_GROUP(state, groupKey) {
      state.chosenGroupKey = groupKey;
    },
    SET_CHOSEN_EMPTY_GROUP(state, groupEmptyKey) {
      state.chosenEmptyGroupKey = groupEmptyKey;
    },
    SET_GROUPS(state, groups) {
      state.groups.splice(0, state.groups.length); // eslint-disable-line no-magic-numbers
      groups.forEach(group => state.groups.push(group));
    },
    SET_CHOSEN_INDICATOR(state, chosenIndicatorKey) {
      state.chosenIndicatorKey = chosenIndicatorKey;
    },
    SET_INDICATORS(state, indicators) {
      state.indicators.splice(0, state.indicators.length); // eslint-disable-line no-magic-numbers
      indicators.forEach(indicator => state.indicators.push(indicator));
    },
    SET_CHOSEN_YEAR(state, year) {
      state.chosenYear = year;
    },
    SET_CHOSEN_MONTH(state, month) {
      state.chosenMonth = month;
    },
    SET_CHOSEN_REPORT_KEY(state, chosenReportKey) {
      state.chosenReportKey = chosenReportKey;
    },
    SET_YEARS(state, years) {
      state.years.splice(0, state.years.length); // eslint-disable-line no-magic-numbers
      years.forEach(year => state.years.push(year));
    },
    SET_MONTHS(state, months) {
      state.months.splice(0, state.months.length);
      months.forEach(month => state.months.push(month));
    },
    SET_FETCH_READY(state, value) {
      state.fetchReady = value;
    },
    SET_FETCH_OPTIONS_READY(state, value) {
      state.fetchOptionsReady = value;
    },
    SET_ENROLLMENT_DISPLAY(state, value) {
      state.enrollmentDisplay = value;
    },
    RESET_STATE(state) {
      const groups = state.groups;
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
      state.groups = groups;
    },
    SET_REPORT_PARAMS(state, value) {
      Object.keys(state.reportParams).forEach(key => {
        state.reportParams[key] = false;
      });
      Object.keys(value).forEach(key => {
        if (value[key]) {
          state.reportParams[key] = true;
        }
      });
    },
  },
  actions: {
    async setChosenSchool({ commit, dispatch }, schoolId) {
      handleSchoolsUpdate(commit, dispatch, [schoolId], schoolIds => {
        commit('SET_CHOSEN_SCHOOL', schoolIds[0]);
      });
    },
    async setChosenSchools({ rootGetters, commit, dispatch }, schools) {
      handleSchoolsUpdate(commit, dispatch, schools, schoolIds => {
        commit('SET_CHOSEN_SCHOOLS', schoolIds.slice(0, rootGetters['auth/chosenSchoolLimit']));
      });
    },
    async addChosenSchools({ getters, commit, dispatch }, schools) {
      handleSchoolsUpdate(commit, dispatch, schools, schoolIds => {
        commit('ADD_CHOSEN_SCHOOLS', schoolIds.slice(0, getters.schoolChoicesRemaining));
      });
    },
    setChosenIndicatorKey({ commit }, chosenIndicatorKey) {
      commit('SET_CHOSEN_INDICATOR', chosenIndicatorKey);
    },
    setReportParams({ commit }, dictParams) {
      commit('SET_REPORT_PARAMS', dictParams);
    },
    setChosenGroup({ commit }, groupKey) {
      commit('SET_CHOSEN_GROUP', groupKey);
      updateRouterParam('group', groupKey);
      return Promise.resolve();
    },
    setChosenEmptyGroup({ commit }, groupEmptyKey) {
      commit('SET_CHOSEN_EMPTY_GROUP', groupEmptyKey);
    },
    setChosenYear({ state, commit }, year) {
      commit('SET_CHOSEN_YEAR', year);
      updateRouterParam('year', state.chosenYear);
    },
    setChosenMonth({ state, commit }, month) {
      commit('SET_CHOSEN_MONTH', month);
      updateRouterParam('month', state.chosenMonth);
    },
    setEnrollmentDisplay({ state, commit }, display) {
      commit('SET_ENROLLMENT_DISPLAY', display);
      updateRouterParam('enrollmentDisplay', state.enrollmentDisplay);
    },
    fetchGroups({ state, commit }) {
      if (state.groups.length > 0) return Promise.resolve();
      commit('results/ADD_LOADING', null, { root: true });

      return ResultsApi.getGroups().then(({ body }) => {
        commit('SET_GROUPS', body.groups);
      })
        .finally(() => commit('results/REMOVE_LOADING', null, { root: true }));
    },

    async fetchOptions({ state, rootState, commit }, { chosenSchoolOnly } = {}) {
      commit('results/ADD_LOADING', null, { root: true });
      let schoolIds = chosenSchoolOnly ? state.chosenSchoolId : state.chosenSchoolIds.join(',');
      if (state.chosenReportKey === "grades-group-average" ) {
        schoolIds = state.chosenSchoolId;
      }
      const chosenGroup = state.groups ? state.groups.find(i => i.key === state.chosenGroupKey) : null;
      const params = {
        school_ids: schoolIds, // eslint-disable-line camelcase
        report_key: state.chosenReportKey, // eslint-disable-line camelcase
        options_only: 'true', // eslint-disable-line camelcase
      };


      try {
        let { body: { results_with_stats } } = await ResultsApi.getResults(params); // eslint-disable-line camelcase
        saveOptions(rootState, commit, results_with_stats);

        // Petition is send it again to get the only indicators which are whished.
        if (state.chosenReportKey === 'subject-test-grades-by-student' && chosenGroup) {
          params.group_ids = chosenGroup.id;
          let { body: { results_with_stats } } = await ResultsApi.getResults(params); // eslint-disable-line camelcase
          saveIndicators(rootState, commit, results_with_stats);
        }
        if (state.chosenReportKey === 'subject-average-grades-by-student') {
          params.years = state.chosenYear;
          let { body: { results_with_stats } } = await ResultsApi.getResults(params);
          saveOptionsAverage(rootState, commit, results_with_stats);
        }
      } finally {
        commit('results/REMOVE_LOADING', null, { root: true });
      }
    },
    async autofillRemainingSchoolChoices({ state, commit, dispatch }) {
      try {
        const selected = state.chosenSchoolId || state.chosenSchoolIds[0];
        const related = (await SchoolsApi.getRelatedSchools(selected)).body.schools;
        commit('ADD_SCHOOLS', related);
        dispatch('setChosenSchools', [selected, ...related.map(s => s.id)]);
      } catch (_) {
        return;
      }
    },
    async fetchMissingSchools({ state, dispatch }) {
      const savedSchoolIds = Object.keys(state.schools);
      const missingSchoolIds = state.chosenSchoolIds.filter(s => !savedSchoolIds.includes(s));
      if (!savedSchoolIds.concat(missingSchoolIds).includes(state.chosenSchoolId)) {
        missingSchoolIds.push(state.chosenSchoolId);
      }
      if (missingSchoolIds.length > 0) await dispatch('fetchSchools', missingSchoolIds);
    },
    async fetchSchools({ commit }, schoolIds) {
      const schoolIdString = Array.isArray(schoolIds) ? schoolIds.join(',') : schoolIds;
      const { body: { schools } } = await SchoolsApi.findSchools(schoolIdString);
      commit('ADD_SCHOOLS', schools);
    },
  },
};
