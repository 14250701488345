<template>
  <div class="rdr-view">
    <rdr-results-header
      :tabs="tabs"
      :year-picker="isSipDashboardComparative"
      :show-school-selector="isSipDashboardBySchool"
      hide-school-picker
      mobile-only-picker>
      <div v-if="isSipDashboardComparative" class="results-header__subtitle"><br>Este mapa de color muestra los distintos KPI en cada colegio SIP para el año seleccionado.</div>
      <div v-if="isSipDashboardBySchool" class="results-header__subtitle"><br>Este mapa de color muestra los distintos KPI para el colegio y año seleccionados.</div>
    </rdr-results-header>
    <router-view></router-view>
  </div>
</template>

<script>
import ResultsHeader from '../../components/results/results-header.vue';

import LinkMixin from '../../mixins/links';

export default {
  name: 'SipDashboard',
  mixins: [LinkMixin],
  components: {
    'rdr-results-header': ResultsHeader,
  },
  computed: {
    isSipDashboardBySchoolNetwork () {
      return this.$route.name === 'sipDashboardBySchoolNetwork';
    },
    isSipDashboardComparative () {
      return this.$route.name === 'sipDashboardComparative';
    },
    isSipDashboardBySchool () {
      return this.$route.name === 'sipDashboardBySchool';
    },
    tabs() {
      return [
        { icon: 'group_work', name: 'SIP', link: this.sipDashboardBySchoolNetworkLink },
        { icon: 'compare_arrows', name: 'Comparativo', link: this.sipDashboardComparativeLink },
        { icon: 'search', name: 'Por Colegio', link: this.sipDashboardBySchoolLink },
      ];
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

</style>
