<template>
  <div>
    <div :class="$style.paywall">
      <div
        v-if="!permitted"
        :class="[
          $style.cover,
          { [$style.transparent]: transparent }
      ]">
        <div
          :class="[
            $style.message,
            { [$style.transparent]: transparent }
        ]">
          <div
            v-if="exitButton"
            :class="$style.closeDialog"
            @click="closeDialog"/>
          <div v-if="!isLoggedIn && free">
            <h1>{{ freeHeader }}</h1>
            <h2>{{ freeText }}</h2>
            <div
              class="rdr-btn rdr-btn--large rdr-btn--primary"
              @click="signUp">Registrarse</div>
            <div
              :class="$style.login"
              @click="loginBack">Ya tengo una cuenta</div>
          </div>
          <div v-else-if="!isLoggedIn || restricted">
            <h1>{{ quoteHeader }}</h1>
            <h2>{{ quoteText }}</h2>
            <a
              @click="openContact"
              class="rdr-btn rdr-btn--large rdr-btn--important">
              Cotiza otro plan de Radar Escolar
            </a>
            <div
              v-if="!isLoggedIn"
              :class="$style.login"
              @click="loginBack">
              <div v-if="textLogin">
                Cargando inicio de sesión ...
              </div>
              <div v-else>
                Ya tengo una cuenta
              </div>
            </div>
          </div>
          <div v-else>
            <h1>{{ header }}</h1>
            <h2>{{ text }}</h2>
            <a
              v-if="link"
              :href="link.url"
              class="rdr-btn rdr-btn--large rdr-btn--important">
              {{ link.message }}
            </a>
          </div>
        </div>
      </div>
      <slot v-else/>
      <slot
        v-if="!permitted && transparent"
        name="mock"/>
    </div>

    <!-- ===== MODAL COTIZAR ===== -->
      <el-dialog
        v-if="mdlQuote"
        :visible.sync="mdlQuote"
        :class="[$style.radar-dialog, $style.login-dialog]">
        <div
          slot="title">
          <h2 :class="$style.titleh2">Cotizar servicio</h2>
        </div>
        <div :class="$style.mdlContenBody">
          <h3>
            Escríbenos a <a href="mailto:radar@colegium.com">radar@colegium.com</a>
          </h3>
          <p :class="$style.contenText">
                Pide tu cotización escribiendo a nuestro correo indicando el nombre y RBD
                del(los) colegio(s) para los que quieres cotizar y los servicios en los que estás interesado(a).
          </p>

          <hr :class="$style.dividerLogin">
          <div :class="$style.contenO">ó</div>
          <hr :class="$style.dividerLogin">
          <rdr-contact-notification />
          <contact-form-dialog />

        </div>
      </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AuthMixin from '../mixins/auth';

import { Dialog } from 'element-ui';
import ContactNotification from '../components/partials/contact-notification.vue';
import ContactFormDialog from '../components/dialogs/contact-form.vue';

export default {
  name: 'Paywall',
  mixins: [AuthMixin],
  components: {
    'rdr-contact-notification': ContactNotification,
    'el-dialog': Dialog,
    ContactFormDialog,
  },
  data() {
    return {
      textLogin: false,
      mdlQuote: false,
      oneClick: true,
    };
  },
  props: {
    permitted: {
      type: Boolean,
      default: false,
    },
    restricted: {
      type: Boolean,
      default: false,
    },
    free: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    exitButton: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: 'No tienes permiso para ver esto',
    },
    text: {
      type: String,
      default: 'Tu plan actual no incluye este tipo de reporte.',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    freeHeader: {
      type: String,
      default: 'Crear cuenta gratis',
    },
    freeText: {
      type: String,
      default: 'Sin tener una cuenta, solo puedes ver resultados de IV° Medio y 4° Básico. Para ver resultados de otros cursos, debes registrarte de manera gratuita.',
    },
    quoteHeader: {
      type: String,
      default: '¿Quieres información clave para tomar decisiones?',
    },
    quoteText: {
      type: String,
      default: 'Tu plan actual no permite acceder a esta sección de Radar Escolar. Con todas las funcionalidades puedes obtener diagnósticos detallados a nivel de curso, estudiante y mucho más.',
    },
  },
  methods: {
    ...mapActions({ closeDialog: 'signUp/closeSignUpDialog' }),

    loginBack() {
      this.textLogin = true;
      if(this.oneClick) {
        this.oneClick = false;
        location.href = "./";
      }
    },
    openContact() {
      this.mdlQuote = !this.mdlQuote;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.paywall {
  // position: relative;

  h1 {
    font-family: 'GT Haptik', sans-serif;
    font-weight: normal;
    margin-top: 0;
    color: $primary-color;
  }

  h2 {
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.login {
  margin-top: 20px;
  color: $primary-color;
  cursor: pointer;
}

.close-dialog {
  color: #909399;
  font: 24px/100% arial, sans-serif;
  position: relative;
  right: -250px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: -25px;
  cursor: pointer;

  &:after {
    content: 'x';
  }

  &:hover {
    color: #252525;
  }
}

.cover {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;

  &.transparent {
    position: absolute;
  }
}

.message {
  max-width: 580px;
  background-color: whitesmoke;
  text-align: center;
  padding: 50px;
  border-radius: 5px;
  box-shadow: none;

  &.transparent {
    box-shadow: 0px 3px 10px rgba(0,0,0,0.1);
    background-color: white;
  }
}

.divider-login {
    width: 40%;
    border-top: 1px solid #ccc;
    display: inline-block;
  }
  .conten-o {
    display: inline-block;
    text-align: center;
    width: 30px;
  }
  .titleh2 {
    text-align: left !important;
    margin-bottom: 0px !important;
  }
  .mdl-conten-body {
    height: calc(100% - 5px);
    overflow: auto;
    padding-right: 10px;
    width: 104%;
    text-align: center;
  }
  .conten-text {
    text-align: justify;
    white-space: normal;
  }
</style>
