<template>
  <div>
    <div v-if="isLoggedIn && hasSipDashboardPermission">
      <div id="downTable" class="results-colormap">
        <div class="results-colormap__color-map">
          <color-map
            :loading="loading"
            :rows="indicators"
            :columns="schoolsAsColumns"
            :results="results"
            :hide-empty-columns="true"
            :show-school-picker="false"
            :disable-school-adding="true"
            :grades="true"
            :sip-dashboard-view="true"
          />
        </div>
        <template v-if="results.length !== 0">
          <color-map-footer
            text="La gama de colores representa el valor de los indicadores de la siguiente forma:"
            high="sobre la referencia"
            average="similar a la referencia"
            low="bajo la referencia"
            :footer-bottom-paragraphs="footerParagraphs">
          </color-map-footer>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import TooltipInfoMixin from '../../../mixins/tooltip-info';

export default {
  name: 'SipDashboardComparativeView',
  mixins: [AuthMixin, PermissionMixin, ResultsMixin, TooltipInfoMixin],
  components: {
    ColorMapFooter,
  },
  data() {
    return {
      footerParagraphs: [],
    }
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('results', ['resultsSchools']),
    ...mapGetters('results', {
      indicators: 'resultsIndicators',
      resultsGetter: 'results',
      evaluations: 'resultsEvaluations',
    }),
    mySchoolIds() { return this.resultsSchools.map(school => school['id']); },
    results() {
      return this.resultsGetter.map(result => {
        const school = this.resultsSchools.find(s => s.id === result.school_id);

        if (!school) return [];
        return { ...result, column_id: school.id };
      });
    },
    orderedSchools() {
      const getSchoolAverageScore = (school) => {
        if (this.results) {
          const sum = this.results.filter(result => result.school_id === school.id).reduce((partial, result) => {
            return partial + result.norm_value;
          }, 0);

          return sum / this.indicators.length;
        }
        return 0;
      };

      return this.resultsSchools.slice().sort((a, b) => {
        const averageA = getSchoolAverageScore(a);
        const averageB = getSchoolAverageScore(b);

        return averageA - averageB;
      });
    },
    schoolsAsColumns() {
      if (this.orderedSchools) {
        return this.orderedSchools.map(school => (
          {
            ...this.schoolData(school),
            active: this.isLoggedIn ? this.mySchoolIds.indexOf(school.id) !== -1 : school.id === this.resultsSchools[0].id,
          }
        ));
      }
      return [];
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    ...mapActions('ui', 'openLoginDialog'),
    login() {
      this.openLoginDialog(true);
    },
    setViewParams() {
      this.setReportParams({ 'school_ids_auth': true, 'years': true });
      this.setReportKey('sip-kpi-comparative');
    },
  },
};
</script>
