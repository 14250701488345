function itemHeight(item, openItem, userPermissions) {
  const permissionSet = new Set(Object.keys(userPermissions));
  let ret = 50;
  if (openItem === item.key && item.children && item.children.length) {
    const visibleChildrenCount = item.children.filter(i => (
      !i.permissionsRequired ||
      i.permissionsRequired.every(p => (
        permissionSet.has(p)
      )))).length;
    ret += 40 * visibleChildrenCount; // eslint-disable-line no-magic-numbers
  }

  return ret;
}

export { itemHeight }; // eslint-disable-line import/prefer-default-export
