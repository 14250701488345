/* eslint-disable camelcase */
import Vue from 'vue';

function fetchOptions({ reportKey, schoolIds }) {
  return Vue.http.get(
    `/api/v1/reports/${reportKey}/options`,
    { params: { school_ids: schoolIds.join(',') } }
  );
}

export { fetchOptions }; // eslint-disable-line import/prefer-default-export
