/* eslint-disable no-magic-numbers */
const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const TERM_NAMES = Object.fromEntries(
  [
    [[1, 2], 'Semestre'],
    [[1, 2, 3], 'Trimestre'],
    [[1, 2, 3, 4], 'Bimestre'],
    [[1, 2, 3, 4, 5, 6], 'Bimestre'],
  ].map(([numbers, term]) => (
    numbers.map(number => [numbers.length * 10 + number, `${number}º ${term}`])
  )).flat(1)
);

function offsetMonth(month) {
  return month.toString().length === 1 ? `0${month}` : month;
}

function academicYearMonths(maxMonth) {
  const indexGap = 2;
  const monthLength = maxMonth - indexGap;

  return Array.from({ length: monthLength }, (v, k) => MONTHS[k + indexGap]);
}

function schoolMonth() {
  const currentMonth = new Date().getMonth();
  const monthOffset = 2;
  return currentMonth < monthOffset ? MONTHS.length - monthOffset : currentMonth;
}

export { MONTHS, TERM_NAMES, offsetMonth, academicYearMonths, schoolMonth };
