<template>
  <div :class="$style.reportOptions">
    <div :class="$style.row">
      <school-logo v-if="showSchoolLogo" />
      <div>
        <div
          v-if="showChosenSchoolPicker"
          :class="$style.picker">
          <label class="labelInput">Colegio:</label><br>
          <chosen-school-picker
            :loading="loading"
            :show-logo="true" />
        </div>
        <div
          v-else
          :class="$style.picker">
          <label class="labelInput">Colegio:</label><br>
          <div :class="[$style.resultsHeaderSchool, 'contenInp']">
            <span v-if="isLoggedIn && userNetworkName">{{ userNetworkName }}</span>
            <span v-else-if="isLoggedIn && userSchoolIds.length > 0">Visión general colegios</span>
            <span v-else-if="chosenSchool">{{ chosenSchool.name | startCase }} COLEGIO </span>
            <span v-else>Cargando...</span>
          </div>
        </div>
        <div
          v-if="selectors.includes('year')"
          :class="$style.picker">
          <label class="labelInput">Año:</label><br>
          <year-picker
            :custom-year-options="yearOptions"
            :loading="loading"
            :icon-calendar="true"/>
        </div>
        <div
          v-if="selectors.includes('month')"
          :class="$style.picker">
          <label v-if="showLabels" class="labelInput">Mes:</label><br>
          <month-picker
            :custom-month-options="monthOptions"
            :loading="loading"
            :icon-calendar="true"/>
        </div>
        <div
          v-if="selectors.includes('group_key')"
          :class="$style.picker">
          <label v-if="showLabels" class="labelInput">Curso:</label><br>
          <group-picker
            :custom-group-options="groupOptions"
            :loading="loading" />
        </div>
        <div :class="$style.slot">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ChosenSchoolPicker from './chosen-school-picker';
import YearPicker from './year-picker';
import MonthPicker from './month-picker';
import GroupPicker from './group-picker';
import SchoolLogo from './school-logo';
import PermissionMixin from '../mixins/permission';
import { fetchOptions } from '../api/reports';
import schoolYear from '../utils/years';

const schoolNetworkReports = [
  'administrator_report_school_results',
];

export default {
  components: { ChosenSchoolPicker, GroupPicker, SchoolLogo, YearPicker, MonthPicker },
  mixins: [PermissionMixin],
  props: {
    reportKey: {
      type: String,
      default: '',
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showChosenSchoolPicker: {
      type: Boolean,
      default: true,
    },
    hideSchoolLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      optionTuples: [],
    };
  },
  computed: {
    ...mapState(
      'options',
      ['chosenSchoolId', 'chosenYear', 'chosenMonth', 'chosenGroupKey', 'groups']
    ),
    ...mapState('auth', ['isLoggedIn']),
    ...mapGetters('options', ['chosenSchool']),
    ...mapGetters('auth', ['userNetworkName', 'userSchoolIds']),
    selectors() {
      return Object.keys(
        this.optionTuples.reduce(
          (accumulatedDimensions, tuple) => ({
            ...accumulatedDimensions,
            ...Object.keys(tuple).reduce(
              (tupleDimensions, dimension) => ({ ...tupleDimensions, ...{ [dimension]: 1 } }),
              {}
            ) }),
          {})
      );
    },
    yearOptions() {
      const optionTuples = this.isSchoolNetworkReport ?
        this.optionTuples : this.optionTuples.filter(t => t.school_id === +this.chosenSchoolId);
      return [...new Set([...optionTuples.map(t => +t.year), schoolYear()])];
    },
    monthOptions() {
      return [...new Set(
        [...this.optionTuples.filter(t => t.year === this.chosenYear).map(t => t.month)]
      )];
    },
    groupOptions() {
      const validGroupKeys = new Set(
        this.optionTuples.filter(t => t.year === this.chosenYear).map(t => t.group_key)
      );
      return this.groups.filter(g => validGroupKeys.has(g.key));
    },
    isSchoolNetworkReport() {
      return schoolNetworkReports.includes(this.reportKey);
    },
    showSchoolLogo() {
      return !this.freeOrGuestUser && !this.hideSchoolLogo;
    },
  },
  watch: {
    reportKey: {
      handler() {
        this.populateOptions();
      },
      immediate: true,
    },
    chosenSchoolId() {
      if (!this.isSchoolNetworkReport) this.populateOptions();
    },
    userSchoolIds() {
      if (this.isSchoolNetworkReport) this.populateOptions();
    },
    optionTuples(to) {
      if (to.length === 0) {
        this.$emit('update:noOptions', true);
      } else {
        this.$emit('update:noOptions', false);
      }
    },
  },
  methods: {
    async populateOptions() {
      this.loading = true;
      try {
        this.optionTuples = (
          await fetchOptions({
            reportKey: this.reportKey,
            schoolIds: this.isSchoolNetworkReport ? this.userSchoolIds : [this.chosenSchoolId],
          })
        ).body.options;
      } catch (_) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.report-options {
  // text-align: center;
  font-size: 28px;
  font-weight: 200;
  margin-bottom: 30px;
  width: 100%;

  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 45px;
  }

  .row {
    display: flex;
    // justify-content: center;
    flex-direction: row;
    align-items: flex-start;

    &-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: auto;
      flex-wrap: wrap;
      font-size: 19px;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  }

   .division {
    &::before {
      content: "\2006";
    }

    &::after {
      content: "\2006";
    }
  }

  .label {
    font-size: 15px;
    padding-left: 15px;
    &::after {
      content: "\2006";
    }
  }

  .picker {
    // display: flex;
    // align-items: baseline;
    // width: 193px;
    // height: 40px;

    display: inline-block;
    font-size: 15px;
    padding-left: 15px;
  }

  .slot {
    flex-basis: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    font-size: 18px;
  }
  .add-input {
    width:300px;
    display: inline-block;
  }
}
</style>
