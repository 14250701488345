const FORBIDDEN_SCHOOL_TERMS = [
  'CENTRO EDUCACIONAL',
  'CENTRO EDUCATIVO',
  'CENTRO EDUC.',
  'COLEGIO PARROQUIAL',
  'COLEGIO PARROQ',
  'COLEGIO PARTICULAR',
  'COLEGIO POLIV.',
  'COLEGIO',
  'COL.',
  'COMPLEJO EDUC.MUN.',
  'COMPLEJO EDUCACIONAL',
  'COMPLEJO EDUCATIVO',
  'ESC BAS',
  'ESC BASICA PART',
  'ESC. BAS. PART.',
  'ESC. BAS.',
  'ESC. BASICA',
  'ESC. BASICA RURAL',
  'ESC.BAS.',
  'ESC.BAS.BILINGUE',
  'ESC.BASICA',
  'ESC ',
  'ESC.',
  'ESC.EDUC.GRAL.BAS.',
  'ESCUELA BASICA',
  'ESCUELA BÁSICA',
  'BASICA',
  'ESCUELA MUNICIPAL',
  'ESCUELA PARTICULAR',
  'ESCUELA PART ',
  'ESCUELA',
  'FUNDACION EDUCACIONAL',
  'FUNDACION EDUC.',
  'INSTITUTO DE ENS.MEDIA',
  'INSTITUTO',
  'INS ',
  'INS.',
  'INST.',
  'INTERNADO',
  'LICEO DE NIÑAS',
  'LICEO POLITEC.',
  'LICEO POLIV.',
  'LICEO INDUSTRIAL DE',
  'LICEO INDUSTRIAL',
  'LICEO',
  'PARTICULAR',
  'PART ',
  'POLIV ',
  'POLIVALENTE',
  'PARROQUIAL',
];

export default function (value) {
  let filtered = value;

  FORBIDDEN_SCHOOL_TERMS.forEach(term => {
    const termIndex = filtered.toUpperCase().indexOf(term);
    if (termIndex > -1) {
      filtered = filtered.substring(0, termIndex) + filtered.substring(termIndex + term.length, filtered.length);
    }
  });

  return filtered;
}
