/* this is all mock stuff so no linters pleaseeeee */
/* eslint-disable */

export default {
  computed: {
    mockStudentGradesData() {
      const year = 2019;
      const years = [2016, 2017, 2018, 2019];
      const subjects = [
        {key: 'matematica', label: 'Matemática', relative: 0.6, trend: -0.2},
        {key: 'lenguaje', label: 'Lenguaje y comunicacion', relative: 0.2, trend: 0.1},
        {key: 'historia', label: 'Historia', relative: -1.6, trend: 0.25},
        {key: 'educacion-fisica', label: 'Educación Física', relative: 0, trend: 0.3},
        {key: 'ciencias', label: 'Ciencias Naturales', relative: 2.4, trend: 0.2},
      ]
      const grades = subjects.map(subject => years.map(gradeYear => {
          const trend = (year - gradeYear)*subject.trend*Math.random();
          const student_grade = Math.min(Math.max(Math.random()*2 + 5 + trend, 4), 7);
          const group_value = Math.min(7, Math.max(4, trend*Math.random() + Math.random()*subject.relative + 5 + trend));
          return {
            key: subject.key,
            label: subject.label,
            student_value: student_grade,
            group_value: group_value,
            course_value: group_value,
            year: gradeYear,
          }
        })
      ).flat(1);
      return {
        grades: grades,
        years: years,
        year: year
      }
    },
    mockEnrollmentData() {
      const resultsColumnKey = 'column_id';
      const resultsIndicatorKey = 'group_id';
      const defaultOpenColumns = [];

      const fakeYears = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017];
      const fakeLevels = [1, 2, 3, 4, 5, 6, 7, 8, 'I', 'II', 'III', 'IV'];

      const groups = [];
      const columns = [];
      const results = [];

      fakeLevels.forEach((level, index) => {
        groups.push({
          id: index + 1,
          label: `${level}º`,
        });
        ['A', 'B', 'C'].forEach((group, groupIndex) => {
          groups.push({
            id: parseInt(`${index}000${groupIndex}1337`),
            label: `${level}º ${group}`,
            parent_id: index + 1,
          });
        });
      });

      fakeYears.forEach((year, index) => {
        columns.push({
          id: index + 1,
          label: year,
        });
      });

      columns.forEach(column => {
        groups.forEach(group => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: (Math.random()*2)-1,
            group_id: group.id,
            column_id: column.id,
          });
        });
      });

      return {
        defaultOpenColumns,
        resultsColumnKey,
        resultsIndicatorKey,
        groups,
        columns,
        results,
      };
    },
    mockChronicAbsenteeismData() {
      const resultsColumnKey = 'column_id';
      const resultsIndicatorKey = 'group_id';
      const defaultOpenColumns = [];

      const fakeMonths = ['Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct'];
      const fakeLevels = [1, 2, 3, 4, 5, 6, 7, 8, 'I', 'II', 'III', 'IV'];

      const groups = [];
      const columns = [];
      const results = [];

      fakeLevels.forEach((level, index) => {
        groups.push({
          id: index + 1,
          label: `${level}º`,
        });
        ['A', 'B', 'C'].forEach((group, groupIndex) => {
          groups.push({
            id: parseInt(`${index}000${groupIndex}1337`),
            label: `${level}º ${group}`,
            parent_id: index + 1,
          });
        });
      });

      fakeMonths.forEach((month, index) => {
        columns.push({
          id: index + 1,
          label: month,
        });
        if (month === 'Oct') {
          defaultOpenColumns.push(index + 1);
        }
        ['2-6', '9-13', '16-20', '23-27', '30-31'].forEach((week, weekIndex) => {
          columns.push({
            id: parseInt(`${index}000${weekIndex}1338`),
            label: `${month} ${week}`,
            parent_id: index + 1,
          });
        });
      });

      columns.forEach(column => {
        groups.forEach(group => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: Math.min(-(Math.random())+0.5,0),
            group_id: group.id,
            column_id: column.id,
          });
        });
      });

      return {
        defaultOpenColumns,
        resultsColumnKey,
        resultsIndicatorKey,
        groups,
        columns,
        results,
      };
    },
    mockGradesGroupAverageData() {
      const resultsColumnKey = 'column_id';
      const resultsRowKey = 'group_id';
      const defaultOpenRows = [];

      const fakeSubjects = ['Lenguaje y Comunicación',
                            'Matemática',
                            'Historia, Geografía y Ciencias Sociales',
                            'Ciencias Naturales',
                            'Artes Visuales',
                            'Música',
                            'Tecnología',
                            'Educación Física y Salud'
                           ];
      const fakeLevels = [1, 2, 3, 4, 5, 6, 7, 8, 'I', 'II', 'III', 'IV'];

      const groups = [];
      const columns = [];
      const results = [];

      fakeLevels.forEach((level, index) => {
        groups.push({
          id: index + 1,
          label: `${level}º`,
        });
        if (level === 1) {
          defaultOpenRows.push(index + 1);
        }
        ['A', 'B', 'C'].forEach((group, groupIndex) => {
          groups.push({
            id: parseInt(`${index}000${groupIndex}1337`),
            label: `${level}º ${group}`,
            parent_id: index + 1,
          });
        });
      });

      fakeSubjects.forEach((subject, index) => {
        columns.push({
          id: index + 1,
          label: subject,
        });
      });

      columns.forEach(column => {
        groups.forEach(group => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: 3 * Math.random() - 1.5,
            group_id: group.id,
            column_id: column.id,
          });
        });
      });

      return {
        defaultOpenRows,
        resultsColumnKey,
        resultsRowKey,
        groups,
        columns,
        results,
      };
    },
    mockChronicAbsenteeismComparativeData() {
      const resultsColumnKey = 'column_id';
      const resultsIndicatorKey = 'school_id';
      const defaultOpenColumns = [];

      const fakeMonths = ['Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct'];
      const fakeSchools = [
        'Arturo Matte Larraín',
        'Liceo Bicentenario Italia',
        'Rafael Sanhueza Lizardi',
        'Presidente Alessandri',
        'Colegio Claudio Matte'
      ];

      const schools = [];
      const columns = [];
      const results = [];

      fakeSchools.forEach((school, index) => {
        schools.push({
          id: index + 1,
          label: school,
          parent_id: null,
        });
      });

      fakeMonths.forEach((month, index) => {
        columns.push({
          id: index + 1,
          label: month,
        });
        ['2-6', '9-13', '16-20', '23-27', '30-31'].forEach((week, weekIndex) => {
          columns.push({
            id: parseInt(`${index}000${weekIndex}1338`),
            label: `${month} ${week}`,
            parent_id: index + 1,
          });
        });
      });

      columns.forEach(column => {
        schools.forEach((school, index) => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: Math.min(-(Math.random())+0.5,0),
            school_id: index + 1,
            column_id: column.id,
          });
        });
      });

      return {
        defaultOpenColumns,
        resultsColumnKey,
        resultsIndicatorKey,
        schools,
        columns,
        results,
      };
    },
    mockAttendanceData() {
      const resultsColumnKey = 'column_id';
      const resultsIndicatorKey = 'group_id';
      const defaultOpenColumns = [];

      const fakeMonths = ['Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct'];
      const fakeLevels = [1, 2, 3, 4, 5, 6, 7, 8, 'I', 'II', 'III', 'IV'];

      const groups = [];
      const columns = [];
      const results = [];

      fakeLevels.forEach((level, index) => {
        groups.push({
          id: index + 1,
          label: `${level}º`,
        });
        ['A', 'B', 'C'].forEach((group, groupIndex) => {
          groups.push({
            id: parseInt(`${index}000${groupIndex}1337`),
            label: `${level}º ${group}`,
            parent_id: index + 1,
          });
        });
      });

      fakeMonths.forEach((month, index) => {
        columns.push({
          id: index + 1,
          label: month,
        });
        if (month === 'Oct') {
          defaultOpenColumns.push(index + 1);
        }
        ['2-6', '9-13', '16-20', '23-27', '30-31'].forEach((week, weekIndex) => {
          columns.push({
            id: parseInt(`${index}000${weekIndex}1338`),
            label: `${month} ${week}`,
            parent_id: index + 1,
          });
        });
      });

      columns.forEach(column => {
        groups.forEach(group => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: (Math.random()*2)-1,
            group_id: group.id,
            column_id: column.id,
          });
        });
      });

      return {
        defaultOpenColumns,
        resultsColumnKey,
        resultsIndicatorKey,
        groups,
        columns,
        results,
      };
    },
    mockAttendanceComparativeData() {
      const resultsColumnKey = 'column_id';
      const resultsIndicatorKey = 'school_id';
      const defaultOpenColumns = [];

      const fakeMonths = ['Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct'];
      const fakeSchools = [
        'Escuela Andrés Nelson Mandela F.',
        'E. Almirante Jaime Bunzli Rojas',
        'Liceo República RH de Quilpué',
        'Liceo Luis Calvo Garrido',
        'Colegio Creador René Morales',
        'Colegio Licán Pugh'
      ];

      const schools = [];
      const columns = [];
      const results = [];

      fakeSchools.forEach((school, index) => {
        schools.push({
          id: index + 1,
          label: school,
          parent_id: null,
        });
      });

      fakeMonths.forEach((month, index) => {
        columns.push({
          id: index + 1,
          label: month,
        });
        ['2-6', '9-13', '16-20', '23-27', '30-31'].forEach((week, weekIndex) => {
          columns.push({
            id: parseInt(`${index}000${weekIndex}1338`),
            label: `${month} ${week}`,
            parent_id: index + 1,
          });
        });
      });

      columns.forEach(column => {
        schools.forEach((school, index) => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: (Math.random() * 2) - 1,
            school_id: index + 1,
            column_id: column.id,
          });
        });
      });

      return {
        defaultOpenColumns,
        resultsColumnKey,
        resultsIndicatorKey,
        schools,
        columns,
        results,
      };
    },
    mockColorMapData() {
      const fakeLevels = [2, 3, 4, 5, 6, 7, 8, 'I', 'II', 'III', 'IV'];
      const resultsColumnKey = 'column_id';
      const columns = [];
      const results = [];
      const indicators = [
        {
          id: 44,
          key: "aprendizaje-y-ensenanza",
          label: "Aprendizaje y enseñanza",
          parent_id: null
        },
        {
          id: 42,
          key: "aprendizaje",
          label: "Aprendizaje",
          parent_id: 44
        },
        {
          id: 19,
          key: "ambiente-favorable-para-aprendizaje",
          label: "Ambiente favorable para aprendizaje",
          parent_id: 42
        },
        {
          id: 20,
          key: "percepcion-de-aprendizaje-en-institucion",
          label: "Percepción de aprendizaje en institución",
          parent_id: 42
        },
        {
          id: 43,
          key: "ensenanza",
          label: "Enseñanza",
          parent_id: 44
        },
        {
          id: 21,
          key: "valoracion-de-clases-recibidas",
          label: "Valoración de clases recibidas",
          parent_id: 43
        },
        {
          id: 22,
          key: "altas-expectativas",
          label: "Altas expectativas",
          parent_id: 43
        },
        {
          id: 23,
          key: "percepcion-de-ensenanza-personalizada",
          label: "Percepción de enseñanza personalizada",
          parent_id: 43
        },
        {
          id: 31,
          key: "eficacia-de-aspectos-ensenanza-segun-alumnos",
          label: "Eficacia de aspectos enseñanza (según alumnos)",
          parent_id: 43
        },
        {
          id: 32,
          key: "lenguaje",
          label: "Lenguaje",
          parent_id: 43
        },
        {
          id: 33,
          key: "matematica",
          label: "Matemática",
          parent_id: 43
        },
        {
          id: 5,
          key: "clima-de-convivencia-escolar",
          label: "Clima de convivencia escolar",
          parent_id: null
        },
        {
          id: 10,
          key: "ambiente-de-respeto",
          label: "Ambiente de respeto",
          parent_id: 5
        },
        {
          id: 11,
          key: "ambiente-organizado",
          label: "Ambiente organizado",
          parent_id: 5
        },
        {
          id: 12,
          key: "ambiente-seguro",
          label: "Ambiente seguro",
          parent_id: 5
        },
        {
          id: 70,
          key: "autoestima-academica-y-motivacion-escolar",
          label: "Autoestima académica y motivación escolar",
          parent_id: null
        },
        {
          id: 71,
          key: "autopercepcion_y_autovaloracion_academica",
          label: "Autopercepción y autovaloración académica",
          parent_id: 70
        },
        {
          id: 80,
          key: "habitos_de_vida_saludable",
          label: "Hábitos de vida saludable",
          parent_id: null
        },
        {
          id: 81,
          key: "habitos_alimenticios",
          label: "Hábitos alimenticios",
          parent_id: 80
        },
        {
          id: 90,
          key: "participacion",
          label: "Hábitos alimenticios",
          parent_id: null
        },
        {
          id: 91,
          key: "sentido_de_pertenencia",
          label: "Sentido de pertenencia",
          parent_id: null
        }
      ];
      const openIndicators = ['aprendizaje-y-ensenanza', 'aprendizaje', 'clima-de-convivencia-escolar'];

      fakeLevels.forEach((level, index) => {
        columns.push({
          id: index+1,
          label: `${level}º`,
        });
        ['A', 'B'].forEach((group, groupIndex) => {
          columns.push({
            id: parseInt(`${index}${groupIndex}`),
            label: `${level}º ${group}`,
            parent_id: index+1,
          });
        });
      });

      columns.forEach(column => {
        indicators.forEach(indicator => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: (Math.random()*2)-1,
            indicator_id: indicator.id,
            column_id: column.id,
          })
        });
      });

      return {
        resultsColumnKey,
        columns,
        results,
        indicators,
        openIndicators,
      };
    },
    mockHistoricalData() {
      const fakeYears = [2011, 2012, 2013, 2014, 2015, 2016, 2017];
      const resultsColumnKey = 'column_id';
      const columns = [];
      const results = [];
      const indicators = [
        {
          id: 44,
          key: "aprendizaje-y-ensenanza",
          label: "Aprendizaje y enseñanza",
          parent_id: null
        },
        {
          id: 42,
          key: "aprendizaje",
          label: "Aprendizaje",
          parent_id: 44
        },
        {
          id: 19,
          key: "ambiente-favorable-para-aprendizaje",
          label: "Ambiente favorable para aprendizaje",
          parent_id: 42
        },
        {
          id: 20,
          key: "percepcion-de-aprendizaje-en-institucion",
          label: "Percepción de aprendizaje en institución",
          parent_id: 42
        },
        {
          id: 43,
          key: "ensenanza",
          label: "Enseñanza",
          parent_id: 44
        },
        {
          id: 21,
          key: "valoracion-de-clases-recibidas",
          label: "Valoración de clases recibidas",
          parent_id: 43
        },
        {
          id: 22,
          key: "altas-expectativas",
          label: "Altas expectativas",
          parent_id: 43
        },
        {
          id: 23,
          key: "percepcion-de-ensenanza-personalizada",
          label: "Percepción de enseñanza personalizada",
          parent_id: 43
        },
        {
          id: 31,
          key: "eficacia-de-aspectos-ensenanza-segun-alumnos",
          label: "Eficacia de aspectos enseñanza (según alumnos)",
          parent_id: 43
        },
        {
          id: 32,
          key: "lenguaje",
          label: "Lenguaje",
          parent_id: 43
        },
        {
          id: 33,
          key: "matematica",
          label: "Matemática",
          parent_id: 43
        },
        {
          id: 5,
          key: "clima-de-convivencia-escolar",
          label: "Clima de convivencia escolar",
          parent_id: null
        },
        {
          id: 10,
          key: "ambiente-de-respeto",
          label: "Ambiente de respeto",
          parent_id: 5
        },
        {
          id: 11,
          key: "ambiente-organizado",
          label: "Ambiente organizado",
          parent_id: 5
        },
        {
          id: 12,
          key: "ambiente-seguro",
          label: "Ambiente seguro",
          parent_id: 5
        },
        {
          id: 4,
          key: "autoestima-academica-y-motivacion-escolar",
          label: "Autoestima académica y motivación escolar",
          parent_id: null
        }
      ];
      const openIndicators = ['aprendizaje-y-ensenanza', 'aprendizaje', 'clima-de-convivencia-escolar'];

      fakeYears.forEach((year, index) => {
        columns.push({
          id: year,
          label: year,
        });
      });

      columns.forEach(column => {
        indicators.forEach(indicator => {
          results.push({
            id: Date.now(),
            value: null,
            norm_value: (Math.random()*2)-1,
            indicator_id: indicator.id,
            column_id: column.id,
          })
        });
      });

      return {
        resultsColumnKey,
        columns,
        results,
        indicators,
        openIndicators,
      };
    },
  },
};
