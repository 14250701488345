import { mapState, mapGetters } from 'vuex';

export default {
  methods: {
    hasPermission(name) {
      return this.currentUser && this.currentUser.permissions[name];
    },
    hasAnyPermissionFrom(names) {
      return (
        this.currentUser &&
        (names.length === 0 || names.some(n => this.currentUser.permissions[n]))
      );
    },
    checkItemPermissions(item) {
      return (
        (this.freeOrGuestUser && item.alwaysShowToFreeUsers) ||
        !item.permissionsRequired ||
        this.hasAnyPermissionFrom(item.permissionsRequired)
      );
    },
  },
  computed: {
    isAptusAccount() {
      return this.hasPermission('aptus_client');
    },
    ...mapState({
      currentUser: state => state.auth.userInfo,
    }),
    ...mapGetters('auth', [
      'userPermissions',
      'hasAdministratorReportPermission',
      'hasAptusClientPermission',
      'hasAttendanceCompromisePermission',
      'hasAttendancePermission',
      'hasBarrancasClientPermission',
      'hasEnrollmentTrackingPermission',
      'hasGoogleClassroomAdministratorPermission',
      'hasGoogleClassroomPermission',
      'hasGradesPermission',
      'hasGroupReportPermission',
      'hasHigherEducationGraduationPermission',
      'hasInternalEvaluationsPermission',
      'hasImprovementPlanPermission',
      'hasManagementIndicatorsPermission',
      'hasOccupancyTrackingPermission',
      'hasPresenteClientPermission',
      'hasPulsoPermission',
      'hasPsuDetailsPermission',
      'hasRadarGeneratedSigeFilesPermission',
      'hasRadarUploadFilesPermission',
      'hasSalesianosLeadershipEvaluationPermission',
      'hasSchoolReportPermission',
      'hasSigeHistoricalStudentPromotionCsvPermission',
      'hasSipDashboardPermission',
      'hasStudentReportPermission',
      'hasTechnicalEducationQualificationPermission',
      'isFreeUser',
      'freeOrGuestUser',
      'isProUser',
      'userType',
    ]),
  },
};
