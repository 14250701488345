<template>
  <div
    v-if="isFreeUser"
    :class="$style.warningBarFreeAccountWarning">
    <span>
      Estás usando una cuenta gratuita de Radar Escolar con acceso limitado solo a información pública del colegio.<br>
    </span>
    <template v-if="requestedAccessToPlaform !== null">
      <platform-access-request-form
        :visible.sync="showDialog"
        :requested-access-to-plaform.sync="requestedAccessToPlaform"/>
      <span v-if="requestedAccessToPlaform">
        Estamos procesado tu solicitud de acceso a la plataforma. Solo queda esperar a que sea revisada por los usuarios validadores de tu colegio.
      </span>
      <span
        v-else
        :class="$style.clickable"
        @click="openDialog">
        Si tu establecimiento ya está suscrito a un plan con nosotros y tienes la autorización del equipo directivo, solicita tu acceso completo haciendo click aquí
      </span>
    </template>
  </div>
  <div
    v-else-if="(isSchoolHistorical || isComparative) && !isLoggedIn"
    :class="$style.warningBarClickable">
    <span
      v-if="isMobile"
      @click="signUp()">
      Para ver más resultados <strong>registrarte gratis aquí</strong>.
    </span>
    <span
      v-else
      @click="signUp()">
      <strong>¡Atención!</strong> Para ver más resultados puedes registrarte <strong>gratuitamente</strong> haciendo click aquí.
    </span>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AuthMixin from '../../mixins/auth';
import DisplayMixin from '../../mixins/display';
import PermissionMixin from '../../mixins/permission';
import LinksMixin from '../../mixins/links';
import PlatformAccessRequestForm from '../dialogs/platform-access-request.vue';
import AuthApi from '../../api/auth';

export default {
  name: 'WarningBar',
  components: { PlatformAccessRequestForm },
  mixins: [AuthMixin, DisplayMixin, PermissionMixin, LinksMixin],
  data() {
    return {
      windowWidth: 0,
      requestedAccessToPlaform: null,
      showDialog: false,
      showEditGradesDialog: false,
    };
  },
  computed: {
    ...mapState('results', {
      loading: state => !!state.loading,
    }),
    ...mapGetters('options', ['chosenSchool']),
    isLoggedIn() { return this.$store.state.auth.isLoggedIn; },
    isSchoolHistorical() { return this.$route.name === 'schoolHistorical'; },
    isComparative() { return this.$route.name === 'comparativeColorMap'; },
    isGradesByStudent() {
      return this.$route.name === 'gradesStudentSubjectAverages' ||
             this.$route.name === 'gradesStudentSubjectTests';
    },
    isGradesByStudentSubjectTests() {
      return this.$route.name === 'gradesStudentSubjectTests';
    },
    isGradesBySchool() {
      return this.$route.name === 'gradesGroupAverageBySchool' ||
             this.$route.name === 'gradesTestCountBySchool';
    },
    userCanUploadRadarFilesToSchool() {
      if (!this.chosenSchool) return false;
      const { grades_warning: gradesWarning } = this.chosenSchool;
      return this.hasRadarUploadFilesPermission && gradesWarning;
    },
  },
  async mounted() {
    try {
      const { body: { requested_school: requestedSchool } } =
        await AuthApi.getPlatformAccessRequests(this.currentUser.id);
      const { preferred_school_id: chosenSchoolId } = this.currentUser;
      this.requestedAccessToPlaform = requestedSchool === chosenSchoolId;
    // eslint-disable-next-line no-empty
    } catch (_) {
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.warning-bar {
  text-align: center;
  display: flex;
  flex-direction: column;;
  color: #fff;
  background-color: $primary-color-with-transparency;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 15px;
  margin: 10px 35px 15px 35px;
  min-height: 40px;
  padding: 0 2px 2px 2px;

  &-clickable {
    @extend .warning-bar;
    cursor: pointer;
  }

  &-free-account-warning {
    @extend .warning-bar;
    width: 95%;
    margin: 10px auto 0;
    padding: 10px;
  }

  a {
    display: inline-block;
    color: #fff;
    font-weight: bold;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 30px;
  }
}

.clickable {
  cursor: pointer;
}
</style>
