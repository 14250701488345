<template>
  <div
    :class="$style.rdrSwitcher">
    <el-switch
      :class="['rdrSwitcher', 'hide-print']"
      v-model="direction"
      :active-icon-class="views.right.icon"
      :inactive-icon-class="views.left.icon"
      :active-text="views.right.label"
      :active-color="views.right.color"
      :inactive-text="views.left.label"
      :inactive-color="views.left.color"
      active-value="right"
      inactive-value="left"
      @change="() => { emitToggleSwitchOnUpdate = true; }"/>
    <div
      v-if="direction === 'left'">
      <component
        :is="views.left.component"
        v-bind="views.left.props"
      />
    </div>
    <div
      v-else>
      <component
        :is="views.right.component"
        v-bind="views.right.props"
      />
    </div>
  </div>
</template>

<script>
import { Switch } from 'element-ui';

export default {
  name: 'RdrSwitcher',
  components: { 'el-switch': Switch },
  props: {
    views: {
      type: Object,
      default: () => ({
        left: {
          label: 'Left Label',
          color: 'Background Color when Left View is selected',
          component: 'Component to be shown when Left View is selected',
          props: 'Component props',
        },
        right: {
          label: 'Right Label',
          color: 'Background Color when Right View is selected',
          component: 'Component to be shown when Right View is selected',
          props: 'Component props',
        },
      }),
      validator: (prop) => {
        const outer = ['left', 'right'];
        const inner = ['label', 'color', 'component'];

        return outer.map(o => prop.hasOwnProperty(o) &&
          inner.map(i => prop[o].hasOwnProperty(i))
            .reduce((x, y) => x && y, true))
          .reduce((x, y) => x && y, true);
      },
    },
  },
  data() {
    return {
      direction: 'left',
      emitToggleSwitchOnUpdate: false,
    };
  },
  updated() {
    if (this.emitToggleSwitchOnUpdate) {
      this.$emit('switcher-toggle', this.direction);
      this.emitToggleSwitchOnUpdate = false;
    }
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .rdr-switcher {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: auto;
  }

  :global(.el-switch) {
    margin: 0 auto 15px auto;
    pointer-events: none;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 25px;
    }

    &__label {
      color: $primary-color;
      pointer-events: auto;

      & > span {
        font-size: 16px;
        text-decoration: none;
      }

      &:hover {
        color: darken($primary-color, 30%);
      }

      &--left {
        text-align: right;
        & > span {
          padding-right: 3px;
        }
      }

      &--right {
        text-align: left;
        & > span {
          padding-left: 3px;
        }
      }

      &:global(.is-active) {
        color: darken($primary-color, 30%);
        pointer-events: none;

        & > span {
          font-size: 14px;
          text-decoration-line: none;
        }
      }

    }

    &__core {
      pointer-events: auto;
      min-width: 40px;
    }

  }

</style>
