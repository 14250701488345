<template>
  <div>
    <nav
      :class="[
        $style.sidebar,
        { [$style.hidden]: !displayMenu },
        { 'menu-hover': !displayMenu },
      ]"
      :data-step="overviewTutorial.step"
      :data-intro="overviewTutorial.description"
      @mouseover="menutipoHoverOn()"
      @mouseout="menutipoHoverOff()"
    >
      <div :class="$style.menu">
        <template v-if="loadMenu">
          <div
            v-for="item in itemsMenu"
            :key="item.key"
            :class="$style.item"
            :data-step="itemTutorial(item).step"
            :data-intro="itemTutorial(item).description">
            <div
              :class="[
                $style.itemLabel,
                { 'center-icon-menu': !displayMenu,
                  [`${$style.active} ${$style.selected}`]: item.select,
                }]"
              @click="handleItemLabelClick(item, true)">
              <div :class="$style.itemLabelText">
                <i
                  v-if="item.icon"
                  :class="[$style.icon, item.icon]"
                />
                <span
                  v-if="item.pro && freeOrGuestUser"
                  :class="$style.proBadge">
                  PRO
                </span>
                <span
                  :class="[{ 'hidden-text-menu': !displayMenu }]"
                  style="margin-left:6px"
                >
                  {{ item.label }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="isLoggedIn"
        class="menudown">
        <div
          class="items-menu-down"
          @click="evaluateTuto()"
          :style="!displayMenu ? {'text-align':'center'} : ''"
        >
          <i class="clg-share_multimedia" />
          <span
            :class="[{ 'hidden-text-menu': !displayMenu }]"
            style="margin-left:6px"
          >Tutorial</span>
        </div>
        <div
          class="items-menu-down"
          :style="!displayMenu ? {'text-align':'center'} : ''"
        >
          <template v-if="displayMenu">
            <help-button />
          </template>
          <template v-else>
            <help-button :text-display="false" />
          </template>
        </div>
        <div
          class="items-menu-down"
          @click="handleItemLabelClick(itemConfig, true)"
          :style="!displayMenu ? {'text-align':'center'} : ''"
        >
          <i class="clg-config" />
          <span
            :class="{ 'hidden-text-menu': !displayMenu }"
            style="margin-left:6px"
          >Configuración</span>
        </div>
      </div>
      <div
        v-if="isFreeUser || !isLoggedIn"
        :class="$style.footer">
        <a href="https://www.radarescolar.cl/" style="color:#FFF !important;">
          <span>Más información</span>
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { itemHeight } from './sidebar-utils';

import items from './menu';
import AuthMixin from '../../mixins/auth';
import LinksMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';
import HelpButton from './help-button.vue';

export default {
  name: 'Sidebar',
  components: {
    HelpButton,
  },
  mixins: [AuthMixin, LinksMixin, PermissionMixin],
  data() {
    return {
      currentOpenItem: null,
      itemConfig: {
        key: 'settings',
        label: 'Configuración general',
        icon: 'clg-config',
        link: 'settingsLink',
        permissionsRequired: [],
        routes: ['settings'],
      },
      firstEvent: true,
      firstAction: true,
      loadMenu: false,
      firstTitle: '',
      titleStart: '',
      itemsMenu: [],
    };
  },
  computed: {
    ...mapState('ui', ['displayMenu', 'openedSidebarItem', 'menuHover', 'menuArray']),
    ...mapGetters('intro', ['introOpenItem', 'tutorialInfo']),
    items() {
      const menuItems = this.availableMenuItems();
      if (this.freeOrGuestUser) {
        const index = menuItems.findIndex(i => i.key === 'public-results');
        menuItems.splice(0, 0, ...menuItems.splice(index, 1));
      }
      const allItems = [
        ...menuItems, // ,
        // {
        //   key: 'settings',
        //   label: 'Configuración general',
        //   icon: 'clg-config',
        //   link: 'settingsLink',
        //   permissionsRequired: [], // checks if logged in
        //   routes: ['settings'],
        // },
      ];

      let filterMenu = allItems.filter(i => this.checkItemPermissions(i))
        .map(i => {
          if (!i.children) return i;

          return {
            ...i,
            children: i.children.filter(c => this.checkItemPermissions(c)),
          };
        });
      // return this.getItemsActive(filterMenu);
      return filterMenu;
    },

    openItem() {
      return this.introOpenItem || this.currentOpenItem;
    },
    overviewTutorial() {
      return this.tutorialInfo(['menu', 'overview']);
    },
  },
  watch: {
    openedSidebarItem() {
      this.currentOpenItem = this.openedSidebarItem;
    },
  },
  mounted() {
    const menuStorage = localStorage.getItem('menu_data');
    const itemStorage = localStorage.getItem('item_select');
    const objMenuStorage = JSON.parse(menuStorage);
    const objItemStorage = JSON.parse(itemStorage);

    if (menuStorage === null) {
      this.itemsMenu = this.getItemsActive(this.items); // JSON.parse(JSON.stringify(this.items));
      localStorage.setItem('menu_data', JSON.stringify(this.itemsMenu));
    } else {
      this.itemsMenu = JSON.parse(JSON.stringify(objMenuStorage));
    }
    this.validateCountry();
    const open = this.itemsMenu.find(
      i =>
        i.children &&
        i.children.length &&
        i.children.some(
          c => c.routes && c.routes.length && this.routesMatch(c.routes)
        )
    );
    if (open) {
      this.openMenu(open.key);
    }

    if (menuStorage !== null) {
      this.itemSelected(objItemStorage);
    }
    if (this.firstAction) {
      this.firstAction = false;
      if (itemStorage === null) this.titleModule(this.titleStart);
    }
    setTimeout(() => {
      this.loadMenu = true;
    }, 400);
  },
  methods: {
    ...mapMutations({
      setIntroOpenItem: 'intro/SET_MENU_OPEN_ITEM',
      hoverMenu: 'ui/hoverMenu',
      menuData: 'ui/menuData',
      titleModule: 'ui/titleModule',
    }),
    ...mapActions({
      startIntro: 'intro/start',
    }),
    link(link) {
      this.$router.push(this[link]);
    },
    openMenu(key) {
      this.currentOpenItem = this.currentOpenItem === key ? null : key;
      this.setIntroOpenItem(
        this.itemsMenu.find(i => i.key === this.currentOpenItem)
      );
    },
    handleItemLabelClick(item, removeItem=false) {
      if (item.children && item.children.length) {
        // this.openMenu(item.key);
        this.link(item.children[0].link);
      } else this.link(item.link);
      this.itemSelected(item, removeItem);
    },
    height(item) {
      return itemHeight(item, this.openItem, this.userPermissions);
    },
    itemTutorial(item) {
      return this.tutorialInfo(['menu', 'items', item.key]);
    },
    availableMenuItems() {
      const permissionRestrictionInfo = {
        'aptus_client': ['improvement-plan', 'indicators'],
      };

      let myItems = items.slice();
      Object.entries(permissionRestrictionInfo).forEach(
        ([permission, restrictionList]) => {
          if (this.hasPermission(permission)) {
            myItems = myItems.filter(obj => !restrictionList.includes(obj.key));
          }
        }
      );
      return myItems;
    },
    menutipoHoverOn() {
      if (this.firstEvent && this.menuHover) {
        this.firstEvent = false
        this.hoverMenu(true)
      }
    },

    menutipoHoverOff() {
      this.firstEvent = true
      if (this.menuHover) {
        this.hoverMenu(false)
      }
    },

    itemSelected(item, removeItem=false) {
      this.loadMenu = false
      let objClon = JSON.parse(JSON.stringify(this.itemsMenu));
      if (removeItem) {
        localStorage.removeItem('item_select');
        localStorage.removeItem('item_index');
      }

      // llaves bloqueadas para quitar estilos de menu y tab activo en todos los items.
      if (item !== null) {
        objClon.forEach((val) => {
          val.select = false;
          if(val.children !== undefined) {
            val.children.forEach((val2) => {
              val2.tabActiv = false;
            });
          }
          // activamos llaves para agregar estilos de menu y tab activo.
          if(val.key === item.key) {
            val.select = true;
            if(val.children !== undefined) val.children[0].tabActiv = true;
            this.titleModule(val);
          }
        });
        if (item.key === 'settings') {
          const objData = {};
          this.titleModule(objData);
        }
      }
      this.itemsMenu = [];
      this.itemsMenu = JSON.parse(JSON.stringify(objClon));
      localStorage.setItem('menu_data', JSON.stringify(objClon));
      this.loadMenu = true
    },

    getItemsActive(data) {
      const assignedRoute = this.landingLink.name;
      let newObj = JSON.parse(JSON.stringify(data));
      // const assignedRoute = 'schoolHistorical';

      newObj.forEach((val, key) => {
        if(val.children !== undefined) {
          val.children.forEach((val2, key2) => {
            val2.routes.forEach((val3, key3) => {
              if(val3 === assignedRoute) {
                val.select = true;
                val2.tabActiv = true;
                this.titleStart = val;
              }
            });
          });
        } else {
          val.routes.forEach((val4) => {
            if(val4 === assignedRoute) {
              val.select = true;
              this.titleStart = val;
            }
          });
        }
      });
      data = JSON.parse(JSON.stringify(newObj));
      return data;
    },

    validateCountry() {
      const listSchool = JSON.parse(JSON.stringify(this.currentUser.schools));
      let countryCHL = false;
      listSchool.forEach((val, key) => {
        if (val.country === 'CL') countryCHL = true;
      });
      if (!countryCHL) {
        let keyRmove = null;
        this.itemsMenu.forEach((valMen, keyMen) => {
          if (valMen.key === 'public-results') keyRmove = keyMen;
        });
        if (keyRmove !== null) this.itemsMenu.splice(keyRmove, 1);
      }
    },

    evaluateTuto() {
      this.startIntro();
      if (this.menuHover) {
        setTimeout(() => {
          const elem = document.getElementsByClassName('introjs-tooltip');
          const list = elem[0].classList;
          list.add('dialog-hover');
        }, 600);
      }
    },
  },
};
</script>

<style lang="scss" module>
@import '../../../styles/app/variables';

.sidebar {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  // top: $header-height;
  z-index: 1500;
  border-right: 1px solid $sidebar-border-color;
  background-color: $primary-color; // $sidebar-background;
  overflow: auto;
  width: $sidebar-width;
  height: calc(100vh - 56px);
  transition: all 0.3s linear;
  padding-top: 30px;
}

.hidden {
  // transform: translateX(-$sidebar-width);
  transition: all 0.3s linear;
  width: 60px; // 0;
}
.menu {
  overflow: auto;
}

.item {
  // transition: height 0.9s ease-out;
  user-select: none;
  overflow: hidden;
  color: #fff;
  height: 40px;
  // margin: 0px 6px;
}

.item-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid $sidebar-border-color;
  padding: 10px 15px;
  height: 40px; // $sidebar-item-height;
  font-size: 13px;
  cursor: pointer;

  &:hover:not(.selected) {
    background-color: #eee;
    color: $primary-color;
  }
}

.item-label-text {
  display: flex;
  align-items: center;
}

.subitem {
  position: relative;
  // border-bottom: 1px solid $sidebar-border-color;
  padding: 12px 15px;
  padding-left: 24px;
  font-size: 12px;
  height: $sidebar-subitem-height;
  display: list-item;
  cursor: pointer;
  margin: 0px 12px;
  list-style: none;

  &:before {
    content: '—';
    margin-right: 10px;
  }

  &:hover:not(.selected) {
    background-color: #eee;
    color: $primary-color;
  }
}

.active {
  color: #fff;
}

.selected {
  background-color: $sidebar-subitem-background;
  font-weight: bold;
  border-left: 5px solid $primary-color;
  border-right: 5px solid $primary-color;
  border-radius: 25px;

}

.pro-badge {
  padding: 0px 6px;
  margin: 5px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background-color: $primary-color;
  border-radius: 10px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  font-size: 11px;
  color: $primary-color;
  padding: 15px;
  height: $sidebar-item-height;

  a {
    text-decoration: none;
  }
}
</style>
<style>
  .dialog-hover{
    left: 70px !important;
  }
</style>
