<template>
  <div :class="$style.appLayout">
    <rdr-mobile-menu class="hide-print"/>
    <rdr-header :class="[$style.header, 'hide-print']" />
    <div :class="$style.mainContainer">
      <rdr-sidebar :class="[$style.sidebar, 'hide-print', 'hide-mobile']" />
      <main
        ref="main"
        :class="[$style.main, {[$style.shrunkenWidth]: displayMenu}, {[$style.expandedWidth]: !displayMenu}]">
        <template v-if="totalTabs !== 0">
          <div :class="[$style.divtabs]">
            <tabs-submenu
              :tabs="tituloMod"/>
          </div>
        </template>
        
        <rdr-warning-bar />

        <div :class="[$style.printDate, 'print-only']">
          <p>Impreso el {{ getCurrentDate }}</p>
        </div>
        <div
          v-if="title && title.length"
          :class="[$style.title, 'hide-print', 'mobile-only']"
          @click="showMobileMenu">
          {{ title }}
        </div>
        <div
          :class="[$style.content] ">
          <router-view/>
          <rdr-footer />
        </div>
        <!-- <div :class="[$style.printLogoWrapper, 'print-only']">
          <img
            :class="$style.printLogo"
            src="/logo-radar-colegium-extend.png"
          >
        </div> -->
      </main>
    </div>
    <div style="display: none;"><school-picker /></div>
    <!-- Dialogs -->
    <!-- <rdr-contact-notification /> -->
    <!-- <contact-form-dialog /> -->
    <login-dialog />
    <sign-up-dialog />
    <password-feedback-dialog />
    <school-picker-dialog />
    <school-select-dialog />
    <upcoming-dialog />
    <feedback-dialog/>
    <!-- <version-update-dialog/> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import Footer from '../components/partials/footer.vue';
// import ContactNotification from '../components/partials/contact-notification.vue';
import FeedbackDialog from '../components/dialogs/feedback.vue';
// import ContactFormDialog from '../components/dialogs/contact-form.vue';
import FreeOfferDialog from '../components/dialogs/free-offer.vue';
import LoginDialog from '../components/dialogs/login.vue';
import NewSimceNotificationDialog from '../components/dialogs/new-simce-notification.vue';
import PasswordFeedbackDialog from '../components/dialogs/password-feedback.vue';
import SchoolPickerDialog from '../components/dialogs/school-picker.vue';
import SchoolSelectDialog from '../components/dialogs/school-select.vue';
import SignUpDialog from '../components/dialogs/sign-up.vue';
import UpcomingDialog from '../components/dialogs/upcoming.vue';
// import VersionUpdateDialog from '../components/dialogs/version-update.vue';
import WarningBar from '../components/utils/warning-bar.vue';
import Header from './app/header.vue';
import Sidebar from './app/sidebar.vue';
import MobileMenu from './app/mobile-menu.vue';
import LinksMixin from '../mixins/links';
import PermissionMixin from '../mixins/permission';
import TabsSubmenu from '../components/tabs-submenu.vue';

export default {
  components: {
    'rdr-footer': Footer,
    // 'rdr-contact-notification': ContactNotification,
    'rdr-mobile-menu': MobileMenu,
    'rdr-header': Header,
    'rdr-sidebar': Sidebar,
    'rdr-warning-bar': WarningBar,
    FeedbackDialog,
    // ContactFormDialog,
    FreeOfferDialog,
    LoginDialog,
    NewSimceNotificationDialog,
    PasswordFeedbackDialog,
    SchoolPickerDialog,
    SchoolSelectDialog,
    SignUpDialog,
    UpcomingDialog,
    // VersionUpdateDialog,
    TabsSubmenu,
  },
  mixins: [LinksMixin, PermissionMixin],
  data() {
    return {
      totalTabs: 0,
    };
  },
  computed: {
    ...mapState('ui', ['displayMenu']),
    ...mapState('options', ['chosenSchoolId', 'chosenSchoolIds']),
    ...mapState('auth', ['isLoggedIn']),
    ...mapGetters('auth', ['userSchoolIds', 'userType']),
    ...mapState('ui', ['tituloMod']),
    getCurrentDate() {
      return (new Date()).toLocaleDateString('es-CL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    },
  },
  watch: {
    '$route': {
      async handler(to, from) {
        this.setFetchReady(false);
        await this.updateSelectionFromRoute(to, from);
        await this.fetchGroups();
        this.setFetchReady(true);
      },
      immediate: true,
    },

    tituloMod: function(newValue) {
      if (this.tituloMod.hasOwnProperty('children')) {
        this.totalTabs = this.tituloMod.children.length;
      } else {
        this.totalTabs = 0;
      }
    }
  },
  mounted() {
    if (this.userType !== 'guest' && !localStorage.getItem('tutorialSeen')) {
      setTimeout(() => {
        this.startIntro();
        localStorage.setItem('tutorialSeen', true);
      }, 3000); // eslint-disable-line no-magic-numbers
    }
  },
  methods: {
    ...mapMutations('ui', ['showMobileMenu']),
    ...mapMutations('options', { setFetchReady: 'SET_FETCH_READY' }),
    ...mapActions('options', [
      'setChosenSchool',
      'setChosenSchools',
      'fetchGroups',
      'setChosenGroup',
      'setChosenYear',
      'setChosenMonth',
      'autofillRemainingSchoolChoices',
    ]),
    ...mapActions('intro', {
      startIntro: 'start',
    }),
    /* eslint-disable max-statements */
    async updateSchoolsFromRoute(route) {
      const { params } = route;

      let schoolId = params.school;
      let schoolIds = this.isPublicRoute(route.name) ? null : this.userSchoolIds;
      if (params.schools) {
        schoolIds = params.schools.split(',');
      } else if (!params.school && !params.schools) {
        schoolId = this.chosenSchoolId || this.userSchoolIds[0];
        schoolIds = this.userSchoolIds;
      } else if (this.chosenSchoolIds.length === 0) {
        schoolIds = this.userSchoolIds;
      }

      if (schoolId) await this.setChosenSchool(schoolId);
      if (schoolIds) await this.setChosenSchools(schoolIds);
      if (this.userType === 'guest') await this.autofillRemainingSchoolChoices();
    },
    async updateSelectionFromRoute(to, from) {
      const params = to.params;
      if (params.group) this.setChosenGroup(params.group);
      if (params.year) this.setChosenYear(parseInt(params.year, 10));
      if (params.month) this.setChosenMonth(parseInt(params.month, 10));
      if (!this.isPublicComparativeRoute(to.name) &&
        from && this.isPublicRoute(from.name) &&
        to.name !== from.name) {
        this.setChosenSchools(
          [...new Set([
            ...this.userSchoolIds,
            ...this.chosenSchoolIds.map(x => parseInt(x, 10)),
          ])]
        );
      }
      if (sessionStorage.getItem('useOwnSchools')) {
        sessionStorage.removeItem('useOwnSchools');
        await this.setChosenSchools(this.userSchoolIds);
        await this.setChosenSchool(this.userSchoolIds[0]);
      } else {
        await this.updateSchoolsFromRoute(to);
      }
    },
    isPublicRoute(routeName) {
      return ['schoolColorMap', 'schoolHistorical', 'learningLevels',
        'comparativeColorMap', 'comparativeHistorical', 'comparativeEntry'].includes(routeName);
    },
    /* eslint-enable max-statements */
    isPublicComparativeRoute(routeName) {
      return ['comparativeColorMap', 'comparativeHistorical', 'comparativeEntry'].includes(routeName);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.app-layout {
  @media only print {
    display: block;
  }

  @media only screen {
    display: grid;
    justify-items: stretch;
    height: 100vh;
    grid-template-rows: $header-height auto;
    grid-template-areas: "header" "main";
  }
}

.main-container {
  display: flex;
}

.main {
  grid-area: main;
  overflow: auto;
  // transform: translateX(0);
  height: calc(100vh - 56px);
  max-width: 100vw;
  width: 100%;
  transition: all $menu-transition-time linear;
  background: $administrator-report-background;
  border-top: 1px solid #ccc;

  @media print {
    height: 99%;
  }
}

.shrunken-width {
  @media only screen and (min-width: #{$mobile-breakpoint + 1px}) {
    max-width: calc(100vw - #{$sidebar-width});
  }
}

.expanded-width {
  @media only screen and (min-width: #{$mobile-breakpoint + 1px}) {
    max-width: calc(100vw - 61px);
  }
}

.header {
  grid-area: header;
}

.content {
  // transform: scaleX(1);
  padding: 10px;
  // width: 100%;
  margin: 25px;
  // background: #fff;
  border-radius: 20px;

  @media only screen and (max-width: $mobile-breakpoint) {
    padding: 15px;
  }

  @media only print {
    padding: 0;
    overflow: hidden;
  }
}

.title {
  background-color: $title-background;
  padding: 5px 20px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.print-logo-wrapper {
  max-width: $desktop-max-width;
  padding: 20px;
  width: 100%;
  text-align: right;
}

.print-logo {
  height: 55px;
  width: 150px;
}

.print-date {
  max-width: $desktop-max-width;
  width: 100%;
  text-align: right;
  padding: 0 20px;
}

.divtabs {
  background: #FAFAFA;
  border-bottom: 1px solid #ccc;
}
</style>
