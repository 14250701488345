<template>
  <div id="dth-contenReport" class="report">
    <template v-if="isGeneralPanel || isAttendanceEvolution">
      <alert-info :text-alert="title" :icon-viwe="false"/>
    </template>
    <div :class="[$style.rdrView, $style.attendanceView, 'rdr-view']">
      <rdr-results-header
        :tabs="bySchoolTabs"
        :show-school-selector="isLoggedIn"
        hide-school-picker
        year-picker
        type-picker>
        <br>
        <span v-if="lastUpdated" class="results-header__subtitle hide-print">
          Última actualización: {{ lastUpdated }}
        </span>
      </rdr-results-header>

      <school-attachment-download
        v-if="chosenSchoolId"
        :key-params="{chosenSchoolId, chosenYear}"
        :reports-params="[
          {
            reportKey: 'presente-attendance-pdf',
            label: 'Descargar Informe Presente',
          }
        ]"
      />
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ResultsHeader from '../../components/results/results-header.vue';
import SchoolAttachmentDownload from '../../components/school-attachment-download.vue';
import AuthMixin from '../../mixins/auth';
import LastUpdated from '../../mixins/last-updated';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'AttendanceBySchoolView',
  components: {
    'rdr-results-header': ResultsHeader,
    SchoolAttachmentDownload,
    AlertInfo,
  },
  mixins: [AuthMixin, LastUpdated],
  data() {
    return {
      title: '',
    }
  },
  computed: {
    ...mapState('options', ['chosenYear', 'chosenSchoolId']),
    ...mapGetters('results', ['results']),
    generalPanelLink() {
      return { name: 'attendanceGeneralPanelBySchool', params: this.$route.params };
    },
    attendanceEvolutionLineChartLink() {
      return { name: 'attendanceEvolutionLineChart', params: this.$route.params };
    },
    lastYearComparisonLink() {
      return { name: 'attendanceLastYearComparisonBySchool', params: this.$route.params };
    },
    byDateLink() {
      return { name: 'attendanceByDateBySchool', params: this.$route.params };
    },
    byRiskLink() {
      return { name: 'attendanceRiskBySchool', params: this.$route.params };
    },
    isGeneralPanel() {
      this.title = 'Porcentaje de Asistencia (en base a días) y Ausentismo Crónico (porcentaje de estudiantes en esa condición).';
      return this.$route.name === 'attendanceGeneralPanelBySchool';
    },
    isAttendanceEvolution() {
      this.title = 'En este reporte encontrarás la comparación de asistencia de tu colegio del año actual con el año anterior.';
      return this.$route.name === 'attendanceEvolutionLineChart';
    },
    bySchoolTabs() {
      return [
        { icon: 'info', name: 'Panel General', link: this.generalPanelLink },
        { icon: 'show_chart', name: 'Evolución mensual', link: this.attendanceEvolutionLineChartLink },
        { icon: 'bar_chart', name: 'Comparación año anterior', link: this.lastYearComparisonLink },
        { icon: '', name: 'Por fecha', link: this.byDateLink },
        { icon: '', name: 'Riesgo', link: this.byRiskLink },
      ];
    },
    lastUpdated() {
      const fetchedResults = this.results;
      if (fetchedResults === 'ERROR-500') return null;
      if (fetchedResults.length === 0) return null;

      let latestImport = new Date(0);
      fetchedResults.forEach((result) => {
        const resultDate = new Date(result.imported_at);
        if (!latestImport || resultDate > latestImport) latestImport = resultDate;
      });
      // let textFecha = 'Última actualización:' + this.toLocal(latestImport, 'DD/MM/YYYY')
      // this.title = this.title + textFecha;
      return this.toLocal(latestImport, 'DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .attendance-view {
    .color-map {
      &__header {
        .button-wrapper {
          min-width: 100px;
          max-width: 100px;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
          padding-right: 100px;
        }
      }

      &__row-label {
        min-width: 100px;
        max-width: 100px;
        padding-left: 24px;
      }

      &__column-label--child {
        padding: 0;
        background: none;
      }
    }
  }
  .header {
    display: flex;
    justify-content: flex-end;
  }
  @media print {
    .color-map {
      width: 600px;
      min-width: 600px;
    }
    .button-wrapper {
      min-width: 100px;
    }
    .color-map__column {
      min-width: 50px;
      font-size: 9px;
    }
    .color-map__row-label {
      min-width: 100px;
      font-size: 9px;
    }
    .color-map__result {
      min-width: 50px;
      font-size: 9px;
    }
    .color-map__result-value {
      font-size: 9px;
    }
    .rdr-view {
      padding: 0px;
    }
    .content {
      padding: 0px;
      margin: 0px;
    }
    .rdr-view {
      border: 0px;
    }
    .contenScroll {
      text-align: center;
    }
  }

</style>
