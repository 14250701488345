const MOBILE_BREAKPOINT = 750;

export default {
  computed: {
    isMobile() {
      const windowWidth = window.innerWidth;
      return windowWidth < MOBILE_BREAKPOINT;
    },
  },
};
