<template>
  <a class="rdr-btn rdr-btn--success share-btn-whatsapp mobile-only" :href="link" data-action="share/whatsapp/share">
    <i class="rdr-btn__icon socicon-whatsapp"></i>
    <span>Compartir en tu grupo</span>
  </a>
</template>

<script>
  export default {
    computed: {
      link() {
        const link = encodeURIComponent(`https://${window.location.host}/#${this.$route.fullPath}`);
        return `whatsapp://send?text=${link} Mira esta comparación del colegio`;
      }
    }
  }
</script>

<style lang="scss">
  .share-btn-whatsapp {
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 240px;
  }
</style>
