<template>
 <!-- <i
    :class="[
      $style.sortButton,
      'material-icons',
      order === null ? 'hide-print' : $style.active,
    ]"
    v-on="$listeners"
  >
    {{ order === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
  </i>-->
  <i :class="[
      $style.sortButton,
      order === null ? 'hide-print' : $style.active,
      order === 'asc' ? 'clg-smal-arrow-up' : 'clg-smal-arrow-down'
    ]"
    v-on="$listeners"/>
</template>

<script>
export default {
  name: 'SortButton',
  props: {
    order: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

i.sort-button {
  user-select: none;
  cursor: pointer;
  font-size: 36px;
}

.active {
  color: $primary-color;
}

</style>
