<template>
  <div :class="$style.box">
    <div
      :class="$style.title"
    >
      <div :class="$style.titleElements">
        <div>
          {{ title }}
        </div>
        <span
          v-if="infoTooltip"
          :class="tooltipClass || $style.infoTooltip">
          <info-icon
            :text="infoTooltip"/>
        </span>
      </div>
      <sort-button
        :order="sort[0]"
        @click="rotateSort"/>
    </div>
    <div
      :class="$style.rows"
    >
      <div
        v-for="value in sortedValues"
        :key="value.objectId"
        :class="[$style.row, { [$style.groupRow]: objectType === 'group' }]"
      >
        <div
          v-if="objects[value.objectId] && objects[value.objectId].link"
          :class="$style.object"
          @click="objects[value.objectId].handlePress ? objects[value.objectId].handlePress() : () => {}"
        >
          <router-link
            :to="objects[value.objectId].link">
            <rdr-tooltip
              :disabled="!objects[value.objectId].key">
              <div v-html="objects[value.objectId].key"/>
              <span
                slot="reference">
                {{ objectName(objects[value.objectId], objectType) }}
              </span>
            </rdr-tooltip>
          </router-link>
        </div>
        <div
          v-else
          :class="$style.object"
        >
          {{ objects[value.objectId] ? objects[value.objectId].name : '' }}
        </div>
        <div
          v-if="pro !== null"
          :class="$style.value">
          <rdr-tooltip>
            <div>{{ pro }}</div>
            <i
              slot="reference"
              class="material-icons material-icons-light-grey">
              help
            </i>
          </rdr-tooltip>
        </div>
        <div
          v-else
          :class="$style.value">
          {{ leftValue(value) }}
        </div>
        <rdr-tooltip
          :class="$style.bar"
          :disabled="!value.extraInfo">
          <span v-html="value.extraInfo"/>
          <progress-bar
            v-if="pro === null && value.value !== null"
            slot="reference"
            :class="$style.progress"
            :line-color="getColor(value.value)"
            :progress="progress(value.value)"
            :background-color="progressBarBackgroundColor"
            :rotation="180"
            :height="8"
            :round="2"
            :sorted="false"
            :interpolate-colors="interpolateColors"
          />
        </rdr-tooltip>
        <div
          v-if="showComplement"
          :class="$style.value">
          {{ rightValue(value) }}
        </div>

      </div>
      <rdr-tooltip
        :class="$style.bar"
        :disabled="!aggrInfo">
        <span v-html="aggrInfo"/>
        <div
          v-if="hasTotal"
          slot="reference"
          :class="[$style.stickyRow, { [$style.totalWithLegend]: legends }]">
          <div :class="$style.object">
            Total
          </div>
          <div :class="$style.value">
            {{ aggrValue !== null ? formatValue(aggrValue, aggrFormat) : '—' }}
          </div>
          <div :class="$style.bar"/>
        </div>
      </rdr-tooltip>

      <div
        v-if="legends"
        :class="[
          $style.stickyRow,
          $style.legendsWrapper,
          { [$style.legendsWithTotal]: hasTotal },
      ]">
        <div :class="$style.object"/>
        <div
          v-for="legend in legends"
          :key="legend.label"
          :class="$style.legend">
          <div
            :class="$style.legendBox"
            :style="{backgroundColor: legend.color}"/>
          {{ legend.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '../base/progress-bar';
import { PRIMARY_COLOR, SIDEBAR_BACKGROUND_COLOR, WHITE_COLOR } from '../../utils/style-variables';
import interpolate from '../../utils/color-interpolate';
import InfoIcon from '../info-icon.vue';
import SortButton from '../sort-button.vue';
import startCase from '../../filters/start-case';
import cleanSchoolName from '../../filters/clean-school-name';

export default {
  name: 'IndicatorBox',
  components: {
    InfoIcon,
    ProgressBar,
    SortButton,
  },
  props: {
    color: {
      type: [String, Array],
      default: PRIMARY_COLOR,
    },
    objects: {
      type: Object,
      default: () => ({}),
    },
    objectType: {
      type: String,
      default: 'group',
    },
    orderedObjects: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    infoTooltip: {
      type: String,
      default: null,
    },
    tooltipClass: {
      type: String,
      default: null,
    },
    maxValue: {
      type: Number,
      default: null,
    },
    reverseSort: {
      type: Boolean,
      default: false,
    },
    hasTotal: {
      type: Boolean,
      default: false,
    },
    aggrValue: {
      type: Number,
      default: null,
    },
    aggrInfo: {
      type: String,
      default: null,
    },
    pro: {
      type: String,
      default: null,
    },
    aggrFormat: {
      type: String,
      default: null,
    },
    progressBarBackgroundColor: {
      type: String,
      default: SIDEBAR_BACKGROUND_COLOR,
    },
    legends: {
      type: Array,
      default: null,
    },
    interpolateColors: {
      type: Boolean,
      default: true,
    },
    showComplement: {
      type: Boolean,
      default: false,
    },
    showFirstValue: {
      type: Boolean,
      default: false,
    },
    showLastValue: {
      type: Boolean,
      default: false,
    },
    sortFunc: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      sort: this.reverseSort ? ['desc', 'asc', null] : [null, 'desc', 'asc'],
    };
  },
  computed: {
    shade() {
      return interpolate([this.color, WHITE_COLOR]);
    },
    processedValues() {
      return this.values.map(e => ({ ...e, value: Array.isArray(e.value) ? e.value : [e.value] }));
    },
    maxDefaultValue() {
      return Math.max(...this.processedValues.map(o => o.value.reduce((a, b) => a + b, 0)), 0);
    },
    activeSort() { return this.sort[0] !== null; },
    sortedValues() {
      if (this.sort[0] === 'desc') {
        return [...this.processedValues].sort((a, b) => (a.value.every(x => x === null)) - (b.value.every(x => x === null)) ||
          this.sortingFunction(a, b));
      } else if (this.sort[0] === 'asc') {
        return [...this.processedValues].sort((a, b) => (a.value.every(x => x === null)) - (b.value.every(x => x === null)) ||
          this.sortingFunction(b, a));
      }
      return [...this.processedValues].sort((a, b) => (a.value.every(x => x === null)) - (b.value.every(x => x === null)) ||
        this.orderedObjects.indexOf(a.objectId) - this.orderedObjects.indexOf(b.objectId));
    },
  },
  mounted() {
    this.rotateSort();
  },
  methods: {
    sortingFunction(a, b) {
      if (this.sortFunc) {
        return this.sortFunc(a, b);
      }
      return b.value[b.value.length - 1] - a.value[a.value.length - 1];
    },
    leftValue(value) {
      if (value.value.every(e => e === null)) return '—';
      let realValue;
      if (this.showLastValue) {
        realValue = value.value[value.value.length - 1];
      } else {
        realValue = value.value.length > 1 && !this.showFirstValue ? value.value[0] - value.value[1] : value.value[0];
      }
      return this.formatValue(realValue, value.format);
    },
    rightValue(value) {
      if (value.value.every(e => e === null)) return '';
      const realValue = value.value[value.value.length - 1];
      return this.formatValue(realValue, value.format);
    },
    getColor(value) {
      if (value === null) return null;
      return value.map((e, i) => (this.interpolateColors ? this.shade(1 - this.percentage(e)) : this.color[i]));
    },
    objectName(object, objectType) {
      if (objectType === 'group') return object.name;

      return startCase(cleanSchoolName(object.name));
    },
    formatValue(value, format) {
      if (value === null) return null;
      let formattedValue = value;
      if (format) {
        if (format.string) {
          formattedValue = this.$options.filters[format.string](value);
        } else {
          formattedValue = this.$options.filters[format](value);
        }
      }

      return formattedValue;
    },
    percentage(value) {
      if (value === null) return null;
      const maxValue = this.maxValue ? this.maxValue : this.maxDefaultValue;

      return (value / maxValue) || 0;
    },
    progress(value) {
      if (value === null) return null;
      return value.map(e => this.percentage(e) * 100); // eslint-disable-line no-magic-numbers
    },
    rotateSort() {
      return this.sort.push(this.sort.shift());
    },
  },
};
</script>

 <style lang="scss" module>
@import "../../../styles/app/variables";

.box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: $cards-background-color;
  max-height: 270px;
  padding: 10px;
  margin: 15px 0px;
  font-size: 12px;
  border: 1px solid $cards-background-border;
  border-radius: 10px;
}

.title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  font-weight: 200;
  letter-spacing: -1px;
  margin: 2px 10px 20px 10px;
}

.info-tooltip {
  margin: 0 10px;
  padding-top: 5px;
}

.legend {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &-box {
    width: 20px;
    height: 15px;
    margin: 0 5px ;
    border-radius: 5px;
  }

}

.rows {
  overflow-y: auto;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 10px 7px 10px;
}

.sticky-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: $sidebar-background;
  justify-content: flex-start;
  font-weight: bold;
}

.legends-wrapper {
  padding: 2px;
  white-space: nowrap;

  @media only screen and (max-width: 1550px) {
    flex-wrap: wrap;

    .legend {
      margin: 2px;
    }
  }
}

.total-with-legend {
  padding-bottom: 16px;
}

.legends-with-total {
  padding: 0;
  background: none;
  flex-wrap: nowrap;
}

.group-row {
  height: 20px;
  margin-bottom: 0px;
}

.object {
  width: 40vh;

  @media only screen and (max-width: $mobile-breakpoint) {
    width: 20vmin;
  }

  a {
    color: $primary_color;
    text-decoration: none;

    &:visited {
      color: $primary_color;
    }
  }
}

.value {
  text-align: center;
  width: 6vh;
  margin: 0 3px;
}

.bar {
  align-items: center;
  width: 25vh;
  margin: 0 10px;
  @media only screen and (max-width: $mobile-breakpoint) {
    width: 30vmin;
  }
}

.title-elements {
  display: flex;
  align-items: center;
}
</style>
