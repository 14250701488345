// Based on Element UI Slider
<template>
  <div :class="styleClass">
    <div
      ref="slider"
      :class="`${styleClass}__wrapper`">
      <div
        :class="[`${styleClass}__bar`, `${styleClass}__bar--top`]"
        :style="topBarStyle"
      />
      <slider-button
        ref="button1"
        v-model="topValue"
        :vertical="vertical"
        :type="type"
      />
      <div
        :class="`${styleClass}__bar`"
        :style="middleBarStyle"
      />
      <slider-button
        v-if="multipleButtons"
        ref="button2"
        v-model="bottomValue"
        :vertical="vertical"
        :type="type"
      />
      <div
        :class="[`${styleClass}__bar`, `${styleClass}__bar--bottom`]"
        :style="bottomBarStyle"
      />
    </div>
  </div>
</template>

<script>
import SliderButton from './slider-button.vue';
import { PRIMARY_COLOR, LIGHT_COLOR } from '../../utils/style-variables';

export default {
  name: 'Slider',
  components: { SliderButton },
  props: {
    min: {
      type: Number,
      default: 0,
    },
    multipleButtons: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    value: {
      type: Array,
      default() { return [40, 60]; },
    },
    debounce: {
      type: Number,
      default: 300,
    },
    colors: {
      type: Array,
      default: () => ([
        LIGHT_COLOR,
        PRIMARY_COLOR,
        LIGHT_COLOR,
      ]),
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
    },
    type: {
      type: String,
      default: 'number',
    },
  },
  data() {
    return {
      bottomValue: null,
      topValue: null,
      oldValue: null,
      dragging: false,
      sliderSize: 1,
      styleClass: `slider_${this.vertical ? 'v' : 'h'}`,
      PRIMARY_COLOR,
      LIGHT_COLOR,
    };
  },
  computed: {
    minValue() { return Math.min(this.bottomValue, this.topValue); },
    maxValue() { return Math.max(this.bottomValue, this.topValue); },
    bottomBarStyle() {
      return this.vertical ?
        {
          'background-color': this.colors[0],
          height: `${100 * (this.minValue - this.min) / (this.max - this.min)}%`,
          'border-color': this.colors[0],
        } : {
          'background-color': this.colors[0],
          width: `${100 * (this.minValue - this.min) / (this.max - this.min)}%`,
          'border-color': this.colors[0],
        };
    },
    middleBarStyle() {
      return this.vertical ?
        {
          'background-color': this.colors[1],
          height: `${100 * (this.maxValue - this.minValue) / (this.max - this.min)}%`,
          bottom: `${100 * (this.minValue - this.min) / (this.max - this.min)}%`,
          'border-color': this.colors[1],
        } : {
          'background-color': this.colors[1],
          width: `${100 * (this.maxValue - this.minValue) / (this.max - this.min)}%`,
          left: `${100 * (this.minValue - this.min) / (this.max - this.min)}%`,
          'border-color': this.colors[1],
        };
    },
    topBarStyle() {
      return this.vertical ?
        {
          'background-color': this.colors[2],
          height: `${100 * (this.max - this.maxValue) / (this.max - this.min)}%`,
          'border-color': this.colors[2],
        } : {
          'background-color': this.colors[2],
          width: `${100 * (this.max - this.maxValue) / (this.max - this.min)}%`,
          'border-color': this.colors[2],
        };
    },
    precision() {
      const precisions = [this.min, this.max, this.step].map(item => {
        const decimal = (`${item}`).split('.')[1];

        return decimal ? decimal.length : 0;
      });

      return Math.max.apply(null, precisions);
    },
  },
  watch: {
    value(val, oldVal) {
      if (this.dragging ||
          Array.isArray(val) &&
          Array.isArray(oldVal) &&
          val.every((item, index) => item === oldVal[index])) {
        return;
      }
      this.setValues();
    },
    dragging(val) {
      if (!val) {
        this.setValues();
      }
    },
    min() {
      this.setValues();
    },
    max() {
      this.setValues();
    },
    bottomValue(val) {
      this.$emit('input', [this.minValue, this.maxValue]);
    },
    topValue(val) {
      this.$emit('input', [this.minValue, this.maxValue]);
    },
  },
  mounted() {
    this.bottomValue = Math.max(this.min, this.value[0]);
    this.topValue = Math.min(this.max, this.value[1]);
    this.oldValue = [this.bottomValue, this.topValue];
    this.resetSize();
    window.addEventListener('resize', this.resetSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetSize);
  },
  methods: {
    valueChanged() {
      return ![this.minValue, this.maxValue].every((item, index) => item === this.oldValue[index]);
    },
    setPosition(percent) {
      const targetValue = this.min + percent * (this.max - this.min) / 100;
      let button;
      if (Math.abs(this.minValue - targetValue) < Math.abs(this.maxValue - targetValue)) {
        button = this.topValue < this.bottomValue ? 'button1' : 'button2';
      } else {
        button = this.topValue > this.bottomValue ? 'button1' : 'button2';
      }
      this.$refs[button].setPosition(percent);
    },
    onSliderClick(event) {
      if (this.dragging) return;
      this.resetSize();
      if (this.vertical) {
        const sliderOffsetBottom = this.$refs.slider.getBoundingClientRect().bottom;
        this.setPosition((sliderOffsetBottom - event.clientY) / this.sliderSize * 100);
      } else {
        const sliderOffsetLeft = this.$refs.slider.getBoundingClientRect().left;
        this.setPosition((event.clientX - sliderOffsetLeft) / this.sliderSize * 100);
      }
      this.emitChange();
    },
    resetSize() {
      if (this.$refs.slider) {
        this.sliderSize = this.$refs.slider[`client${this.vertical ? 'Height' : 'Width'}`];
      }
    },
    emitChange() {
      this.$nextTick(() => {
        this.$emit('change', [this.minValue, this.maxValue]);
      });
    },
    setValues() {
      [this.bottomValue, this.topValue] = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .slider_v {
    min-height: 200px;
    width: 86px;
    padding: 16px;
    padding-right: 70px;

    &__wrapper {
      position: relative;
      min-height: 100%;
    }

    &__bar {
      position: absolute;
      width: 6px;
      height: auto;

      @media only print {
        border: 3px solid;
      }

      &--top {
        border-radius: 3px 3px 0 0;
        top: 0%;
      }

      &--bottom {
        border-radius: 0 0 3px 3px;
        bottom: 0%;
      }
    }
  }

  .slider_h {
    min-width: 200px;
    height: 86px;
    padding: 16px;
    padding-top: 70px;

    &__wrapper {
      position: relative;
      width: 100%;
    }

    &__bar {
      position: absolute;
      width: auto;
      height: 6px;

      @media only print {
        border: 3px solid;
      }

      &--top {
        border-radius: 3px 0 0 3px;
        right: 0%;
      }

      &--bottom {
        border-radius: 0 3px 3px 0;
        left: 0%;
      }
    }
  }
</style>
