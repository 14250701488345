<template>
  <paywall
    :permitted="permitted"
    :free="isFreeUser"
    restricted
    transparent>
    <div
      id="downTable"
      :data-step="tutorial.step"
      :data-intro="tutorial.description"
      class="results-colormap">
      <div
        ref="autoScrollableComponent"
        class="results-colormap__color-map">
        <color-map
          :class="{ 'hide-print': !isProUser }"
          :loading="loading"
          :rows="indicators"
          :columns="yearsAsColumns"
          :results="results"
          :hide-empty-columns="true"
          :show-school-picker="false"
          :disable-school-adding="true"
          :column-header-hover="false"
          results-column-key="year"
        />
      </div>
      <share-whatsapp/>
      <template v-if="results.length !== 0">
        <color-map-footer/>
      </template>
    </div>

    <color-map
      slot="mock"
      :rows="mockHistoricalData.indicators"
      :columns="mockHistoricalData.columns"
      :results="mockHistoricalData.results"
      :results-column-key="mockHistoricalData.resultsColumnKey"
      :default-open-rows="mockHistoricalData.openIndicators"
      :disable-school-adding="true"
      class="color-map--pro"
    />
  </paywall>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ColorMapFooter from '../../components/results/color-map-footer.vue';
import AuthMixin from '../../mixins/auth';
import ForceGroupWithResultsMixin from '../../mixins/force-group-with-results';
import MockDataMixin from '../../mixins/mock-data';
import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';
import UpcomingDialogMixin from '../../mixins/upcoming-dialog';
import AutoScrollMixin from '../../mixins/auto-scroll.js';

export default {
  name: 'SchoolHistoricalView',
  components: { ColorMapFooter },
  mixins: [
    AuthMixin,
    ForceGroupWithResultsMixin,
    MockDataMixin,
    PermissionMixin,
    ResultsMixin,
    UpcomingDialogMixin,
    AutoScrollMixin,
  ],
  computed: {
    ...mapState('results', {
      loading: state => state.loading,
      isGroupRestrictedToLoggedUsers(state) {
        if (this.chosenGroup) {
          const reportGroup = state.reportGroups
            .find(item => item.group_id === this.chosenGroup.id);

          return reportGroup && reportGroup.restricted_to_logged_users;
        }

        return false;
      },
      yearsAsColumns(state) {
        return state.results ?
          state.results.years.map(year => ({
            id: year,
            label: year,
          })) :
          [];
      },
    }),
    ...mapGetters('intro', ['tutorialInfo']),
    ...mapGetters('results', {
      results: 'results',
      indicators: 'resultsIndicators',
    }),
    permitted() {
      return (
        this.isLoggedIn ||
        !this.isGroupRestrictedToLoggedUsers ||
        this.hasSchoolPublicResultsPermission
      );
    },
    tutorial() {
      return this.tutorialInfo(['schoolHistorical']);
    },
  },
  updated() {
    this.refreshIntro();
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    ...mapActions('intro', { refreshIntro: 'refresh', startIntro: 'start' }),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'group_ids': true });
      this.setReportKey('color-map');
    },
  },
};
</script>
