<template>
  <paywall
    :permitted="permitted"
    :free="isFreeUser"
    restricted
    transparent>
    <div
      id="downTable"
      :data-step="tutorial.step"
      :data-intro="tutorial.description"
      class="results-colormap">
      <error-message v-if="error"/>
      <div
        v-else
        class="results-colormap__color-map">
        <color-map
          :class="{ 'hide-print': !isProUser }"
          :loading="loading"
          :results="results"
          :rows="indicators"
          :columns="schoolsAsColumns"
          :show-school-picker="true"
          :comparative-public-results-view="true"
          :restricted-school-picker="!isLoggedIn"
          column-sort="average_ascending"
          results-column-key="school_id"
        />
      </div>
      <share-whatsapp/>

      <template v-if="results.length !== 0">
        <color-map-footer/>
      </template>
    </div>
    <color-map
      slot="mock"
      :rows="mockColorMapData.indicators"
      :columns="mockColorMapData.columns"
      :results="mockColorMapData.results"
      :results-column-key="mockColorMapData.resultsColumnKey"
      :default-open-rows="mockColorMapData.openIndicators"
      :disable-school-adding="true"
      class="color-map--pro"
    />
  </paywall>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../components/results/color-map-footer.vue';
import AuthMixin from '../../mixins/auth';
import ForceGroupWithResultsMixin from '../../mixins/force-group-with-results';
import MockDataMixin from '../../mixins/mock-data';
import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';
import TooltipInfoMixin from '../../mixins/tooltip-info';

export default {
  name: 'ComparativeColorMapView',
  components: { ColorMapFooter },
  mixins: [
    AuthMixin,
    ForceGroupWithResultsMixin,
    MockDataMixin,
    PermissionMixin,
    ResultsMixin,
    TooltipInfoMixin,
  ],
  computed: {
    ...mapState('results', ['resultGroups', 'loading']),
    ...mapState('results', { isGroupRestrictedToLoggedUsers(state) {
      if (this.chosenGroup) {
        const reportGroup = state.reportGroups
          .find(item => item.group_id === this.chosenGroup.id);
        return reportGroup && reportGroup.restricted_to_logged_users;
      }
      return false;
    } }),
    ...mapState('options', ['chosenSchoolIds']),
    ...mapGetters('results', {
      indicators: 'resultsIndicators',
      results: 'results',
    }),
    ...mapGetters('options', ['chosenSchools']),
    ...mapGetters('intro', ['tutorialInfo']),
    ...mapGetters('auth', ['userSchoolIds']),
    orderedSchools() {
      const getSchoolAverageScore = (school) => {
        if (this.results) {
          const sum = this.results.filter(result => result.school_id === school.id)
            .reduce((partial, result) => partial + result.norm_value, 0);

          return sum / this.indicators.length;
        }

        return 0;
      };

      return this.chosenSchools.slice().sort((a, b) => {
        const averageA = getSchoolAverageScore(a);
        const averageB = getSchoolAverageScore(b);

        return averageA - averageB;
      });
    },
    schoolsAsColumns() {
      if (this.orderedSchools) {
        return this.orderedSchools.map(school => (school ? {
          ...this.schoolData(school),
          active: this.isLoggedIn ? this.userSchoolIds.indexOf(school.id) !== -1 : school.id === this.chosenSchools[0].id,
        } : {}));
      }

      return [];
    },
    permitted() {
      return (this.isLoggedIn || !this.isGroupRestrictedToLoggedUsers);
    },
    restrictedSchoolPicker() {
      return this.$store.state.signUp.showSignUpDialog;
    },
    tutorial() {
      return this.tutorialInfo(['colorMapComparative']);
    },
  },
  async created() {
    try {
      await this.searchDefaultGroupAndYear(this.chosenSchoolIds, 'color-map');
    } catch (_) {
      this.error = true;
    }
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'chosen_school_ids': true, 'group_ids': true, 'years': true });
      this.setReportKey('color-map');
    },
  },
};
</script>

<style lang="scss">
  .results-colormap {
    // position: relative;
  }
</style>
