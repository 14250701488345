import { mapGetters } from 'vuex';
import ToLocalTimeMixin from './to-local-time';

export default {
  mixins: [ToLocalTimeMixin],
  computed: {
    ...mapGetters('results', ['results']),
    lastUpdated() {
      if (this.results.length === 0) return null;
      let latestImport = new Date(0);
      this.results.forEach((result) => {
        const resultDate = new Date(result.imported_at);
        if (!latestImport || resultDate > latestImport) latestImport = resultDate;
      });
      return this.toLocal(latestImport, 'DD/MM/YYYY');
    },
  },
};
