<template>
  <div class="report">
    <alert-info :text-alert="textData" :icon-viwe="false"/>
    <div class="rdr-view school-view">
      <div
        v-if="isLoggedIn"
        class="options">
        <print-button
          :loading="loading"
          primary/>
      </div>
      <rdr-results-header
        :tabs="tabs"
        :year-picker="isColorMap"
        :group-picker="isHistorical"
        :show-school-selector="isLoggedIn"
        hide-school-picker>
        <!-- <div
          v-if="isColorMap"
          class="results-header__subtitle">Este mapa de color muestra los resultados SIMCE, PSU y otros indicadores de tu colegio para cada uno de sus cursos.</div>
        <div
          v-if="isHistorical"
          class="results-header__subtitle">Este mapa de color muestra los resultados SIMCE, PSU y otros indicadores de un curso a lo largo de los años.</div>
        <div
          v-if="isLearningLevels"
          class="results-header__subtitle">Este gráfico muestra los niveles de aprendizaje.</div> -->
      </rdr-results-header> 
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResultsHeader from '../../components/results/results-header.vue';
import PrintButton from '../../components/utils/print-button.vue';
import AuthMixin from '../../mixins/auth';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'PublicEvaluationsSchoolView',
  components: {
    'rdr-results-header': ResultsHeader,
    PrintButton,
    AlertInfo,
  },
  mixins: [AuthMixin],
  data() {
    return {
      windowWidth: 0,
      textData: '',
    };
  },
  computed: {
    ...mapState('results', {
      loading: state => !!state.loading,
    }),
    isLoggedIn() { return this.$store.state.auth.isLoggedIn; },
    tabs() {
      return [
        { icon: 'today', name: 'Anual', link: this.colorMapLink },
        { icon: 'show_chart', name: 'Histórico', link: this.historicalLink },
        { icon: 'star', name: 'Niveles de Aprendizaje', link: this.learningLevelsLink },
      ];
    },
    colorMapLink() {
      return { name: 'schoolColorMap', params: { year: null, school: null, ...this.$route.params } };
    },
    historicalLink() {
      return { name: 'schoolHistorical', params: { group: null, school: null, ...this.$route.params } };
    },
    learningLevelsLink() {
      return { name: 'learningLevels', params: { school: null, ...this.$route.params } };
    },
    isColorMap() {
      this.textData = 'Este mapa de color muestra los datos públicos y otros indicadores de tu colegio para cada uno de sus cursos.';
      return this.$route.name === 'schoolColorMap';
    },
    isHistorical() {
      this.textData = 'Este mapa de color muestra los datos públicos y otros indicadores de un curso a lo largo de los años.';
      return this.$route.name === 'schoolHistorical';
    },
    isLearningLevels() {
      this.textData = 'Este gráfico muestra los niveles de aprendizaje.';
      return this.$route.name === 'learningLevels';
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/app/variables";

.school-view .options {
  // display: flex;
  // justify-content: flex-end;
  // margin-bottom: 20px;
  position: relative;
  float: right;
  margin-right: 35px;
  margin-top: -9px;
}
</style>
