<template>
  <div>
    <button
      :class="[
        'rdr-btn',
        { 'rdr-btn--primary': primary },
        'hide-print',
        'hide-mobile'
      ]"
      :disabled="dialogVisible"
      @click.prevent="showDialog">
      <i class="rdr-btn__icon material-icons">print</i>
      <span> {{ buttonTitle || title }}</span>
    </button>
    <rdr-dialog
      :visible.sync="dialogVisible"
      :custom-classes="[$style.dialog]"
      append-to-body
      @close="dialogVisible = false">
      <div :class="$style.tutorialDialogMenu">
        <h1 :class="$style.title"> {{ title }} </h1>
        <h2> {{ body }} </h2>
        <h3
          v-if="subtitle"
          :class="$style.subtitle"
        > {{ subtitle }} </h3>
        <div>
          <img
            :class="$style.image"
            :src="imageSrc" >
        </div>
      </div>
    </rdr-dialog>
  </div>
</template>

<script>

import RdrDialog from '../../components/base/dialog.vue';

export default {
  name: 'TutorialGif',
  components: { RdrDialog },
  props: {
    title: {
      type: String,
      default: 'Tutorial',
      required: false,
    },
    imageSrc: {
      type: String,
      default: '',
      required: false,
    },
    body: {
      type: String,
      default: '',
      required: false,
    },
    subtitle: {
      type: String,
      default: null,
      required: false,
    },
    primary: {
      type: Boolean,
      default: true,
      required: false,
    },
    buttonTitle: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
  },

};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";

  .dialog {
    width: 850px;
  }

  .tutorial-dialog-menu {
    text-align: center;
    word-break: keep-all;

    .title {
      font-size: 24px;
      font-weight: 300;
    }

    .subtitle {
      padding-top: 50px;
      font-size: 24px;
      font-weight: 300;
    }
  }

  .image {
    max-width: 800px;
    max-height: 700px;
  }
</style>
