/* eslint-disable camelcase */
import Vue from 'vue';

export default {
  getActions(params) {
    return Vue.http.get(
      '/api/v1/pme/actions.json',
      { params },
    );
  },
  getOptions(schoolId) {
    return Vue.http.get(
      '/api/v1/pme/actions/options.json',
      { params: { school_ids: schoolId } },
    );
  },
  getActionSummary(params) {
    return Vue.http.get(
      '/api/v1/pme/actions/summary.json',
      { params },
    );
  },

};
