<template>
  <line-chart
    :width="width"
    :height="height"
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import LineChart from './base/line-chart.vue';
import { PRIMARY_COLOR, RED_COLOR, DARK_GRAY_COLOR } from '../utils/style-variables';

export default {
  name: 'GradesChart',
  components: { LineChart },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    yAxisMin: {
      type: Number,
      default: 2,
    },
    chartRawData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartData() {
      const studentGradesColors = this.chartRawData.studentGrades.map(x => this.gradeColor(x));
      return {
        labels: this.chartRawData.years,
        datasets: [
          {
            label: 'Estudiante',
            data: this.chartRawData.studentGrades,
            backgroundColor: studentGradesColors,
            fill: false,
            lineTension: 0,
            borderColor: PRIMARY_COLOR,
            borderWidth: 1,
          },
          {
            label: 'Curso',
            data: this.chartRawData.groupGrades,
            backgroundColor: DARK_GRAY_COLOR,
            fill: false,
            lineTension: 0,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'top',
        },
        tooltips: {
          mode: 'index',
          intersect: true,
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString('es-CL', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
              return label;
            },
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              max: 7,
              min: this.yAxisMin,
              stepSize: 1,
              callback: (value) => this.$options.filters.numberWithOneDecimal(value),
            },
          }],
          xAxes: [{
            offset: true,
            gridLines: {
              display: false,
            },
          }],
        },
        ...this.options,
      };
    },
  },
  methods: {
    gradeColor(grade) {
      return grade < 4 ? RED_COLOR : PRIMARY_COLOR; // eslint-disable-line no-magic-numbers
    },
  },
};
</script>
