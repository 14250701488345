<template>
  <div>
    <div v-if="isLoggedIn && hasInternalEvaluationsPermission">
      <div id="downTable" class="results-colormap">
        <div class="results-colormap__color-map">
          <color-map
            :loading="loading"
            :rows="groupsAsRows"
            :columns="subjectsAsColumns"
            :results="results"
            :hide-empty-columns="true"
            :show-school-picker="false"
            :disable-school-adding="true"
            :round="true"
            :percentage="true"
            :column-header-hover="false"
            results-row-key="group_id"
            column-sort="average_ascending"
          />
        </div>
        <share-whatsapp/>
        <template v-if="results.length !== 0">
          <color-map-footer
            :footer-bottom-paragraphs="footerParagraphs"
            text="La gama de colores representa el nivel de logro en cada prueba de la siguiente forma:"
            high="Alto"
            average="Medio"
            low="Bajo"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import AuthMixin from '../../../mixins/auth';
import MockDataMixin from '../../../mixins/mock-data';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';

export default {
  name: 'InternalEvaluationsLevelTestsBySchoolView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [],
    };
  },
  computed: {
    ...mapState({
      loading: state => state.results.loading,
    }),
    ...mapGetters({
      groups: 'options/groups',
      evaluations: 'results/resultsEvaluations',
      indicators: 'results/resultsIndicators',
    }),
    results() {
      return this.$store.getters['results/results'].map(result => {
        const indicator = this.indicators.find(i => i.id === result.indicator_id); // eslint-disable-line camelcase
        return { ...result, column_id: indicator.id }; // eslint-disable-line camelcase
      });
    },
    groupsAsRows() {
      return this.groups.map(group => ({
        id: group.id,
        key: group.key,
        label: group.short_name, // eslint-disable-line camelcase
        parent_id: group.parent_id, // eslint-disable-line camelcase
      }));
    },
    subjectsAsColumns() {
      const columns = [];
      this.indicators.forEach(indicator => {
        const newColumn = {
          id: indicator.id,
          label: indicator.label,
          format: indicator.format,
        };
        const columnExists = columns.find(column => column.id === newColumn.id);
        if (!columnExists) columns.push(newColumn);
      });
      return columns;
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    login() {
      this.$store.dispatch('ui/openLoginDialog', true);
    },
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true, 'months': true });
      this.setReportKey('pdn-group-average');
    },
  },
};
</script>
