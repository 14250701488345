<template>
  <el-dialog
    v-bind="$attrs"
    :custom-class="`${$style.dialog} ${customClasses.join(' ')}`"
    v-on="$listeners">
    <slot
      slot="title"
      :class="$style.title"
      name="title"/>
    <slot/>
    <slot
      slot="footer"
      name="footer"/>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';

export default {
  name: 'RdrDialog',
  components: { 'el-dialog': Dialog },
  inheritAttrs: false,
  props: {
    customClasses: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.dialog {
  background-color: #FCFCFC;

  @media only screen and (max-width: #{$mobile-breakpoint}) {
    width: 90%;
  }

  a {
    color: $primary-color;
    text-decoration: none;
    border-bottom: 1px dashed;
  }
}

:global(.el-dialog__body) {
  padding: 40px 30px;
  word-break: normal;
  overflow: scroll;
}

:global(.el-dialog__header) {
  margin-top: 20px;
}

.title {
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}
</style>
