<template>
  <paywall
    :permitted="isLoggedIn && hasAttendancePermission"
    :free="isFreeUser"
    restricted
    transparent>
    <div id="downTable" class="results-colormap">
      <!-- TIPO === {{ typeAtt }} -->
      <div class="results-colormap__color-map">
        <color-map
          :loading="loading"
          :rows="groupsAsRows"
          :columns="monthsAsColumns"
          :results="results"
          :hide-empty-columns="true"
          :show-school-picker="false"
          :disable-school-adding="true"
          :percentage="true"
          :floor="true"
          :attendance="true"
          :column-header-hover="false"
          results-row-key="group_id"
        />
      </div>
      <share-whatsapp/>
      <template v-if="results.length !== 0">
        <color-map-footer
          :footer-bottom-paragraphs="footerParagraphs"
          text="La gama de colores representa el porcentaje de asistencia de la siguiente forma:"
          high="100%"
          average="90%"
          low="80% o menos"/>
      </template>
    </div>
  </paywall>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';

import { MONTHS, offsetMonth } from '../../../utils/months';

export default {
  name: 'AttendancePercentageBySchoolView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [
        'Los cálculos de asistencia del año en curso se obtienen a partir de la información del sistema SIGE de cada establecimiento.',
        'Los colores son referenciales para cada concepto y no necesariamente representan las metas internas de cada proyecto educativo.',
      ],
    };
  },
  props: {
    typeAtt: {
      type: String,
      default: '2',
    }
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('options', ['groups']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      resultsGetter: 'results',
    }),
    results() {
      return this.resultsGetter.map(result => {
        const evaluation = this.evaluations.find(e => e.id === result.evaluation_id);

        return { ...result, column_id: `${evaluation.year}_${offsetMonth(evaluation.month)}` }; // eslint-disable-line camelcase
      });
    },
    groupsAsRows() {
      return this.groups.map(group => ({
        id: group.id,
        key: group.key,
        label: group.short_name,
        parent_id: group.parent_id, // eslint-disable-line camelcase
      }));
    },
    monthsAsColumns() {
      const columns = [];

      this.evaluations.forEach(evaluation => {
        const newColumn = {
          id: `${evaluation.year}_${offsetMonth(evaluation.month)}`,
          label: evaluation.month === 0 ?
            `Anual acumulada ${evaluation.year}` :
            `${MONTHS[evaluation.month - 1]} ${evaluation.year}`,
        };
        const columnExists = columns.find(column => column.id === newColumn.id);

        if (!columnExists) columns.push(newColumn);
      });

      const sortedColumns = columns.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;

        return 0;
      });
      if (sortedColumns.length > 0 && sortedColumns[0].label.includes('Anual acumulada')) {
        const yearlyAttendanceColumn = sortedColumns.shift();
        yearlyAttendanceColumn.highlighted = true;

        return sortedColumns.concat(yearlyAttendanceColumn);
      }

      return sortedColumns;
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true });
      this.setReportKey('attendance-aggregate-by-month-day');
    },
  },
};
</script>
