<template>
  <div class="report">
    <template v-if="routeMatches('gradesGroupAverageBySchool')">
      <alert-info :text-alert="textInfo" :icon-viwe="false"/>
    </template>
    <template v-if="routeMatches('gradesGroupAverageBySchool')">
      <alert-info :text-alert="textInfo2" :icon-viwe="false"/>
    </template>
    <div class="rdr-view grades-view">
      <rdr-results-header
        :tabs="[
          {
            name: 'Promedios de cursos',
            link: gradesGroupAverageBySchoolLink
          },
          {
            name: 'Cantidad de calificaciones',
            link: gradesTestCountBySchoolLink
          },
        ]"
        :show-school-selector="isLoggedIn"
        :is-months="false"
        year-picker
        month-picker
        hide-school-picker
        show-school-picker
        show-school-year-picker
        show-planned-term-picker>
        <!-- <span
          v-if="routeMatches('gradesGroupAverageBySchool')"
          class="results-header__subtitle">
          Este mapa de color muestra el promedio por curso para cada asignatura en el año seleccionado.
        </span>
        <span
          v-else-if="routeMatches('gradesTestCountBySchool')"
          class="results-header__subtitle">
          Esta tabla muestra la cantidad de evaluaciones con notas por curso para cada asignatura en el año seleccionado.
        </span> -->
        <br>
        <span class="results-header__subtitle">
          Última actualización: {{ lastUpdated || '' }}
        </span>
      </rdr-results-header>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ResultsHeader from '../../components/results/results-header.vue';
import AuthMixin from '../../mixins/auth';
import LinksMixin from '../../mixins/links';
import LastUpdated from '../../mixins/last-updated';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'GradesBySchoolView',
  components: {
    'rdr-results-header': ResultsHeader,
    AlertInfo,
  },
  mixins: [AuthMixin, LinksMixin, LastUpdated],
  data() {
    return {
      textInfo: 'Este mapa de color muestra el promedio por curso para cada asignatura en el año seleccionado.',
      textInfo2: 'Esta tabla muestra la cantidad de evaluaciones con calificaciones por curso para cada asignatura en el año seleccionado.',
    };
  },
  computed: {
    ...mapState('options', ['months']),
  },
  watch: {
    months(newValue, oldValue) {
      if (!oldValue || !newValue.every(m => oldValue.includes(m))) {
        this.setChosenMonth(0);
      }
    },
  },
  methods: {
    ...mapActions('options', ['setChosenMonth']),
  },
};
</script>
