<template>
  <div>
    <table
      :class="customClass === null ? $style.RdrTable : customClass">
      <thead>
        <tr>
          <th
            v-if="showRowIndex || showRowIndexNew !== 0">
            #
          </th>
          <th
            v-for="column in visibleColumns"
            :key="column.key"
            :style="{ top: `${stickyTopOffset}px`, minWidth: `${column.minWidth}px` }"
            :class="{
              [$style.click]: column.click,
              [$style.visibilityTogglerHeader]: column.key==='visibilityToggler',
              [$style.displayedHiddenHeader]: isHiddenColumn(column),
              [$style.stickyColumnHeader]: isStickyColumn(column),
              [$style.stickyColumn]: isStickyColumn(column),
              [$style.stickyColumnTwoHeader]: isStickyColumnTwo(column),
              [$style.stickyColumnTwo]: isStickyColumnTwo(column)
            }"
            @click="column.click ? column.click(column) : () => {}">
            <rdr-tooltip
              v-if="column.key==='visibilityToggler'"
              placement="bottom">
              <div>{{ columnsAreHidden ? 'Mostrar más columnas' : 'Ocultar columnas' }}</div>
              <div
                slot="reference"
                :class="[extendedHeaderClass]"
                @click="toggleVisibility">
                <i :class="columnsAreHidden ? 'clg-arrow-nav-right' : 'clg-arrow-nav-left'"/>
              </div>
            </rdr-tooltip>
            <div
              v-else
              :class="[
                extendedHeaderClass,
                $style.headerCell,
            ]">
              <rdr-tooltip
                :class="[textAlignStyle(column)]"
                :disabled="column.label.length < 20 && !column.headerHover">
                <div :class="$style.headerTooltip">{{ column.headerHover ? column.headerHover : headerCustomFilters(column)(column.label) }}</div>
                <div
                  slot="reference"
                  :class="$style.headerCellLabel">{{ headerCustomFilters(column)(column.label) }}</div>
              </rdr-tooltip>
              <sort-button
                v-if="column.sortFunc"
                :order="sortedColumnKey === column.key ? sortOrder : null"
                @click="() => sortColumn(column)"/>
            </div>
          </th>
        </tr>
      </thead>
      <sort-rows tag="tbody">
        <tr
          v-for="(row, index) in visibleRows"
          :key="row[dataKey]"
          :style="rowStyle(row)"
          :class="{ [$style.highlight]: row.highlight }"
        >
          <td
            v-if="showRowIndex">
            <div :class="$style.center">
              {{ visibleRows.indexOf(row) + 1 }}
            </div>
          </td>
          <td
            v-if="showRowIndexNew !== 0">
            <div :class="$style.center">
              {{ numRow + index }}
            </div>
          </td>
          <td
            v-for="(column, colIndex) in visibleColumns"
            :key="`${row[dataKey]}-${column.key}`"
            :class="{
              [$style.click]: click !== null,
              [$style.visibilityTogglerBox]: column.key==='visibilityToggler',
              [$style.displayedHiddenColumnBox]: isHiddenColumn(column),
              [$style.stickyColumn]: isStickyColumn(column),
              [$style.stickyColumnTwo]: isStickyColumnTwo(column)
            }"
            @click="click && click(row)">
            <div
              :style="cellContainerStyle(row[dataKey], colIndex)"
              :class="[
                $style.cell,
                extendedCellClass,
            ]">
              <i
                v-if="colIndex === 0 && rowHasChildren(row[dataKey])"
                :class="[
                  $style.toggleIcon,
                  'material-icons',
                  isRowOpen(row[dataKey]) ? 'down-arrow' : 'right-arrow',
                  'hide-print'
                ]"
                @click.stop="toggleRow(row[dataKey])"
              >
                {{ isRowOpen(row[dataKey]) ? 'remove' : 'add' }}
              </i>
              <div v-if="!undefinedOrNull(column.pro)">
                <rdr-tooltip>
                  {{ column.pro }}
                  <i
                    slot="reference"
                    class="material-icons material-icons-light-grey">
                    help
                  </i>
                </rdr-tooltip>
              </div>
              <span v-else-if="column.key==='visibilityToggler'"/>
              <span v-else-if="hideValue(row, column)">—</span>
              <rdr-tooltip
                v-else-if="column.type && column.type === 'progress-bar'"
                :disabled="!(column.options && column.options.tooltip)">
                <div v-if="column.options && column.options.tooltip">
                  {{ row[column.options.tooltip] }}
                </div>
                <span
                  slot="reference"
                  :class="$style.progressBarContainer">
                  <progress-bar
                    :class="$style.progressBar"
                    :height="18"
                    :round="9"
                    :progress="row[column.key]"
                    :text-inside="column.options && column.options.textInside"
                  />
                  <span
                    v-if="column.options && column.options.textAfter"
                    :class="$style.progressBarTextAfter">
                    {{ row[column.options.textAfter] }}
                  </span>
                </span>
              </rdr-tooltip>
              <rdr-tooltip
                v-else-if="column.type && column.type === 'data-box'"
                :disabled="disableRowHover(row, column)"
                trigger="hover"
                placement="right">
                <span
                  slot="reference"
                >
                  <data-box
                    :colors="column.colors"
                    :value="row[column.key]"
                    :norm-value="column.norm ? row[column.norm] : null"
                    :text="row[column.text]"
                    :format="column.format"
                    :max-value="column.maxValue"
                    :min-value="column.minValue"
                    :icon="column.icon"
                    :background-type="column.backgroundType"
                    :discrete-colors="column.discreteColors"
                    :color-index-value="column.getColorIndexValue ? column.getColorIndexValue(row) : null"
                  />
                </span>
                <div
                  v-if="column.hover"
                  v-html="column.hoverShowValue(row)"/>
              </rdr-tooltip>
              <span
                v-else-if="column.type && column.type === 'boolean'">
                {{ row[column.key] ? 'Sí' : 'No' }}
              </span>
              <router-link
                v-else-if="column.type && column.type === 'link' && row.link"
                :to="row.link">
                <span>{{ customFilters(column)(row[column.key]) }}</span>
              </router-link>
              <div
                v-else
                :class="[
                  $style.cellLabel,
                  textAlignStyle(column)
              ]">
                <rdr-tooltip
                  v-if="row.tooltip"
                  :disabled="row[column.key] === '-'">
                  <div v-html="row.tooltip"/>
                  <span slot="reference">{{ shortenedColumnLabel(customFilters(column)(row[column.key])) }}</span>
                </rdr-tooltip>
                <rdr-tooltip
                  v-else
                  :disabled="customFilters(column)(row[column.key]).toString().length < maxLabelLength">
                  <div v-html="customFilters(column)(row[column.key])"/>
                  <span
                    slot="reference">
                    {{ shortenedColumnLabel(customFilters(column)(row[column.key])) }}
                  </span>
                </rdr-tooltip>
              </div>
              <span
                v-if="column.infoTooltip && row[column.infoTooltip]"
                :class="$style.infoTooltip">
                <info-icon
                  :text="row[column.infoTooltip]"/>
              </span>
              <span
                v-if="column.warning && column.warningFunction(row)"
                :class="$style.infoTooltip">
                <info-icon
                  :text="column.infoWarning"
                  icon="error"/>
              </span>
            </div>
          </td>
        </tr>
      </sort-rows>
    </table>

    <div v-if="rows.length === 0" :class="$style.cell">
      <img src="/noResultados.svg">
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp';
import ProgressBar from './progress-bar.vue';
import DataBox from '../utils/data-box';
import SortRows from '../transition-groups/sort-rows.vue';
import SortButton from '../sort-button.vue';
import InfoIcon from '../info-icon.vue';
import { PRIMARY_COLOR } from '../../utils/style-variables';
import orderedArrayByIndexToHash from '../../utils/ordered-array-to-hash';

const COLUMN_REQUIRED_KEYS = [
  'key',
  'label',
];

const SORT_FACTOR = {
  asc: 1,
  desc: -1,
};

const BASE_COLOR = 0xFEFEFE;
const COLOR_DEGRADATION = 0x010101;
const IDENT = 30;

export default {
  name: 'RdrTable',
  components: {
    ProgressBar,
    DataBox,
    InfoIcon,
    SortButton,
    SortRows,
    VClamp,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      validator: (columns) => columns.every((column) => COLUMN_REQUIRED_KEYS.every(key => Object.keys(column).includes(key))),
    },
    data: {
      type: Array,
      default: () => [],
    },
    rawDataGroups: {
      type: Array,
      default: () => [],
    },
    dataKey: {
      type: String,
      default: 'id',
    },
    hover: {
      type: Boolean,
      default: false,
    },
    hoverTooltipMessage: {
      type: Function,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    extendedHeaderClass: {
      type: String,
      default: '',
    },
    extendedCellClass: {
      type: String,
      default: '',
    },
    click: {
      type: Function,
      default: null,
    },
    headerClick: {
      type: Function,
      default: null,
    },
    emptyText: {
      type: String,
      default: 'No hay datos.',
    },
    stickyTopOffset: {
      type: Number,
      default: 0,
    },
    hiddenColumnsCount: {
      type: Number,
      default: 0,
    },
    maxLabelLength: {
      type: Number,
      default: 999,
    },
    orderArray: {
      type: Array,
      default: () => [],
    },
    showRowIndex: {
      type: Boolean,
      default: false,
    },
    showRowIndexNew: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      PRIMARY_COLOR,
      sortedColumnKey: null,
      sortOrder: null,
      columnsAreHidden: true,
      openedRows: {},
      numRow: 0,
    };
  },
  computed: {
    hasGroupedRows() {
      return this.rawDataGroups.length > 0;
    },
    visibleRows() {
      return this.hasGroupedRows ?
        this.sortedRowGroup(this.dataGroups)
          .map(r => this.getRowGroupVisibleRows(r))
          .flat(Infinity) :
        this.rows;
    },
    rows() {
      if(this.showRowIndexNew !== 0) this.numRow = this.showRowIndexNew;
      if (this.sortedColumnKey && this.visibleColumns) {
        const sortedColumn = this.visibleColumns.find(c => c.key === this.sortedColumnKey);
        const { sortFunc, orderArray } = sortedColumn;
        if (sortFunc) {
          const data = this.data.slice();
          const sortKey = sortedColumn.sortKey || this.sortedColumnKey;
          const arrayHashedIndexes = orderArray ? orderedArrayByIndexToHash(orderArray) : {};

          return data.sort((a, b) => {
            const aValueToCompare = orderArray ? arrayHashedIndexes[a[sortKey]] : a[sortKey];
            const bValueToCompare = orderArray ? arrayHashedIndexes[b[sortKey]] : b[sortKey];
            if (a.first || this.undefinedOrNull(bValueToCompare)) return -1;
            if (b.first || this.undefinedOrNull(aValueToCompare)) return 1;
            if (this.undefinedOrNull(aValueToCompare) && this.undefinedOrNull(bValueToCompare)) return 0;

            return SORT_FACTOR[this.sortOrder] * sortFunc(aValueToCompare, bValueToCompare);
          });
        }
      }
      return this.data;
    },
    rowsSchema() {
      return Object.fromEntries(
        this.rows.map((row, index) => [row[this.dataKey], { position: index, row }])
      );
    },
    enableHideColumns() {
      return this.hiddenColumnsCount > 0;
    },
    visibleColumns() {
      let columns = this.columns.slice();
      if (this.enableHideColumns && this.columnsAreHidden) columns = columns.slice(0, columns.length - this.hiddenColumnsCount);
      if (this.enableHideColumns) columns = [...columns, { key: 'visibilityToggler' }];
      return columns;
    },
    dataGroups() {
      if (!this.hasGroupedRows) return [];
      const keysInDataGroups = this.rawDataGroups.map(g => this.getRowGroupKeys(g)).flat(Infinity);
      return this.rawDataGroups.concat(
        Object.keys(this.rowsSchema).map(k => +k)
          .filter(k => !keysInDataGroups.includes(k))
          .map(key => ({ key }))
      );
    },
    rowDepths() {
      return Object.fromEntries(
        this.hasGroupedRows ?
          this.dataGroups.map(g => this.getRowGroupDepths(g)).flat(1) :
          Object.keys(this.rowsSchema).map(r => [r[this.dataKey], 0])
      );
    },
    rowChildrenData() {
      return this.hasGroupedRows ?
        Object.fromEntries(this.dataGroups.map(g => this.getRowGroupChildren(g)).flat(1)) : {};
    },
    maxRowDepth() {
      return Math.max(...Object.values(this.rowDepths));
    },
  },
  methods: {
    hideValue(row, column) {
      return this.undefinedOrNull(row[column.key]);
    },
    undefinedOrNull(value) {
      return value === null || value === undefined;
    },
    headerCustomFilters(column) {
      return (label) => {
        if (!column.headerFilters) return label;

        return column.headerFilters.reduce((accumulator, filterName) =>
          this.$options.filters[filterName](accumulator), label,
        );
      };
    },
    customFilters(column) {
      return (label) => {
        if (!column.filters) return label;

        return column.filters.reduce((accumulator, filterName) =>
          this.$options.filters[filterName](accumulator), label,
        );
      };
    },
    shortenedColumnLabel(label) {
      let shortenedLabel = '';
      if (typeof label === 'number') return label;
      const splitLabel = label.split(' ');
      for (let i = 0; i < splitLabel.length; i++) {
        if (shortenedLabel.length + splitLabel[i].length + 1 > this.maxLabelLength) {
          return `${shortenedLabel}...`;
        }
        shortenedLabel = [shortenedLabel, splitLabel[i]].join(' ');
      }
      return label;
    },
    sortColumn(column) {
      if (column.key === this.sortedColumnKey) {
        if (this.sortOrder === 'desc') {
          this.sortOrder = 'asc';
        } else if (this.sortOrder === 'asc') {
          this.sortOrder = null;
          this.sortedColumnKey = null;
        }
      } else {
        this.sortedColumnKey = column.key;
        this.sortOrder = 'desc';
      }
    },
    toggleVisibility() {
      this.columnsAreHidden = !this.columnsAreHidden;
    },
    isHiddenColumn(column) {
      return this.enableHideColumns && this.columns.indexOf(column) >= this.columns.length - this.hiddenColumnsCount;
    },
    isStickyColumn(column) {
      return column.sticky;
    },
    isStickyColumnTwo(column) {
      if( column.hasOwnProperty('stickyTwo') ){
        return column.stickyTwo;
      } else {
        return false;
      }
    },
    disableRowHover(row, column) {
      return !column.hover || (column.hover && !column.hoverCheckFunction(row));
    },
    textAlignStyle(column) {
      return {
        [this.$style.left]: column.textAlign && column.textAlign === 'left',
        [this.$style.right]: column.textAlign && column.textAlign === 'right',
        [this.$style.center]: column.textAlign && column.textAlign === 'center',
      };
    },
    sortedRowGroup(group) {
      return group.sort(({ key: aKey }, { key: bKey }) => (
        this.rowsSchema[aKey].position - this.rowsSchema[bKey].position
      ));
    },
    rowHasChildren(rowKey) {
      return this.hasGroupedRows && this.rowChildrenData[rowKey];
    },
    isRowOpen(key) {
      return this.openedRows[key];
    },
    toggleRow(key) {
      this.openedRows = { ...this.openedRows, [key]: !this.openedRows[key] };
    },
    getRowGroupDepths({ key, children }, depth = 0) {
      return [[key, depth]].concat(
        children ? children.map(g => this.getRowGroupDepths(g, depth + 1)).flat(1) : []
      );
    },
    getRowGroupVisibleRows({ key, children }) {
      return [this.rowsSchema[key].row].concat(
        children && this.openedRows[key] ?
          this.sortedRowGroup(children).map(g => this.getRowGroupVisibleRows(g)) : []
      );
    },
    getRowGroupKeys({ key, children }) {
      return [key].concat(children ? children.map(g => this.getRowGroupKeys(g)) : []);
    },
    getRowGroupChildren({ key, children }) {
      return [[key, children && children.length > 0]]
        .concat(children ? children.map(g => this.getRowGroupChildren(g)).flat(1) : []);
    },
    rowStyle(row) {
      const style = {};
      if (this.hasGroupedRows) {
        const depth = this.rowDepths[row[this.dataKey]];
        // eslint-disable-next-line no-magic-numbers
        style['background-color'] = `#${(BASE_COLOR - (COLOR_DEGRADATION * depth)).toString(16)}`;
      }
      return style;
    },
    cellContainerStyle(rowKey, columnIndex) {
      const style = {};
      if (this.hasGroupedRows && columnIndex === 0 && this.rowDepths[rowKey] !== 0) {
        style['padding-left'] = `${this.rowDepths[rowKey] * IDENT}px`;
      }
      return style;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
$highlight-border: 3px double #e9ebf9;
$border-radius: 12px;
$show-more-width: 40px;
$hidden-column-color: #F5F5F6;

.sticky-column {
  position: sticky;
  left: 0px;
  z-index: 301;
  background-color: #FBFBFC;

  &-header {
    z-index: 304 !important;
    background-color: #F7F7F7 !important;
  }
}

.sticky-column-two {
  position: sticky;
  left: 75px;
  z-index: 301;
  background-color: #FBFBFC;

  &-header {
    z-index: 304 !important;
    background-color: #F7F7F7 !important;
  }
}

table.rdr-table {
  border: 1px solid #F6F4FE;
  border-radius: $border-radius;
  background-color: #FEFEFE;
  min-width: 100%;
  color: #555E81;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 303;
      border-bottom: 1px solid #F6F4FE;
      background-color: #FEFEFE;

      &:nth-child(even) {
        background-color: #FAFAFD;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }
  }

  tbody {
    tr:hover {
      td {
        background-color: #FBFBFC;

        &:nth-child(even) {
          background-color: #FAFAFD;
        }
      }
    }

    td {
      &:nth-child(even) {
        background-color: #FBFBFC;
      }
    }
  }
}

.headerCell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  height: 56px;
  padding: 18px 4px 18px 8px;
  letter-spacing: 0.2px;
}

.headerCellLabel {
  font-weight: 600;
  flex-grow: 1;
  text-align: center;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  max-height: 40px;
}

.header-tooltip {
  white-space: pre-wrap;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 18px;
  padding: 18px 4px 18px 8px;
}

.cellLabel {
  flex-grow: 1;
  text-align: center;
}

.visibility-toggler-box {
  width: $show-more-width;
  background-color: $hidden-column-color;
}

.visibility-toggler-header {
  width: $show-more-width;
  padding: 0;
  color: $primary-color;
  cursor: pointer;
  user-select: none;
  transition: all 0.147967s linear;
  transition-delay: 0.052033s;
  i {
    font-size: 32px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F0F0F5;
  }
}

.displayed-hidden-header {
  padding: 0;
  /* div {
    background-color: #F0F0F5;
  } */
}

.displayed-hidden-column-box {
  background-color: $hidden-column-color;
}

.left {
  text-align: left;
  margin-right: auto;
}

.right {
  text-align: right;
  margin-left: auto;
}

.center {
  text-align: center;
  margin: auto;
}
.click{
  cursor: pointer;
}

tr.highlight td {
  font-weight: bold;
  border-top: $highlight-border;
  border-bottom: $highlight-border;

  &:first {
    border-left: $highlight-border;
  }

  &:last {
    border-right: $highlight-border;
  }
}

.info-tooltip {
  margin-left: 10px;
}

.progress-bar {
  flex-grow: 3;
}

.progress-bar-text-after {
  text-align: right;
  flex-grow: 1;
  white-space: nowrap;
  margin-left: 10px;
}

.progress-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-icon {
  cursor: pointer;
  margin-left: -5px;
  margin-right: 5px;
  color: #4266f7;
}
</style>
