<template>
  <span
    :data-step="tutorial.step"
    :data-intro="tutorial.description">
    <rdr-picker
      :options="sortedGroupOptions"
      :value="chosenGroup"
      :on-change="pick"
      :loading="loading"
      value-property="key"
      label-property="name"
    />
  </span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    onlyResultGroups: {
      type: Boolean,
      default: false,
    },
    onlyChildrenGroups: {
      type: Boolean,
      default: false,
    },
    customGroupOptions: {
      type: Array,
      default: null,
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
  },
  computed: {
    ...mapState('results', ['resultGroups']),
    ...mapGetters('intro', ['tutorialInfo']),
    ...mapGetters('options', ['chosenGroup', 'groups']),
    sortedGroupOptions() {
      return this.groupOptions.slice().sort((a, b) => a.report_order - b.report_order);
    },
    groupOptions() {
      if (this.customGroupOptions) return this.customGroupOptions;
      const optionGroups = this.onlyResultGroups ? this.resultGroups : this.groups;
      if (this.onlyChildrenGroups) {
        return optionGroups.filter(group => this.isChildGroup(group));
      }

      return optionGroups;
    },
    tutorial() {
      return this.tutorialInfo(['groupPicker']);
    },
  },
  watch: {
    sortedGroupOptions: {
      handler(to) {
        let chosenGroup = this.chosenGroup;
        if (chosenGroup && !to.find(g => g.id === chosenGroup.id)) {
          chosenGroup = to.find((group) => group.key.charAt(0) === chosenGroup.key.charAt(0));
        }
        if (!chosenGroup) {
          chosenGroup = to.find(group => (
            parseInt(group.education_type, 10) === 110 && parseInt(group.grade, 10) === 4) // eslint-disable-line no-magic-numbers
          );
        }
        if (!chosenGroup) {
          if (to[0]) {
            chosenGroup = to[0];
          } else {
            return;
          }
        }
        this.pick(chosenGroup.key);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('options', ['setChosenGroup']),
    isChildGroup(group) {
      return !this.groups.some(g => g.parent_id === group.id);
    },
    pick(groupKey) {
      this.setChosenGroup(groupKey);
      this.$ga.event({
        eventCategory: 'GroupPicker',
        eventAction: 'Pick',
        eventLabel: 'OptionSelect',
        eventValue: groupKey,
      });
    },
  },
};
</script>