/* eslint-disable camelcase */
import ResultsApi from '../../api/results';
import SchoolsApi from '../../api/schools';

function initialState() {
  return {
    loading: 0,
    results: null,
    reports: [],
    resultGroups: [],
    reportGroups: [],
    absenteeismEvaluation: null,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ADD_LOADING(state) {
      state.loading++;
    },
    REMOVE_LOADING(state) {
      state.loading--;
    },
    CLEAR_LOADING(state) {
      state.loading = 0;
    },
    RESET_STATE(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    SET_REPORT_GROUPS(state, reportGroups) {
      state.reportGroups.splice(0, state.reportGroups.length); // eslint-disable-line no-magic-numbers
      reportGroups.forEach(reportGroup => state.reportGroups.push(reportGroup));
    },
    SET_RESULT_GROUPS(state, resultGroups) {
      state.resultGroups.splice(0, state.resultGroups.length); // eslint-disable-line no-magic-numbers
      resultGroups.forEach(resultGroup => state.resultGroups.push(resultGroup));
    },
    SET_RESULTS(state, results) {
      state.results = results;
    },
    SET_ABSENTEEISM_EVALUATION(state, evaluation) {
      state.absenteeismEvaluation = evaluation;
    },
  },
  getters: {
    absenteeismEvaluation(state) {
      return state.absenteeismEvaluation || [];
    },
    loading(state) {
      return state.loading || 0;
    },
    resultsSchools(state) {
      const schools = (state.results ? state.results.schools : []);

      return schools.filter(school => !!school);
    },
    reportDescription(state) {
      return state.results ? state.results.report_description : null;
    },
    resultsIndicators(state) {
      return state.results ? state.results.indicators : [];
    },
    resultsEvaluations(state) {
      return state.results ? state.results.evaluations : [];
    },
    resultsEvaluableStudents(state) {
      if (!state.results) return [];

      return state.results.evaluable_students.reduce((students, student) => {
        students[student.id] = student;
        return students;
      }, {});
    },
    resultsGroups(state) {
      if (!state.results) return [];
      if (state.results.groups.length === 0) return [];
      if (state.results.report_groups.length === 0) return [];
      const reportGroups = state.results.report_groups;

      return state.results.groups.concat().sort((a, b) => {
        const aReportGroup = reportGroups.find(i => i.group_id === a.id);
        const bReportGroup = reportGroups.find(i => i.group_id === b.id);
        if (aReportGroup && bReportGroup && aReportGroup.index !== bReportGroup.index) {
          return aReportGroup.index - bReportGroup.index;
        }

        return a.key.localeCompare(b.key);
      });
    },
    resultYears(state) {
      return state.results ? state.results.years : [];
    },
    results(state, getters) {
      if (state.results === 'ERROR-500') {
        return 'ERROR-500';
      }
      return state.results ? state.results.results.map(result => {
        const newResult = { ...result };
        const evaluation = state.results.evaluations.find(e => e.id === result.evaluation_id);

        if (!evaluation) return newResult;

        if (evaluation.evaluable_type === 'School') {
          newResult.school_id = evaluation.evaluable_id;
        } else if (evaluation.evaluable_type === 'SchoolNetwork') {
          newResult.school_network_id = evaluation.evaluable_id;
        }
        newResult.year = evaluation.year;
        newResult.month = evaluation.month;

        newResult.evaluable_student = getters.resultsEvaluableStudents[newResult.evaluable_student_id];
        return newResult;
      }) : [];
    },
  },
  actions: {
    /* eslint max-statements: ["error", 12, { "ignoreTopLevelFunctions": true }] */
    async fetchResults({ rootState, rootGetters, commit }) {
      const params = { report_key: rootState.options.chosenReportKey }; // eslint-disable-line camelcase
      const indicator = rootState.options.indicators ? rootState.options.indicators.find(i => i.key === rootState.options.chosenIndicatorKey) : null;
      const chosenGroup = rootState.options.groups ? rootState.options.groups.find(i => i.key === rootState.options.chosenGroupKey) : null;

      const paramValues = {
        'chosen_school_ids': rootState.options.chosenSchoolIds.length ? rootState.options.chosenSchoolIds.join(',') : null,
        'school_ids': rootState.options.chosenSchoolId,
        'school_ids_auth': rootGetters['auth/userSchoolIds'].length ? rootGetters['auth/userSchoolIds'].join(',') : null,
        'group_ids': chosenGroup ? chosenGroup.id : null,
        'indicator_ids': indicator ? indicator.id : null,
        'years': rootState.options.chosenYear,
        'consecutive_years': [rootState.options.chosenYear, rootState.options.chosenYear - 1].join(','),
        'months': rootState.options.chosenMonth,
        'school_network_ids': rootState.auth.userInfo ? rootState.auth.userInfo.school_network_ids[0] : null,
      };

      Object.keys(rootState.options.reportParams).forEach(key => {
        if (rootState.options.reportParams[key]) {
          let paramKey = key;
          switch (key) {
          case 'consecutive_years':
            paramKey = 'years';
            break;
          case 'chosen_school_ids':
          case 'school_ids_auth':
            paramKey = 'school_ids';
          // no default
          }
          params[paramKey] = paramValues[key];
        }
      });
      if (Object.keys(params).some(key => params[key] === undefined || params[key] === null)) {
        return;
      }

      commit('ADD_LOADING');
      try {
        const { body } = await ResultsApi.getResults(params);
        commit('SET_RESULTS', body.results_with_stats);
      } catch (err) {
        commit('REMOVE_LOADING');
        commit('SET_RESULTS', 'ERROR-500');
      } finally {
        commit('REMOVE_LOADING');
      }
    },
    async fetchAbsenteeismEvaluation({ rootState, commit }) {
      commit('ADD_LOADING');
      try {
        const { body } = await SchoolsApi.getSchoolAbsenteeismEvaluation(
          rootState.options.chosenSchoolId
        );
        commit('SET_ABSENTEEISM_EVALUATION', body.evaluation);
      } catch (e) {
        commit('SET_ABSENTEEISM_EVALUATION', null);
      } finally {
        commit('REMOVE_LOADING');
      }
    },
  },
};
