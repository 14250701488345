<template>
  <div
    id="downTable"
    :ref="chartContainerRef"
    :class="$style.attendanceEvolution">
    <paywall
      :permitted="hasAttendancePermission"
      :free="isFreeUser"
      restricted>
      <div :class="['hide-print']">
        <div
          v-if="!chosenStageKeyOverride"
          :class="$style.pickers"
        >
          <h4>Selecciona un ciclo:</h4>
          <rdr-picker
            v-model="chosenStage"
            :options="stageGroups"
            :class="$style.picker"
            :loading="loading"
            label-property="name"
            value-property="key"
            picker-style="round"/>
        </div>
      </div>
      <div
        v-if="loading"
        :class="$style.container">
        <transition name="fade">
          <loading-overlay v-if="loading"/>
        </transition>
      </div>
      <transition
        :enter-class="$style.faded"
        :leave-to-class="$style.faded"
        :enter-active-class="$style.fadeTransition"
        :leave-active-class="$style.fadeTransition"
        name="chartFade"
        mode="out-in">
        <div
          v-if="monthsAsColumns.length > 0 && chartRows.length > 0"
          :key="noTransitions && '' || 'transition-1'"
          :class="$style.chart">
          <!-- <div style="text-align: right">
            <button
              :class="['rdr-btn', 'rdr-btn--small', 'rdr-btn--primary', 'hide-print']"
              @click="printChart">
              <i class="rdr-btn__icon material-icons">print</i>
              <span>Imprimir .... </span>
            </button>
          </div> -->
          <template v-if="renderChart">
            <div ref="contenchartline">
              <historic-chart
                :periods="monthsAsColumns"
                :columns="yearsAsLine"
                :rows="chartRows"
                :view-window="attendanceChartFrame"
                :v-axis-format="chartFormat"
                :width="chartSize.width"
                :height="chartSize.height"
                :colors="chartProps.colors.length > 0 ? chartProps.colors : []"
                label-type="string"
                :anotaciones="true"
              />
            </div>
            <div class="info__chart-legend">
              <div
                v-for="(year, index) in yearsAsLine"
                :key="index"
                class="info__column">
                <div
                  :style="{ borderColor: chartProps.colors.length > 0 ? chartProps.colors[index] : chartColors[index] }"
                  class="info__color"
                />
                <div class="info__column-name">{{ year }}</div>
              </div>
            </div>
          </template>
        </div>
        <div
          v-else-if="!chosenStageKeyOverride"
          :key="noTransitions && '' || 'transition-2'"
          :class="$style.attendanceEvolutionEmpty">
          <img src="/noResultados.svg">
        </div>
      </transition>
    </paywall>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import PrintReactiveChartMixin from '../../mixins/print-reactive-chart';
import ResultsMixin from '../../mixins/results';
import StageGroupsMixin from '../../mixins/stage-groups';
import HistoricChart from '../../components/historic-chart.vue';
import ResultsHeader from '../../components/results/results-header.vue';
import RdrPicker from '../../components/utils/picker';
import Paywall from '../../components/paywall.vue';
import schoolYear from '../../utils/years';
import GRAPH_COLORS from '../../utils/graph-colors';
import { MONTHS } from '../../utils/months';

const DEFAULT_VIEW_WINDOW_MIN = 50;

export default {
  name: 'AttendanceEvolutionLineChartView',
  components: { HistoricChart, ResultsHeader, RdrPicker, Paywall },
  mixins: [PermissionMixin, PrintReactiveChartMixin, ResultsMixin, StageGroupsMixin],
  props: {
    chosenStageKeyOverride: {
      type: String,
      default: null,
    },
    noTransitions: {
      type: Boolean,
      default: false,
    },
    chartProps: {
      type: Object,
      default: () => ({
        colors: ['#C0C5E8', '#4266F7'],
      }),
    },
  },
  data() {
    return {
      chartFormat: '#\'%\'',
      error: false,
      chartColors: GRAPH_COLORS,
      chosenStageKey: 'todos',
      chartSize: {},
      renderChart: true,
      oldWidth: 0,
      oldHeight: 0,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['groups', 'chosenYear']),
    ...mapGetters('results', {
      results: 'results',
      evaluations: 'resultsEvaluations',
    }),
    chosenStage: {
      get() {
        const key = this.chosenStageKeyOverride || this.chosenStageKey;

        return this.stageGroups.find(stage => stage.key === key);
      },
      set(value) {
        this.chosenStageKey = value;
      },
    },
    stageResults() {
      if (!this.chosenStage) {
        return this.results;
      }

      const resultFilter =
        this.chosenStage.key === 'todos' ?
          this.filterResultByGroup :
          this.filterResultByParentGroup;

      return this.results.filter(resultFilter);
    },
    chartRows() {
      let rows = this.months.map(month => {
        const monthsResults = this.stageResults.filter(result => result.month === month.id);
        return monthsResults.length > 0 &&
        [
          month.label,
          ...this.yearsAsLine.map(year => {
            const yearResults = monthsResults.filter(result => result.year === year);
            if (yearResults.length === 0) return null;
            return parseFloat(
              (yearResults.reduce((a, b) => a + b.value, 0) / yearResults.length).toFixed(1)
            );
          }),
        ];
      }).filter(r => !!r);
      return rows;
    },
    yearsAsLine() {
      return [this.chosenYear - 1, this.chosenYear];
    },
    months() {
      if (!this.evaluations) return [];

      return [...new Set(this.evaluations.map(e => e.month))]
        .sort((a, b) => a - b)
        .map(month => ({
          id: month,
          label: `${MONTHS[month - 1]}`,
        }));
    },
    monthsAsColumns() {
      return this.months.map(month => month.label);
    },
    minAttendanceValue() {
      const values = [].concat(...this.chartRows).filter(value => typeof value === 'number');

      return Math.min(...values);
    },
    attendanceChartFrame() {
      return { min: this.minAttendanceValue < DEFAULT_VIEW_WINDOW_MIN ? 0 : DEFAULT_VIEW_WINDOW_MIN, max: 100 };
    },
    chosenStageGroupIds() {
      return this.chosenStage.groups.map(g => g.id);
    },
    groupParentIds() {
      return this.groups.map(g => ({ [g.id]: g.parent_id })).reduce((a, b) => ({ ...a, ...b }), {});
    },
  },
  created() {
    this.setChosenYear(schoolYear());
  },
  mounted() {
    window.addEventListener("afterprint", (event) => {
      this.renderChart = false;
      let self = this;
      setTimeout(() => {
        self.chartSize.width = self.oldWidth;
        self.chartSize.height = self.oldHeight;
        self.renderChart = true;
      }, 200);
    });
  },
  methods: {
    ...mapActions('options', { setChosenYear: 'setChosenYear', setReportParams: 'setReportParams' }),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'consecutive_years': true });
      this.setReportKey('last-year-attendance-line-chart');
    },
    filterResultByGroup(result) {
      return this.filterResultByGroupMapping(result, result.group_id);
    },
    filterResultByParentGroup(result) {
      return this.filterResultByGroupMapping(result, this.groupParentIds[result.group_id]);
    },
    filterResultByGroupMapping(result, resultGroupMapping) {
      return result.month > 0 &&
        this.chosenStageGroupIds.includes(resultGroupMapping);
    },
    printChart() {
      this.renderChart = false;
      this.chartSize.width = 600;
      this.oldWidth = this.$refs.contenchartline.clientWidth;
      this.oldHeight = this.$refs.contenchartline.clientHeight;
      let self = this;
      setTimeout(() => {
        self.renderChart = true;
        setTimeout(() => {
          window.print();
        }, 200);
      }, 200);
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";
  .attendance-evolution {
    padding: 0 30px;

    &-empty {
      text-align: center;
    }
  }
  .pickers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .picker {
      margin-top: 10px;
    }

    h3 {
      margin: 0;
    }
  }
  .faded {
    opacity: 0;
  }
  .fade-transition {
    transition: all 0.2s;
  }

  @media print {
    .content {
      margin: 0px !important;
      padding: 0px !important;
    }
    .attendance-evolution {
      padding: 0px !important;
    }
  }
</style>
