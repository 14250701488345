<template>
  <rdr-picker
    :options="indicatorOptions"
    :value="chosenIndicator"
    :on-change="pick"
    :picker-style="pickerStyle"
    :size="size"
    :loading="loading"
    value-property="key"
    label-property="label"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    pickerStyle: {
      type: String,
      default: 'inline',
    },
    size: {
      type: String,
      default: '',
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
  },
  computed: {
    ...mapState('options', ['indicators']),
    ...mapGetters('options', ['chosenIndicator']),
    indicatorOptions() {
      const options = this.indicators.map(indicator => {
        if (indicator.parent_id) {
          const indicatorClone = JSON.parse(JSON.stringify(indicator));

          indicatorClone.label = `— ${indicatorClone.label}`;

          return indicatorClone;
        }

        return indicator;
      });
      if (options.length > 0 &&
        (!this.chosenIndicator ||
        !options.some((i) => i.id === this.chosenIndicator.id))) {
        this.pick(options[0].key);
      }

      return options;
    },
  },
  watch: {
    indicators: {
      handler() {
        if (this.indicators.length > 0 && (!this.chosenIndicator ||
        (this.chosenIndicator &&
        !this.indicators.some((i) => i.id === this.chosenIndicator.id)))) {
          this.pick(this.indicators[0].key);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('options', ['setChosenIndicatorKey']),
    pick(value) {
      if (!value) return;
      this.setChosenIndicatorKey(value);
      this.$ga.event({
        eventCategory: 'IndicatorPicker',
        eventAction: 'Pick',
        eventLabel: 'OptionSelect',
        eventValue: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>