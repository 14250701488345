<template>
  <div class="schools-settings">
    <div class="schools-settings__header">
      <span><strong>Colegios enlazados a mi cuenta</strong></span>
    </div>
    <loading-overlay v-if="loading"/>
    <ul v-else>
      <li v-if="!schools.length">
        No tienes colegios enlazados a tu red.
      </li>
      <li
        v-for="school in schools"
        :key="school.rbd">
        <school-row
          :school="school"
          :open-school-file-uploader="openSchoolFileUploader(school)"
          :update-function="updateSchoolData"/>
      </li>
    </ul>
  </div>
</template>

<script>
import SchoolsApi from '../../api/schools';
import School from './school.vue';

export default {
  name: 'SchoolsSettings',
  components: {
    'school-row': School,
  },
  props: {
    openSchoolId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      schools: [],
      loading: 0,
    };
  },
  mounted() {
    this.fetchUserSchools();
  },
  methods: {
    async fetchUserSchools() {
      try {
        this.loading++;
        const { body: { schools } } = await SchoolsApi.getUserSchoolConfigurations();
        this.schools = schools;
      // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading--;
      }
    },
    async updateSchoolData(schoolId) {
      try {
        const {
          body: { school_configuration: school },
        } = await SchoolsApi.getSchoolConfiguration(schoolId);
        this.schools[this.schools.findIndex(s => s.id === school.id)] = school;
      // eslint-disable-next-line no-empty
      } catch (_) {
      }
    },
    openSchoolFileUploader(school) {
      return +school.id === +this.openSchoolId;
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

  .schools-settings {
    > * {
      padding: 0 30px;

      @media only screen and (max-width: $mobile-breakpoint) {
        padding: 0 15px;
      }

      &:first-child {
        padding-top: 25px;
      }
    }

    &__header {
      margin-bottom: 20px;

      @media only screen and (max-width: $mobile-breakpoint) {
        span {
          display: block;
        }
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;

      li {
        margin: 0;
        border-bottom: 1px solid $settings-border-color;
        padding: 10px 30px;

        @media only screen and (max-width: $mobile-breakpoint) {
          padding: 20px 15px;

          &:not(:nth-child(even)) {
            background-color: $settings-block-emphasis-background;
          }
        }

        &:first-child {
          border-top: 1px solid $settings-border-color;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .right {
    float: right;
  }
</style>
