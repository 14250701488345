/**
 * @author Cristian Moreno <khriztian@platan.us>
 */

import Vue from 'vue';
import VueResource from 'vue-resource';

import store from '../store/index';

Vue.use(VueResource);

Vue.http.interceptors.push((request, next) => {
  const headers = store.getters['auth/getAuthHeaders']();

  Object.keys(headers).forEach(key => {
    request.headers.set(key, headers[key]);
  });

  next();
});

export default {
  getUserInfo() {
    return Vue.http.get('/api/v1/users/me');
  },
  submit(cred) {
    return Vue.http.post('/api/v1/authentications', cred);
  },
  radarManagementLogin(cred) {
    return Vue.http.post(
      `${RADAR_MANAGEMENT_HOST}/authentications`, // eslint-disable-line no-undef
      {},
      { headers: { Authorization: `Bearer ${cred}` } }
    );
  },
  changeUserPassword(userId, currentPassword, password) {
    return Vue.http.patch(
      `/api/v1/users/${userId}`,
      { user: { password, currentPassword } }
    );
  },
  checkUserPassword(userId, password) {
    return Vue.http.post(
      `/api/v1/users/${userId}/check-password`,
      { password }
    );
  },
  getRadarManagementToken(userId) {
    return Vue.http.get(`/api/v1/users/${userId}/radar-management-token`);
  },
  requestPlatformAccess(userId, message) {
    return Vue.http.post(`/api/v1/users/${userId}/platform-access-requests`, { message });
  },
  getPlatformAccessRequests(userId) {
    return Vue.http.get(`/api/v1/users/${userId}/platform-access-requests`);
  },
  updateUserInfo(userId, userInfo) {
    const formData = new FormData();
    Object.keys(userInfo).forEach((key) => {
      formData.append(key, userInfo[key]);
    });

    return Vue.http.patch(
      `/api/v1/users/${userId}`,
      formData,
    );
  },
  refreshAttachment(attachmentId) {
    return Vue.http.post(
      `/api/v1/user_attachments/${attachmentId}/refresh`
    );
  },
  sendForgottenPassword(email) {
    return Vue.http.post(
      '/api/v1/users/forgotten-password', { email }
    );
  },
  resetPassword(email, password, token) {
    return Vue.http.post(
      '/api/v1/users/reset-password', { email, password, token }
    );
  },
};
