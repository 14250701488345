<template>
  <div :class="[$style.verticalCard, selected ? $style.verticalCardSelected : null ]">
    <div :class="$style.verticalCardTitle">
      {{ title }}
    </div>
    <div :class="$style.verticalCardMessage">
      {{ body.message }}
    </div>
    <div
      v-if="body.list">
      <span
        v-for="item in body.list"
        :key="item"
        :class="$style.verticalCardList">
        - {{ item }}
      </span>
    </div>
    <div
      :v-if="body.extraInfo"
      :class="$style.verticalCardExtra">
      {{ body.extraInfo }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerticalCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: Object,
      default: () => (
        { 'message': '',
          'list': [],
          'extra-info': '',
        }
      ),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};

</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.vertical-card {
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: $light-color;
  border-radius: 3%;
  flex: 0 1 30%;
  min-height: 250px;
  &__title {
    color: $primary-color;
    text-align: center;
    font-size: 16px;
    border: none;
    margin: 5px;
  }
  &__message {
    text-align: justify;
    font-size: 12px;
    border: none;
    margin: 10px;
  }
  &__list {
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    border: none;
    margin: 10px;
    margin-left: 20px;
    display: block;
  }
  &__extra {
    text-align:justify;
    font-size: 12px;
    border: none;
    margin: 10px;

  }
  &:hover {
    border-color: $primary-color;
  }
  &__selected {
    border-color: $primary-color;
    border-width: 2px;
  }

}
</style>
