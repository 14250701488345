const Format = {
  ROUND: 0,
  FLOOR: 1,
  ONE_DECIMAL: 2,
  TWO_DECIMALS: 3,
  PERCENTAGE: 4,
  PERCENTAGE_WITH_ONE_DECIMAL: 5,
  PERCENTAGE_WITH_TWO_DECIMALS: 6,
};

const INDICATOR_FORMAT_TO_ENUM = {
  'round': Format.ROUND,
  'floor': Format.FLOOR,
  'one_decimal': Format.ONE_DECIMAL,
  'two_decimals': Format.TWO_DECIMALS,
  'percentage': Format.PERCENTAGE,
  'percentage_with_one_decimal': Format.PERCENTAGE_WITH_ONE_DECIMAL,
  'percentage_with_two_decimals': Format.PERCENTAGE_WITH_TWO_DECIMALS,
};

export { Format, INDICATOR_FORMAT_TO_ENUM };
