<template>
  <div class="contenScroll">
    <div class="color-map">
      <div v-if="loading">
        <transition name="fade">
          <loading-overlay />
        </transition>
      </div>
      <div v-else-if="results && results.length > 0">
        <!-- ======= INICIO HEADER ====== -->
        <div
          ref="colorMapHeader"
          :class="[
            'color-map__header',
            ...borderClasses(['right', 'top', 'bottom']),
            { 'with-banner-displacement': !isLoggedIn || isFreeUser },
          ]"
        >
          <div :class="['button-wrapper2', 'hide-mobile']">
            <rdr-tooltip v-if="showVisibleResultsButton">
              <span v-if="showResults">Ocultar valores</span>
              <span v-else>Mostrar valores</span>
              <button
                slot="reference"
                :class="[
                  'rdr-btn',
                  'rdr-btn--small',
                  { 'rdr-btn--primary': showResults },
                  'visibility-button',
                  'hide-print',
                ]"
                @click="toggleResults"
              >
                <i class="visibility-button__icon material-icons">{{
                  showResults ? "visibility" : "visibility_off"
                }}</i>
              </button>
            </rdr-tooltip>
          </div>

          <div class="color-map__column2">Lunes</div>
          <div class="color-map__column2">Martes</div>
          <div class="color-map__column2">Miércoles</div>
          <div class="color-map__column2">Jueves</div>
          <div class="color-map__column2">Viernes</div>
        </div>
        <!-- ======= FIN HEADER ====== -->

        <!-- ======= SUB-HEADER ====== -->
        <div class="color-map__row color-map__row--even2 sub-header">
          <div class="color-map__row-label2">Total General</div>
          <div
            class="color-map__row color-map__result2 box-subgrup-left"
            :style="getColorPorcen(totalRows[0])"
          >
            <div
              v-if="showResults && valideData(totalRows[0])"
              :class="['color-map__result-value', 'no-hover']"
            >
              <div slot="reference">
                {{ totalRows[0] }}
              </div>
            </div>
          </div>
          <div
            class="color-map__row color-map__result2"
            :style="getColorPorcen(totalRows[1])"
          >
            <div
              v-if="showResults && valideData(totalRows[1])"
              :class="['color-map__result-value', 'no-hover']"
            >
              <div slot="reference">
                {{ totalRows[1] }}
              </div>
            </div>
          </div>
          <div
            class="color-map__row color-map__result2"
            :style="getColorPorcen(totalRows[2])"
          >
            <div
              v-if="showResults && valideData(totalRows[2])"
              :class="['color-map__result-value', 'no-hover']"
            >
              <div slot="reference">
                {{ totalRows[2] }}
              </div>
            </div>
          </div>
          <div
            class="color-map__row color-map__result2"
            :style="getColorPorcen(totalRows[3])"
          >
            <div
              v-if="showResults && valideData(totalRows[3])"
              :class="['color-map__result-value', 'no-hover']"
            >
              <div slot="reference">
                {{ totalRows[3] }}
              </div>
            </div>
          </div>
          <div
            class="color-map__row color-map__result2 box-subgrup-right"
            :style="getColorPorcen(totalRows[4])"
          >
            <div
              v-if="showResults && valideData(totalRows[4])"
              :class="['color-map__result-value', 'no-hover']"
            >
              <div slot="reference">
                {{ totalRows[4] }}
              </div>
            </div>
          </div>
        </div>
        <!-- ======= END SUB-HEADER ====== -->

        <!-- ======= CONTEN TABLE ====== -->
        <div class="color-map__content-with-row-headers">
          <div
            :class="['color-map__content', ...borderClasses(['right', 'bottom'])]"
            @mouseleave="columnHover = null"
          >
            <div
              v-for="(rowGroup, index) in nestedRows"
              :key="index"
              :class="[
                'color-map__row-container',
                { 'color-map__row-container-son': rowGroup[0].parent_id },
              ]"
            >
              <div
                v-for="(row, indexGroup) in rowGroup"
                :key="(index, indexGroup)"
                :class="[
                  'color-map__row',
                  'color-map__row--even2',
                  { 'first-row': indexGroup === 0 },
                  rowClasses(row),
                ]"
              >
                <div
                  :class="[
                    'color-map__row-label2',
                    `color-map__row-label--depth-${getDepthLevels(sortedRows, row)}`,
                    ...borderClasses(['left', 'right']),
                    {
                      'color-map__row-label--child': row.parent_id,
                      'color-map__row-label--clickable': hasChildren(rows, row),
                      'color-map__row-label--active': isRowOpen(row.key),
                    },
                  ]"
                  :style="rowStyle"
                  @click="toggleRow(row)"
                >
                  <div class="color-map__expansion-button-text">
                    <i
                      v-if="hasChildren(rows, row) && !isRowOpen(row.key)"
                      class="toggle-icon material-icons right-arrow hide-print"
                      >add</i
                    >
                    <i
                      v-if="hasChildren(rows, row) && isRowOpen(row.key)"
                      class="toggle-icon material-icons down-arrow hide-print"
                      >remove</i
                    >
                    <div v-if="internalEvaluationsView || attendancePercentageView">
                      <router-link :to="selectedSchoolLink(row.id)">
                        <rdr-tooltip :disabled="!row.key">
                          <div v-html="row.key" />
                          <span slot="reference">{{ row.label }} </span>
                        </rdr-tooltip>
                      </router-link>
                    </div>
                    <div v-else>
                      <rdr-tooltip :disabled="windowWidth > 750">
                        <div v-html="row.label" />
                        <span slot="reference">{{ shortenedColumnLabel(row) }}</span>
                      </rdr-tooltip>
                    </div>
                  </div>
                </div>

                <div class="color-map__results">
                  <div
                    v-for="(column, index) in sortedColumns"
                    :class="[
                      'color-map__row',
                      'color-map__result2',
                      {
                        'color-map__result--selected-column':
                          index === columnHover || showResults,
                      },
                      { 'color-map__result--no-result': !columnHasResults(column) },
                      { 'color-map__result--highlighted': column.highlighted },
                      classFromColumn(column),
                    ]"
                    :style="getColorPorcen(getResult(column.id, row.id), true)"
                    :key="(row.id, column.id)"
                    @click="toggleRow(row)"
                    @mouseover="columnHover = index"
                  >
                    <div
                      v-if="
                        getResult(column.id, row.id) &&
                        getResult(column.id, row.id).value !== null &&
                        showResults
                      "
                      :class="[
                        'color-map__result-value',
                        { 'mobile-value': isRowOpen(row.key) || isParentRowOpen(row) },
                      ]"
                    >
                      <div slot="reference">
                        {{ transformResult(column, row) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="color-map__empty">
        <button
          v-if="showSchoolPicker"
          class="desktop-only school-picker-button rdr-btn rdr-btn--small rdr-btn--primary"
          @click="openSchoolPicker"
        >
          {{ schoolPickerLabel }}
        </button>
        <div v-if="attendance">
          <img src="/noResultados.svg" />
          <div class="results-header__subtitle color-map__no-results">
            {{ emptyAttendanceMessage }}
          </div>
          <router-link
            :to="settingsLink"
            class="results-header__subtitle color-map__no-results"
            >Panel de configuración de clave SIGE</router-link
          >
        </div>
        <div v-else>
          <img src="/noResultados.svg" />
          <!-- <p> {{ emptyMessage }} </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "array.prototype.move";
import { mapState, mapGetters } from "vuex";
import interpolate from "../utils/color-interpolate";
import AuthMixin from "../mixins/auth";
import PermissionMixin from "../mixins/permission";
import ToLocalTimeMixin from "../mixins/to-local-time";
import UpcomingDialogMixin from "../mixins/upcoming-dialog";
import ValueFormattingMixin from "../mixins/value-formatting";
import columnLabelShortener from "../utils/label-shortener";

const COLORS = {
  RED: "#ED050B",
  YELLOW: "#FFFDCC",
  GREEN: "#05BD3C",
  WHITE: "#FEFFF2",
};

const ROW_HEADER_WIDTH = 20;
const CELL_HEIGHT = 56;

export default {
  mixins: [
    AuthMixin,
    PermissionMixin,
    ToLocalTimeMixin,
    UpcomingDialogMixin,
    ValueFormattingMixin,
  ],
  props: {
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    schoolPickerLabel: {
      type: String,
      default: "Agregar/Quitar Colegios",
    },
    showSchoolPicker: {
      type: Boolean,
      default: false,
    },
    disableSchoolAdding: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    totalRows: {
      type: Object,
      default: () => {},
    },
    results: {
      type: Array,
      default: () => [],
    },
    rowHeaders: {
      type: Array,
      default: () => [],
    },
    hideEmptyColumns: {
      type: Boolean,
      default: false,
    },
    defaultOpenRows: {
      type: Array,
      default: () => [],
    },
    defaultOpenColumns: {
      type: Array,
      default: () => [],
    },
    attendance: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    grades: {
      type: Boolean,
      default: false,
    },
    invertedColors: {
      type: Boolean,
      default: false,
    },
    floor: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    rowSort: {
      type: String,
      default: "",
    },
    columnSort: {
      type: String,
      default: "",
    },
    resultsColumnKey: {
      type: String,
      default: "column_id",
    },
    resultsRowKey: {
      type: String,
      default: "indicator_id",
    },
    whiteReplacesYellow: {
      type: Boolean,
      default: false,
    },
    latestUpdateHover: {
      type: Boolean,
      default: false,
    },
    restrictedSchoolPicker: {
      type: Boolean,
      default: false,
    },
    sipDashboardView: {
      type: Boolean,
      default: false,
    },
    comparativePublicResultsView: {
      type: Boolean,
      default: false,
    },
    internalEvaluationsView: {
      type: Boolean,
      default: false,
    },
    attendancePercentageView: {
      type: Boolean,
      default: false,
    },
    normalizationLabel: {
      type: String,
      default: "norm_value",
    },
    minValue: {
      type: Number,
      default: -1.5, // eslint-disable no-magic-numbers
    },
    maxValue: {
      type: Number,
      default: 1.5, // eslint-disable no-magic-numbers
    },
    optimalValue: {
      type: Number,
      default: 0,
    },
    columnHeaderHover: {
      type: Boolean,
      default: true,
    },
    noColor: {
      type: Boolean,
      default: false,
    },
    optimalInCenter: {
      type: Boolean,
      default: false,
    },
    onlyAlerts: {
      type: Boolean,
      default: false,
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: 0,
      openColumns: [],
      openRows: ["simce"],
      emptyMessage: "No hay resultados para las opciones seleccionadas.",
      addSigePasswordMessage:
        "Debes ingresar la clave SIGE de tus colegios para ver resultados.",
      emptyAttendanceMessage: `Intenta eligiendo otro año.
        \nSi el problema persiste, en la sección Configuración general recuerda ingresar la clave SIGE correspondiente a este RBD.
        \n\nSi necesitas más información, contáctate con nosotros a radar@colegium.com
        \n`,
      columnHover: null,
      yearDefault: 2017,
      groupDefault: 4,
      showResults: true,
    };
  },
  computed: {
    ...mapState("options", ["chosenYear", "chosenMonth"]),
    ...mapGetters("options", ["schoolChoicesRemaining"]),
    cellsWithTooltip() {
      const cellsWithTooltip = {};
      this.displayColumns.forEach((col) => {
        cellsWithTooltip[col.id] = {};
      });
      this.displayRows.forEach((row) => {
        let lastColWithResult = null;
        this.displayColumns.forEach((col) => {
          const result = this.getResult(col.id, row.id);
          if (result && result.value) {
            lastColWithResult = col;
          }
        });
        if (!lastColWithResult) return;
        const rowId = {};
        rowId[row.id] = true;
        Object.assign(cellsWithTooltip[lastColWithResult.id], rowId);
      });

      return cellsWithTooltip;
    },
    sortedRows() {
      switch (this.rowSort) {
        case "average_ascending": {
          const parentRows = this.displayRows
            .filter((row) => row.parent_id === null)
            .sort((a, b) => this.rowAverage(a) - this.rowAverage(b));
          const sortRows = parentRows.slice();
          parentRows.forEach((parent) => {
            if (parent.parent_id === null) {
              const childrenRows = this.displayRows.filter(
                (row) => row.parent_id === parent.id
              );
              if (childrenRows) {
                const sortedChildrenRows = childrenRows
                  .slice()
                  .sort((a, b) => this.rowAverage(a) - this.rowAverage(b));
                sortRows.splice(sortRows.indexOf(parent) + 1, 0, ...sortedChildrenRows);
              }
            }
          });
          return sortRows;
        }
        case "last_cell_descending": {
          return this.displayRows.slice().sort(this.compareRowByLastColumn(true));
        }
        default: {
          return this.displayRows.map((row) => ({ ...row }));
        }
      }
    },
    nestedRows() {
      /* In this computed we create an array that contains arrays of rows.
         These arrows groups rows that have the same parent_id. */
      return this.sortedRows.reduce((cumulatedRows, row, index) => {
        row.even = index % 2 === 0;
        if (
          cumulatedRows.length > 0 &&
          cumulatedRows[cumulatedRows.length - 1][0].parent_id === row.parent_id
        )
          cumulatedRows[cumulatedRows.length - 1].push(row);
        else cumulatedRows.push([row]);

        return cumulatedRows;
      }, []);
    },

    sortedColumns() {
      if (this.attendance) return this.displayColumns;
      let preSorted;
      switch (this.columnSort) {
        case "average_ascending":
          preSorted = this.displayColumns
            .slice()
            .sort((a, b) => this.columnAverage(a) - this.columnAverage(b));
          break;
        case "column_value_count_descending":
          preSorted = this.displayColumns
            .slice()
            .sort((a, b) => this.columnValueCount(b) - this.columnValueCount(a));
          break;
        default:
          preSorted = this.displayColumns.map((col) => ({ ...col }));
      }
      if (this.comparativePublicResultsView) {
        const sorted = [];
        preSorted
          .slice()
          .reverse()
          .forEach((col) => {
            if (
              this.displayRows.some((row) =>
                this.results.some(
                  (result) =>
                    result[this.resultsColumnKey] === col.id &&
                    result[this.resultsRowKey] === row.id
                )
              )
            ) {
              sorted.splice(0, 0, col);
            } else {
              sorted.push(col);
            }
          });

        return sorted;
      }
      return preSorted;
    },
    displayRows() {
      let rowsFilter = this.rows.filter((row) => this.shouldDisplayRow(row));
      return rowsFilter;
    },
    displayColumns() {
      return this.columns.filter((column) => this.shouldDisplayColumn(column));
    },
    displayRowHeaders() {
      return this.rowHeaders.filter((rowHeader) =>
        this.displayRows.some((r) => rowHeader.children.includes(r.id))
      );
    },
    showSchoolAddColumn() {
      return (!this.isLoggedIn || this.showSchoolPicker) && !this.disableSchoolAdding;
    },
    schoolChoiceLabel() {
      return this.schoolChoicesRemaining > 0
        ? this.schoolPickerLabel
        : "Cambiar colegios";
    },
    activeColormap() {
      let colors;
      if (this.optimalInCenter) {
        colors = this.onlyAlerts
          ? [COLORS.RED, COLORS.WHITE, COLORS.RED]
          : [COLORS.RED, COLORS.YELLOW, COLORS.GREEN, COLORS.YELLOW, COLORS.RED];
      } else if (this.whiteReplacesYellow) {
        colors = this.invertedColors
          ? [COLORS.GREEN, COLORS.WHITE, COLORS.RED]
          : [COLORS.RED, COLORS.WHITE, COLORS.GREEN];
      } else {
        colors = this.invertedColors
          ? [COLORS.GREEN, COLORS.YELLOW, COLORS.RED]
          : [COLORS.RED, COLORS.YELLOW, COLORS.GREEN];
      }
      return interpolate(colors);
    },
    maxLabelLength() {
      const widthValues = this.displayRows.map(
        (row) =>
          Math.ceil(row.label.length / 2) * 11 + this.getDepthLevels(this.rows, row) * 10
      ); // eslint-disable-line no-magic-numbers
      const maxWidth = Math.max(...widthValues) + 50; // eslint-disable-line no-magic-numbers
      return `${Math.min(maxWidth, 205)}px`; // eslint-disable-line no-magic-numbers
    },
    hasRowHeaders() {
      return this.displayRowHeaders.length > 0;
    },
    rowHeaderTopRows() {
      if (!this.hasRowHeaders) return [];
      return this.displayRowHeaders
        .slice(1, this.displayRowHeaders.length)
        .map(({ children }) => this.displayRows.find((r) => children.includes(r.id)).id);
    },
    rowStyle() {
      return {
        width: this.maxLabelLength,
        left: this.hasRowHeaders ? `${ROW_HEADER_WIDTH}px` : 0,
      };
    },
    cornerStyle() {
      return { width: `${ROW_HEADER_WIDTH + (this.isBordered ? 1 : 0)}px` };
    },
    showVisibleResultsButton() {
      return this.results.every((r) => !!(r.value || r.alternative_text));
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.defaultOpenRows) {
      this.defaultOpenRows.forEach((key) => this.openRows.push(key));
    }
    if (this.defaultOpenColumns) {
      this.defaultOpenColumns.forEach((id) => this.openColumns.push(id));
    }
  },
  methods: {
    getColorPorcen(data, conten=null) {
      let porcen = 0;
      if (conten) porcen = parseInt(data.value);
      else porcen = parseInt(data);
      if (isNaN(porcen)) {
        const styles = "background: #EDF4FD;";
        return styles;
      }
      let background = 'rgb(238, 21, 24)';
      let palette = [
        "rgb(238, 21, 24)",
        "rgb(240, 52, 47)",
        "rgb(241, 62, 56)",
        "rgb(244, 102, 87)",
        "rgb(245, 116, 97)",
        "rgb(247, 146, 121)",
        "rgb(249, 169, 138",
        "rgb(250, 188, 154)",
        "rgb(252, 218, 177)",
        "rgb(254, 236, 191)",
        "rgb(247, 251, 200)",
        "rgb(229, 246, 189)",
        "rgb(199, 239, 171)",
        "rgb(178, 233, 160)",
        "rgb(139, 223, 137)",
        "rgb(128, 220, 131)",
        "rgb(81, 209, 104)",
        "rgb(69, 205, 97)",
        "rgb(34, 196, 77)",
        "rgb(11, 191, 64)",
        "rgb(5, 189, 60)",
      ];

      if (porcen >= 80) {
        let posicion = porcen - 80;
        background = palette[posicion];
      }
      const styles = "background: " + background + ";";
      return styles;
    },
    valideData(data) {
      let validate = true;
      const valor = parseInt(data);
      if (isNaN(valor)) {
        validate = false;
      }
      return validate;
    },

    rowAverage(row) {
      let total = 0;
      let count = 0;
      this.displayColumns.forEach((col) => {
        const result = this.getResult(col.id, row.id);
        if (result && result[this.normalizationLabel] !== null) {
          total += result[this.normalizationLabel];
          count++;
        }
      });

      return total / count;
    },
    columnAverage(col) {
      let total = 0;
      let count = 0;
      let parentRows = this.displayRows.slice().filter((row) => row.parent_id === null);
      if (parentRows.length === 0) {
        parentRows = this.displayRows.filter(
          (row) => !this.isParentInReport(row.parent_id)
        );
      }
      parentRows.forEach((row) => {
        const result = this.getResult(col.id, row.id);
        if (result) {
          total += result[this.normalizationLabel];
          if (result[this.normalizationLabel] !== null) {
            count++;
          }
        }
      });

      return total / count;
    },
    columnValueCount(col) {
      return this.displayRows.filter(
        (row) => row.parent_id !== null && this.getResult(col.id, row.id)
      ).length;
    },
    isParentInReport(parentId) {
      const parentRowsIds = this.displayRows
        .filter((row) => row.parent_id !== null)
        .map((elem) => elem.id);

      return !!parentRowsIds.includes(parentId);
    },
    compareRowByLastColumn(desc) {
      return (rowA, rowB) => {
        const columns = this.sortedColumns.slice().reverse();
        const lastColumnA = columns.find((col) => this.getResult(col.id, rowA.id));
        const lastColumnB = columns.find((col) => this.getResult(col.id, rowB.id));

        return (
          (this.getResult(lastColumnA.id, rowA.id).value -
            this.getResult(lastColumnB.id, rowB.id).value) *
          (desc ? -1 : 1)
        );
      };
    },
    transformResult(column, row) {
      const result = this.getResult(column.id, row.id);
      let value = this.getResult(column.id, row.id).value;
      value = parseInt(value) + "%";
      return value;
    },
    openSchoolPicker() {
      this.$store.dispatch("ui/openDialog", "schoolPicker");
      this.$ga.event({
        eventCategory: "SchoolPicker",
        eventAction: "Open",
        eventLabel: "SchoolPicker",
      });
    },
    hasChildren(collection, element) {
      return collection.some((item) => item.parent_id === element.id);
    },
    isColumnOpen(column) {
      return this.openColumns.indexOf(column.id) > -1;
    },
    toggleColumn(column) {
      if (this.columns.find((item) => item.parent_id === column.id)) {
        if (this.isColumnOpen(column)) {
          this.openColumns.splice(this.openColumns.indexOf(column.id), 1);
          this.$ga.event({
            eventCategory: "ColorMap",
            eventAction: "CloseColumn",
            eventLabel: "ColorMap",
            eventValue: column.label,
          });
        } else {
          this.openColumns.push(column.id);
          this.$ga.event({
            eventCategory: "ColorMap",
            eventAction: "OpenColumn",
            eventLabel: "ColorMap",
            eventValue: column.label,
          });
        }
      }
    },
    columnHasResults(column) {
      if (!this.results) return false;

      return this.results.some((result) => result[this.resultsColumnKey] === column.id);
    },
    shouldDisplayColumn(column) {
      if (column.parent_id) {
        const parentColumn = this.columns.find((item) => item.id === column.parent_id);
        if (parentColumn) {
          return this.columnHasResults(column) && this.isColumnOpen(parentColumn);
        }
      }

      return this.columnHasResults(column) || !this.hideEmptyColumns;
    },
    isRowOpen(rowKey) {
      let open = this.openRows.indexOf(rowKey) > -1;
      return open;
    },
    isParentRowOpen(row) {
      const parent = this.rows.find((item) => item.id === row.parent_id);
      return parent ? this.isRowOpen(parent.key) : false;
    },
    toggleRow(row) {
      if (this.isRowOpen(row.key)) {
        this.closeRow(row);
        this.$ga.event({
          eventCategory: "ColorMap",
          eventAction: "CloseRow",
          eventLabel: "ColorMap",
          eventValue: row.label,
        });
      } else {
        this.openRow(row);
        this.$ga.event({
          eventCategory: "ColorMap",
          eventAction: "OpenRow",
          eventLabel: "ColorMap",
          eventValue: row.label,
        });
      }
    },
    openRow(row) {
      if (this.hasChildren(this.rows, row) && !this.isRowOpen(row.key)) {
        this.openRows.push(row.key);
      }
    },
    closeRow(row) {
      if (this.hasChildren(this.rows, row) && this.isRowOpen(row.key)) {
        this.openRows.splice(this.openRows.indexOf(row.key), 1);
        const children = this.rows.filter((child) => child.parent_id === row.id);
        children.forEach((child) => this.closeRow(child));
      }
    },
    getSchoolId(result) {
      const evaluation = this.$store.state.results.results.evaluations.filter(
        (e) => e.id === result.evaluation_id
      );
      return evaluation.length ? evaluation[0].school_id : null;
    },
    rowHasResults(rowId) {
      if (this.results) {
        return this.results.some((result) => {
          const sourceId = result[this.resultsRowKey] || this.getSchoolId(result);
          return sourceId === rowId;
        });
      }
      return false;
    },
    shouldDisplayRow(row) {
      if (row.parent_id) {
        const parentRow = this.rows.find((item) => item.id === row.parent_id);
        if (parentRow) return this.rowHasResults(row.id) && this.isRowOpen(parentRow.key);
      }
      return this.rowHasResults(row.id);
    },
    getResult(columnId, rowId) {
      const row = parseInt(rowId);
      if (this.results) {
        return this.results.find(
          (result) =>
            result[this.resultsColumnKey] === columnId &&
            result[this.resultsRowKey] === row
        );
      }

      return null;
    },
    colorFromResult(result) {
      if (this.noColor) return "inherit";
      if (result && ![undefined, null].includes(result[this.normalizationLabel])) {
        return this.activeColormap(
          this.getNormalizedValue(result[this.normalizationLabel])
        );
      }
      return "#F6F6F6";
    },
    /* eslint-disable max-statements */
    getNormalizedValue(normalizedResult) {
      let value;
      let range;
      let normalizedValue;
      if (this.optimalInCenter) {
        if (normalizedResult < this.optimalValue) {
          value = Math.min(Math.max(this.minValue, normalizedResult), this.optimalValue);
          range = Math.abs(this.minValue - this.optimalValue);
          normalizedValue = (0.5 * Math.abs(this.minValue - value)) / range; // eslint-disable-line no-magic-numbers
        } else {
          value = Math.min(Math.max(this.optimalValue, normalizedResult), this.maxValue);
          range = Math.abs(this.maxValue - this.optimalValue);
          normalizedValue = (0.5 * Math.abs(this.maxValue - value)) / range; // eslint-disable-line no-magic-numbers
        }
      } else {
        value = Math.min(Math.max(this.minValue, normalizedResult), this.maxValue);
        range = Math.abs(this.maxValue - this.minValue);
        normalizedValue = (value - this.minValue) / range;
      }
      return normalizedValue;
    },
    /* eslint-enable max-statements */
    getDepthLevels(collection, element, count = 0) {
      let current = element;
      let counter = count;
      while (current && current.parent_id) {
        current = collection.find((i) => i.id === current.parent_id); // eslint-disable-line no-loop-func
        counter += 1;
      }
      return counter;
    },
    columnLink(column) {
      if (this.comparativePublicResultsView || this.sipDashboardView) {
        return this.selectedSchoolLink(column.id);
      }
      return column.link;
    },
    selectedSchoolLink(schoolId) {
      const year = parseInt(this.chosenYear, 10);
      const month = parseInt(this.chosenMonth, 10);
      if (this.sipDashboardView) {
        return { name: "sipDashboardBySchool", params: { school: schoolId } };
      } else if (this.attendancePercentageView) {
        return {
          name: "attendanceGeneralPanelBySchool",
          params: { year, school: schoolId },
        };
      } else if (this.internalEvaluationsView) {
        return {
          name: "internalEvaluationsLevelTestsBySchool",
          params: { year, month, school: schoolId },
        };
      }

      return {
        name: "schoolColorMap",
        params: { group: this.groupDefault, year, school: schoolId },
      };
    },
    toggleResults() {
      this.showResults = !this.showResults;
    },
    shortenedColumnLabel({ label }) {
      const maxLabelLength = 18;
      return columnLabelShortener(label, maxLabelLength, this.windowWidth);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    classFromColumn({ options }) {
      const columnClasses = [];
      if (options) {
        const { isSeparatorColumn } = options;
        if (isSeparatorColumn) columnClasses.push("separator-column");
      }
      return columnClasses.map((c) => `color-map__column--${c}`).join(" ");
    },
    rowHeaderStyle(rowHeader) {
      const children = this.displayRows.filter((r) => rowHeader.children.includes(r.id))
        .length;
      return {
        width: `${ROW_HEADER_WIDTH}px`,
        height: `${CELL_HEIGHT * children}px`,
        // eslint-disable-next-line no-magic-numbers
        "transform-origin": `${children * 15 + 10}px ${children * 15 + 12}px`,
      };
    },
    rowClasses({ id }) {
      const classes = [];
      if (this.hasRowHeaders && this.rowHeaderTopRows.includes(id))
        classes.push("separator");
      return classes.map((c) => `color-map__row--${c}`).join(" ");
    },
    borderClasses(borders) {
      return this.isBordered ? borders.map((b) => `bordered-${b}`) : [];
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/app/variables";

$legend-width: 370px;
$legend-mobile-width: 120px;
$shadow-color: #33333350;
$shadow-with: 5px;
$shadow-blur-radius: 11px;
$shadow-spread-radius: -12px;
$table-border: 1px solid $main-text-color;
$header-background-color: #fdfdfd;

.first-row {
  border-radius: 8px 0px 0px 0px;
}
.no-hover {
  opacity: 1 !important;
}

.sub-header {
  background: #edf4fd !important;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 4px;
}
.box-subgrup-left {
  border-radius: 8px 0px 0px 8px;
}
.box-subgrup-right {
  border-radius: 0px 8px 8px 0px;
}

.color-map-row-description-tooltip {
  max-width: 35vw;
  word-break: keep-all;
}

.color-map {
  display: inline-block;
  min-width: 100%;
  position: relative;
  min-height: 100px;

  .bordered-right {
    border-right: $table-border;
  }

  .bordered-left {
    border-left: $table-border;
  }

  .bordered-top {
    border-top: $table-border;
  }

  .bordered-bottom {
    border-bottom: $table-border;
  }

  &__empty {
    text-align: center;
  }

  &__header {
    display: flex;
    background-color: $header-background-color;
    color: $primary-color;
    font-weight: bold;
    position: sticky;
    top: 0;
    min-height: 35px;
    z-index: 6;
    margin-left: -5px;
    margin-bottom: 10px;

    @media print {
      border: 1px solid $sidebar-border-color;
      border-bottom: none;
    }

    .button-wrapper2 {
      align-items: center;
      justify-content: center;
      padding: 0px;
      min-width: 140px;
      align-self: stretch;
      text-align: center;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      padding-left: 0;
      padding-right: $legend-mobile-width;
      height: 110px;
      align-items: flex-end;
    }

    &.extra-margin {
      @media only screen and (max-width: $mobile-breakpoint) {
        margin-top: 50px;
      }
    }

    &.with-banner-displacement {
      // top: 40px;
    }
  }

  &__content {
    width: 100%;
    @media print {
      border: 1px solid $sidebar-border-color;
      border-top: none;
    }
  }

  &__corner {
    position: sticky;
    left: 0;
    right: 0;
    background-color: $header-background-color;
  }

  &__row-headers-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: sticky;
    background-color: $header-background-color;
    z-index: 1;
    left: 0;
    right: 0;

    .bordered-row-header:not(:first-child) {
      border-top: $table-border;
    }

    .row-header {
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }

  &__content-with-row-headers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__column2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-family: "GT Haptik", sans-serif;
    font-weight: 600;
    font-size: 9pt;
    padding: 0px 5px;
    letter-spacing: 0.5px;
    text-align: center;
    min-width: 70px;
    order: 5;

    &:not(:first-child) {
      @media print {
        border-left: 1px solid $sidebar-border-color;
      }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      position: relative;
      min-width: 42px;

      span {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 50%;
        white-space: nowrap;
        transform: rotate(-31deg);
        transform-origin: 0%;
        max-width: 175px;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    &--active {
      font-weight: 500;
      color: #4266f7;
    }

    &--highlighted {
      font-weight: bold;
    }

    a {
      color: #4266f7;
      text-decoration: none;
    }

    &--separator-column {
      border-left: $table-border;
    }
  }

  &__column-label {
    &--clickable {
      border: 1px solid #ddd;
      display: inline-block;
      border-radius: 30px;
      padding: 2px 10px 2px 12px;
      cursor: pointer;

      &::after {
        content: "+";
        font-weight: bold;
        color: #aaa;
        margin-left: 5px;
      }

      &:hover {
        border-color: $primary-color;
        color: $primary-color;
      }

      &.color-map__column-label--active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: white;
        font-weight: 600 !important;

        &::after {
          content: "-";
          color: white;
        }
      }
    }

    &--child {
      background-color: #eee;
      display: inline-block;
      padding: 3px 14px;
      border-radius: 20px;
    }
  }

  &__column-value-with-hover {
    &:hover {
      cursor: pointer;
      background-color: #e9eef5;
      display: block;
    }
  }

  &__row--separator {
    border-top: $table-border;
  }

  &__row--even2 {
    background-color: #edf4fd;
  }

  &__row-container-son {
    .color-map__row:first-child {
      .color-map__row-label2,
      .color-map__result2 {
        box-shadow: none !important;
      }
    }
    .color-map__row:last-child {
      .color-map__row-label2,
      .color-map__result2 {
        box-shadow: inset 0 -10px $shadow-blur-radius $shadow-spread-radius $shadow-color;
      }
    }

    @media print {
      border-top: 1px solid $sidebar-border-color;
    }
  }

  &__row {
    display: flex;

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-direction: row-reverse;
    }

    &--child {
      background-color: #e9eef5 !important;
    }

    &:hover {
      .color-map__result-value {
        opacity: 1;
      }
    }

    a {
      color: #4266f7;
      text-decoration: none;
    }
  }

  &__row-label2 {
    font-family: "GT Haptik", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #124989;
    padding: 10px 10px 10px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
    min-width: 140px;
    right: 0;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 15px;
      width: $legend-mobile-width;
      min-width: $legend-mobile-width;
      max-width: $legend-mobile-width;
      font-size: 13px;
    }

    .toggle-icon {
      display: none;
      margin-left: -5px;
      margin-right: 5px;
      color: #4266f7;
    }

    &--depth-1 {
      padding-left: 20px;
      @media only screen and (max-width: $mobile-breakpoint) {
        padding-left: 8px;
      }
    }

    &--depth-2 {
      padding-left: 44px;
      @media only screen and (max-width: $mobile-breakpoint) {
        padding-left: 30px;
      }
    }

    &--clickable {
      .right-arrow {
        display: inline-block;
      }

      &:hover {
        cursor: pointer;
        background-color: #e9eef5;
      }
    }

    &--active {
      background-color: #e9eef5;

      .right-arrow {
        display: none;
      }

      .down-arrow {
        display: inline-block;
      }
    }
  }

  &__results {
    display: flex;
    flex: 1;
  }

  &__result2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    overflow: hidden;
    height: 40px !important;
    min-width: 70px;
    cursor: pointer;
    order: 5;
    font-weight: 700;
    color: #212121;

    @media print {
      border-left: 1px solid $sidebar-border-color;
    }

    &--placeholder {
      order: 10;
      position: relative;
      overflow: visible;
    }

    &--selected-column {
      .color-map__result-value {
        opacity: 1;
      }
    }

    &--highlighted {
      font-weight: bold;
    }

    &:hover {
      .color-map__result-value {
        opacity: 1;
      }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0;
      min-width: 42px;
    }
  }

  &__result-value {
    background-color: rgba(255, 255, 255, 0.9);
    font-family: "GT Haptik", sans-serif;
    padding: 4px 10px;
    border-radius: 14px;
    font-size: 14px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    transition: opacity 0.2s ease;
    text-align: center;

    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 11px;
      // opacity: 1;
    }
  }

  &__add-school {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    border: 1px dashed $primary-color;
    cursor: pointer;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;

    &:hover {
      background-color: transparentize($primary-color, 0.9);
    }

    .rdr-btn--small {
      padding: 5px 10px;
    }
  }

  &__expansion-button-text {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.visibility-button {
  margin-right: -4px;
  padding: 5px !important;

  &__icon {
    position: relative;
    top: -1px;
    font-size: 18px;
    fill: inherit;
  }
}
.clgm-tab {
  border-bottom: 3px solid #177ed3;
  padding-bottom: 4px;
  margin: 0px 8px;
  align-self: flex-end;
}

.el-dialog__headerbtn {
  top: 0px !important;
}
</style>
