<template>
  <div>
    <div
      v-if="editMode || loading"
      :class="$style.editMode">
      <input
        v-model="currentValue"
        :class="[$style.input, 'rdr-input']">
      <div :class="$style.inputOptions" >
        <spinner
          v-if="loading"
          size="24px"/>
        <div
          v-if="!loading"
          :class="$style.button">
          <rdr-tooltip>
            <div>{{ confirmTooltipText }}</div>
            <i
              slot="reference"
              class="material-icons"
              @click="emitUpdate">check</i>
          </rdr-tooltip>
        </div>
        <div
          v-if="!loading"
          :class="$style.button">
          <rdr-tooltip>
            <div>{{ cancelTooltipText }}</div>
            <i
              slot="reference"
              class="material-icons"
              @click="cancel">close</i>
          </rdr-tooltip>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="$style.showMode">
      <span>{{ value || emptyPlaceholder }}</span>
      <div :class="$style.button">
        <rdr-tooltip>
          <div>{{ editTooltipText }}</div>
          <i
            slot="reference"
            class="material-icons"
            @click="editMode = true">edit</i>
        </rdr-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './utils/spinner.vue';

export default {
  name: 'OptionalInput',
  components: { Spinner },
  props: {
    value: {
      type: String,
      default: '',
    },
    editTooltipText: {
      type: String,
      default: 'Editar',
    },
    confirmTooltipText: {
      type: String,
      default: 'Actualizar',
    },
    cancelTooltipText: {
      type: String,
      default: 'Cancelar',
    },
    emptyPlaceholder: {
      type: String,
      default: 'No disponible',
    },
    validationFunc: {
      type: Function,
      default: () => true,
    },
    loading: {
      type: [Boolean, Number],
      default: 0,
    },
  },
  data() {
    return {
      editMode: false,
      currentValue: this.value,
    };
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.editMode = false;
      }
    },
  },
  methods: {
    emitUpdate() {
      if (this.validationFunc(this.currentValue)) {
        this.$emit('update', this.currentValue);
      }
    },
    cancel() {
      this.editMode = false;
      this.currentValue = this.value;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";

.show-mode {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.edit-mode {
  display: flex;
  align-items: center;
}

.button {
  cursor: pointer;
  margin: 5px;
  border-radius: 50%;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: #e2e2ea;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    margin: 5px;
  }
}

.input {
  width: 0;
  flex: 1 1 auto;
  margin-right: 10px;
  max-height: 32px;
}

.input-options {
  display: flex;
}

@media (max-width: $mobile-breakpoint) {
  .input {
    padding: 0 .5rem !important;
  }

  .input-options {
    flex-direction: column;
  }
}
</style>
