import router from '../router';

export default function updateRouterParam(paramName, value) {
  if (router.currentRoute.params.hasOwnProperty(paramName) &&
      router.currentRoute.params[paramName] !== value) {
    const newRoute = { name: router.currentRoute.name, params: router.currentRoute.params, query: router.currentRoute.query };
    newRoute.params[paramName] = value;
    router.replace(newRoute);
  }
}
