<template>
  <v-select
    ref="autoCompleteSelect"
    :value="value"
    :options="options"
    :on-search="onSearch"
    :placeholder="placeholder"
    :on-change="change"
    :filterable="false"
    class="rdr-select auto-complete-select"
    max-height="320px"
    @search:focus="() => hasSearched = false"
  >
    <template
      slot="option"
      slot-scope="object">
      <div><span class="select-option__name">
        {{ object.label }}
      </span></div>
    </template>
    <span
      slot="no-options"
      @mousedown.stop="">
      <span
        v-if="!hasSearched">
        Escribe para comenzar a buscar.
      </span>
      <span v-if="hasSearched">
        <img src="/noResultados.svg">
        <!-- No hay resultados -->
      </span>
    </span>
  </v-select>
</template>

<script>
export default {
  name: 'AutoCompleteSelect',
  props: {
    value: {
      default: null,
      type: Object,
    },
    placeholder: {
      default: 'Busca',
      type: String,
    },
    selectOptions: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      options: [],
      hasSearched: false,
    };
  },
  methods: {
    change(val) {
      this.$emit('input', val);
      this.options = [];
    },
    label(object) {
      return (object && object.label.toLowerCase()) || '';
    },
    onSearch(query) {
      this.hasSearched = true;
      this.search(query);
    },
    search(query) {
      this.options = this.selectOptions.filter(o => {
        let label = this.label(o);
        if (typeof label === 'number') {
          label = label.toString();
        }
        return label.includes(query.toLowerCase());
      });
      if (!(this.options.length > 0 && this.options[this.options.length - 1].key === 'noSe')) {
        this.options.push({ key: 'noSe', label: 'No sé' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-option {
    &__name {
      margin-right: 10px;
    }
  }
</style>
