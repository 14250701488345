<template>
  <div class="home">
    <sign-up-dialog />
    <div class="home__half home__half-left home__features-display">
      <div class="features">
        <div class="features__container">
          <img
            class="features__logo"
            src="logo-analytics-colegium-extend-blanco.svg">
          <div>
            <p class="features__content">
              Big Data para potenciar la gestión educacional
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home__half home__half-right home__school-form">
      <div class="login-buttons">
        <login-dialog :load-view="true"/>
        <div class="conten-text text-reg">
          ¿Aún no tienes cuenta?
          <span
            class="text-link"
            @click="openSignUpModal">
            Registrarme
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SchoolSelect from '../components/school-select.vue';
import AuthMixin from '../mixins/auth';
import LandingPopover from '../layouts/app/landing-popover.vue';
import LinksMixin from '../mixins/links';
import LoginDialog from '../components/dialogs/login.vue';
import SignUpDialog from '../components/dialogs/sign-up.vue';

export default {
  name: 'HomeView',
  components: {
    LandingPopover,
    LoginDialog,
    SchoolSelect,
    SignUpDialog,
  },
  mixins: [AuthMixin, LinksMixin],
  props: {
    openLogin: {
      type: Boolean,
      // default: false,
      default: true,
    },
    openRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chosenSchool: null,
    };
  },
  computed: {
    ...mapState('ui', ['loading']),
  },
  watch: {
    async chosenSchool(newSchool) {
      if (newSchool) {
        try {
          this.addLoading();
          await this.setChosenSchool(newSchool.id);
          this.$router.push(this.landingLink);
        } catch (_) {
          this.error = true;
        } finally {
          this.removeLoading();
        }
      }
    },
  },
  created() {
    this.fetchGroups();
    this.$freshchat.hideWidget();
    if (this.isLoggedIn) {
      this.$router.push(this.landingLink);
      localStorage.removeItem('menu_data');
      localStorage.removeItem('item_select');
      localStorage.removeItem('item_index');
      this.$freshchat.showWidget();
      const email = this.$store?.state?.auth?.userInfo?.email;
      this.$freshchat.setUserProperties({ email: email });
    // } else if (this.openLogin) {
    //   const seconds = 2000;
    //   setTimeout(function () {
    //     // this.openLoginModal();
    //   }, seconds);
    } else if (this.openRegister) {
      this.signUp();
    }
  },
  methods: {
    openLoginModal() {
      this.$router.push('login');
      this.loginPopUp();
    },
    openSignUpModal() {
      this.$router.push('register');
      this.signUp();
    },
    ...mapMutations('ui', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapActions('options', ['setChosenSchool', 'fetchGroups']),
  },
};
</script>

<style lang="scss">
@import '../../styles/app/variables';
.home {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
    overflow-y: auto;
  }
  &__half {
    flex-grow: 1;
    // flex-basis: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px;
    position: relative;
    text-align: center;
    @media only screen and (max-width: $mobile-breakpoint) {
      flex-basis: auto;
    }
  }
  &__half-left {
    flex-basis: 300px;
  }
  &__half-right {
    flex-basis: 580px;
  }
  &__features-display {
    // background: linear-gradient(#5977ff, #6f64d9);
    background: linear-gradient(180deg, #177ed3 0%, #0e5895 100%);
    color: #fff;
  }
  &__school-form {
    background-color: #f7f6fb;
  }
}
.features {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  &__logo {
    // margin-bottom: 6%;
    width: 78%;
    max-width: 406px;
    margin-top: -20%;
    margin-bottom: 20px;
  }
  &__content {
    font-size: 19px;
    margin-left: 40px;
    margin-right: 40px;
    @media only screen and (min-width: $mobile-breakpoint) {
      margin-left: 80px;
      margin-right: 80px;
    }
  }
  &__footer {
    position: absolute;
    bottom: 7%;
    text-align: center;
    font-size: 16px;
    color: #fff;
    @media only screen and (max-width: $mobile-breakpoint) {
    }
  }
}
.school-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 65px 0 15px 0;
  position: relative;
  * {
    z-index: 2;
  }
  .school-select.rdr-select.v-select {
    &.open .dropdown-toggle {
      border-bottom-color: #fff;
    }
    .dropdown-toggle {
      border-color: $primary-color;
      ::placeholder {
        color: $primary-color;
      }
      input {
        color: $primary-color;
        font-size: 18px;
      }
      i.open-indicator::after {
        color: $primary-color;
      }
    }
    .dropdown-menu {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
  &__select {
    width: 100%;
    max-width: 410px;
    z-index: 3;
    transition: all 0.2s ease-in-out;
    &.open {
      @media only screen and (min-width: $mobile-breakpoint + 1px) {
        transform: translateY(-34px);
      }
    }
  }
  &__lead {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
  .loading-overlay {
    background: #f7f6fb;
    align-items: center;
    z-index: 10;
  }
}
.login-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
  @media only screen and (min-width: $mobile-breakpoint + 1px) {
    right: 30px;
    width: 55%;
  }
  .button-wrapper {
    width: 100%;
    margin: 10px 0px;
    .rdr-btn {
      // font-size: 28px;
      color: $primary-color;
      border: 1px solid $primary-color;
      margin: 0 5px;
      // font-weight: 400;
      border-radius: 24px;
      border-width: 1px;
      padding: 15px 150px;
      font-size: 18px;
      width: 100%;
    }
  }
}
.dot {
  position: absolute;
  border-radius: 50%;
  display: inline-block;
  background-color: #000;
  z-index: 1;
  &--yellow {
    height: 2vw;
    width: 2vw;
    min-height: 20px;
    min-width: 20px;
    left: 4%;
    top: 4%;
    background-color: #ffd370;
  }
  &--gray {
    height: 0.5vw;
    width: 0.5vw;
    min-height: 5px;
    min-width: 5px;
    left: 23%;
    top: 18%;
    background-color: #a0a5b9;
  }
  &--red {
    height: 0.6vw;
    width: 0.6vw;
    min-height: 6px;
    min-width: 6px;
    right: 25%;
    top: 77%;
    background-color: #ff7070;
  }
  &--green {
    height: 1vw;
    width: 1vw;
    min-height: 10px;
    min-width: 10px;
    left: 3%;
    top: 93%;
    background-color: #24d5bd;
  }
  &--olive {
    height: 1.1vw;
    width: 1.1vw;
    min-height: 11px;
    min-width: 11px;
    right: 2%;
    top: 40%;
    background-color: #dbf2d0;
  }
}
.text-reg {
  margin-top: 15px;
  .text-link {
    color: $primary-color;
    font-weight: 500;
    cursor: pointer;
  }
}
</style>
