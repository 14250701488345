const labelCodes = {
  1: 'Bajo',
  2: 'Medio Bajo',
  3: 'Medio',
  4: 'Medio Alto',
  5: 'Alto',
};

export default function (value) {
  return value ? labelCodes[value] : 'No hay información';
}
