<template>
  <div>

    <div
      :style="donutChartStyle"
      :class="[$style.donutChart]">
      <div
        :style="sliceOneStyle"
        :class="[$style.slice, $style.one]"/>

      <div
        :style="sliceTwoStyle"
        :class="[$style.slice, $style.two]"/>

      <div :class="$style.chartCenter">
        <span> {{ percent }}%</span>
      </div>

    </div>
  </div>
</template>

<script>

import interpolate from '../../utils/color-interpolate';

const colormap = interpolate(['#ED050B', '#FFFDCC', '#05BD3C']);

export default {
  name: 'CircularProgressBar',
  props: {
    total: {
      type: Number,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      base: '#DBDBDD',
    };
  },
  computed: {
    color() {
      const min = 80;
      const max = 100;
      const range = min - max;
      const normalizedValue = -Math.max((this.value - min) / range, -1.5); // eslint-disable-line no-magic-numbers
      return colormap(normalizedValue);
    },
    sliceOneStyle() {
      return {
        '-webkit-transform': `rotate(${this.deg1})`,
        transform: `rotate(${this.deg1})`,
        background: this.percent > 50 ? this.color : this.base, // eslint-disable-line no-magic-numbers
      };
    },
    sliceTwoStyle() {
      return {
        '-webkit-transform': `rotate(${this.deg2})`,
        transform: `rotate(${this.deg2})`,
        background: this.percent > 50 ? this.color : this.base, // eslint-disable-line no-magic-numbers
      };
    },
    donutChartStyle() {
      return { background: this.percent > 50 ? this.base : this.color }; // eslint-disable-line no-magic-numbers
    },
    percent() {
      return this.value && this.total ? 100 * this.value / this.total : null; // eslint-disable-line no-magic-numbers
    },
    deg1() {
      return this.percent > 50 ? '90deg' : `${this.percent / 100 * 360 + 90}deg`; // eslint-disable-line no-magic-numbers
    },
    deg2() {
      return this.percent < 50 ? '0deg' : `${(this.percent / 100 * 360)}deg`; // eslint-disable-line no-magic-numbers
    },
  },
};
</script>

<style lang="scss" module>

.donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
  .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.one {
      clip: rect(0 120px 60px 0);
    }
    &.two {
      clip: rect(0 60px 120px 0);
    }
  }
  .chart-center {
    position: absolute;
    border-radius: 50%;
    top: 20px;
    left: 20px;
    width: 80px;
    height: 80px;
    background: #fff;
    span {
      display: block;
      text-align: center;
      font-size: 25px;
      line-height: 80px;
      color: grey;
      font-weight: bold;
    }
  }
}
</style>
