<template>
  <div class="contenScroll">
    <div class="color-map">
      <div v-if="loading">
        <transition name="fade">
          <loading-overlay/>
        </transition>
      </div>
      <div
        v-if="errorEmpty"
        class="color-map__empty">
        <img src="https://colegium-recursos.s3.amazonaws.com/recursos/ilustraciones/ilustracion_0002.svg" height="250">
        <h3>Tenemos inconvenientes en el servicio</h3>
        <p>
          Por favor reportanos éste problmea.
        </p>
      </div>
      <div
        v-else-if="searchEmpty"
        class="color-map__empty">
        <h3>Estamos buscando información...</h3>
        <img src="https://colegium-recursos.s3.amazonaws.com/recursos/ilustraciones/ilustracion_0012.svg" height="250">
      </div>
      <div v-else>
        <template v-if="!keyUndefined">
          <div v-if="results[0].closHeaders.length > 0">
            <!-- ======= INICIO HEADER ====== -->
            <div
              ref="colorMapHeader"
              :class="[
                'color-map__header2',
                ...borderClasses(['right', 'top', 'bottom']),
                { 'with-banner-displacement': !isLoggedIn || isFreeUser },
              ]"
            >
              <div
                :class="['button-wrapper2','hide-mobile', 'stick-col']"
                style="left:0px;background:#EDF4FD;">
                <rdr-tooltip>
                  <span v-if="showResults">Ocultar valores</span>
                  <span v-else>Mostrar valores</span>
                  <button
                    slot="reference"
                    :class="[
                      'rdr-btn',
                      'rdr-btn--small',
                      { 'rdr-btn--primary': showResults },
                      'visibility-button',
                      'hide-print',
                    ]"
                    @click="toggleResults">
                    <i class="visibility-button__icon material-icons">{{ showResults ? 'visibility' : 'visibility_off' }}</i>
                  </button>
                </rdr-tooltip>
                <div class="text-info font-14">
                  Curso
                </div>
              </div>
              <div
                v-for="(colHeader, index) in results[0].closHeaders"
                :key="index"
                class="color-map__column-hdr"
              >
                <div class="color-map__column-row" style="background:#FFF;">
                  <div class="color-map__column-cell">
                    {{ colHeader.day_of_the_week }}
                  </div>
                </div>
                <div class="color-map__column-row">
                  <div class="color-map__column-cell text-info font-12">
                    {{ colHeader.day }} {{ mesSelected }}
                  </div>
                </div>

              </div>
            </div>
            <!-- ======= FIN HEADER ====== -->

            <!-- ======= SUB-HEADER ====== -->
            <div class="color-map__row color-map__row--even2 sub-header">
              <div class="color-map__row-label-month" style="left:0px;background:#EDF4FD">
                Total Colegio
              </div>
              <div
                v-for="(porcen, index0) in results[0].totals"
                :key="index0"
                class="color-map__row color-map__result2"
                :style="getColorPorcen(porcen.porcentaje, 'header', index0)" >
                <template v-if="porcen.porcentaje !== null">
                  <div v-show="showResults" class="color-map__result-values">
                    <div slot="reference">
                      {{ formatNumber(porcen.porcentaje) }}%
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- ======= END SUB-HEADER ====== -->

            <!-- ======= CONTEN TABLE ====== -->
            <div class="color-map__content-with-row-headers">
              <div
                :class="['color-map__content', ...borderClasses(['right', 'bottom'])]"
                @mouseleave="columnHover = null"
              >
                <div
                  v-for="(rowGroup, indexGroup) in results[0].groups"
                  :key="indexGroup"
                  :class="['color-map__row-container', { 'color-map__row-container-son': rowGroup.cursos.length }]">
                  <div class="color-map__row">
                    <div
                      :class="[
                        'color-map__row-label-month',
                        'color-map__row-label--depth-1',
                        'color-map__row-label--child',
                        'color-map__row-label--clickable',
                        'color-map__row--even2',
                        { 'first-row' : indexGroup === 0,
                          'color-map__row-label--active': rowGroup.open,
                          'end-row' : (indexGroup + 1) === results[0].groups.length
                        }
                      ]"
                      :style="rowStyle"
                    >
                      <div class="color-map__expansion-button-text">
                        <template v-if="rowGroup.cursos.length > 1">
                          <i
                            v-show="!rowGroup.open"
                            class="toggle-icon material-icons right-arrow hide-print"
                            @click="toggleRows(indexGroup, 'open')"
                          >add
                          </i>
                          <i
                            v-show="rowGroup.open"
                            class="toggle-icon material-icons down-arrow hide-print"
                            @click="toggleRows(indexGroup, 'close')"
                          >remove
                          </i>
                        </template>
                        <div>
                          <span v-if="rowGroup.cursos.length > 1" slot="reference">{{ rowGroup.grupo_name }}</span>
                          <span v-else slot="reference">{{ rowGroup.cursos[0].curso_name }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="color-map__results">
                      <div
                        v-if="rowGroup.present_percentage"
                        v-for="(column, indexColumn) in rowGroup.present_percentage"
                        :class="[
                          'color-map__row', 'color-map__result2',
                          'color-map__result--selected-column',
                          'color-map__result--no-result',
                          'color-map__result--highlighted'
                        ]"
                        :style="getColorPorcen(column.porcentaje, 'body', indexColumn, indexGroup)"
                        :key="rowGroup.grupo_id"
                      >
                        <template v-if="column.porcentaje !== null">
                          <div v-show="showResults" class="color-map__result-values">
                            <div slot="reference">
                              {{ formatNumber(column.porcentaje) }}%
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>

                  <!-- ======== CURSOS CONTENEDOR ====== -->
                  <template v-if="openCursos">
                    <div
                      v-if="rowGroup.cursos.length"
                      v-for="(curso, indexSon) in rowGroup.cursos"
                      :key="indexSon"
                    >
                      <div
                        v-if="curso.open"
                        class="color-map__row color-map__row--even2"
                      >
                        <div
                          :class="[
                            'color-map__row-label-month',
                            'color-map__row-label--depth-1',
                            'color-map__row-label--child',
                            {'color-map__row-label--active': curso.open}
                          ]"
                          :style="rowStyle"
                        >
                          <div class="color-map__expansion-button-text">
                            <div>
                              <span slot="reference">{{ curso.curso_name }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="color-map__results">
                            <div
                              v-for="(columnC, indexColumnC) in curso.present_percentage"
                              :class="[
                                'color-map__row', 'color-map__result2',
                                'color-map__result--selected-column',
                                'color-map__result--no-result',
                                'color-map__result--highlighted'
                              ]"
                              :style="getColorPorcen(columnC.porcentaje, 'children', indexColumnC, indexSon)"
                              :key="(rowGroup.grupo_id, columnC.porcentaje)"
                            >
                              <template v-if="columnC.porcentaje !== null">
                                <div v-show="showResults" class="color-map__result-values">
                                  <div slot="reference">
                                    {{ formatNumber(columnC.porcentaje) }}%
                                  </div>
                                </div>
                              </template>
                            </div>
                        </div>

                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="color-map__empty">
            <div>
              <img src="/noResultados.svg">
            </div>
          </div>
        </template>
        <template v-else>
          <div class="color-map__empty">
            <div>
              <img src="/noResultados.svg">
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import 'array.prototype.move';
import { mapState, mapGetters } from 'vuex';
// import interpolate from '../utils/color-interpolate';
import AuthMixin from '../mixins/auth';
import PermissionMixin from '../mixins/permission';
import ToLocalTimeMixin from '../mixins/to-local-time';
import UpcomingDialogMixin from '../mixins/upcoming-dialog';
import ValueFormattingMixin from '../mixins/value-formatting';
// import columnLabelShortener from '../utils/label-shortener';

const COLORS = {
  RED: '#ED050B',
  YELLOW: '#FFFDCC',
  GREEN: '#05BD3C',
  WHITE: '#FEFFF2',
};

const ROW_HEADER_WIDTH = 20;
const CELL_HEIGHT = 56;

export default {
  mixins: [
    AuthMixin,
    PermissionMixin,
    ToLocalTimeMixin,
    UpcomingDialogMixin,
    ValueFormattingMixin,
  ],
  props: {
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    results: {
      type: Array,
      default: () => [],
    },
    mesSelected: {
      type: String,
      default: 'Ene',
    },
    defaultOpenRows: {
      type: Array,
      default: () => [],
    },
    defaultOpenColumns: {
      type: Array,
      default: () => [],
    },
    attendance: {
      type: Boolean,
      default: false,
    },
    rowSort: {
      type: String,
      default: '',
    },
    resultsColumnKey: {
      type: String,
      default: 'column_id',
    },
    resultsRowKey: {
      type: String,
      default: 'indicator_id',
    },
    sipDashboardView: {
      type: Boolean,
      default: false,
    },
    comparativePublicResultsView: {
      type: Boolean,
      default: false,
    },
    internalEvaluationsView: {
      type: Boolean,
      default: false,
    },
    attendancePercentageView: {
      type: Boolean,
      default: false,
    },
    normalizationLabel: {
      type: String,
      default: 'norm_value',
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
    errorEmpty: {
      type: [Boolean],
      default: false,
    },
    searchEmpty: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      windowWidth: 0,
      openColumns: [],
      openRows: ['simce'],
      emptyMessage: 'No hay resultados para las opciones seleccionadas.',
      addSigePasswordMessage: 'Debes ingresar la clave SIGE de tus colegios para ver resultados.',
      emptyAttendanceMessage: `Intenta eligiendo otro año.
        \nSi el problema persiste, en la sección Configuración general recuerda ingresar la clave SIGE correspondiente a este RBD.
        \n\nSi necesitas más información, contáctate con nosotros a radar@colegium.com
        \n`,
      columnHover: null,
      yearDefault: 2017,
      groupDefault: 4,
      showResults: true,
      openCursos: true,
      keyUndefined: true,
    };
  },
  computed: {
    ...mapState('options', ['chosenYear', 'chosenMonth']),
    ...mapGetters('options', ['schoolChoicesRemaining']),

    rowStyle() {
      return {
        width: this.maxLabelLength,
        left: this.hasRowHeaders ? `${ROW_HEADER_WIDTH}px` : 0,
      };
    },
    cornerStyle() {
      return { width: `${ROW_HEADER_WIDTH + (this.isBordered ? 1 : 0)}px` };
    },
    showVisibleResultsButton() {
      return this.results.every(r => !!(r.value || r.alternative_text));
    },
  },
  created() {
    if (this.results[0].closHeaders === undefined) { this.keyUndefined = true; }
    else { this.keyUndefined = false; }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.defaultOpenRows) {
      this.defaultOpenRows.forEach(key => this.openRows.push(key));
    }
    if (this.defaultOpenColumns) {
      this.defaultOpenColumns.forEach(id => this.openColumns.push(id));
    }
  },
  methods: {
    getColorPorcen(data, tipo=null, indexCol=null, indexRow=null) {
      if (data === null) return 'background: #e9eef5;';
      if (data === undefined) return 'background: #e9eef5;';
      if(indexCol === null) indexCol = 0;
      let styles = '';
      let border = '';
      let background = 'rgb(238, 21, 24);';
      let indexCal = indexCol + 1;
      let porcen = parseInt(data);
      let palette = [
        'rgb(238, 21, 24)', 'rgb(240, 52, 47)', 'rgb(241, 62, 56)',
        'rgb(244, 102, 87)', 'rgb(245, 116, 97)', 'rgb(247, 146, 121)',
        'rgb(249, 169, 138', 'rgb(250, 188, 154)', 'rgb(252, 218, 177)',
        'rgb(254, 236, 191)', 'rgb(247, 251, 200)', 'rgb(229, 246, 189)',
        'rgb(199, 239, 171)', 'rgb(178, 233, 160)', 'rgb(139, 223, 137)',
        'rgb(128, 220, 131)', 'rgb(81, 209, 104)', 'rgb(69, 205, 97)',
        'rgb(34, 196, 77)', 'rgb(11, 191, 64)', 'rgb(5, 189, 60)',
      ];

      if (tipo === 'body') {
        if (indexCal === this.results[0].groups[indexRow].present_percentage.length) {
          if (indexRow === 0) border = 'border-radius: 0px 8px 0px 0px;';
          if ((indexRow + 1) === this.results[0].groups.length) border = 'border-radius: 0px 0px 8px 0px;';
        }
      }
      if (tipo === 'header') {
        if (indexCol === 0) border = 'border-radius: 8px 0px 0px 8px;';
        if (indexCal === this.results[0].totals.length) border = 'border-radius: 0px 8px 8px 0px;';
      }

      if(porcen >= 80) {
        let posicion = porcen - 80;
        background = palette[posicion];
      }
      styles = 'background: '+background+';'+border+';';
      return styles;
    },

    toggleRows(index, type) {
      this.openCursos = false;
      let objClon = JSON.parse(JSON.stringify(this.results[0].groups));
      if (type === 'open') {
        objClon[index].open = true;
        objClon[index].cursos.forEach((cur) => {
          cur.open = true;
        });
      } else {
        objClon[index].open = false;
        objClon[index].cursos.forEach((cur) => {
          cur.open = false;
        });
      }
      this.openCursos = true;
      this.results[0].groups = JSON.parse(JSON.stringify(objClon));
    },

    toggleResults() {
      this.showResults = !this.showResults;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    borderClasses(borders) {
      return this.isBordered ? borders.map(b => `bordered-${b}`) : [];
    },
    formatNumber(num) {
      return parseInt(num);
    }
  },
};
</script>

<style lang="scss">
  @import "../../styles/app/variables";

  $legend-width: 370px;
  $legend-mobile-width: 120px;
  $shadow-color: #33333350;
  $shadow-with: 5px;
  $shadow-blur-radius: 11px;
  $shadow-spread-radius: -12px;
  $table-border: 1px solid $main-text-color;
  $header-background-color: #FDFDFD;

  .first-row {
    border-radius: 8px 0px 0px 0px;
  }
  .end-row {
    border-radius: 0px 0px 0px 8px;
  }

  .sub-header {
    background: #EDF4FD !important;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px 4px 10px 0px;
  }

  .color-map-row-description-tooltip {
    max-width: 35vw;
    word-break: keep-all;
  }

  .color-map {
    display: inline-block;
    min-width: 100%;
    position: relative;
    min-height: 100px;

    .bordered-right {
      border-right: $table-border;
    }

    .bordered-left {
      border-left: $table-border;
    }

    .bordered-top {
      border-top: $table-border;
    }

    .bordered-bottom {
      border-bottom: $table-border;
    }

    &__empty {
      text-align: center;
    }

    &__header2 {
      display: flex;
      background: #EDF4FD !important;
      color: $primary-color;
      font-weight: bold;
      position: sticky;
      top: 0;
      max-height: 80px;
      z-index: 6;
      margin-bottom: 10px;
      border-radius: 8px;
      padding: 0px 4px 4px 0px;

      @media print {
        border: 1px solid $sidebar-border-color;
        border-bottom: none;
      }

      .button-wrapper2 {
        align-items: center;
        justify-content: center;
        padding: 0px;
        min-width: 140px;
        align-self: stretch;
        text-align:center;
      }
      .stick-col {
        position: sticky;
        z-index: 5;
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        padding-left: 0;
        padding-right: $legend-mobile-width;
        height: 110px;
        align-items: flex-end;
      }

      &.extra-margin {
        @media only screen and (max-width: $mobile-breakpoint) {
        margin-top: 50px;
        }
      }

      &.with-banner-displacement {
        // top: 40px;
      }
    }

    &__content {
      width: 100%;
      @media print {
        border: 1px solid $sidebar-border-color;
        border-top: none;
      }
    }

    &__corner {
      position: sticky;
      left: 0;
      right: 0;
      background-color: $header-background-color;
    }

    &__row-headers-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      position: sticky;
      background-color: $header-background-color;
      z-index: 1;
      left: 0;
      right: 0;

      .bordered-row-header:not(:first-child) {
        border-top: $table-border;
      }

      .row-header {
        transform: rotate(-90deg);
        white-space: nowrap;
      }
    }

    &__content-with-row-headers {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-right: 4px;
    }

    &__column-hdr {
      display: table;
      flex: 1;
      font-family: 'GT Haptik', sans-serif;
      font-weight: 600;
      font-size: 14px;
      padding-left: 1px;
      letter-spacing: 0.5px;
      text-align: center;
      min-width: 70px;
      order: 5;

      &:not(:first-child) {
        @media print {
          border-left: 1px solid $sidebar-border-color;
        }
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        position: relative;
        min-width: 42px;

        span {
          display: block;
          position: absolute;
          bottom: 10px;
          left: 50%;
          white-space: nowrap;
          transform: rotate(-31deg);
          transform-origin: 0%;
          max-width: 175px;
          text-overflow: ellipsis;
          text-align: left;
        }
      }

      &--active {
        font-weight: 500;
        color: #4266F7;
      }

      &--highlighted {
        font-weight: bold;
      }

      a {
        color: #4266F7;
        text-decoration: none;
      }

      &--separator-column {
        border-left: $table-border;
      }
    }

    &__column-row {
      display: table-row;
    }
    &__column-cell {
      display: table-cell;
      vertical-align: middle;
      padding: 5px 0px !important;
    }

    &__column-label {
      &--clickable {
        border: 1px solid #ddd;
        display: inline-block;
        border-radius: 30px;
        padding: 2px 10px 2px 12px;
        cursor: pointer;

        &::after {
          content: "+";
          font-weight: bold;
          color: #aaa;
          margin-left: 5px;
        }

        &:hover {
          border-color: $primary-color;
          color: $primary-color;
        }

        &.color-map__column-label--active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: white;
          font-weight: 600 !important;

          &::after {
            content: "-";
            color: white;
          }
        }
      }

      &--child {
        background-color: #eee;
        display: inline-block;
        padding: 3px 14px;
        border-radius: 20px;
      }
    }

    &__column-value-with-hover{
      &:hover {
          cursor: pointer;
          background-color: #e9eef5;
          display: block;
      }
    }

    &__row--separator {
      border-top: $table-border;
    }

    &__row--even2 {
      background-color: #EDF4FD;
    }

    &__row-container-son {
      .color-map__row:first-child {
        .color-map__row-label-month, .color-map__result2 {
          box-shadow: none !important;
        }
      }
      .color-map__row:last-child {
        .color-map__row-label-month, .color-map__result2 {
          box-shadow: inset 0 -10px $shadow-blur-radius $shadow-spread-radius $shadow-color;
        }
      }

      @media print {
        border-top: 1px solid $sidebar-border-color;
      }
    }

    &__row {
      display: flex;

      @media only screen and (max-width: $mobile-breakpoint) {
        flex-direction: row-reverse;
      }

      &--child {
        background-color: #e9eef5 !important;
      }

      &:hover {
        .color-map__result-values { opacity: 1.0; }
      }

      a {
        color: #4266F7;
        text-decoration: none;
      }
    }

    &__row-label-month {
      font-family: 'GT Haptik', sans-serif;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.5px;
      color: #124989;
      padding: 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: -webkit-sticky;
      position: sticky;
      z-index: 5;
      min-width: 140px;
      max-width: 140px;
      right: 0;

      @media only screen and (max-width: $mobile-breakpoint) {
        padding: 0 15px;
        width: $legend-mobile-width;
        min-width: $legend-mobile-width;
        max-width: $legend-mobile-width;
        font-size: 13px;
      }

      .toggle-icon {
        margin-left: -5px;
        margin-right: 5px;
        color: #4266f7;
      }

      &--depth-1 {
        padding-left: 10px;
        @media only screen and (max-width: $mobile-breakpoint) {
            padding-left: 8px;
        }
      }

      &--depth-2 {
        padding-left: 44px;
        @media only screen and (max-width: $mobile-breakpoint) {
            padding-left: 30px;
        }
      }

      &--clickable {
        .right-arrow {
          display: inline-block;
        }

        &:hover {
          cursor: pointer;
          background-color: #e9eef5;
        }
      }

      &--active {
        background-color: #e9eef5;

        .right-arrow {
          display: none;
        }

        .down-arrow {
          display: inline-block;
        }
      }
    }

    &__results {
      display: flex;
      flex: 1;
      }

    &__result2 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      overflow: hidden;
      height: 40px !important;
      min-width: 70px;
      cursor: pointer;
      order: 5;
      font-weight: 700;
      color: #212121;

      @media print {
        border-left: 1px solid $sidebar-border-color;
      }

      &--placeholder {
        order: 10;
        position: relative;
        overflow: visible;
      }

      &--highlighted {
        font-weight: bold;
      }

      &:hover {
        .color-map__result-values { opacity: 1.0; }
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        padding: 0;
        min-width: 42px;
      }
    }

    &__result-values {
      background-color: rgba(255,255,255,.9);
      font-family: 'GT Haptik', sans-serif;
      padding: 4px 10px;
      border-radius: 14px;
      font-size: 14px;
      box-shadow: 0px 2px 10px rgba(0,0,0,.1);
      opacity: 0.9;
      transition: opacity .2s ease;
      text-align: center;

      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 11px;
        // opacity: 1;
      }
    }

    &__add-school {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100px;
      border: 1px dashed $primary-color;
      cursor: pointer;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;

      &:hover {
        background-color: transparentize($primary-color, 0.9);
      }

      .rdr-btn--small {
        padding: 5px 10px;
      }
    }

    &__expansion-button-text {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .visibility-button {
    margin-right: -4px;
    padding: 5px !important;

    &__icon {
      position: relative;
      top: -1px;
      font-size: 18px;
      fill: inherit;
    }
  }
  .clgm-tab {
    border-bottom: 3px solid #177ED3;
    padding-bottom: 4px;
    margin: 0px 8px;
    align-self: flex-end;
  }

  .el-dialog__headerbtn {
    top: 0px !important;
  }
  .text-info {
    color: #505068 !important;
  }
  .font-14 {
    font-size: 14px !important;
  }
  .font-12 {
    font-size: 12px !important;
  }
</style>
