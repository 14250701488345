<template>
  <div v-if="loading || error">
    <transition name="fade">
      <loading-overlay v-if="loading"/>
    </transition>
    <div v-if="error">
      <strong>Ocurrió un error. Intenta de nuevo.</strong>
    </div>
  </div>
  <div
    v-else
    class="report__container">
    <div class="report__row">
      <div class="report__block--half">
        <h2 class="info__datum">Asistencia mensual del curso</h2>
        <historic-chart
          :columns="chartColumns"
          :rows="chartRows"
          :view-window="chartFrame"
          :formatter-params="chartFormatter"
          :v-axis-format="chartFormat"
          :height="300"
          label-type="string"
        />
        <div class="info__chart-legend">
          <div
            v-for="(column, index) in chartColumns"
            :key="index"
            class="info__column">
            <div
              :style="{ borderColor: chartColors[index] }"
              class="info__color"
            />
            <div class="info__column-name">{{ column }}</div>
          </div>
        </div>
      </div>
      <div class="report__block--half">
        <h2 class="info__datum">Asistencia por día del curso</h2>
        <table class="attendance__daily-percentage-table">
          <thead>
            <tr>
              <th>Lun</th>
              <th>Mar</th>
              <th>Mié</th>
              <th>Jue</th>
              <th>Vie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="day in 5"
                :key="day">
                {{ dailyPercentages[day] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="button__container">
      <button
        class="rdr-btn rdr-btn--small rdr-btn--primary"
        @click="link(attendanceByStudentLink, 'attendance')">
        Ver más detalle
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import HistoricChart from '../../components/historic-chart.vue';
import ResultsApi from '../../api/results.js';
import { MONTHS } from '../../utils/months';
import GRAPH_COLORS from '../../utils/graph-colors';
import LinksMixin from '../../mixins/links';

export default {
  name: 'GroupAttendanceView',
  components: {
    'historic-chart': HistoricChart,
  },
  mixins: [LinksMixin],
  props: {
    chosenSchool: {
      type: String,
      default: '',
    },
    chosenGroups: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      error: false,
      groupResults: [],
      levelResults: [],
      schoolResults: [],
      dailyResults: [],
      chartFormat: '#\'%\'',
      chartColors: GRAPH_COLORS,
      chartFormatter: { groupingSymbol: '', fractionDigits: 1, suffix: '%' },
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenYear', 'groups']),
    options() {
      return {
        chosenSchool: this.chosenSchool,
        chosenGroup: this.chosenGroups,
        chosenYear: this.chosenYear,
      };
    },
    TDGroupId() {
      return this.groups.find(g => g.key === 'TD').id;
    },
    chartRows() {
      return this.months.map(month => {
        const monthLabel = MONTHS[month - 1];
        const schoolResult = this.schoolResults.find(result => result.month === month);
        const levelResult = this.levelResults.find(result => result.month === month);
        const groupResult = this.groupResults.find(result => result.month === month);
        return [
          monthLabel,
          schoolResult && (schoolResult.value),
          levelResult && (levelResult.value),
          groupResult && (groupResult.value),
        ];
      });
    },
    chartFrame() {
      return { max: 100, min: this.chartRows.some(r => [1, 2, 3].some(i => r[i] < 70)) ? 0 : 70 }; // eslint-disable-line no-magic-numbers
    },
    chartColumns() {
      return [
        'Colegio',
        'Nivel',
        this.chosenGroups.short_name,
      ];
    },
    months() {
      return [
        ...new Set(
          this.schoolResults.concat(this.levelResults, this.groupResults)
            .map(r => r.month).filter(month => month !== 0)
        ),
      ].sort((a, b) => a - b);
    },
    monthsLabels() {
      return this.months.map(month => MONTHS[month - 1]);
    },
    dailyPercentages() {
      const dayPercentageMap = {};
      this.dailyResults.forEach(r => {
        dayPercentageMap[r.extra_info.day_of_week] = `${Math.round(r.value)}%`;
      });

      return dayPercentageMap;
    },
  },
  watch: {
    options: {
      async handler() {
        await this.fetchResults();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapMutations('ui', { changeOpenedSidebarItem: 'changeOpenedSidebarItem' }),
    async fetchResults() {
      this.addLoading();
      const params = {
        school_ids: this.chosenSchool, // eslint-disable-line camelcase
        report_key: 'attendance', // eslint-disable-line camelcase
        years: this.chosenYear,
      };
      try {
        await this.getResultsForAllGroups(params);
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
      }
    },
    async getResultsForAllGroups(params) {
      /* eslint-disable camelcase */
      const promises = [];
      this.getApiResults(
        { ...params, group_ids: this.TDGroupId }, 'schoolResults', promises
      );
      this.getApiResults(
        { ...params, group_ids: this.chosenGroups.parent_id }, 'levelResults', promises
      );
      this.getApiResults(
        { ...params, group_ids: this.chosenGroups.id }, 'groupResults', promises
      );
      this.getApiResults(
        { ...params, group_ids: this.chosenGroups.id, report_key: 'attendance-aggregate-by-day' },
        'dailyResults',
        promises
      );
      /* eslint-enable camelcase */

      await Promise.all(promises);
    },
    getApiResults(params, dataStorage, promises) {
      promises.push(ResultsApi.getResults(params)
        .then(({ body }) => {
          this[dataStorage] = this.resultsWithMonth(body.results_with_stats);
        }));
    },
    resultsWithMonth(resultsWithStats) {
      return resultsWithStats.results.map(r => {
        const evaluation = resultsWithStats.evaluations.find(e => e.id === r.evaluation_id);

        return { ...r, month: evaluation.month };
      });
    },
    link(link, sidebarItemKey) {
      this.changeOpenedSidebarItem(sidebarItemKey);
      this.$router.push(link);
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../styles/app/variables";

.attendance {
  &__daily-percentage-table {
    width: 100%;
    margin-left: auto;
    border-radius: 5px;
    border: 1px solid $settings-block-emphasis-background;

    & th {
      background-color: $settings-block-emphasis-background;
      font-weight: bold;
      padding: 5px;
      border: 1px solid $settings-block-emphasis-background;
    }

    & td {
      text-align: center;
      font-size: 26px;
      padding: 5px;
    }
  }
}
.report {
  &__block {
    &--half {
      flex-basis: 49%;
    }
  }
}

.button__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
}
</style>

