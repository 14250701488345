import Vue from 'vue';

export default {
  administratorReport(year) {
    return Vue.http.get(`/api/v1/enrollment_dashboard/administrator_report.json?year=${year}`);
  },
  getOptions() {
    return Vue.http.get('/api/v1/enrollment_dashboard/options.json');
  },
};
