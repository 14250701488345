import startCase from './filters/start-case';
import cleanSchoolName from './filters/clean-school-name';
import gseName from './filters/gse-name';

/* eslint-disable max-statements */

export default {
  install(Vue) {
    Vue.filter('startCase', startCase);
    Vue.filter('upperCase', value => value.toUpperCase());
    Vue.filter('lowerCase', value => value.toLowerCase());
    Vue.filter('cleanSchoolName', cleanSchoolName);
    Vue.filter('gseName', gseName);
    Vue.filter('ceil', Math.ceil);
    Vue.filter('floor', Math.floor);
    Vue.filter('round', Math.round);
    Vue.filter('percentage', value => `${Math.round(value)}%`);
    Vue.filter('percentageWithOneDecimal', value => `${value.toLocaleString('es-CL', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}%`);
    Vue.filter('percentageWithTwoDecimals', value => `${value.toLocaleString('es-CL', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}%`);
    Vue.filter('number', value => value.toLocaleString('es-CL'));
    Vue.filter('numberOnly', value => (value.toLocaleString('es-CL').match(/^\d*[.,]\d+$/) ? value : ''));
    Vue.filter('numberWithTwoDigits', value => value.toLocaleString('es-CL', { minimumIntegerDigits: 2 }));
    Vue.filter('numberWithMaxOneDecimal', value => value.toLocaleString('es-CL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }));
    Vue.filter('numberWithOneDecimal', value => value.toLocaleString('es-CL', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }));
    Vue.filter('numberWithTwoDecimals', value => value.toLocaleString('es-CL', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }));
    Vue.filter('clp', value => `$${value.toLocaleString('es-CL')}`);
  },
};
