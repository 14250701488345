<template>
  <div class="auth">
    <div class="auth__menu" v-if="!isLoggedIn">
      <a class="rdr-btn auth__login-button auth__menu-button" @click="loginPopUp">
        <i class="rdr-btn__icon material-icons">account_circle</i>
        <span class="login-button">Iniciar sesión</span>
      </a>
      <router-link
        v-if="proQuote"
        class="rdr-btn auth__menu-button"
        :to="proQuoteLink">
        <span class="login-button">Cotiza</span>
        <i class="rdr-btn__icon material-icons">attach_money</i>
      </router-link>
    </div>
    <div @click="openDropdown" class="auth__account" v-if="isLoggedIn">
      <span class="auth__account-name" v-if="currentUser">Hola, {{ currentUser.name }}</span>
      <i class="auth__account-icon material-icons">account_circle</i>
      <i class="auth__account-arrow material-icons">keyboard_arrow_down</i>
    </div>
    <transition name="dropdown">
      <div class="auth__dropdown" v-if="showDropdown">
        <router-link :to="settingsLink" class="auth__dropdown-link">Configuración</router-link>
        <a @click="logout" href="javascript:void(0)" class="auth__dropdown-link">Cerrar sesión</a>
      </div>
    </transition>
  </div>
</template>

<script>
  import AuthMixin from '../../mixins/auth';

  export default {
    data() {
      return {
        showDropdown: false,
      };
    },
    props: {
      proQuote: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [AuthMixin],
    methods: {
      openDropdown() {
        if (!this.showDropdown) {
          this.showDropdown = true;
          setTimeout(() => {
            const clickTrap = (event) => {
              this.showDropdown = false;
              window.removeEventListener('click', clickTrap, false);
            };
            window.addEventListener('click', clickTrap, false);
          }, 0);
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

  .auth {
    display: inline-flex;
    cursor: pointer;
    color: #555E81;
    position: relative;

    &__menu {
      // display: inline-flex;
      display: flex;
      justify-content: center;

      &-button{
        display: flex;
        flex-grow: 1;

        &:first-child:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-width: 1px;
        }

        &:not(:first-child):not(:last-child) {
          border-left-width: 1px;
          border-right-width: 1px;
          border-radius: 0;
        }

        &:last-child:not(:first-child) {
          border-left-width: 1px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &:hover {
      color: $primary-color;
    }

    &__account {
      display: flex;
      align-items: center;
    }

    &__account-name {
      margin-right: 8px;
      font-family: 'GT Haptik', sans-serif;
      font-size: 14px;
    }

    &__account-icon {
      font-size: 32px;
    }

    &__dropdown {
      position: absolute;
      background-color: white;
      padding: 6px 0;
      font-size: 14px;
      width: 100%;
      top: 40px;
      border-radius: 2px;
      box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
      z-index: 20;
    }

    &__dropdown-link {
      text-decoration: none;
      padding: 6px 15px;
      display: block;
      font-size: 14px;
      color: #555E81;

      &:hover {
        background-color: #eee;
        color: $primary-color;
      }
    }
  }

  .dropdown-enter-active, .dropdown-leave-active {
    transition: opacity .2s ease, transform .2s ease;
  }
  .dropdown-enter, .dropdown-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
</style>
