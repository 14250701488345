<template>
  <rdr-popover
    v-model="visible"
    :disabled="disabled"
    :width="200"
    :popper-class="`desktop-only  ${$style.popover}`"
    :hide="disablePopoverAfterFirstShow"
    arrow-size="big"
    placement="top"
    trigger="click"
    transition="el-zoom-in-bottom">
    <slot slot="reference"/>
    <div>
      <p :class="$style.contentPopover">
        ¡Comienza tu prueba <strong>gratis aquí</strong>!
      </p>
    </div>
  </rdr-popover>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RdrPopover from '../../components/base/popover.vue';

export default {
  name: 'LandingPopover',
  components: { 'rdr-popover': RdrPopover },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    ...mapState('ui', {
      display: 'displayLandingPopover',
    }),
    ...mapState('auth', ['googleAuthInfo']),
    visible: {
      get() { return this.display; },
      set(value) { this.setVisibility(value); },
    },
  },
  mounted() {
    if (!this.googleAuthInfo) {
      setTimeout(this.show, 1000); // eslint-disable-line no-magic-numbers
      setTimeout(this.hide, 6000); // eslint-disable-line no-magic-numbers
    }
  },
  methods: {
    ...mapMutations('ui', {
      show: 'showLandingPopover',
      hide: 'hideLandingPopover',
      setVisibility: 'setLandingPopoverVisibility',
    }),
    disablePopoverAfterFirstShow() {
      this.disabled = true;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.popover {
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 8px;
}
.content-popover {
	color: #87878e;
	font-size: 17px;
	margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  text-align: center;
}
</style>
