import Vue from 'vue';

export default {
  searchStudents(params, page = 1, limit = 50) { // eslint-disable-line no-magic-numbers
    return Vue.http.get('/api/v1/evaluable_students.json', {
      params: {
        page,
        limit,
        ...params,
      },
    });
  },
  getStudent(id) {
    return Vue.http.get(`/api/v1/evaluable_students/${id}.json`);
  },
  countStudents(params) {
    return Vue.http.get('/api/v1/evaluable_students/count', {
      params: {
        ...params,
      },
    });
  },
};
