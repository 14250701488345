import { Format, INDICATOR_FORMAT_TO_ENUM } from '../utils/result-formats';

const FORMAT_FILTERS = {
  [Format.ROUND]: 'round',
  [Format.FLOOR]: 'floor',
  [Format.ONE_DECIMAL]: 'numberWithOneDecimal',
  [Format.TWO_DECIMALS]: 'numberWithTwoDecimals',
  [Format.PERCENTAGE]: 'percentage',
  [Format.PERCENTAGE_WITH_ONE_DECIMAL]: 'percentageWithOneDecimal',
  [Format.PERCENTAGE_WITH_TWO_DECIMALS]: 'percentageWithTwoDecimals',
};

export default {
  methods: {
    formatValue(value, format) {
      const filter = this.$options.filters[FORMAT_FILTERS[format]];
      if (!filter) return value;
      return filter(value);
    },
    formatValueWithIndicator(value, indicatorFormat) {
      return this.formatValue(value, INDICATOR_FORMAT_TO_ENUM[indicatorFormat]);
    },
  },
};
