<template>
  <paywall
    :permitted="isLoggedIn"
    :free="isFreeUser"
    restricted
    transparent>
  
    <div id="downTable" class="results-colormap">
      <div class="results-colormap__color-map">
        <color-map
          :class="{ 'hide-print': !isProUser }"
          :loading="loading"
          :rows="indicators"
          :columns="groupsAsColumns"
          :results="results"
          :hide-empty-columns="true"
          :show-school-picker="false"
          :disable-school-adding="true"
          results-column-key="group_id"
        />
      </div>
      <share-whatsapp/>

      <color-map-footer v-if="isLoggedIn && indicators.length > 0"/>
    </div>
    <color-map
      slot="mock"
      :rows="mockColorMapData.indicators"
      :columns="mockColorMapData.columns"
      :results="mockColorMapData.results"
      :results-column-key="mockColorMapData.resultsColumnKey"
      :default-open-rows="mockColorMapData.openIndicators"
      :disable-school-adding="true"
      class="color-map--pro"
    />
  </paywall>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../components/results/color-map-footer.vue';
import AuthMixin from '../../mixins/auth';
import MockDataMixin from '../../mixins/mock-data';
import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';
import UpcomingDialogMixin from '../../mixins/upcoming-dialog';

export default {
  name: 'SchoolColorMapView',
  components: { ColorMapFooter },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin, UpcomingDialogMixin],
  data() {
    return {
      emptyMessage: 'No tenemos resultados para la combinación año/nivel de los colegios seleccionados.',
    };
  },

  computed: {
    ...mapState('results', ['loading']),
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('options', ['chosenSchoolId']),
    ...mapGetters('results', {
      results: 'results',
      indicators: 'resultsIndicators',
    }),
    ...mapGetters('options', ['groups']),
    groupsAsColumns() {
      return this.groups.map(group => ({
        id: group.id,
        label: group.short_name,
        parent_id: group.parent_id, // eslint-disable-line camelcase
      }));
    },
  },
  mounted() {
    this.openSchoolSelect();
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    ...mapActions('ui', ['openDialog']),
    openSchoolSelect() {
      if (!this.chosenSchoolId) {
        this.openDialog('schoolSelect');
      }
    },
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true });
      this.setReportKey('color-map');
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";
</style>
