<template>
  <div id="downTable" class="results-colormap">
    <div class="conten-filters">
      <div class="ttl-panel">Riesgo AC vs AC</div>
      <div class="sub-filters">
        <div class="conten-picker-evo">
          <rdr-picker
            v-if="selectCurso"
            v-model="chosenStageCurso"
            :options="optionLevels"
            label-property="name"
            value-property="key"
            picker-style="round"/>
        </div>
        <div class="conten-picker-evo">
          <rdr-picker
            v-model="chosenStage"
            :options="optionEvol"
            :loading="0"
            label-property="name"
            value-property="key"
            picker-style="round"/>
        </div>
      </div>
    </div>

    <div v-if="loadGraph" class="contenGraficas">
      <div v-if="loading">
        <transition name="fade">
          <loading-overlay/>
        </transition>
      </div>
      <div v-else>
        <template v-if="loadGraph">
          <bar-graph
            :data-grph="dataReport"
            :text-vert="txtVert"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>
<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';
import AuthMixin from '../../mixins/auth';
import RdrPicker from '../../components/utils/picker';
import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';
import MockDataMixin from '../../mixins/mock-data';
import barGraph from '../../components/bar-graph.vue';

export default {
  name: 'RiskView',
  components: {
    RdrPicker,
    barGraph,
  },
  mixins: [AuthMixin, PermissionMixin, ResultsMixin, MockDataMixin],
  data() {
    return {
      StageKey: 1,
      StageKeyCurso: 1,
      firstLoad: false,
      loadGraph: true,
      dataReport: {},
      optionEvol: [
        {
          'key': 1,
          'name': 'Evolución por ciclo'
        },
        {
          'key': 2,
          'name': 'Evolución por nivel'
        },
        {
          'key': 3,
          'name': 'Evolución por mes'
        },
        {
          'key': 4,
          'name': 'Evolución por Red de Colegios'
        },
      ],
      optionLevels: [],
      temporalData: {
        "Pre Kinder": {
            "axisX": [
                "Pre Kinder A",
                "Pre Kinder B",
                "Pre Kinder C"
            ],
            "cronico": [
                64,
                34,
                44
            ],
            "riesgo": [
                23,
                23,
                21
            ]
        },
        "Kinder": {
            "axisX": [
                "Kinder A",
                "Kinder B",
                "Kinder C"
            ],
            "cronico": [
                64,
                34,
                44
            ],
            "riesgo": [
                23,
                23,
                21
            ]
        },
        "1 Basico": {
            "axisX": [
                "1 Basico A",
                "1 Basico B",
                "1 Basico C"
            ],
            "cronico": [
                64,
                34,
                44
            ],
            "riesgo": [
                23,
                23,
                21
            ]
        }
      },
      selectCurso: false,
      onWatch: true,
      txtVert: false,
    };
  },
  computed: {
    ...mapState('results', {
      resultsLoading: 'loading',
    }),
    ...mapGetters('options', ['groups']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      resultsGetter: 'results',
    }),
    loading() { return !!this.resultsLoading; },
    results() {
      return  this.resultsGetter;
    },

    chosenStage: {
      get() {
        if(this.firstLoad) {
          this.loadGraph = false;
        }
        this.firstLoad = true;
        let key = parseInt(this.StageKey);
        let objMes = this.optionEvol.find(stage => stage.key === key);
        return objMes;
      },
      set(value) {
        this.loadGraph = false;
        this.StageKey = parseInt(value);
        this.selectCurso = false;
        let self = this;
        setTimeout(function() {
          self.paramsGraph();
        }, 600);
      },
    },

    chosenStageCurso: {
      get() {
        let key = parseInt(this.StageKeyCurso);
        let objCurso = this.optionLevels.find(stage => stage.key === key);
        return objCurso;
      },
      set(value) {
        this.loadGraph = false;
        this.onWatch = false;
        this.StageKeyCurso = parseInt(value);
        let self = this;
        setTimeout(function() {
          self.paramsGraphCurso(value);
        }, 600);
      },
    },
  },
  watch: {
    results: {
      handler(newValue, oldValue) {
        if (this.onWatch) {
          this.paramsGraph();
        }
      },
    },
  },
  methods: {
    paramsGraph() {
      if(this.results.length > 0) {
        this.txtVert = false;
        switch (this.StageKey) {
          case 1:
            this.dataReport = this.results[0];
            break;
          case 2:
            this.dataReport = this.results[1];
            this.getOptionCurso();
            break;
          case 3:
            this.dataReport = this.results[2];
            break;
          case 4:
            this.txtVert = true;
            this.dataReport = this.results[3];
            break;
        }

        let self = this;
        setTimeout(function() {
          self.loadGraph = true;
        }, 500);
      }
    },

    paramsGraphCurso(valSelect) {
      if (valSelect !== '1') {
        let arraySelect = [];
        arraySelect.push(this.chosenStageCurso.name);
        const filterCurso = Object.keys(this.results[4])
            .filter(key => arraySelect.includes(key))
            .reduce((obj, key) => {
                obj[key] = this.results[4][key];
                return obj;
          }, {});
        let objResult = Object.values(filterCurso);
        this.dataReport = objResult[0];
      } else {
        this.dataReport = this.results[1];
      }
        this.loadGraph = true;
    },

    getOptionCurso() {
      let arrayTemp = Object.keys(this.results[4]);
      let arrayObjTemp = [
        {
          'key': 1,
          'name': 'Todos los cursos'
        }
      ];
      arrayTemp.forEach((val, key) => {
        let objTemp = {}
        objTemp = {
          'key': key+2,
          'name': val
        },
        arrayObjTemp.push(objTemp);
      });
      this.optionLevels = arrayObjTemp;
      this.selectCurso = true;
    },

    ...mapMutations('options', { setFetchReady: 'SET_FETCH_READY' }),
    toggleFetchReady() {
      this.setFetchReady(false);
      this.$nextTick(() => this.setFetchReady(true));
    },

    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true });
      this.setReportKey('risk-absenteeism-complete');
      let self = this;
      setTimeout(function() {
        self.loadGraph = true;
      }, 500);
    },
  }
};
</script>

<style>
  @import "../../../styles/app/variables";
  .ttl-panel {
    float: left;
    padding-top: 26px !important;
    font-size: 14px;
    color: #212121;
    font-weight: 700;
  }
  .conten-filters {
    margin-bottom: 30px;
    height: 60px;
  }
  .sub-filters {
    width: 60%;
    float: right;
    text-align: right;
    max-height:45px;
  }
  .conten-picker-evo {
    padding-top: 16px;
    display: inline-block;
    width: 220px;
  }
</style>
