<template>
  <div>
    <div v-if="isLoggedIn && hasSipDashboardPermission">
      <div id="downTable" class="results-colormap">
        <div class="results-colormap__color-map">
          <color-map
            :loading="loading"
            :rows="indicators"
            :columns="yearsAsColumns"
            :results="results"
            :hide-empty-columns="true"
            :show-school-picker="false"
            :latest-update-hover="true"
            :disable-school-adding="true"
            :grades="true"
            results-column-key="evaluation_id"
          />
        </div>
        <share-whatsapp/>
        <template v-if="results.length !== 0">
          <color-map-footer
            :footer-bottom-paragraphs="footerParagraphs"
            text="La gama de colores representa el valor de los indicadores de la siguiente forma:"
            high="sobre la referencia"
            average="similar a la referencia"
            low="bajo la referencia"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';

export default {
  name: 'SipDashboardBySchoolNetworkView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [],
    };
  },
  computed: {
    ...mapState('options', ['years']),
    ...mapState('results', ['loading']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      indicators: 'resultsIndicators',
      storeResults: 'results',
    }),
    results() {
      return this.storeResults.map(result => {
        const indicator = this.indicators.find(i => i.id === result.indicator_id);

        return { ...result, column_id: indicator.id }; // eslint-disable-line camelcase
      });
    },
    yearsAsColumns() {
      const columns = [];

      this.evaluations.forEach(evaluation => {
        columns.push({
          id: evaluation.id,
          label: evaluation.year,
        });
      });

      return columns.sort((a, b) => a.label - b.label);
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    login() {
      this.$store.dispatch('ui/openLoginDialog', true);
    },
    setViewParams() {
      this.setReportParams({ 'school_network_ids': true });
      this.setReportKey('sip-kpi-by-school-network');
    },
  },
};
</script>
