<template>
  <div class="settings rdr-view">
    <!-- <h1>Configuración</h1> -->
    <div class="settings__section settings__section--horizontal">
      <user-info
        :button-method="openPasswordMenu"
        button-name="Cambiar mi contraseña en Radar Escolar"/>
      <el-dialog
        :visible.sync="showChangePassword"
        append-to-body>
        <h2 slot="title">Cambio de contraseña actual</h2>
        <p>Cambio de contraseña de tu usuario de Radar</p>
        <password-settings/>
      </el-dialog>
    </div>
    <div
      v-if="!isFreeUser"
      class="settings__section">
      <schools-settings :open-school-id="openSchoolId"/>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import PasswordSettings from '../components/settings/password.vue';
import SchoolsSettings from '../components/settings/schools.vue';
import UserInfo from '../components/settings/user-info.vue';
import PermissionMixin from '../mixins/permission';

export default {
  name: 'SettingsView',
  components: {
    'password-settings': PasswordSettings,
    'schools-settings': SchoolsSettings,
    'user-info': UserInfo,
    'el-dialog': Dialog,
  },
  mixins: [PermissionMixin],
  props: {
    openSchoolId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showChangePassword: false,
    };
  },
  methods: {
    openPasswordMenu() {
      this.showChangePassword = !this.showChangePassword;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/app/variables";

.el-dialog__body {
  padding: 20px 30px;
}
.el-dialog__header {
  margin-top: 0px;
  margin-bottom: 0px;
}

.settings {
  max-width: $desktop-max-width;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 100;
    font-size: 28px;

    @media only screen and (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    border: 1px solid $settings-border-color;
    border-radius: 5px;
    background-color: $settings-section-background;

    &--horizontal {
      @media only screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
      }

      .settings__block {
        flex-basis: 100%;
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    line-height: 2em;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 15px;
    }

    &--emphasis {
      // background-color: $settings-block-emphasis-background;
      background-color: #E7EFFF;
    }

    &-element {

      &:not(:first-child) {
        margin-top: 25px;
      }

      strong {
        display: block;
      }
    }
  }
}

.el-dialog {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40%;
  margin-left: 60%;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 100%;
}

h2 {
  margin-top: 0px;
}

@media (max-width: $mobile-breakpoint) {
  // Adapt element-ui modal to mobile
  .el-dialog, .dialog {
    width: 100% !important;
    height: 100%;
  }
  .el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
</style>
