<template>
  <attendance-bar-chart
    :disable-options="disableOptions"
    :label-text="labelText"
    :month-joiner="monthJoiner"
    :chart-props="chartProps"/>
</template>

<script>
import AttendanceBarChart from '../attendance-bar-chart.vue';

export default {
  name: 'AttendanceLastYearComparisonBySchoolView',
  components: { AttendanceBarChart },
  data() {
    return {
      disableOptions: ['course'],
      labelText: 'Asistencia promedio',
      monthJoiner: 'de',
      chartProps: {
        colors: ['#9897ff', '#3839ff'],
        reportKey: 'last-year-comparison',
      },
    };
  },
};
</script>

<style lang="scss" module>
</style>
