<template>
  <el-dialog
    :visible.sync="show"
    class="radar-dialog password-feedback-dialog">
    <div
      v-if="feature"
      class="radar-dialog__title">
      <span>{{ feature.text }}</span>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';

export default {
  name: 'PasswordFeedbackDialog',
  components: {
    'el-dialog': Dialog,
  },
  computed: {
    featureKey() {
      return this.$store.state.ui.dialogs.passwordFeedback.feature;
    },
    feature() {
      return this.$store.state.ui.dialogs.passwordFeedback.features[this.featureKey];
    },
    show: {
      get() { return this.$store.state.ui.dialogs.passwordFeedback.open; },
      set(value) { this.$store.commit({ type: 'ui/SET_DIALOG_STATE', dialog: 'passwordFeedback', state: value }); },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";
</style>
