import introJs from 'intro.js';
import safeNavigate from '../../utils/safe-navigate';
import menuItems from '../../layouts/app/menu';
import { itemHeight } from '../../layouts/app/sidebar-utils';

const MENU_PARENT_HEIGHT = 64;
const MENU_CHILD_HEIGHT = 40;
const INTROJS_HELPER_PADDING = 10;

function initialState() {
  return {
    active: false,
    menuOpenItem: null,
    initialMenuOpenItem: null,
  };
}

function setHelperLayerStyle(state, getters, item) {
  const helperLayer = document.getElementsByClassName('introjs-helperLayer')[0];
  const openItemHeight = itemHeight(item, item.key, getters.userPermissions);
  let top = parseInt(helperLayer.style.top.slice(0, -2), 10); // eslint-disable-line no-magic-numbers
  if (menuItems.indexOf(item) > menuItems.indexOf(state.menuOpenItem)) {
    const lastItemChildrenHeight = itemHeight(
      state.menuOpenItem,
      state.menuOpenItem.key,
      getters.userPermissions
    ) - MENU_PARENT_HEIGHT;
    top -= lastItemChildrenHeight;
  }
  if (item.children.length < state.menuOpenItem.children.length) {
    top += (state.menuOpenItem.children.length - item.children.length) * MENU_CHILD_HEIGHT;
  }
  helperLayer.style.height = `${openItemHeight + INTROJS_HELPER_PADDING}px`;
  helperLayer.style.top = `${top}px`;
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    tutorial(_, __, rootState) {
      const psuGroupKey = '12';

      return {
        guest: {
          colorMapComparative: {
            step: 1,
            description: 'Hola! Bienvenid@ a Radar. En este tablero estás viendo los resultados \
            de tu colegio en comparación a colegios de similar grupo socioeconómico (GSE)',
          },
          groupPicker: {
            step: 2,
            description: 'Para ver más resultados, prueba cambiando el nivel acá',
          },
          menu: {
            overview: {
              step: 3,
              description: 'Podrás ver más información seleccionando en este panel. Regístrate y \
              accede a todas las opciones gratuitas',
            },
          },
        },
        free: {
          menu: {
            overview: {
              step: 1,
              description: 'Hola! acá encontrarás toda la información y herramientas que ofrece \
              Radar. Con Radar ahorras tiempo, potencias tus decisiones y puedes apoyar en forma \
              personalizada a tus estudiantes. Tu cuenta gratuita permite acceder a SIMCE - PSU pero \
              ¡Radar tiene mucho más!',
            },
          },
          quoteButton: {
            step: 2,
            description: 'Cotiza acá',
          },
          doubtButton: {
            step: 3,
            description: 'O contáctanos acá para agendar un demo',
          },
        },
        pro: {
          menu: {
            overview: {
              step: 1,
              description: 'Hola! Acá encontrarás el menú para acceder a toda la información clave \
              que tiene Radar ¡Explora todas las secciones y subsecciones! Verás que hay información \
              muy útil para tu trabajo en el colegio que, además, te ayudará a ahorrar tiempo',
            },
            // items: {
            //   reports: {
            //     step: 2,
            //     description: 'En esta sección puedes tener un vistazo general del colegio, de un \
            //     curso en particular o de un alumno. ¡Navégala! verás que hay fichas imprimibles muy \
            //     útiles para reuniones de trabajo, con apoderados y otras instancias de trabajo en tu \
            //     colegio. Toda la información en Radar se actualiza automáticamente para no pedirte \
            //     trabajo extra',
            //   },
            //   settings: {
            //     step: 3,
            //     description: 'Acá puedes editar las credenciales de las distintas fuentes de \
            //     información de tu colegio. Si cambia una clave (por ejemplo SIGE) procura \
            //     cambiarla acá también para que tu información se mantenga actualizada en Radar',
            //   },
            // },
          },
        },
      };
    },
    tutorialInfo(_, getters) {
      return function (location) {
        return {
          step: safeNavigate(getters.tutorial, getters.userType, ...location, 'step'),
          description: safeNavigate(getters.tutorial, getters.userType, ...location, 'description'),
        };
      };
    },
    introOpenItem(state) {
      return state.active && state.menuOpenItem && state.menuOpenItem.key;
    },
    userType(_, __, ___, rootGetters) {
      return rootGetters['auth/userType'];
    },
    userPermissions(_, __, ___, rootGetters) {
      return rootGetters['auth/userPermissions'];
    },
  },
  mutations: {
    SET_ACTIVE(state, active) {
      state.active = active;
      if (active) {
        this.intro = introJs();
        state.initialMenuOpenItem = state.menuOpenItem;
      } else {
        state.menuOpenItem = state.initialMenuOpenItem;
      }
    },
    SET_MENU_OPEN_ITEM(state, item) {
      if (item) state.menuOpenItem = menuItems.find(i => i.key === item.key);
    },
  },
  actions: {
    start({ state, rootState, getters, commit, dispatch }) {
      let timeout = 0;
      if (!rootState.ui.displayMenu) {
        timeout = 275; // eslint-disable-line no-magic-numbers
        commit('ui/showMenu', null, { root: true });
      }
      if (!state.active) {
        commit('SET_ACTIVE', true);

        const itemsWithTutorial = getters.tutorial[getters.userType].menu.items || {};
        const parentItems = menuItems
          .filter(item => itemsWithTutorial.hasOwnProperty(item.key))
          .reduce((prev, item) => (
            {
              ...prev,
              [itemsWithTutorial[item.key].step.toString()]: item,
            }), {});

        this.intro.onafterchange(elem => {
          const item = parentItems[elem.getAttribute('data-step')];
          if (!item || !state.menuOpenItem) return;
          setHelperLayerStyle(state, getters, item);
          commit('SET_MENU_OPEN_ITEM', item);
        });
        this.intro.onexit(() => commit('SET_ACTIVE', false));
        this.intro.oncomplete(() => commit('SET_ACTIVE', false));
        this.intro.setOptions(
          {
            showStepNumbers: false,
            tooltipPosition: 'right',
            positionPrecedence: ['right', 'left', 'top', 'bottom'],
            scrollToElement: false,
            nextLabel: '<i class="material-icons">keyboard_arrow_right</i>',
            prevLabel: '<i class="material-icons">keyboard_arrow_left</i>',
            skipLabel: 'Salir',
            doneLabel: 'Listo',
          }
        );
        dispatch('showTutorial', { setTime: timeout });
      }
    },
    refresh({ state }) {
      if (state.active) {
        this.intro.refresh();
      }
    },
    async showTutorial({ dispatch }, obj) {
      await dispatch('showTutorialAsync', { setTime: obj.setTime });
    },
    showTutorialAsync(_, obj) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(this.intro.start());
        }, obj.setTime);
      });
    },
  },
};
