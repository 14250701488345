<template>
  <div>
    <template v-if="finalLoad">
      <div
        v-if="renderON"
        :class="hideAlert ? 'alertInfoHide' : 'alertInfo'">
        <span
          class="alertInfoText">
          <i class="clg-info" />
          {{ textAlert }}
        </span>
        <span
          class="alertInfoText"
          @click="changeState()">
          <i class="clg-x" />
        </span>
      </div>
    </template>

    <!-- ICONO INFORMATIVO -->
    <template v-if="iconViwe">
      <span
        class="iconInfoAlert"
        @click="hideAlert = !hideAlert">
          <i class="clg-info" />
      </span>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Popover } from 'element-ui';

export default {
  name: 'AlertInfo',
  components: {
    'el-popover': Popover,
  },
  props: {
    textAlert: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    iconViwe: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hideAlert: false,
      finalLoad: false,
      renderON: true,
    };
  },
  computed: {
    ...mapState({
      viewAlert: state => state.ui.stateAlert,
    }),
  },
  watch: {
    viewAlert() {
      this.hideAlert = this.viewAlert;
      this.renderON = true;
      if(this.hideAlert) {
        setTimeout(() => {
          this.renderON = false;
        }, 900);
      }
    },
  },
  mounted() {
    const timeOne = 1200;
    const timeTwo = 10000;
    setTimeout(() => {
      this.finalLoad = true
    }, timeOne);
    setTimeout(() => {
      this.hideAlert = true
      setTimeout(() => {
        this.renderON = false;
      }, 900);
    }, timeTwo);
  },
  methods: {
    ...mapMutations({
      openAlertInfo: 'ui/openAlertInfo',
    }),
    changeState() {
      this.renderON = true;
      this.hideAlert = !this.hideAlert
      if(!this.iconViwe) {
        this.openAlertInfo(this.hideAlert)
      }
      if(this.hideAlert) {
        setTimeout(() => {
          this.renderON = false;
        }, 900);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/app/variables";
$small-window-breakpoint: 900px;
$big-window-breakpoint: 1350px;

.report-description-popper {
  max-height: 60vh;
  max-width: 80vw;
  margin-right: 1rem;
  color: $main-text-color;
  white-space: pre-line;
  word-break: keep-all;

  @media only screen and (max-width: $big-window-breakpoint) {
    max-width: 70vw;
  }

  @media only screen and (max-width: $small-window-breakpoint) {
    max-width: 60vw;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 85vw;
    max-height: 60vh;
    overflow-y: auto;
  }
}

.report-description {
  .info {
    margin-left: 20%;
  }
  .info:hover {
    cursor: pointer;
    color: $primary-color;
  }
  .info:focus {
    color: $primary-color;
  }
}
</style>
