<template>
  <div class="rdr-view enrollment-view">
    <paywall
      :permitted="hasSalesianosLeadershipEvaluationPermission"
      restricted>
      <rdr-results-header
        :show-school-selector="false"
        year-picker
        hide-school-picker
        mobile-only-picker>
        <span>Este mapa de color muestra la evaluación del liderazgo directivo para el año seleccionado.</span>
      </rdr-results-header>
      <color-map
        :loading="loading"
        :rows="indicators"
        :columns="schoolsAsColumns"
        :results="results"
        :hide-empty-columns="true"
        :show-school-picker="false"
        :disable-school-adding="true"
      />
      <template v-if="results.length !== 0">
        <color-map-footer
          :footer-bottom-paragraphs="footerParagraphs"
          text="Estratificación del desempeño:"
          high="Altamente Destacado"
          average="Básico"
          low="Insuficiente"
        />
      </template>
    </paywall>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColorMapFooter from '../../components/results/color-map-footer.vue';

import PermissionMixin from '../../mixins/permission';
import ResultsMixin from '../../mixins/results';
import ResultsHeader from '../../components/results/results-header.vue';
import TooltipInfoMixin from '../../mixins/tooltip-info';

export default {
  name: 'SalesianosLeadershipEvaluationView',
  components: {
    'rdr-results-header': ResultsHeader,
    ColorMapFooter,
  },
  mixins: [PermissionMixin, ResultsMixin, TooltipInfoMixin],
  data() {
    return {
      footerParagraphs: [],
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('results', ['resultsSchools']),
    ...mapGetters('results', {
      indicators: 'resultsIndicators',
      resultsGetter: 'results',
    }),
    results() {
      return this.resultsGetter.map(result => {
        const school = this.resultsSchools.find(s => s.id === result.school_id);

        if (!school) return [];
        return { ...result, column_id: school.id }; // eslint-disable-line camelcase
      });
    },
    orderedSchools() {
      const getSchoolAverageScore = (school) => {
        if (this.results) {
          const sum = this.results
            .filter(result => result.school_id === school.id)
            .reduce((partial, result) => partial + result.norm_value, 0);
          return sum / this.indicators.length;
        }
        return 0;
      };

      return this.resultsSchools.slice().sort((a, b) => {
        const averageA = getSchoolAverageScore(a);
        const averageB = getSchoolAverageScore(b);

        return averageA - averageB;
      });
    },
    schoolsAsColumns() {
      return this.orderedSchools.map(school => this.schoolData(school));
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids_auth': true, 'years': true });
      this.setReportKey('salesianos-leadership-evaluation');
    },
  },
};
</script>
