<template>
  <div :class="[$style.students, 'rdr-view']">
    <div v-if="loading">
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
    </div>
    <div
      v-else>
      <div
        v-if="grades.length > 0"
        :class="$style.studentsContent">
        <explanation-message
          :v-if="showAptusExplanation"
          :explanation-message="aptusExplanationMessage"
        />
        <filter-table
          :rows="studentsAsRows"
          :columns="mixedColumns"
          :header-row-count="2"
          :values="values"
          :filters="filters"
          :loading="loading"
          :color-from-result="colorFromResult"
          :show-filters-on-mount="!isMobile"
          :sticky-columns="1"
          color-map
          compact-headers
          row-key="run"
          column-key="indicatorId"
          show-row-index
        >
          <div
            slot="moreInfoButtons"
            :class="$style.buttonsContainer">
            <button
              :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.rdrBtnSmall]"
              @click="link(gradesStudentSubjectAveragesLink, 'grades')">
              Detalle Calificaciones
            </button>
            <button
              :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.rdrBtnSmall]"
              @click="link(attendanceByStudentLink, 'attendance')">
              Detalle Asistencia
            </button>
          </div>
        </filter-table>
      </div>
      <div
        v-else
        :class="$style.studentsEmpty">
        <img src="/noResultados.svg">
        <!-- <p>
          No hay resultados para las opciones seleccionadas.
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import GroupStudentsApi from '../../api/groups';
import FilterTable from '../../components/filter-table.vue';
import interpolate from '../../utils/color-interpolate';
import PermissionMixin from '../../mixins/permission';
import LinksMixin from '../../mixins/links';
import EvaluableStudentsMixin from '../../mixins/evaluable-students';
import DisplayMixin from '../../mixins/display';
import Decree67Mixin from '../../mixins/decree-67';
import { SEMAPHORE_COLORS } from '../../utils/style-variables.js';
import { APPROVAL_LIMIT_GRADE } from '../../utils/constants';
import ExplanationMessage from '../../components/explanation-message.vue';

const ATTENDANCE_KEY = 'porcentaje-asistencia-anual-por-alumno';
const READING_QUALITY_KEY = 'calidad-lectora-por-alumno';
const QUALITY_NAME_MAPPINGS = {
  0: 'NL',
  1: 'S',
  2: 'PP',
  3: 'UC',
  4: 'F',
};
const colormap = interpolate(['#FF0000', '#FFFFFF', '#4266f7']);
const colormapRYG = interpolate(['#ED050B', '#FFFDCC', '#05BD3C']);
const colormapRed = interpolate(['#FF0000', '#FF7277', '#4266f7']);

export default {
  name: 'GroupReportStudentsView',
  components: {
    'filter-table': FilterTable,
    ExplanationMessage,
  },
  mixins: [DisplayMixin, EvaluableStudentsMixin, Decree67Mixin, PermissionMixin, LinksMixin],
  props: {
    chosenSchool: {
      type: String,
      default: null,
    },
    chosenGroupKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      results: {},
      aptusExplanationMessage: 'Importante: El logro normalizado de las evaluaciones 2020 de Aptus se realizó con una referencia de colegios distinta a SIP red de colegios.',
      localIdIndicators: {
        'pdd-aptus-promedio-normalizado-alumno': 0,
        'pdd-aptus-lenguaje-y-comunicacion-alumno': 1,
        'pdd-aptus-matematica-alumno': 2,
        'pdd-aptus-historia-geografia-y-ciencias-sociales-alumno': 3,
        'pdd-aptus-ciencias-naturales-alumno': 4,
        'promedio-de-notas-anual-por-alumno': 5,
        'promedio-lenguaje-y-comunicacion-alumno': 6,
        'promedio-matematica-alumno': 7,
        'promedio-ciencias-naturales-alumno': 8,
        'promedio-historia-geografia-y-ciencias-sociales-alumno': 9,
        'emn-aptus-promedio-normalizado-alumno': 10,
        'emn-aptus-lenguaje-y-comunicacion-alumno': 11,
        'emn-aptus-matematica-alumno': 12,
        'emn-aptus-historia-geografia-y-ciencias-sociales-alumno': 13,
        'emn-aptus-ciencias-naturales-alumno': 14,
        'pdn-aptus-promedio-normalizado-alumno': 15,
        'pdn-aptus-lenguaje-y-comunicacion-alumno': 16,
        'pdn-aptus-matematica-alumno': 17,
        'pdn-aptus-historia-geografia-y-ciencias-sociales-alumno': 18,
        'pdn-aptus-ciencias-naturales-alumno': 19,
        'mda-aptus-promedio-normalizado-alumno': 20,
        'mda-aptus-lenguaje-y-comunicacion-alumno': 21,
        'mda-aptus-matematica-alumno': 22,
        'mda-aptus-historia-geografia-y-ciencias-sociales-alumno': 23,
        'mda-aptus-ciencias-naturales-alumno': 24,
        'calidad-lectora-por-alumno': 25,
        'porcentaje-asistencia-anual-por-alumno': 1000,
      },
      staticFilters: {
        'Características del alumno': [
          {
            label: 'Preferente',
            filterFunc: () => row => row.preferential,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'preferential',
          },
          {
            label: 'Prioritario',
            filterFunc: () => row => row.priority,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'priority',
          },
          {
            label: 'PIE',
            filterFunc: () => row => row.pieBoolean,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'pieBoolean',
            mainItem: true,
          },
          {
            label: 'Permanente',
            filterFunc: () => row => row.permanentPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'permanentPie',
            subitem: true,
          },
          {
            label: 'Transitorio',
            filterFunc: () => row => row.temporaryPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'temporaryPie',
            subitem: true,
          },
        ],
        'Asistencia': [
          {
            label: 'Ausentismo crónico',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const averageId = 1000;
                const cell = rowValues.find(value => value.indicatorId === averageId);
                if (cell) {
                  return cell.value < 90; // eslint-disable-line no-magic-numbers
                }
                return false;
              };
            },
            exclusionClass: 'attendance',
            type: 'switch',
            key: 'chronic_absenteeism',
          },
        ],
      },
      repetitionFilter: {
        'Repitencia': [
          {
            label: 'Repitentes',
            filterFunc: () => row => row.repetition,
            exclusionClass: 'repetition',
            type: 'switch',
            key: 'repetition',
            subitem: true,
          },
        ],
      },
      dynamicFilters: {
        'Promedio de calificaciones': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const averageId = 5;
                const cell = rowValues.find(value => value.indicatorId === averageId);
                if (cell) {
                  if (!cell.value && cell.alternativeText) return true;
                  return cell.value >= this.range[0] && cell.value <= this.range[1];
                }

                return false;
              };
            },
            exclusionClass: 'grades',
            type: 'slider',
            key: 'grade',
            min: 1.0,
            max: 7.0, // eslint-disable-line no-magic-numbers
            range: [1.0, 7.0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
        'Promedio EMN': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const averageId = 10;
                const cell = rowValues.find(value => value.indicatorId === averageId);
                if (this.min === this.range[0] && this.max === this.range[1]) return true;
                if (cell) {
                  return (cell.value >= this.range[0] && cell.value <= this.range[1]);
                }
                return false;
              };
            },
            exclusionClass: 'emn',
            type: 'slider',
            key: 'emn',
            min: -1,
            max: 0, // eslint-disable-line no-magic-numbers
            range: [-1, 0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
        'Promedio MDA': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const averageId = 20;
                const cell = rowValues.find(value => value.indicatorId === averageId);
                if (this.min === this.range[0] && this.max === this.range[1]) return true;
                if (cell) {
                  return (cell.value >= this.range[0] && cell.value <= this.range[1]);
                }
                return false;
              };
            },
            exclusionClass: 'mda',
            type: 'slider',
            key: 'mda',
            min: -1,
            max: 0, // eslint-disable-line no-magic-numbers
            range: [-1, 0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
        'Promedio PDN': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const averageId = 15;
                const cell = rowValues.find(value => value.indicatorId === averageId);
                if (this.min === this.range[0] && this.max === this.range[1]) return true;
                if (cell) {
                  return (cell.value >= this.range[0] && cell.value <= this.range[1]);
                }
                return false;
              };
            },
            exclusionClass: 'pdn',
            type: 'slider',
            key: 'pdn',
            min: -1,
            max: 0, // eslint-disable-line no-magic-numbers
            range: [-1, 0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
        'Promedio PDD': [
          {
            label: 'Rango de calificaciones',
            filterFunc(values) {
              return (row) => {
                const rowValues = values.filter((value) => value.run === row.id);
                const averageId = 0;
                const cell = rowValues.find(value => value.indicatorId === averageId);
                if (this.min === this.range[0] && this.max === this.range[1]) return true;
                if (cell) {
                  return (cell.value >= this.range[0] && cell.value <= this.range[1]);
                }
                return false;
              };
            },
            exclusionClass: 'pdd',
            type: 'slider',
            key: 'pdd',
            min: -1,
            max: 0, // eslint-disable-line no-magic-numbers
            range: [-1, 0], // eslint-disable-line no-magic-numbers
            step: 0.1,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenYear']),
    options() {
      return {
        chosenSchool: this.chosenSchool,
        chosenYear: this.chosenYear,
        groupKey: this.groupKey,
      };
    },
    indicators() {
      return (this.results && this.results.indicators) ?
        this.results.indicators.concat(this.optionalIndicators) : [];
    },
    optionalIndicators() {
      return (this.results && this.results.optional_indicators) ?
        this.results.optional_indicators : [];
    },
    emnIndicatorsKeys() {
      return [
        'emn-aptus-lenguaje-y-comunicacion-alumno',
        'emn-aptus-matematica-alumno',
        'emn-aptus-historia-geografia-y-ciencias-sociales-alumno',
        'emn-aptus-ciencias-naturales-alumno',
        'emn-aptus-promedio-normalizado-alumno',
      ];
    },
    pdnIndicatorsKeys() {
      return [
        'pdn-aptus-lenguaje-y-comunicacion-alumno',
        'pdn-aptus-matematica-alumno',
        'pdn-aptus-historia-geografia-y-ciencias-sociales-alumno',
        'pdn-aptus-ciencias-naturales-alumno',
        'pdn-aptus-promedio-normalizado-alumno',
      ];
    },
    pddIndicatorsKeys() {
      return [
        'pdd-aptus-lenguaje-y-comunicacion-alumno',
        'pdd-aptus-matematica-alumno',
        'pdd-aptus-historia-geografia-y-ciencias-sociales-alumno',
        'pdd-aptus-ciencias-naturales-alumno',
        'pdd-aptus-promedio-normalizado-alumno',
      ];
    },
    mdaIndicatorsKeys() {
      return [
        'mda-aptus-lenguaje-y-comunicacion-alumno',
        'mda-aptus-matematica-alumno',
        'mda-aptus-historia-geografia-y-ciencias-sociales-alumno',
        'mda-aptus-ciencias-naturales-alumno',
        'mda-aptus-promedio-normalizado-alumno',
      ];
    },
    gradesIndicatorsKeys() {
      return [
        'promedio-de-notas-anual-por-alumno',
        'promedio-lenguaje-y-comunicacion-alumno',
        'promedio-matematica-alumno',
        'promedio-ciencias-naturales-alumno',
        'promedio-historia-geografia-y-ciencias-sociales-alumno',
      ];
    },
    values() {
      return this.grades.concat(this.studentInfo);
    },
    filteredResults() {
      return this.results.results ?
        this.results.results.filter(r => (this.evaluableStudents[r.evaluable_student_id] &&
        !this.evaluableStudents[r.evaluable_student_id].retired)) :
        [];
    },
    grades() {
      return this.filteredResults.map(result => ({
        run: this.evaluableStudents[result.evaluable_student_id].run,
        indicatorId: this.getIdByIndicatorKey(
          this.indicators.find(i => i.id === result.indicator_id).key,
        ),
        value: this.resultIsReadingQuality(result) ? QUALITY_NAME_MAPPINGS[result.value] : result.value,
        norm_value: this.usesNormValue(result) ? result.norm_value : result.value, // eslint-disable-line camelcase
        alternativeText: result.alternative_text,
        class: this.$style.grade,
        format: {
          string: (this.formatGrades(this.indicators.find(
            i => i.id === result.indicator_id).key)
          ),
        },
      }));
    },
    studentInfo() {
      return this.filteredResults.map(result => ({
        run: this.evaluableStudents[result.evaluable_student_id].run,
        indicatorId: -1,
        value: this.evaluableStudents[result.evaluable_student_id].full_name,
        format: { string: 'startCase' },
        class: this.$style.studentLink,
      }));
    },
    studentInfoAsColumns() {
      return [
        { id: -1, label: 'Nombre', headerRow: 1, notCompact: true },
        { id: -1, label: 'Promedio curso:', headerRow: 2, class: this.$style.secondRowLabel },
      ];
    },
    studentsAsRows() {
      return Object.values(this.evaluableStudents).map(student => (
        {
          id: student.run,
          value: student.full_name,
          preferential: student.preferential,
          priority: student.priority,
          temporaryPie: student.pie_type === 'Transitorio',
          permanentPie: student.pie_type === 'Permanente',
          pieBoolean: student.pie_boolean,
          repetition: student.repetition,
          repetitionRisk: student.repetition_risk,
          repetitionRiskAbsenteeism: student.repetition_risk === 3, // eslint-disable-line no-magic-numbers
          repetitionRiskGrades: [1, 2, 4, 5].includes(student.repetition_risk), // eslint-disable-line no-magic-numbers
          link: {
            name: 'studentReport',
            params: { student: student.id },
          },
        }
      )).sort((a, b) => a.value.localeCompare(b.value));
    },
    invalidIndicatorIds() {
      let invalidIds = [];
      if (!this.hasGradesPermission) {
        invalidIds = invalidIds.concat(this.gradesIndicatorsKeys.map(k => this.localIdIndicators[k]));
      }
      return invalidIds;
    },
    filteredIndicators() {
      if (this.hasGradesPermission) return this.indicators;
      return this.indicators.filter(i => !this.invalidIndicatorIds.includes(this.getIdByIndicatorKey(i.key)));
    },
    averagesAsColumns() {
      const averagesFirstRow = Object.values(
        this.filteredIndicators.map(indicator => {
          const average = {
            id: this.getIdByIndicatorKey(indicator.key),
            indicatorId: indicator.id,
            label: indicator.label,
            headerRow: 1,
            highlighted: [0, 5, 10, 15, 20, 1000].includes(this.getIdByIndicatorKey(indicator.key)), // eslint-disable-line no-magic-numbers
          };

          return { [average.id]: average };
        }).reduce((averages, average) => ({ ...averages, ...average })),
      );

      return averagesFirstRow.concat(averagesFirstRow.map(item => (
        {
          id: 500 + item.id, // eslint-disable-line no-magic-numbers
          headerRow: 2,
          highlighted: item.highlighted,
          label: (
            this.formatGrades(this.indicators.find(i => i.id === item.indicatorId).key) === 'percentage' ?
              `${this.$options.filters.round(this.groupAverage(item.id))}%` :
              this.$options.filters.numberWithOneDecimal(this.groupAverage(item.id))
          ),
        }
      )));
    },
    mixedColumns() {
      const columns = this.studentInfoAsColumns
        .concat(this.averagesAsColumns)
        .sort((a, b) => a.id - b.id);
      return columns;
    },
    emnRange() {
      return this.aptusRange(this.emnResultExists, 'emn-aptus-promedio-normalizado-alumno');
    },
    mdaRange() {
      return this.aptusRange(this.mdaResultExists, 'mda-aptus-promedio-normalizado-alumno');
    },
    pdnRange() {
      return this.aptusRange(this.pdnResultExists, 'pdn-aptus-promedio-normalizado-alumno');
    },
    pddRange() {
      return this.aptusRange(this.pddResultExists, 'pdd-aptus-promedio-normalizado-alumno');
    },
    hasGradesFilter() {
      return this.isAverageInColumns && this.hasGradesPermission;
    },
    repetitionRiskFilter() {
      return {
        [this.repetitionRiskLabel]: [
          {
            label: 'General',
            filterFunc: () => row => row.repetitionRisk,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk',
          },
          {
            label: 'Por calificaciones',
            filterFunc: () => row => row.repetitionRiskGrades,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_grades',
            subitem: true,
          },
          {
            label: 'Por asistencia',
            filterFunc: () => row => row.repetitionRiskAbsenteeism,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_absenteeism',
            subitem: true,
          },
        ],
      };
    },
    hasRepetitionValues() {
      return this.studentsAsRows.some(e => typeof e.repetition === 'boolean');
    },
    hasRepetitionRiskValues() {
      return this.studentsAsRows.some(e => typeof e.repetitionRisk === 'number');
    },
    repetitionOrRepetitionRiskFilters() {
      if (this.hasRepetitionValues) return this.repetitionFilter;
      if (this.hasRepetitionRiskValues) {
        return {
          [this.repetitionRiskLabel]: this.hasGradesFilter ?
            this.repetitionRiskFilter[this.repetitionRiskLabel] :
            this.repetitionRiskFilter[this.repetitionRiskLabel].filter(f => f.label === 'Por asistencia'),
        };
      }
      return {};
    },
    showAptusExplanation() {
      // eslint-disable-next-line no-magic-numbers
      return this.chosenYear === 2020 && this.filteredResults.some(result => (
        this.resultIsEmn(result) || this.resultIsPdn(result) || this.resultIsPdd(result) || this.resultIsMda(result)
      ));
    },
    filters() {
      return {
        ...(this.hasGradesFilter && { 'Promedio de notas': this.dynamicFilters['Promedio de notas'] }),
        ...(this.emnResultExists && { 'Promedio EMN': this.dynamicFilters['Promedio EMN'] }),
        ...(this.mdaResultExists && { 'Promedio MDA': this.dynamicFilters['Promedio MDA'] }),
        ...(this.pdnResultExists && { 'Promedio PDN': this.dynamicFilters['Promedio PDN'] }),
        ...(this.pddResultExists && { 'Promedio PDD': this.dynamicFilters['Promedio PDD'] }),
        ...this.repetitionOrRepetitionRiskFilters,
        ...this.staticFilters,
      };
    },
    isAverageInColumns() {
      const averageKey = ['promedio-de-notas-anual-por-alumno'];
      const averageIndicator = this.indicators.find(i => averageKey.includes(i.key));

      if (averageIndicator) {
        return this.results.results.some(i => averageIndicator.id === i.indicator_id);
      }

      return false;
    },
    emnResultExists() {
      return this.filteredResults.some(result => (
        this.resultIsEmn(result)
      ));
    },
    pdnResultExists() {
      return this.filteredResults.some(result => (
        this.resultIsPdn(result)
      ));
    },
    pddResultExists() {
      return this.filteredResults.some(result => (
        this.resultIsPdd(result)
      ));
    },
    mdaResultExists() {
      return this.filteredResults.some(result => (
        this.resultIsMda(result)
      ));
    },
  },
  watch: {
    options: {
      async handler() {
        await this.fetchStudents();
      },
      inmediate: true,
    },
    emnRange(newRange) {
      if (!newRange) return;
      this.dynamicFilters['Promedio EMN'][0] = Object.assign(
        this.dynamicFilters['Promedio EMN'][0],
        {
          min: newRange[0],
          max: newRange[1],
          range: newRange,
        });
    },
    pdnRange(newRange) {
      if (!newRange) return;
      this.dynamicFilters['Promedio PDN'][0] = Object.assign(
        this.dynamicFilters['Promedio PDN'][0],
        {
          min: newRange[0],
          max: newRange[1],
          range: newRange,
        });
    },
    pddRange(newRange) {
      if (!newRange) return;
      this.dynamicFilters['Promedio PDD'][0] = Object.assign(
        this.dynamicFilters['Promedio PDD'][0],
        {
          min: newRange[0],
          max: newRange[1],
          range: newRange,
        });
    },
    mdaRange(newRange) {
      if (!newRange) return;
      this.dynamicFilters['Promedio MDA'][0] = Object.assign(
        this.dynamicFilters['Promedio MDA'][0],
        {
          min: newRange[0],
          max: newRange[1],
          range: newRange,
        });
    },
  },
  async created() {
    await this.fetchStudents();
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapMutations('ui', { changeOpenedSidebarItem: 'changeOpenedSidebarItem' }),
    getIdByIndicatorKey(key) {
      return this.localIdIndicators[key];
    },
    formatGrades(key) {
      const percentageFormat =
        [ATTENDANCE_KEY].concat(
          this.emnIndicatorsKeys.slice(0, -1),
          this.pdnIndicatorsKeys.slice(0, -1),
          this.pddIndicatorsKeys.slice(0, -1),
          this.mdaIndicatorsKeys.slice(0, -1),
        );
      return percentageFormat.includes(key) ? 'percentage' : 'numberWithOneDecimal';
    },
    fetchStudents() {
      this.addLoading();
      GroupStudentsApi.getGroupStudents({
        schoolId: this.chosenSchool,
        groupKey: this.chosenGroupKey,
        year: this.chosenYear })
        .then(({ body }) => {
          this.results = body.group_report_students;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.removeLoading();
        });
    },
    groupAverage(id) {
      let sum = 0;
      let count = 0;
      this.filteredResults
        .filter((r) => (
          this.getIdByIndicatorKey(
            this.indicators.find(i => i.id === r.indicator_id).key) === id
        ))
        .forEach((r) => {
          sum += r.value;
          count++;
        });
      if (count > 0) {
        return sum / count;
      }
      return '';
    },
    usesNormValue(result) {
      return this.resultIsEmn(result) ||
        this.resultIsPdn(result) ||
        this.resultIsPdd(result) ||
        this.resultIsReadingQuality(result);
    },
    resultIsEmn(result) {
      return this.resultIsAptus(this.emnIndicatorsKeys, result);
    },
    resultIsMda(result) {
      return this.resultIsAptus(this.mdaIndicatorsKeys, result);
    },
    resultIsPdn(result) {
      return this.resultIsAptus(this.pdnIndicatorsKeys, result);
    },
    resultIsPdd(result) {
      return this.resultIsAptus(this.pddIndicatorsKeys, result);
    },
    resultIsReadingQuality(result) {
      return this.resultIsAptus([READING_QUALITY_KEY], result);
    },
    resultIsAptus(indicatorsKeys, result) {
      const aptusIndicators = this.optionalIndicators.filter(indicator => (
        indicatorsKeys.includes(indicator.key)
      ));
      const localIndicatorIds = aptusIndicators.map(indicator => (
        this.getIdByIndicatorKey(indicator.key)
      ));
      const realIndicatorIds = aptusIndicators.map(indicator => indicator.id);

      return localIndicatorIds.includes(result.indicatorId) ||
        realIndicatorIds.includes(result.indicator_id);
    },
    aptusRange(aptusResultExists, indicatorKey) {
      if (!aptusResultExists) return null;
      const aptusResults = this.filteredResults
        .filter((r) =>
          this.indicators.find(i => i.id === r.indicator_id).key === indicatorKey,
        )
        .map((r) => r.value);
      return [
        Math.floor(Math.min(...aptusResults) * 10) / 10, // eslint-disable-line no-magic-numbers
        Math.ceil(Math.max(...aptusResults) * 10) / 10, // eslint-disable-line no-magic-numbers
      ];
    },
    colorFromResult(result) { // eslint-disable-line max-statements
      if (result) {
        if (result.indicatorId === this.getIdByIndicatorKey(ATTENDANCE_KEY)) {
          const value = Math.min(Math.max(80, result.value), 100); // eslint-disable-line no-magic-numbers
          return colormapRYG((value - 80) / 20); // eslint-disable-line no-magic-numbers
        }
        if (this.resultIsEmn(result) || this.resultIsPdn(result) || this.resultIsPdd(result)) {
          const value = result.norm_value || result.value;
          if (value <= -0.3) return SEMAPHORE_COLORS.low; // eslint-disable-line no-magic-numbers
          else if (value > 0.1) return SEMAPHORE_COLORS.high; // eslint-disable-line no-magic-numbers
          return SEMAPHORE_COLORS.middle;
        }
        if (this.resultIsReadingQuality(result)) {
          const colorValue = result.norm_value;
          if (colorValue === 0) return SEMAPHORE_COLORS.middle;
          return colorValue < 0 ? SEMAPHORE_COLORS.low : SEMAPHORE_COLORS.high;
        }
        if (!result.value) return '#ffffff';
        const value = Math.min(Math.max(1, result.value), 7); // eslint-disable-line no-magic-numbers
        const normalizedValue = (value - 1) / 6; // eslint-disable-line no-magic-numbers
        if (value < APPROVAL_LIMIT_GRADE) { // eslint-disable-line no-magic-numbers
          return colormapRed(normalizedValue);
        }

        return colormap(normalizedValue);
      }

      return 'inherit';
    },
    link(link, sidebarItemKey) {
      this.changeOpenedSidebarItem(sidebarItemKey);
      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
@import "../../../styles/app/forms";

.students {
  padding: 0 30px;

  &-empty {
    text-align: center;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    padding: 0 5px;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-right: 25px;

  button {
    margin: 16px 0;
  }
}

.grade {
  text-align: center;
  border: 2px solid;
  padding: 2px;
  max-height: 27px;
  min-width: 27px;
}

.second-row-label {
  text-align: right;
}

.student-link {
  a {
    color: $primary-color;
  }

  a:visited {
    color: $primary-color;
  }

  a:hover {
    color: $link-hover-color;
  }
}
</style>
