import Vue from 'vue';

export default {
  submit({
    contactType,
    currentSchool,
    email,
    name,
    otherSchools,
    phone,
    relation,
  }) {
    return Vue.http.post('/api/v1/contacts', {
      contact: {
        email,
        name,
        phone,
        relation,
        contact_type: contactType,
        current_school: currentSchool,
        other_schools: otherSchools.join(','),
      },
    });
  },
};
