<template>
  <div class="rdr-view">
    <paywall
      :permitted="hasTechnicalEducationQualificationPermission"
      restricted>
      <data-studio-report :report-key="reportKey"/>
    </paywall>
  </div>
</template>

<script>
import DataStudioReport from '../../components/data-studio-report.vue';
import PermissionMixin from '../../mixins/permission';

const REPORT_KEY = 'technical-education-qualification';

export default {
  name: 'TechnicalEducationQualificationView',
  components: { DataStudioReport },
  mixins: [PermissionMixin],
  data() {
    return {
      reportKey: REPORT_KEY,
    };
  },
};

</script>
