<template>
  <div>
    <div v-if="viewTypeAttendence === '1'">
      <rdr-switcher
        :views="views"
        @switcher-toggle="toggleFetchReady"
      />
    </div>
    <div v-if="viewTypeAttendence === '2'">
      <attendance-week-month
        :type-att="viewTypeAttendence"
      />
    </div>
    <div v-if="viewTypeAttendence === '3'">
      <attendance-week-month
        :type-att="viewTypeAttendence"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AuthMixin from '../../../mixins/auth';
import RdrSwitcher from '../../../components/utils/switcher.vue';
import AttendancePercentageView from '../percentage/by-school.vue';
import AttendanceWeekMonth from '../percentage/by-school-week.vue';
import ChronicAbsenteeismView from '../chronic-absenteeism/by-school.vue';

export default {
  name: 'AttendanceBySchoolView',
  components: {
    'rdr-switcher': RdrSwitcher,
    'attendance-week-month': AttendanceWeekMonth,
  },
  mixins: [AuthMixin],
  data() {
    return {
      views: {
        left: {
          label: 'Porcentaje Asistencia',
          component: AttendancePercentageView,
          color: 'rgb(5, 189, 60)',
        },
        right: {
          label: 'Ausentismo Crónico',
          component: ChronicAbsenteeismView,
          color: 'rgb(237, 5, 11)',
        },
      },
      viewTypeAttendence: '1',
    };
  },

  computed: {
    ...mapState('ui', ['typeAttendanceKey']),
  },

  watch: {
    typeAttendanceKey() {
      this.viewTypeAttendence = this.typeAttendanceKey;
    },
  },

  methods: {
    ...mapMutations('options', { setFetchReady: 'SET_FETCH_READY' }),
    toggleFetchReady() {
      this.setFetchReady(false);
      this.$nextTick(() => this.setFetchReady(true));
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../../styles/app/variables";
</style>
