// Based on Element UI Slider Button
<template>
  <div
    ref="button"
    :class="[`${styleClass}__button-wrapper`, { 'hover': hovering }]"
    :style="wrapperStyle"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @mousedown="onButtonDown"
    @touchstart="onButtonDown"
  >
    <div
      :class="[`${styleClass}__button`, { 'hover': hovering }]"
    />
    <div :class="`${styleClass}__button-tooltip`">
      <div :class="`${styleClass}__button-tooltip-arrow`"/>
      <div :class="`${styleClass}__button-tooltip-content`"> {{ formatedValue }} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderButton',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'number',
    },
  },
  data() {
    return {
      hovering: false,
      dragging: false,
      isClick: false,
      startX: 0,
      currentX: 0,
      startY: 0,
      currentY: 0,
      startPosition: 0,
      newPosition: null,
      oldValue: this.value,
      styleClass: `slider_${this.vertical ? 'v' : 'h'}`,
    };
  },
  computed: {
    max() { return this.$parent.max; },
    min() { return this.$parent.min; },
    step() { return this.$parent.step; },
    precision() { return this.$parent.precision; },
    currentPosition() {
      return `${(this.value - this.min) / (this.max - this.min) * 100}%`;
    },
    wrapperStyle() { return this.vertical ? { bottom: this.currentPosition } : { left: this.currentPosition }; },
    formatedValue() { return this.type === 'number' ? (this.value * 1).toFixed(this.precision) : `${this.value}%`; },
  },
  watch: {
    dragging(value) { this.$parent.dragging = value; },
  },
  methods: {
    handleMouseEnter() { this.hovering = true; },
    handleMouseLeave() { this.hovering = false; },
    onButtonDown(event) {
      event.preventDefault();
      this.onDragStart(event);
      window.addEventListener('mousemove', this.onDragging);
      window.addEventListener('touchmove', this.onDragging);
      window.addEventListener('mouseup', this.onDragEnd);
      window.addEventListener('touchend', this.onDragEnd);
      window.addEventListener('contextmenu', this.onDragEnd);
    },
    onDragStart(event) {
      this.dragging = true;
      this.isClick = true;
      if (event.type === 'touchstart') {
        event.clientY = event.touches[0].clientY;
        event.clientX = event.touches[0].clientX;
      }
      if (this.vertical) {
        this.startY = event.clientY;
      } else {
        this.startX = event.clientX;
      }
      this.startPosition = parseFloat(this.currentPosition);
      this.newPosition = this.startPosition;
    },
    onDragging(event) {
      if (this.dragging) {
        this.isClick = false;
        let diff = 0;
        if (event.type === 'touchmove') {
          event.clientY = event.touches[0].clientY;
          event.clientX = event.touches[0].clientX;
        }
        if (this.vertical) {
          this.currentY = event.clientY;
          diff = (this.startY - this.currentY) / this.$parent.sliderSize * 100;
        } else {
          this.currentX = event.clientX;
          diff = (this.currentX - this.startX) / this.$parent.sliderSize * 100;
        }
        this.newPosition = this.startPosition + diff;
        this.setPosition(this.newPosition);
      }
    },
    onDragEnd() {
      if (this.dragging) {
        setTimeout(() => {
          this.dragging = false;
          if (!this.isClick) {
            this.setPosition(this.newPosition);
            this.$parent.emitChange();
          }
        }, 0);
        window.removeEventListener('mousemove', this.onDragging);
        window.removeEventListener('touchmove', this.onDragging);
        window.removeEventListener('mouseup', this.onDragEnd);
        window.removeEventListener('touchend', this.onDragEnd);
        window.removeEventListener('contextmenu', this.onDragEnd);
      }
    },
    setPosition(newPosition) {
      if (newPosition === null) return;
      if (newPosition < 0) newPosition = 0;
      else if (newPosition > 100) newPosition = 100;
      const lengthPerStep = 100 / ((this.max - this.min) / this.step);
      const steps = Math.round(newPosition / lengthPerStep);
      let value = steps * lengthPerStep * (this.max - this.min) * 0.01 + this.min;
      value = parseFloat(value.toFixed(this.precision));
      this.$emit('input', value);
      if (!this.dragging && this.value !== this.oldValue) {
        this.oldValue = this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";

  .slider_v {
    &__button-wrapper {
      height: 36px;
      width: 36px;
      position: absolute;
      z-index: 350;
      display: flex;
      justify-content: center;
      align-items: center;
      top: auto;
      left: -15px;
      transform: translateY(50%);
      background-color: transparent;
      text-align: center;
      cursor: pointer;
    }

    &__button {
      width: 16px;
      height: 16px;
      border: 2px solid $primary-color;
      background-color: #fff;
      border-radius: 50%;
      transition: .2s;

      &.hover {
        transform: scale(1.2);
      }
    }

    &__button-tooltip {
      position: absolute;
      left: 35px;
      display: flex;
      align-items: center;

      &-arrow {
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-right: 13px solid $main-text-color;

        @media only print {
          border-bottom: 15px solid transparent;
          border-top: 15px solid transparent;
          border-right: 15px solid $main-text-color;
          &:before {
            content: "";
            position: absolute;
            border-top: 13px solid transparent;
            border-bottom: 13px solid transparent;
            border-right: 12px solid white;
            top: 2px;
            left: 3px;
          }
        }
      }

      &-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 5px;
        background-color: $main-text-color;
        font-size: 12px;
        color: #fff;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        @media only print {
          padding-bottom: 6px;
          border: 2px solid $main-text-color;
          border-left: 0px;
          color: black;
        }
      }
    }
  }

  .slider_h {
    &__button-wrapper {
      height: 36px;
      width: 36px;
      position: absolute;
      z-index: 350;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -15px;
      transform: translateX(-50%);
      background-color: transparent;
      text-align: center;
      cursor: pointer;
    }

    &__button {
      width: 16px;
      height: 16px;
      border: 2px solid $primary-color;
      background-color: #fff;
      border-radius: 50%;
      transition: .2s;

      &.hover {
        transform: scale(1.2);
      }
    }

    &__button-tooltip {
      position: absolute;
      bottom: 35px;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;

      &-arrow {
        width: 0;
        height: 0;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        border-top: 15px solid $main-text-color;

        @media only print {
          &:before {
            content: "";
            position: absolute;
            border-right: 18px solid transparent;
            border-left: 18px solid transparent;
            border-top: 13px solid white;
            bottom: 2px;
            left: 2px;
          }
        }
      }

      &-content {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 3px 3px 3px;
        background-color: $main-text-color;
        font-size: 12px;
        color: #fff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @media only print {
          border: 2px solid $main-text-color;
          border-bottom: 0;
          color: black;
        }
      }
    }
  }
</style>

