<template>
  <paywall
    :permitted="isLoggedIn && hasAttendancePermission"
    :free="isFreeUser"
    restricted
    transparent>
    <div id="downTable" class="results-colormap">
      <div class="results-colormap_color-map">
        <color-map
          :loading="loading"
          :rows="schoolsAsRows"
          :columns="monthsAsColumns"
          :results="results"
          :hide-empty-columns="true"
          :show-school-picker="false"
          :disable-school-adding="true"
          :percentage="true"
          :round="true"
          :white-replaces-yellow="true"
          :latest-update-hover="true"
          :attendance-percentage-view="true"
          :column-header-hover="false"
          results-row-key="school_id"
          row-sort="last_cell_descending"
        />
      </div>
      <template v-if="results.length !== 0">
        <color-map-footer
          :show-high="false"
          :white-replaces-yellow="true"
          :reverse-legends-row="true"
          :footer-bottom-paragraphs="footerParagraphs"
          text="La gama de colores representa el porcentaje de inasistentes crónicos por colegio"
          average="0%"
          low="50% o más"/>
      </template>
    </div>
    <color-map
      slot="mock"
      :rows="mockChronicAbsenteeismComparativeData.schools"
      :columns="mockChronicAbsenteeismComparativeData.columns"
      :results="mockChronicAbsenteeismComparativeData.results"
      :default-open-columns="mockChronicAbsenteeismComparativeData.defaultOpenColumns"
      :results-column-key="mockChronicAbsenteeismComparativeData.resultsColumnKey"
      :results-row-key="mockChronicAbsenteeismComparativeData.resultsIndicatorKey"
      :disable-school-adding="true"
      class="color-map--pro"
    />
  </paywall>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import MockDataMixin from '../../../mixins/mock-data';
import ResultsMixin from '../../../mixins/results';
import TooltipInfoMixin from '../../../mixins/tooltip-info';

import { MONTHS, offsetMonth } from '../../../utils/months';

export default {
  name: 'ComparativeChronicAbsenteeismView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin, TooltipInfoMixin],
  data() {
    return {
      footerParagraphs: [
        'Los cálculos de inasistencia del año en curso se obtienen a partir de la información del sistema SIGE de cada establecimiento.',
        'Los colores son referenciales para cada concepto y no necesariamente representan las metas internas de cada proyecto educativo.',
      ],
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('results', ['resultsSchools']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      resultsGetter: 'results',
    }),
    results() {
      return this.resultsGetter.map(result => {
        const evaluation = this.evaluations.find(e => e.id === result.evaluation_id);

        // eslint-disable-next-line camelcase
        return { ...result, column_id: `${evaluation.year}_${offsetMonth(evaluation.month)}` };
      });
    },
    schoolsAsRows() {
      return this.resultsSchools.map(school => ({ ...this.schoolData(school), parent_id: null })); // eslint-disable-line camelcase
    },
    monthsAsColumns() {
      const columns = [];

      this.evaluations.forEach(evaluation => {
        const newColumn = {
          id: `${evaluation.year}_${offsetMonth(evaluation.month)}`,
          label: `${MONTHS[evaluation.month - 1]} ${evaluation.year}`,
        };
        const columnExists = columns.find(column => column.id === newColumn.id);

        if (!columnExists) columns.push(newColumn);
      });

      return columns.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;

        return 0;
      });
    },
    quoterLink() {
      return { name: 'proQuote' };
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids_auth': true, 'years': true });
      this.setReportKey('absenteeism-comparative');
    },
  },
};
</script>
