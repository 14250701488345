<template>
  <div class="contenPB">
    <loading-overlay v-if="loading"/>
    <template v-else>
      <div v-if="loadIframe">
        <iframe  
          id="iframeFinancial"
          :width="widthIFRM"
          :height="heightIFRM"
          :src="urlFinancial">
        </iframe>
      </div>
      <div v-else class="subconten">
        <div class="rdr-view">
          <h3>Colegios enlazados a mi cuenta</h3>
          <div class="schools-settings">
            <div class="search-bar">
              <input type="text" v-model="searchQuery" placeholder="Buscar colegio...">
            </div>
            <ul>
              <li
                v-for="school in filteredSchools"
                :key="school.rbd">
                {{ school.name }}
                <button
                  v-if="school.power_bi_url"
                  class="rdr-btn rdr-btn--primary school__platforms-button btnPB"
                  @click="renderLoadIframe(school.power_bi_url)"
                >
                  Dashboard
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToLocalTimeMixin from '../mixins/to-local-time';
import SchoolsApi from '../api/schools';



export default {
  name: 'FinancialView',
  mixins: [ToLocalTimeMixin],
  data() {
    return {
      loadIframe: false,
      urlFinancial: '',
      widthIFRM: 0,
      heightIFRM: 0,
      schools: [],
      loading: 0,
      searchQuery: '',
    };
  },
  computed: {
    ...mapState('ui', ['displayMenu', 'menuHover']),
    attachments() {
      return this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.user_attachments;
    },
    filteredSchools() {
      return this.schools.filter(school => {
        const searchLower = this.searchQuery.toLowerCase();
        return school.name.toLowerCase().includes(searchLower) ||
              school.rbd.toString().includes(searchLower);
      });
  }
  },
  watch: {
    menuHover(newMenuHover, oldMenuHover) {
      if (this.urlFinancial !== '') {
        this.renderLoadIframe(this.urlFinancial);
      }
    }
  },
  mounted() {
    this.fetchUserSchools();
  },
  methods: {
    async fetchUserSchools() {
      try {
        this.loading++;
        let { body: { schools } } = await SchoolsApi.getUserSchoolFinantials();
        this.schools = schools;
      } catch (_) {
      } finally {
        this.loading--;
      }
    },

    renderLoadIframe(data) {
      this.loading = 1; 
      this.urlFinancial = data;
      this.heightIFRM = window.innerHeight - 90;
      this.menuHover
        ? this.widthIFRM = window.innerWidth - 60
        : this.widthIFRM = window.innerWidth - 257
      setTimeout(() => {
        this.loadIframe = true;
        setTimeout(() => {
          this.loading = 0;
        }, 500); 
      }, 500); 
    }
  },
};
</script>

<style lang="scss">
  @import "../../styles/app/variables";

  .contenPB {
    margin-top: -34px;
    margin-left: -35px;
  }
  .btnPB {
    float: right;
  }
  #iframeFinancial {
    border: none;
  }
  .subconten {
    padding-left: 25px;
    padding-top: 25px;
  }

  .schools-settings {
    padding: 0 30px;

    ul {
      list-style: none;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;

      li {
        height: 62px;
        margin: 0;
        border-bottom: 1px solid $settings-border-color;
        padding: 10px 30px;

        @media only screen and (max-width: $mobile-breakpoint) {
          padding: 20px 15px;

          &:not(:nth-child(even)) {
            background-color: $settings-block-emphasis-background;
          }
        }

        &:first-child {
          border-top: 1px solid $settings-border-color;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .search-bar {
    display: flex;
    justify-content: center;
    /* Centra los elementos hijos horizontalmente */
    /* Ajusta el padding según sea necesario para alinear verticalmente */
  }
  /* Estilos para la barra de búsqueda */
  input[type="text"] {
    width: 70%; /* Ajusta este valor según prefieras */
    padding: 4px 15px; /* Reducido para disminuir la altura */
    margin: 10px auto; /* Centrado horizontalmente y margen vertical */
    /* Resto de estilos permanecen igual */
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    transition: border-color 0.3s;
  }

  input[type="text"]:focus {
    border-color: #007BFF;
    /* Color del borde al enfocar */
    outline: none;
    /* Eliminar el outline predeterminado */
  }

</style>
