<template>
  <rdr-popover
    :title="title"
    :value="visible"
    :width="275"
    :after-leave="() => { firstShow = false}"
    popper-class="desktop-only"
    trigger="click"
    placement="bottom"
    arrow-size="big"
  >
    <a
      slot="reference"
      :class="buttonClass"
    >
      <i class="clg-question"/>
      <span v-if="textDisplay" style="margin-left:6px"> Ayuda</span>
    </a>
    <div>
      <!--div>
        <i class="socicon-whatsapp"/>
        <span>+56951203573<em>(WhatsApp)</em></span>
      </div-->
      <div :class="$style.contactInfo">
        <i class="socicon-mail"/>
        <span style="margin-left:6px">
          <em>
            {{
              'radar@colegium.com'
            }}
          </em>
        </span>
      </div>
    </div>
  </rdr-popover>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RdrPopover from '../../components/base/popover';

export default {
  name: 'HelpButton',
  components: { RdrPopover },
  props: {
    buttonClass: {
      type: Array,
      default: () => [],
    },
    textDisplay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('ui', [{display: 'displayHelpPopover'}, 'displayMenu']),
    visible: {
      get() { return this.display; },
      set(value) { this.setVisibility(value); },
    },
    title() {
      return '¿Necesitas ayuda?';
    },
  },
  methods: {
    ...mapMutations('ui', {
      show: 'showHelpPopover',
      hide: 'hideHelpPopover',
      setVisibility: 'setHelpPopoverVisibility',
    }),
  },
};
</script>
<style lang="scss" module>
  .contactInfo {
    display: flex;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
</style>
