<template>
  <div class="rdr-view enrollment-view">
    <transition name="fade">
      <loading-overlay v-if="loading"/>
    </transition>
    <div v-if="schools">
      <div :class="$style.selectorWrapper">
        <div :class="$style.tableSelector">
          <div :class="$style.switch">
            <el-switch
              v-model="views.average"
              @change="changeView('average')"/>
          </div>
          <span>
            Matrículas promedio por nivel
          </span>
        </div>
        <div :class="$style.tableSelector">
          <div :class="$style.switch">
            <el-switch
              v-model="views.total"
              @change="changeView('total')"/>
          </div>
          <span>
            Matrículas totales por nivel
          </span>
        </div>
        <div
          v-if="hasOccupancyTrackingPermission"
          :class="$style.tableSelector">
          <div :class="$style.switch">
            <el-switch
              v-model="views.occupancy"
              @change="changeView('occupancy')"/>
          </div>
          <span>
            Vacantes por nivel
          </span>
        </div>
      </div>
      <div v-if="views.occupancy">
        <color-map
          :rows="mapRows"
          :columns="mapColumns"
          :results="mapResults"
          :min-value="-20"
          :max-value="20"
          :optimal-value="0"
          hide-empty-columns
          only-alerts
          optimal-in-center
          results-row-key="group_id"
        />
      </div>
      <div v-else-if="views.average || views.total">
        <div>
          <slider
            v-if="views.average"
            v-model="range"
            :value="range"
            :multiple-buttons="false"
            :max="45"
            :min="25"
            :class="$style.slider"/>
          <span v-if="views.average"> Se destacan los niveles que tengan en promedio <strong> {{ range[1] }} o menos estudiantes </strong>. Puedes cambiar este criterio deslizando el botón en la barra.</span>
          <rdr-table
            :columns="tableColumns"
            :data="tableRows"
            :raw-data-groups="tableRowGroups"
            :custom-class="$style.levelReportTable"
            :extended-header-class="$style.levelReportHeaderCell"/>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="$style.empty">
      No se encontraron resultados
    </div>
  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex';
import { Switch } from 'element-ui';
import EnrollmentDashboardApi from '../../../api/enrollment-dashboard';
import HistoricResults from '../../../components/historic-results.vue';
import Paywall from '../../../components/paywall.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import Slider from '../../../components/utils/slider.vue';
import RdrSwitcher from '../../../components/utils/switcher.vue';
import LinkMixin from '../../../mixins/links';
import cleanSchoolName from '../../../filters/clean-school-name';

export default {
  name: 'EnrollmentTablesView',
  components: {
    HistoricResults,
    Paywall,
    Slider,
    'el-switch': Switch,
    'rdr-switcher': RdrSwitcher,
  },
  mixins: [AuthMixin, PermissionMixin, LinkMixin],
  data() {
    return {
      schools: null,
      range: [25, 30], // eslint-disable-line no-magic-numbers
      loading: 0,
    };
  },
  computed: {
    ...mapState('options', ['chosenYear', 'years', 'chosenMonth']),
    views() {
      let currentView = this.$route.params.enrollmentDisplay;
      if (this.$route.name === 'enrollmentByLevel' && !currentView) {
        this.changeView('average');
        currentView = 'average';
      }
      return {
        average: currentView === 'average',
        occupancy: currentView === 'occupancy',
        total: currentView === 'total',
      };
    },
    permitted() {
      return this.hasPermission('management-indicators');
    },
    selectedFormat() {
      return this.views.average ? 'enrollment_average' : 'group_enrollments';
    },
    averageDisplayed() {
      return this.selectedFormat === 'enrollment_average';
    },
    levels() {
      const levels = this.schools.map(school => (school.levels)).flat().filter(l => l);
      return [...new Set(levels.map(level => level.id))]
        .map(id => levels.find(level => level.id === id))
        .sort((g1, g2) => this.groupOrder(g1) - this.groupOrder(g2));
    },
    attendances() {
      return this.schools.map(school => (school.attendances)).flat(1);
    },
    capacities() {
      return this.schools.map(school => (school.capacities)).filter(l => l).flat(1);
    },
    courses() {
      const levelIds = this.levels.map(({ id }) => id);
      return Object.values(
        Object.fromEntries(this.schools.map((school) => {
          if (school.courses)
            return (school.courses.map(c => [c.id, c]))
          else
            return []
        }).flat(1))
      ).filter(({ parent_id: parentId }) => levelIds.includes(parentId));
    },
    tableRowGroups() {
      return this.levels.map(({ id: levelId }) => ({
        key: levelId,
        children: this.courses
          .filter(({ parent_id: parentId }) => parentId === levelId)
          .map(c => ({ key: c.id })),
      }));
    },
    tableRows() {
      const groups = this.levels.concat(this.courses).map(group => {
        const result = { ...group, group_name: group.name, group_total: null }; // eslint-disable-line camelcase
        const groupAttendances = this.attendances.filter(enr => enr.group_id === group.id);
        groupAttendances.forEach(att => {
          result[att.school_id] = att[this.selectedFormat];
          result.group_total += att[this.selectedFormat]; // eslint-disable-line camelcase
        });
        if (this.views.average) result.group_total /= groupAttendances.length; // eslint-disable-line camelcase
        return result;
      });
      const allGroupResult = { group_name: 'Total' }; // eslint-disable-line camelcase
      this.attendances.filter(att => att && att.group_name === 'Todos')
        .forEach(att => {
          allGroupResult.id = att.group_id;
          allGroupResult[att.school_id] = this.views.average ? att.enrollment_average : att.group_enrollments;
        });
      groups.push(allGroupResult);
      return groups;
    },
    tableColumns() {
      const format = this.views.average ? 'numberWithOneDecimal' : 'number';
      const columns = this.schools.map(school => ({ key: school.id,
        click: this.columnClick,
        getColorIndexValue: this.colorIndex(school.id),
        label: school.name,
        textAlign: 'center',
        format,
        headerFilters: ['cleanSchoolName', 'startCase'],
        backgroundType: 'discrete',
        discreteColors: { low: '#FF0000', high: '#FFFFFF' },
        type: 'data-box' }));
      columns.unshift({ label: 'Nivel', key: 'group_name', textAlign: 'left', sticky: true });
      columns.push({ label: 'Total',
        textAlign: 'center',
        key: 'group_total',
        format,
        type: 'data-box',
        backgroundType: 'discrete',
        discreteColors: { low: '#FF0000', high: '#FFFFFF' },
        getColorIndexValue: this.colorIndex('group_total') });
      return columns;
    },
    mapColumns() {
      return this.schools.map(school => ({ id: school.id, label: cleanSchoolName(school.name), click: this.colorMapColumnClick }));
    },
    mapRows() {
      return this.levels.concat([this.schools[0].all_group]).filter(l => l)
        .map(group => ({ id: group.id, key: group.key, label: group.name })); // eslint-disable-line camelcase
    },
    mapResults() {
      let result;
      return this.attendances.map(enr => {
        result = this.capacities.find(cap => cap.group_id === enr.group_id && cap.school_id === enr.school_id);
        if (!result) return null;
        return {
          column_id: enr.school_id, // eslint-disable-line camelcase
          group_id: enr.group_id, // eslint-disable-line camelcase
          norm_value: result.value - enr.group_enrollments, // eslint-disable-line camelcase
          value: result.value - enr.group_enrollments, // eslint-disable-line camelcase
        };
      }).filter(x => x);
    },
  },
  watch: {
    chosenYear: {
      async handler() {
        await this.fetchReport();
      },
      inmediate: true,
    },
  },
  async mounted() {
    await this.fetchOptions();
    this.fetchReport();
  },
  methods: {
    ...mapActions('options', ['setEnrollmentDisplay']),
    ...mapMutations('options', { setYears: 'SET_YEARS' }),
    groupOrder(group) {
      let prefix;
      const educationType = group.education_type;
      if (educationType === '10') prefix = '1';
      else if (/^2\d+$/.test(educationType)) prefix = '2';
      else if (educationType === '110') prefix = '3';
      else if (['310', '410', '510', '610', '710', '810', '910'].includes(educationType)) prefix = '4';
      else prefix = '5';
      return parseFloat(prefix + group.grade);
    },
    changeView(value) {
      this.$router.push({
        name: 'enrollmentByLevel',
        params: { enrollmentDisplay: value, year: this.chosenYear },
      });
    },
    columnClick(column) {
      this.$router.push({
        name: 'enrollmentTracking',
        params: { school: column.key, year: this.chosenYear, month: this.chosenMonth },
      });
    },
    colorMapColumnClick(column) {
      this.$router.push({
        name: 'occupancyTracking',
        params: { school: column.id, year: this.chosenYear, month: this.chosenMonth },
      });
    },
    colorIndex(schoolId) {
      return (row) => (row[schoolId] <= this.range[1] && this.averageDisplayed ? 'low' : 'high');
    },
    async fetchOptions() {
      this.loading++;
      try {
        const { body } = await EnrollmentDashboardApi.getOptions();
        this.error = false;
        this.setYears(body.enrollment_dashboard);
      } catch (error) {
        this.error = true;
      } finally {
        this.loading--;
      }
    },
    async fetchReport() {
      this.loading++;
      try {
        const fetchedYear = this.years.includes(this.chosenYear) ? this.chosenYear : this.years[0];
        if (fetchedYear) {
          const { body } = await EnrollmentDashboardApi.administratorReport(fetchedYear);
          this.error = false;
          this.schools = body.enrollment_dashboard;
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading--;
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../../styles/app/variables";

.level-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;
  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
      justify-content: center;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $sidebar-background;
      }
    }
    tr:hover {
      td {
        background-color: #FBFBFC;
      }
    }
  }
}

.level-report-header-cell {
  justify-content: center ;
}

div.school-report-header-cell {
  height: 42px;
}

.table-selector {
  display: flex;
  flex-direction: row;
}

.selector-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 30px;

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    justify-content: flex-start;

    .table-selector {
      height: 30px;
    }
  }
}

.slider {
  display: flex;
  height: 100px;
}

.switch {
  margin-right: 5px;
}

.empty {
  padding-top: 20px;
  text-align: center;
}

</style>
