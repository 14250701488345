<template>
  <div v-if="valid">
    <school-attachment-button
      v-if="attachments.length == 1"
      :label="attachments[0].label"
      :school-attachment="attachments[0].schoolAttachment"
    />
    <school-attachment-dropdown
      v-else-if="attachments.length > 1"
      :attachments="attachments"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SchoolAttachmentsApi from '../api/school-attachments';
import PermissionMixin from '../mixins/permission';
import SchoolAttachmentButton from './school-attachment-button';
import SchoolAttachmentDropdown from './school-attachment-dropdown';

export default {
  name: 'SchoolAttachmentDownload',
  components: {
    SchoolAttachmentButton,
    SchoolAttachmentDropdown,
  },
  mixins: [PermissionMixin],
  props: {
    reportsParams: {
      type: Array,
      default: () => ([]),
    },
    keyParams: {
      type: Object,
      default: () => ({}),
    },
    updateHasSchoolAttachments: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      attachments: [],
    };
  },
  computed: {
    valid() {
      const validKeyParams = !Object.keys(this.keyParams).find(key => !this.keyParams[key]);
      return this.attachments.length > 0 && validKeyParams;
    },
    reportMappings() {
      return {
        'group-report': {
          attachmentKey: this.groupReportKey('group-report'),
          permission: 'group_report',
        },
        'student-report': {
          attachmentKey: this.groupReportKey('student-report'),
          permission: 'student_report',
        },
        'all-student-reports': {
          attachmentKey: this.groupReportKey('all-student-reports'),
          permission: 'student_report',
        },
        'presente-attendance-pdf': {
          attachmentKey: this.attendanceKey(),
          permission: 'presente_attendance_pdf',
        },
        'alertas-de-inicio-de-ano-escolar': {
          attachmentKey: this.spikeAlertsKey('alertas-de-inicio-de-ano-escolar'),
          permission: 'spike_alerts',
        },
        ...(this.keyParams.hasOwnProperty('chosenSurveyTypes') ?
          Object.fromEntries(
            this.keyParams.chosenSurveyTypes.map(surveyType => ([
              [`survey-report_${surveyType}`, {
                attachmentKey: this.surveyReportKey('survey-report', surveyType),
                permission: 'pulso',
              }],
              [`student-alerts_${surveyType}`, {
                attachmentKey: this.surveyReportKey('student-alerts', surveyType),
                permission: 'pulso',
              }],
              [`open-answers_${surveyType}`, {
                attachmentKey: this.surveyReportKey('open-answers', surveyType),
                permission: 'pulso',
              }],
            ])).flat(1)
          ) : {}),
      };
    },
  },
  watch: {
    keyParams: {
      async handler(to, from) {
        const changed = from && Object.keys(from).find(key => to[key] !== from[key]);
        if (!from || changed) {
          this.updateAttachments();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    async updateAttachments() {
      this.addLoading();
      this.attachments = [];
      this.attachments = await Promise.all(
        this.reportsParams.map(reportParams => this.fetchSchoolAttachment(reportParams))
      );
      this.attachments = this.attachments.filter(a => a && a.schoolAttachment);
      this.removeLoading();
      if (this.updateHasSchoolAttachments) {
        this.updateHasSchoolAttachments(this.attachments.length > 0);
      }
    },
    // eslint-disable-next-line max-statements
    async fetchSchoolAttachment(reportParams) {
      const { reportKey, label } = reportParams;
      const { permission, attachmentKey } = this.reportMappings[reportKey];
      let schoolAttachment = {};
      if (!this.hasPermission(permission)) return null;
      try {
        const { body } = await SchoolAttachmentsApi.getSchoolAttachment({
          attachment_key: attachmentKey, school_id: this.keyParams.chosenSchoolId, // eslint-disable-line camelcase
        });
        schoolAttachment = body.school_attachment || {};
      } catch (_) {
        this.error = true;
      }
      if (!schoolAttachment.url) return null;
      return { label, schoolAttachment };
    },
    groupReportKey(reportKey) {
      return [reportKey, this.keyParams.chosenGroupKey, this.keyParams.chosenYear].join('_');
    },
    attendanceKey() {
      return `presente_${this.keyParams.chosenYear}`;
    },
    spikeAlertsKey(reportKey) {
      return [
        this.keyParams.chosenYear,
        this.keyParams.chosenSchoolRbd,
        this.keyParams.chosenGroupKey,
        reportKey,
      ].join('_');
    },
    surveyReportKey(reportKey, surveyType) {
      return [
        reportKey,
        surveyType,
        this.keyParams.chosenSchoolRbd,
        this.keyParams.chosenYear,
        this.keyParams.chosenMonth,
      ].join('_');
    },
  },
};
</script>

<style lang="scss" module>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
</style>
