<template>
  <button
    :class="[
      'rdr-btn',
      'rdr-btn--small',
      { 'rdr-btn--primary': primary },
      'hide-print',
      'hide-mobile'
    ]"
    :disabled="loading"
    @click.prevent="print">
    <i class="rdr-btn__icon material-icons">print</i>
    <span>Imprimir</span>
  </button>
</template>

<script>
export default {
  name: 'PrintButton',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
