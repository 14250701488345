import { mapState, mapActions, mapGetters } from 'vuex';
import PermissionMixin from './permission';

export default {
  mixins: [PermissionMixin],
  computed: {
    ...mapState('results', ['resultGroups']),
    ...mapGetters('options', ['chosenGroup']),
  },
  watch: {
    resultGroups() {
      if (!this.chosenGroup) return;
      this.forceGroupWithResults();
    },
    chosenGroup(_, from) {
      if (from) return;
      this.forceGroupWithResults();
    },
  },
  methods: {
    ...mapActions('options', ['setChosenGroup']),
    forceGroupWithResults() {
      if (!this.resultGroups.map(group => (group.key)).includes(this.chosenGroup.key)) {
        let groupWithResults;
        if (this.isLoggedIn) {
          groupWithResults = this.resultGroups[0];
        }
        if (groupWithResults) this.setChosenGroup(groupWithResults.key);
      }
    },
  },
};
