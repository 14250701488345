<template>
  <el-dialog
    :visible.sync="show"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :show-close="true"
    :custom-class="$style.dialog"
    append-to-body>
    <div
      slot="title"
      :class="$style.dialogTitle">
      ¿Qué es Radar Escolar?
    </div>
    <div :class="$style.dialogBody">
      <p>
        Radar Escolar es la forma más fácil de gestionar información escolar. Integra datos de diferentes plataformas y la transforma en información útil sin necesidad de trabajo extra. Radar Escolar obtiene información desde: Sige, plataforma de gestión, evaluaciones Aptus, velocidad lectora, Simce, entre otros.
      </p>
      <p>
        La alianza Aptus - Radar Escolar te permite acceder a esta plataforma con un precio preferencial, integrando tus evaluaciones Aptus al plan Asistencia. Así, podrás, por ejemplo, ver el historial de cada estudiante en las pruebas Aptus, junto con su asistencia para generar mejores estrategias y remediales.
      </p>
      <div :class="$style.dialogBodyVideoContainer">
        <youtube
          ref="youtube"
          :player-width="'100%'"
          :video-id="videoId"
          :player-vars="playerVars"/>
      </div>
      <div :class="$style.buttonsContainer">
        <a
          :href="contactFormLink.url"
          :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.infoModalButton]">
          Contáctanos
        </a>
        <button
          :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.infoModalButton]"
          @click="openRadarInfoPage">Más información
          <i class="material-icons">open_in_new</i>
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import LinksMixin from '../../mixins/links';

export default {
  name: 'AptusHomeMoreInfoDialog',
  components: { 'el-dialog': Dialog },
  mixins: [LinksMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    videoId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playerVars: {
        autoplay: 0,
        rel: 0,
        modestbranding: 1,
      },
    };
  },
  computed: {
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
  },
  watch: {
    visible(to) {
      if (this.$refs.youtube) {
        if (to) {
          this.$refs.youtube.player.playVideo();
        } else {
          this.$refs.youtube.player.stopVideo();
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";
  @import "../../../styles/app/forms";

  .dialog {
    background-color: #FCFCFC;
    font-family: Muli;

    @media only screen and (max-width: #{$mobile-breakpoint}) {
      width: 90% !important;
    }

    &-title {
      color: $primary-color;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        word-break: break-word;
        font-size: 18px;
        text-align: justify;
        text-indent: 2rem;
        margin: 6px 0;
      }

      &-video-container {
        width: 100%;
        max-width: 640px;
        margin: 8px 0;
      }
    }
  }

  .buttons-container {
    & > .info-modal-button {
      width: 200px;
    }

    @media only screen and (max-width: #{$mobile-breakpoint}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

</style>
