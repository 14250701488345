<template>
  <a v-if="urlStorage"
    class="go-back-link hide-print"
    :href="url_storage">
    <i
      v-if="arrow"
      class="material-icons go-back-icon">keyboard_arrow_left</i>
    <span>{{ message }}</span>
  </a>
  <a v-else
    class="go-back-link hide-print"
    @click.prevent="$router.back">
    <i
      v-if="arrow"
      class="material-icons go-back-icon">keyboard_arrow_left</i>
    <span>{{ message }}</span>
  </a>
</template>

<script>
export default {
  name: 'GoBackLink',
  props: {
    message: {
      type: String,
      default: 'Volver',
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    urlStorage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url_storage: '',
    };
  },
  mounted() {
    this.url_storage = localStorage.getItem('url_student_report')
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/app/variables";

.go-back-link {
  display: inline-flex;
  cursor: pointer;
  color: $primary-color;
  text-decoration: none;

  span {
    border-bottom: 2px dashed;
    font-size: 20px;
    font-weight: 700;
  }
}

.go-back-icon {
  padding-top: 3px;
}
</style>
