<template>
  <div
    v-if="chosenYear !== currentSchoolYear"
    :class="hide ? $style.hideMessage : $style.warningBar"
  >
    <span
      :class="$style.warningBarText"
      @click="goToCurrentYear">
      <i class="clg-info" />
      <strong>¡Atención!</strong> Estás viendo resultados del año
      {{ chosenYear }}. Para ver datos del año actual haz click aquí.</span>
    <span
      :class="$style.warningBarText"
      @click="hide = !hide">
      <i class="clg-x" />
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import schoolYear from '../../utils/years';

export default {
  name: 'YearWarningBar',
  props: {
    middle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSchoolYear: schoolYear(),
      hide: false,
    };
  },
  computed: {
    ...mapState('options', ['chosenYear']),
  },
  methods: {
    ...mapActions('options', ['setChosenYear']),
    goToCurrentYear() {
      this.setChosenYear(this.currentSchoolYear);
    },
    hideMessage() {
      this.ocultar = false;
    },
  },
};
</script>

<style lang="scss" module>
@import '../../../styles/app/variables';

.warning-bar {
  position: absolute;
  text-align: center;
  display: flex;
  width: 100%;
  background-color: #fffdccfa;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-radius: 15px;
  margin: -30px 0 15px 0;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 302;
  min-height: 50px;
  height: 50px;
  background: $administrator-report-warning-color;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0px 15px;
  .warningBarText {
    color: #177ed3;
    font-size: 14px;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    position: relative;
    margin-top: -45px;
    height: auto;
  }
}
.hideMessage {
  opacity: 0;
  transition: opacity 2s linear;
  text-align: center;
  display: flex;
  width: 100%;
  background-color: #fffdccfa;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-radius: 15px;
  margin: -30px 0 15px 0;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 302;
  min-height: 50px;
  background: $administrator-report-warning-color;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0px 15px;
  .warningBarText {
    color: #177ed3;
    font-size: 14px;
  }
}
</style>
