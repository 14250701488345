<template>
  <div>
    <div
      v-if="gradesKeys.length === 0"
      :class="$style.noResultsMessage">
      <img src="/noResultados.svg">
      <!--No hay resultados de notas para este alumno.-->
    </div>
    <div
      v-else
      :class="$style.row">
      <div
        v-for="(indicatorKey) in gradesKeys"
        :key="indicatorKey"
        :class="[$style.greyBox, $style.row]">
        <result-box
          :title="indicatorLabel(indicatorKey).label"
          :value="resultChartValue(indicatorKey)"
          :custom-footer="resultChartCustomFooter(indicatorKey)"
          :relative-value="resultChartRelativeValue(indicatorKey)"
          metric-type="pts"/>
        <div :class="$style.column">
          <div :class="$style.fullWidth">
            <span :class="['info__label', $style.alignRight]">Evolución {{ indicatorLabel(indicatorKey).label }} últimos años</span>
          </div>
          <div :class="$style.row">
            <grades-chart
              :chart-raw-data="lineChartData(indicatorKey)"
              :y-axis-min="yAxisMin"
              :height="200"
              :width="250"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultBox from '../../components/utils/result-box.vue';
import HistoricChart from '../../components/historic-chart.vue';
import GradesChart from '../../components/grades-chart.vue';
import { APPROVAL_LIMIT_GRADE } from '../../utils/constants';

export default {
  name: 'StudentGradesView',
  components: {
    HistoricChart,
    ResultBox,
    GradesChart,
  },
  props: {
    grades: {
      type: Array,
      default: () => [],
    },
    years: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
      default: 0,
    },
    vAxisFormat: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentGrades() {
      return this.grades.filter(grade => this.year === grade.year);
    },
    gradesKeys() {
      const keys = Array.from(new Set(this.grades.map(e => e.key)));
      const mainSubjects = ['lenguaje-y-comunicacion', 'matematica', 'ciencias-naturales', 'historia-geografia-y-ciencias-sociales'];
      const subjDiff = keys.filter(key => !mainSubjects.includes(key));
      const keysCount = subjDiff.map(key => this.grades.filter(grade => grade.key === key).length);
      const subjGradesCount = subjDiff.map((subj, i) => [subj, keysCount[i]]);

      return [
        ...mainSubjects.filter(subj => keys.includes(subj)),
        ...subjGradesCount.sort((a, b) => b[1] - a[1]).map(subj => subj[0]),
      ];
    },
    yAxisMin() {
      return Math.floor(Math.min(...[...this.grades.map(e => [e.course_value, e.group_value, e.student_value]).flat(Infinity)]));
    },
  },
  methods: {
    lineChartData(key) {
      const rows = this.evolutionRows(key);
      return {
        years: rows.map(x => x[0]),
        studentGrades: rows.map(x => x[1]),
        groupGrades: rows.map(x => x[2]), // eslint-disable-line no-magic-numbers
      };
    },
    evolutionRows(key) {
      return this.years.map(year => {
        const yearlyResult = this.grades.find(result => result.year === year && result.key === key);
        return [
          year.toString(),
          yearlyResult && typeof yearlyResult.student_value === 'number' ?
            yearlyResult.student_value : null,
          yearlyResult && typeof yearlyResult.course_value === 'number' ?
            yearlyResult.course_value : null,
        ];
      });
    },
    repetitionGrade(grade) {
      if (grade.student_value && grade.student_value < APPROVAL_LIMIT_GRADE) return true; // eslint-disable-line no-magic-numbers
      if (grade.group_value && grade.group_result < APPROVAL_LIMIT_GRADE) return true; // eslint-disable-line no-magic-numbers
      return false;
    },
    relativeGrade(grade) {
      const value = grade.course_value && grade.student_value ? grade.student_value - grade.course_value : null;
      return value ? Math.round(value * 10) / 10 : null; // eslint-disable-line no-magic-numbers
    },
    gradeChartFrame(key) {
      const grades = this.grades.filter(result => result.key === key);
      const chartFrame = { min: 4, max: 7 };
      if (grades.some(this.repetitionGrade)) chartFrame.min = 1;
      return chartFrame;
    },
    gradeTicks(key) {
      const frame = this.gradeChartFrame(key);
      if (frame.min === 4) return [4, 5, 6, 7]; // eslint-disable-line no-magic-numbers
      return [1, 3, 5, 7]; // eslint-disable-line no-magic-numbers
    },
    indicatorLabel(key) {
      return this.grades.find(g => g.key === key);
    },
    currentGradeFromKey(key) {
      return this.currentGrades.find(g => g.key === key);
    },
    resultChartValue(key) {
      const grade = this.currentGradeFromKey(key);
      return grade && grade.student_value;
    },
    resultChartCustomFooter(key) {
      return this.currentGradeFromKey(key) ? null : `No tiene calificación del ramo el ${this.year}`;
    },
    resultChartRelativeValue(key) {
      const grade = this.currentGradeFromKey(key);
      if (!grade) return null;
      const hasNumericGrades = ['course_value', 'student_value'].every(attr => (
        grade[attr] && typeof grade[attr] === 'number'
      ));
      const value = hasNumericGrades ? grade.student_value - grade.course_value : null;
      return value === null ? null : Math.round(value * 10) / 10; // eslint-disable-line no-magic-numbers
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
.row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.no-results-message {
  text-align: center;
}

.historic-grade-title {
  text-align: right;
  font-weight: bold;
  margin-left: 10px;
}
.column{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.full-width {
  width: 100%;
  text-align: center;
}
.grey-box {
  background-color: #FBFBFE;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 10px !important;
}
</style>
