import Vue from 'vue';

export default {
  getQuote(schools) {
    return Vue.http.post('/api/v1/quotations.json', {
      schools,
    });
  },
  saveQuote({ schools, name, email, phone, relation, selectedPlan }) {
    return Vue.http.post('/api/v1/quotations.json', {
      schools,
      name,
      email,
      phone,
      relation,
      selectedPlan,
    });
  },
};
