<template>
  <div :class="$style.boxWrapper">
    <div
      :class="[$style.box, $style.columnFlex]">
      <strong :class="$style.title">{{ title }}</strong>
      <circular-progress-bar
        :total="100"
        :value="value"
        :class="$style.progressBar"/>
      <strong>{{ footer | upperCase }}</strong>
    </div>
  </div>
</template>

<script>
import CircularProgressBar from '../base/circular-progress-bar';

export default {
  name: 'PercentageDonutResult',
  components: { CircularProgressBar },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    footer: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

$background-color: #FBFBFE;

.box-wrapper{
  background: $background-color;
  padding: 10px;
  margin: 10px;
}

.box {
  padding: 3px;
  width: 200px;
  height: 190px;
  flex-grow: 0;
  border-radius: 5px;
  font-weight: 200;
  border-style: solid;
  border-width: thin;
  text-align: center;
  position: relative;
  text-align: center;
  color: $main-text-color;
  border-color: $light-gray-color;
  @media print {
    border-style: solid;
    border-width: 2px;
  }
}

.title {
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
}

.progressBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
  background: $background-color
}

.column-flex{
   display: flex;
   flex-direction: column;
   justify-content: center;
}

</style>
