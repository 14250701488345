<template>
  <div :class="$style.filterss">
    <el-dialog
      :class="$style.filterssDialog"
      :visible.sync="show"
      append-to-body>
      <div
        slot="title"
        :class="$style.filterssDialogHeader">
        <div :class="$style.filterssDialogTitle">Formulario para generar cartas de asistencia para apoderados</div>
        <div :class="$style.filterssDialogSubtitle">
            Al completar este formulario, te llegará un email con las cartas especialmente diseñadas para comunicar a los apoderados sobre la asistencia de sus pupilos. 
            Estas te llegarán en PDF y pueden ser impresas para adjuntarlas a las agendas de los estudiantes.
        </div>
      </div>
        
      <div :class="$style.filterssDialogConten">
        <!-- <hr :class="$style.filterssDialogDivider" > -->
        <div v-if="submitting">
          <transition name="fade">
            <loading-overlay/>
          </transition>
        </div>
        <div
          v-else
          :class="$style.contenForm">
          <div
            v-if="!submitted"
            :class="$style.attendanceCompromiseForm">
            <form
              @submit.prevent="submitAttendanceCompromiseData()">
              <strong>
                Umbral de asistencia
              </strong>
              <div
                :class="$style.sliderWrapper">
                <el-slider
                  v-model="attendanceLevelValue"
                  :marks="marks"
                  :class="$style.sliderWrapperSlider"/>
                <input
                  v-model.number="attendanceLevel"
                  :class="[$style.sliderWrapperInput, 'rdr-input', 'rdr-input--block']"
                  type="string"
                  disabled
                  required>
              </div>
              <p style="display: grid;width: 98%;">
                Se generarán para todos los estudiantes con asistencia <strong>igual o menor a {{ attendanceLevel }}</strong>
              </p><br>
              <strong>Nombre de quien firma la carta</strong>
              <input
                v-model="signerName"
                class="rdr-input rdr-input--block"
                type="text"
                placeholder="Escribir nombre y apellido"
                title="Debes rellenar este campo"
                required >
              <strong>Cargo de quien firma la carta</strong>
              <input
                v-model="signerRole"
                class="rdr-input rdr-input--block"
                type="text"
                placeholder="Escribir cargo"
                title="Debes rellenar este campo"
                required >
              <strong>Lema escolar para carta (Opcional)</strong>
              <input
                v-model="motto"
                :maxlength="100"
                class="rdr-input rdr-input--block"
                type="text"
                placeholder="Escribir lema escolar">
              <div :class="$style.buttonWrapper">
                <button
                  :disabled="submitting"
                  class="rdr-btn rdr-btn--primary"
                  type="submit">
                  <span v-show="!submitting">Generar cartas de asistencia</span>
                  <span v-show="submitting">Generando cartas de asistencia</span>
                </button>
              </div>
            </form>
          </div>

          <div
             v-else-if="submitted && anyStudents"
            :class="['attendance_compromise_form--submitted', $style.txtCenter]">
            <span>¡Estamos generando las cartas de asistencia!<br><br>Te llegará un email a la brevedad.</span>
          </div>

          <div
            v-else-if="submitted && !anyStudents"
            :class="['attendance_compromise_form--no-students', $style.txtCenter]">
            <p>¡Buenas noticias! No hay estudiantes con menos de {{ attendanceLevel }} de asistencia. Puedes intentar con un porcentaje de asistencia más alto.</p>
            <br>
            <button
              class="rdr-btn rdr-btn--primary"
              @click="restartForm">
              <span>OK</span>
            </button>
          </div>

        </div>
        <div
          v-if="isMobile"
          :class="['mobile-options', $style.txtCenter]">
          <button
            :disabled="submitting"
            class="rdr-btn"
            type="submit"
            @click="show = !show">
            <span>Volver</span>
          </button>
        </div>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Dialog, Slider } from 'element-ui';
import SchoolAttachmentsApi from '../../api/school-attachments';
import InfoIcon from '../../components/info-icon.vue';
import AuthMixin from '../../mixins/auth';
import DisplayMixin from '../../mixins/display';

export default {
  name: 'AttendanceCompromiseForm',
  components: {
    'el-dialog': Dialog,
    'el-slider': Slider,
    InfoIcon,
  },
  mixins: [AuthMixin, DisplayMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signerName: '',
      signerRole: '',
      motto: 'El aprendizaje se construye en el Colegio día a día',
      attendanceLevelValue: 85,
      submitting: false,
      submitted: false,
      anyStudents: false,
      error: false,
      attachment: {},
      marks: {
        0: '0%',
        100: {
          style: {
            width: '10%',
          },
          label: '100%',
        },
      },
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolId', 'chosenYear']),
    ...mapGetters('options', ['chosenSchool']),
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
    attendanceLevel: {
      get() {
        return this.attendanceLevelValue === 0 ? '0%' : `${this.attendanceLevelValue}%`;
      },
      set(newValue) {
        this.attendanceLevelValue = newValue;
      },
    },
  },
  watch: {
    chosenSchoolId() {
      this.submitted = false;
    },
  },
  methods: {
    async submitAttendanceCompromiseData() {
      this.submitting = true;
      try {
        await SchoolAttachmentsApi.generateSchoolAttachmentPdf({
          user_id: this.currentUser.id, // eslint-disable-line camelcase
          rbd: this.chosenSchool.rbd,
          attachment_key: 'compromise', // eslint-disable-line camelcase
          year: this.chosenYear,
          signer_name: this.signerName, // eslint-disable-line camelcase
          signer_role: this.signerRole, // eslint-disable-line camelcase
          attendance_threshold: this.attendanceLevelValue, // eslint-disable-line camelcase
          motto: this.motto,
        }).then(successResponse => {
          if (successResponse.status >= 200 && successResponse.status < 300) { // eslint-disable-line no-magic-numbers
            this.anyStudents = true;
          }
        }, errorResponse => {
          if (errorResponse.status >= 400 && errorResponse.status < 500) { // eslint-disable-line no-magic-numbers
            this.anyStudents = false;
          }
        });
      } catch (_) {
        this.error = true;
      } finally {
        this.submitting = false;
        this.submitted = true;
      }
    },
    restartForm() {
      this.submitting = false;
      this.submitted = false;
      this.anyStudents = false;
    },
  },
};

</script>

<!-- <style lang="scss" scoped> -->
<style lang="scss" module>
  @import "../../../styles/app/variables";
  .filterss-dialog {
    &-conten {
      height: 100%!important;
    }

    &-header {
      white-space: pre-line;
      word-break: break-word;
    }

    &-title {
      font-size: 24px;
      font-weight: 600;
      padding-right: 30px;
      text-align: left;
    }

    &-subtitle {
      font-size: 15px;
      margin: 0 10px 0px;
      text-align: justify;
    }

    &-divider {
      color: $light-color;
      opacity: 0.4;
      margin: 25px auto 15px auto;
    }

    :global(.el-dialog__body) {
      height: calc(100% - 200px) !important;
      padding-top: 0px;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 !important;
      width: 100vw;
      height: 100vh;
      overflow: hidden;

      &-subtitle {
        margin: 0;
      }

      &__divider {
        margin: 10px auto 10px auto;
      }

      &__body {
        margin-top: -30px;
      }

    }
  }

  .conten-form {
    overflow:auto;
    padding-top: 10px;
    height: 100%;
  }

  .button-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 40px !important;
  }

  .attendance-compromise-form {
    padding: 10px 5px;
    font-size: 16px;
    word-break: break-word;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }

    &--submitted, &--no-students{
      text-align: center;
      margin: 10px 0 24px;
      font-size: 20px;
      color: $success-color;

      p {
        font-size: 20px;
        word-break: break-word;
      }
    }

    .slider-wrapper{
      display: flex;
      justify-content: space-between;
      word-break: keep-all;
      @media only screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
      }

      &-slider {
        width: 60%;
        margin: 0 30px;
        @media only screen and (max-width: $mobile-breakpoint) {
          width: 70%;
        }
      }
      &-input {
        margin: 3px 30px 0 0;
        width: 30%;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        @media only screen and (max-width: $mobile-breakpoint) {
          margin: 20px 0 0 10px;
          width: 90%;
        }
      }
    }
    strong {
      padding-top: 15px;
    }
    form {
      > * {
        // display: block;
        width: 100%;

        &:not(:first-child) {
          margin-top: 15px;
        }
      }

      input {
        width: 95%;
        height: 45px;
        margin-top: 4px !important;
        margin-bottom: 20px !important;
      }

      button {
        margin: 10px 0;
        height: 35px;
        width: auto;
      }
    }
  }

  .mobile-options {
    display: flex;
    justify-content: center;
    width: 85vw;

    button {
      width: 40vw;
    }
  }

  .txt-center {
    text-align: center !important;
  }
</style>
