<template>
  <error-message v-if="error"/>
  <div
    v-else-if="areReportAndPageLoaded"
    ref="dataStudioContainer"
    :class="$style.reportContainer"
  >
    <report-description
      :description="instructions"
      :new-stayle="true"
      />
    <div :class="$style.titleContainer">
      <span :class="$style.title">
        {{ report.name }}
      </span>
    </div>
    <div :class="$style.options">
      <print-tutorial
        :body="printInstructions"
        :image-src="tutorialSrc"
        button-title="Guía de impresión"
        title="¿Cómo descargar este reporte en PDF?"
        subtitle="Vídeo de cómo hacerlo"
        primary/>
    </div>
    <tabs
      v-if="tabs"
      :class="$style.dataStudioTabs"
      :tabs="tabs"
      :tab-width="220"
      v-model="currentPage"
    />

    <template v-if="switchShow">
      <h4 :class="$style.txtCenter">Escala</h4>
      <rdr-switcher
        :views="views"
        @switcher-toggle="toggleFetchReady"
      />
    </template>
    <template v-if="loadIframe">
      <iframe
        v-if="linkData"
        :class="$style.googleReport"
        :width="widthIFRM"
        :height="heightIFRM"
        :src="linkData"
        frameborder="0"
      />
      <iframe
        v-else
        :class="$style.googleReport"
        :width="widthIFRM"
        :height="heightIFRM"
        :src="link"
        frameborder="0"
      />
    </template>
    <loading-overlay v-if="loadingShow"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import DataStudioReportApi from '../api/data-studio-reports';
import ErrorMixin from '../mixins/error';
import ReportDescription from './results/report-description.vue';
import Tabs from './tabs.vue';
import TutorialGif from '../components/utils/tutorial-gif.vue';
import RdrSwitcher from '../components/utils/switcher.vue';

const HEIGHT_OFFSET = 100;

export default {
  name: 'DataStudioReport',
  components: { ReportDescription, Tabs, 'print-tutorial': TutorialGif, 'rdr-switcher': RdrSwitcher },
  mixins: [ErrorMixin],
  props: {
    reportKey: {
      type: String,
      default: 'classroom-attendance',
    },
  },
  data() {
    return {
      report: null,
      currentPage: null,
      dataStudioContainerWidth: 500,
      instructions:
        'Esta sección solo está disponible para los usuarios de Radar Escolar que usan Google como \
        motor de sus cuentas de correo. Para ver el reporte, tienes que tener sesión iniciada en \
        Google en este mismo browser, con el correo de usuario de Radar Escolar. Además, la \
        sesión en esa cuenta de Google tiene que haber sido iniciada como cuenta primaria. Si el \
        reporte no te aparece te recomendamos: \n\n1.- Cerrar toda sesión en Google dentro del \
        mismo browser y cerrar sesión también en Radar Escolar. \n2.- Iniciar sesión en Google con \
        la misma cuenta que usas para Radar Escolar. \n3.- Iniciar sesión en Radar Escolar con esa \
        cuenta.',
      printInstructions:
       'Mueva el cursor hacia el espacio en blanco abajo de los selectores, y haga click con el botón \
       derecho del mouse. Se desplegarán las opciones de descarga',
      widthIFRM: 0,
      heightIFRM: 0,
      loadIframe: false,
      views: {
        left: {
          label: 'PAES',
          component: '',
          color: 'rgb(5, 189, 60)',
        },
        right: {
          label: 'PSU',
          component: '',
          color: 'rgb(237, 5, 11)',
        },
      },
      linkData: null,
      loadingShow: false,
      switchShow: false,
    };
  },
  computed: {
    ...mapState('ui', ['displayMenu', 'menuHover']),
    link() {
      this.linkData = null;
      this.loadingShow = true;
      this.switchShow = false;
      const linkInfo = this.renderLink('left');
      return linkInfo;
    },
    dataStudioReportStyle() {
      if (!this.areReportAndPageLoaded) return {};
      return {
        width: `${this.dataStudioContainerWidth}px`,
        // height: `${(this.dataStudioContainerWidth * this.report.ratio) + HEIGHT_OFFSET}px`,
        height: '800px',
      };
    },
    tabs() {
      this.linkData = null;
      return this.areReportAndPageLoaded && this.report.pages.map(page => ({
        key: page.key, text: page.name, icon: '',
      }));
    },
    areReportAndPageLoaded() {
      return this.report && this.currentPage;
    },
    tutorialSrc() {
      switch (this.$route.name) {
      case 'administratorGoogleClassroom':
        return '/tutorial-classroom-schools-download.gif';
      case 'googleClassroomBySchool':
        return '/tutorial-classroom-school-download.gif';
      default:
        return '/tutorial-classroom-school-download.gif';
      }
    },
  },
  created() {
    this.fetchReport();
  },
  watch: {
    menuHover(newMenuHover, oldMenuHover) {
      this.renderLoadIframe();
    },
    displayMenu(newDisplay, oldDisplay) {
      this.renderLoadIframe('HOVER');
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateContainerWidth);
    });
    this.renderLoadIframe();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateContainerWidth);
  },
  methods: {
    ...mapMutations('ui', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    updateContainerWidth() {
      this.dataStudioContainerWidth = (
        this.$refs.dataStudioContainer && this.$refs.dataStudioContainer.clientWidth
      );
    },
    async fetchReport() {
      await this.fetch(async () => {
        const { body: { data_studio_report: report } } = await DataStudioReportApi.getReport(
          this.reportKey
        );
        if (report) {
          this.report = report;
          this.currentPage = report.pages && report.pages[0].key;
        }
      });
    },
    async fetch(fetchFunction) {
      this.addLoading();
      try {
        await fetchFunction();
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
        this.updateContainerWidth();
      }
    },
    renderLoadIframe(evento=null) {
      this.heightIFRM = window.innerHeight - 90;
      if (evento === 'HOVER') {
        this.displayMenu
          ? this.widthIFRM = window.innerWidth - 352
          : this.widthIFRM = window.innerWidth - 156
      } else {
        this.menuHover
          ? this.widthIFRM = window.innerWidth - 156
          : this.widthIFRM = window.innerWidth - 352
      }
      setTimeout(() => {
        this.loadIframe = true;
      }, 800);
    },
    toggleFetchReady(even) {
      this.loadingShow = true;
      this.loadIframe = false;
      this.linkData = this.renderLink(even);
    },
    renderLink(even) {
      this.loadIframe = false;
      let keyRep = this.currentPage;
      if (this.reportKey === 'psu-details') {
        const keysReport = this.currentPage.split(';');
        keyRep = keysReport[0];
        if (even === 'right') keyRep = keysReport[1];
      }
      setTimeout(() => {
        this.loadingShow = false;
        this.loadIframe = true;
        if (this.reportKey === 'psu-details') this.switchShow = true;
      }, 600);
      return this.areReportAndPageLoaded && `${this.report.link}/page/${keyRep}`;
    },
  },
};

</script>

<style lang="scss" module>
.report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 28px;
      padding-bottom: 10px;
    }
  }
  .txt-center {
    text-align: center;
  }

  .data-studio-tabs {
    width: 100%;
  }

  .google-report {
    margin: 20px 0;
    border: 0;
  }

  .options {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  :global(.iconInfoAlertTool) {
    top: 1px !important;
    right: 1px !important;
  }
}
</style>
