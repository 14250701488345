<template>
  <div class="results-column">
    <div
      v-if="loading || (results && results.length > 0)"
      class="results-column__content">
      <column-chart
        :loading="loading"
        :columns="chartColumns"
        :rows="chartRows"
        class="results-column__chart"
        is-stacked="percent"
        custom-tooltip
      />
      <div class="results-column__slider-title">
        <rdr-tooltip placement="left">
          <div>En esta barra se puede cambiar el porcentaje de corte que define los Niveles de logro Alto, Medio y Bajo.</div>
          <div slot="reference">Ajusta los niveles de logro</div>
        </rdr-tooltip>
      </div>
      <slider
        v-if="!loading"
        v-model="range"
        :colors="colors"
        type="percentage"
        class="results-column__slider"
        vertical/>
      <div class="results-column__footer">
        <div class="legend">
          <div
            :style="{ 'background-color': colors[0] }"
            class="legend__color"/>
          <div class="legend__container">
            <div class="legend__text">Nivel de Logro Bajo</div>
            <div class="legend__text--blur">(Estudiantes con porcentaje de logro <strong>menor o igual a {{ range[0] }}%</strong>).</div>
          </div>
        </div>
        <div class="legend">
          <div
            :style="{ 'background-color': colors[1] }"
            class="legend__color"/>
          <div class="legend__container">
            <div class="legend__text">Nivel de Logro Medio</div>
            <div class="legend__text--blur">(Estudiantes con porcentaje de logro <strong>mayor a {{ range[0] }}% y menor o igual a {{ range[1] }}%</strong>).</div>
          </div>
        </div>
        <div class="legend">
          <div
            :style="{ 'background-color': colors[2] }"
            class="legend__color"/>
          <div class="legend__container">
            <div class="legend__text">Nivel de Logro Alto</div>
            <div class="legend__text--blur">(Estudiantes con porcentaje de logro <strong>mayor a {{ range[1] }}%</strong>).</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="results-column__empty">
      <img src="/noResultados.svg">
      <!-- <p>No hay resultados para las opciones seleccionadas.</p> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColumnChart from '../../../components/column-chart.vue';
import Slider from '../../../components/utils/slider.vue';
import ResultsMixin from '../../../mixins/results';

export default {
  name: 'InternalEvaluationsLevelTestsByLevelView',
  components: { ColumnChart, Slider },
  mixins: [ResultsMixin],
  data() {
    return {
      range: [50, 75], // eslint-disable-line no-magic-numbers
      colors: [
        '#3839ff',
        '#9897ff',
        '#c7c9ff',
      ],
    };
  },
  computed: {
    ...mapState('results', {
      resultsLoading: 'loading',
    }),
    ...mapGetters('results', ['results']),
    ...mapGetters('results', {
      groups: 'resultsGroups',
    }),
    bottomValue() { return this.range[0]; },
    topValue() { return this.range[1]; },
    loading() { return !!this.resultsLoading; },
    chartRows() {
      return this.groups.map((g) => {
        const results = this.results.filter(r => r.group_id === g.id);
        const low = results.filter(r => r.value <= this.bottomValue).length;
        const medium = results.filter(r => r.value > this.bottomValue && r.value <= this.topValue).length;
        const high = results.filter(r => r.value > this.topValue).length;
        const total = low + medium + high;
        return [
          g.short_name,
          low,
          `Nivel Bajo: ${low} estudiantes de ${total}`,
          medium,
          `Nivel Medio: ${medium} estudiantes de ${total}`,
          high,
          `Nivel Alto: ${high} estudiantes de ${total}`,
        ];
      });
    },
    chartColumns() {
      return [
        `Nivel de Logro Bajo\n(Estudiantes con porcentaje de logro menor o igual a ${this.bottomValue}%)`,
        `Nivel de Logro Medio\n(Estudiantes con porcentaje de logro mayor a ${this.bottomValue}% y menor o igual a ${this.topValue}%)`,
        `Nivel de Logro Alto\n(Estudiantes con porcentaje de logro mayor a ${this.topValue}%)`,
      ];
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({
        'school_ids': true, 'group_ids': true, 'indicator_ids': true,
        'years': true, 'months': true });
      this.setReportKey('pdn-by-level');
    },
  },
};
</script>

<style lang="scss" scoped>
  .results-column {
    position: relative;
    margin-bottom: 60px;
    &__empty {
      text-align: center;
    }
    &__content {
      display: grid;
      position: relative;
      grid-template-columns: auto 20px 156px;
      grid-template-rows: auto 400px 20px auto;
      grid-template-areas:
        "chart . sliderTitle"
        "chart . slider"
        ". . ."
        "footer . .";
    }
    &__chart {
      grid-area: chart;
    }
    &__slider {
      grid-area: slider;
      margin-left: 54px;
    }
    &__slider-title {
      grid-area: sliderTitle;
      text-align: center;
      font-size: 18px;
    }
    &__footer {
      grid-area: footer;
      display: flex;
      justify-content: space-evenly;
    }
    &__results {
      display: flex;
      justify-content: space-between;
    }
  }
  .legend {
    display: flex;
    margin: 0 5px;
    &__color {
      min-width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 150px;
    }
    &__text {
      font-size: 14px;
      &--blur {
        font-size: 12px;
        opacity: 0.7;
      }
    }
  }
</style>
