<template>
  <!-- <div
    :class="$style.container">
    <a
      :href="aptusLink"
      class="rdr-btn rdr-btn--primary"
      target="_blank">
      <span>{{ 'Ver detalle en Aptus' }}</span>
    </a> -->
  </div>
</template>

<script>
export default {
  name: 'AptusLink',
  props: {
    aptusLink: {
      type: String,
      default: 'https://www.aptus.org/',
    },
  },
};
</script>

<style lang="scss" module>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
</style>
