<template>
  <el-dialog
    :visible.sync="show"
    append-to-body
    class="radar-dialog"
    center>
    <div
      slot="title"
      class="radar-dialog__header">
      <div class="radar-dialog__title"><span>Formulario para solicitar acceso</span></div>
      <div class="radar-dialog__subtitle">
        <span>
          Al completar este formulario, contactaremos a usuarios validadores de tu colegio para solicitar tu acceso.
        </span>
      </div>
    </div>
    <div class="radar-dialog__body">
      <hr class="radar-dialog__divider" >
      <error-message v-if="error"/>
      <div v-else-if="submitting">
        <transition name="fade">
          <loading-overlay/>
        </transition>
      </div>
      <template v-else>
        <form
          v-if="!submitted"
          class="access-request__form"
          @submit.prevent="requestAccessToPlatform">
          <textarea
            v-model="message"
            class="access-request__input rdr-input"
            type="text"
            placeholder="Ingresa un mensaje para solicitar acceso"
          />
          <div class="access-request__button-wrapper">
            <button
              :disabled="submitting"
              class="rdr-btn rdr-btn--primary"
              type="submit">
              <span v-show="!submitting">Enviar solicitud</span>
              <span v-show="submitting">Enviando solicitud</span>
            </button>
          </div>
        </form>
        <div
          v-if="submitted"
          class="access-request__form access-request__form--submitted">
          <span>¡Hemos recibido tu solicitud!<br><br>Nos contactaremos con los encargado de tu colegio a la brevedad.</span>
        </div>
      </template>
      <div
        v-if="isMobile"
        class="mobile-options">
        <button
          :disabled="submitting"
          class="rdr-btn"
          type="submit"
          @click="show = !show">
          <span>Volver</span>
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { Dialog } from 'element-ui';
import AuthApi from '../../api/auth';
import AuthMixin from '../../mixins/auth';
import DisplayMixin from '../../mixins/display';
import ErrorMixin from '../../mixins/error';

export default {
  name: 'PlatformAccessRequestForm',
  components: {
    'el-dialog': Dialog,
  },
  mixins: [AuthMixin, DisplayMixin, ErrorMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    requestedAccessToPlaform: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitting: false,
      submitted: false,
      message: '',
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
  },
  methods: {
    async requestAccessToPlatform() {
      try {
        this.submitting = true;
        const message = this.message.length > 0 ? this.message : null;
        const { status } = await AuthApi.requestPlatformAccess(this.userInfo.id, message);
        // eslint-disable-next-line no-magic-numbers
        if (status >= 400) throw Error('Request Platform Access Error');
        // eslint-disable-next-line no-magic-numbers
        this.$emit('update:requestedAccessToPlaform', status === 200);
      } catch (_) {
        this.error = true;
      } finally {
        this.submitting = false;
        this.submitted = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";

  .access-request {
    &__button-wrapper {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 40px !important;
    }

    &__icon {
      color: $success-color;
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 45px;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 100%;
      }

      &--submitted {
        word-break: break-word;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 24px;
        font-size: 20px;
        color: $success-color;
      }
    }

    &__input {
      width: 80%;
      height: 200px;
      margin-bottom: 15px;
      max-width: 80%;
      max-height: 300px;
      border-radius: 10px;
    }
    &__required {
      display: block;
      margin: 15px auto;
      color: red;
      font-weight: 400;
      text-align: center;
    }
    .rdr-btn {
      margin-top: 35px;
    }
  }

</style>
