<template>
  <div class="downloads">
    <h1>Descargas</h1>
    <div v-if="!attachments">Cargando...</div>
    <div v-if="attachments && !attachments.length">No tienes descargas disponibles.</div>
    <table v-if="attachments && attachments.length">
      <thead>
        <tr>
          <th class="left-text">Nombre</th>
          <th class="center-text">Última actualización</th>
          <th/>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="attachment in attachments"
          :key="attachment.id">
          <td class="left-text">{{ attachment.name }}</td>
          <td class="center-text">{{ toLocal(attachment.updated_at, 'LLL') }}</td>
          <td class="right-text">
            <a
              :href="attachment.url"
              title="Descargar"
              class="material-icons"
              target="_blank">
              file_download
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ToLocalTimeMixin from '../mixins/to-local-time';

export default {
  name: 'DownloadsView',
  mixins: [ToLocalTimeMixin],
  computed: {
    attachments() {
      return this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.user_attachments;
    },
  },
};
</script>

<style lang="scss">
  @import "../../styles/app/variables";

  .downloads {
    max-width: $desktop-max-width;

    h1 {
      font-weight: 100;
      font-size: 28px;

      @media only screen and (max-width: $mobile-breakpoint) {
        text-align: center;
      }
    }

    table {
      width: 100%;
      background-color: $table-background;
      border: none;
      border-collapse: collapse;

      .left-text {
        text-align: left;
      }

      .center-text {
        text-align: center;
      }

      .right-text {
        text-align: right;
      }

      td, th {
        padding: 10px;
        vertical-align: middle;
      }

      thead th {
        background-color: $table-emphasis-background;
      }

      tbody tr:nth-child(even) {
        background-color: $table-emphasis-background;
      }
    }

    a.material-icons {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }

</style>
