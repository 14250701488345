/* eslint-disable camelcase */
import ResultsApi from './../../api/results';
import SchoolsApi from './../../api/schools';

function initialState() {
  return {
    loading: 0,
    schools: [],
    evaluations: [],
    results: null,
    chosenSchool: null,
    chosenEvaluation: null,
    currentReport: null,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ADD_LOADING(state) {
      state.loading++;
    },
    REMOVE_LOADING(state) {
      state.loading--;
    },
    ADD_EVALUATIONS(state, newEvaluations) {
      newEvaluations.forEach(evaluation => {
        if (!state.evaluations.find(item => item.id === evaluation.id)) {
          state.evaluations.push(evaluation);
        }
      });
    },
    RESET_STATE(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    SET_RESULTS(state, results) {
      state.results = results;
    },
    SET_CHOSEN_EVALUATION(state, value) {
      state.chosenEvaluation = value;
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    updateChosenEvaluation({ state, commit }, evaluation) {
      commit('ADD_LOADING');
      commit('SET_CHOSEN_EVALUATION', evaluation);
      // eslint-disable-next-line no-negated-condition
      if (!!evaluation) {
        const params = {
          evaluation_ids: state.chosenEvaluation.id,
          report_key: 'pro',
        };

        return ResultsApi.getResults(params)
          .then(({ body }) => {
            commit('SET_RESULTS', body.results_with_stats);
            commit('REMOVE_LOADING');
          })
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {
            commit('REMOVE_LOADING');
          });
      }
      commit('SET_RESULTS', null);
      commit('REMOVE_LOADING');
    },
    fetchEvaluations(
      { rootState, commit, dispatch, getters }, { evaluationType = null, updateEvaluation = true }
    ) {
      commit('ADD_LOADING');
      return SchoolsApi.getSchoolEvaluations(rootState.options.chosenSchoolId, evaluationType)
        .then(({ body }) => {
          commit('ADD_EVALUATIONS', body.evaluations);
          if (updateEvaluation && getters.evaluations.length) {
            dispatch('updateChosenEvaluation', getters.evaluations[0]); // eslint-disable-line no-magic-numbers
          }
          commit('REMOVE_LOADING');
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          commit('REMOVE_LOADING');
        });
    },
  },
  getters: {
    chosenSchoolId({ chosenSchool }) {
      return chosenSchool && chosenSchool.toString();
    },
    evaluations(state, _, rootState) {
      return state.evaluations.filter(evaluation =>
        evaluation.evaluable_id.toString() === rootState.options.chosenSchoolId.toString() &&
        evaluation.evaluation_type.name === 'Radar PRO'
      );
    },
    indicators(state) {
      return state.results ? state.results.indicators : [];
    },
    groups(state) {
      return state.results ? state.results.groups : [];
    },
    results(state) {
      return state.results ? state.results.results : [];
    },
  },
};
