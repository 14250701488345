import startCase from '../filters/start-case';
import cleanSchoolName from '../filters/clean-school-name';
import gseName from '../filters/gse-name';
import schoolYear from '../utils/years';

export default {
  methods: {
    schoolData(school) {
      const rbdValue = school.rbd ? school.rbd : 'No hay información';
      const regionValue = school.region ? school.region : 'No hay información';
      const communeValue = school.commune ? school.commune : 'No hay información';
      const dependencyValue = school.school_type ? school.school_type : 'No hay información';
      const gseValue = gseName(school.gse);
      const gseYear = school.gse_year;
      const iveYear = school.ive_year;
      let schoolTooltip = [
        `${school.name}`,
        `RBD: ${rbdValue}`,
        `Región: ${regionValue}`,
        `Comuna: ${communeValue}`,
        `Dependencia: ${dependencyValue}`,
        `GSE ${gseYear} Predominante: ${gseValue}`,
      ];
      if (school.ive_basica) {
        schoolTooltip.push(`IVE ${iveYear} Basica: ${this.$options.filters.numberWithTwoDecimals(school.ive_basica)}`);
      }
      if (school.ive_media) {
        schoolTooltip.push(`IVE ${iveYear} Media : ${this.$options.filters.numberWithTwoDecimals(school.ive_media)}`);
      }
      if (school.has_pie) {
        schoolTooltip.push(`Tiene convenio pie para el año  ${schoolYear()}`);
      }
      schoolTooltip = schoolTooltip.join('<br>');
      return {
        id: school.id,
        label: startCase(cleanSchoolName(school.name)),
        key: schoolTooltip,
      };
    },
    studentData(student) {
      const entry = student.first_enrollment_entry &&
        student.first_enrollment_entry.year ? student.first_enrollment_entry.year : 'Falta información';

      const commune = student.commune ? student.commune : 'Falta información';
      const formattedRun = `${this.$options.filters.number(student.run)}-${student.run_dv}`;
      const studentTooltip = [
        `Nombre: ${student.full_name}`,
        `RUN: ${formattedRun}`,
        `Año ingreso estimado: ${entry}`,
        `Comuna: ${startCase(commune)}`,
      ];
      if (student.email) studentTooltip.push(`Email: ${student.email}`);
      if (student.phone) studentTooltip.push(`Teléfono: ${student.phone}`);
      if (student.cellphone) studentTooltip.push(`Celular: ${student.cellphone}`);
      if (student.birthdate) studentTooltip.push(`Fecha de nacimiento: ${student.birthdate}`);
      return studentTooltip.join('<br>');
    },
    historicalRepetitionTooltip(student) {
      const yearGradeList = student.historical_repetition_data.map(({ group_short_name, year }) => // eslint-disable-line camelcase
        `Año: ${year} - Curso: ${group_short_name}` // eslint-disable-line camelcase
      );
      return yearGradeList.join('<br>');
    },
  },
};
