<template>
  <div class="rdr-view">
    <go-back-link
      v-if="!(isAnnualEnrollment || isEnrollmentByLevel)"
      arrow/>
    <div :class="$style.header">
      <rdr-results-header
        :tabs="tabs"
        :show-school-selector="!(isAnnualEnrollment || isEnrollmentByLevel)"
        :month-picker="!(isAnnualEnrollment || isEnrollmentByLevel)"
        :year-picker="!isAnnualEnrollment"
        hide-school-picker>
        <div
          :class="$style.header__subtitle"
          v-html="reportTitle"/>
      </rdr-results-header>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Tabs from '../../components/tabs.vue';
import LinkMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';
import GoBackLink from '../../components/go-back-link.vue';
import ResultsHeader from '../../components/results/results-header.vue';

export default {
  name: 'EnrollmentView',
  components: {
    Tabs,
    GoBackLink,
    'rdr-results-header': ResultsHeader,

  },
  mixins: [LinkMixin, PermissionMixin],
  data() {
    return {
      loading: 0,
      loadingOptions: 0,
      error: false,
      years: [],
      dates: [],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Todos'],
      annualEnrollmentTitle: 'Cantidad de estudiantes por establecimiento según bases oficiales del Mineduc (actualizadas al 30 de abril de cada año). Puedes buscar <strong>cualquier establecimiento de Chile</strong>.',
      enrollmentTrackingTitle: 'Este gráfico muestra la cantidad de estudiantes matriculados por curso en base al SIGE según las <strong>opciones seleccionadas</strong>.',
      enrollmentAverageByLevelTitle: 'Este gráfico muestra la cantidad de estudiantes matriculados en promedio por nivel en base al SIGE.',
      totalEnrollmentByLevelTitle: 'Este gráfico muestra la cantidad de estudiantes matriculados por nivel en base al SIGE.',
      occupancyTrackingTitle: 'Este gráfico muestra el porcentaje de Ocupación por nivel según las <strong>opciones seleccionadas</strong>.',
      vacancyTrackingTitle: 'Este gráfico muestra la cantidad de vacantes disponibles por nivel. Se calcula en base a la capacidad aprobada y los estudiantes matriculados según el SIGE.',
    };
  },
  computed: {
    ...mapGetters('auth', { schools: 'userSchools' }),
    ...mapState('options', ['chosenSchoolId', 'chosenYear']),
    ...mapState('options', { resultsChosenMonth: 'chosenMonth' }),
    chosenSchool: {
      get() {
        return this.schools.find(s => s.id.toString() === this.chosenSchoolId) || this.schools[0];
      },
      async set(value) {
        await this.setChosenSchool(value.id);
      },
    },
    reportTitle() {
      if (this.isAnnualEnrollment) return this.annualEnrollmentTitle;
      else if (this.isEnrollmentTracking) return this.enrollmentTrackingTitle;
      else if (this.isOccupancyTracking) return this.occupancyTrackingTitle;
      else if (this.isCustomEnrollmentByLevel('average')) return this.enrollmentAverageByLevelTitle;
      else if (this.isCustomEnrollmentByLevel('total')) return this.totalEnrollmentByLevelTitle;
      return this.vacancyTrackingTitle;
    },
    months() {
      if (this.chosenYear) {
        return this.dates.filter(d => d.year === this.chosenYear)[0].months.map(d => this.monthNames[d - 1]).concat(['Todos']);
      }
      return [];
    },
    actualRouteName() {
      return this.$route.name;
    },
    isAnnualEnrollment() {
      return this.$route.name === 'annualEnrollment';
    },
    isEnrollmentTracking() {
      return this.$route.name === 'enrollmentTracking';
    },
    isEnrollmentByLevel() {
      return this.$route.name === 'enrollmentByLevel';
    },
    isOccupancyTracking() {
      return this.$route.name === 'occupancyTracking';
    },
    created() {
      return this.fetchResults();
    },
    tabs() {
      if (this.isAnnualEnrollment || this.isEnrollmentByLevel) {
        const tabs = [{ icon: 'class', name: 'Matrícula Anual', link: this.annualEnrollmentLink }];
        if (this.hasEnrollmentTrackingPermission) {
          tabs.push({ icon: 'recent_actors', name: 'Seguimiento Matrícula y Ocupación', link: this.enrollmentByLevelLink });
        }
        return tabs;
      }
      return [];
    },
  },
  methods: {
    ...mapActions('options', ['setChosenSchool']),
    ...mapActions('results', ['fetchResults']),
    isCustomEnrollmentByLevel(type) {
      return this.isEnrollmentByLevel && this.$route.params.enrollmentDisplay === type;
    },
  },
};
</script>

<style lang="scss" module>
.header {
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  padding: 0 30px;
  display: flex;
  flex-direction: column;

  &__subtitle {
    font-size: 18px;
  }

  & > * {
    display: inline-block;
    margin-top: 10px;
  }
}
</style>
