<template>
  <div class="option">
    <div v-if="titleForm !== ''">
      <div
        v-if="titleForm === 'sige'"
        class="titleConten">
        Sistema Información<br>General de Estudiantes<br>(SIGE)
      </div>
      <div
        v-else
        class="titleConten titleMayus">
        {{ titleForm }}
      </div>
    </div>
    <div
      v-for="field in extraFields"
      :key="field.name"
      class="row"
    >
      <input
        v-model="extraFieldModels[field.name]"
        :placeholder="field.placeholder"
        class="rdr-input rdr-input--block"
        type="text"
        required
      >
      <div v-if="field.helperIcon">
        <rdr-tooltip>
          <img :src="field.helperIcon">
          <i
            slot="reference"
            class="material-icons">help_outline</i>
        </rdr-tooltip>

      </div>
    </div>
    <input
      v-if="withUsername"
      v-model="username"
      :placeholder="usernamePlaceholder"
      class="rdr-input rdr-input--block"
      type="text"
      required
    >
    <input
      v-model="password"
      :placeholder="passwordPlaceholder"
      class="rdr-input rdr-input--block"
      type="password"
      required
    >
    <input
      v-model="passwordConfirmation"
      :placeholder="confirmPasswordPlaceholder"
      class="rdr-input rdr-input--block"
      type="password"
      required
    >
    <p
      v-if="passwordConfirmation.length > 2 && password !== passwordConfirmation"
      class="option__error"
    >
      Por favor, revisa que ambas contraseñas coincidan.
    </p>
    <div class="option__button-row">
      <button
        :disabled="submitting || password.length < 2 || passwordConfirmation !== password"
        class="rdr-btn rdr-btn--primary"
        style="width:100%;"
        @click="submit"
      >
        Guardar
      </button><br>
      <button
        class="rdr-btn"
        style="width:100%;"
        @click="closeMethod">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformsSettingsOption',
  props: {
    schoolId: {
      type: Number,
      default: 0,
    },
    withUsername: {
      type: Boolean,
      default: false,
    },
    submitMethod: {
      type: Function,
      default: Promise.resolve,
    },
    closeMethod: {
      type: Function,
      default: () => {},
    },
    usernamePlaceholder: {
      type: String,
      default: 'Ingrese su usuario',
    },
    passwordPlaceholder: {
      type: String,
      default: 'Ingrese su contraseña',
    },
    confirmPasswordPlaceholder: {
      type: String,
      default: 'Reingrese la contraseña',
    },
    extraFields: {
      type: Array,
      default: () => [],
    },
    titleForm: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      username: '',
      password: '',
      passwordConfirmation: '',
      extraFieldModels: {},
      submitting: false,
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      try {
        const args = [this.schoolId, this.password];
        if (this.withUsername) args.splice(1, 0, this.username);
        await this.submitMethod(...args.concat(Object.values(this.extraFieldModels)));
        this.openDialog('success');
        this.show = false;
      } catch (err) {
        switch (err.status) {
        case 400:
          this.openDialog('badRequest');
          break;
        case 401:
          this.openDialog('unauthorized');
          break;
        default:
          this.openDialog('error');
        }
      } finally {
        this.submitting = false;
        this.closeDialog();
        setTimeout(() => {
          location.reload();
        }, 1000); /* eslint-disable no-magic-numbers */
      }
    },
    openDialog(feature) {
      this.$store.dispatch('ui/openDialogWithFeature', {
        dialog: 'passwordFeedback',
        feature,
      });
    },
    closeDialog() {
      const delay = 1500;
      setTimeout(this.$store.dispatch, delay, 'ui/hideDialog', 'passwordFeedback');
      setTimeout(this.closeMethod, delay);
    },
  },
};
</script>

<style lang="scss" scoped>

  .row {
    display: flex;
    flex-direction: row;
  }

  .option {
    text-align: center;
    display: block;
    flex-direction: column;
    margin: auto;
    max-width: 400px;
    width:95%;

    input {
      margin: 5px 0;
      border-radius: 8px !important;
    }

    &__button-row {
      // display: flex;
      justify-content: center;
      width: 100%;
      padding-top: 25px;
    }

    &__error {
      color: red;
    }
  }
  .titleConten {
    font-size: 25pt;
    font-weight: bold;
    color: #177ED3;
    margin-bottom: 25px;
    text-align: left;
  }

  .titleMayus {
    text-transform: uppercase;
  }
</style>
