<template>
  <div>
    <rdr-switcher
      :views="views"
      @switcher-toggle="toggleFetchReady"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import AuthMixin from '../../mixins/auth';
import RdrSwitcher from '../../components/utils/switcher.vue';

import AttendancePercentageView from './percentage/by-date.vue';
import ChronicAbsenteeismView from './chronic-absenteeism/by-date.vue';

export default {
  name: 'ByDateView',
  components: {
    'rdr-switcher': RdrSwitcher,
  },
  mixins: [AuthMixin],
  data() {
    return {
      views: {
        left: {
          label: 'Por día de la semana',
          component: AttendancePercentageView,
          color: 'rgb(5, 189, 60)',
        },
        right: {
          label: 'Por fecha del mes',
          component: ChronicAbsenteeismView,
          color: 'rgb(237, 5, 11)',
        },
      },
    };
  },

  methods: {
    ...mapMutations('options', { setFetchReady: 'SET_FETCH_READY' }),
    toggleFetchReady() {
      this.setFetchReady(false);
      this.$nextTick(() => this.setFetchReady(true));
    },
  },
};
</script>

<style lang="scss" module>
  @import "../../../styles/app/variables";
</style>
