/* eslint-disable camelcase */
import Vue from 'vue';

export default {
  searchSchools(query) {
    return Vue.http.get(`/api/v1/schools.json?q=${query}`);
  },
  findSchools(schoolIds) {
    return Vue.http.get(`/api/v1/schools.json?ids=${schoolIds}`);
  },
  getRelatedSchools(schoolId) {
    return Vue.http.get(`/api/v1/schools.json?like=${schoolId}`);
  },
  getSchoolEvaluations(schoolId, evaluationType = null) {
    const query = evaluationType ? `?evaluation_type=${evaluationType}` : '';
    return Vue.http.get(
      `/api/v1/schools/${schoolId}/evaluations.json${query}`
    );
  },
  getSchoolCourses(schoolId, params) {
    return Vue.http.get(`/api/v1/schools/${schoolId}/courses.json`, {
      params,
    });
  },
  getSchoolAbsenteeismEvaluation(schoolId) {
    return Vue.http.get(`/api/v1/schools/${schoolId}/absenteeism.json`);
  },
  getDashboardSchoolResults(params) {
    return Vue.http.get('/api/v1/schools/summary.json', {
      params,
    });
  },
  getSchoolReport(params) {
    return Vue.http.get(`/api/v1/schools/${params.schoolId}/report`, {
      params: { year: params.year },
    });
  },
  getReportYears(params) {
    return Vue.http.get('/api/v1/schools/report_years', { params });
  },
  changeSigePassword(schoolId, sige_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { sige_password } }
    );
  },
  changePMEPassword(schoolId, pme_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { pme_password } }
    );
  },
  changeNapsisCredentials(schoolId, napsis_username, napsis_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { napsis_username, napsis_password } }
    );
  },
  changeEdufacilCredentials(schoolId, edufacil_username, edufacil_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { edufacil_username, edufacil_password } }
    );
  },
  changeSyscolCredentials(schoolId, syscol_username, syscol_password, syscol_name) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { syscol_username, syscol_password, syscol_name } }
    );
  },
  changeAlexiaCredentials(schoolId, alexia_username, alexia_password, alexia_url) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { alexia_username, alexia_password, alexia_url } }
    );
  },
  changeWebclassCredentials(schoolId, webclass_username, webclass_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { webclass_username, webclass_password } }
    );
  },
  changeAppoderadoCredentials(schoolId, appoderado_username, appoderado_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { appoderado_username, appoderado_password } }
    );
  },
  changePapinotasCredentials(schoolId, papinotas_username, papinotas_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { papinotas_username, papinotas_password } }
    );
  },
  changeKimcheCredentials(schoolId, kimche_username, kimche_password) {
    return Vue.http.patch(
      `/api/v1/schools/${schoolId}`,
      { school: { kimche_username, kimche_password } }
    );
  },
  getSchoolSurveys(params) {
    return Vue.http.get(
      '/api/v1/schools/surveys',
      { params: { year: params.year, school_ids: params.schoolIds } }
    );
  },
  getGroupSurveysBySchool(params) {
    return Vue.http.get(
      `/api/v1/schools/${params.schoolId}/survey`,
      { params: { year: params.year, month: params.month } }
    );
  },
  getUserSchoolConfigurations() {
    return Vue.http.get('/api/v1/schools/user_school_configurations');
  },
  getUserSchoolFinantials() {
    return Vue.http.get('/api/v1/schools/user_school_finantials');
  },
  getLatestSurveyApplications(params) {
    return Vue.http.get(
      `/api/v1/schools/${params.schoolId}/latest_survey_applications`,
      { params: { year: params.year } }
    );
  },
  getSchoolConfiguration(schoolId) {
    return Vue.http.get(`/api/v1/schools/${schoolId}/configuration`);
  },
  requestIntegration(schoolId, integrationKey) {
    return Vue.http.post(
      `/api/v1/schools/${schoolId}/integration`,
      { integration: integrationKey }
    );
  },
  getSchoolSurveyOptions(schoolId) {
    return Vue.http.get(`/api/v1/schools/${schoolId}/survey_options`);
  },
};
