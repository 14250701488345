<template>
  <el-dropdown
    trigger="click">
    <span class="container">
      <a class="rdr-btn rdr-btn--primary">
        Descargas
        <span :class="$style.iconWrapper">
          <i class="material-icons">arrow_drop_down</i>
        </span>
      </a>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(attachment, index) in attachments"
        :key="index"
        :class="$style.item"
      >
        <a
          :href="attachment.schoolAttachment.url"
          :class="$style.dropdownLink"
        >
          {{ attachmentLabel(attachment) }}
        </a>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';

export default {
  name: 'SchoolAttachmentDropdown',
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
  },
  props: {
    attachments: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    attachmentLabel({ label, schoolAttachment }) {
      if (typeof label !== 'function') return label;
      return label(schoolAttachment);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  color: #555E81;
  text-decoration: none;
  &:hover {
    background-color: #eee;
    color: #4266f7;
  }
}

.item {
  padding: 0;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}
</style>
