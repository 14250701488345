<template>
  <div
    :class="[
      $style.loadingOverlay,
      { [$style.middle]: middle },
      'flotante'
  ]">
    <spinner/>
  </div>
</template>

<script>
import Spinner from './spinner.vue';

export default {
  name: 'LoadingOverlay',
  components: { Spinner },
  props: {
    middle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  padding-top: 25%;
  z-index: 9999;
}

.middle {
  align-items: center;
}
.flotante {}
</style>
