import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('options', ['groups']),
    stageGroups() {
      return [
        { key: 'todos', groups: this.filterGroupKeyIn(['TD']), name: 'Colegio' },
        { key: 'pre', groups: this.filterGroupKeyIn(['PK', 'K']), name: 'Ed. Parvularia' },
        { key: 'elementary', groups: this.filterGroupKeyIn(['1', '2', '3', '4']), name: 'Ed. Básica primer ciclo' }, // eslint-disable-line no-magic-numbers
        { key: 'middle', groups: this.filterGroupKeyIn(['5', '6', '7', '8']), name: 'Ed. Básica segundo ciclo' }, // eslint-disable-line no-magic-numbers
        { key: 'high', groups: this.filterGroupKeyIn(['9', '10', '11', '12']), name: 'Ed. Media' }, // eslint-disable-line no-magic-numbers
      ];
    },
  },
  methods: {
    filterGroupKeyIn(array) {
      return this.groups.filter(g => (
        array.includes(g.key)
      ));
    },
  },
};
