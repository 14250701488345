import { MOBILE_BREAKPOINT } from './constants';

// eslint-disable-next-line max-statements
export default function columnLabelShortener(label, maxLineLength, windowWidth) {
  let firstRow = '';
  let secondRow = '';
  let i = 0;
  let j = 0;
  if (label.length > maxLineLength && windowWidth <= MOBILE_BREAKPOINT) {
    const words = label.split(' ');
    while (firstRow.length + words[i].length + 1 <= maxLineLength) {
      firstRow += `${words[i]} `;
      i++;
    }
    while (secondRow.length + words[words.length - j - 1].length <= maxLineLength && words.length - j > i) {
      secondRow = `${words[words.length - j - 1]} ${secondRow}`;
      j++;
    }
    if (words.length - j === i) return `${firstRow}${secondRow}`;
    return `${firstRow}...\n${secondRow}`;
  }
  return label;
}

