<template>
  <el-dialog
    :visible.sync="show"
    append-to-body
    class="radar-dialog">
    <div
      slot="title"
      class="radar-dialog__header">
      <div class="radar-dialog__title">
        Formulario para generar reporte de calificaciones por estudiantes
      </div>
      <div class="radar-dialog__subtitle">
        Al completar este formulario, te llegará un email con los resúmenes de calificaciones por estudiante del año 
        <strong>{{ chosenYear }}</strong> para el colegio <strong>{{ chosenSchool.name | startCase }}</strong>.
        Estas te llegarán en PDF y pueden ser impresas para entregarlas a los apoderados.
      </div>
    </div>
    <div class="radar-dialog__body">
      <div v-if="submitting">
        <transition name="fade">
          <loading-overlay/>
        </transition>
      </div>
      <div v-else style="height:100% !important">

        <div
          v-if="!submitted"
          class="attendance_compromise_form conten-form">
          <form
            class="attendance_compromise_form"
            @submit.prevent="submitTutorReportData()">
            <strong>Nombre de quien firma los resúmenes de calificación</strong>
            <input
              v-model="signerName"
              class="rdr-input rdr-input--block"
              type="text"
              placeholder="Escribir nombre y apellido"
              title="Debes rellenar este campo"
              required >
            <strong>Cargo de quien firma los resúmenes de calificación</strong>
            <input
              v-model="signerRole"
              class="rdr-input rdr-input--block"
              type="text"
              placeholder="Escribir cargo"
              title="Debes rellenar este campo"
              required >
            <strong>Seleccione periodo para generar reporte</strong>
            <select
              v-model="term"
              class="picker-mdl"
              required >
              <option
                v-for="term in termOptions"
                :key="term.number"
                :value="term.number">
                {{ term.label }}
              </option>
            </select>
            <div class="button-wrapper">
              <button
                :disabled="submitting"
                class="rdr-btn rdr-btn--primary"
                type="submit">
                <span v-show="!submitting">Generar resumen de calificaciones por estudiante</span>
                <span v-show="submitting">Generando resumen de calificaciones por estudiante</span>
              </button>
            </div><br>
          </form>
        </div>


        <div
          v-else-if="submissionSuccesful"
          class="attendance_compromise_form--submitted">
          ¡Estamos generando los resúmenes de calificaciones por estudiante!<br><br>Te llegará un email a la brevedad.
        </div>

        <div
          v-else-if="submissionPreconditionFailed"
          class="attendance_compromise_form--no-students">
          <p>Ups! Ha ocurrido un error. Por favor, contáctanos para solucionar tu problema</p>
          <br>
          <button
            class="rdr-btn rdr-btn--primary"
            @click="restartForm">
            <span>OK</span>
          </button>
        </div>

        <div
          v-else-if="submissionNotAcceptable"
          class="attendance_compromise_form--no-students">
          <p>No se pueden generar estos reportes para tu colegio.
          Al parecer falta ingresar algunas calificaciones o los promedios finales.
          Por favor revisa las calificaciones ingresadas.
          Si persiste el problema contáctate con nosotros para que te ayudemos</p>
          <br>
          <button
            class="rdr-btn rdr-btn--primary"
            @click="restartForm">
            <span>OK</span>
          </button>
        </div>
      </div>
      <div
        v-if="isMobile"
        class="mobile-options">
        <button
          :disabled="submitting"
          class="rdr-btn"
          type="submit"
          @click="show = !show">
          <span>Volver</span>
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Dialog } from 'element-ui';
import SchoolAttachmentsApi from '../../api/school-attachments';
import InfoIcon from '../../components/info-icon.vue';
import AuthMixin from '../../mixins/auth';
import DisplayMixin from '../../mixins/display';
import Option from '../settings/platforms/option.vue';
import { TERM_NAMES } from '../../utils/months';

export default {
  name: 'TutorReportForm',
  components: {
    'el-dialog': Dialog,
    InfoIcon,
    Option,
  },
  mixins: [AuthMixin, DisplayMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signerName: '',
      signerRole: '',
      term: null,
      submitting: false,
      submitted: false,
      error: false,
      responseStatusCode: 400,
      attachment: {},
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolId', 'chosenYear']),
    ...mapGetters('options', ['chosenSchool']),
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
    submissionSuccesful() {
      return this.submitted && this.responseStatusCode === 200; // eslint-disable-line no-magic-numbers
    },
    submissionNotAcceptable() {
      return this.submitted && this.responseStatusCode === 406; // eslint-disable-line no-magic-numbers
    },
    submissionPreconditionFailed() {
      return this.submitted && this.responseStatusCode === 412; // eslint-disable-line no-magic-numbers
    },
    showTermPicker() {
      return this.plannedTerms.length !== 0;
    },
    termOptions() {
      return this.termOptionsFromMaxTerm(this.chosenSchool.max_term);
    },
  },
  watch: {
    chosenSchoolId() {
      this.submitted = false;
    },
  },
  methods: {
    async submitTutorReportData() {
      this.submitting = true;
      try {
        await SchoolAttachmentsApi.generateSchoolAttachmentPdf({
          user_id: this.currentUser.id, // eslint-disable-line camelcase
          rbd: this.chosenSchool.rbd,
          attachment_key: 'tutor-report', // eslint-disable-line camelcase
          year: this.chosenYear,
          show_attendance: false, // eslint-disable-line camelcase
          first_attendance_month: null, // eslint-disable-line camelcase
          latest_attendance_month: null, // eslint-disable-line camelcase
          term: this.term,
          signer_name: this.signerName, // eslint-disable-line camelcase
          signer_role: this.signerRole, // eslint-disable-line camelcase
        }).then(successResponse => {
          if (successResponse.status === 200) { // eslint-disable-line no-magic-numbers
            this.responseStatusCode = 200;
          }
        }, errorResponse => {
          switch (errorResponse.status) {
          case 406: // eslint-disable-line no-magic-numbers
            this.responseStatusCode = 406;
            break;
          default: this.responseStatusCode = 412;
          }
        });
      } catch (__) {
        this.error = true;
      } finally {
        this.submitting = false;
        this.submitted = true;
      }
    },
    termOptionsFromMaxTerm(maxTerm) {
      return maxTerm && Array(maxTerm).fill().map((_, idx) =>
        ({ label: TERM_NAMES[maxTerm * 10 + idx + 1], number: idx + 1 }) // eslint-disable-line no-magic-numbers
      );
    },
    restartForm() {
      this.submitting = false;
      this.submitted = false;
      this.succesfull = false;
    },
  },
};

</script>

<style>
  @import "../../../styles/app/variables";

  .radar-dialog {
    &__header {
      white-space: pre-line;
      word-break: break-word;
      // margin-top: 15px;
    }

    &__title {
      text-align: left;
      font-size: 24px;
      font-weight: 600;
      padding-right: 30px;
      margin-top: -25px;
    }

    &__subtitle {
      font-size: 16px;
      text-align: justify;
    }

    &__body {
      //margin-top: -70px;
      height: calc(100% - 140px) !important;
    }

    &__divider {
      color: $light-color;
      opacity: 0.4;
      margin: 25px auto 15px auto;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 !important;
      width: 100vw;
      height: 100vh;
      overflow: hidden;

      &__subtitle {
        margin: 0;
      }

      &__divider {
        margin: 10px auto 10px auto;
      }

      &__body {
        margin-top: -30px;
      }

    }
  }

  .button-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 40px !important;
  }

  .attendance_compromise_form {
    // padding: 10px 40px;
    font-size: 14px;
    word-break: break-word;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }

    &--submitted, &--no-students{
      text-align: center;
      margin: 10px 0 24px;
      font-size: 20px;
      color: $success-color;

      p {
        font-size: 20px;
        word-break: break-word;
      }
    }

    .slider-wrapper{
      display: flex;
      justify-content: space-between;
      word-break: keep-all;
      @media only screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
      }

      &--slider {
        width: 65%;
        margin: 0 30px;
        @media only screen and (max-width: $mobile-breakpoint) {
          width: 70%;
        }
      }
      &--input {
        margin: 3px 30px 0 0;
        width: 25%;
        line-height: 30px;
        font-size: 18px;
        text-align: center;
        @media only screen and (max-width: $mobile-breakpoint) {
          margin: 20px 0 0 10px;
          width: 90%;
        }
      }
    }
    strong {
      padding-top: 15px;
    }
    form {
      > * {
        display: block;
        width: 100%;

        &:not(:first-child) {
          margin-top: 15px;
        }
      }

      input {
        width: 80%;
        height: 45px;
      }

      button {
        margin: 10px 0;
        height: 35px;
        width: auto;
      }
    }
  }

  .mobile-options {
    display: flex;
    justify-content: center;
    width: 85vw;

    button {
      width: 40vw;
    }
  }

  .conten-form {
    overflow:auto;
    padding-top: 10px;
    height: 100%;
  }

  .picker-mdl {
    height:40px;
  }

  .el-dialog {
    white-space: normal !important;
  }
</style>
