<template>
  <el-popover
    :trigger="trigger"
    :title="title"
    :placement="placement"
    :value="value"
    :disabled="disabled"
    :popper-class="popperClass">
    <!-- Use this slot for the element you want to wrap -->
    <slot
      slot="reference"
      name="reference"/>
    <!-- Use this slot for the tooltip's content -->
    <slot/>
  </el-popover>
</template>

<script>
import { Popover } from 'element-ui';

/**
 * Tooltip for inline elements.
 *
 * This is a wrapper for Element UI's popover.
 * (http://element.eleme.io/#/en-US/component/popover)
 *
 * The content provided to the component will be the content
 * of the tooltip. The wrapped content must be given to the
 * reference slot.
 *
 * WARNING: This component wraps whatever you might want to
 * add the tooltip to with a styleless span element.
 */
export default {
  name: 'RdrTooltip',
  components: { 'el-popover': Popover },
  props: {
    /**
     * Trigger mode of the tooltip over the wrapped element.
     * `click, focus, hover, manual`
     */
    trigger: {
      type: String,
      default: 'hover',
    },
    /**
     * Title inside the tooltip and over content provided.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Position of the tooltip around the wrapped element.
     * `top, top-start, top-end, bottom, bottom-start, bottom-end,
     * left, left-start, left-end, right, right-start, right-end`
     */
    placement: {
      type: String,
      default: 'top',
    },
    /**
     * v-model to control visibility of the tooltip.
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the tooltip is completly disabled.
     * It can be used analogously to v-if.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Style class for the tooltip.
     * Classes directly given to the component will be
     * applied over the wrapped element.
     */
    popperClass: {
      type: String,
      default: 'rdr-tooltip',
    },
  },
};
</script>

<style lang="scss">
$arrow-size: 8px;
$color: #6e7898;
$text-color: white;
$title-font-size: 16px;
$text-font-size: 14px;

.rdr-tooltip {
  padding: 10px;
  background-color: $color;
  text-align: center;
  border-radius: 10px;
  color: $text-color;
  font-family: 'GT Haptik';
  box-shadow: none !important;
  font-size: $text-font-size;
  word-break: break-word;

  @media print {
    opacity: 0;
  }

  .el-popover__title {
    color: $text-color;
    font-size: $title-font-size;
  }

  .popper__arrow {
    border-width: $arrow-size;
  }

  .popper__arrow::after {
    border-width: $arrow-size;
  }

  &[x-placement^="top"] {
    margin-bottom: #{$arrow-size + 6};
  }

  &[x-placement^="top"] .popper__arrow {
    bottom: -$arrow-size;
    margin-right: #{$arrow-size / 2};
    border-top-color: $color;

    &::after {
      margin-left: -$arrow-size;
      border-top-color: $color;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: #{$arrow-size + 6};
  }

  &[x-placement^="bottom"] .popper__arrow {
    top: -$arrow-size;
    margin-right: #{$arrow-size / 2};
    border-bottom-color: $color;

    &::after {
      margin-left: -$arrow-size;
      border-bottom-color: $color;
    }
  }

  &[x-placement^="right"] {
    margin-left: #{$arrow-size + 6};
  }

  &[x-placement^="right"] .popper__arrow {
    left: -$arrow-size;
    margin-bottom: #{$arrow-size / 2};
    border-right-color: $color;

    &::after {
      bottom: -$arrow-size;
      border-right-color: $color;
    }
  }

  &[x-placement^="left"] {
    margin-right: #{$arrow-size + 6};
  }

  &[x-placement^="left"] .popper__arrow {
    right: -$arrow-size;
    margin-bottom: #{$arrow-size / 2};
    border-left-color: $color;

    &::after {
      bottom: -$arrow-size;
      margin-left: -$arrow-size;
      border-left-color: $color;
    }
  }
}
</style>
