<template>
  <div :class="$style.box">
    <div :class="$style.info">
      <div :class="$style.labelText">
        <div :class="$style.number">
          {{ value !== null ? customFilter(value, format) : '—' }}
        </div>
        <div :class="$style.text">
          {{ text }}
        </div>
      </div>
      <span
        v-if="infoTooltip"
        :class="$style.infoTooltip">
        <info-icon
          :text="infoTooltip"/>
      </span>
    </div>
    <progress-bar
      :class="$style.progress"
      :line-color="color"
      :progress="progress"
      :width="230"
    />
  </div>
</template>

<script>
import ProgressBar from '../base/progress-bar';
import { PRIMARY_COLOR } from '../../utils/style-variables';
import InfoIcon from '../info-icon.vue';

export default {
  name: 'AggregateBox',
  components: {
    ProgressBar,
    InfoIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
    progress: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: PRIMARY_COLOR,
    },
    format: {
      type: String,
      default: null,
    },
    infoTooltip: {
      type: String,
      default: null,
    },
  },
  methods: {
    customFilter(value, format) {
      if (value === null) return null;
      if (format) return this.$options.filters[format](value);

      return value;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.box {
  display: flex;
  flex-direction: column;
  background-color: $cards-background-color;
  width: 265px;
  min-width: 120px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid $cards-background-border;
  border-radius: 10px;
}

.info{
  display: flex;
  // align-items: center;
}

.number {
  font-size: 26px;
  padding-right: 5px;
  letter-spacing: -1px;
}

.text {
  font-size: 16px;
}

.progress {
  padding-top: 25px;

  @supports (-moz-appearance:none) {
    padding-top: 2px;
  }
}

.label-text {
  // display: flex;
  color: $main-text-color;
  align-items: baseline;
  font-weight: 200;
  width: 85%;
}

.info-tooltip {
  margin-left: 10px;
  padding-top: 5px;
}
</style>
