<template>
  <div class="school">
    <menu-dialog
      :school="school"
      :warnings="warnings"
      :errors="errors"
      :visible.sync="showMenuDialog" />
    <file-uploader-dialog
      v-if="userCanUploadFiles"
      :school="school"
      :update-function="updateFunction"
      :visible.sync="showFileUploaderDialog"
      :management-system="managementSystem"
    />
    <div class="school-name">
      <span class="rbd">
        <div>RBD </div>
        <div>{{ school.rbd }}</div>
      </span>
      <span>{{ school.name | startCase | cleanSchoolName }}</span>
    </div>
    <div class="buttons">
      <button
        v-if="userCanUploadFiles"
        class="rdr-btn rdr-btn--primary school__platforms-button"
        @click="openUploadFilesDialog"
      >
        {{ fileUploaderButtonText }}
      </button>
      <button
        class="rdr-btn rdr-btn--primary school__platforms-button"
        @click="openPlatformsSettingsDialog"
      >
        <span
          v-if="hasNoSigePassword"
          class="warning warning-no-sige-password">
          <info-icon
            :text="hasNoSigePasswordMessage"
            icon="error"/>
        </span>
        <span
          v-if="showSigeLastAttempt"
          class="warning warning-last-attempt-failed">
          <info-icon
            :text="lastSigeAttemptDateMessage"
            icon="warning"/>
        </span>
        <span
          v-if="showSigeConfirmationMessage"
          class="warning warning-last-attempt-failed">
          <info-icon
            :text="confirmingSigePasswordMessage"
            color="grey"
            icon="warning"/>
        </span>
        Editar credenciales
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuDialog from './platforms/menu.vue';
import InfoIcon from '../info-icon.vue';
import FileUploaderDialog from './file-uploader';

const HAS_NO_SIGE_PASSWORD_MESSAGE = 'La clave SIGE no está ingresada';

export default {
  components: {
    'menu-dialog': MenuDialog,
    'info-icon': InfoIcon,
    'file-uploader-dialog': FileUploaderDialog,
  },
  props: {
    school: {
      type: Object,
      required: true,
    },
    updateFunction: {
      type: Function,
      required: false,
      default: null,
    },
    openSchoolFileUploader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenuDialog: false,
      showFileUploaderDialog: this.openSchoolFileUploader,
      lastSigeAttemptDateMessage: `La clave SIGE del colegio podría estar mala (último intento fallido ${new Date(this.school.last_sige_attempt).toLocaleString()}).`,
      showSigeLastAttempt: this.school.has_sige_password && !this.school.sige_attempt_success && this.school.last_sige_attempt,
      showSigeConfirmationMessage: this.school.has_sige_password && !this.school.last_sige_attempt,
      confirmingSigePasswordMessage: 'La clave SIGE del colegio está en confirmación.',
      hasNoSigePassword: !this.school.has_sige_password,
      hasNoSigePasswordMessage: HAS_NO_SIGE_PASSWORD_MESSAGE,
    };
  },
  computed: {
    ...(
      mapGetters('auth', ['hasSchoolTrackUploadFilesPermission', 'hasRadarUploadFilesPermission'])
    ),
    userCanUploadSchoolTrackFilesToSchool() {
      return this.hasSchoolTrackUploadFilesPermission && this.managementSystem === 'school_track_legacy';
    },
    userCanUploadRadarFilesToSchool() {
      return this.hasRadarUploadFilesPermission && this.managementSystem === 'radar';
    },
    userCanUploadFiles() {
      return this.userCanUploadSchoolTrackFilesToSchool || this.userCanUploadRadarFilesToSchool;
    },
    managementSystem() {
      return this.school.management_system;
    },
    fileUploaderButtonText() {
      switch (this.managementSystem) {
      case 'school_track_legacy':
        return 'Subir archivos de consulta SchoolTrack';
      case 'radar':
        return 'Subir archivos a Radar';
      // case null:
      //   return 'Subir archivos de consulta SchoolTrack';
      default:
        return '';
      }
    },
    warnings() {
      return [
        this.showSigeLastAttempt ? this.lastSigeAttemptDateMessage : null,
        this.showSigeConfirmationMessage ? this.confirmingSigePasswordMessage : null,
      ].filter(v => !!v);
    },
    errors() {
      return [
        this.hasNoSigePassword ? this.hasNoSigePasswordMessage : null,
      ].filter(v => !!v);
    },
  },
  methods: {
    openPlatformsSettingsDialog() {
      this.showMenuDialog = true;
    },
    openUploadFilesDialog() {
      this.showFileUploaderDialog = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/app/variables";

.school {
  width: 100%;
  display: flex;

  .buttons {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }

    .warning {
      width: 24px;
      flex-grow: 0;

      &-last-attempt-failed {
        color: $important-color;
      }

      &-no-sige-password {
        color: $color-map-red;
      }
    }
  }

  @media only screen and (min-width: $mobile-breakpoint + 1px) {
    align-items: baseline;
    justify-content: space-between;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }

  &__platforms-button {
    max-height: 35px;
    margin: 0 0 0 15px;

    @media only screen and (max-width: $mobile-breakpoint) {
      width: 50vw;
      margin: 15px 0 0 0;
      max-height: 50px;
    }

    span {
      margin: 0 2px 0 -4px;
    }
  }

  .rbd {
    margin-right: 30px;
    width: 90px;
    display: inline-flex;
    justify-content: space-between;
  }

  .school-name {
    vertical-align: middle;

    @media only screen and (max-width: $mobile-breakpoint) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
</style>
