<template>
  <div id="dth-contenReport" class="report">
    <alert-info :text-alert="title"/>
    <div class="rdr-view">
      <div>
        <div class="results-header">
          <rdr-results-header
            :show-school-selector="isLoggedIn"
            year-picker
            hide-school-picker>
            <!-- <div class="results-header__subtitle">
              <span>
                En este reporte encontrarás el ausentismo por alumno del colegio seleccionado.
              </span>
            </div> -->
          </rdr-results-header>
        </div>
      </div>
      <paywall
        :permitted="hasPermission('attendance')"
        restricted>
        <attendance-compromise-form
          v-if="hasPermission('attendance-compromise')"
          :visible.sync="showDialog" />
        <div class="attendance-view__custom-btn">
          <attendance-compromise-form
            v-if="hasPermission('attendance_compromise')"
            :visible.sync="showDialog" />
          <div
            v-if="attachments && attachments.length > 0"
            class="attendance-view__attachments">
            <a
              v-for="attachment in attachments"
              :key="attachment.id"
              :href="attachment.attachment_url"
              class="rdr-btn rdr-btn--primary"
              target="_blank">
              <span class="icon-wrapper">
                <i class="material-icons">attach_file</i>
              </span>
              <span>{{ attachment.name }}</span>
            </a>
          </div>
          <div
            v-else
            class="attendance-view__attachments">
            <a
              class="rdr-btn rdr-btn--primary attendance-view__aligned"
              disabled="true">
              <span class="icon-wrapper">
                <i class="material-icons">attach_file</i>
              </span>
              <span>
                Ausentismo
              </span>
              <span>
                <info-icon
                  text="Archivo adjunto aún no generado"
                  icon="error"/>
              </span>
            </a>
          </div>
          <div
            v-if="hasPermission('attendance_compromise')"
            class="attendance-view__attachments">
            <button
              class="rdr-btn"
              @click="openAttendanceCompromiseDialog">
              <span class="icon-wrapper">
                <i class="material-icons">picture_as_pdf</i>
              </span>
              <span>
                Carta de asistencia
              </span>
              <span>
                <info-icon
                  text-style="max-width: 50%"
                  text="Apretando el botón puedes generar automáticamente cartas para notificar
                  la asistencia de los estudiantes a cada apoderado. Te llegarán por email en pdf
                  para ser impresas."/>
              </span>
            </button>
          </div>
        </div>
        <attendance-by-student-table-view/>
        <router-view/>
      </paywall>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import ResultsHeader from '../../components/results/results-header.vue';
import Paywall from '../../components/paywall.vue';
import AttendanceByStudentTableView from './by-student-table.vue';
import AttendanceCompromiseForm from '../../components/dialogs/attendance-compromise.vue';

import AuthMixin from '../../mixins/auth';
import ToLocalTimeMixin from '../../mixins/to-local-time';
import PermissionMixin from '../../mixins/permission';
import schoolYear from '../../utils/years';
import InfoIcon from '../../components/info-icon.vue';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'AttendanceByStudentsView',
  components: {
    'rdr-results-header': ResultsHeader,
    'paywall': Paywall,
    AttendanceByStudentTableView,
    AttendanceCompromiseForm,
    InfoIcon,
    AlertInfo,
  },
  mixins: [AuthMixin, ToLocalTimeMixin, PermissionMixin],
  data() {
    return {
      showDialog: false,
      title: 'En este reporte encontrarás el ausentismo por estudiante del colegio seleccionado.',
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolId']),
    ...mapGetters('results', ['absenteeismEvaluation']),
    attachments() {
      return this.absenteeismEvaluation.evaluation_attachments;
    },
  },
  watch: {
    chosenSchoolId: {
      handler(to, from) {
        if (!from || to.toString() !== from.toString()) {
          this.fetchAbsenteeismEvaluation();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setYears([schoolYear() - 2, schoolYear() - 1, schoolYear()].sort()); // eslint-disable-line no-magic-numbers
  },
  methods: {
    ...mapActions('results', ['fetchAbsenteeismEvaluation']),
    ...mapMutations('options', {
      setYears: 'SET_YEARS',
    }),
    openAttendanceCompromiseDialog() {
      this.showDialog = true;
    },
  },
};
</script>

<style lang="scss">
  @import "../../../styles/app/variables";

  .attendance-view {
    margin-top: 25px;

    &__custom-btn {
      display: flex;
      justify-content: center;
      @media only screen and (max-width: #{$mobile-breakpoint}) {
        flex-direction: column;
      }
    }

    &__attachments {
      display: flex;
      flex-direction: column;
      width: 260px;
      justify-content: center;
      margin: 0 40px;

      .rdr-btn {
        text-align: center;
        padding-left: 30px;
        margin-bottom: 10px;
        position: relative;

        .icon-wrapper {
          position: absolute;
          left: 5px;
          top: 0px;
          bottom: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &__aligned {
      align-items: center;
    }

    &__error {
      display: flex;
      flex-direction: column;
      width: 400px;
      justify-content: center;
      margin: auto;
    }
  }

</style>
