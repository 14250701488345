import * as types from './../mutation-types';

export default {
  namespaced: true,
  latestVersion: null,
  state: {
    loading: 0,
    displayMenu: false,
    menuArray: [],
    menuHover: true,
    tituloMod: '',
    stateAlert: false,
    displayMobileMenu: false,
    displayHelpPopover: false,
    displayLandingPopover: false,
    openedSidebarItem: '',
    typeAttendanceKey: '1',
    contact: {
      email: '',
      name: '',
      phone: '',
      relation: '',
    },
    dialogs: {
      contact: {
        open: false,
        submitted: false,
      },
      upcoming: {
        features: {
          comparative: {
            text: '¿Quieres ver el gráfico comparativo?',
            submitted: false,
          },
          historical: {
            text: '¿Quieres ver el gráfico histórico?',
            submitted: false,
          },
          addSchools: {
            text: '¿Quieres agregar más colegios a esta comparación?',
            submitted: false,
            shortText: true,
            showSubtitle: false,
          },
          profundize: {
            text: `
              ¿Quieres realizar análisis más profundos, mirar otra información o tener un diagnóstico en
              detalle usando <a href="http://radarpro.incremental.cl/" target="_blank">Encuestas Radar</a>?
            `,
            submitted: false,
            shortText: true,
            showSubtitle: false,
          },
          betterResults: {
            text: 'Cotiza',
            submitted: false,
            shortText: true,
            showSubtitle: true,
          },
          contact: {
            text: '¡Contáctanos!',
            submitted: false,
            shortText: true,
            showSubtitle: false,
          },
        },
        feature: null,
        shortText: false,
        open: false,
      },
      login: {
        open: false,
      },
      freeOffer: {
        open: false,
      },
      signUp: {
        open: false,
      },
      newSimceNotification: {
        open: false,
      },
      schoolPicker: {
        open: false,
      },
      schoolSelect: {
        open: false,
      },
      versionUpdate: {
        open: false,
      },
      videoDialog: {
        open: false,
      },
      feedback: {
        text: '',
        open: false,
      },
      passwordFeedback: {
        features: {
          success: {
            text: 'Contraseña actualizada.',
          },
          badRequest: {
            text: 'Contraseña inválida. Ingrese otra, por favor.',
          },
          error: {
            text: 'Ocurrió un error. Por favor inténtalo nuevamente.',
          },
          forbidden: {
            text: 'No estás autorizado para cambiar esto.',
          },
          unauthorized: {
            text: 'Error de credenciales. Por favor, inicia sesión.',
          },
          notFound: {
            text: 'Este colegio no existe.',
          },
        },
        feature: null,
        open: false,
      },
    },
  },
  mutations: {
    ADD_LOADING(state) {
      ++state.loading;
    },
    REMOVE_LOADING(state) {
      --state.loading;
    },
    SET_LATEST_VERSION(state, value) {
      state.latestVersion = value;
    },
    setUpcomingDialogFormSubmitted(state, feature) {
      state.dialogs.upcoming.features[feature].submitted = true;
    },
    setContactDialogFormSubmitted(state) {
      state.dialogs.contact.submitted = true;
    },
    updateContactData(state, payload) {
      state.contact[payload.key] = payload.value;
    },
    [types.DISPLAY_LOGIN_DIALOG](state, status) {
      state.dialogs.login.open = status;
    },
    [types.DISPLAY_FREE_OFFER_DIALOG](state, status) {
      state.dialogs.freeOffer.open = status;
    },
    [types.DISPLAY_NEW_SIMCE_NOTIFICATION_DIALOG](state, status) {
      state.dialogs.newSimceNotification.open = status;
    },
    [types.DISPLAY_SIGN_UP_DIALOG](state, status) {
      state.dialogs.signUp.open = status;
    },
    SET_DIALOG_DATA(state, payload) {
      state.dialogs[payload.dialog][payload.key] = payload.value;
    },
    SET_DIALOG_STATE(state, payload) {
      state.dialogs[payload.dialog].open = payload.state;
    },
    showMenu(state) {
      state.displayMenu = true;
      state.menuHover = false;
    },
    hideMenu(state) {
      state.displayMenu = false;
      state.menuHover = true;
    },
    hoverMenu(state, data) {
      state.displayMenu = data;
    },
    menuData(state, data) {
      state.menuArray = data;
    },
    titleModule(state, data) {
      state.tituloMod = data;
    },
    openAlertInfo(state, data) {
      state.stateAlert = data;
    },
    changeOpenedSidebarItem(state, key) {
      state.openedSidebarItem = key;
    },
    changeTypeAttendance(state, key) {
      state.typeAttendanceKey = key;
    },
    showMobileMenu(state) {
      state.displayMobileMenu = true;
    },
    hideMobileMenu(state) {
      state.displayMobileMenu = false;
    },
    showHelpPopover(state) {
      state.displayHelpPopover = true;
    },
    hideHelpPopover(state) {
      state.displayHelpPopover = false;
    },
    setHelpPopoverVisibility(state, value) {
      state.displayHelpPopover = value;
    },
    showLandingPopover(state) {
      state.displayLandingPopover = true;
    },
    hideLandingPopover(state) {
      state.displayLandingPopover = false;
    },
    setLandingPopoverVisibility(state, value) {
      state.displayLandingPopover = value;
    },
    SET_FEEDBACK_DIALOG_STATE(state, value) {
      state.dialogs.feedback.open = value;
    },
    SET_FEEDBACK_DIALOG_TEXT(state, text) {
      state.dialogs.feedback.text = text;
    },
  },
  actions: {
    openDialog({ commit }, dialog) {
      commit({
        type: types.SET_DIALOG_STATE,
        dialog,
        state: true,
      });
    },
    openDialogWithFeature({ commit }, { dialog, feature }) {
      commit({
        type: types.SET_DIALOG_DATA,
        dialog,
        key: 'feature',
        value: feature,
      });
      commit({
        type: types.SET_DIALOG_STATE,
        dialog,
        state: true,
      });
    },
    hideDialog({ commit }, dialog) {
      commit({
        type: types.SET_DIALOG_STATE,
        dialog,
        state: false,
      });
    },
    openUpcomingDialog({ commit }, feature) {
      commit({
        type: 'SET_DIALOG_DATA',
        dialog: 'upcoming',
        key: 'feature',
        value: feature,
      });
      commit({
        type: 'SET_DIALOG_STATE',
        dialog: 'upcoming',
        state: true,
      });
    },
    openLoginDialog({ commit }, status) {
      commit(types.DISPLAY_LOGIN_DIALOG, status);
    },
    openFreeOfferDialog({ commit }, status) {
      commit(types.DISPLAY_FREE_OFFER_DIALOG, status);
    },
    openNewSimceNotificationDialog({ commit }, status) {
      commit(types.DISPLAY_NEW_SIMCE_NOTIFICATION_DIALOG, status);
    },
    openSignUpDialog({ commit }, status) {
      commit(types.DISPLAY_SIGN_UP_DIALOG, status);
    },
    showFeedbackDialog({ commit }, text) {
      commit('SET_FEEDBACK_DIALOG_TEXT', text);
      commit('SET_FEEDBACK_DIALOG_STATE', true);
    },
  },
};
