<template>
  <rdr-picker
    :options="yearOptions"
    :value="chosenYear"
    :on-change="pick"
    :loading="loading"
    :icon-calendar="iconCalendar" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import schoolYear from '../utils/years';

export default {
  name: 'YearPicker',
  props: {
    customYearOptions: {
      type: Array,
      default: null,
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    iconCalendar: {
      type: Boolean,
      default: false,
    },
    resetYears: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapState('options', {
      chosenYear: 'chosenYear',
      resultYears: 'years',
    }),
    years() {
      return this.customYearOptions || this.storeYears;
    },
    storeYears() {
      const years = [...this.resultYears];
      return [...new Set(years)];
    },
    yearOptions() {
      const filtered = this.years.slice().sort((a, b) => b - a).filter(x => !!x);
      if (filtered.length !== this.years.length) console.error('Se encontraron años nil.');
      return filtered;
    },
  },
  watch: {
    yearOptions: {
      handler() {
        let chosenYear = this.chosenYear;
        if (this.resetYears) {
          chosenYear = this.yearOptions[0];
          this.pick(chosenYear);
          this.$emit('yeargeter', chosenYear);
          return;
        }
        if (!this.yearOptions.find(y => y === chosenYear)) {
          chosenYear = this.yearOptions[0] || parseInt(schoolYear(), 10);
          this.pick(chosenYear);
        }
        this.$emit('yeargeter', chosenYear);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('options', ['setChosenYear']),
    pick(year) {
      this.$emit('input', true);
      this.setChosenYear(year);
      this.$ga.event({
        eventCategory: 'YearPicker',
        eventAction: 'Pick',
        eventLabel: 'OptionSelect',
        eventValue: year,
      });
    },
  },
};
</script>
