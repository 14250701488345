<template>
  <rdr-dialog
    :visible.sync="show">
    <div :class="$style.text">
      {{ text }}
    </div>
  </rdr-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'FeedbackDialog',
  computed: {
    ...mapState('ui', {
      open: state => state.dialogs.feedback.open,
      text: state => state.dialogs.feedback.text,
    }),
    show: {
      get() { return this.open; },
      set(value) { this.setState(value); },
    },
  },
  methods: {
    ...mapMutations({
      setState: 'ui/SET_FEEDBACK_DIALOG_STATE',
    }),
  },
};
</script>

<style lang="scss" module>
.text {
  display: block;
  font-size: 24px;
  text-align: center;
}
</style>
