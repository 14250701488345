/*  eslint-disable no-magic-numbers */
function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ] : null;
}

export default function toGreyScale(colorRGB, colorOptions, ...ranges) {
  const rgbParsed = colorRGB.substring(colorRGB.indexOf('(') + 1, colorRGB.indexOf(')'));
  const arrayRGB = rgbParsed.length === 0 ? hexToRgb(colorRGB) : rgbParsed.split(',').slice(0, 3);

  ranges.push(255);
  const coefficients = [0.21, 0.71, 0.072];

  const greyResult = Math.round(parseInt(arrayRGB[0], 10) * coefficients[0]) +
    Math.round(parseInt(arrayRGB[1], 10) * coefficients[1]) +
    Math.round(parseInt(arrayRGB[2], 10) * coefficients[2]);

  const topRange = ranges.sort().find(range => greyResult < range);
  const newGrayResult = colorOptions[ranges.indexOf(topRange)];
  return Array(3).fill(newGrayResult);
}
/*  eslint-enable no-magic-numbers */
