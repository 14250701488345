<template>
    <el-dialog
      :visible.sync="show"
      :class="$style.filters"
      append-to-body
      @closed="closed"
    >
      <div slot="title">
        <h3>
          Configuración de credenciales {{ school.name | startCase }}
        </h3>
      </div>
      <div :class="$style.cols1">
        <div class="password-menu">
          <div>
            <div>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'edufacil' ? 'btn-active': ''
                ]"
                @click="changeOption('edufacil')">
                <img src="/icon-min-colegium.svg">
                EduFácil
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'alexia' ? 'btn-active': ''
                ]"
                @click="changeOption('alexia')">
                Alexia
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'appoderado' ? 'btn-active': ''
                ]"
                @click="changeOption('appoderado')">
                Appoderado
              </button>
              <!-- COMENTADO POR SOLISITUD DE TICKET
              <integration-button
                v-for="integration in availableIntegrations"
                :new-class="true"
                :key="integration.key"
                :integration-key="integration.key"
                :integration-name="integration.name"
                :has-integration="school[integration.schoolKey]"
                :school-id="school.id"
                @click="respondsend"
              />
              -->
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'kimche' ? 'btn-active': ''
                ]"
                @click="changeOption('kimche')">
                Kimche
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'napsis' ? 'btn-active': ''
                ]"
                @click="changeOption('napsis')">
                Napsis
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'papinotas' ? 'btn-active': ''
                ]"
                @click="changeOption('papinotas')">
                Papinotas
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'pme' ? 'btn-active': ''
                ]"
                @click="changeOption('pme')">
                PME
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'sige' ? 'btn-active': ''
                ]"
                @click="changeOption('sige')">
                SIGE
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'syscol' ? 'btn-active': ''
                ]"
                @click="changeOption('syscol')">
                Syscol
              </button>
              <button
                :class="[
                  'btn-verticalmenu',
                  'rdr-btn--menuconfig',
                  btnActive === 'webclass' ? 'btn-active': ''
                ]"
                @click="changeOption('webclass')">
                Webclass
              </button>
            </div>
          </div>
        </div>
      
      </div>
      <div :class="$style.cols2">
        <div :class="$style.contencol2">
          <div
            v-if="isMobile"
            class="alerts">
            <div
              v-for="(errorMessage, index) in errors"
              :key="index"
              class="alert">
              <info-icon
                icon="error"
                class="error"/>
              {{ errorMessage }}
            </div>
            <div
              v-for="(warningMessage, index) in warnings"
              :key="index"
              class="alert">
              <info-icon
                icon="warning"
                class="warning"/>
              {{ warningMessage }}
            </div>
          </div>

          <platform-settings-option
            v-if="currentOption === 'edufacil'"
            :school-id="school.id"
            :submit-method="edufacilSubmit"
            :close-method="() => changeOption(null)"
            username-placeholder="Ingrese RUT de ingreso a Edufacil"
            password-placeholder="Escriba la contraseña de Edufacil"
            confirm-password-placeholder="Reingrese la contraseña de Edufacil"
            with-username
            :title-form="titleForm"/>

          <platform-settings-option
            v-if="currentOption === 'sige'"
            :school-id="school.id"
            :submit-method="sigeSubmit"
            :close-method="() => changeOption(null)"
            password-placeholder="Escriba la contraseña SIGE DIRECTOR"
            confirm-password-placeholder="Reingrese la contraseña SIGE DIRECTOR"
            :title-form="titleForm"/>

          <platform-settings-option
            v-if="currentOption === 'pme'"
            :school-id="school.id"
            :submit-method="pmeSubmit"
            :close-method="() => changeOption(null)"
            password-placeholder="Escriba la contraseña PME"
            confirm-password-placeholder="Reingrese la contraseña PME"
            :title-form="titleForm"/>

          <platform-settings-option
            v-if="currentOption === 'napsis'"
            :school-id="school.id"
            :submit-method="napsisSubmit"
            :close-method="() => changeOption(null)"
            username-placeholder="Escriba el usuario de Napsis"
            password-placeholder="Escriba la contraseña de Napsis"
            confirm-password-placeholder="Reingrese la contraseña de Napsis"
            with-username
            :title-form="titleForm"/>

          <platform-settings-option
            v-if="currentOption === 'syscol'"
            :school-id="school.id"
            :submit-method="syscolSubmit"
            :close-method="() => changeOption(null)"
            :extra-fields="[
              { placeholder: 'Escriba el nombre del colegio en Syscol', name: 'syscolName'}
            ]"
            username-placeholder="Escriba el usuario de Syscol"
            password-placeholder="Escriba la contraseña de Syscol"
            confirm-password-placeholder="Reingrese la contraseña de Syscol"
            with-username
            :title-form="titleForm"/>
          <platform-settings-option
            v-if="currentOption === 'alexia'"
            :school-id="school.id"
            :submit-method="alexiaSubmit"
            :close-method="() => changeOption(null)"
            :extra-fields="[
              { placeholder: 'Escriba la url de Alexia', name: 'alexiaUrl', helperIcon: 'alexia-url-example.png' }
            ]"
            username-placeholder="Escriba el usuario de Alexia"
            password-placeholder="Escriba la contraseña de Alexia"
            confirm-password-placeholder="Reingrese la contraseña de Alexia"
            with-username
            :title-form="titleForm"/>
          <platform-settings-option
            v-if="currentOption === 'webclass'"
            :school-id="school.id"
            :submit-method="webclassSubmit"
            :close-method="() => changeOption(null)"
            username-placeholder="Escriba el usuario de Webclass"
            password-placeholder="Escriba la contraseña de Webclass"
            confirm-password-placeholder="Reingrese la contraseña de Webclass"
            with-username
            :title-form="titleForm"/>
          <platform-settings-option
            v-if="currentOption === 'appoderado'"
            :school-id="school.id"
            :submit-method="appoderadoSubmit"
            :close-method="() => changeOption(null)"
            username-placeholder="RUT funcionario"
            password-placeholder="Escriba la contraseña de Appoderado"
            confirm-password-placeholder="Reingrese la contraseña de Appoderado"
            with-username
            :title-form="titleForm"/>
          <platform-settings-option
            v-if="currentOption === 'papinotas'"
            :school-id="school.id"
            :submit-method="papinotasSubmit"
            :close-method="() => changeOption(null)"
            username-placeholder="Escriba el correo de Papinotas"
            password-placeholder="Escriba la contraseña de Papinotas"
            confirm-password-placeholder="Reingrese la contraseña de Papinotas"
            with-username
            :title-form="titleForm"/>
          <platform-settings-option
            v-if="currentOption === 'kimche'"
            :school-id="school.id"
            :submit-method="kimcheSubmit"
            :close-method="() => changeOption(null)"
            username-placeholder="Escriba el correo de Kimche"
            password-placeholder="Escriba la contraseña de Kimche"
            confirm-password-placeholder="Reingrese la contraseña de Kimche"
            with-username
            :title-form="titleForm"/>

          <div
            v-if="!currentOption"
            :class="$style.contenimg"
          >
            <img src="/fuente-datos.svg">
            <h3>Selecciona la plataforma de gestion que quieres<br>editar en el menú de la izquierda</h3>
          </div>

          <div
            v-if="currentOption === 'printSend'"
            :class="$style.contenimg"
          >
            <h3>{{ messageSendMail }}</h3>
          </div>
        </div>

        </div>
      </div>
    </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import InfoIcon from '../../info-icon.vue';
import DisplayMixin from '../../../mixins/display';
import PlatformsSettingsOption from './option.vue';
import IntegrationButton from './integration-button';
import SchoolsApi from '../../../api/schools';

const AVAILABLE_INTEGRATIONS = [
  { key: 'psicometrix', name: 'Psicometrix', schoolKey: 'has_psicometrix_integration' },
];

function initialData() {
  return {
    currentOption: null,
    napsisUsername: '',
    napsisPassword: '',
    napsisConfirmation: '',
    edufacilUsername: '',
    edufacilPassword: '',
    edufacilConfirmation: '',
    sigePassword: '',
    sigeConfirmation: '',
    syscolUsername: '',
    syscolPassword: '',
    syscolConfirmation: '',
    sigeSubmit: SchoolsApi.changeSigePassword,
    pmeSubmit: SchoolsApi.changePMEPassword,
    napsisSubmit: SchoolsApi.changeNapsisCredentials,
    edufacilSubmit: SchoolsApi.changeEdufacilCredentials,
    syscolSubmit: SchoolsApi.changeSyscolCredentials,
    alexiaSubmit: SchoolsApi.changeAlexiaCredentials,
    webclassSubmit: SchoolsApi.changeWebclassCredentials,
    appoderadoSubmit: SchoolsApi.changeAppoderadoCredentials,
    papinotasSubmit: SchoolsApi.changePapinotasCredentials,
    kimcheSubmit: SchoolsApi.changeKimcheCredentials,
    availableIntegrations: AVAILABLE_INTEGRATIONS,
    titleForm: '',
    btnActive: '',
    messageSendMail: '',
  };
}

export default {
  name: 'PlatformsSettings',
  components: {
    'el-dialog': Dialog,
    'platform-settings-option': PlatformsSettingsOption,
    'info-icon': InfoIcon,
    IntegrationButton,
  },
  mixins: [DisplayMixin],
  props: {
    school: {
      type: Object,
      default: () => ({}),
    },
    warnings: {
      type: Array,
      default: () => ([]),
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return initialData();
  },
  computed: {
    show: {
      get() { return this.visible; },
      set(value) { this.$emit('update:visible', value); },
    },
  },
  methods: {
    closed() {
      // eslint-disable-next-line no-return-assign
      Object.keys(this.$data).forEach(k => this.$data[k] = initialData()[k]);
    },
    changeOption(option) {
      this.currentOption = option;
      this.titleForm = option;
      this.btnActive = option;
    },
    respondsend(info) {
      this.currentOption = 'printSend';
      if(info === 'sending') this.messageSendMail = 'Correo enviado con éxito';
    }
  },
};
</script>

<style lang="scss" module>
@import "../../../../styles/app/variables";

  .password-menu {
    text-align: center;

    @media only screen and (max-width: $mobile-breakpoint) {
      word-break: break-word;

      .alerts {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 5px;
        overflow-y: auto;

        .alert {
          display: flex;
          flex-direction: row;
          align-items: center;

          .warning {
            color: $important-color;
          }
          .error {
            color: $color-map-red;
          }
        }
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 300;
    }

    h2 {
      font-weight: 400;
    }

    button {
      margin: 10px 5px;
    }
  };

  .cols1 {
    width: 29%;
  }

  .cols2 {
    width: 70%;
    padding: 0px 0px 10px 10px;
    display: flow-root;
    height: 100%;
  }

  .contencol2 {
    background: #E7EFFF;
    border-radius: 10px;
    height: 100%;
    margin-top: 9px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .contenimg {
    text-align: center;
  }

  .filters {
    :global(.el-dialog) {
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60%;
      margin-left: 40%;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 100%;

      @media only screen and (max-width: $mobile-breakpoint) {
        width: 95%;
      }
    }

    :global(.el-dialog__header) {
      margin-top: 0px;
      text-align: left;
      padding-top: 3px;
    }

    :global(.el-dialog__body) {
      display: flex !important;
      height: 91%;
      padding-top: 0px;
    }
  }
</style>
