export default {
  data() {
    return {
      results: [],
    };
  },
  computed: {
    evaluableStudents() {
      return this.results.evaluable_students.reduce((students, student) => {
        students[student.id] = student;
        return students;
      }, {});
    },
  },
};
