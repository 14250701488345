<template>
  <v-select
    ref="schoolSelect"
    :value="value"
    :options="filteredOptions"
    :on-search="onSearch"
    :filterable="false"
    :get-option-label="label"
    :placeholder="placeholder"
    :on-change="change"
    class="rdr-select school-select"
    max-height="320px"
    @search:focus="() => hasSearched = false"
  >
    <template
      slot="option"
      slot-scope="school">
      <div class="school-option__commune">
        {{ school.commune }}
      </div>
      <div>
        <span class="school-option__name">
          {{ school.name | startCase }}
        </span>
        <span
          v-if="selectedOptions.indexOf(school.id) > -1"
          class="school-option__selected">
          <info-icon
            text="Ya has seleccionado este establecimiento"
            icon="check_circle"
            color="#4266f7"/>
        </span>
      </div>
      <span class="school-option__rbd">
        RBD {{ school.rbd }}
      </span>
    </template>
    <span
      slot="no-options"
      @mousedown.stop="">
      <span
        v-if="!hasSearched && !loading">
        Escribe para comenzar a buscar.
      </span>
      <span v-if="hasSearched && loading">
        Buscando colegios...
      </span>
      <span v-if="hasSearched && !loading && !error">
        No pudimos encontrar el colegio que buscas o éste ya fue seleccionado.
      </span>
      <span v-if="hasSearched && !loading && error">
        Ocurrió un error. Por favor, revisa tu conexión.
      </span>
    </span>
  </v-select>
</template>

<script>
import { debounce } from 'lodash';
import SchoolsApi from '../api/schools';
import startCase from '../filters/start-case';
import InfoIcon from './info-icon';

export default {
  name: 'SchoolSelect',
  components: {
    InfoIcon,
  },
  props: {
    value: {
      default: null,
      type: Object,
    },
    placeholder: {
      default: 'Busca tu colegio o RBD',
      type: String,
    },
    filterOptions: {
      default: () => [],
      type: Array,
    },
    selectedOptions: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      options: [],
      hasSearched: false,
      loading: false,
      error: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter(o => !this.filterOptions.includes(o.id));
    },
  },
  methods: {
    change(val) {
      this.$emit('input', val);
      this.options = [];
    },
    label(school) {
      return (school && startCase(school.name)) || '';
    },
    clearSelection() {
      this.$refs.schoolSelect.clearSelection();
    },
    onSearch(query) {
      this.clearSelection();
      this.hasSearched = true;
      this.loading = true;
      this.error = false;
      this.search(query);
    },
    search: debounce(function (query) {
      SchoolsApi.searchSchools(query)
        .then(({ body }) => {
          this.options = body.schools;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    }, 300), // eslint-disable-line no-magic-numbers
  },
};
</script>

<style lang="scss" scoped>
  .school-option {
    &__commune {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    &__name {
      margin-right: 10px;
    }

    &__selected {
      float: right;
    }

    &__rbd {
      opacity: .5;
    }
  }
</style>
