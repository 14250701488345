import { mapState, mapGetters, mapMutations } from 'vuex';
import PermissionMixin from './permission';
import schoolYear from '../utils/years';

export default {
  mixins: [PermissionMixin],
  computed: {
    ...mapState('options', ['chosenSchoolId', 'chosenSchoolIds', 'chosenYear', 'chosenMonth']),
    ...mapState('auth', ['userInfo', 'isLoggedIn']),
    ...mapGetters('options', ['chosenGroup']),
    ...mapGetters('auth', ['userSchoolIds']),
    mainLink() {
      if (this.isLoggedIn) {
        return this.landingLink;
      }

      return '/';
    },
    landingLink() {
      const user = this.userInfo;
      if (user && !this.isFreeUser) {
        if (user.position === 'sostenedor' && user.permissions.administrator_report) return this.administratorReportLink;
        if (['equipoDirectivo', 'director', 'profesor'].includes(user.position)) return this.schoolReportPreferredLink;
        if (['sostenedor', 'apoderado'].includes(user.position)) return this.comparativeLink;
        if (user.preferred_school_id && user.position !== 'sostenedor') return this.preferredSchoolLink;
      }

      return this.freeUserLink;
    },
    freeUserLink() {
      return this.comparativeLink;
    },
    schoolParam() {
      return this.chosenSchoolId || this.chosenSchoolIds[0];
    },
    schoolsParam() {
      return this.chosenSchoolIds.join(',');
    },
    firstSchoolNetwork() {
      if (this.userInfo) {
        return this.userInfo.school_network_ids[0];
      }

      return 0;
    },
    preferredSchoolId() {
      if (this.userInfo) {
        return this.userInfo.preferred_school_id;
      }

      return 0;
    },
    groupParam() {
      if (!this.chosenGroup) return 4; // eslint-disable-line no-magic-numbers

      return this.chosenGroup.key;
    },
    yearParam() {
      return this.chosenYear || this.currentSchoolYearParam;
    },
    currentSchoolYearParam() {
      return schoolYear();
    },
    monthParam() {
      if (!this.chosenMonth) return 0;

      return this.chosenMonth;
    },
    comparativeLink() {
      return { name: 'comparativeColorMap', params: { group: this.groupParam, year: this.yearParam, schools: this.schoolsParam } };
    },
    schoolLink() {
      return { name: 'schoolColorMap', params: { group: this.groupParam, year: this.yearParam, school: this.schoolParam } };
    },
    psuDetailsLink() {
      return { name: 'psuDetails' };
    },
    technicalEducationQualificationLink() {
      return { name: 'technicalEducationQualification' };
    },
    higherEducationGraduationLink() {
      return { name: 'higherEducationGraduation' };
    },
    schoolHistoricalLink() {
      return { name: 'schoolHistorical', params: { group: this.groupParam, school: this.schoolParam } };
    },
    preferredSchoolLink() {
      return { name: 'schoolHistorical', params: { group: this.groupParam, year: this.yearParam, school: this.preferredSchoolId } };
    },
    sipDashboardLink() {
      return { name: 'sipDashboardComparative', params: { year: this.yearParam, schools: this.schoolsParam } };
    },
    sipDashboardComparativeLink() {
      return { name: 'sipDashboardComparative', params: { year: this.yearParam, schools: this.schoolsParam } };
    },
    sipDashboardBySchoolLink() {
      return { name: 'sipDashboardBySchool', params: { school: this.schoolParam } };
    },
    sipDashboardBySchoolNetworkLink() {
      return { name: 'sipDashboardBySchoolNetwork', params: { schoolNetwork: this.firstSchoolNetwork } };
    },
    enrollmentLink() {
      return { name: 'annualEnrollment', params: { schools: this.schoolsParam } };
    },
    annualEnrollmentLink() {
      return { name: 'annualEnrollment', params: { schools: this.schoolsParam } };
    },
    enrollmentByLevelLink() {
      return { name: 'enrollmentByLevel', params: { enrollmentDisplay: this.enrollmentDisplay, year: this.yearParams } };
    },
    enrollmentTrackingLink() {
      return { name: 'enrollmentTracking', params: { school: this.schoolParam, year: this.yearParam, month: this.monthParam } };
    },
    occupancyTrackingLink() {
      return { name: 'occupancyTracking', params: { school: this.schoolParam, year: this.yearParam, month: this.monthParam } };
    },
    teacherHoursLink() {
      return { name: 'teacherHours', params: { schools: this.schoolsParam } };
    },
    attendanceBySchoolLink() {
      return { name: 'attendanceGeneralPanelBySchool', params: { year: this.yearParam, school: this.schoolParam } };
    },
    attendanceByStudentLink() {
      return { name: 'attendanceByStudent', params: { year: this.yearParam, school: this.schoolParam, group: this.groupParam } };
    },
    attendanceComparativeLink() {
      return { name: 'comparativeAttendanceGeneralPanel', params: { year: this.yearParam } };
    },
    downloadsLink() {
      return { name: 'downloads' };
    },
    financialLink() {
      return { name: 'financial' };
    },
    externalEvaluationsLink() {
      return { name: 'external-evaluations' };
    },
    gradesGroupAverageBySchoolLink() {
      return { name: 'gradesGroupAverageBySchool', params: { year: this.yearParam, month: 0, school: this.schoolParam } };
    },
    gradesTestCountBySchoolLink() {
      return { name: 'gradesTestCountBySchool', params: { year: this.yearParam, month: this.monthParam, school: this.schoolParam } };
    },
    gradesStudentSubjectTestsLink() {
      return { name: 'gradesStudentSubjectTests', params: { year: this.yearParam, school: this.schoolParam, group: this.groupParam } };
    },
    gradesStudentSubjectAveragesLink() {
      return { name: 'gradesStudentSubjectAverages', params: { year: this.yearParam, school: this.schoolParam, group: this.groupParam } };
    },
    improvementPlanComparativeLink() {
      return { name: 'improvementPlanComparative', params: { year: this.yearParam } };
    },
    improvementPlanPerSchoolLink() {
      return { name: 'improvementPlanPerSchool', params: { year: this.yearParam, school: this.schoolParam } };
    },
    internalEvaluationsComparativeLink() {
      return { name: 'comparativeInternalEvaluationsLevelTests', params: { year: this.yearParam, month: this.monthParam } };
    },
    internalEvaluationsBySchoolLink() {
      return { name: 'internalEvaluationsLevelTestsBySchool', params: { year: this.yearParam, month: this.monthParam, school: this.schoolParam } };
    },
    internalEvaluationsByLevelLink() {
      return { name: 'internalEvaluationsLevelTestByLevel', params: { year: this.yearParam, month: this.monthParam, school: this.schoolParam, group: this.groupParam } };
    },
    internalEvaluationsByStudentLink() {
      return { name: 'internalEvaluationsByStudent', params: { year: this.yearParam, month: this.monthParam, school: this.schoolParam, group: this.groupParam } };
    },
    administratorGoogleClassroomLink() {
      return { name: 'administratorGoogleClassroom' };
    },
    googleClassroomBySchoolLink() {
      return { name: 'googleClassroomBySchool' };
    },
    groupReportLink() {
      return { name: 'groupReport', params: { school: this.schoolParam, year: this.currentSchoolYearParam } };
    },
    schoolReportLink() {
      return { name: 'schoolReport', params: { school: this.schoolParam, year: this.currentSchoolYearParam } };
    },
    schoolReportPreferredLink() {
      return { name: 'schoolReport', params: { school: this.preferredSchoolId, year: this.currentSchoolYearParam } };
    },
    administratorReportLink() {
      return { name: 'administratorReport', params: { year: this.currentSchoolYearParam } };
    },
    studentReportLink() {
      return { name: 'studentReport' };
    },
    pulsoEscolarLink() {
      return { name: 'pulsoEscolar' };
    },
    studentListLink() {
      return { name: 'studentList', params: { school: this.schoolParam } };
    },
    salesianosLeadershipEvaluationLink() {
      return { name: 'salesianosLeadershipEvaluation', params: { year: this.yearParam, schools: this.schoolsParam } };
    },
    title() {
      return this.$route.meta.title;
    },
    infoProGradesLink() {
      return { url: 'https://www.radarescolar.cl/radar-price-formulario/', message: 'Cotiza aquí' };
    },
    contactFormLink() {
      return { url: 'https://www.radarescolar.cl/contacto-radar/', message: 'Contacto' };
    },
    shortcutToUploadLink() {
      return { name: 'settings', query: { ...this.$route.query, id: this.chosenSchoolId } };
    },
    radarYoutubeVideoId() {
      return this.$youtube.getIdFromURL('https://www.youtube.com/watch?v=boMtbw8Uqwg');
    },
  },
  methods: {
    ...mapMutations('ui', ['hideMobileMenu']),
    routeMatches(name) {
      return !!this.$route.matched.find(match => match.name === name);
    },
    routesMatch(routes) {
      return routes.some(r => this.routeMatches(r));
    },
    linkWithToggle(link) {
      this.$router.push(this[link]);
      this.hideMobileMenu();
    },
    openRadarInfoPage() {
      window.open('https://www.radarescolar.cl');
    },
    aptusMasterDashboardLink(schoolRbd) {
      return `https://www.aptuschile.cl/apt_system/AptusDigitalResultados/indexResultadoColegio/${schoolRbd}`;
    },
  },
};
