<template>
  <div :class="$style.header">
    <div
      :class="[$style.menuButton, $style.mobileMenuButton, 'mobile-only']"
      @click="() => isMobileMenuOpen ? hideMobileMenu() : showMobileMenu()">
      <i class="material-icons">
        {{ isMobileMenuOpen ? 'close' : 'menu' }}
      </i>
    </div>
    <div :class="$style.logoWrapper">
      <div
        :class="[
          $style.menuButton,
          { [$style.desktopCloseMenuButton]: isMenuOpen },
          { [$style.desktopOpenMenuButton]: !isMenuOpen },
          'hide-mobile',
        ]"
      >
        <template v-if="isMenuOpen">
          <img
            src="/logo-analytics-colegium-extend-blanco.svg"
            height="50px">
        </template>
        <template v-else>
          <img
            src="/icon-min-colegium.svg"
            height="50px">
        </template>
      </div>
      <div class="title-header">
        <i
          class="material-icons"
          @click="() => isMenuOpen ? hideMenu() : showMenu()"
        >menu</i>
        {{ Object.keys(titleText).length === 0 ? 'Configuración' : titleText.label }}
      </div>
    </div>
    <div :class="$style.account">
      <div
        v-if="isLoggedIn"
        :class="$style.accountLogged">
        <rdr-popover
          trigger="click"
          placement="bottom"
          arrow-size="small">
          <div
            v-if="currentUser"
            slot="reference"
            :class="$style.accountInfo">
            <div :class="$style.accountThumbnail">
              <img
                :src="currentUser.thumbnail"
                alt="Thumbnail">
            </div>
          </div>
          <div class="itemsPerfil">
            <i
              class="clg-usuario"
              style="margin-right:6px"/>
            <span> {{ currentUser.name }}</span>
          </div>
          <div class="itemsPerfil itemPerfilInferior">
            <i
              class="clg-mail"
              style="margin-right:6px"/>
            <span> {{ currentUser.email }}</span>
          </div>
          <div
            class="itemsPerfil itemPerfilInferior salida"
            @click="logout">
            <i
              class="clg-cerrar-sesion1"
              style="margin-right:6px"/>
            <span> Salir</span>
          </div>
        </rdr-popover>
        <div :class="$style.buttonWrapper">
          <router-link
            v-if="isFreeUser"
            :to="{ name: 'proQuote' }"
            :class="[$style.accountButton, 'rdr-btn', 'rdr-btn--small', 'rdr-btn--important']">
            <span>Cotizar</span>
          </router-link>
        </div>
      </div>
      <div
        v-else
        :class="$style.accountLoggedOut">
        <div :class="$style.buttonWrapper">
          <a
            :class="[$style.accountButton, 'rdr-btn', 'rdr-btn--small']"
            @click="loginPopUpBack">
            <span>{{ textLogin }}</span>
          </a>
          <a
            :class="[$style.accountButton, 'rdr-btn', 'rdr-btn--small', 'rdr-btn--primary']"
            @click="signUp">
            <span>Registrarse</span>
          </a>
          <a
            @click="openContact"
            :class="[$style.accountButton, 'rdr-btn', 'rdr-btn--small', 'rdr-btn--important']">
            <span>Cotizar</span>
          </a>
        </div>
      </div>
    </div>
    <loading-overlay v-if="loadingBack"/>

    <!-- ===== MODAL COTIZAR ===== -->
      <el-dialog
        v-if="mdlQuote"
        :visible.sync="mdlQuote"
        :class="[$style.radar-dialog, $style.login-dialog]">
        <div
          slot="title">
          <h2 :class="$style.titleh2">Cotizar servicio</h2>
        </div>
        <div :class="$style.mdlContenBody">
          <h3>
            Escríbenos a <a href="mailto:radar@colegium.com">radar@colegium.com</a>
          </h3>
          <p :class="$style.contenText">
                Pide tu cotización escribiendo a nuestro correo indicando el nombre y RBD
                del(los) colegio(s) para los que quieres cotizar y los servicios en los que estás interesado(a).
          </p>

          <hr :class="$style.dividerLogin">
          <div :class="$style.contenO">ó</div>
          <hr :class="$style.dividerLogin">
          <rdr-contact-notification />
          <contact-form-dialog />

        </div>
      </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { Dialog } from 'element-ui';

import AuthMixin from '../../mixins/auth';
import LinksMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';
import RdrTooltip from '../../components/base/tooltip';
import RdrPopover from '../../components/base/popover';
import HelpButton from './help-button.vue';

import ContactNotification from '../../components/partials/contact-notification.vue';
import ContactFormDialog from '../../components/dialogs/contact-form.vue';



export default {
  name: 'Header',
  components: {
    HelpButton,
    'rdr-tooltip': RdrTooltip,
    'rdr-popover': RdrPopover,
    'rdr-contact-notification': ContactNotification,
    'el-dialog': Dialog,
    ContactFormDialog,
  },
  mixins: [AuthMixin, LinksMixin, PermissionMixin],
  data() {
    return {
      textLogin: 'Iniciar sesión',
      oneClick: true,
      mdlQuote: false,
      loadingBack: false,
    };
  },

  computed: {
    ...mapState({
      isMenuOpen: state => state.ui.displayMenu,
      isMobileMenuOpen: state => state.ui.displayMobileMenu,
      titleText: state => state.ui.tituloMod,
    }),
    title() { return this.$route.meta.title; },
  },

  methods: {
    ...mapMutations({
      showMenu: 'ui/showMenu',
      hideMenu: 'ui/hideMenu',
      showMobileMenu: 'ui/showMobileMenu',
      hideMobileMenu: 'ui/hideMobileMenu',
    }),
    ...mapActions({
      startIntro: 'intro/start',
    }),

    loginPopUpBack() {
      window.location= "./";
      this.loadingBack = true;
    },

    openContact() {
      this.mdlQuote = !this.mdlQuote;
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.header {
  display: flex;
  justify-content: space-between;
  // background-color: $sidebar-background;
  // border-bottom: 1px solid $sidebar-border-color;

  @media only screen and (max-width: $mobile-breakpoint) {
    display: grid;
    justify-items: stretch;
    grid-template-columns: $header-height auto $header-height;
    grid-template-areas: "left center right";
  }
}

.menu-button {
  background: $primary-color;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  // cursor: pointer;
  user-select: none;
  transition: all 0.2s linear;
  transition-delay: 0.052033s;

  i {
    font-size: 32px;
    font-weight: bold;
  }
}

.menu-text {
  color: $primary-color;
  margin-right: 10px;
  font-size: 24px;
}

.mobile-menu-button {
  grid-area: left;
  background-color: $sidebar-border-color;
}

.desktop-open-menu-button {
  width: 59px; // 100%;
  background-color: $primary-color;
}

.desktop-close-menu-button {
  width: $sidebar-width;
  justify-content: center;
  border-right: 1px solid $sidebar-border-color;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: $header-height;
  height: $header-height;
}

.logo-wrapper {
  display: flex;
}

.logo {
  display: flex;

  & > img {
    height: 50px;
    margin: 0px 5px;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    grid-area: center;
    justify-content: flex-end;
  }
}

.special-client-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 56px;
  padding: 15px 0;
  text-decoration: none;

  & > img {
    height: 100%;
  }

  & > span {
    color: $main-text-color;
    position: absolute;
    align-self: baseline;

    &.presente-span {
      margin-left: .63em;
      margin-top: -1em;
      font-size: .6em;
    }

    &.aptus-span {
      width: 4em;
      text-align: center;
      margin-left: 4.3em;
      margin-top: -1.3em;
      font-size: .5em;
    }
  }
}

.account {
  justify-self: right;
  margin: auto 15px;
  display: flex;

  @media only screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.account-logged {
  height: 100%;
  display: flex;
  align-items: center;
}

.account-info {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.account-name {
  font-size: 12px;
  margin-bottom: 4px;
}

.account-email {
  font-size: 10px;
  color: $light-color;
}

.account-thumbnail {
  margin-right: 10px;
  padding-top: 2px;

  img {
    border-radius: 50%;
    width: 38px;
  }
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;

  .account-button {
    min-height: 38px;
  }
}

.account-button {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.config-popover-link {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  text-decoration: none;
}

  .divider-login {
    width: 40%;
    border-top: 1px solid #ccc;
    display: inline-block;
  }
  .conten-o {
    display: inline-block;
    text-align: center;
    width: 30px;
  }
  .titleh2 {
    text-align: left !important;
    margin-bottom: 0px !important;
  }
  .mdl-conten-body {
    height: calc(100% - 5px);
    overflow: auto;
    padding-right: 10px;
    width: 104%;
    text-align: center;
  }
  .conten-text {
    text-align: justify;
    white-space: normal;
  }
</style>
