<template>
  <span
    :id="name"
    :class="$style.center"/>
</template>

<script>
import { select } from 'd3-selection';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import 'd3-transition';
import { PRIMARY_COLOR, LIGHT_GRAY_COLOR } from '../../utils/style-variables';

const TRANSITION_DURATION = 1000;
export default {
  name: 'ProgressBar',
  props: {
    backgroundColor: {
      type: String,
      default: LIGHT_GRAY_COLOR,
    },
    lineColor: {
      type: [String, Array],
      default: PRIMARY_COLOR,
      validator: (value) => {
        if (typeof value === 'string') return true;
        if (Array.isArray(value) && value.every(e => typeof e === 'string')) return true;

        return false;
      },
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: 5,
    },
    progress: {
      type: [Array, Number],
      default: 50,
      validator: (value) => {
        if (typeof value === 'number') return true;
        if (Array.isArray(value) && value.every(e => typeof e === 'number')) return true;

        return false;
      },
    },
    rotation: {
      type: Number,
      default: 0,
    },
    sorted: {
      type: Boolean,
      default: true,
    },
    round: {
      type: Number,
      default: 0,
    },
    textInside: {
      type: Boolean,
      default: false,
    },
    interpolateColors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      svg: null,
      dataJoin: null,
    };
  },
  computed: {
    id() {
      return this._uid; // eslint-disable-line no-underscore-dangle
    },
    name() {
      return `progress-bar-${this.id}`;
    },
    value() {
      if (typeof this.progress === 'number') return [this.progress];
      if (Array.isArray(this.progress)) return this.progress;

      return [];
    },
    processedColors() {
      if (this.lineColor === null) return [PRIMARY_COLOR];
      return [this.lineColor].flat(Infinity);
    },
    barData() {
      const ret = this.value.map(e => ({
        value: e,
        width: this.getWidth(e),
        text: this.percentage(e),
        percentage: this.width === null,
      }));
      if (this.sorted) ret.sort((a, b) => b.value - a.value);
      return ret;
    },
    xScale() {
      return scaleLinear([0, 100], [this.round * 2, this.width]); // eslint-disable-line no-magic-numbers
    },
  },
  watch: {
    barData: 'draw',
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      if (!this.svg) this.initializeSvg();
      this.bindData();
      this.drawBars();
      if (this.textInside) this.drawText();
    },
    initializeSvg() {
      if (this.svg) return;
      this.svg = select(`#${this.name}`)
        .append('svg')
        .attr('height', this.height)
        .attr('width', this.width === null ? '100%' : this.width)
        .attr('transform', `rotate(${this.rotation})`);
      this.svg.append('g')
        .attr('class', 'background-group')
        .append('rect')
        .attr('fill', this.backgroundColor)
        .attr('height', this.height)
        .attr('width', this.width === null ? '100%' : this.width)
        .attr('x', 0)
        .attr('rx', this.round)
        .attr('ry', this.round);
    },
    bindData() {
      if (!this.svg) return;
      this.dataJoin = this.svg.selectAll('g.bar')
        .data(this.barData);
    },
    updateBars(selection) {
      selection.attr('fill', this.getColor)
        .attr('height', this.height)
        .attr('x', 0)
        .attr('width', 0)
        .attr('rx', this.round)
        .attr('ry', this.round);
      selection.transition()
        .duration(TRANSITION_DURATION)
        .attr('width', d => (d.percentage ? `${d.width}%` : d.width));
    },
    drawBars() {
      this.updateBars(
        this.dataJoin.enter()
          .append('g')
          .attr('class', 'bar')
          .append('rect')
      );
      this.updateBars(this.dataJoin.select('g.bar rect'));
      this.dataJoin.exit().remove();
    },
    drawText() {
      /* eslint-disable no-magic-numbers */
      this.dataJoin.selectAll('text').remove();
      const fontSize = this.height - 5;
      const padding = 4;
      const text = this.dataJoin.enter().append('text')
        .attr('class', this.$style.insideText)
        .style('font-size', fontSize)
        .attr('y', fontSize + 1)
        .attr('opacity', 0)
        .text(d => d.text)
        .attr('x', function () {
          return -this.getComputedTextLength();
        });
      text.transition()
        .duration(TRANSITION_DURATION)
        .attr('x', d => (d.percentage ? `${d.width}%` : d.width))
        .attr('opacity', 1)
        .attr('transform', function (d) {
          const textWidth = this.getComputedTextLength();

          if ((d.percentage && d.width < 10) || (!d.percentage && d.width < textWidth + padding)) {
            return `translate(${padding})`;
          }

          return `translate(-${textWidth + padding})`;
        });
      /* eslint-enable no-magic-numbers */
    },
    percentage(value) {
      return this.$options.filters.percentage(value);
    },
    getWidth(value) {
      if (this.width === null) {
        return value;
      }

      return value > 0 ? this.xScale(value) : 0;
    },
    colorScale(i) {
      if (i >= this.processedColors.length) return interpolateRgb('white', this.processedColors[0]);
      return interpolateRgb('white', this.processedColors[i]);
    },
    getColor(_, i) {
      if (this.interpolateColors) return this.colorScale(i)((i + 1) / (this.barData.length));
      return this.processedColors[i];
    },
  },
};
</script>

<style lang="scss" module>
.center {
  display: flex;
  align-items: center;
}
.inside-text {
  fill: white;
}
</style>
