<template>
  <div v-if="newClass">
    <button
      slot="reference"
      :disabled="disabled"
      class="btn-verticalmenu rdr-btn--menuconfig"
      @click="sendMail">
      {{ mainMessage }}
    </button>
  </div>

  <div v-else>
    <rdr-tooltip>
      <button
        slot="reference"
        :disabled="disabled"
        :class="['rdr-btn', 'rdr-btn--primary', $style.integrationButton]"
        @click="sendMail">
        {{ mainMessage }}
      </button>
      <div>
        {{ tooltipMessagge }}
      </div>
    </rdr-tooltip>
  </div>
</template>

<script>
import SchoolsApi from '../../../api/schools';

export default {
  name: 'IntegrationButton',
  props: {
    hasIntegration: {
      type: Boolean,
      default: false,
    },
    integrationName: {
      type: String,
      default: '',
    },
    integrationKey: {
      type: String,
      default: '',
    },
    schoolId: {
      type: Number,
      default: null,
    },
    newClass: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      sent: false,
      errors: false,
    };
  },
  computed: {
    tooltipMessagge() {
      if (this.sent) return 'Solicitud de integración enviada';
      if (this.errors) return 'No podemos efectuar tu solicitud por el momento, intenta más tarde';
      return this.hasIntegration ?
        'Ya cuentas con esta integración' :
        `Si te interesa tener integración con ${this.integrationName} presiona este botón para informarnos.`;
    },
    mainMessage() {
      return `Integración ${this.integrationName}`;
    },
    disabled() {
      return this.hasIntegration || this.errors || this.sent;
    },
  },
  methods: {
    async sendMail() {
      this.sent = true;
      this.$emit('click', 'sending');
      try {
        await SchoolsApi.requestIntegration(this.schoolId, this.integrationKey);
        this.sent = true;
      } catch (_) {
        this.sent = false;
        this.errors = true;
      }
    },
  },
};
</script>

<style lang="scss" module>
button {
  margin: 10px 5px;
}

.integration-button:not([disabled]) {
  background-color: #43b929 !important;
}

.integration-button:not([disabled]):hover {
  background-color: #2a7221 !important;
}
</style>
