<template>
  <paywall
    :class="$style.improvementPlanPerSchool"
    :permitted="hasImprovementPlanPermission"
    restricted>
    <div :class="$style.header">
      <span
        v-if="schools.length === 1">
        {{ chosenSchool.name | startCase }}
      </span>
      <chosen-school-picker
        v-else
        no-choice="Establecimiento"/>
      <div>—</div>
      <div>
        <span>Año</span>
        <rdr-picker
          v-model="chosenYear"
          :options="years"
          :loading="loadingOptions"/>
      </div>
      <div>
        <span>Dimensión</span>
        <rdr-picker
          v-model="chosenDimension"
          :options="dimensions"
          :loading="loadingOptions"
          no-choice="Todas"
          label-property="name"
          empty-choice/>
      </div>
    </div>
    <div :class="$style.content">
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
      <strong v-if="error">Ocurrió un error. Intenta de nuevo.</strong>
      <rdr-table
        v-else-if="rows && !loading"
        :columns="columns"
        :data="rows"
        :max-label-length="25"
      />
    </div>
  </paywall>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import Paywall from '../../components/paywall.vue';
import ChosenSchoolPicker from '../../components/chosen-school-picker.vue';
import PmeApi from '../../api/pme';

export default {
  name: 'ImprovementPlanPerSchoolView',
  components: { Paywall, ChosenSchoolPicker },
  mixins: [PermissionMixin],
  data() {
    return {
      loading: 0,
      loadingOptions: 0,
      error: false,
      years: [],
      chosenDimension: null,
      dimensions: [],
      actions: null,
    };
  },
  computed: {
    ...mapGetters('auth', { schools: 'userSchools' }),
    ...mapGetters('options', ['chosenSchool']),
    ...mapState('options', { resultsChosenYear: 'chosenYear' }),
    chosenYear: {
      get() {
        if (!this.years.includes(this.resultsChosenYear)) {
          this.setChosenYear(this.years[0]);
          return this.years[0];
        }

        return this.resultsChosenYear;
      },
      set(value) {
        this.setChosenYear(value);
      },
    },
    options() {
      const ret = { years: this.chosenYear };
      if (this.chosenDimension) ret.dimension_ids = this.chosenDimension.id; // eslint-disable-line camelcase

      return ret;
    },
    columns() {
      return [
        { key: 'name', label: 'Nombre de acción', textAlign: 'left', sortFunc: (a, b) => a.localeCompare(b), minWidth: 120 },
        { key: 'execution_percentage', label: 'Porcentaje de ejecución', type: 'progress-bar', options: { tooltip: 'executionPercentageString' }, sortFunc: (a, b) => {
          const aArray = Array.isArray(a) ? a : [a, a];
          const bArray = Array.isArray(b) ? b : [b, b];

          if (aArray[0] < bArray[0]) return aArray[0] - bArray[0];

          return aArray[1] - bArray[1];
        } },
        { key: 'budget', label: 'Presupuesto', filters: ['clp'], sortFunc: (a, b) => a - b },
        { key: 'percentage_of_total', label: 'Porcentaje del total', filters: ['percentageWithOneDecimal'], sortFunc: (a, b) => a - b },
        { key: 'days_left', label: 'Días restantes', sortFunc: (a, b) => a - b },
        { key: 'startDate', label: 'Fecha de inicio' },
        { key: 'endDate', label: 'Fecha de término' },
        { key: 'state', label: 'Estado' },
        { key: 'ate', label: 'ATE', type: 'boolean' },
      ];
    },
    rows() {
      return this.actions && this.actions.map(a => ({
        ...a,
        executionPercentage: a.execution_percentage || 0,
        startDate: (new Date(a.start_date)).toLocaleDateString('en-GB'),
        endDate: (new Date(a.end_date)).toLocaleDateString('en-GB'),
        executionPercentageString: this.numberOrArrayToPercentage(a.execution_percentage || 0),
      }));
    },
  },
  watch: {
    chosenSchool: {
      handler() {
        this.actions = [];
        this.fetchOptions();
      },
      immediate: true,
    },
    options(to, from) {
      if (
        to.years && from.years && to.years === from.years &&
        to.dimension_ids && from.dimension_ids && to.dimension_ids === from.dimension_ids
      ) return;
      this.fetchActions();
    },
  },
  methods: {
    ...mapActions('options', ['setChosenYear']),
    async fetchOptions() {
      if (!this.chosenSchool) return;
      try {
        this.loadingOptions++;
        const response = await PmeApi.getOptions(this.chosenSchool.id);
        const { body: { options: { years, dimensions } } } = response;
        this.years = years;
        this.dimensions = dimensions;
        this.error = false;
      } catch (_) {
        this.error = true;
      } finally {
        this.loadingOptions--;
      }
    },
    async fetchActions() {
      if (!this.chosenYear) return;
      try {
        this.loading++;
        const { body: { actions } } = await PmeApi.getActions({
          school_ids: this.chosenSchool.id, // eslint-disable-line camelcase
          ...this.options,
        });
        this.actions = actions;
        this.error = false;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading--;
      }
    },
    numberOrArrayToPercentage(value) {
      if (typeof value === 'number') return this.$options.filters.percentage(value);
      if (Array.isArray(value)) return `${value.join(' - ')}%`;

      return null;
    },
  },
};
</script>

<style lang="scss" module>
.header {
  text-align: center;
  font-size: 28px;
  font-weight: 200;
  padding: 0 30px;
  margin-bottom: 30px;

  & > * {
    display: inline-block;
    margin-top: 10px;
  }
}

.content {
  overflow: auto;
  min-height: 200px;
}
</style>
