<template>
  <div
    ref="filterTable"
    :class="$style.filterTable">
    <i
      class="clg-filter icon-filter-student"
      @click="toggleFilters"/>
    <transition name="fade">
      <loading-overlay v-if="loading"/>
    </transition>
    <div>
      <div v-if="readOnlyMode">
        <transition name="fade">
          <!-- <mobile-modal-decorator
            :visible.sync="showFilters"
            :titles="filterTitles"
            :show-close="false">
            <filters
              :active-filters.sync="activeFilters"
              :filters="filters"
              :show-filters="showFilters"
              :margin="filtersMargin"
              :all-filters="allFilters"
              :loading="loading"
              :custom-class="$style.modalFilters"
              :show-title="showTitle"
            />
            <div
              v-if="isMobile"
              :class="$style.filterButtons">
              <button
                :class="[
                  'rdr-btn',
                  'rdr-btn--small',
                  'rdr-btn--primary',
                  'visibility-button',
                ]"
                @click="showFilters = !showFilters">
                <span>Aplicar filtros</span>
              </button>
              <button
                :class="[
                  'rdr-btn',
                  'rdr-btn--small',
                  'visibility-button',
                ]"
                @click="resetFilters">
                <span>Quitar filtros</span>
              </button>
            </div>
          </mobile-modal-decorator> -->
        </transition>
      </div>
      <div>
        <div :class="{ [$style.filterButtonCompactWrapper]: compactHeaders }">
          <div
            :class="[
              'button-wrapper',
              'hide-print',
              $style.filterButton,
          ]">
            <!-- <button
              v-if="readOnlyMode && Object.keys(filters).length > 0"
              :class="[
                'rdr-btn',
                'rdr-btn--small',
                { 'rdr-btn--primary': !showFilters },
                'visibility-button',
              ]"
              @click="toggleFilters">
              <span v-if="showFilters">Ocultar filtros .....</span>
              <span v-else>Mostrar filtros</span>
            </button> -->
            <button
              v-if="enableEditing"
              :class="[
                'rdr-btn',
                'rdr-btn--small',
                'rdr-btn--primary',
                'visibility-button',
              ]"
              @click="toggleEditing()">
              <span v-if="readOnlyMode">{{ editButtonMessage }}</span>
              <span v-else>Guardar Cambios</span>
            </button>
            <button
              v-if="!readOnlyMode"
              :class="[
                'rdr-btn',
                'rdr-btn--small',
                'visibility-button'
              ]"
              @click="toggleEditing({ keepChanges: false })"
            >
              Descartar Cambios
            </button>
            <slot
              v-if="enableEditing && readOnlyMode"
              name="extra-buttons"
            />
          </div>
        </div>
        <div class="contenScroll">
          <div style="text-algin:center;">
            <table
              ref="table"
              :class="[
                tableClass,
                { [$style.bigCel]: size === 'big' }
            ]">
              <thead>
                <tr
                  v-if="compactHeaders"
                  :class="$style.compactHeaderBackground">
                  <th
                    v-if="showRowIndex"
                    :key="'header-background-' + indexColumnId"/>
                  <th
                    v-for="(headerColumn, hColIndex) in headerColumnWidths"
                    :key="'header-background-' + hColIndex"/>
                </tr>
                <tr
                  v-for="headerRow in headerRowCount"
                  :key="'header' + headerRow">
                  <th
                    v-if="showRowIndex"
                    :key="`${indexColumnId}-header`"
                    :class="[
                      headerClass || $style.header
                    ]"
                    :style="getIndexHeaderStyle(indexColumnId, headerRow)"
                    :ref="`headerCell-${headerRow}-${indexColumnId}`">
                    #
                  </th>
                  <th
                    v-for="(column, colIndex) in filteredColumns(headerRow)"
                    :key="`${column.id}-header`"
                    :class="[
                      headerClass || $style.header,
                      { [$style.headerWithTooltip]: column.tooltip },
                    ]"
                    :style="getHeaderStyleForColumn(column, colIndex, headerRow)"
                    :ref="`headerCell-${headerRow}-${column.id}`">
                    <rdr-tooltip :disabled="!column.tooltip">
                      <slot
                        :column="column"
                        name="tooltip">
                        <div v-html="column.tooltip"/>
                      </slot>
                      <div
                        slot="reference"
                        :class="[
                          column.class,
                          { [$style.headerButtonClickable]: headerRow === 1 },
                          {
                            [$style.headerCompact]: compactHeaders &&
                              headerRow === 1 &&
                              !column.notCompact
                          },
                          { [$style.headerHighlighted]: column.highlighted },
                        ]"
                        :ref="`headerCell-${headerRow}-${column.id}`"
                        :style="{ width: `${headerColumnWidths[colIndex]}px` }">
                        <div
                          :ref="`label-${headerRow}-${column.id}`"
                          :title="columnNeedsTitle(column) ? column.label : ''"
                          :class="{
                            [$style.headerLabelCompact]: compactHeaders &&
                              headerRow === 1 &&
                              !column.notCompact
                          }"
                          @click="() => headerHandleClick(column)">
                          <router-link
                            v-if="column.link"
                            :to="column.link">
                            {{ column.label }}
                          </router-link>
                          <span v-else>
                            {{ column.label }}
                          </span>
                        </div>
                        <div
                          v-if="headerRow === 1 && allowSort"
                          @click="headerRow === 1 && sortColumn(column)">
                          <i
                            :class="[
                              'material-icons',
                              [$style.headerArrow],
                              { [$style.headerArrowActive]: active(column) }
                          ]">
                            {{ active(column) && sortedColumnDir[0] === 'asc' ? "arrow_drop_up" : "arrow_drop_down" }}
                          </i>
                        </div>
                      </div>
                    </rdr-tooltip>
                  </th>
                </tr>
              </thead>
              <transition-group
                :enter-class="$style.fadeEnter"
                :enter-active-class="$style.fadeEnterActive"
                :enter-to-class="$style.fadeEnterTo"
                :leave-class="$style.fadeLeave"
                :leave-active-class="$style.fadeLeaveActive"
                :leave-to-class="$style.fadeLeaveTo"
                :move-class="$style.fadeMove"
                name="fade"
                tag="tbody">
                <tr
                  v-for="(row, rowIndex) in filteredSortedRows"
                  :key="`${row.id}${rowIndex}-row`">
                  <td
                    v-if="showRowIndex"
                  >
                    <div>
                      {{ filteredSortedRows.indexOf(row) + 1 }}
                    </div>
                  </td>
                  <td
                    v-for="(column, colIndex) in filteredColumns(1)"
                    :key="`${row.id}${column.id}-cell`"
                    :class="[
                      cellClass,
                      { [$style.headerButtonClickable]: true },
                    ]"
                    :style="getCellStyleForColumn(colIndex)">
                    <div
                      :style="getCellStyle(row.id, column.id)"
                      :class="[
                        $style.cell,
                        getCellClass(row.id, column.id),
                        { [$style.cellHighlighted]: column.highlighted },
                      ]"
                      @click="executeCellFunction(row.id, column.id)">
                      <router-link
                        v-if="getCellLink(row.id, column.id)"
                        :to="getCellLink(row.id, column.id)">
                        {{ getFormattedValue(row.id, column.id) }}
                      </router-link>
                      <span v-else-if="readOnlyMode || !column.editable">
                        {{ getFormattedValue(row.id, column.id) }}
                      </span>
                      <input-parser
                        v-else
                        v-model="getCell(row.id, column.id, editable = true).newValue"
                        :input-class="$style.editingInput"
                        :initial-value="getCell(row.id, column.id, editable = true).newValue"
                        :custom-display-parser="customDisplayParser"
                        :custom-parser="customGradeParser"
                        :tabindex="getTabIndex(rowIndex, colIndex)" />
                    </div>
                  </td>
                </tr>
              </transition-group>
            </table>
          </div>
        </div>

        <slot name="moreInfoButtons"/>
      </div>
    </div>

    <!-- MODAL -->
    <div :class="$style.filters">
      <el-dialog
        :class="$style.filtersDialog"
        :visible.sync="showFilters"
        append-to-body>
        <div
          slot="title"
          class="titleFilter">
          <i class="clg-filter"/> Filtros
        </div>
        <filters
          :active-filters.sync="activeFilters"
          :filters="filters"
          :show-filters="showFilters"
          :all-filters="allFilters"
          :loading="loading"
          :custom-class="$style.modalFilters"
          :background="false"
          orientation="horizontal"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Filters from './filters.vue';
import InputParser from './base/input-parser';
import MobileModalDecorator from './utils/mobile-modal-decorator';
import interpolate from '../utils/color-interpolate';
import DisplayMixin from '../mixins/display';
import { Dialog } from 'element-ui';


export default {
  components: {
    Filters,
    InputParser,
    MobileModalDecorator,
    'el-dialog': Dialog,
  },
  mixins: [DisplayMixin],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    headerRowCount: {
      type: Number,
      default: 1,
    },
    columnKey: {
      type: String,
      default: 'id',
    },
    rows: {
      type: Array,
      default: () => [],
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    values: {
      type: Array,
      default: () => [],
    },
    allowSort: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    colorMap: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Number,
      default: 1,
    },
    colorFromResult: {
      type: Function,
      default: result => {
        const colormap = interpolate(['#ED050B', '#FFFDCC', '#05BD3C']);
        if (result) {
          const normalizedValue = Math.min(Math.max(-1.5, result.norm_value), 1.5); // eslint-disable-line no-magic-numbers
          return colormap((normalizedValue + 1.5) / 3); // eslint-disable-line no-magic-numbers
        }
        return 'inherit';
      },
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    compactHeaders: {
      type: Boolean,
      default: false,
    },
    howToColor: {
      type: String,
      default: 'border',
    },
    stickyColumns: {
      type: Number,
      default: 0,
    },
    cellClass: {
      type: String,
      default: null,
    },
    headerClass: {
      type: String,
      default: null,
    },
    tableClass: {
      type: String,
      default: 'tableDefault',
    },
    buttonClass: {
      type: String,
      default: null,
    },
    headerHandleClick: {
      type: Function,
      default: () => null,
    },
    showEmptyData: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    showFiltersOnMount: {
      type: Boolean,
      default: false,
    },
    initialSortType: {
      type: Array,
      default: () => ['none', 'desc', 'asc'],
    },
    columnToSort: {
      type: Object,
      default: () => ({ id: null }),
    },
    showRowIndex: {
      type: Boolean,
      default: false,
    },
    enableEditing: {
      type: Boolean,
      default: false,
    },
    editButtonMessage: {
      type: String,
      default: 'Editar',
    },
    editing: {
      type: Boolean,
      default: false,
    },
    verticalTab: {
      type: Boolean,
      default: false,
    },
    defaultSortColumn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeFilters: {},
      stickTooltip: false,
      headerRowTops: [],
      headerColumnWidths: [],
      columnLefts: [],
      sortedColumn: this.columnToSort,
      sortedColumnDir: this.initialSortType,
      tableWidth: 0,
      isMounted: false,
      showFilters: false, // this.showFiltersOnMount,
      filterTitles: [
        { text: 'Selecciona los filtros que quieres aplicar' },
      ],
      indexColumnId: -10,
      editableValues: [],
    };
  },
  computed: {
    readOnlyMode() {
      return !this.editing;
    },
    filteredColumnsTableLength() {
      return this.filteredColumns(1).length;
    },
    allFilters() {
      return [].concat(...Object.values(this.filters));
    },
    filtersMargin() {
      if (this.isMobile) return '20px';
      return this.compactHeaders ? '100px' : '46px';
    },
    filteredSortedRows() {
      const filtered = this.filteredRows(this.rowsWithData);
      if (this.$data.sortedColumnDir[0] !== 'none') {
        return this.sortedRows(filtered);
      }

      return filtered;
    },
    rowsWithData() {
      return this.rows.filter(({ id: rowId }) => (
        this.filteredColumns(1).some(({ id: colId }) => !!this.getCell(rowId, colId)))
      );
    },
    filtersActive() {
      return Object.keys(this.activeFilters).find((key) => this.activeFilters[key]);
    },
    headerRows() {
      const headerRows = [];
      for (let i = 1; i <= this.headerRowCount; i++) {
        headerRows[i] = this.filteredColumns(i);
      }

      return headerRows;
    },
    indexOfEmptyColumns() {
      const headerColumns = this.columns.filter(column => column.headerRow === 1);
      const emptyHeaderColumns = this.columns.filter(column =>
        column.headerRow === 1 && column.id >= 0 && !this.values.find(data =>
          data[this.columnKey] === column.id
        ));

      return emptyHeaderColumns.map(column => headerColumns.indexOf(column));
    },
  },
  watch: {
    tableWidth(to, from) {
      if (to !== from) {
        this.updateHeaderColumnWidths();
      }
    },
    values(to) {
      const editedValueMapping = Object.fromEntries(
        this.editableValues.map(v => [[v.studentId, v.testId].toLocaleString(), v.newValue])
      );
      this.editableValues = to.map(v => ({ ...v, newValue: this.getNewValue(v, editedValueMapping) }));
    },
  },
  mounted() {
    this.isMounted = true;
    this.updateHeaderRowTops();
    this.updateHeaderColumnWidths();
    this.updateColumnLefts();
    if (this.defaultSortColumn) this.sortColumn(this.defaultSortColumn);
  },
  updated() {
    this.tableWidth = this.$refs.table.getBoundingClientRect().width;
  },
  methods: {
    getNewValue(value, editedValueMapping) {
      const oldNewValue = editedValueMapping[[value.studentId, value.testId].toLocaleString()];
      if (oldNewValue) return oldNewValue;
      return value.calculatedValue ? null : value.value;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    resetFilters() {
      this.activeFilters = {};
      Object.values(this.filters).forEach((filtersGroup) => {
        filtersGroup.forEach((filter) => {
          if (filter.hasOwnProperty('range')) {
            const { min, max } = filter;
            filter.range = [min, max];
          }
        });
      });
      this.showFilters = false;
    },
    sortedRows(rows) {
      return rows.sort((a, b) => {
        const A = this.getCell(a.id, this.$data.sortedColumn.id);
        const B = this.getCell(b.id, this.$data.sortedColumn.id);
        if (!A && B) return 1;
        else if (A && !B) return -1;
        else if (!A && !B) return 0;

        const valA = A.value || A.alternativeText;
        const valB = B.value || B.alternativeText;

        if (!valA && valB) return 1;
        else if (valA && !valB) return -1;
        else if (!valA && !valB) return 0;

        if (typeof valA === 'number' && typeof valB === 'number') {
          return (valA - valB) * this.getSortMultiplier();
        }

        return (valA.toString().localeCompare(valB.toString())) * this.getSortMultiplier();
      });
    },
    filteredRows(rows) {
      return rows.filter(row => (
        this.allFilters.every(filter => (
          !this.$data.activeFilters[filter.key] || filter.filterFunc(this.values)(row)
        ))
      ));
    },
    active(column) {
      return this.$data.sortedColumn.id === column.id && this.$data.sortedColumnDir[0] !== 'none';
    },
    sortColumn(column) {
      if (!this.allowSort) return;
      if (column.id === this.$data.sortedColumn.id) {
        this.$data.sortedColumnDir = this.rotate(this.$data.sortedColumnDir, 1);
      } else {
        this.$data.sortedColumn.id = column.id;
        this.$data.sortedColumnDir = ['asc', 'desc', 'none'];
      }
      this.$ga.event({
        eventCategory: 'FilterTable',
        eventAction: 'ApplySort',
        eventLabel: 'OptionSelect',
        eventValue: `${column.label} ${this.$data.sortedColumnDir[0]}`,
      });
    },
    filteredColumns(headerRow) {
      let headerRowColumns = this.columns.filter((column) => !column.headerRow || column.headerRow === headerRow);
      if (!this.showEmptyData) {
        const emptyColumns = this.indexOfEmptyColumns.map(index => headerRowColumns[index].id);
        headerRowColumns = headerRowColumns.filter(column =>
          !emptyColumns.includes(column.id)
        );
      }

      return headerRowColumns.map((column) => ({ ...column, label: this.formatValue(column, 'label') }));
    },
    getSortMultiplier() {
      const options = { 'asc': 1, 'desc': -1 };

      return options[this.$data.sortedColumnDir[0]];
    },
    getFormattedValue(rowId, columnId) {
      return this.formatValue(this.getCell(rowId, columnId));
    },
    getCellLink(rowId, columnId) {
      const cell = this.getCell(rowId, columnId);
      if (!cell) return false;
      if (cell[this.columnKey] === -1) {
        const row = this.filteredSortedRows.find(r => r.value === cell.value);
        return row && row.link;
      }

      return false;
    },
    executeCellFunction(rowId, columnId) {
      const cell = this.getCell(rowId, columnId);
      if (cell && cell[this.columnKey] === -1) {
        const row = this.filteredSortedRows.find(r => r.value === cell.value);
        if (row && row.handlePress) row.handlePress();
      }
    },
    getCellClass(rowId, columnId) {
      const cell = this.getCell(rowId, columnId);
      if (cell) return cell.class;

      return null;
    },
    getCellStyle(rowId, columnId) {
      const cell = this.getCell(rowId, columnId);
      if (!cell) return {};

      let style = {};
      if (this.colorMap) {
        style = this.getCellColorStyle(cell);
      }
      if (cell.format) {
        style = { ...style, ...cell.format.style };
      }

      return style;
    },
    getCellColorStyle(cell) {
      const style = {};
      let propertiesToColor = [];
      if (cell.propertiesToColor) {
        propertiesToColor = cell.propertiesToColor;
      } else if (cell.norm_value !== undefined) {
        switch (this.howToColor) {
        case 'border':
          propertiesToColor = ['border-color'];
          break;
        case 'background':
          propertiesToColor = ['background-color'];
          style.color = 'white';
          break;
        case 'borderAndBackground':
          propertiesToColor = ['border-color', 'background-color'];
          style.color = 'white';
          break;
        default:
          break;
        }
      }
      propertiesToColor.forEach(property => (
        style[property] = this.colorFromResult(cell)
      ));

      return style;
    },
    getCell(rowId, columnId, editable = false) {
      const values = editable ? this.editableValues : this.values;
      let cell = values.find(v => v[this.rowKey] === rowId && v[this.columnKey] === columnId);
      if (cell === undefined && editable) {
        cell = { [this.rowKey]: rowId, [this.columnKey]: columnId };
        values.push(cell);
      }
      return cell;
    },
    formatValue(element, key = 'value', alternativeKey = 'alternativeText') {
      if (!element) return null;
      let value = element[key];
      if (!value && element[alternativeKey]) value = element[alternativeKey];
      if (!value) return '–';
      const format = element.format;
      if (format) {
        if (format.precision) {
          value = value.toFixed(format.precision);
        }
        if (format.string) {
          value = this.$options.filters[format.string](value);
        }
      }

      return value;
    },
    rotate(arr, times) {
      const count = times - arr.length * Math.floor(times / arr.length);
      const ret = [...(arr.slice(count)), ...arr.splice(0, count)];

      return ret;
    },
    updateHeaderRowTops() {
      let lastHeaderRowEnd = this.compactHeaders ? 100 : 0; // eslint-disable-line no-magic-numbers
      for (let i = 1; i <= this.headerRowCount; i++) {
        const elem = this.$refs[`headerCell-${i}-${this.filteredColumns(i)[0].id}`][0];
        const rect = elem.getBoundingClientRect();
        this.headerRowTops[i] = lastHeaderRowEnd;
        lastHeaderRowEnd += rect.height;
      }
    },
    updateHeaderColumnWidths() {
      this.headerColumnWidths = this.headerRows.map((row, i) => row.map(column => (
        this.$refs[`headerCell-${i}-${column.id}`][0].getBoundingClientRect().width
      ))).reduce((maxRowWidths, rowWidths) => rowWidths.map((rowWidth, i) => (
        rowWidth > maxRowWidths[i] ? rowWidth : maxRowWidths[i]
      )));
    },
    updateColumnLefts() {
      let lastLeft = 0;
      for (let i = 0; i < this.stickyColumns; i++) {
        const elem = this.$refs[`headerCell-${1}-${this.filteredColumns(1)[i].id}`][0];
        const rect = elem.getBoundingClientRect();
        this.columnLefts.push(lastLeft);
        lastLeft += rect.width;
      }
    },
    getIndexHeaderStyle(colIndex, headerRow) {
      const cellStyle = this.getCellStyleForColumn(colIndex);

      return {
        ...{},
        ...cellStyle,
        top: `${this.headerRowTops[headerRow]}px`,
        zIndex: cellStyle.zIndex + 100, // eslint-disable-line no-magic-numbers
      };
    },
    getHeaderStyleForColumn(column, colIndex, headerRow) {
      const cellStyle = this.getCellStyleForColumn(colIndex);

      return {
        ...{},
        ...cellStyle,
        ...(column.format && column.format.style),
        top: `${this.headerRowTops[headerRow]}px`,
        zIndex: cellStyle.zIndex + 100, // eslint-disable-line no-magic-numbers
      };
    },
    getCellStyleForColumn(colIndex) {
      return {
        left: `${this.columnLefts[colIndex]}px`,
        zIndex: this.filteredColumns(1).length - colIndex,
      };
    },
    columnNeedsTitle(column) {
      if (column.headerRow === 1 && column.id !== -1) {
        const col = this.$refs[`label-${1}-${column.id}`];
        if (col && col.length > 0) {
          const offsetWidth = col[0].offsetWidth;
          const scrollWidth = col[0].scrollWidth;

          return (offsetWidth < scrollWidth && !column.tooltip);
        }
      }

      return false;
    },
    getTabIndex(rowIndex, colIndex) {
      if (this.verticalTab) {
        return colIndex * this.filteredSortedRows.length + rowIndex;
      }
      return this.filteredColumnsTableLength * rowIndex + colIndex;
    },
    toggleEditing({ keepChanges = true } = {}) {
      if (this.editing && keepChanges) {
        this.$emit('update:values', this.editableValues);
      } else {
        this.editableValues = this.values.map(v => ({ ...v, newValue: v.calculatedValue ? null : v.value }));
        this.$emit('update:editing', !this.editing);
      }
    },
    customDisplayParser(value) {
      if (value === null || value === '') return null;
      return this.$options.filters.numberWithOneDecimal(parseFloat(value));
    },
    customGradeParser(value) {
      if (value === null || value === '') return null;
      let finalValue = value.replace(/[^\d]/g, '');
      if (finalValue.length > 2) finalValue = finalValue.substring(0, 2); // eslint-disable-line no-magic-numbers
      finalValue = parseFloat(finalValue);
      if (Number.isNaN(finalValue)) return null;
      if (finalValue >= 10) finalValue /= 10; // eslint-disable-line no-magic-numbers
      if (finalValue > 7 || finalValue < 1) return null; // eslint-disable-line no-magic-numbers
      return this.$options.filters.numberWithOneDecimal(finalValue);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../styles/app/variables";
@import "../../styles/app/forms";

$transition-time: 0.3s;

.filter-table {
  width: 100%;
  font-size: 13px;
  position: relative;
  margin-top: 15px;

  table {
    /* We can't set borders rigth here, cause the header backgound is considered in the table's borders. */
    border-spacing: 0;
    border-collapse: separate;

    tbody {

      td {
        background-color: $table-background;
        position: sticky;
        z-index: 0;
      }

    /*All of the above rules are for making table's borders in tbody*/
      tr {
        td:first-of-type {
          border-left: 1px solid $sidebar-border-color;
        }
        td:last-of-type {
          border-right: 1px solid $sidebar-border-color;
        }
      }

      tr:last-of-type {
        td {
          border-bottom: 1px solid $sidebar-border-color;
        }
      }
    }
  }
}

.open {
    background-color: darken($sidebar-background, 3%);
    color: $blue-gray-text-color !important;
    border: none;
  }

.filter-button {
  padding-bottom: 15px;

  &-compact {
    padding-bottom: 0;
    position: absolute;
    top: 50px;
    z-index: 200;

    &-wrapper {
      position: sticky;
      top: 0;
      z-index: 150;
    }
  }
}

.header-button {
  &-clickable {
    cursor: pointer;

    &:hover {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
}

.header {
  border: 1px solid $sidebar-border-color;
  background-color: $sidebar-background;
  padding: 2px;
  text-overflow: ellipsis;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  &-with-tooltip {
    text-decoration: underline;
    text-decoration-style: dotted;
    color: $primary-color;
  }

  &-compact {
    max-width: 27px;
  }

  &-highlighted {
    font-weight: bold;
    color: #000;
    background-color: $cell-highlighted-background;
  }
}

.header-label {
  &-compact {
    width: 150px;
    transform:
      translate(14px, -60px)
      rotate(-33deg);
    white-space: nowrap;
    border-bottom: 2px solid $sidebar-border-color;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cell {
  margin: 0;
  white-space: nowrap;

  &-highlighted {
    font-weight: bold;
    color: #000;
    background-color: $cell-highlighted-background;
  }

  a {
    text-decoration: none;

    &:visited {
      color: blue;
    }
  }
}

.header-arrow {
  font-size: 22px;
  color: $main-text-color;

  &-active {
    color: $primary-color;
  }
}

.compact-header-background {
  th {
    height: 100px;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }
}

.fade-enter-active {
  transition: all $transition-time ease;
}

.fade-leave-active {
  transition: all $transition-time ease;
  display: none;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
  display: none;
}

.fade-leave {
  opacity: 1;
}

.fade-move {
  transition: transform $transition-time;
}

.top-stuck-tooltip {
  top: 0px !important;
}

.sided-elements{
  display: flex;
  align-items: start;

  @media only print {
     flex-direction: column;
  }
}

.margin-filters-no-compact-headers {
  margin-top: 46px;

  @media only print {
      margin-top: 0;
      margin-bottom: 10px;
    }
}

.margin-filters-compact-headers{
  margin-top: 100px;
}

.modal-filters {
   @media only screen and (max-width: $mobile-breakpoint) {
    width: 90vw;
    align-items: center;
    margin-top: 20px;
  }
}

.filterButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1px 0;
}

.editing-input {
  max-width: 27px;
  text-align: center;
}

.filters {
  width: 100%;
  display: flex;
  align-items: start;
  font-size: 13px;

  &-dialog {
    :global(.el-dialog) {
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 60%;
      width: 40%;
      margin-left: 60%;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 100%;

      @media only screen and (max-width: $mobile-breakpoint) {
        width: 95%;
      }
    }

    :global(.el-dialog__header) {
      margin-top: 10px;
      text-align: left;
    }
  }

  @media only print {
     flex-direction: column;
  }
}
</style>
