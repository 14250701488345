<template>
  <div class="group-report">
    <paywall
      :permitted="hasGradesPermission"
      :header="proGradesHeader"
      :text="proGradesMessage"
      :link="infoProGradesLink"
    >
      <div
        v-if="loading"
        :class="$style.groupReport">
        <transition name="fade">
          <loading-overlay v-if="loading"/>
        </transition>
      </div>
      <div
        v-else
        :class="$style.groupReportGrades">
        <div v-if="info.length > 0">
          <filter-table
            :rows="subjectsAsRows"
            :columns="mixedColumns"
            :header-row-count="2"
            :values="values"
            :color-map="true"
            :loading="loading"
            :color-from-result="colorFromResult"
            :column-to-sort="{ id: localIds.average }"
            :initial-sort-type="sortOrder"
            row-key="indicatorId"
            column-key="infoId"
          >
            <div
              slot="moreInfoButtons"
              :class="$style.buttonContainer">
              <button
                :class="[$style.rdrBtn, $style.rdrBtnPrimary, $style.rdrBtnSmall]"
                @click="link(gradesStudentSubjectAveragesLink, 'grades')">
                Ver más detalle
              </button>
            </div>
          </filter-table>
        </div>
        <div
          v-else
          :class="$style.groupReportEmpty">
          <img src="/noResultados.svg">
          <!-- <p>
            No hay resultados para las opciones seleccionadas.
          </p> -->
        </div>
      </div>
    </paywall>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import GroupStudentsApi from '../../api/groups';
import EvaluableStudentsMixin from '../../mixins/evaluable-students';
import LinksMixin from '../../mixins/links';
import PermissionMixin from '../../mixins/permission';
import FilterTable from '../../components/filter-table.vue';
import interpolate from '../../utils/color-interpolate';
import { APPROVAL_LIMIT_GRADE } from '../../utils/constants';

const colormap = interpolate(['#ED050B', '#FFFDCC', '#05BD3C']);

export default {
  name: 'GroupReportGradesView',
  components: {
    'filter-table': FilterTable,
  },
  mixins: [EvaluableStudentsMixin, PermissionMixin, LinksMixin],
  props: {
    chosenSchool: {
      type: String,
      default: null,
    },
    chosenGroupKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      results: {},
      localIds: {
        average: 1,
        failingStudents: 2,
      },
      proGradesHeader: '¿Quieres información clave para tomar decisiones?',
      proGradesMessage: 'Con el plan Académico de RadarEscolar podrías ver acá el promedio de calificaciones de este curso, con detalle por asignatura y número de estudiantes que tienen promedio rojo en cada una de estas',
      sortOrder: ['asc', 'none', 'desc'],
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenYear']),
    options() {
      return {
        chosenSchool: this.chosenSchool,
        chosenGroupKey: this.chosenGroupKey,
        chosenYear: this.chosenYear,
      };
    },
    indicators() {
      return (this.results && this.results.indicators) ? this.results.indicators : [];
    },
    values() {
      return this.info.concat(this.subjectInfo);
    },
    info() {
      const arr = this.indicators.map(
        indicator => {
          const results = this.getResultsByIndicator(indicator);

          return [{
            indicatorId: indicator.id,
            infoId: this.localIds.average,
            value: this.subjectAverage(results),
            norm_value: 0, // eslint-disable-line camelcase
            class: this.$style.grade,
            format: { string: 'numberWithOneDecimal' },
          },
          {
            indicatorId: indicator.id,
            infoId: this.localIds.failingStudents,
            value: this.countFailingStudents(results),
            norm_value: 0, // eslint-disable-line camelcase
            class: this.$style.failing,
          }];
        }
      );

      return [].concat(...arr);
    },
    subjectInfo() {
      return this.indicators.map(indicator => ({
        infoId: -1,
        indicatorId: indicator.id,
        value: this.getFullSubjectName(indicator),
        key: indicator.key,
        format: { string: 'startCase' },
      }));
    },
    subjectInfoAsColumns() {
      return [
        { id: -1, label: 'Asignatura', headerRow: 1, notCompact: true },
        { id: -1, label: '', headerRow: 2, format: { style: { textAlign: 'right' } } },
      ];
    },
    subjectsAsRows() {
      return Object.values(
        this.indicators
          .map(indicator => (
            {
              [indicator.id]: {
                id: indicator.id,
                value: this.getFullSubjectName(indicator),
              },
            }
          )).reduce((subjects, subject) => ({ ...subjects, ...subject }))
      );
    },
    subjectDataAsColumns() {
      const subjectFirstRow = [
        {
          id: this.localIds.average,
          label: 'Promedio curso',
          headerRow: 1,
        },
        {
          id: this.localIds.failingStudents,
          label: 'Estudiantes bajo 4,0',
          headerRow: 1,
        },
      ];

      return subjectFirstRow.concat(subjectFirstRow.map(item => (
        {
          id: 500 + item.id, // eslint-disable-line no-magic-numbers
          headerRow: 2,
          label: item.id === 1 ? `Promedio: ${this.courseAverage.toString().replace('.', ',')}` : `Total: ${this.totalFailing}`,
        }
      )));
    },
    mixedColumns() {
      const columns = this.subjectInfoAsColumns
        .concat(this.subjectDataAsColumns)
        .sort((a, b) => a.id - b.id);

      return columns;
    },
    courseAverage() {
      const count = this.indicators.length;
      const sumTotal = this.indicators.map(
        indicator => this.subjectAverage(this.getResultsByIndicator(indicator))
      ).reduce(
        (sum, i) => (sum + i)
      );

      return count > 0 && parseFloat((sumTotal / count).toFixed(1));
    },
    totalFailing() {
      const sumTotal = this.indicators.map(
        indicator => this.countFailingStudents(this.getResultsByIndicator(indicator))
      ).reduce((sum, i) => (sum + i));

      return sumTotal;
    },
  },
  watch: {
    options: {
      async handler() {
        await this.fetchSubjects();
      },
      inmediate: true,
    },
  },
  async created() {
    await this.fetchSubjects();
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapMutations('ui', { changeOpenedSidebarItem: 'changeOpenedSidebarItem' }),
    getResultsByIndicator(indicator) {
      return this.results.results ?
        this.results.results.filter(r => r.indicator_id === indicator.id) :
        [];
    },
    countFailingStudents(results) {
      return results.filter(r => r.value !== null && r.value < APPROVAL_LIMIT_GRADE &&
      !this.evaluableStudents[r.evaluable_student_id].retired).length;
    },
    subjectAverage(results) {
      let sum = 0;
      let count = 0;
      results.filter(r => r.value).forEach((r) => {
        sum += r.value;
        count++;
      });

      return count > 0 && parseFloat((sum / count).toFixed(1));
    },
    async fetchSubjects() {
      this.addLoading();
      await GroupStudentsApi.getGroupSubjects({
        schoolId: this.chosenSchool,
        groupKey: this.chosenGroupKey,
        year: this.chosenYear })
        .then(({ body }) => {
          this.results = body.group_report_subjects;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.removeLoading();
        });
    },
    getFullSubjectName(indicator) {
      return indicator.key.split('-').slice(1, -1).join(' ');
    },
    colorFromResult(result) {
      if (result && result.infoId === 1) {
        const normalizedValue = (result.value - 4) / 3; // eslint-disable-line no-magic-numbers

        return colormap(normalizedValue);
      }

      return 'inherit';
    },
    link(link, sidebarItemKey) {
      this.changeOpenedSidebarItem(sidebarItemKey);
      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";
@import "../../../styles/app/forms";

.group-report {
  margin-left: 60px;

  &__empty {
    text-align: center;
  }

}

.grade {
  text-align: center;
  border: 2px solid;
  padding: 2px;
  max-height: 27px;
  min-width: 27px;
}

.failing {
  text-align: center;
  padding: 2px;
  max-height: 27px;
  min-width: 27px;
}

.button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
}
</style>
