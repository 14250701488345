<template>
  <div class="rdr-view enrollment-view">
    <paywall
      :permitted="hasManagementIndicatorsPermission"
      restricted>
      <historic-results :tooltip-formatter="{fractionDigits: 0}"/>
    </paywall>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import HistoricResults from '../../../components/historic-results.vue';
import Paywall from '../../../components/paywall.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';

export default {
  name: 'AnnualEnrollmentView',
  components: {
    HistoricResults,
    Paywall,
  },
  mixins: [AuthMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      enrollmentTitle: 'Matrícula de estudiantes en tu(s) establecimiento(s)',
    };
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'chosen_school_ids': true });
      this.setReportKey('enrollment');
    },
  },
};
</script>

<style lang="scss">
</style>
